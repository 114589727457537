<div class="advanced-filters">
  <div class="advanced-filters-header">
    <!-- Having the button with row-reverse ensure it to be at the very right of the modal -->
    <pearl-button type="icon" icon="close" aria-label=" Cancel modifications" (click)="closeAdvancedFilters()" />

    <mat-tab-group
      *ngIf="tabs && tabs.length > 1"
      (selectedTabChange)="tabChanged($event)"
      [selectedIndex]="selectedTabIndex">
      <ng-container *ngFor="let tab of tabs; let i = index">
        <mat-tab [label]="tab.title" [disabled]="!filtersInitialized">
          <ng-template mat-tab-label>
            <div>
              <span
                matBadge="{{ numberFilterApplied }}"
                matBadgeOverlap="false"
                [matBadgeHidden]="!numberFilterApplied || i != 0"
                matBadgeColor="primary">
                {{ tab.title | upperFirstLetter }}
              </span>
            </div>
          </ng-template>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

  <div class="advanced-filters-content">
    <div class="left-content" [ngClass]="getFilterContentClass()" [hidden]="!filtersInitialized">
      <ng-container [ngSwitch]="tabs[selectedTabIndex].id">
        <!-- WTI vessel selector -->
        <wti-vessel-selector
          #advancedFilteringModel
          *ngSwitchCase="'wtiSelector'"
          (onResults)="onAdvancedModelResults($event)"
          (onQuery)="onAdvancedModelQuery()"
          (onReset)="onAdvancedModelReset($event)"
          [config]="selectedModelConfig" />

        <!-- Wfi vessel selector -->
        <wfi-vessel-selector
          #advancedFilteringModel
          *ngSwitchCase="'wfiSelector'"
          (onResults)="onAdvancedModelResults($event)"
          (onQuery)="onAdvancedModelQuery()"
          (onReset)="onAdvancedModelReset($event)"
          [config]="selectedModelConfig" />
      </ng-container>

      <!-- Standard advanced filters -->
      <div class="standard-filters" [ngStyle]="standardFiltersStyle()">
        <div class="filters-header">
          <pearl-form-field [small]="true" iconPrefix="search" label="Enter your search">
            <input matInput ngModel (ngModelChange)="searchFilters($event)" autocomplete="off" />
          </pearl-form-field>
          <div class="filters-actions">
            <mat-checkbox [(ngModel)]="allowNulls" (change)="onAllowNullsChanged()"> Allow empty values</mat-checkbox>
            <pearl-button type="alternative" (click)="resetFilters()">Reset filters</pearl-button>
          </div>
        </div>
        <div class="advanced-filters-fieldsets" [ngClass]="{ 'advanced-filters-with-tabs': tabs.length > 1 }">
          <ng-container *ngFor="let fieldset of fieldsets">
            <selector-fieldset [fieldset]="fieldset" (onchange)="updateFilter($event)" />
          </ng-container>
        </div>
      </div>
    </div>

    <div class="results" *ngIf="filtersInitialized" [ngClass]="getResultContentClass()">
      <div class="wti-loading-container" *ngIf="loading; else results">
        <mat-spinner diameter="20"></mat-spinner>
      </div>
      <ng-template #results>
        <div
          *ngIf="!$advancedFilteringModel || $advancedFilteringModel.isValid; else modelErrors"
          class="result-selection">
          <div class="result-selection-header">
            <div class="result-selection-title">{{ resultsTitle | upperFirstLetter }}</div>
            <div class="result-selection-count" [matTooltip]="userHelpText">
              <p>{{ filteredResults.length + " result" | pluralWord: filteredResults.length }}</p>
              <pearl-icon class="help-icon" icon="information" [size]="24" />
            </div>
          </div>

          <div class="results-search-and-edit">
            <pearl-form-field [small]="true" label="Search in results" iconPrefix="search">
              <input
                matInput
                [(ngModel)]="searchResult"
                (ngModelChange)="reOrderFilteredResults()"
                autocomplete="off" />
            </pearl-form-field>
            <pearl-button
              type="icon"
              icon="splitscreen_right"
              *ngIf="advancedModelActive"
              (click)="openFilterColumnsDialog()"
              matTooltip="Add/remove columns" />
          </div>

          <cdk-virtual-scroll-viewport
            itemSize="36"
            class="result-list example-viewport"
            [ngClass]="{ 'advanced-results': advancedModelActive, 'standard-results': !advancedModelActive }">
            <!-- The header is placed as first element inside cdk-view-port and it has
          position:sticky style applied with top 0px. However this does not work well with cdkVirtualScrollViewPort
          and we have to use a workaround proposed here: https://github.com/angular/components/issues/14833
          This workaround gets the yTransformation from the cdkViewPort and applies the inverse of it
          to the sticky header, the discussion is worth the read, the StackBlitz is here:
          https://stackblitz.com/edit/components-issue-t3xvyz?file=app%2Fapp.component.ts-->
            <div *ngIf="advancedModelActive" class="advanced-results-header" [style.top]="inverseOfTranslation">
              <div class="result-title advanced-result-title">
                {{ entityInfo.name | upperFirstLetter }}
              </div>

              <!-- Inject additional columns if present on the model -->
              <div
                class="advanced-result-column-header"
                *ngFor="let field of allAdvancedModelColumns"
                (click)="toggleOrderingByField(field)"
                [matTooltip]="field.description"
                [ngStyle]="field.cssStyle">
                <div class="result-title-header-text">
                  {{ field.title }}
                </div>
                <div class="field-arrow" [ngClass]="orderArrowClass(field)">
                  @if (fieldOrdering?.propOrder !== field.id || fieldOrdering?.orderDirection === "desc") {
                    <pearl-icon icon="down" [size]="24" />
                  }
                  @if (fieldOrdering?.propOrder === field.id && fieldOrdering?.orderDirection === "asc") {
                    <pearl-icon icon="up" [size]="24" />
                  }
                </div>
              </div>
            </div>

            <div
              class="result"
              *cdkVirtualFor="
                let result of filteredResults | searchResultAdvanced: searchResult;
                trackBy: trackEntityResult
              "
              [ngClass]="getResultItemClass(result)"
              (click)="toggleResult(result)"
              [matTooltip]="getResultTooltip(result)">
              <div
                class="result-title"
                [ngClass]="{
                  'advanced-result-title': advancedModelActive,
                  'standard-result-title': !advancedModelActive,
                }">
                <p [title]="result.title">{{ result.title }}</p>
                <!-- Page button appears on hover -->
                <a
                  mat-icon-button
                  class="result-buttons"
                  *ngIf="result.pageUrl"
                  [href]="result.pageUrl"
                  target="_blank"
                  (click)="onPageLinkClick($event)"
                  [matTooltip]="entityInfo.name + ' page' | upperFirstLetter">
                  <pearl-icon icon="open_in_new" [size]="24" />
                </a>
              </div>

              <!-- For advanced models a list of columns is injected here -->
              <ng-container *ngIf="advancedModelActive">
                <div
                  class="result-field"
                  [matTooltip]="
                    fieldsHelperService.getResultFieldTooltip(field, result.itemData) ??
                    getResultFieldValue(field, result.itemData)
                  "
                  [ngStyle]="fieldsHelperService.getCustomCssStyle(field, result.itemData)"
                  *ngFor="let field of allAdvancedModelColumns">
                  <!-- Handle fields with button style -->
                  <ng-container [ngSwitch]="fieldsHelperService.getFieldType(field, result.itemData)">
                    <ng-container *ngSwitchCase="'button'">
                      <field-button
                        *ngIf="showButton(field.button, result)"
                        [buttonConfig]="field.button"
                        [values]="result"
                        (buttonClick)="onFieldButton($event)">
                      </field-button>
                    </ng-container>

                    <pearl-icon
                      *ngSwitchCase="'icon-field'"
                      [matTooltip]="fieldsHelperService.getResultFieldTooltip(field, result.itemData)"
                      [icon]="fieldsHelperService.getResultFieldIcon(field, result.itemData)"
                      [size]="24">
                    </pearl-icon>

                    <spin-link
                      *ngSwitchCase="'link'"
                      [link]="externalUrlFromLink(result, field)"
                      [linkTitle]="externalLinkTitle(result, field)"
                      [icon]="field.icon">
                    </spin-link>

                    <span *ngSwitchDefault>
                      {{ getResultFieldValue(field, result) }}
                    </span>
                  </ng-container>
                </div>
              </ng-container>

              <!-- Remove button appears on hover-->
              <pearl-button
                type="icon"
                icon="close"
                *ngIf="canExcludeItems"
                (click)="removeFromResultList($event, result)"
                matTooltip="Remove" />
            </div>
            <div class="empty-space-for-more-results-fadeout-effect"></div>
          </cdk-virtual-scroll-viewport>

          <div class="selection-actions">
            <pearl-button
              type="alternative"
              icon="checked"
              (click)="selectResults()"
              [disabled]="!canSelectAll()"
              [spinTooltip]="getSelectAllTooltip()">
              {{ advancedModelActive ? advancedModelSelectButtonText : "Select all" }}
            </pearl-button>
            <pearl-button icon="close" type="alternative" (click)="resetResultSelection()"
              >Clear selection</pearl-button
            >
          </div>

          <div class="additional">
            <spin-filters-multi
              [ngClass]="{ 'advanced-model-input': advancedModelActive }"
              fieldId="additional-entities"
              [data]="addOnEntities"
              #additionalEntitiesFilter
              [showSelectAll]="false"
              [placeholder]="'Additional ' + entityInfo.name + 's'"
              (unselected)="removeAdditionalEntity($event)"
              (selected)="addAdditionalEntity($event[0].data)"
              [disabled]="!canSelect()"
              [spinTooltip]="getMessageIfCannotSelect()" />

            <div class="additional-list" *ngIf="additionalEntities.length > 0">
              <div class="additional-entity" *ngFor="let entity of additionalEntities">
                {{ entity.title }}
                <pearl-button
                  type="icon"
                  icon="close"
                  (click)="removeAdditionalEntity(entity.id)"
                  matTooltip="Remove from additional" />
              </div>
            </div>
          </div>

          <div class="excluded-list" *ngIf="canExcludeItems && excludedEntities.length">
            <div (mouseover)="showExcludedResults($event)" (mouseout)="hideExcludedResults($event)">
              {{ excludedEntities.length + " excluded " + entityInfo.name | pluralWord: excludedEntities.length }} *
            </div>
            <div>
              <pearl-button type="alternative" (click)="restoreExcludedEntities()">Restore</pearl-button>
            </div>
          </div>
          <p id="selector-filtered-vessels-number" [innerHTML]="selectedItemsInformation | safeHtml"></p>
          <selector-actions [actions]="actions" />
        </div>
      </ng-template>

      <ng-template #modelErrors>
        <div class="error-container">
          <span>Unable to run model. Some fields are in error.</span>
        </div>
      </ng-template>
    </div>

    <div class="filters-loading" *ngIf="!filtersInitialized">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
</div>

<list-tooltip />
