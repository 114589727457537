import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgIf } from '@angular/common';

import { merge, round } from 'lodash-es';

import { ChartBridge, ChartSelectKey } from './chart-types';
import { NvGraph } from './nvgraph';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { GraphOptionsComponent } from './graph-options';
import { DescriptionButtonComponent } from '../shared/description-button';

export type WaterfallMeasure = 'relative' | 'total' | 'absolute';

@Component({
  selector: 'plotly-waterfall',
  templateUrl: 'plotly-waterfall.html',
  styleUrls: ['nvgraph.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DescriptionButtonComponent,
    GraphOptionsComponent,
    MatProgressSpinnerModule,
    NgClass,
    ChartTooltipComponent,
  ],
})
export class PlotlyWaterfallComponent extends NvGraph {
  @Input()
  set bridges(bridges: ChartBridge[]) {
    bridges.forEach(bridge => this._bridges[bridge.id] = bridge);
  }

  public override availableSelects: ChartSelectKey[] = [
    'metric',
  ];

  private _bridges: { [bridgeId: string]: ChartBridge } = {};

  constructor(elementRef: ElementRef) {
    super(elementRef, 'waterfall');
  }

  public async chartSpecificPlot(head: any, data: any[]) {
    if (!data) {
      return;
    }
    const measure: WaterfallMeasure[] = [];

    // xCoords - it is standard
    const xTicksAndLabels: { [index: number]: string } = {};
    // yCoords
    const yData: any[] = [];

    // Label to be displayed on each bar
    const barLabels: string[] = [];
    let currentBridgeId: string = null;
    let bridgeConf: ChartBridge = null;
    let bridgeTotal = 0;

    let i = 0;

    /*
     * transform standard chart data in plotly format
     * we iterate through each point and when we change bridge we calculate a total for this bridge
     */
    data.forEach((d, index) => {
      // first bridge
      if (!currentBridgeId) {
        currentBridgeId = d.bridgeId;
      } // we change bridge. So we close we previous one by calculating its total
      else if (d.bridgeId != currentBridgeId) {
        measure.push('total');
        xTicksAndLabels[i] = bridgeConf.totalTitle;
        yData.push(0);
        i++;
        barLabels.push(NvGraph.formatNumberWithCommas(round(bridgeTotal, 3)));
        currentBridgeId = d.bridgeId;
      }

      bridgeConf = this._bridges[currentBridgeId];

      // list of group that need to be count as negative value
      const negativeGroups = {};
      if (bridgeConf.negativeGroups) {
        bridgeConf.negativeGroups.forEach(ng => negativeGroups[ng] = true);
      }
      measure.push('relative');

      /*
       * Force max precision to 3 digits. Sum from floats is sometimes of the form 8.899999999
       * and this value is printed without formatting in waterfall chart
       */
      let yValue = round(d[this.getDefaultSplitKeyValue(d)], 3);

      // We check if the current group is in negativeGroups if so we force this value to be a negative value
      if (bridgeConf.negativeGroups && negativeGroups[d.x] && yValue > 0) {
        yValue = -yValue;
      }

      bridgeTotal += yValue;

      /*
       * We check if we already has a xCoord with same name.
       * If so we add bridge name at end of xLabel to guarentee unicity
       */
      const xLabel = xTicksAndLabels[d.x] ? `${d.x} - ${bridgeConf.groupby.title}` : d.x;
      xTicksAndLabels[i] = xLabel;
      i++;
      yData.push(yValue);
      barLabels.push(NvGraph.formatNumberWithCommas(yValue));

      // If last point we need to add the final total
      if (index == data.length - 1) {
        measure.push('total');
        xTicksAndLabels[i] = bridgeConf.totalTitle;
        yData.push(0);
        barLabels.push(NvGraph.formatNumberWithCommas(round(bridgeTotal, 3)));
      }
    });

    const plotOpts = {
      name: this.title,
      type: 'waterfall',
      orientation: 'v',
      textposition: 'auto',
      cliponaxis: false,
      measure,
      text: barLabels,
      decreasing: { marker: { color: 'Maroon', line: { color: 'white', width: 2 } } },
      increasing: { marker: { color: 'Teal' }, line: { color: 'white', width: 2 } },
      totals: { marker: { color: 'rgb(3, 87, 133)', line: { color: 'white', width: 2 } } },
      x: Object.keys(xTicksAndLabels),
      customData: Object.values(xTicksAndLabels).map(x => ({ fullX: x })),
      y: yData,
      connector: {
        line: {
          color: 'rgb(63,63,63)',
          dash: 3,
        },
      },
    };

    this.opts.xaxis.tickvals = Object.keys(xTicksAndLabels);
    this.opts.xaxis.ticktext = Object.values(xTicksAndLabels).map(d => this.adaptValueToXAxisTick(d));

    // Change decreasing/increasing/totals style
    if (this.opts.decreasing) {
      plotOpts.decreasing = this.opts.decreasing;
    }
    if (this.opts.increasing) {
      plotOpts.increasing = this.opts.increasing;
    }
    if (this.opts.totals) {
      plotOpts.totals = this.opts.totals;
    }
    this.traces = [plotOpts];

    this.layout = merge(this.mapChartOptsInPlotlyLayout(), this.getYAxisLayout());
    await this.plotlyPlot();
    this.addPlotlyTooltip();
  }

  public override mapChartOptsInPlotlyLayout() {
    const plotlyLayout = super.mapChartOptsInPlotlyLayout();
    plotlyLayout.margin.t = 0;
    return plotlyLayout;
  }
}
