import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, HostListener, OnInit, Output, ViewChild,
  computed, input, signal } from '@angular/core';
import { FloatLabelType, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatFormFieldControl,
  MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { PearlIconComponent } from '../icons/pearl-icon.component';
import { PearlIcon, PearlIconSize } from '../icons/icons';

@Component({
  selector: 'pearl-form-field',
  standalone: true,
  styleUrl: './pearl-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, MatIconModule, PearlIconComponent],
  host: {
    '[class.label-floating]': 'ngContentControl.shouldLabelFloat || floatLabel() === "always"',
    '[class.readonly]': 'ngContentControl.readonly || readonly()',
    '[class.highlighted]': 'highlighted()',
    '[class.small]': 'small()',
    '[class.editable]': 'editable()',
    '[class.hint-error]': 'hintError()',
  },
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
      appearance: 'outline',
    },
  }],
  templateUrl: './pearl-form-field.component.html',
})
export class PearlFormFieldComponent implements OnInit {
  @ViewChild(MatFormField, { static: true })
  private readonly matFormField!: MatFormField;

  @ContentChild(MatFormFieldControl, { static: true })
  public readonly ngContentControl!: MatFormFieldControl<unknown>;

  @ContentChild(MatSelect, { static: true })
  public readonly ngContentSelect!: MatSelect;

  @ContentChild(MatAutocompleteTrigger, { static: true })
  public readonly ngContentAutocompleteTrigger!: MatAutocomplete;

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (!this.editable()) return;
    this.displayEditSuffix.set(true && (this.readonly() || ((this.ngContentControl as any).readonly as boolean)));
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (!this.editable()) return;
    this.displayEditSuffix.set(false);
  }

  @Output()
  public readonly edit = new EventEmitter<void>();

  public readonly small = input<boolean>(false);
  public readonly highlighted = input<boolean>(false);
  public readonly readonly = input<boolean>(false);
  public readonly editable = input<boolean>(false);
  /** mat-label must be present on component init. search bar set search label after component has been initialized */
  public readonly hasLabel = input<boolean>(true);
  public readonly label = input<string | null>(null);
  public readonly floatLabel = input<FloatLabelType>('auto');
  public readonly iconPrefix = input<PearlIcon | null>(null);
  public readonly iconSuffix = input<PearlIcon | null>(null);
  public readonly hintStart = input<string | null>(null);
  public readonly hintEnd = input<string | null>(null);
  /**
   * In most cases, we bypass the FormsModule implementation and use our own validators, for instance data entry
   * coherency checks, to determine if an input is in an error state. In these cases, mat-error cannot be triggered,
   * so we display errors using `mat-hint`.
   */
  public readonly hintError = input<boolean>(false);
  /**
   * If we have proper FormsModule implementation using validator we can rely on `mat-error`
   */
  public readonly showError = input<boolean>(false);
  /**
   * The error message that should be displayed is the same however
   */
  public readonly errorMessage = input<string | null>(null);

  public readonly displayEditSuffix = signal<boolean>(false);
  public readonly iconSize = computed<PearlIconSize>(() => (this.small() ? 20 : 24));

  ngOnInit(): void {
    this.matFormField._control = this.ngContentControl;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (this.ngContentControl as any)._isInFormField = true;

    if (this.ngContentSelect) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (this.ngContentControl as any)._parentFormField = this.matFormField;
    }

    if (this.ngContentAutocompleteTrigger) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (this.ngContentAutocompleteTrigger as any)._formField = this.matFormField;
    }
  }
}
