<fieldset class="spin-filters-fieldset" [ngClass]="{ 'internal-use-feature': config.isInternal }">
  <ng-container *ngIf="fieldset.fields.length">
    <pearl-button *ngIf="config.isInternal" icon="search" type="icon" size="small" (click)="openConfig(fieldset)" />
    <div
      *ngIf="fieldset.title !== null"
      (click)="legendOnclick()"
      [spinTooltip]="fieldset.description"
      class="fieldset-legend">
      <pearl-icon [icon]="this.fieldset.expanded ? 'chevron_down' : 'chevron_right'" [size]="24" />
      <span [ngClass]="getLegendClass()">{{ fieldset.title }} </span>
      <div *ngIf="count > 0 && showCount" class="spin-padded spin-rounded-div spin-bg-primary">
        {{ count }}
      </div>
    </div>
    <div class="fieldset-fields" [ngStyle]="getFieldsetFieldsStyle()">
      <ng-container *ngFor="let field of fieldset.fields">
        <ng-container
          [ngSwitch]="field.filterType"
          *ngIf="field.filteringEnabled != false && field.filterType && field.visible != false">
          <spin-filters-interval
            *ngSwitchCase="'interval'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />
          <spin-filters-interval
            *ngSwitchCase="'datetime'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-interval *ngSwitchCase="'date'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

          <spin-filters-number *ngSwitchCase="'number'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

          <spin-filters-doubledate
            *ngSwitchCase="'doubledate'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-doubledate
            *ngSwitchCase="'intersection'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-checkbox
            *ngSwitchCase="'checkbox-exclude'"
            [field]="$any(field)"
            (checkboxFilterChange)="onbubblingchange($event)" />

          <spin-filters-checkbox
            *ngSwitchCase="'checkbox'"
            [field]="$any(field)"
            (checkboxFilterChange)="onbubblingchange($event)" />

          <spin-filters-field *ngSwitchCase="'multi'" [field]="field" (onchange)="onbubblingchange($event)" />

          <spin-filters-field *ngSwitchCase="'excludemulti'" [field]="field" (onchange)="onbubblingchange($event)" />

          <spin-filters-field
            [haveNoTags]="true"
            *ngSwitchCase="'single'"
            [field]="field"
            (onchange)="onbubblingchange($event)">
          </spin-filters-field>
        </ng-container>
      </ng-container>
      <pearl-button
        *ngIf="fieldset.selector"
        class="selector-button"
        icon="sort"
        type="alternative"
        (click)="openSelector()">
        {{ selectorButtonLabel | upperFirstLetter }}
        <span *ngIf="numberAdvancedFilterApplied" class="spin-rounded-div spin-bg-primary spin-padded spin-inline-flex">
          {{ numberAdvancedFilterApplied }}
        </span>
      </pearl-button>
    </div>
  </ng-container>
</fieldset>
