<!-- DISPLAY/LOADING STATE -->
<ng-container *ngIf="!editMode">
  <div class="line-container">
    <div class="content">
      <!-- Projected content. Different components might display the field content differently -->
      <ng-content></ng-content>
    </div>

    <div class="actions" *ngIf="hover() && !loading()">
      <pearl-icon class="action" title="Edit" role="button" icon="edit" [size]="20" (click)="onEditButtonClick()" />
    </div>

    <mat-spinner [diameter]="15" *ngIf="loading()"></mat-spinner>
  </div>
</ng-container>

<!-- EDITING STATE -->
<ng-container *ngIf="editMode && !loading()">
  <div class="line-container">
    <div class="content">
      <entity-detail-field
        [field]="entityFieldDefinition"
        [small]="true"
        [entityAccessor]="entityDataAccessor"
        [parent]="this"
        [datetimeBiggerFont]="false"
        [datetimeDarkMode]="darkMode"
        [datetimeHideLabel]="hideLabel"
        [hideErrorHint]="true" />
    </div>

    <div class="actions">
      <pearl-icon
        class="action"
        title="Cancel"
        role="button"
        icon="cancel"
        [size]="20"
        (click)="onCancelButtonClick()" />
      <pearl-icon
        class="action"
        title="Save"
        role="button"
        icon="save"
        [size]="20"
        [ngClass]="{ disabled: coherencyErrors.length }"
        (click)="onSaveButtonClick()" />
    </div>
  </div>

  <div class="errors" *ngIf="coherencyErrors.length">
    <mat-error *ngFor="let error of coherencyErrors">{{ error }}</mat-error>
  </div>
</ng-container>
