import { xAxisParameters } from '../schedule/schedule-types';
import { milliSecondInPeriod } from '../helpers/types';

/**
 * Class to manage time related logic for charts
 */
export class ChartTimeManager {
  /**
   * The goal is to create xAxis tick for each vertical breaklines draw. But we will also create intermediate ticks
   * The ticks are creating following this method: we calculate first the tickNumber (depending the available width and
   * a standard minimum tick width)
   * Then if the number vertical breaklines is under tickNumber we gonna create intermediate ticks. To do this we check
   * the gap between two tick (e.g 2 hours)
   * then we will create point corresponding to the half of this gap (so in this case we will have a tick each hour).
   * Then we check again if we have now enough points
   * If not we repeat the process.
   *
   * @param xAxisParameters
   * @param theoreticalTickNumber
   * @returns
   */
  public static getXaxisTicks(xAxisParameters: xAxisParameters, theoreticalTickNumber: number): number[] {
    let breaklineGap: number;

    // If we have more than one vertical breakline we take the diff between two breaklines as gap
    if (xAxisParameters.verticalBreaklines.length > 1) {
      breaklineGap = xAxisParameters.verticalBreaklines[1].timestamp - xAxisParameters.verticalBreaklines[0].timestamp;
    } else {
      /**
       * But if the interval has a too small interval we can have only one breakline
       * in this case we take the breakline unit duration (if breakline unit is 1 year we take 1 year as gap)
       */
      breaklineGap = milliSecondInPeriod[xAxisParameters.breakLineUnit as string];
    }

    let xAxisTicks = xAxisParameters.verticalBreaklines
      .filter(breakline =>
        breakline.timestamp >= xAxisParameters.currentExtent[0]
        && breakline.timestamp <= xAxisParameters.currentExtent[1]
      )
      .map(breakline => breakline.timestamp);

    // If we don't have xAxisTicks we create two artificial ones corresponding to the interval extent
    if (xAxisTicks.length === 0) {
      xAxisTicks.push(xAxisParameters.currentExtent[0]);
      xAxisTicks.push(xAxisParameters.currentExtent[1]);
    }

    while (xAxisTicks.length < theoreticalTickNumber) {
      breaklineGap /= 2;

      for (let i = 0; i < xAxisTicks.length; i++) {
        const newPoint = xAxisTicks[i] + breaklineGap;
        /**
         * We add a 'minor' tick between two vertical breaklines when we haven't enough point
         * this minor tick won't have vertical breaklines but just a tick in xAxis
         */
        if (newPoint < xAxisParameters.currentExtent[1]) {
          xAxisTicks.splice(i + 1, 0, newPoint);
          i++;
        }
      }

      /**
       * We also check if we can introduce a point BEFORE the first existing point (so between interval min and first
       * vertical breakline)
       */
      if (xAxisTicks[0] - breaklineGap >= xAxisParameters.currentExtent[0]) {
        xAxisTicks.splice(0, 0, xAxisTicks[0] - breaklineGap);
      }
    }

    /**
     * Tick number is the tick number in perfect case, but it possible that we have more vertical breaklines
     * If we have more than twice as many ticks as the tickNumber we'll keep max 2* tickNumber
     */
    while (xAxisTicks.length >= 2 * theoreticalTickNumber) {
      xAxisTicks = xAxisTicks.filter((value, index) => index % 2 === 0);
      // Avoid infinite loop if the windows get resized
      if (xAxisTicks.length === 1 && theoreticalTickNumber <= 0) {
        break;
      }
    }

    return xAxisTicks;
  }
}
