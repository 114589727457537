/// <reference path="../../node_modules/@types/google.maps/index.d.ts" />

import { DecimalPipe } from '@angular/common';

import dayjs from 'dayjs';
import { marked } from 'marked';
import { Duration } from 'dayjs/plugin/duration';
import { sha256 } from 'js-sha256';

import { AdditionalData, AdditionalDataKeys, FieldSettings, HasDisplayOrder, Interval, SomeEntityChainable,
  SpinTimeUnit, WithAdditionalData, milliSecondInPeriod } from './types';
import { FilterHelper } from '../filters/filter-helper';
import { getChained } from '../data-loader/ref-data-provider';
import { ErrorWithFingerprint } from './sentry.helper';

const DURATION_MAPPING = {
  'd': milliSecondInPeriod['day'],
  'h': milliSecondInPeriod['hour'],
  'm': milliSecondInPeriod['minute'],
};

export class DataHelpers {
  public static emailRegex: RegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public static numberPipe = new DecimalPipe('en-US');

  /**
   * Sort list of items according to a text field somewhere on the item
   * Put the items that start with the search string to the top
   */
  public static sortExactMatch<T>(filteredList: T[], titleGetter: (x: T) => string, searchStr: string) {
    if (searchStr == undefined || searchStr === '') {
      return filteredList;
    }

    filteredList.sort((item1, item2) => {
      return DataHelpers.alphabeticalOrder(item1, item2, titleGetter, searchStr);
    });
  }

  private static warnNullItemOrTitle(item: any): boolean {
    if (!item || !item['title']) {
      console.warn('Undefined object: ', item);
      return true;
    }

    return false;
  }

  public static alphabeticalOrder<T>(item1: T, item2: T, titleGetter: (x: T) => string, searchStr: string) {
    if (this.warnNullItemOrTitle(item1) || this.warnNullItemOrTitle(item2)) {
      return -1;
    }

    const firstStarts = titleGetter(item1).startsWith(searchStr);
    const secondStarts = titleGetter(item2).startsWith(searchStr);

    if (firstStarts && !secondStarts) {
      return -1;
    }

    if (!firstStarts && secondStarts) {
      return 1;
    }

    if (titleGetter(item1) < titleGetter(item2)) {
      return -1;
    }

    return 1;
  }

  public static toDict<T>(data: readonly T[], indexor: (t: T) => string | number) {
    const result: { [K in string | number]: T } = {};
    for (const item of data) {
      result[indexor(item)] = item;
    }
    return result;
  }

  public static formatNumber(value: number, format?: string): string {
    // in case of mixed data, where majority of values are formatted numbers but sometimes mixed with strings
    if (isNaN(value)) {
      return '';
    }

    if (format) {
      if (format.startsWith('#')) {
        return DataHelpers.formatMillisecondsDuration(value, format.slice(1));
      }
      if (format === '%') {
        return DataHelpers.numberPipe.transform(value * 100, '1.0-1') + '%';
      }
      return DataHelpers.numberPipe.transform(value, format);
    }

    /*
     * in absence of any number format just return the raw value with comma separator at thousand
     * We apply comma separator only if we aren't format for a download
     */
    if (value >= 10000) {
      return DataHelpers.numberPipe.transform(value, '.');
    }

    // TODO: return value.toString() after checking potential side effects
    return value as unknown as string;
  }

  private static formatMillisecondsDuration(value: number, format: string): string {
    switch (format) {
      case 'default':
        if (value >= milliSecondInPeriod['day']) {
          return DataHelpers.formatDuration(value, 'D [d] H [h]');
        } else if (value >= milliSecondInPeriod['hour']) {
          return DataHelpers.formatDuration(value, 'H [h] m [m]');
        } else {
          return DataHelpers.formatDuration(value, 'm [m] s [s]');
        }
      case 'd':
      case 'h':
      case 'm':
        return `${(value / DURATION_MAPPING[format]).toFixed(1)} ${format}`;
      default:
        console.error(
          new ErrorWithFingerprint(`Unknown duration format ${format}`, ['format-number-unknown-duration-format']),
        );
    }
  }

  /**
   * Tell whether input has parameters to be injected in
   *
   * @param input string
   * @returns boolean, return true if pattern "<{*}>" is found in input
   */
  public static needsParameterInjection(input: string): boolean {
    return input?.match(/\<\{\w+\}\>/)?.length > 0;
  }

  /**
   * Inject variables in a string with patterns, e.g. "<{vessel}>'s number of engines"
   *
   * @param input        string with patterns, e.g. "<{vessel}>'s number of engines"
   * @param parameterBag an object with key-value pairs, e.g. {vessel: "WIND OF CHANGE"}
   * @returns            string with injected parameters, e.g. "WIND OF CHANGE's number of engines"
   */
  public static injectParameters(input: string, parameterBag: object): string | null {
    if (!parameterBag) return null;

    return input.replace(/(\<\{\w+\}\>)/g, match => {
      return decodeURIComponent(parameterBag[match.slice(2, -2)]);
    });
  }

  public static getDayjsDuration(value: number, appliedUnit): Duration {
    return dayjs.duration(value, appliedUnit);
  }

  /**
   * Format duration
   * Convert seconds/milliseconds to human readable time
   * Relies on dayjs format()
   *
   * @param  number       value         Value (in s/ms/...)
   * @param  string       format        Output format (default to HH:mm)
   * @param  SpinTimeUnit durationUnit  dayjs units (OpUnitType)
   * @return string                     Formatted duration
   */
  public static formatDuration(value: number, format?: string, durationUnit?: SpinTimeUnit): string {
    const appliedFormat = format ? format : 'HH:mm';
    const appliedUnit: SpinTimeUnit = durationUnit ? durationUnit : 'millisecond';
    const duration = DataHelpers.getDayjsDuration(value, appliedUnit);

    /*
     * We handle HH:mm format manually because of 2 issues:
     * by default dayjs (and majority of libs) will return 00:00 for duration of 24 hours
     * formatted in HH:mm, so we handle this manually. This is the same as C# timespan formatting.
     * Other issue is that hh:mm  duration formatting of negative value gives -02:-02 instead of -02:02
     * https://github.com/iamkun/dayjs/issues/1700
     */
    if (appliedFormat === 'HH:mm') {
      const sign = duration.asMilliseconds() < 0 ? '-' : '';
      const durationHoursPart = `${Math.floor(Math.abs(duration.asHours()))}`.padStart(2, '0');
      const durationMinutesPart = duration.format('mm').replace(/-/, '');
      return `${sign}${durationHoursPart}:${durationMinutesPart}`;
    }

    return (duration as any).format(appliedFormat, { trim: false });
  }

  /**
   * Format a duration range with the given format.
   * This basically applies `formatDuration` function to 2 numbers.
   *
   * @param value         The duration range. Expected format is `number1,number2`.
   * @param format        The duration format.
   * @param durationUnit  The time unit of each number.
   * @returns             The formatted duration range.
   */
  public static formatDurationRange(value: string, format?: string, durationUnit?: SpinTimeUnit): string {
    const durationRange = value?.split(',');
    if (!durationRange || durationRange.length !== 2) {
      return '';
    }
    const formattedRange = durationRange.map(duration => DataHelpers.formatDuration(+duration, format, durationUnit));
    // Return an empty string if the formatted start and the formatted end of the range are the same
    if (formattedRange[0] === formattedRange[1]) {
      return '';
    }
    return formattedRange.join(' - ');
  }

  public static formatRangeValue(leftPropValue: number, rightPropValue: number): string {
    if ((leftPropValue || leftPropValue === 0) && (rightPropValue || rightPropValue === 0)) {
      if (leftPropValue === rightPropValue) {
        return `${leftPropValue}`;
      }
      return `${leftPropValue} - ${rightPropValue}`;
    }
    return '';
  }

  public static validateEmail(email: string): boolean {
    return this.emailRegex.test(email.toLowerCase());
  }

  public static displayOrderSort(a: HasDisplayOrder, b: HasDisplayOrder) {
    if (a.displayOrder !== null && b.displayOrder === null) {
      return -1;
    }
    if (a.displayOrder === null && b.displayOrder !== null) {
      return 1;
    }
    return a.displayOrder < b.displayOrder ? -1 : (b.displayOrder < a.displayOrder ? 1 : 0);
  }

  /**
   * Extract query params from the URL, using special notation [:queryParamToRetrieve:]
   *
   * @example
   * ```ts
   * // In config we have [:vesselId:]
   * extractQueryParam('[:vesselId:]') // returns 'vesselId'
   * ```
   */
  public static extractQueryParam(s: string): string {
    return /(\[:)(.*?)(:\])/.exec(s)[2];
  }

  /**
   * This method is used to check whether a string corresponds to our notation to access query params.
   *
   * @example
   * ```ts
   * // In config we have [:vesselId:]
   * isQueryParam('[:vesselId:]') // returns true
   * ```
   */
  public static isQueryParam(s: string): boolean {
    return /^((\[:.+:\]))$/.test(s);
  }

  /**
   * Extract data parameters from entity/values, using special notation :dataParamToRetrieve
   *
   * @example
   * ```ts
   * // In config we have :vesselId
   * extractDataParam(':vesselId') // returns 'vesselId'
   * ```
   */
  public static extractDataParam(s: string): string {
    return s.substring(1);
  }

  /**
   * This method is used to check whether a string corresponds to our notation to access data params.
   *
   * @example
   * ```ts
   * // In config we have :vesselId
   * isQueryParam(':vesselId') // returns true
   * ```
   */
  public static isDataParam(s: string): boolean {
    return s.charAt(0) === ':';
  }

  public static markdownToHtml(text: string): string {
    // Note: it's possible to avoid surrounding <p> tags using marked.parseInline(text).
    return marked(text, { gfm: true, breaks: true });
  }

  // valid ids are numbers (most pages) or 2-letters strings (countries)
  public static isIdValid(candidate: string | number): boolean {
    if (Number.isInteger(parseInt(candidate as string))) {
      return true;
    }

    if (typeof candidate === 'string' && candidate.length === 2) {
      return true;
    }

    return false;
  }

  /**
   *  @desc Order object keys based on alphanumeric order.
   *  As of ECMA 2020, object keys are ordered based on a mix of alphanumeric (for number keys) and insertion
   *  (for others) orders. This order makes the object keys order dependent on the user path, which we don't want when
   *  we compute the object hash. Here we're making sure that the guaranteed insertion order is consistent with the
   *  alphanumeric order.
   *  More info here: https://stackoverflow.com/a/38218582
   */
  private static sortObjectKeys(obj: unknown): unknown {
    if (obj == null) {
      return obj;
    }
    if (typeof obj != 'object') { // it is a primitive: number/string (in an array)
      return obj;
    }
    return Object.keys(obj).sort().reduce((acc, key) => {
      if (Array.isArray(obj[key])) {
        acc[key] = obj[key].map(DataHelpers.sortObjectKeys);
      } else if (typeof obj[key] === 'object') {
        acc[key] = DataHelpers.sortObjectKeys(obj[key]);
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }

  public static generateHashFromObject(obj: unknown): string {
    const objectSorted = DataHelpers.sortObjectKeys(obj);
    // JSON.stringify returns null for both undefined and null values, hence the need for this replacer
    const objectStringified = JSON.stringify(objectSorted, function(k, v) {
      return v === undefined ? 'undef' : v;
    });
    const objectHashable: string = objectStringified.replace(/\s+/g, '');

    return sha256(objectHashable);
  }

  /**
   * Compare parameters obj hash and return true if hashs are similar
   * @param objA
   * @param objB
   * @returns boolean
   */
  public static haveSameHash(objA: object, objB: object): boolean {
    return DataHelpers.generateHashFromObject(objA) === DataHelpers.generateHashFromObject(objB);
  }
}

export function getNumberPrecisionFromFormat(format: string) {
  return Number(format.split('-')[1]);
}

export function safeString(value: any) {
  if (value == null) {
    return null;
  }
  return value.toString();
}

// converts the provided knot value to m/s
export function knotsToMeterPerSecond(knotValue: number): number {
  return knotValue * 0.514444;
}

export function getNumberListFromString(numbersStr?: string): number[] {
  return numbersStr?.split(',').map(n => Number(n));
}

// We consider a timestamp is valid from this point in time
const TIMESTAMP_1995 = 800000000000;
export function isValidRecentInterval(interval: Interval): boolean {
  if (!Array.isArray(interval)) return false;
  return interval.length === 2 && interval[0] > TIMESTAMP_1995 && interval[0] < interval[1]; // more than year 1995
}

export function isSerializedInterval(numbersStr?: string): boolean {
  const splitted = numbersStr.split(',');
  const isNumeric = splitted.length === 2 && splitted.every(n => FilterHelper.isNumeric(n) && Number(n) > 0);
  if (!isNumeric) return false;
  return isValidRecentInterval(getNumberListFromString(numbersStr) as Interval);
}

/**
 * Get the additional data object corresponding to field `fieldId`.
 *
 * `T` is the type of the base object without additional data fields.
 *
 * @param data object containing `fieldId` key and additional data
 * @param fieldId base field key
 * @returns additional data for field with `fieldId` key
 *
 * @example
 * ```ts
 * type Base = {a: number};
 * const obj = {
 *  a: 1;
 *  a__additionalData: {...}
 * }
 *
 * getAdditionalData<Base>(obj, 'a'); // -> obj.a__additionalData
 * ```
 */
export function getAdditionalData<T>(
  data: WithAdditionalData<T>,
  fieldId: keyof T & string,
): AdditionalData<T[typeof fieldId]> | undefined {
  const additionalDataKey = `${fieldId}__additionalData` as AdditionalDataKeys<T>;

  return data[additionalDataKey];
}

/**
 * Get original value of field `fieldId` from additional data.
 */
export function getOriginalValue<T>(
  data: WithAdditionalData<T>,
  fieldId: keyof T & string,
): ReturnType<typeof getAdditionalData<T>>['originalValue'] {
  return getAdditionalData<T>(data, fieldId)?.originalValue;
}

/**
 * For now this is a simple function, but it should be used as far as possible.
 * If needed, the implementation could process special plural forms, e.g. company -> companies
 */
export function pluralize(word: string, count = 2): string {
  return `${word}${count === 1 ? '' : 's'}`;
}

/**
 * For multiple fields, propTitle has precedence since we can include common filtering field definitions,
 * that use propTitle themselves to display the verbose value.
 */
export function getPropKeyForNonFilterField(field: FieldSettings): string {
  return field.propTitle ?? field.propValue ?? field.id;
}

/**
 * Allows to fetch a field from an entity, using chaining and following the above function precedence rules.
 */
export function getChainedPropForNonFilterField<T = unknown>(entity: SomeEntityChainable, field: FieldSettings): T {
  return getChained(entity, field.propTitle) || getChained(entity, field.propValue) || getChained(entity, field.id);
}

/**
 * Return an iterator, yielding pair values of provided iterable, i.e iterPairs([1,2,3]) will yield:
 * - [1, 2]
 * - [2, 3]
 */
export function* iterPairs<T>(iterable: Iterable<T>): Generator<[T, T], void> {
  const iterator = iterable[Symbol.iterator]();
  let a = iterator.next();
  if (a.done) return;
  let b = iterator.next();
  while (!b.done) {
    yield [a.value, b.value];
    a = b;
    b = iterator.next();
  }
}

/**
 * Uses a binary search to determine the lowest index at which value should be inserted into array in
 * order to maintain its sort order.
 * @param array       The sorted array (increasing order), sorted on "sortedProd"
 * @param toFind      The value to find. This corresponds to the value on which the value is sorted on
 * @param sortedProp  The property on which the array of object is sorted on
 */
export function sortedIndexBy<T extends object>(
  array: readonly T[],
  toFind: number,
  sortedProp: string,
): number {
  if (array.length === 0) return 0;
  const comparison = (a: T, b: number): boolean => a[sortedProp] < b;
  let left = 0;
  let right = array.length;

  while (left !== right - 1) {
    const mid = Math.floor((left + right) / 2);
    if (comparison(array[mid], toFind)) {
      left = mid;
    } else {
      right = mid;
    }
  }
  const index = left + (comparison(array[left], toFind) ? 1 : 0);
  return index;
}
