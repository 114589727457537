import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, inject } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgFor, NgIf } from '@angular/common';

import { SelectorActionConfig } from './selector.types';
import { SelectorActionButtonComponent } from './selector-action-button.component';
import { PearlButtonComponent } from '../shared/pearl-components';
import { UIService } from '../shared/services/ui.service';

@Component({
  selector: 'selector-actions',
  templateUrl: './selector-actions.component.html',
  styleUrls: ['./selector-actions.component.css'],
  standalone: true,
  imports: [
    NgIf,
    PearlButtonComponent,
    MatMenuModule,
    NgFor,
    SelectorActionButtonComponent,
  ],
})
export class SelectorActionsComponent implements OnChanges {
  /**
   * Secondary buttons are put in a drop down if isSmallDisplay is true.
   */

  @Input()
  public actions: SelectorActionConfig[] = [];

  public primaryAction: SelectorActionConfig;
  public secondaryActions: SelectorActionConfig[] = [];
  public uiService = inject(UIService);

  public ngOnChanges(_: SimpleChanges): void {
    this.primaryAction = this.actions.find(action => action.type === 'primary');
    this.secondaryActions = this.actions.filter(action => action.type === 'secondary');
  }
}
