import { Injectable, computed, inject, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

type DisplayMode = 'large' | 'medium' | 'small';

import tinycolor from 'tinycolor2';

import { Config } from '../../config/config';
import { Color, HEX } from '../../helpers/types';
import { SPINERGIE_BLUE } from '../../helpers/color-helper';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  private _displayMode = signal<DisplayMode>('large');

  public customLogo = signal<string | undefined>(undefined);
  public appLogo = computed(() => {
    if (!this.isSmallDisplay() && this.customLogo()) {
      return this.customLogo();
    }
    return Config.SPIN_LOGO_PATH;
  });

  /**
   * The color to use for the navbar. Initialized to default spin blue, but can be set to a custom navbar color.
   */
  public navbarColor = signal<Color>(SPINERGIE_BLUE);

  public navbarStyle = computed(() => {
    return { 'background-color': this.navbarColor() };
  });

  /**
   * Computed color for active item in menu panel: darker or brighter shade of navbarColor depending on its brightness.
   */
  public readonly activePanelColor = computed<HEX>(() => {
    const color = tinycolor(this.navbarColor());
    color.isLight() ? color.darken(20) : color.brighten(20);

    return color.toString('hex') as HEX;
  });

  public isSmallDisplay = computed(() => this._displayMode() === 'small');
  public isMediumDisplay = computed(() => this._displayMode() === 'medium');
  public isLargeDisplay = computed(() => this._displayMode() === 'large');

  constructor() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe(result => {
        if (result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.Medium]) {
          this._displayMode.set('medium');
        } else if (result.breakpoints[Breakpoints.XSmall]) {
          this._displayMode.set('small');
        } else {
          this._displayMode.set('large');
        }
        this.adaptDocumentBodyCssClassToDisplayMode();
      });
  }

  public adaptDocumentBodyCssClassToDisplayMode(): void {
    const body = document.getElementsByTagName('body')[0];
    this.isSmallDisplay() ? body.classList.add('small-display') : body.classList.remove('small-display');
    this.isMediumDisplay() ? body.classList.add('medium-display') : body.classList.remove('medium-display');
  }
}
