@if (errorMessage) {
  <div class="error-hint">{{ errorMessage }}</div>
}
<!-- two groups of buttons one on left and on the right maximising the space between -->
<div class="entity-header">
  <span class="fill-space"></span>
  <div class="entity-header-buttons">
    @if (!deleteMode && showSearchBar) {
      <pearl-form-field iconPrefix="search">
        <input
          matInput
          type="text"
          [formControl]="searchBarContent"
          matInput
          [matAutocomplete]="auto"
          #searchBarInput />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="switchToFieldTab($event)">
          @for (field of getAllFields(searchBarInput.value); track field.id) {
            <mat-option [value]="field.title">
              <div class="search-option">{{ field.title }}</div>
            </mat-option>
          }
        </mat-autocomplete>
      </pearl-form-field>
    }

    @if (showDelete) {
      <pearl-button icon="delete" type="alternative" mode="warning" (click)="enterDeleteMode()"> Delete </pearl-button>
    }

    @if (deleteMode) {
      <pearl-button icon="delete" type="alternative" mode="warning" (click)="deleteEntity()"> Confirm </pearl-button>
      <pearl-button icon="cancel" mode="warning" (click)="leaveDeleteMode()"> Cancel </pearl-button>
    }

    @if (advancedFieldButtonToShow() == 1) {
      <pearl-button
        type="alternative"
        class="show-advanced-fields-button"
        mode="warning"
        icon="visibility"
        (click)="toggleAdvancedFields(true)">
        Show advanced fields
      </pearl-button>
    }

    @if (advancedFieldButtonToShow() == -1) {
      <pearl-button
        type="alternative"
        icon="visibility_off"
        class="hide-advanced-fields-button"
        (click)="toggleAdvancedFields(false)">
        Hide advanced fields
      </pearl-button>
    }

    @if (showDuplicate) {
      <pearl-button type="alternative" icon="duplicate" (click)="duplicate()"> Duplicate </pearl-button>
    }

    @if (showSave) {
      <pearl-button type="primary" icon="save" (click)="saveAndDoAfterSave()"> Save </pearl-button>
    }

    @if (showEdit) {
      <pearl-button type="alternative" icon="edit" (click)="enterEditMode()"> Edit </pearl-button>
    }

    <pearl-button type="icon" icon="close" matTooltip="Close" (click)="closeModal()" />
  </div>
</div>
<div class="entity-header-title">{{ title }}</div>

<mat-tab-group
  class="entity-tabs"
  animationDuration="0"
  [selectedIndex]="selectedTabIndex.value"
  disableRipple="true"
  [ngStyle]="{ height: coherencyErrors?.length ? 'calc(100% - 12em)' : 'calc(100% - 9em)' }"
  (selectedTabChange)="tabChanged($event)">
  @for (tab of tabs; track tab.tabId) {
    <mat-tab>
      <ng-template mat-tab-label>
        {{ tab.title }}
        <pearl-icon
          [icon]="tabInvalid(tab) ? 'warning' : 'checked'"
          [size]="24"
          [ngClass]="tabInvalid(tab) ? 'invalid-tab-icon' : 'valid-tab-icon'" />
        @if (getTabMatchCount(tab) != 0) {
          <div class="field-match-count">{{ getTabMatchCount(tab) }}</div>
        }
      </ng-template>
      <ng-template matTabContent>
        @if (tab.orderedFields) {
          <div class="entity-edit-form">
            @for (field of tab.orderedFields; track field.id) {
              @if (shouldShowField(field)) {
                <div
                  class="fields-column"
                  [ngClass]="{
                    'ordered-fields': field.type != 'collection' || field.formType == 'multi',
                    'multi-column': field.type != 'collection' || field.formType == 'multi',
                    'entity-collection': field.type == 'collection' && field.formType != 'multi',
                  }">
                  <entity-detail-field
                    #entityFields
                    [field]="field"
                    [entityAccessor]="entityAccessor"
                    [parent]="this" />
                </div>
              }
            }
          </div>
        }
        @if (tab.fieldsWithLayout) {
          <div class="custom-edit-form entity-edit-form">
            @for (line of tab.fieldsWithLayout; track $index) {
              <div
                [ngStyle]="{ display: fieldsInLine(line) ? 'flex' : 'none', 'max-width': getMaxLineWidth() + 'px' }"
                class="entity-edit-line">
                @for (field of line; track field.id) {
                  <div
                    [ngStyle]="{
                      display: shouldShowGroup(field.groups) && shouldShowField(field) ? 'initial' : 'none',
                      'min-width': '180px',
                    }"
                    [ngClass]="{
                      'multi-column':
                        (field.type != 'collection' || field.formType == 'multi') && field.type != 'coordinate',
                      'entity-collection': field.type == 'collection' && field.formType != 'multi',
                      coordinate: field.type == 'coordinate',
                    }">
                    <entity-detail-field
                      #entityFields
                      [field]="field"
                      [entityAccessor]="entityAccessor"
                      [parent]="this" />
                  </div>
                }
              </div>
            }
          </div>
        }
      </ng-template>
    </mat-tab>
  }
</mat-tab-group>

@if (coherencyErrors?.length) {
  <div class="errors-footer">
    <pearl-icon icon="error" [size]="24" />
    <span class="error-label">Invalid fields :</span>
    @for (error of coherencyErrors; track error; let last = $last) {
      @if (!last) {
        <span class="error-value">{{ error }},</span>
      } @else {
        <span class="error-value">{{ error }}</span>
      }
    }
  </div>
}

@if (footerFields.length) {
  <div class="entity-detail-footer">
    @for (field of footerFields; track field.id) {
      @if (getValue(field)) {
        <div class="footer-field">
          <mat-label class="footer-field-title">{{ field.footerLabel }}</mat-label
          >:
          <mat-label class="footer-field-value">
            @switch (field.type) {
              @case ("datetime") {
                {{ getFormattedDatetime(field) }}
              }
              @default {
                {{ getValue(field) }}
              }
            }
          </mat-label>
        </div>
      }
    }
  </div>
}

@if (loading) {
  <div class="fullscreen">
    <div class="loading-indicator">
      <mat-spinner></mat-spinner>
    </div>
  </div>
}
