import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Host,
  HostBinding, Injector, Input, NgZone, Output, ViewChild, ViewContainerRef, ViewEncapsulation, booleanAttribute,
  forwardRef, input, model } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

import { Dayjs } from 'dayjs';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';

import { DatetimePrecision } from '../shared/pearl-components/components/datepicker/datepicker-types';
import { ColorHelper } from '../helpers/color-helper';
import { AfterSave, CoordinateAxes, CoordinateType, EntityFieldDefinition, EntityInformation, FieldState,
  FieldValidityResult, OptionValue, SomeEntity } from '../helpers/types';
import { TimezoneService } from '../helpers/timezone.service';
import { DatabaseHelper } from './database-helper';
import { EntityDataAccessor } from './entity-data-accessor';
import { EntityDetailComponent } from './entity-detail';
import { IEntityDetailFieldParent } from './entity-detail-field-parent';
import { EntityDetailTableComponent } from './entity-detail-table';
import { EntityLinkedCollectionComponent } from './entity-linked-collection';
import { FileUploaderComponent } from './file-uploader';
import { InternetStatusService } from '../helpers/internet-status.service';
import { PearlButtonComponent, PearlDatepickerComponent, PearlDatepickerInput, PearlDatepickerToggleComponent,
  PearlFormFieldComponent } from '../shared/pearl-components';
import { DataAccessor } from './data-accessor';
import { OrderByPipe } from '../helpers/pipes';
import { SearchBarComponent } from '../shared/search-bar';
import { DescriptionButtonComponent } from '../shared/description-button';
import { getAdditionalData } from '../helpers/data-helpers';
import { ReportingField } from '../live-dpr/models/reporting-config-types';
import { ChipListComponent, ChipSelectionEvent } from '../shared/chip-list';
import { MapCoordsComponent } from '../map/map-coords';
import { codeMirrorExtraKeys, codeMirrorHintFunction } from '../helpers/codemirror-helper';

@Component({
  selector: 'entity-detail-field',
  templateUrl: './entity-detail-field.html',
  encapsulation: ViewEncapsulation.None,
  styleUrl: 'entity-detail-field.scss',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  providers: [ColorPickerService],
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgClass,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatOptionModule,
    NgFor,
    DescriptionButtonComponent,
    NgSwitchDefault,
    MatInputModule,
    MatAutocompleteModule,
    SearchBarComponent,
    PearlButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatCheckboxModule,
    forwardRef(() => EntityDetailTableComponent),
    EntityLinkedCollectionComponent,
    FileUploaderComponent,
    TextFieldModule,
    ColorPickerModule,
    AsyncPipe,
    OrderByPipe,
    ChipListComponent,
    PearlDatepickerComponent,
    PearlDatepickerInput,
    PearlDatepickerToggleComponent,
    PearlFormFieldComponent,
  ],
})
export class EntityDetailFieldComponent implements AfterContentInit, AfterViewInit {
  @Input()
  public field: EntityFieldDefinition;
  // Can be either entityDataAccessor or DataAccessor if it comes from the DPR
  @Input()
  public entityAccessor: EntityDataAccessor | DataAccessor;
  @Input()
  public parent: IEntityDetailFieldParent;
  @Input()
  public disabled: boolean = false;
  @Input()
  public readonly: boolean = false;
  @Input()
  public tooltip: string;

  /**
   * Set true to prevent the field from displaying the field validity error under the input.
   * For instance light edit fields display all the errors of all the fields grouped together and not on each
   * field.
   */
  @Input({ required: false })
  protected hideErrorHint: boolean = false;

  /**
   * Set true to hide the label of a date / datetime field
   */
  @Input()
  datetimeHideLabel: boolean = false;

  /**
   * Set true to render the date / datetime field with a bigger font
   */
  @Input()
  public datetimeBiggerFont: boolean = false;

  /**
   * Set true to style the date / datetime field to be displayed on a dark background
   */
  @Input()
  public datetimeDarkMode: boolean = false;

  @Input()
  public datetimePrecision: DatetimePrecision = 'second';

  public readonly showSearchBarIcon = input(true, { transform: booleanAttribute });

  // Dynamic id for <entity-detail-field> selector (base on fieldId)
  @HostBinding('attr.id')
  selectorId;

  public readonly small = input<boolean>(false);
  public readonly hasLabel = input<boolean>(true);

  private cdRef: ChangeDetectorRef;
  private ngZone: NgZone;

  public loaded: boolean = false;

  private readonly internetStatusService: InternetStatusService;

  // Timezone
  @Host()
  public timezoneService: TimezoneService;

  @ViewChild('linkedCollection')
  public $linkedCollectionComponent: EntityLinkedCollectionComponent;
  @ViewChild('integratedTable')
  public $integratedTableComponent: EntityDetailTableComponent;
  @ViewChild('fileCollection')
  public $fileComponent: FileUploaderComponent;

  @ViewChild('codemirrorContainer', { read: ViewContainerRef })
  public $codemirrorContainer: ViewContainerRef;

  /**
   * Currently only used to integrate details fields into DPR.
   * The purpose of this event is to notify when a change should be uploaded.
   * Please note that for certain field types (text/number),
   * this event is only triggered after the field has been focused out.
   * This is because we don't want to trigger an upload every time a character is entered.
   */
  @Output()
  fieldUpdated = new EventEmitter<void>();

  @Output()
  forceSync = new EventEmitter();

  public constructor(private dialog: MatDialog, injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
    this.ngZone = injector.get(NgZone);
    this.timezoneService = injector.get(TimezoneService);
    this.internetStatusService = injector.get(InternetStatusService);
  }

  public ngAfterContentInit(): void {
    this.selectorId = `detail-field-${this.field.id}`;
    this.initTransientField();
    // Run initial check
    this.fieldInvalid(this.field);
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.setupJsonField();
  }

  /**
   * Need to initialize fields that aren't available in database
   *
   * - for coordinate fields, where we don't save ddm coordinate, but we display them.
   *   We need to fill the attribute of this class oninit
   * - for timezone, we need to populate & filter timezones
   */
  public initTransientField(): void {
    // Special case: coordinate
    if (this.field.type === 'coordinate') {
      const value = this.getValue(this.field);
      // first initialization so we don't emit an update event
      this.setCoordinate(this.field, { target: { value } }, 'decimal', false);
    }
  }

  public get entity(): SomeEntity {
    return this.entityAccessor.entity;
  }

  /**
   * trigger parent components to notify them of an update (mainly used for DPR)
   * @param $event optionally pass event as parameter if potentially triggered by other events
   */
  public changeEnded($event = null): void {
    this.fieldUpdated.emit($event);
  }

  public setFile(field: EntityFieldDefinition): void {
    /*
     * Technically the value is already set by the file-uploader. But it is done directly without using the data
     * accessor and thus it doesn't run post-processing (like file formatting). So the display of the file field could
     * be empty.
     */
    this.entityAccessor.setAnything(field, this.entity[field.id]);
    this.changeEnded();
  }

  getNumber(field: EntityFieldDefinition) {
    return this.entityAccessor.getNumber(field);
  }

  getChoice(field: EntityFieldDefinition) {
    return this.entityAccessor.getChoice(field);
  }

  getValue(field: EntityFieldDefinition) {
    return this.entityAccessor.getValue(field);
  }

  /**
   * @param field
   * @returns title of chosen choice in a list
   */
  getSearchBarValue(field: EntityFieldDefinition): string {
    return this.getChoice(field)?.title ?? '';
  }

  private addToModifiedGroup(groups?: string[]): void {
    groups?.forEach(g => {
      if (this.entityAccessor.getModifiedGroups().indexOf(g) == -1) {
        this.entityAccessor.getModifiedGroups().push(g);
      }
    });
  }

  public valueChangeForEntityLinkedCollection(field: EntityFieldDefinition): void {
    field.fieldState.valid = null;
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    this.changeEnded();
  }

  public setSelection(field: EntityFieldDefinition, value): void {
    field.fieldState.valid = null;
    this.entityAccessor.setAnything(field, value);
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    this.changeEnded();
  }

  public setAnything(field: EntityFieldDefinition, event, changeEnded: boolean = true): void {
    field.fieldState.valid = null;
    this.entityAccessor.setAnything(field, event.target.value);
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    /**
     * In some case (e.g. text area) we don't want to trigger an update field event
     * for each character entered. In this case, the udpateEntityField event
     * will be triggered by this specific field focusOut
     */
    if (changeEnded) {
      this.changeEnded();
    }
  }

  public setNumber(field: EntityFieldDefinition, event, changeEnded: boolean = true): void {
    field.fieldState.valid = null;
    this.entityAccessor.setNumber(field, event, changeEnded);
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    /**
     * In some case (e.g. number field) we don't want to trigger an update field event
     * for each character entered. In this case, the udpateEntityField event
     * will be triggered by this specific field focusOut
     */
    if (changeEnded) {
      this.changeEnded();
    }
  }

  protected setDatetime(field: EntityFieldDefinition, event): void {
    field.fieldState.valid = null;
    switch (field.type) {
      case 'time':
      case 'datetime':
      case 'date': {
        this.entityAccessor.setDatetime(field, event.target.value);
        break;
      }
      case 'datetimeWithTimezone': {
        this.entityAccessor.setDatetimeWithTimezone(field, event);
        break;
      }
      default:
    }
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    this.changeEnded();
  }

  protected getDatetime(field: EntityFieldDefinition): Dayjs {
    switch (field.type) {
      case 'time':
      case 'datetime':
      case 'date': {
        return this.entityAccessor.getDatetime(field);
      }
      case 'datetimeWithTimezone': {
        return this.entityAccessor.getDatetimeWithTimezone(field);
      }
      default:
    }
  }

  protected getTimezone(field: EntityFieldDefinition): string | undefined {
    return this.entityAccessor.getTimezone(field);
  }

  completeCoordinateDegree(field: EntityFieldDefinition, event: any): void {
    if (event.target.value === '') return;
    const axe = field.title.toLowerCase() as CoordinateAxes;
    const coordinateDegree = MapCoordsComponent.completeDDM(event.target.value, axe);
    this.setCoordinate(field, coordinateDegree, 'degrees');
  }

  getCoordinateDegree(field: EntityFieldDefinition): string {
    return MapCoordsComponent.convertToDDM(
      this.getNumber(field) as number,
      field.title.toLowerCase().includes('longitude'),
    );
  }

  public readonly coordinate = model<string>('');

  public setCoordinate(
    field: EntityFieldDefinition,
    event: any,
    type: CoordinateType,
    emitFieldUpdated: boolean = true,
  ): void {
    field.fieldState.valid = null;
    switch (type) {
      case 'degrees': {
        this.setNumber(
          field,
          {
            target: {
              value: MapCoordsComponent.convertToDD(event, this.field.title.toLowerCase() as CoordinateAxes),
            },
          },
          emitFieldUpdated,
        );
        this.coordinate.set(this.getCoordinateDegree(field));
        break;
      }

      case 'decimal':
      default: {
        if (event.target.value === '-') {
          break;
        }

        /**
         * Here when user removes the last digit "2" from "12.2" we get "12", but we don't want to set
         * the number otherwise we would loose the '.' on the interface which in painfull for user
         */
        const parts = event.target.value.toString().split('.');
        if (parts.length === 2 && parts[1] === '') {
          break;
        }

        this.setNumber(field, event, emitFieldUpdated);
        this.coordinate.set(this.getCoordinateDegree(field));
        break;
      }
    }
  }

  isDMSCharacter(event: any): boolean {
    return MapCoordsComponent.DDM_INPUT[this.field.title.toLowerCase()].test(event.key);
  }

  isFileUploader(fieldType: string): boolean {
    return fieldType === 'files' || fieldType === 'file';
  }

  isNumericCharacter(event: any): boolean {
    return /-|\.|[0-9]|,/.test(event.key);
  }

  public setCheckbox(field: EntityFieldDefinition, event): void {
    field.fieldState.valid = null;
    this.entityAccessor.setAnything(field, event.checked);
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    /*
     * Force trigger fieldUpdated.
     * In some place where entity-detail-field is integrated (e.g DPR)
     * It's needed to correctly update component
     */
    this.changeEnded();
  }

  public setCheckboxRequired(field, checked?, text?): void {
    let value = this.entityAccessor.getValue(field);
    let emitFieldUpdated = false;
    // case where checkbox was modified
    if (checked !== undefined) {
      emitFieldUpdated = true;
      value = { value: checked, comment: value?.text ?? '' };
    }
    // case where text details was modified
    if (text !== undefined) {
      value = { value: value?.value ?? false, comment: text };
    }
    this.entityAccessor.setAnything(field, value);
    this.addToModifiedGroup(field.groups);
    // force trigger fieldUpdated.
    if (emitFieldUpdated) {
      this.changeEnded();
    }
  }

  /**
   * Set color only detect valid change color
   * we add this function to detect when color picker is reset
   * and we reset color value
   */
  public anyColorChange(field: EntityFieldDefinition, event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.value || input.value == '') {
      this.entityAccessor.setAnything(field, '');
    }
  }

  public setColor(field: EntityFieldDefinition, event): void {
    field.fieldState.valid = null;
    this.entityAccessor.setAnything(field, event);
    this.parent.checkCoherency();
    this.addToModifiedGroup(field.groups);
    this.cdRef.detectChanges();
  }

  public getLinkedEntityTitle(field: EntityFieldDefinition): string {
    return this.entityAccessor.getLinkedEntityTitle(field);
  }

  public getErrorMessage(field: EntityFieldDefinition, specialType: string = ''): string {
    if (field.configError) {
      return field.configError;
    }
    if (field.coherencyChecks) {
      const errors = [];
      for (const coherencyCheck of field.coherencyChecks) {
        if (!coherencyCheck.checkResult) {
          errors.push(coherencyCheck.message);
        }
      }
      if (errors.length) {
        return errors.join(', ');
      }
    }
    if (specialType === 'dms') {
      return this.coordinateFieldInvalid(field).msg;
    }

    return this.entityAccessor.fieldValidity(field, this.parent.MappedBy).msg;
  }

  public optionSelected(field: EntityFieldDefinition, option: OptionValue): void {
    this.entityAccessor.optionSelected(field, option);
    this.parent.checkCoherency();
    this.addToModifiedGroup(this.field.groups);
    this.changeEnded();
  }

  isChecked(field: EntityFieldDefinition) {
    const value = this.entityAccessor.getValue(field);
    return DatabaseHelper.isTrue(value);
  }

  /**
   * Called when user types in the searchable entity boxes
   */
  public inputValueChanges(field: EntityFieldDefinition, value: string): void {
    field.fieldState.valid = null;
    this.entityAccessor.inputValueChanges(field, value);
    this.parent.checkCoherency();
  }

  urlFromLink(field: EntityFieldDefinition): string {
    return `/dashboard/table/${field.class}/${(this.entityAccessor as EntityDataAccessor).getLinkedEntityId(field)}`;
  }

  getOptionName(option: OptionValue): string {
    if (!option) return '';
    return option.title;
  }

  showAll(event, field: EntityFieldDefinition) {
    event.stopPropagation();
    this.entityAccessor.showAllOptionForField(field);
  }

  private formatJson(field: EntityFieldDefinition): void {
    const json = this.entityAccessor.getValue(field);
    let formatted;
    try {
      formatted = JSON.stringify(JSON.parse(json), null, 2);
    } catch (e) {
      return;
    }
    this.entityAccessor.setAnything(field, formatted);
  }

  private async setupJsonField(): Promise<void> {
    if (this.field.type !== 'json') return;

    await Promise.all([
      import('codemirror/addon/edit/matchbrackets'),
      import('codemirror/addon/edit/closebrackets'),
      import('codemirror/mode/javascript/javascript'),
      import('codemirror/addon/fold/foldgutter'),
      import('codemirror/addon/fold/brace-fold'),
      import('codemirror/addon/lint/json-lint'),
      import('codemirror/addon/lint/lint'),
      import('codemirror/addon/search/search'),
      import('codemirror/addon/search/jump-to-line'),
      import('codemirror/addon/search/searchcursor'),
      import('codemirror/addon/search/match-highlighter'),
      import('codemirror/addon/dialog/dialog'),
      import('codemirror/addon/hint/show-hint'),
    ]);

    /*
     * CodeMirror has been added to the project to better the json editing experience among other things,
     * it highlights errors, auto closes brackets, and matches brackets, it is also highly customizable
     */
    const codeMirrorOptions = {
      lineNumbers: true,
      theme: 'eclipse',
      mode: 'application/ld+json',
      hintOptions: { hint: codeMirrorHintFunction },
      lint: true,
      autoCloseBrackets: true,
      matchBrackets: true,
      foldGutter: true,
      gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
      extraKeys: codeMirrorExtraKeys,
    };

    /*
     * codeMirror linter must override the global jsonlint in order to be display error messages in the editor,
     * we have to define the jsonlint property on the window object first, jsonlint-mod is a fork of jsonlint that
     * allows to override the global jsonlint see https://www.npmjs.com/package/jsonlint-mod
     */
    await import('jsonlint-mod').then(jsonlint => window['jsonlint'] = jsonlint.default);
    const ngxCodeMirror = await import('@ctrl/ngx-codemirror');
    this.$codemirrorContainer.clear();
    const cmComponent = this.$codemirrorContainer.createComponent(ngxCodeMirror.CodemirrorComponent);
    cmComponent.instance.options = codeMirrorOptions;
    cmComponent.instance.value = this.getValue(this.field);
    cmComponent.instance.focusChanged = () => {
      this.formatJson(this.field);
      cmComponent.instance.codeMirror.setValue(
        this.getValue(this.field) ? this.getValue(this.field) : '{\n\n}',
      );
      cmComponent.instance.codeMirror.setCursor({ line: 1, ch: 3 });
    };
    cmComponent.instance.codemirrorValueChanged = (cm, change) => {
      this.setSelection(this.field, cm.getValue());
    };
    this.cdRef.detectChanges();
  }

  public onChildElementChanges(afterSave: AfterSave) {
    if (!this.testIfUserRightsContain('write')) {
      this.parent.pushAfterSave(afterSave);
    }
    this.cdRef.detectChanges();
  }

  /**
   * Checks whether this field should show the error hint below the input
   */
  protected showErrorHint(field: EntityFieldDefinition, specialType: string = ''): boolean {
    // Check fieldInvalid before hideErrorHint because fieldInvalid updates the state.
    return this.fieldInvalid(field, specialType) && !this.hideErrorHint;
  }

  /**
   * Returns if we should show the (Spinergie value: ...) below an input in the entity form
   * We check that, for this entity and field, we have an original value (store in __additionalData), and that
   * this original value is different than the current one.
   */
  public showSpinValueHint(field: EntityFieldDefinition): boolean {
    // if entityAccessor not link to entity we never show spin hint value
    if (!(this.entityAccessor instanceof EntityDataAccessor)) {
      return false;
    }

    if (
      this.parent.prefill
      && this.parent.prefill.__scenarioHint
      && (field.id in this.parent.prefill)
      && !field.hideHint
    ) {
      return true;
    }
    if (!DatabaseHelper.hasOverrideReadRight(this.entityAccessor.definition)) {
      return false;
    }
    const spinValue = this.spinRawValue(field);
    if (spinValue === undefined) {
      return false;
    }

    const rawValue = this.entityAccessor.getValueByFieldId(field.id);
    if (field.type === 'boolean' || field.type === 'checkbox') {
      if ((spinValue === 0 || spinValue === false) && (rawValue === true || rawValue === 1)) {
        return true;
      } else if ((spinValue === 1 || spinValue === true) && (rawValue === false || rawValue === 0)) {
        return true;
      } else {
        return false;
      }
    }

    if (spinValue != null && rawValue != null) {
      return (spinValue as any).toString() !== rawValue.toString();
    }
    return spinValue !== rawValue;
  }

  public getSpinValueHintClass(field: EntityFieldDefinition) {
    const entityValue = this.entityAccessor.getValueByFieldId(field.id);
    return {
      'grey-value-hint': this.parent.prefill
        && (field.id in this.parent.prefill)
        && this.parent.prefill[field.id] == entityValue,
    };
  }

  public spinRawValue(field: EntityFieldDefinition): unknown {
    return this.entityAccessor.getValueByFieldId(field.id, true);
  }

  public spinFormattedValue(field: EntityFieldDefinition & { id: keyof SomeEntity }): string {
    return DatabaseHelper.formatFieldValue(field, getAdditionalData(this.entity, field.id), 'originalValue');
  }

  public getSpinValueHint(field: EntityFieldDefinition): string | null {
    if (!this.showSpinValueHint(field)) {
      return null;
    }
    /*
     * PB: Currently we used prefill and hit with prefill only with tenders linked to scenario
     * The hint here is used to notify the user if the scenario value is different or not from the most likely scenario
     * @TODO: if prefill is used for other usecases the prefill would have to be dynamic
     */
    if (this.parent.prefill && field.id in this.parent.prefill) {
      const entityValue = this.entityAccessor.getValueByFieldId(field.id);
      if (this.parent.prefill[field.id] == entityValue) {
        return 'Same as most likely scenario';
      }
      let formattedFieldValue = DatabaseHelper.formatFieldValue(field, this.parent.prefill);
      if (field.type == 'boolean' || field.type == 'checkbox') {
        formattedFieldValue = this.parent.prefill[field.id] ? 'Yes' : 'No';
      }
      return `Most likely scenario value is: ${formattedFieldValue}`;
    }

    const spinRawValue = this.spinRawValue(field);
    if (spinRawValue === null || spinRawValue === undefined) {
      return 'Spinergie value: no public information';
    }

    if (field.type === 'boolean' || field.type === 'checkbox') {
      if (spinRawValue === 0) {
        return 'Spinergie value: No';
      } else {
        return 'Spinergie value: Yes';
      }
    }

    const formattedFieldValue = this.spinFormattedValue(field as EntityFieldDefinition & { id: keyof SomeEntity });
    return `Spinergie value: ${formattedFieldValue}`;
  }

  public async afterParentInit() {
    this.loaded = true;
    this.cdRef.detectChanges();
    await this.$linkedCollectionComponent?.parentEntityFullyLoaded();
    // we have to notify the file component to load its files
    await this.$fileComponent?.loadFiles();
    this.$integratedTableComponent?.afterUpdate();
    this.entityAccessor.afterUpdate(this.field.id);
  }

  public refreshUI() {
    this.cdRef.detectChanges();
  }

  openLinkedEntity(field: EntityFieldDefinition, event: MouseEvent) {
    if (event) {
      if (DatabaseHelper.isSpecialClick(event)) {
        return;
      }
    }
    if (!this.isLinkable(field)) {
      return;
    }

    const entity = {};

    /*
     * idField on the target entity will be id in was majority of cases or maybe
     * country iso code or somesthing else so we have to be generic
     */
    entity[field.idField] = this.entityAccessor.getValueByFieldId(field.id);

    const entityInformation: EntityInformation = {
      entity: entity,
      entityName: field.class,
      editMode: false,
      idField: field.idField,
      closeAfterSave: false,
      afterSaveAction: null,
      creation: false,
      afterCloseAction: null,
      layerId: null,
    };

    this.openEntityDialog(entityInformation);
  }

  public isLinkable(field: EntityFieldDefinition): boolean {
    return (
      this.isFieldReadonly(field)
      && field.link
      && this.entityAccessor.getValueByFieldId(field.id) !== null
    );
  }

  public getFieldPlaceholder(field: EntityFieldDefinition): string {
    return field.placeholder ? field.placeholder : field.title;
  }

  public fieldInvalid(field: EntityFieldDefinition, specialType: string = ''): boolean {
    if (!field.fieldState) {
      field.fieldState = {} as FieldState;
    }
    if (
      field.configError
      || field.coherencyChecks
        && field.coherencyChecks.filter(c => c.checkResult == false).length
    ) {
      field.fieldState.valid = false;
      return true;
    }
    if (specialType === 'dms') {
      field.fieldState.valid = this.coordinateFieldInvalid(field).valid;
      return !field.fieldState.valid;
    }

    field.fieldState.valid = this.entityAccessor.fieldValidity(field, this.parent.MappedBy).valid;
    return !field.fieldState.valid;
  }

  public coordinateFieldInvalid(field: EntityFieldDefinition): FieldValidityResult {
    let result = { valid: true, msg: '' };
    const coordinateType = field.title.toLowerCase();

    const fieldSize = coordinateType === 'longitude' ? 10 : 9;
    const coordinateDegree = this.getCoordinateDegree(field);

    if (!coordinateDegree) {
      if (field.req) {
        return { valid: false, msg: `${field.title} is required` };
      }
      return result;
    }

    if (
      !MapCoordsComponent.DDM_REGEXP[coordinateType].test(coordinateDegree)
      || coordinateDegree?.length > fieldSize
    ) {
      return {
        valid: false,
        msg: 'Invalid format',
      };
    }

    const length = coordinateDegree.length - 1;
    const coordinateDegreeLength = length >= 0 ? length : 0;

    if (
      !(coordinateDegree.charAt(coordinateDegreeLength) === 'E')
      && !(coordinateDegree.charAt(coordinateDegreeLength) === 'W')
      && coordinateType === 'longitude'
    ) {
      result = {
        valid: false,
        msg: 'Should end with E or W',
      };
    }

    if (
      !(coordinateDegree.charAt(coordinateDegreeLength) === 'N')
      && !(coordinateDegree.charAt(coordinateDegreeLength) === 'S')
      && coordinateType === 'latitude'
    ) {
      result = {
        valid: false,
        msg: 'Should end with N or S',
      };
    }

    return result;
  }

  public preventDefaultIfNormalClick(event: MouseEvent): boolean {
    if (!DatabaseHelper.isSpecialClick(event)) event.preventDefault();
    // we return true so this function can be used we other function with && separator
    return true;
  }

  public getFieldClass(field: EntityFieldDefinition): object {
    const classes = {
      'has-error': this.fieldInvalid(field),
      'read-only': this.isFieldReadonly(field),
      'linked-entity': (field.type === 'entity' || field.type === 'client_entity') && this.isLinkable(field),
    };
    return classes;
  }

  isFieldDisabled(field: EntityFieldDefinition): boolean {
    return (
      !field.editable
      || this.parent.isGroupDisabled(field.groups)
      || this.disabled
      || this.readonly
      || (field?.calc && field.calc !== null)
    );
  }

  isFieldReadonly(field: EntityFieldDefinition) {
    return (
      !this.parent.editMode
      || !field.editable
      || this.parent.isGroupDisabled(field.groups)
      || this.readonly
      || (field?.calc && field.calc !== null)
    );
  }

  canAddNew(field: EntityFieldDefinition) {
    return this.parent.editMode && field.addNew;
  }

  public testIfUserRightsContain(right: string): boolean {
    return (this.entityAccessor as EntityDataAccessor).definition.userEntityRights.includes(right);
  }

  public getColorPickerPresetColors() {
    return ColorHelper.colors;
  }

  public isChipSelected(option: OptionValue): boolean {
    return this.entityAccessor.getChoice(this.field)?.id === option.id;
  }

  public chipSelectionChanged(selectionEvent: ChipSelectionEvent, field: EntityFieldDefinition): void {
    this.addToModifiedGroup(field.groups);
    if (this.isFieldReadonly(field)) {
      if (selectionEvent.event.selected && !this.isChipSelected(selectionEvent.chip)) {
        selectionEvent.event.source.deselect();
      }
      this.cdRef.detectChanges();
      return;
    }
    if (!selectionEvent.event.selected) {
      if (selectionEvent.chip.id == this.getChoice(field)?.id) {
        this.optionSelected(this.field, null);
      } else if (!this.getChoice(field)) {
        selectionEvent.event.source.selected = true;
      }
      return;
    }
    this.optionSelected(this.field, selectionEvent.chip);
  }

  /*
   * Used as the aftersave for a new OneToMany relationship
   * The new entity must be saved after its parents
   */
  afterAddNew(field: EntityFieldDefinition): (data: AfterSave) => void {
    return (data: AfterSave) => {
      const entity = data.entity;

      // Generate an option value with a fake id to be referenced in the frontend (entity select options)
      const newOptionValue: OptionValue = {
        id: `new_${field.id}_${new Date().getTime()}`,
        title: entity['title'],
      };

      // Add the new option to the options list
      field.values.push(newOptionValue);

      // Select the new option
      this.entityAccessor.optionSelected(field, newOptionValue);

      // Place the new value to create for when we save
      this.entity['__to_construct_for_' + field.id] = entity;

      /*
       * Force the update of the added field now before the refresh of the whole UI
       * Otherwise it can take up to a few seconds before the field is updated which feels buggy
       */
      this.cdRef.detectChanges();
    };
  }

  openEntityDialog(entityInformation: EntityInformation) {
    const dialogRef = this.dialog.open(EntityDetailComponent, {
      width: '95%',
      height: '85%',
      disableClose: true,
      panelClass: 'entity-dialog',
      autoFocus: false,
    });

    this.ngZone.run(() => {
      const component = dialogRef.componentInstance;
      component.setEntityInformation(entityInformation);
    });

    return dialogRef;
  }

  addNew(field: EntityFieldDefinition, event: Event) {
    const entityInformation: EntityInformation = {
      entityName: field.class,
      editMode: true,
      entity: null,
      idField: field.idField,
      linkBy: { linkType: 'OneToMany', parentWillSave: true },
      closeAfterSave: true,
      afterCloseAction: () => this.cdRef.detectChanges(),
      afterSaveAction: this.afterAddNew(field),
      creation: true,
      layerId: null,
    };

    this.openEntityDialog(entityInformation);
    event.stopPropagation();
  }

  public checkboxDetailsHintVisible(checked: boolean, field: EntityFieldDefinition) {
    if (!checked || this.hideErrorHint) {
      return false;
    }
    const fieldValue = this.getValue(field);
    return !fieldValue || !fieldValue.comment;
  }

  public getCoordinatePlaceHolder(field: EntityFieldDefinition): string {
    return `${
      this.field.title.toLocaleLowerCase() === 'longitude'
        ? '0'
        : ''
    }17°22.12${
      field.title.toLowerCase() === 'latitude'
        ? 'N'
        : 'E'
    }`;
  }

  public getNumberToLocaleString(): string {
    return '17.37';
  }

  private getSyncableField(field: ReportingField): ReportingField {
    if (field.reportingFieldType === 'composite') {
      return field.compositeChildrenFields.find(childField => this.isSyncButtonDisplayed(childField));
    }
    return field;
  }

  public onClickSync($event: any, field: ReportingField): void {
    $event.stopPropagation();
    const fieldToEmit = this.getSyncableField(field);
    this.forceSync.emit(fieldToEmit);
  }

  /**
   * This is the tooltip of the force sync button for a sync field
   */
  public getSyncFieldTooltip(field: ReportingField): string {
    const syncValue = this.getSyncableChoice(this.getSyncableField(field));
    // If we can force sync
    return `Use suggested value: ${syncValue}`;
  }

  public getSyncableChoice(field: ReportingField): string | number {
    return field.type === 'choice'
      ? field.values.find(v => v.id === field.syncable?.syncableValue)?.title
      : field.syncable.syncableValue;
  }

  /**
   * If a field is composite, all children fields in order of priority are tested.
   *  - If one field with highest priority than selected child field has a syncable value then we return true,
   *  - Otherwise, if the selected child value matches its synced value we return false
   *  - Otherwise, we test the other fields with a lower priority and return true if any has a syncable value
   * @param compositeField composite field to test
   * @returns boolean
   */
  private isCompositeSyncButtonDisplayed(compositeField: ReportingField): boolean {
    if (!compositeField?.compositeChildrenFields?.length) {
      return false;
    }
    const compositeFieldValue = this.getChoice(compositeField);
    for (const childrenField of compositeField.compositeChildrenFields) {
      if (this.isSyncButtonDisplayed(childrenField)) {
        return true;
      }
      if (
        compositeFieldValue?.childFieldId === childrenField.id
        && (childrenField?.syncable && childrenField?.syncable.syncableValue === compositeFieldValue?.id)
      ) {
        return false;
      }
    }
    return false;
  }

  public isSyncButtonDisplayed(field: ReportingField): boolean {
    // compositeField has it own sync field visibility condition
    if (field?.reportingFieldType === 'composite') {
      return this.isCompositeSyncButtonDisplayed(field);
    }
    const isFieldSyncable = !!(
      !this.isFieldDisabled(field)
      && field?.syncable
      && this.internetStatusService.isOnline()
    );
    const hasSyncButtonNoAction = !!(
      field?.syncable && (
        field.syncable.syncableValue === this.getNumber(field)
        || field.syncable.syncableValue === null
        || (!!field.values && !field.values.map(e => e.id).includes(field.syncable.syncableValue))
      )
    );
    return isFieldSyncable && !hasSyncButtonNoAction;
  }
}
