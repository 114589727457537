import { ChangeDetectorRef, Directive, EventEmitter, Injector, Input, Output, QueryList, ViewChildren,
  inject } from '@angular/core';

import { uniqBy } from 'lodash-es';

import { FieldComponent } from './field';
import { CheckboxExclude, SpinFiltersCheckboxComponent } from './spin-checkbox/spin-filters-checkbox.component';
import { IntervalComponent } from './interval';
import { DoubleDateComponent } from './doubledate';
import { FieldSettings, Fieldset, FieldsetUpdate, FilterApplied, IntervalOrNull, LayerFilter } from '../helpers/types';
import { NumberComponent } from './number';
import { Config } from '../config/config';
import { FilterHelper } from './filter-helper';
import { RawDataPoint } from '../graph/chart-types';
import { RouterService } from '../helpers/router.service';
import { DistanceToEntityComponent } from './distance-to-entity/distance-to-entity.component';

@Directive()
export abstract class FieldsetComponent {
  public config: Config;
  public injector: Injector;
  public routerService = inject(RouterService);

  @Input()
  fieldset: Fieldset;
  @Input()
  showCount: boolean = true;
  @Output()
  onchange = new EventEmitter<FilterApplied>();

  @ViewChildren(FieldComponent)
  $fields: QueryList<FieldComponent>;
  @ViewChildren(SpinFiltersCheckboxComponent)
  $checkboxes: QueryList<SpinFiltersCheckboxComponent>;
  @ViewChildren(IntervalComponent)
  $intervals: QueryList<IntervalComponent>;
  @ViewChildren(DoubleDateComponent)
  $doubledates: QueryList<DoubleDateComponent>;
  @ViewChildren(NumberComponent)
  $numbers: QueryList<NumberComponent>;
  @ViewChildren(DistanceToEntityComponent)
  $distanceToEntityFilters: QueryList<DistanceToEntityComponent>;

  /* Hold the state of current filters applied on this fieldset. */
  public appliedFilters: LayerFilter = {};

  constructor(injector: Injector, private cdRef: ChangeDetectorRef) {
    this.config = injector.get(Config);
  }

  /**
   * Go over all fields that are part of the FieldsetUpdate, look them up in the current fieldset
   * and if found we populate them with the values received inside the update. Unlike standard populate
   * which takes the whole dataset, this takes only values to be added, or ranges to be extended.
   * @param fieldsetUpdate
   */
  public populateWithValues(fieldsetUpdate: FieldsetUpdate): void {
    for (const key in fieldsetUpdate) {
      const field = this.$fields.find(f => f.field.id === key);
      if (!field) continue;
      const valuesToBeAdded = fieldsetUpdate[key].values;
      let uniqueValues = valuesToBeAdded;
      if (field.field.values) {
        uniqueValues = uniqBy([...valuesToBeAdded, ...field.field.values], d => d.value);
      } else {
        uniqueValues = valuesToBeAdded;
      }
      field.field.values = uniqueValues;
      field.update(true);
    }
  }

  public forEachField(fn: ($fieldComponent: any) => unknown): void {
    [this.$fields, this.$doubledates, this.$numbers, this.$intervals].forEach($fieldset => {
      $fieldset.forEach($field => fn($field));
    });
  }
  /**
   * Populate every fields (filters) of the fieldset with the given data
   *
   * @param data                  Data used to populate the fields (filters)
   * @param keepExisting          Whether to keep existing filter values or to populate only with the given `data`
   */
  public populate(
    data: readonly RawDataPoint[],
    keepExisting: boolean,
  ): void {
    for (const $fieldset of [this.$fields, this.$doubledates, this.$numbers, this.$intervals]) {
      for (const $field of $fieldset) {
        FilterHelper.populateField($field.field, data, keepExisting);
        $field.update(true);
      }
    }
  }

  /**
   * Set the filters on a fieldset from the outside - previously set filters are cleared.
   */
  public set(filters: LayerFilter, fire: boolean = true): void {
    this.appliedFilters = FilterHelper.getFiltersApplicableOnFieldsets(filters, [this.fieldset]);
    this.fillStandardFilters(this.appliedFilters);
    if (fire) {
      this.onchange.emit(null);
    }
  }

  protected fillStandardFilters(filters: LayerFilter): void {
    // go over all fields inside this fieldset and set the values of the visual elements
    this.$fields.forEach($f => {
      if (filters[$f.field.id]) {
        if (filters[$f.field.id].values?.length) {
          $f.set(filters[$f.field.id].values.slice(0), false);
        } else {
          delete filters[$f.field.id];
        }
      }
    });

    this.$checkboxes.forEach($c => {
      if (filters[$c.field.id]) {
        const checkBoxValue = (filters[$c.field.id].values as CheckboxExclude[]).slice(0)[0];
        $c.setFilterValue(checkBoxValue ?? false);
      }
    });

    this.$intervals.forEach($i => {
      if (filters[$i.field.id]) {
        $i.setFilterStr(filters[$i.field.id].values.slice(0), false);
      }
    });

    this.$doubledates.forEach($d => {
      if (filters[$d.field.id]) {
        const filterValue = filters[$d.field.id].values as IntervalOrNull;
        $d.setFilter(filterValue, false);
      }
    });

    this.$numbers.forEach($d => {
      if (filters[$d.field.id]) {
        $d.setFilter(filters[$d.field.id], false);
      }
    });

    this.$distanceToEntityFilters.forEach($d => {
      if (filters[$d.field.id]) {
        $d.setFilter(filters[$d.field.id]);
      }
    });
  }

  /**
   * Gets the count of applied filters.
   * Checkboxes only count if they are checked.
   */
  public get count(): number {
    let count = 0;

    Object.keys(this.appliedFilters).forEach(filterId => {
      const filter = this.appliedFilters[filterId];
      if (FilterHelper.hasCheckboxBehavior(filter.filterType) && !filter.values[0]) {
        return;
      }

      count++;
    });

    return count;
  }

  public clearAndSet(filters: LayerFilter, fire = true): void {
    this.clear(false);
    this.set(filters, fire);
  }

  /**
   * This method reloads visually the values from the underlying fields.
   * It assumes that the underlying field object has the values already loaded:
   * - intervals should have already min and max determined
   * - multi - field.values is already pre-loaded with distinct values
   */
  public reloadAfterConfig(): void {
    this.$fields.forEach($field => $field.update());
    this.$intervals.forEach($interval => $interval.update());
    this.$doubledates.forEach($doubledate => $doubledate.update());
  }

  /**
   * Clear fields (not reset to their default values)
   * @param fire
   */
  public clear(fire: boolean): void {
    this.appliedFilters = {};
    this.$fields.forEach($field => $field.clear(false));
    this.$checkboxes.forEach($checkbox => $checkbox.clear());
    this.$intervals.forEach($interval => $interval.clear(false));
    this.$doubledates.forEach($doubledate => $doubledate.clear(false));
    this.$numbers.forEach($number => $number.clear());
    this.$distanceToEntityFilters.forEach($filter => $filter.clear());

    if (fire) {
      this.onchange.emit(null);
    }
  }

  /**
   * Put all fields of this fieldset into their default state
   */
  public reset(fire: boolean = true): void {
    this.appliedFilters = FilterHelper.getFieldsetsDefaultFilters([this.fieldset]);
    this.$fields.forEach($field => $field.reset(false));
    this.$checkboxes.forEach($checkbox => $checkbox.reset());
    this.$intervals.forEach($interval => $interval.reset(false));
    this.$doubledates.forEach($doubledate => $doubledate.reset(false));
    this.$numbers.forEach($number => $number.reset());
    this.$distanceToEntityFilters.forEach($filter => $filter.reset());

    if (fire) {
      this.onchange.emit(null);
    }
  }

  public detectChanges() {
    this.cdRef.detectChanges();
  }

  public legendOnclick(): void {
    this.fieldset.expanded = !this.fieldset.expanded;
    this.cdRef.detectChanges();
  }

  public onbubblingchange(filter: FilterApplied): void {
    if (!filter.active) {
      delete this.appliedFilters[filter.id];
    } else {
      this.appliedFilters[filter.id] = filter;
    }
    this.cdRef.detectChanges();
    this.onchange.emit(filter);
  }

  public getLegendClass() {
    const classes = {
      active: this.count > 0,
    };
    return classes;
  }

  public getFieldsetFieldsStyle() {
    const style = {};

    if (!this.fieldset.expanded) {
      style['display'] = 'none';
    }
    return style;
  }

  public isFieldHidden(field: FieldSettings): boolean {
    return field.visible === false || field.filteredOut;
  }
}
