import { EventEmitter, Injectable, Optional, SkipSelf } from '@angular/core';

import { IHaveTimezone } from './config-types';
import { DateTimezone } from './types';

@Injectable({ providedIn: 'root' })
export class TimezoneService {
  // Timezone & caller
  public name: string = 'root';
  public disabled: boolean = false;
  private _timezone: DateTimezone = 'inherit';
  private _overridenUserTimezone: DateTimezone;

  /** Timzone config that can be passed: project, dashboard or component config */
  private _timezoneConfig: IHaveTimezone | undefined;

  /** Local timezone - either "browser-local" or chosen in the zone picker */
  private _localTimezone: DateTimezone;

  // Event
  public onChange = new EventEmitter<DateTimezone>();

  /**
   * @constructor
   *
   * @param {TimezoneService} parent    Parent timezone service (if any)
   */
  constructor(@SkipSelf() @Optional() public parent: TimezoneService = null) {
    // Repeat
    if (this.parent) {
      this.parent.onChange.subscribe(() => this.onChange.emit(this.timezone));
    }
  }

  /** */
  public reset(): void {
    if (!this.parent) return;
    this._timezoneConfig = undefined;
    this._timezone = 'inherit';
  }

  /**
   * Get timezone
   *
   * @return  {DateTimezone}  Scoped or global timezone
   */
  public get timezone(): DateTimezone {
    return this.getZone(this.timezoneConfig);
  }

  public getZone(config: IHaveTimezone): DateTimezone {
    // if some config was provided
    if (config && config.timezone) {
      if (config.timezone === 'local') {
        return this.localTimezone;
      }
      return config.timezone;
    }

    // console.info('Reading timezone from', this.name, this._timezone);
    return this._timezone === 'inherit' ? this.parent.timezone : this._timezone;
  }

  /**
   * Get timezone name (human)
   *
   * @return  {string}  Human timezone name
   */
  public get title(): string {
    return this.human(this.timezone);
  }

  /**
   * Set the local timezone. Everything in the app which shoud be "localized"
   * will use this zone. Only the root should have the timezone set
   *
   * @param  {DateTimezone}  Scoped timezone
   * @return {void}
   */
  public setLocalTimezone(timezone: DateTimezone, isTimezoneForced: boolean = false): void {
    // If timezone is forced by a dashboard we preserve the user timezone to be able to reset this value
    this._overridenUserTimezone = isTimezoneForced ? this._localTimezone : null;
    this._localTimezone = timezone;
    if (this.parent) {
      console.error('Setting app local timezone non-root timezone service - not good');
    }
    /**
     * If user timezone has been overriden (e.g. for reporting) event isn't emitted because
     * The dashboard is already in the timezone & we don't want to overwrite user preferences
     */
    if (isTimezoneForced) {
      return;
    }
    this.onChange.emit(this.localTimezone);
  }

  /**
   * Call when leaving a dashboard w/ forcedTimezone (e.g. reporting), to restore the previous tz
   */
  public restoreUserTimezone(): void {
    this.setLocalTimezone(this._overridenUserTimezone);
    this.disabled = false;
  }

  public get localTimezone(): DateTimezone {
    /*
     * if we are the root timezone service either some local zone was chosen
     * or it was no yet set and we are "browser-local"
     */
    if (!this.parent) {
      if (this._localTimezone !== null && this._localTimezone !== undefined) {
        return this._localTimezone;
      } else {
        return 'local';
      }
    }

    return this.parent.localTimezone;
  }

  public set timezoneConfig(timezoneConfig: IHaveTimezone) {
    this._timezoneConfig = timezoneConfig;
    if (this._timezoneConfig?.timezone) {
      this._timezone = this._timezoneConfig.timezone;
    } else {
      /*
       * If no timezone is set in config, revert to 'inherit'
       * to avoid that components with timezone affect those who don't
       * (like component-page, which is re-used)
       */
      this._timezone = 'inherit';
    }
  }

  public get timezoneConfig(): IHaveTimezone | undefined {
    return this._timezoneConfig;
  }

  /* * Static * */

  /**
   * Format timezone
   *
   * @param  {DateTimezone} code  Timezone code
   * @return {string}             Human-readable timezone
   */
  public human(code: DateTimezone): string {
    if (code === 'local') return 'Local timezone';
    if (code === 'utc') return 'UTC';
    if (code === 'dprDay') return 'Project day';
    if (code === 'none') return '';
    if (code === 'inherit') return this.human(this.timezone);
    return code;
  }
}
