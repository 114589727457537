import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject } from 'rxjs';

import { IdentityItem, MultiOption } from '../helpers/types';
import { BaseMultiFilter } from './multi-interface';
import { SearchBarComponent } from '../shared/search-bar';

const toIdentityItem: (v: MultiOption<null>) => IdentityItem = ({ value: id, title }) => ({
  id,
  title,
});

const fromIdentityItem: (v: IdentityItem) => MultiOption<null> = ({ id: value, title }) => ({
  title,
  value: value as string,
});

@Component({
  selector: 'spin-filters-single',
  templateUrl: 'single.html',
  standalone: true,
  imports: [SearchBarComponent],
})
export class SingleComponent implements BaseMultiFilter<string> {
  @Input()
  placeholder: string;

  @Output()
  selected = new EventEmitter<MultiOption<null>[]>();
  @Output()
  unselected = new EventEmitter<string>();

  private _selection: MultiOption<null> = null;

  public readonly small = input<boolean>(true);

  public readonly updateData$ = new Subject<MultiOption<null>[]>();
  public readonly toggle$ = new Subject<{ payload: any[]; chosen?: boolean }>();

  public showSelectAll = false;

  public searchItemsAvailable: IdentityItem[] = [];

  constructor() {
    this.updateData$.pipe(takeUntilDestroyed()).subscribe(data => {
      this.searchItemsAvailable = data.map(toIdentityItem);
    });

    this.toggle$.pipe(takeUntilDestroyed()).subscribe(({ payload, chosen }) => {
      this.choose(payload, chosen);
    });
  }

  get selection(): string {
    return this._selection?.title || '';
  }

  choose(raw: any[], chosen: boolean): void {
    if (!chosen) {
      this._selection = null;
      return;
    }

    let value = raw[0];
    /** The full option might already be given which is useful when the field is not yet populated */
    if (!value.title) {
      value = this.searchItemsAvailable
        .map(fromIdentityItem)
        .find(({ value: v }) => String(value) === String(v));
    }
    if (!value) {
      return;
    }
    this._selection = value;
  }

  optionSelected(event: IdentityItem): void {
    if (event === null) return;

    if (this._selection) {
      this.unselected.emit(this._selection.value);
    }

    const option = fromIdentityItem(event);
    this.choose([option.value], true);
    this.selected.emit([option]);
  }

  clear(): void {
    if (this._selection) {
      this.unselected.emit(this._selection.value);
      this.choose(null, false);
    }
  }
}
