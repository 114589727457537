<aside #child tabindex="-1" class="spin-tooltip complex-tooltip" [ngStyle]="{ width: getTooltipWidth() }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div *ngIf="title" [ngSwitch]="title.type" class="modal-header">
        <i class="tooltip-close" (click)="forceHide()">×</i>
        <h4 *ngSwitchCase="'date'" class="modal-title">{{ dateTimeFormat(getTitle(), title.format) }}</h4>
        <h4 *ngSwitchCase="'datetime'" class="modal-title">{{ dateTimeFormat(getTitle(), title.format) }}</h4>
        <h4 *ngSwitchDefault class="modal-title">
          <mat-icon
            *ngIf="getTitleIcon(values) as titleIcon"
            [ngClass]="titleIcon.css"
            [ngStyle]="{ color: titleIcon.color }"
            >{{ titleIcon.name }}</mat-icon
          >{{ getTitle() }}
        </h4>
      </div>

      <div class="modal-body">
        <ng-container *ngIf="imageURIField && getChained(values, imageURIField)">
          <img #illustration [src]="getChained(values, imageURIField)" class="loading" (load)="onImgLoad()" />
        </ng-container>

        <mat-tab-group class="spin-tooltip-tab-group" (selectedTabChange)="afterRender()" dynamicHeight>
          <ng-container *ngFor="let tab of tabs; let i = index">
            <mat-tab [label]="tab.title">
              <div class="spin-tooltip-tab-body">
                <table>
                  <colgroup>
                    <col class="tooltip-title-col" [ngStyle]="{ 'width.px': tab.titleColWidth }" />
                    <col />
                  </colgroup>
                  <ng-container *ngFor="let field of tab.fields; let rowIndex = index">
                    <tr *ngIf="shouldShowField(field)">
                      <td class="tooltip-item-title">{{ field.title }}</td>
                      <td class="tooltip-item-value">
                        <ng-container *ngIf="isLightEditable(field); else tooltipItemValue">
                          <light-edit-field
                            [field]="field"
                            [dataToEdit]="values"
                            [darkMode]="true"
                            [hideLabel]="true"
                            (fieldUpdated)="onFieldUpdated($event)">
                            <ng-template [ngTemplateOutlet]="tooltipItemValue" />
                          </light-edit-field>
                        </ng-container>

                        <ng-template #tooltipItemValue>
                          <ng-container [ngSwitch]="field.type">
                            <div *ngSwitchCase="'pageLink'">
                              <a
                                [href]="getNavigateLink(field)"
                                class="page-link"
                                [ngStyle]="getPageLinkStyle(field)"
                                queryParamsHandling="preserve"
                                (click)="pageLinkClick($event, field, values)">
                                <span [ngStyle]="getTooltipItemStyle(field.id)"> {{ getFormattedField(field) }} </span>
                                <ng-container *ngIf="isValueOverridden(field.id)">
                                  &nbsp;
                                  <span> {{ getFormattedSpinergieValue(field) }} </span>
                                </ng-container>
                              </a>
                            </div>
                            <div *ngSwitchCase="'pageLinkList'">
                              <ng-container *ngFor="let pageLink of getPageLinkList(field)">
                                <a
                                  [href]="pageLink.href"
                                  class="page-link"
                                  [ngStyle]="getPageLinkStyle(field, pageLink.index)"
                                  queryParamsHandling="preserve"
                                  (click)="pageLinkClick($event, field, values, pageLink.index)"
                                  ><span [ngStyle]="getTooltipItemStyle(field.id)"> {{ pageLink.title }}</span>
                                  <ng-container *ngIf="isValueOverridden(field.id)">
                                    &nbsp;
                                    <span> {{ getFormattedSpinergieValue(field) }} </span>
                                  </ng-container>
                                </a>
                                <span *ngIf="pageLink.index != pageLink.linkListLength - 1">, </span>
                              </ng-container>
                            </div>
                            <div *ngSwitchCase="'link'" class="tooltip-item-link">
                              <spin-link
                                [link]="getFormattedField(field)"
                                [linkTitle]="getLinkTitle(field)"
                                [icon]="field.icon ?? (getLinkTitle(field) ? null : 'link')"
                                [tooltip]="true"
                                (hideTooltip)="setHideTooltip($event)"
                                [tooltipElRef]="elRef" />
                            </div>
                            <div *ngSwitchCase="'externalLink'">
                              <a
                                *ngIf="getExternalLink(field)"
                                [href]="getExternalLink(field)"
                                target="_blank"
                                class="page-link"
                                rel="noopener noreferrer">
                                <span [ngStyle]="getTooltipItemStyle(field.id)"> {{ getFormattedField(field) }} </span>
                                <ng-container *ngIf="isValueOverridden(field.id)">
                                  &nbsp;
                                  <span> {{ getFormattedSpinergieValue(field) }} </span>
                                </ng-container>
                                <i class="material-icons external-link">launch</i>
                              </a>
                              <span
                                *ngIf="getFormattedField(field) && !getExternalLink(field)"
                                [ngStyle]="getTooltipItemStyle(field.id)">
                                {{ getFormattedField(field) }}
                              </span>
                              <ng-container *ngIf="isValueOverridden(field.id)">
                                &nbsp;
                                <span> {{ getFormattedSpinergieValue(field) }} </span>
                              </ng-container>
                            </div>
                            <div *ngSwitchCase="'files'" class="page-link">
                              <ng-container
                                *ngFor="let file of getFormattedField(field); let i = index; let last = last">
                                <a href="{{ file.link }}" target="_blank" class="link"> {{ file.title }} </a>
                                {{ last ? "" : "," }}
                              </ng-container>
                            </div>
                            <div *ngSwitchCase="'html'">
                              <div [innerHtml]="getFormattedField(field) | safeHtml" class="html-item"></div>
                            </div>
                            <div *ngSwitchDefault class="line-clamped">
                              <span [ngStyle]="getTooltipItemStyle(field.id)"> {{ getFormattedField(field) }} </span>
                              <ng-container *ngIf="isValueOverridden(field.id)">
                                &nbsp;
                                <span> {{ getFormattedSpinergieValue(field) }} </span>
                              </ng-container>
                            </div>
                          </ng-container>
                          <ng-container *ngIf="config.hasFeature('wfi') && field.button">
                            <field-button
                              [buttonConfig]="field.button"
                              [values]="values"
                              (buttonClick)="onFieldButton($event)">
                            </field-button>
                          </ng-container>
                        </ng-template>
                      </td>
                    </tr>
                  </ng-container>
                </table>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>

      <div class="modal-footer" *ngIf="buttons && buttons.length">
        <div class="tooltip-buttons">
          <ng-container *ngFor="let button of buttons">
            <ng-container *ngIf="showButton(button, values)">
              <spin-link
                *ngIf="button.type == 'link'; else classicButton"
                [link]="linkButtonData(button, values)"
                [linkTitle]="button.title"
                [tooltip]="true"
                [button]="true"
                (hideTooltip)="setHideTooltip($event)"
                [tooltipElRef]="elRef" />
              <ng-template #classicButton>
                <pearl-button-link
                  *ngIf="button.type != 'navigate'; else navigateButton"
                  queryParamsHandling="preserve"
                  size="small"
                  (click)="onclick($event, button)">
                  {{ button.title }}
                </pearl-button-link>
                <ng-template #navigateButton>
                  <pearl-button-link
                    [href]="getNavigateLink(button)"
                    size="small"
                    queryParamsHandling="preserve"
                    (click)="onclick($event, button)">
                    {{ button.title }}
                  </pearl-button-link>
                </ng-template>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</aside>
