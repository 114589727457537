import { Injectable, Injector, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { EntityInformation } from '../helpers/types';
import { EntityDetailComponent } from './entity-detail';

@Injectable()
export class EntityDialogManager {
  private dialog: MatDialog;
  private ngZone: NgZone;

  constructor(injector: Injector) {
    this.dialog = injector.get(MatDialog);
    this.ngZone = injector.get(NgZone);
  }

  openEntityDialog(entityInformation: EntityInformation) {
    const dialogRef = this.dialog.open(EntityDetailComponent, {
      width: '95%',
      height: '85%',
      disableClose: true,
      panelClass: 'entity-dialog',
      autoFocus: false,
    });

    this.ngZone.run(() => {
      const component = dialogRef.componentInstance;
      component.setEntityInformation(entityInformation);
    });

    return dialogRef;
  }
}
