<div class="spin-filters-field" cdkScrollable>
  <spin-filters-multi
    #filters
    [small]="small()"
    [fieldId]="field.id"
    [ngClass]="{ 'no-pointer-event': !editMode }"
    *ngIf="
      field.filterType == 'multi' ||
      field.filterType == 'entity' ||
      field.filterType == 'regions' ||
      field.filterType == 'excludemulti'
    "
    [matTooltip]="field.description"
    matTooltipPosition="after"
    [placeholder]="field.title"
    [isEntity]="field.filterType === 'entity'"
    (unselected)="removeTag($event, true, true)"
    (selected)="addTags($event, true, true)"
    [isBlankOptionActivated]="field?.hasNullValue"
    [style]="field.style"
    [showSelectAll]="field.id !== 'vessel'" />

  <spin-filters-single
    #filters
    [small]="small()"
    *ngIf="field.filterType == 'single'"
    [matTooltip]="field.description"
    matTooltipPosition="after"
    [placeholder]="field.title"
    (unselected)="removeTag($event, false, true)"
    (selected)="addTags($event, true, true)" />

  <div *ngIf="!haveNoTags && !field.hideTags" class="mat-tags" [ngClass]="{ 'has-tags': tags.length }">
    <ng-container *ngIf="!this?.$filters?.selectAllChecked; else showExcludedTag">
      <ng-container *ngFor="let tag of tags; trackBy: tagValue">
        <span
          *ngIf="tag.title !== null && tag.value != -1"
          [attr.data-value]="tag.value"
          class="tag mat-primary"
          [ngClass]="{ 'no-edit': !editMode }"
          (click)="removeTag(tag.value, true, true)">
          {{ tag.title }}
          <span *ngIf="editMode">&nbsp;×</span>
        </span>
      </ng-container>
    </ng-container>
    <ng-template #showExcludedTag>
      <ng-container *ngFor="let tag of getNotChosenItems(); trackBy: tagValue">
        <span
          *ngIf="tag.title != null && tag.value != -1"
          [attr.data-value]="tag.value"
          (click)="removeTagWhenSelectAllChecked(tag)"
          class="tag mat-primary red-tag">
          {{ tag.title }}
          <span *ngIf="editMode">&nbsp;×</span>
        </span>
      </ng-container>
    </ng-template>
  </div>
  <ng-container *ngIf="unavailableTags">
    <div *ngIf="unavailableTags.length > 0" class="tags-unavailable">
      {{ unavailableTags.length }} more option{{ unavailableTags.length > 1 ? "s are" : " is" }} not available for this
      screen, <span class="remove" (click)="removeUnavailableTags()">click to remove</span>.
    </div>
  </ng-container>
</div>
