import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgStyle } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { scaleLinear } from 'd3-scale';

import { EntityFieldDefinition, FieldSettings } from '../helpers/types';

@Component({
  selector: 'score',
  templateUrl: './score.html',
  styleUrls: ['score.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTooltipModule, NgStyle],
})
export class ScoreComponent {
  @Input()
  scoreValue: number;
  @Input()
  field: FieldSettings | EntityFieldDefinition;
  @Input()
  description: string = null;

  private scoreScale: d3.scale.Linear<number, number>;

  // Score is rounded to an integer
  public get roundedScore(): number {
    return Math.round(this.scoreValue);
  }

  public get scoreColor() {
    if (!this.scoreScale) {
      const domain = this.field.scoreDomain ? this.field.scoreDomain : [0, 25, 50, 75, 100];
      const colorScale = this.field.scoreColorScale
        ? this.field.scoreColorScale
        : ['#fd0303', '#fd9a03', '#fbfd03', '#67d611', '#127427'];
      this.scoreScale = scaleLinear(domain, colorScale);
    }
    const color = {
      'stroke': this.scoreScale(this.scoreValue),
    };
    return color;
  }
}
