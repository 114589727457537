<div class="wti-selector-sidebar">
  <fieldset class="obligatory-fieldset">
    <div class="reset-filters-container">
      <pearl-button type="secondary" size="small" (click)="resetAllParameters()">Clear</pearl-button>
    </div>
    <legend>Windfarm information</legend>
    <i>Select one option for turbine inputs:</i>
    <mat-button-toggle-group
      class="obligatory-parameters-pick"
      [(ngModel)]="obligatoryParametersPick"
      (ngModelChange)="valueChange()">
      <mat-button-toggle value="turbineCapacity">Capacity</mat-button-toggle>
      <mat-button-toggle value="turbineModel">Model</mat-button-toggle>
      <mat-button-toggle value="heightAndWeight">User defined</mat-button-toggle>
    </mat-button-toggle-group>

    @switch (obligatoryParametersPick) {
      @case ("turbineCapacity") {
        <div>
          <pearl-form-field
            [small]="true"
            [label]="wtiTurbineCapacityField.title"
            [hintError]="fieldRangeInvalid(wtiTurbineCapacityField, obligatoryParameters.wtiTurbineCapacity)"
            [errorMessage]="getErrorMessage(wtiTurbineCapacityField)">
            <input
              type="number"
              matInput
              [(ngModel)]="obligatoryParameters.wtiTurbineCapacity"
              (input)="valueChange()"
              autocomplete="off"
              [min]="0"
              [required]="wtiTurbineCapacityField.required" />
          </pearl-form-field>
        </div>
      }
      @case ("turbineModel") {
        <div>
          <pearl-form-field [label]="turbineModelField.title" [small]="true" [showError]="true">
            <input type="text" matInput [formControl]="turbineModelControl" [matAutocomplete]="auto" />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="optionValueTitle.bind(this)"
              (optionSelected)="modelChanged($event.option.value)">
              @for (turbineModel of filteredTurbineModels | async; track turbineModel.id) {
                <mat-option [value]="turbineModel">{{ turbineModel.title }}</mat-option>
              }
            </mat-autocomplete>
          </pearl-form-field>
        </div>
      }
      @case ("heightAndWeight") {
        <pearl-form-field
          [small]="true"
          [label]="nacelleWeightField.title"
          [errorMessage]="getErrorMessage(nacelleWeightField)"
          [hintError]="fieldRangeInvalid(nacelleWeightField, obligatoryParameters.nacelleWeight)">
          <input
            type="number"
            matInput
            [(ngModel)]="obligatoryParameters.nacelleWeight"
            (input)="valueChange()"
            autocomplete="off"
            [min]="0"
            [required]="this.nacelleWeightField.required" />
        </pearl-form-field>
        <pearl-form-field
          [small]="true"
          [label]="nacelleHeightField.title"
          [errorMessage]="getErrorMessage(nacelleHeightField)"
          [hintError]="fieldRangeInvalid(nacelleHeightField, obligatoryParameters.nacelleHeight)"
          appearance="outline">
          <input
            type="number"
            matInput
            [placeholder]="this.nacelleHeightField.title"
            [(ngModel)]="obligatoryParameters.nacelleHeight"
            (input)="valueChange()"
            autocomplete="off"
            [min]="0"
            [required]="nacelleHeightField.required" />
        </pearl-form-field>
        <pearl-form-field
          [small]="true"
          [label]="turbineHubHeightField.title"
          [errorMessage]="getErrorMessage(turbineHubHeightField)"
          [hintError]="fieldRangeInvalid(turbineHubHeightField, obligatoryParameters.turbineHubHeight)">
          <input
            type="number"
            matInput
            [(ngModel)]="obligatoryParameters.turbineHubHeight"
            (input)="valueChange()"
            autocomplete="off"
            [min]="0"
            [required]="this.turbineHubHeightField.required" />
        </pearl-form-field>
      }
    }

    <pearl-form-field
      [small]="true"
      [label]="waterDepthField.title"
      [errorMessage]="getErrorMessage(this.waterDepthField)"
      [hintError]="fieldRangeInvalid(this.waterDepthField, obligatoryParameters.waterDepth)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.waterDepth"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="this.waterDepthField.required" />
    </pearl-form-field>
  </fieldset>

  <fieldset
    class="optional-parameters-fields"
    [ngClass]="{ 'optional-parameters-fieldset-rolled': !optionalFieldset.expanded }">
    <legend (click)="optionalFieldset.expanded = !optionalFieldset.expanded">Advanced parameters</legend>
    <div [ngClass]="{ 'optional-parameters-rolled': !optionalFieldset.expanded }">
      <div class="reset-filters-container">
        <pearl-button type="secondary" size="small" (click)="setDefaultOptionalParams()">Reset to default</pearl-button>
      </div>
      @for (optionalField of optionalFieldset.fields; track optionalField.id) {
        <pearl-form-field
          [small]="true"
          [label]="optionalField.title"
          [errorMessage]="getErrorMessage(optionalField)"
          [hintError]="fieldRangeInvalid(optionalField, optionalParameters[optionalField.id])">
          <input
            type="number"
            matInput
            [(ngModel)]="optionalParameters[optionalField.id]"
            (input)="valueChange()"
            autocomplete="off"
            [title]="fieldDescription(optionalField)"
            step="0.01" />
        </pearl-form-field>
      }
    </div>
  </fieldset>

  @if (loading) {
    <div>
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  }

  @if (modelApplied) {
    <fieldset class="calculated-parameters-fieldset">
      <legend>{{ calculatedFieldset.title }}</legend>
      <i>These parameters are calculated by the model:</i>
      @for (calculatedParameterField of calculatedFieldset.fields; track calculatedParameterField.id) {
        <pearl-form-field [small]="true" [label]="calculatedParameterField.title">
          <input
            type="text"
            matInput
            disabled
            [(ngModel)]="calculatedParameters[calculatedParameterField.id]"
            (input)="valueChange()"
            autocomplete="off" />
        </pearl-form-field>
      }
    </fieldset>
  }
</div>
