import dayjs from 'dayjs';
import { clone, has, isNumber, isString, max, min, orderBy, sum, uniq } from 'lodash-es';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { scaleLinear } from 'd3-scale';

import { getOriginalValue, safeString } from '../helpers/data-helpers';
import { DateHelper } from '../helpers/date-helper';
import { Aggregation, BaseOperator, ChartCalcConfig, ChartContext, ChartData, ChartGroup, ChartHistInfo, ChartOptions,
  ChartSelect, ChartSelectKey, ChartSelects, ChartSeries, ChartSettings, ChartSplit, ComplexAggregation, DataSeries,
  NestedAggregation, NormalizationType, Operation, QuantileData, RawDataPoint, SelectableGroupBy, SelectableMetric,
  SelectableSplitBy, SelectableValue, SeriesHeader, SimpleAggregation, TimeLevel, TimeLevelEnum, TimeMode,
  TransitionalSeries, TransitionalValues, YAxis, isChartSelect, isComplexAggregation, isNestedAggregation,
  isSimpleAggregation, xAxisType } from './chart-types';
import { BaseEndpointType, CalcOperation, ComponentStateOptions, DateTimezone, Duration, EntityFieldDefinition,
  HeavyDataSeries, Interval, IntervalField, NumOrString, SeriesSplit, StringDuration,
  milliSecondInPeriod } from '../helpers/types';
import { getChained } from '../data-loader/ref-data-provider';
import { AvailablePages, PageLinkHelper } from '../helpers/page-link-helper';
import { DatabaseHelper } from '../database/database-helper';
import { StringHelper } from '../helpers/string-helper';

export type Period = {
  dateStart: number;
  dateEnd: number;
  dateEndDayJs: dayjs.Dayjs;
  duration: number;
};

export class ChartingHelpers {
  public static nullGroupTitle = 'Not available';
  public static timeMetrics: TimeLevel[] = [
    'minute',
    'hour',
    'day',
    'week',
    'isoWeek',
    'month',
    'quarter',
    'year',
    'date',
  ];

  public static DEFAULT_PERCENTILES = {
    'q10': '10th percentile',
    'q25': '25th percentile',
    'q50': 'Median',
    'q75': '75th percentile',
    'q90': '90th percentile',
  };

  public static OPERATIONS_MAP = {
    avg: ChartingHelpers.average,
    max: ChartingHelpers.maximum,
    min: ChartingHelpers.minimum,
    psum: ChartingHelpers.pSum,
    pcount: ChartingHelpers.pCount,
    dcount: ChartingHelpers.dCount,
  };

  // Keep the list of all values for quantile (q),  distinct count (dcount), max and min calculations
  private static OPERATIONS_NEEDING_VALUES_LIST: CalcOperation[] = ['q', 'dcount', 'none', 'max', 'min'];

  /*
   * TODO: for hover/out effect on marker
   * private static SELECTION_GROWING_OFFSET = 6;
   */
  private static SIZE_SCALE_RANGE = [10, 30];

  /**
   * Get time level by looking in 'value' or 'samplingUnit' for generic time metrics
   * aka. minute, hour, day, ... (see ChartingHelpers.timeMetrics)
   */
  public static getTimeLevel(groupBy: SelectableGroupBy): TimeLevelEnum | null {
    if (!groupBy) return null;
    const keysToCheck = ['value', 'samplingUnit'];

    for (const key of keysToCheck) {
      if (ChartingHelpers.timeMetrics.includes(groupBy[key])) {
        const t: TimeLevel = ChartingHelpers.timeCoherence(groupBy[key]) as TimeLevel;
        return TimeLevelEnum[t];
      }
    }
    return null;
  }

  /**
   * Determine if grouping is temporal, we can base ourselves on the value name or on the samplingUnit.
   */
  public static isTimeGrouping(groupBy: SelectableGroupBy): boolean {
    if (!groupBy) {
      return false;
    }
    if ('samplingUnit' in groupBy || 'samplingDuration' in groupBy) {
      return true;
    }
    return ChartingHelpers.getTimeLevel(groupBy) !== null;
  }

  /**
   * This function has the same functionality than isTimeGrouping but also returns true when the value is
   * 'none' because althought 'none' is a special case of the timeGrouping, so that the 'none' special case
   * can be handled well
   */
  public static isTimeInterval(groupBy: SelectableGroupBy): boolean {
    /** FIXME: groupBy?.interval can be undefined and (A || B && C) is ambiguous **/
    return ChartingHelpers.isTimeGrouping(groupBy)
      || groupBy?.interval && groupBy?.value === 'none';
  }

  public static timeCoherence(name: string) {
    if (name === 'date') return 'day';

    return name;
  }

  /** From a set of data, check if x is numeric */
  public static hasNumericX(data: RawDataPoint[]): boolean {
    if (!data?.length) return false;

    return isNumber(getChained(data[0], 'x'));
  }

  public static isHeavyOrHeavyCustom(type: BaseEndpointType): boolean {
    return type === 'heavy' || type === 'heavy-custom';
  }

  /**
   * The temporal breakdown time mode breaks down a value associated to a duration (ex. contract with a $ value) into fixed time periods.
   * 2 distinct modes are implemented wrt. the metric operation:
   *
   *  - If the operation is count - For each contract, we just want for each time group the ratio of time covered by the contract.
   *      Ex: A contract with dates 2016-07-01 -- 2017-9-30, to be broken done in `year`
   *      We have 0.5 in year 2016 and 0.75 in year 2017
   *
   *  - Else (only sum, xsum and nsum are implemented so far) - For each contract, we have to lineary split the metric column into the time groups according to the duration
   *        Ex: A contract with dates 2016-07-01 -- 2017-9-30, contractValue of 1500$, to be broken done in `year`, with the metric `sum:contractValue`
   *        The contract duration is 456 days, thus we will have for 2016: 184 / 456 * 1500 = 605.26$
   *                                                             for 2017: 272 / 456 * 1500 = 894.74$
   */
  private static temporalBreakdown(
    data: RawDataPoint[],
    chartSettings: ChartSettings,
    dateStartProp: string,
    dateEndProp: string,
    calc: ChartCalcConfig,
  ): TransitionalSeries {
    /*
     * For dayjs, there's a distinction between 'week' (1st day is Sunday) and 'isoWeek' (1st day is Monday)
     * We should always be using 'isoWeek' by default
     */
    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;
    const timeGroupForStart = (xAxis.group === 'week' ? 'isoWeek' : xAxis.group) as dayjs.QUnitType;
    const tabFilterSelected = calc.tabFilterSelected;
    const tabFilterProp = calc.tabFilterProp;
    /*
     * If this boolean is true, that means the value of each group has to be equal to the ratio of the duration
     * it covers inside the group and the group duration [0, 1]
     */
    const aggregation = yAxis.aggregation;
    const isSimple = isSimpleAggregation(aggregation);
    const isTemporalBreakDownCount = isSimple && aggregation.operation === 'count';

    const splitPageLink = ChartingHelpers.getPageLink(calc.splitBy, calc.availablePages);
    const splitIds = {};

    let totalDuration: number;
    let totalValue;
    // Structure containing the aggregated data by group and splits
    const inter: {
      [group: string]: {
        [split: string]: number;
      };
    } = {};
    // We will keep track of already calculated periods to speed up the algorithm and keep calculated period durations
    const periods: { [periodStart: number]: Period } = {};
    const nullSplitTitle = calc.splitBy?.nullTitle ?? 'Not available';

    for (const d of data) {
      // Apply tab filter
      if (tabFilterProp && tabFilterSelected && getChained(d, tabFilterProp) !== tabFilterSelected) {
        continue;
      }

      const split = calc.splitBy ? (getChained<string>(d, calc.splitBy.value) ?? nullSplitTitle) : 'All';
      let contractStart = getChained<number>(d, dateStartProp);
      // We discard data without start date
      if (!contractStart) {
        continue;
      }

      // If date end is null, we take now
      if (getChained(d, dateEndProp) === null) {
        /*
         * contractEnd is passed later in enforceLocalTz(), like contractStart
         * so we shouldn't have to enforce the date here
         */
        d[dateEndProp] = dayjs.utc().valueOf();
      }
      let contractEnd = getChained<number>(d, dateEndProp);

      // if there is an interval to which the dates have to be clipped on the periods, we will clip
      if (xAxis.extent) {
        if (xAxis.extent[0] > contractStart) {
          // skip entirely if end before clip start
          if (contractEnd < xAxis.extent[0]) continue;
          contractStart = xAxis.extent[0];
        }
      }

      // These values are only used in the case of sum
      if (!isTemporalBreakDownCount && isSimpleAggregation(aggregation)) {
        totalDuration = contractEnd - contractStart;
        totalValue = getChained(d, aggregation.variable);
      }

      if (totalDuration === 0 || totalValue === 0) {
        continue;
      }

      const contractStartDayjs = DateHelper.getDayjs(contractStart, calc.timezone);
      const contractStartPeriod = contractStartDayjs.startOf(timeGroupForStart);
      const startOfPeriod = contractStartPeriod.valueOf();

      // Current period holds the info. of the currently analyzed time period
      let currentPeriod: Period = {
        dateStart: startOfPeriod,
        dateEnd: startOfPeriod,
        dateEndDayJs: contractStartPeriod,
        duration: 0,
      };

      /*
       * At this point, we need contractStart and contractEnd to be the timestamps relative to the correct timezone
       * For locale, we don't take `dayjs(value).valueOf()` because it returns the initial timestamp `value`
       * The timestamp is correctly changed in "local timezone" if we make an operation with dayjs, such as startOf()
       */
      contractEnd = DateHelper.enforceLocalTz(contractEnd, calc.timezone);
      let contractEndsAfterThisGroup = true;
      let contractDurationInGroup: number;
      let contractStartInPeriod = DateHelper.enforceLocalTz(contractStart, calc.timezone);
      let groupValue: number;
      while (contractEndsAfterThisGroup) {
        if (currentPeriod.dateEnd in periods) {
          currentPeriod = clone(periods[currentPeriod.dateEnd]);
        } else {
          currentPeriod.dateStart = currentPeriod.dateEnd;
          currentPeriod.dateEndDayJs = currentPeriod.dateEndDayJs.add(1, xAxis.group as dayjs.QUnitType);
          currentPeriod.dateEnd = currentPeriod.dateEndDayJs.valueOf();
          currentPeriod.duration = currentPeriod.dateEnd - currentPeriod.dateStart;
          periods[currentPeriod.dateStart] = clone(currentPeriod);
        }

        contractEndsAfterThisGroup = contractEnd > currentPeriod.dateEnd;
        contractDurationInGroup = contractEndsAfterThisGroup
          ? currentPeriod.dateEnd - contractStartInPeriod
          : contractEnd - contractStartInPeriod;
        // If this is true, we need the ratio of the contract duration in the group and the group duration
        if (isTemporalBreakDownCount) {
          /*
           * Transform the ratio in another time unit if needed - We don't do it bc it costs too much
           * middle[calc.col] = dayjs.utc(contractEndInGroup).diff(dayjs.utc(middle[dateStartProp]), unit, true)
           */
          groupValue = contractDurationInGroup / currentPeriod.duration;
        } // Else we just want the totalValue * the amount of the contract duration in the group
        else {
          groupValue = contractDurationInGroup / totalDuration * totalValue;
        }
        contractStartInPeriod = currentPeriod.dateEnd;

        // Add data to results
        if (!(currentPeriod.dateStart in inter)) {
          inter[currentPeriod.dateStart] = {};
        }

        inter[currentPeriod.dateStart][split] ??= 0;
        inter[currentPeriod.dateStart][split] += groupValue;

        if (getChained(d, splitPageLink?.idParamKey)) {
          splitIds[split] ??= getChained(d, splitPageLink.idParamKey);
        }
      }
    }

    /*
     * Normalize the dataset if needed
     * xsum: normalize by the calc interval duration
     */
    if (isSimple && aggregation.operation === 'xsum') {
      let intervalStart = null;
      let intervalEnd = null;
      if (xAxis.extent) {
        intervalStart = xAxis.extent[0];
        intervalEnd = xAxis.extent[1];
      } else if (chartSettings.intervalField) {
        // When we have no interval, we simply take the min and max of the dataset
        const leftProp = chartSettings.intervalField.leftPropValue;
        const rightProp = chartSettings.intervalField.rightPropValue;
        intervalStart = min(Object.values(data).map(value => getChained(value, leftProp)));
        intervalEnd = max(Object.values(data).map(value => getChained(value, rightProp)));
      } else {
        intervalStart = 0;
        intervalEnd = milliSecondInPeriod.day;
      }
      // Divide by the nb of ms in 1 day
      const intervalLength = (intervalEnd - intervalStart) / milliSecondInPeriod.day;
      for (const groupValue in inter) {
        for (const splitValue in inter[groupValue]) {
          inter[groupValue][splitValue] /= intervalLength;
        }
      }
    } // nsum = normalize by the group duration (in days)
    else if (isSimple && aggregation.operation === 'nsum') {
      for (const groupValue in inter) {
        for (const splitValue in inter[groupValue]) {
          inter[groupValue][splitValue] /= periods[groupValue].duration / milliSecondInPeriod.day;
        }
      }
    }

    const transitionalData: TransitionalValues[] = [];
    const header: SeriesHeader = {};
    for (const groupValue in inter) {
      const groupValueAsInt = parseInt(groupValue);
      const values: TransitionalValues = {
        x: isNaN(groupValueAsInt) ? groupValue : groupValueAsInt,
      };
      for (const splitValue in inter[groupValue]) {
        values[splitValue] = inter[groupValue][splitValue];
        if (values[splitValue] != null) {
          header[splitValue] = splitValue;
        }
      }
      transitionalData.push(values);
    }

    if (Object.keys(splitIds).length) {
      header.__splitIds = splitIds;
    }

    return {
      header,
      data: transitionalData,
      xAxisType: 'numeric',
    };
  }

  /**
   * Extract Y-axis operation from `fullMetric` string
   */
  public static metricColumn(fullMetric: string): Aggregation {
    /*
     * first match for nested complex operation
     * this matches metrics in the form: avg((sum:backlog_years/max:supply_excl_cold_stacked):groupby:date,rig_id)
     */
    const nestedCmplxMatch = /^([a-zA-Z]+)\(\(([^:]+):([^:]+)([\*\/\+\-])([^:]+):([^:]+)\):groupby:(.*)\)/;
    let matchResult = fullMetric.match(nestedCmplxMatch);
    if (matchResult && matchResult.length) {
      return {
        globalOperation: matchResult[1] as CalcOperation,
        leftOperation: matchResult[2] as CalcOperation,
        leftVariable: matchResult[3],
        arithmeticOperator: matchResult[4] as BaseOperator,
        rightOperation: matchResult[5] as CalcOperation,
        rightVariable: matchResult[6],
        groupBy: matchResult[7],
      };
    }

    // this matches metrics in the form: 1000*avg((sum:backlog_years/max:supply_excl_cold_stacked):groupby:date,rig_id)
    const nestedCmplxMatchWithConst =
      /^([0-9]+)([\*\/\+\-])([a-zA-Z]+)\(\(([^:]+):([^:]+)([\*\/\+\-])([^:]+):([^:]+)\):groupby:(.*)\)/;
    matchResult = fullMetric.match(nestedCmplxMatchWithConst);
    if (matchResult && matchResult.length) {
      return {
        const: parseInt(matchResult[1]),
        constOperator: matchResult[2] as BaseOperator,
        globalOperation: matchResult[3] as CalcOperation,
        leftOperation: matchResult[4] as CalcOperation,
        leftVariable: matchResult[5],
        arithmeticOperator: matchResult[6] as BaseOperator,
        rightOperation: matchResult[7] as CalcOperation,
        rightVariable: matchResult[8],
        groupBy: matchResult[9],
      };
    }

    /*
     * match for simple nested operation
     * avg(sum:backlog_years:groupby:date)
     */
    const nestedOperationRegex = /^([a-zA-Z]+)\(([a-zA-Z]+)\:([^:]+)\:groupby:(.*)\)/;
    matchResult = fullMetric.match(nestedOperationRegex);
    if (matchResult && matchResult.length) {
      return {
        globalOperation: matchResult[1] as CalcOperation,
        leftOperation: matchResult[2] as CalcOperation,
        leftVariable: matchResult[3],
        groupBy: matchResult[4],
      };
    }

    /*
     * match for simple nested operation with constant
     * 100*avg(sum:backlog_years:groupby:date)
     */
    const nestedOperationWithConstRegex = /^([0-9]+)([\*\/\+\-])([a-zA-Z]+)\(([a-zA-Z]+)\:([^:]+)\:groupby:(.*)\)/;
    matchResult = fullMetric.match(nestedOperationWithConstRegex);
    if (matchResult && matchResult.length) {
      return {
        const: parseInt(matchResult[1]),
        constOperator: matchResult[2] as BaseOperator,
        globalOperation: matchResult[3] as CalcOperation,
        leftOperation: matchResult[4] as CalcOperation,
        leftVariable: matchResult[5],
        groupBy: matchResult[6],
      };
    }

    /*
     * If there is an arithmetic sign on the metric, this is a complex operation
     * Complex operation can be in the form:
     *   - sum:variable/avg:variable2
     *   - sum(variable*variable2)/avg:variable2
     * ** Warning **
     * Light charts only support the first form, trying to use the second one will lead to calculation errors
     */
    const operatorRegex = /[\*\/\+\-\!]/;
    matchResult = fullMetric.match(operatorRegex);
    if (matchResult && matchResult.length) {
      let operations = [];
      const operationCols = [];
      const operationModes = [];
      const operator = matchResult[0] as BaseOperator;
      operations = fullMetric.split(operator);
      operations.forEach(operation => {
        const operationOptions = ChartingHelpers.metricColumn(operation) as SimpleAggregation;
        operationCols.push(operationOptions.variable);
        operationModes.push(operationOptions.operation);
      });
      return {
        serialized: fullMetric,
        arithmeticOperator: operator,
        variables: operationCols,
        operations: operationModes,
      };
    }

    // metric including calculation mode
    if (fullMetric.includes(':')) {
      const [metric, column] = fullMetric.split(':');
      return {
        operation: metric as CalcOperation,
        variable: column,
      };
    }

    // Default, column name only
    return {
      variable: fullMetric,
    };
  }

  public static chartCalcConfigFromStateAndConfig(
    componentState: ComponentStateOptions,
    chartConfig: ChartSettings,
    availablePages: AvailablePages,
  ): ChartCalcConfig {
    const chartOptions = {} as ChartCalcConfig;
    const selects = chartConfig.selects;

    // Default bar chart aggregation
    if (componentState.showTail !== undefined) {
      chartOptions.showTail = componentState.showTail;
    } else {
      componentState.showTail = chartOptions.showTail = chartConfig.opts.barsShowAll;
    }

    // Default line chart display mode
    if (componentState.displayMode) {
      chartOptions.displayMode = componentState.displayMode;
    } else {
      componentState.displayMode = chartOptions.displayMode = chartConfig.opts.linesDisplayMode;
    }

    /*
     * For heavy chart by default nullValues aren't included
     * For light chart by default nullValues are included
     */
    if (componentState.groupby) {
      const selectedGroupBy = ChartingHelpers.findSelectValue<SelectableGroupBy>('groupby', componentState, selects)
        ?? ChartingHelpers.firstSelectValue<SelectableGroupBy>(selects.groupby);
      if (selectedGroupBy) {
        chartOptions.xaxis = { ...selectedGroupBy, group: componentState.groupby };
        chartOptions.xaxis.nullTitle ??= ChartingHelpers.nullGroupTitle;
      }
    }
    // Always create a fake X-axis, as some graphs may have only heavy series that don't require a groupBy
    if (!chartOptions.xaxis) {
      chartOptions.xaxis = { title: '', value: '__missing' };
    }

    // Default splitBy
    if (componentState.splitby) {
      const selectedSplitBy = ChartingHelpers.findSelectValue<SelectableSplitBy>('splitby', componentState, selects)
        ?? ChartingHelpers.firstSelectValue<SelectableSplitBy>(selects.splitby);
      if (selectedSplitBy) {
        chartOptions.splitBy = { ...selectedSplitBy };
        chartOptions.splitBy.nullTitle ??= ChartingHelpers.nullGroupTitle;
      }
    }

    /*
     * if interval filter was specified on the component we will pass it to the calc config
     * it might be necessary if we are in adjustToInterval mode
     */
    if (componentState.period?.extent) {
      chartOptions.xaxis.extent = componentState.period.extent;
    }

    // Default size (scatter)
    if (componentState.size) {
      chartOptions.size = ChartingHelpers.findSelectValue('size', componentState, selects);
    }

    // Selected tab filters
    if (selects && selects.selectFilter) {
      chartOptions.tabFilterProp = selects.selectFilter.prop;
      chartOptions.tabFilterSelected = componentState.tabFilterSelected ? componentState.tabFilterSelected : null;
    }

    // Append available pages (for page & label links)
    chartOptions.availablePages = availablePages;
    return chartOptions;
  }

  public static getPageLink(item: SelectableValue, availablePages: AvailablePages): PageLinkHelper {
    if (!item) return null;

    return item.pageLink
      ? PageLinkHelper.fromConfigPageLink(item.pageLink, availablePages)
      : PageLinkHelper.inferFromKey(item.value, availablePages);
  }

  private static average(inter: ChartData, operationIndex: number): void {
    for (const main in inter) {
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] /= inter[main].splits[split].ops[operationIndex][1];
      }
    }
  }

  private static maximum(inter: ChartData, operationIndex: number): void {
    for (const main in inter) {
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] = max(inter[main].splits[split].ops[operationIndex][2]);
      }
    }
  }

  private static minimum(inter: ChartData, operationIndex: number): void {
    for (const main in inter) {
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] = min(inter[main].splits[split].ops[operationIndex][2]);
      }
    }
  }

  private static normalizeBy(inter: ChartData, operationIndex: number, dateRangeDays: number) {
    for (const main in inter) {
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] /= dateRangeDays;
      }
    }
  }

  private static totalPCount(groupBy: ChartGroup, operationIndex: number): number {
    let totalPCount = 0;
    for (const split in groupBy.splits) {
      totalPCount += groupBy.splits[split].ops[operationIndex][1];
    }
    return totalPCount;
  }

  private static total(groupBy: ChartGroup, operationIndex: number): number {
    let total = 0;
    for (const split in groupBy.splits) {
      total += groupBy.splits[split].ops[operationIndex][0];
    }
    return total;
  }

  private static pCount(inter: ChartData, operationIndex: number) {
    for (const main in inter) {
      const totalPcount = ChartingHelpers.totalPCount(inter[main], operationIndex);
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] =
          (inter[main].splits[split].ops[operationIndex][1] / totalPcount) * 100;
      }
    }
  }

  /**
   * Calculate quantile data for split series
   *
   * @param  {ChartData}    inter           Split series
   * @param  {ChartOptions} chartOptions    Chart options
   * @param  {number}       operationIndex  Operation index
   * @return {void}
   */
  private static quantiles(inter: ChartData, operationIndex: number, chartOptions: ChartOptions): void {
    for (const main in inter) {
      for (const sub in inter[main].splits) {
        const values = inter[main].splits[sub].ops[operationIndex][2];
        delete inter[main].splits[sub].ops[operationIndex];
        const qData = ChartingHelpers.quantile(values);
        // boxplot can have a show outliers options to display outliers
        if (chartOptions?.showOutliers) {
          /*
           * if outliers has a min and max options for outliers we take these limits
           * otherwise we take whisker min and max as a limit
           */
          const outlierMin = chartOptions.outliersMin ?? qData.whiskerLow;
          const outlierMax = chartOptions.outliersMax ?? qData.whiskerHigh;
          qData.outliers = values.filter(point => point > outlierMax || point < outlierMin);
        }
        inter[main].splits[sub].ops[operationIndex] = [qData];
      }
    }
  }

  /**
   * Calculate quantiles of a list
   * FIXME: When working on less than 20 values, the Math.floor operation will introduce a small bias:
   *        With 20 values, whiskerLow should be the 2nd value (not the 3rd), Q2 the 10th one (not the 11th)...
   *
   * @param  {number[]}     values   Unordered values
   * @return {QuantileData}          Quantiles as an object
   */
  private static quantile(values: number[]): QuantileData {
    const nonNullValues = values.filter(d => d !== null);
    const sorted = nonNullValues.sort((a: number, b: number) => a === b ? 0 : a < b ? -1 : 1);
    const n = sorted.length;
    return {
      whiskerLow: sorted[Math.floor(0.1 * n)],
      Q1: sorted[Math.floor(0.25 * n)],
      Q2: sorted[Math.floor(0.5 * n)],
      Q3: sorted[Math.floor(0.75 * n)],
      whiskerHigh: sorted[Math.floor(0.9 * n)],
      nbOfObservations: n,
      values: sorted,
    } as QuantileData;
  }

  private static pSum(inter: ChartData, operationIndex: number) {
    const size = 100;
    for (const groupBy in inter) {
      const total = ChartingHelpers.total(inter[groupBy], operationIndex);
      const ratio = size / total;
      for (const splitBy in inter[groupBy].splits) {
        inter[groupBy].splits[splitBy].ops[operationIndex][0] *= ratio;
      }
    }
  }

  private static cum(inter: ChartData, head: any, totalSplitByValue: any, percentage: boolean, operationIndex: number) {
    const cumData = {};
    for (const main in inter) {
      for (const sub in head) {
        let subValue = 0;
        if (!inter[main].splits[sub]) {
          inter[main].splits[sub] = {
            errorMin: 0,
            errorMax: 0,
            errorMinCount: 0,
            errorMaxCount: 0,
            ops: [],
          };
        }
        if (!inter[main].splits[sub].ops[operationIndex]) {
          inter[main].splits[sub].ops[operationIndex] = [0, 0, 0];
        } else {
          subValue = inter[main].splits[sub].ops[operationIndex][0];
        }
        cumData[sub] = cumData[sub] ? cumData[sub] + subValue : subValue;
        inter[main].splits[sub].ops[operationIndex][0] = cumData[sub];
        if (percentage) {
          inter[main].splits[sub].ops[operationIndex][0] =
            (inter[main].splits[sub].ops[operationIndex][0] / totalSplitByValue[sub]) * 100;
        }
      }
    }
  }

  private static dCount(inter: ChartData, operationIndex: number) {
    for (const main in inter) {
      for (const split in inter[main].splits) {
        const values = inter[main].splits[split].ops[operationIndex][2];
        inter[main].splits[split].ops[operationIndex][0] = values ? uniq(values).length : 0;
      }
    }
  }

  /*
   * XSum is the normalization by the width of the analyzed interval
   * TODO: Handle the case of a sidebar filter on leftPropValue/rightPropValue OR a period filter not sync
   *    with the chart interval
   *    We should take the filter values as the interval bounds.
   *    This case does not occur yet.
   */
  private static xSum(
    inter: ChartData,
    operationIndex: number,
    calc: ChartCalcConfig,
    intervalField: IntervalField,
    raw: RawDataPoint[],
  ) {
    const xAxis = calc.xaxis;
    let intervalStart = null;
    let intervalEnd = null;
    if (xAxis.extent) {
      intervalStart = xAxis.extent[0];
      intervalEnd = xAxis.extent[1];
    } else {
      // When we have no interval, we simply take the min and max of the dataset
      const leftProp = intervalField.leftPropValue;
      const rightProp = intervalField.rightPropValue;
      intervalStart = min(Object.values(raw).map(value => getChained<number>(value, leftProp)));
      intervalEnd = max(Object.values(raw).map(value => getChained<number>(value, rightProp)));
    }
    // Divide by the nb of ms in 1 day
    const intervalLength = (intervalEnd - intervalStart) / milliSecondInPeriod.day;
    ChartingHelpers.normalizeBy(inter, operationIndex, intervalLength);
  }

  /*
   * NSum is the normalization by the group duration
   * TODO: Handle the case of a sidebar filter applying on the grouping field
   *    We should adapt the interval length wrt. to the filter
   *    ex: we group by month and the filter is set on Jan. 15th --> Jan. should be normalize by 31 - 15 = 16
   */
  private static nSum(inter: ChartData, operationIndex: number, group: string) {
    for (const main in inter) {
      const mainAsNumber = Number(main);
      let intervalLength = 1;
      if (group === 'week') {
        intervalLength = 7;
      } else if (group === 'month') {
        intervalLength = dayjs(mainAsNumber).daysInMonth();
      } else if (group === 'quarter') {
        const groupDate = dayjs(mainAsNumber).startOf('quarter');
        intervalLength = groupDate.daysInMonth();
        intervalLength += groupDate.add(1, 'month').daysInMonth();
        intervalLength += groupDate.add(2, 'month').daysInMonth();
      } else if (group === 'year') {
        intervalLength = 365;
      }
      for (const split in inter[main].splits) {
        inter[main].splits[split].ops[operationIndex][0] /= intervalLength;
      }
    }
  }

  /**
   * Create points for each chart bridges
   *
   * @param  {ChartCalcConfig} calc      Chart options
   * @param  {RawDataPoint[]}  raw       Raw data
   * @param  {ChartSettings}   settings  Optional chart settings
   * @return {TransitionalSeries}           Dot-plot series
   */
  private static transformBridges(
    calc: ChartCalcConfig,
    raw: RawDataPoint[],
    settings: ChartSettings,
  ): TransitionalSeries {
    let header = {};
    let xAxisType: xAxisType;
    const data = [];
    settings.bridges.forEach(bridge => {
      const bridgeGroup = bridge.groupby;

      // Override calc config
      calc.series.type = 'bar';
      calc.xaxis = { ...bridgeGroup, group: bridgeGroup.value };

      // we call transform Series for each bridge because same single raw can be in multiple bridges
      const bridgeData = ChartingHelpers.transformSeries(calc, raw);

      xAxisType = bridgeData.xAxisType;
      const groupOrder = bridge.groupby.orderBy?.fixedOrder;
      bridgeData.data.forEach(d => {
        d.bridgeId = bridge.id;
        if (groupOrder) {
          const dataOrder = groupOrder.indexOf(d.x as string);
          // if a data hasn't his property in the fixedOrder list it will be placed at the end
          d.groupOrder = dataOrder > -1 ? dataOrder : Number.MAX_VALUE;
        }
      });
      if (groupOrder) {
        bridgeData.data = orderBy(bridgeData.data, 'groupOrder');
      }
      data.push(...bridgeData.data);
      header = {
        ...header,
        ...bridgeData.header,
      };
    });

    return { header, data, xAxisType };
  }

  /**
   * Transform series:
   * - Apply filters & group by
   * - Calculate dimensions (sum, avg, ...)
   * - Select relevant series or calculate quantiles (line series)
   * - Truncate if too many values (bar series)
   * ...
   *
   * @param  {ChartCalcConfig} calc           Chart options (including calculus config)
   * @param  {RawDataPoint[]}  raw            Raw data
   * @param  {ChartSettings}   chartSettings  Optional chart settings
   * @return {TransitionalSeries}                Transformed data series
   */
  public static transformSeries(
    calc: ChartCalcConfig,
    raw: RawDataPoint[],
    chartSettings: ChartSettings = null,
  ): TransitionalSeries {
    // For any array (even empty), we want this method to update the underlying chart
    if (!raw || !Array.isArray(raw)) {
      return;
    }

    // Custom chart types
    if (chartSettings && chartSettings.bridges) {
      return ChartingHelpers.transformBridges(calc, raw, chartSettings);
    }
    if (chartSettings && chartSettings.type === 'polar') {
      return ChartingHelpers.transformPolarSeries(calc, raw);
    }

    // General case

    /*
     * For dayjs, there's a distinction between 'week' (1st day is Sunday) and 'isoWeek' (1st day is Monday)
     * We should always be using 'isoWeek' by default
     */
    /*
     * By convention, value = '' will be interpreted as a groupByAll instruction
     */
    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;
    const group = xAxis.group === 'week' ? 'isoWeek' : xAxis.group;

    // check if groupby is histogram and if so extract the histogram config
    let histInfo: ChartHistInfo = null;
    if (group && group.indexOf('hist:') > -1) {
      const histInfoValues = group.split(':');
      histInfo = {
        variable: histInfoValues[1],
        size: parseFloat(histInfoValues[3]),
      };
    }

    // Configurable time variable names
    let timeMode = yAxis.timeMode;
    /**
     * From @tourfl: Wondering if there is a need to define a timeVariable when series define dateStart and dateEnd?
     */
    const timeVar = yAxis.overrideTimeVariable ?? calc.series.timeVariable ?? calc.series.dateStart;
    const dateStart = calc.series.dateStart ?? timeVar ?? 'dateStart';
    const dateEnd = calc.series.dateEnd ?? 'dateEnd';
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(xAxis);

    if (isTimeGrouping && timeVar == null) {
      /**
       * Note:
       * - For temporal breakdown, `timeVariable` is not used but `dateStart` and `dateEnd`
       */
      throw new Error(
        'timeVariable must be defined for charts with grouping by special temporal keyword as `year`, `month`.',
      );
    }

    // Split options
    const splitPageLink = ChartingHelpers.getPageLink(calc.splitBy, calc.availablePages);
    const titlePageLink = calc.titlePageLink;
    const splitIds = {};
    const totalBySplitValue = {};

    /*
     * Temporal breakdown is meaningful only with a temporal group by
     * For a non-temporal groupby we only want to adjust the value in proportion with the interval duration,
     * thus we change the timeMode to adjustToInterval in this case
     */
    const aggregation = yAxis.aggregation;
    if (timeMode === 'temporalBreakdown') {
      if (isTimeGrouping) {
        if (!isSimpleAggregation(aggregation)) {
          console.warn('Temporal breakdown should be used with simple metrics in the form `operation:column`');
        }
        return ChartingHelpers.temporalBreakdown(raw, chartSettings, dateStart, dateEnd, calc);
      } else {
        timeMode = 'adjustToInterval';
      }
    }

    // A Nested Operation is an operation that must be done before the operation on the global group by.
    const operations = ChartingHelpers.getOperations(aggregation);
    if (isNestedAggregation(aggregation)) {
      raw = ChartingHelpers.calculateNestedVariable(calc, raw);
    }

    // Prepare common buffer
    const buffer: ChartContext = {
      // Already calculated options
      timeMode,
      isTimeGrouping,
      group,
      splitPageLink,
      titlePageLink,
      histInfo,
      // Operations
      operations,
      // Head & intermediate data
      header: {},
      inter: {},
      // Split options
      splitIds,
      totalBySplitValue,
      // Group by
      allGroupsValidFloats: true, // Are all the groupby values valid floats?
      allGroupsValidString: true, // or valid strings?
      // Counters
      total: 0, // Total sum: independent of splits & groups (sums the variable)
      totalCount: 0, // Total count: independent of splits & groups
    };

    // First pass: transform each raw data to a series item
    raw.forEach(d => {
      ChartingHelpers.transformSeriesItem(d, calc, buffer);
    });

    // Second pass: run all aggregated operations
    ChartingHelpers.groupSeries(calc, raw, chartSettings, buffer);

    // Third pass: format & complete head
    let data: TransitionalValues[] = [];
    for (const main in buffer.inter) {
      // Group include & exclude
      if (
        (xAxis.include && !xAxis.include.find(i => i === main))
        || (xAxis.exclude && xAxis.exclude.find(e => e === main))
      ) {
        continue;
      }
      if (calc.series.type === 'scatter' && operations.operations[0] === 'none') {
        // Special case for scatter with no aggregation
        data.push(...ChartingHelpers.transformScatterSeries(main, calc, buffer));
      } else {
        data.push(ChartingHelpers.transformFormatSeries(main, calc, buffer));
      }
    }

    // Build TransitionalSeries
    const transitionalSeries: TransitionalSeries = { data, header: buffer.header, xAxisType: 'mixed' };

    // Head
    if (Object.keys(splitIds).length) {
      transitionalSeries.header.__splitIds = splitIds;
    }

    /*
     * X-axis
     * if all group values are valid floats we will order them
     * we won't order discrete string nor time values
     */
    if (buffer.allGroupsValidFloats) {
      data = orderBy(data, d => d.x);
      transitionalSeries.xAxisType = 'numeric';
    } else if (buffer.allGroupsValidString) {
      transitionalSeries.xAxisType = 'string';
    }

    // Last pass: aggregated (bar charts) or simplified (line charts)
    if (chartSettings && chartSettings.series) {
      ChartingHelpers.transformAggregatedSeries(transitionalSeries, calc, chartSettings);
    }

    return transitionalSeries;
  }

  /**
   * Transforming series: nested operation
   *
   * @param  {ChartCalcConfig} calc           Chart options
   * @param  {RawDataPoint[]}  raw            Raw data
   * @return {any[]}                          Updated data, modes & columns
   *
   * If we have a nestedOperation we'll browse the dataset and create a transformed one grouped by the nested group by
   * For example we have global groupby vessel and a nested group by count:id:groupby:month on following dataset:
   * [id:1, vessel: v1, date: 01/11/20]
   * [id:2, vessel: v1, date: 02/11/20]
   * [id:3, vessel: v1, date: 03/11/20]
   * [id:4, vessel: v1, date: 01/12/20]
   * [id:5, vessel: v2, date: 01/11/20]
   * [id:6, vessel: v2, date: 02/11/20]
   * Will be transformed in :
   * [vessel: v1, month: 11/20, __nestedCalculated: 3]
   * [vessel: v1, month 12/20, __nestedCalculated: 1],
   * [vessel: v2, month: 11/20, __nestedCalculated: 2]
   */
  private static calculateNestedVariable(
    calc: ChartCalcConfig,
    raw: RawDataPoint[],
  ): RawDataPoint[] {
    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;

    const groupBy = xAxis.value;
    const group = xAxis.group === 'week' ? 'isoWeek' : xAxis.group;
    const groupPageLink = ChartingHelpers.getPageLink(xAxis, calc.availablePages);

    const aggregation = yAxis.aggregation as NestedAggregation;
    const { leftOperation, leftVariable, groupBy: nestedGroupBy } = aggregation;
    const nestedTemporal = ChartingHelpers.isTimeGrouping({ title: 'Nested Groupby', value: nestedGroupBy });

    const split = calc.splitBy?.value;
    const splitPageLink = ChartingHelpers.getPageLink(calc.splitBy, calc.availablePages);
    const splitIds = {};

    const timeVar = yAxis.overrideTimeVariable ?? calc.series.timeVariable ?? 'dateStart';

    const nestedOperationGroupId: { [group: string]: string } = {};
    const groups: { [globalGroup: string]: { [nestedGroup: string]: { [nestedSplit: string]: number[] } } } = {};
    const transformedRaw = [];

    raw.forEach(d => {
      let nestedGroupValue = null;

      const nestedPropValue = getChained<number>(d, leftVariable);
      if (!nestedPropValue) {
        return;
      }

      const globalGroup = getChained<string>(d, groupBy) ?? ChartingHelpers.nullGroupTitle;
      const globalSplit = split && getChained(d, split) ? getChained<string>(d, split) : 'All';

      groups[globalGroup] ??= {};
      groups[globalGroup][globalSplit] ??= {};

      nestedOperationGroupId[globalGroup] = getChained(d, groupPageLink?.idParamKey);

      const idParam = getChained(d, splitPageLink?.idParamKey);
      if (idParam) {
        splitIds[globalSplit] ??= idParam;
      }

      // grouping by start of period if isTime
      if (nestedTemporal) {
        // timestamp or non formatable string
        if (isNaN(parseInt(getChained(d, timeVar)))) {
          nestedGroupValue = getChained(d, timeVar) || ChartingHelpers.nullGroupTitle;
        } else {
          nestedGroupValue = ChartingHelpers.roundTimeValueFromGroup(
            getChained(d, timeVar),
            nestedGroupBy,
            xAxis,
            calc.timezone,
          );
        }
        // modulo periods
      } else if (has(DateHelper.defaultFormat, nestedGroupBy)) {
        const date = DateHelper.getDayjs(getChained(d, nestedGroupBy), calc.timezone);
        if (nestedGroupBy === 'weekday' && !xAxis.format) {
          nestedGroupValue = DateHelper.weekDays[date.day()];
        } else {
          nestedGroupValue = date.format(xAxis.format || DateHelper.defaultFormat[nestedGroupBy]);
        }
      } /*
       * If to group we're using samplingDuration (SamplingDuration makes sure to have a single point every x duration)
       * we create a group for each timestamp
       */
      else if (!group && xAxis.samplingDuration) {
        nestedGroupValue = getChained(d, nestedGroupBy);
      } else if (!group) {
        /*
         * case when group by is null. In this case data is concatenate in one group
         * by default this group name is 'All' but it can be speficied in conf with nullTitle
         */
        nestedGroupValue = 'All';
      } else {
        nestedGroupValue = getChained(d, nestedGroupBy);
      }

      groups[globalGroup][globalSplit][nestedGroupValue] ??= [];
      groups[globalGroup][globalSplit][nestedGroupValue].push(nestedPropValue);
    });

    for (const globalGroup in groups) {
      for (const globalSplit in groups[globalGroup]) {
        for (const nestedGroup in groups[globalGroup][globalSplit]) {
          const nestedGroupValues = groups[globalGroup][globalSplit][nestedGroup];
          let resultOperation = 0;

          if (leftOperation === 'count') {
            resultOperation = nestedGroupValues.length;
          } else if (leftOperation === 'avg') {
            resultOperation = sum(nestedGroupValues) / nestedGroupValues.length;
          } else if (leftOperation === 'sum') {
            resultOperation = sum(nestedGroupValues);
          }

          const transformedValue: any = {
            [groupBy]: globalGroup,
            __nestedCalculated: resultOperation,
            __xId: nestedOperationGroupId[globalGroup],
          };
          if (split) {
            transformedValue[split] = globalSplit;
          }
          transformedRaw.push(transformedValue);
        }
      }
    }

    return transformedRaw;
  }

  /**
   * Transform single data in series
   *
   * @param  {RawDataPoint}    d       Data
   * @param  {ChartCalcConfig} calc    Chart options (including calculus config)
   * @param  {ChartContext}    buffer  Already calculated settings & shared data
   * @return {void}
   */
  private static transformSeriesItem(
    d: RawDataPoint,
    calc: ChartCalcConfig,
    buffer: ChartContext,
  ): void {
    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;
    const split = calc.splitBy?.value;
    const group = xAxis.group === 'week' ? 'isoWeek' : xAxis.group;
    const groupBy = xAxis.value;
    const groupOrder = xAxis.orderBy;
    const groupOrderMode = groupOrder?.mode;
    const groupPageLink = ChartingHelpers.getPageLink(xAxis, calc.availablePages);

    // Time variables
    const timeVar = yAxis.overrideTimeVariable ?? calc.series.timeVariable ?? 'dateStart';
    const dateStart = calc.series.dateStart ?? timeVar;
    const dateEnd = calc.series.dateEnd ?? 'dateEnd';

    // Parameters used if we need to calculate error points
    const errorMinProp = yAxis.errorMinProp;
    const errorMaxProp = yAxis.errorMaxProp;
    const totalPcumVariable = yAxis.totalVariable;

    // Tab filtering
    const tabFilterSelected = calc.tabFilterSelected;
    const tabFilterProp = calc.tabFilterProp;

    // Buffer
    const timeMode: TimeMode = buffer.timeMode;
    const { variables, operations } = buffer.operations;
    const header = buffer.header;
    const inter = buffer.inter;
    const splitPageLink = buffer.splitPageLink;
    const splitIds = buffer.splitIds;
    const totalBySplitValue = buffer.totalBySplitValue;
    const isTimeGrouping = buffer.isTimeGrouping;
    const histInfo = buffer.histInfo;

    /**
     * This is meant to avoid division by zero, in ChartingHelpers.adjustValueToInterval
     */
    if (getChained(d, dateEnd) === getChained(d, dateStart) && timeMode === 'adjustToInterval' && xAxis.extent) {
      return;
    }

    const totalForPCum = getChained(d, totalPcumVariable);

    // get group orderBy value
    const orderByValue = getChained(d, groupOrder?.value);

    const errorMinValue = getChained<number>(d, errorMinProp) ?? 0;
    const errorMaxValue = getChained<number>(d, errorMaxProp) ?? 0;

    // fill additionalProps (prop.value = d[prop.prop]), they allow to include extra info in each plot point
    const additionalProps: SeriesSplit[] = calc.additionalProps?.map(additionalProp => {
      const key = additionalProp.prop;
      const iniValue = getOriginalValue(d, key);
      const pageLink = calc.availablePages ? PageLinkHelper.inferFromKey(key, calc.availablePages) : null;
      const field: EntityFieldDefinition = { id: key, ...additionalProp };
      const value = DatabaseHelper.getFormatFieldPureValue(field, d, key);
      const prop = { ...additionalProp, value };
      if (iniValue !== undefined) prop.spinergieValue = iniValue ?? 'no public information';
      if (pageLink) prop.valuePageUrl = pageLink.computeUrlFromDict(d);
      return prop;
    });
    const scatterSize = calc.size?.value ? getChained<number>(d, calc.size.value) : 0;

    if (
      tabFilterProp && tabFilterSelected && getChained(d, tabFilterProp) !== tabFilterSelected
      && !calc.forceNoTabFiltering
    ) {
      return;
    }

    const vals = [];
    for (let i = 0; i < variables.length; i++) {
      const propCol = variables[i];
      let val = getChained<number>(d, propCol);
      /*
       * if the requested value is undefined, we don't include it in the graph
       * nor we use it to calculate aggregations (sums, avgs)
       * if we selected a tab filter and current val isn't equal to tab filter selected
       * we don't include this value too
       */
      if (val == null) {
        return;
      }

      /*
       * we might have to adjust to variable to pro-rata
       * we will do this only for the nominator, which works ok for metrics such as
       * sum(duration)/avg(turbineNumber)
       */
      if (i === 0) {
        val = ChartingHelpers.adjustValueToInterval(val, calc, timeMode, d, dateStart, dateEnd);
      }

      vals.push(val);
    }

    if (split && !getChained(d, split) && calc.splitBy?.includeNull === false) {
      return;
    }

    const sub = split
      ? getChained<NumOrString>(d, split) || calc.splitBy?.nullTitle || ChartingHelpers.nullGroupTitle
      : (yAxis.title ?? 'All');

    // increment the total counter - independant of subs/groups
    buffer.totalCount++;

    // go over all metrics (in case of compl operations there will be 2)
    for (let i = 0; i < operations.length; i++) {
      // will hold the group by value (which needs to be determined)
      let main = null;

      const op = ChartingHelpers.getOperation(operations[i]);

      if (op.operation === 'pcum' && totalPcumVariable) {
        totalBySplitValue[sub] = totalForPCum;
      }
      /*
       * For pcum and cum we need to get every sub even if this sub doesn't has value
       * Because we need to see a point for each split for each group
       */
      if ((op.operation === 'pcum' || op.operation === 'cum') && !header[sub]) {
        header[sub] = sub;
      }

      if (isTimeGrouping) {
        // timestamp or non formatable string
        if (isNaN(parseInt(getChained(d, timeVar)))) {
          main = getChained(d, timeVar) || (xAxis.nullTitle ? xAxis.nullTitle : null) || ChartingHelpers.nullGroupTitle;
        } else {
          main = ChartingHelpers.roundTimeValueFromGroup(getChained(d, timeVar), group, xAxis, calc.timezone);
        }
      } /*
       * Grouping by weekdays or yearmonths. Useful for period data where the exact date does not matter
       * but we are interested into which week-day or month the date falls into
       * - Either we have a discrete value (1-12) for months or (1-7) for days
       * - Or we have a date timestamps somewhere and we want to get the month/week
       */
      else if (has(DateHelper.defaultFormat, groupBy)) {
        const date = DateHelper.getDayjs(getChained(d, timeVar), calc.timezone);
        const groupValue = getChained<number>(d, group);
        if (groupBy === 'yearmonth' && groupValue >= 0 && groupValue <= 12) {
          main = dayjs.months()[groupValue - 1];
        } else if (groupBy === 'weekday' && !xAxis.format) {
          main = DateHelper.weekDays[date.day()];
        } else {
          main = date.format(xAxis.format || DateHelper.defaultFormat[groupBy]);
        }
      } else if (group === 'none') {
        main = getChained(d, timeVar);
      } else if (!group && xAxis.samplingDuration) {
        main = getChained(d, timeVar);
      } /*
       * case when group by is null. In this case data is concatenate in one group
       * by default this group name is 'All' but it can be speficied in conf with nullTitle
       */
      else if (!group) {
        main = xAxis.nullTitle ? xAxis.nullTitle : 'All';
      } // histogram - group by buckets
      else if (histInfo !== null) {
        const value = getChained<number>(d, histInfo.variable);
        const bucketIndex = Math.round(value / histInfo.size);
        main = bucketIndex * histInfo.size;
      } else {
        if (xAxis.includeNull === false && !getChained(d, group)) {
          return;
        }

        /*
         * group by title or some discrete value. If the value is not present we will check if nullTitle was defined
         * if nullTitle is not defined we check if the xAxis has title, that means a real groupby
         * in that case it will be Not available, otherwise it is None groupby
         */
        main = getChained(d, group);
        /** We can update the raw data in the following lines. Cast it to something else than RawDataPoint */
        if (main === null || main === undefined) {
          main = (xAxis.nullTitle ? xAxis.nullTitle : null) || (xAxis.title ? ChartingHelpers.nullGroupTitle : null)
            || 'None';
        } // If main is a boolean, it means we are handling a checkbox for a groupby
        else if (main === true) {
          main = 'Yes';
          d[group] = 'Yes';
        } else if (main === false) {
          main = 'No';
          d[group] = 'No';
        }
      }
      // if the groupby would be undefined we won't include it
      if (main === null) {
        return;
      }

      // initialize the data structure for the group
      if (!inter[main]) {
        inter[main] = {
          splits: {},
          // If there is a nested groupby, __xId has been computed in the first loop
          id: getChained(d, '__nestedCalculated')
            ? getChained(d, '__xId')
            : getChained(d, groupPageLink?.idParamKey || calc.titlePageLink?.idParamKey),
        };

        // for each group
        buffer.allGroupsValidFloats = buffer.allGroupsValidFloats && isNumber(main);
        buffer.allGroupsValidString = buffer.allGroupsValidString && isString(main);
      }

      /*
       * we set two sub value array, because on a complexe operation
       * we need a value for each operation
       * In a case of a standard metric (with only one mode and one col)
       * we will just fill the first array
       */
      if (!inter[main].splits[sub]) {
        inter[main].splits[sub] = {
          errorMin: 0,
          errorMinCount: 0,
          errorMax: 0,
          errorMaxCount: 0,
          scatterSize,
          additionalProps,
          points: [],
          ops: {
            0: [0, 0, []],
            1: [0, 0, []],
          },
        };
        const idParam = getChained(d, splitPageLink?.idParamKey);
        if (idParam) {
          splitIds[sub] ??= idParam;
        }
      }

      if (op.operation === 'count') {
        inter[main].splits[sub].ops[i][0]++;
      } else {
        inter[main].splits[sub].ops[i][0] += vals[i]; // sum
      }

      if (this.OPERATIONS_NEEDING_VALUES_LIST.includes(op.operation)) {
        inter[main].splits[sub].ops[i][2].push(vals[i]);
      }

      // We keep the original data in case mode is 'none'
      if (op.operation === 'none') {
        inter[main].splits[sub].points.push({ additionalProps, scatterSize });
      }

      // increment the total sum (which is independent of subs) - does not work for complex operations
      buffer.total += vals[i];

      inter[main].splits[sub].ops[i][1]++;

      // store group orderBy value
      if (groupOrder?.value) {
        if (
          !groupOrderMode
          || !inter[main].orderValue
          || (groupOrderMode === 'max' && orderByValue > inter[main].orderValue)
          || (groupOrderMode === 'min' && orderByValue < inter[main].orderValue)
        ) {
          inter[main].orderValue = orderByValue;
        }
      }

      if (errorMaxProp) {
        inter[main].splits[sub].errorMax += errorMaxValue - vals[i];
        inter[main].splits[sub].errorMaxCount++;
      }
      if (errorMinProp) {
        inter[main].splits[sub].errorMin += vals[i] - errorMinValue;
        inter[main].splits[sub].errorMinCount++;
      }
    }
  }

  /**
   * Group series values
   *
   * @param  {ChartCalcConfig} calc           Chart options (including calculus config)
   * @param  {RawDataPoint[]}  raw            Raw data
   * @param  {ChartSettings}   chartSettings  Optional chart settings
   * @param  {ChartContext}    buffer         Already calculated settings & shared data
   * @return {void}
   */
  private static groupSeries(
    calc: ChartCalcConfig,
    raw: RawDataPoint[],
    chartSettings: ChartSettings,
    buffer: ChartContext,
  ): void {
    const group = buffer.group;
    const { operations } = buffer.operations;
    const inter: ChartData = buffer.inter;
    const header = buffer.header;
    const totalBySplitValue = buffer.totalBySplitValue;

    operations.forEach((operation, i) => {
      const op = ChartingHelpers.getOperation(operation);
      // First apply aggregation method
      switch (op.operation) {
        case 'q':
          ChartingHelpers.quantiles(inter, i, chartSettings?.opts);
          break;
        case 'pcum':
        case 'cum':
          ChartingHelpers.cum(inter, header, totalBySplitValue, op.operation === 'pcum', i);
          break;
        case 'xsum':
          ChartingHelpers.xSum(inter, i, calc, chartSettings?.intervalField, raw);
          break;
        case 'nsum':
          ChartingHelpers.nSum(inter, i, group);
          break;
        default:
          if (ChartingHelpers.OPERATIONS_MAP[op.operation]) {
            ChartingHelpers.OPERATIONS_MAP[op.operation](inter, i);
          }
      }

      /// Then normalize
      if (op.normType === 'totalSum') {
        /// Normalize by the total sum (just gets percentages) used for series without splits
        ChartingHelpers.normalizeBy(inter, i, buffer.total / 100);
      } else if (op.normType === 'totalCount') {
        // Divides by the total count of items, independant of groups/subs
        ChartingHelpers.normalizeBy(inter, i, buffer.totalCount / 100);
      }
    });
  }

  /**
   * Transform intermediate data to series values
   *
   * @param  {string}            main    Series name
   * @param  {ChartCalcConfig}   calc    Chart options (including calculus config)
   * @param  {ChartContext}      buffer  Already calculated settings & shared data
   * @return {TransitionalValues}         Formatted values
   */
  private static transformFormatSeries(
    main: string,
    calc: ChartCalcConfig,
    buffer: ChartContext,
  ): TransitionalValues {
    const group = buffer.group;
    const isTimeGrouping = buffer.isTimeGrouping;
    const header = buffer.header;
    const inter = buffer.inter;
    const { arithmeticOperator: operator, operations } = buffer.operations;

    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;
    const groupOrder = xAxis.orderBy;

    // Parameters used if we need to calculate error points
    const errorMinProp = yAxis.errorMinProp;
    const errorMaxProp = yAxis.errorMaxProp;

    const values: TransitionalValues = { x: main };
    const intValue = parseInt(main);

    /*
     * the final group by might be string, integer or float value
     * string for all descrete charts
     * integer for timestamps and float for non-discrete charts
     */

    // float values
    if (buffer.allGroupsValidFloats) {
      values.x = parseFloat(main);
    } // integer and temporal values
    else if ((isTimeGrouping || group === null) && !isNaN(intValue)) {
      values.x = intValue;
    }

    // Always keep __xId to compute pageUrl later
    values.__xId = inter[main].id;

    /*
     * if groupOrder it means groupBy has a orderBy variable
     * we set the value in the data group to be able to orderBy this variable
     */
    if (groupOrder?.value) {
      values.__orderValue = inter[main].orderValue;
    }

    for (const split in inter[main].splits) {
      const currentSplit = inter[main].splits[split];
      /*
       * If we are in a complexe operation it's the moment to calculte the result operation
       * If we aren't in a complexe operation (in this case operator is null)
       * this function will simply return inter[main].splits[sub].ops[0]
       */
      values[split] = ChartingHelpers.getSubValue(currentSplit, operator);

      /*
       * If metricUnit and durationUnit are define, it means yAxis is a duration,
       * we have to format the values accordingly
       */
      if (yAxis.metricUnit && yAxis.durationUnit) {
        const duration = dayjs.duration(values[split], yAxis.durationUnit as DurationUnitType);
        values[split] = duration.as(yAxis.metricUnit as DurationUnitType);
      }

      const groupMode = operations?.[0] ?? 'sum';

      /*
       * if errorMinProp/errorMaxProp is specified it means we have to set for each sub point in each group
       * a value corresponding to the min/max error point. The caculation of this value depends on groupMode applied
       * E.g. avg we have to take the avg minError/maxError value of all the sub value in a group
       * For sum we just gonna take the sum of all the minErrorValue
       * we assume that calculation for error points are working for only one mode
       */
      if (errorMinProp) {
        if (!values.__errorMin) {
          values.__errorMin = {};
        }
        if (groupMode === 'avg') {
          currentSplit.errorMin /= currentSplit.errorMinCount;
        }
        values.__errorMin[split] = currentSplit.errorMin;
      }
      if (errorMaxProp) {
        if (!values.__errorMax) {
          values.__errorMax = {};
        }
        // we assume that calculation for error points are working for only one mode
        if (groupMode === 'avg') {
          currentSplit.errorMax /= currentSplit.errorMaxCount;
        }
        values.__errorMax[split] = currentSplit.errorMax;
      }

      /*
       * If value isn't null we add it to head
       * a split should be appear on head only if it has at least one value not null
       */
      if (
        values[split]
        || values[split] === 0
        || (values.__errorMax && values.__errorMax[split])
        || (values.__errorMin && values.__errorMin[split])
      ) {
        header[split] = split;
        /*
         * If split is a boolean this means we are handling a checkbox type for splitby,
         * false is handle as a null value
         */
        if (split === 'true') {
          header[split] = 'Yes';
        }
      }

      if (currentSplit.additionalProps) {
        // ??= create .__additionalProps if doesn't exists
        (values.__additionalProps ??= {})[split] = currentSplit.additionalProps;
      }
      if (currentSplit.scatterSize) {
        (values.__scatterSize ??= {})[split] = currentSplit.scatterSize;
      }
    }

    return values;
  }

  /**
   * Transform intermediate data to scatter series values
   * FIXME: to be refac'd with transformSeries to only separate inner routine (inside `for split in inter.splits`)
   *        => this will be the same use case with boxplot, so let's start now!
   *
   * @param  {string}            main    Series name
   * @param  {ChartCalcConfig}   calc    Chart options (including calculus config)
   * @param  {ChartContext}      buffer  Already calculated settings & shared data
   * @return {TransitionalValues}         Formatted values
   */
  private static transformScatterSeries(
    main: string,
    calc: ChartCalcConfig,
    buffer: ChartContext,
  ): TransitionalValues[] {
    const group = buffer.group;
    const isTimeGrouping = buffer.isTimeGrouping;
    const header = buffer.header;
    const inter = buffer.inter;

    const xAxis = calc.xaxis;
    const yAxis = calc.yaxis;
    const groupOrder = xAxis.orderBy;

    const allValues: TransitionalValues[] = [];
    const maskValues: TransitionalValues = { x: main };
    const intValue = parseInt(main);

    /*
     * the final group by might be string, integer or float value
     * string for all descrete charts
     * integer for timestamps and float for non-discrete charts
     */

    // float values
    if (buffer.allGroupsValidFloats) {
      maskValues.x = parseFloat(main);
    } // integer and temporal values
    else if ((isTimeGrouping || group === null) && !isNaN(intValue)) {
      maskValues.x = intValue;
    }

    // Always keep __xId to compute pageUrl later
    maskValues.__xId = inter[main].id;

    /*
     * if groupOrder it means groupBy has a orderBy variable
     * we set the value in the data group to be able to orderBy this variable
     */
    if (groupOrder?.value) {
      maskValues.__orderValue = inter[main].orderValue;
    }

    for (const split in inter[main].splits) {
      const currentSplit = inter[main].splits[split];
      currentSplit.points.forEach(({ additionalProps, scatterSize }, i) => {
        const values = { ...maskValues };
        values[split] = currentSplit.ops[0][2][i];

        /*
         * If metricUnit and durationUnit are define, it means yAxis is a duration,
         * we have to format the values accordingly
         */
        if (yAxis.metricUnit && yAxis.durationUnit) {
          const duration = dayjs.duration(values[split], yAxis.durationUnit as DurationUnitType);
          values[split] = duration.as(yAxis.metricUnit as DurationUnitType);
        }

        /*
         * If value isn't null we add it to head
         * a split should be appear on head only if it has at least one value not null
         */
        if (values[split] || values[split] === 0) {
          header[split] = split;
          /*
           * If split is a boolean this means we are handling a checkbox type for splitby,
           * false is handle as a null value
           */
          if (split === 'true') {
            header[split] = 'Yes';
          }
        }

        if (additionalProps) {
          // ??= create .__additionalProps if doesn't exists
          (values.__additionalProps ??= {})[split] = additionalProps;
        }
        if (currentSplit.scatterSize) {
          (values.__scatterSize ??= {})[split] = scatterSize;
        }
        allValues.push(values);
      });
    }

    return allValues;
  }

  /**
   * Transform aggregated series (in-place).
   * Limit the number of shown bars or lines, calculate percentiles, etc.
   */
  public static transformAggregatedSeries(
    transitionalSeries: TransitionalSeries,
    calc: ChartCalcConfig,
    chartSettings: ChartSettings,
  ): void {
    // Make a shallow copy, as header is shared between all chart series of the same heavy series
    const header = { ...transitionalSeries.header };
    let data = transitionalSeries.data;

    const yAxis = calc.yaxis;
    const xAxis = calc.xaxis;
    const group = xAxis.group === 'week' ? 'isoWeek' : xAxis.group;
    const groupMetric: SelectableGroupBy = { value: group, title: 'Group' };
    if ('samplingUnit' in xAxis) {
      groupMetric.samplingUnit = xAxis.samplingUnit;
    }
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(groupMetric);
    const aggregation = yAxis.aggregation;
    const { operations } = ChartingHelpers.getOperations(aggregation);
    let summable = yAxis.summable || yAxis.summable === undefined;

    // Disable sum for specific y-axis (not summable metric)
    if (operations && operations.length && ['psum', 'pcount', 'avg'].includes(operations[0])) {
      summable = false;
    }

    // Enable range slider
    chartSettings.rangeSliderEnabled ||= chartSettings.type === 'multi'
      && chartSettings.opts.barsTargetNumber !== -1
      && calc.series.type === 'bar'
      && !isTimeGrouping
      // Disable slider for specific group by (like Enviro score)
      && xAxis.summable !== false
      && data.length > chartSettings.opts.barsTargetNumber;

    // Aggregate for not time-related bar charts (and selects are visible)
    chartSettings.toggleTailEnabled ||= chartSettings.rangeSliderEnabled
      && !chartSettings.opts.hideSelects
      && summable;

    // Simplify for line charts with strictly more than 10 series (and selects are visible)
    chartSettings.modesEnabled ||= chartSettings.type === 'multi'
      && !chartSettings.opts.hideSelects
      && chartSettings.opts.linesMaxSeries !== -1
      && calc.series.type === 'line'
      && Object.keys(header).length > chartSettings.opts.linesMaxSeries;

    // Simplified line charts
    if (chartSettings.modesEnabled) {
      if (calc.displayMode === 'quantiles') {
        transitionalSeries.data = ChartingHelpers.calculatePercentiles(data, header);
        transitionalSeries.header = ChartingHelpers.DEFAULT_PERCENTILES;
      }
      if (calc.displayMode === 'relevant') {
        transitionalSeries.header = ChartingHelpers.findClosestToPercentiles(data, header, chartSettings.opts);
        chartSettings.shownSeries = [Object.keys(transitionalSeries.header).length - 1, Object.keys(header).length];
      }
    }

    // Aggregated bar charts
    if (chartSettings.toggleTailEnabled && calc.series.type === 'bar') {
      const limit = ChartingHelpers.getBarsNumber(transitionalSeries, chartSettings);

      // Aggregate tail
      if (!calc.showTail && limit !== null) {
        const tail: TransitionalValues = { x: 'OTHERS', __tail: true };

        /*
         * Sort by decreasing sum & truncate
         * FIXME: In case current select as an 'ordered' property set, do not sort
         */
        data = orderBy(data, '__sum', 'desc');

        // Now calculate tail sum with that limit
        data.slice(limit - 1).forEach(d => {
          Object.keys(header).forEach(key => {
            if (!d[key]) return;
            if (!tail[key]) tail[key] = 0;
            tail[key] += d[key];
          });
        });

        // Truncate & append aggregated tail
        tail.x = 'OTHERS (' + (data.length + 1 - limit) + ')';
        data = data.slice(0, limit - 1);
        data.push(tail);
        chartSettings.shownBarValues = limit;
        chartSettings.shownXValues = new Set(data.map(d => d.x));
        transitionalSeries.data = data;
      }

      // Aggregation is not possible, disable checkbox
      if (limit === null) {
        chartSettings.toggleTailEnabled = false;
        chartSettings.shownBarValues = 0;
        chartSettings.shownXValues = null;
      }
    }
    /**
     * Remove extra data (scatter points and lines)
     * This requires that scatter series are defined *after* aggregated bar series
     * @see SP-8579 This will only affect light series, not heavy ones
     */
    if (chartSettings.toggleTailEnabled && calc.series.type !== 'bar' && chartSettings.shownXValues) {
      transitionalSeries.data = transitionalSeries.data.filter(d => chartSettings.shownXValues.has(d.x));
    }
  }

  /**
   * Create log size scale
   */
  public static getSizeScale(values: number[]) {
    const maxSize = Math.max(0, max(values));
    return scaleLinear([0, maxSize], ChartingHelpers.SIZE_SCALE_RANGE);
  }

  /**
   * Get numbers of bars to display
   *
   * @param  {TransitionalSeries} transitionalSeries  Series values
   * @param  {ChartSettings}      chartSettings       Optional chart settings
   * @return {number|null}                            Number of bars to aggregate if possible else null
   */
  private static getBarsNumber(transitionalSeries: TransitionalSeries, chartSettings: ChartSettings): number | null {
    const header = transitionalSeries.header;
    let data = transitionalSeries.data;

    // No data
    if (!data.length) {
      return null;
    }

    // First calculate sum
    data.forEach(d => {
      d.__sum = Object.keys(header).reduce((sum, key) => (sum += d[key] || 0), 0);
    });

    /*
     * Sort by decreasing sum & truncate
     * FIXME: In case current select as an 'ordered' property set, do not sort
     */
    data = orderBy(data, '__sum', 'desc');

    /*
     * By default truncate after barsTargetNumber, but if tail sum is greater than first value, try to increase
     * this limit until tail is 80% of first value (or barsOtherValue), and cap at 2x barsTargetNumber
     */
    const minLimit = chartSettings.opts.barsTargetNumber;
    const maxLimit = 2 * minLimit;
    const first = data[0].__sum;
    const threshold = chartSettings.opts.barsOtherValue * first;

    // Sum from the end of the tail to maxLimit and check
    let sum = 0;
    let limit = data.length - 1;
    for (; limit > minLimit; limit--) {
      sum += data[limit].__sum;
      // Continue at least to maxLimit
      if (limit > maxLimit) continue;
      // And stop before minLimit if over threshold
      if (sum > threshold) break;
    }

    // Less than twice the expected threshold?
    if (sum <= 2 * threshold) {
      return limit;
    }

    return null;
  }

  /**
   * Calculate percentile distributions
   * 10%, 25%, 50%, 75%, 90%
   *
   * chart-studio.plotly.com/~vigneshbabu/9/_10th-percentile-25th-percentile-median-75th-percentile-90th-percentile
   *
   * @param  {TransitionalValues[]}  data  Original data (filtered/grouped/splitted)
   * @param  {object} head  Original series names
   * @return {any[]}        Percentile distributions
   */
  private static calculatePercentiles(data: TransitionalValues[], head: object): TransitionalValues[] {
    const qData = [];
    data.forEach(d => {
      const values = Object.keys(head).reduce(function(values, key): number[] {
        if (key in d && d[key] !== null) values.push(d[key]);
        return values;
      }, []);
      if (!values.length) return;
      const quantile = ChartingHelpers.quantile(values);
      const n = quantile.nbOfObservations;
      const qPoint = {
        x: d.x,
        ini: d.ini || d.x,
        __quant: true,
        avg: values.reduce((avg, val) => avg + val, 0) / n,
        q10: quantile.whiskerLow,
        q25: quantile.Q1,
        q50: quantile.Q2,
        q75: quantile.Q3,
        q90: quantile.whiskerHigh,
      };
      qData.push(qPoint);
    });
    return qData;
  }

  /**
   * Find series closest to percentile distribution
   *
   * @param  {TransitionalValues[]} data  Original data (filtered/grouped/splitted)
   * @param  {object}       head  Original series names
   * @param  {ChartOptions} opts  Chart options (max series per percentile + show median)
   * @return {SeriesHeader}       Selected series names
   */
  private static findClosestToPercentiles(data: TransitionalValues[], head: object, opts: ChartOptions): SeriesHeader {
    const max = opts.linesRelevantSeries;
    const median = opts.linesShowMore === 'median';
    const avg = opts.linesShowMore === 'average';
    const qData = ChartingHelpers.calculatePercentiles(data, head);
    // Start with median (q50) to find closest series, then whiskers (q10 & q90)
    const orderedQ = ['q50', 'q10', 'q90'];
    const stdDev = {};
    const closest = [];
    // Append median/average series
    if (median) {
      data.forEach((d, index) => d.q50 = qData[index].q50);
    }
    if (avg) {
      data.forEach((d, index) => d.q50 = qData[index].avg);
    }
    /*
     * Calculate standard deviations
     * Use a 'handicap' method to add a penalty on incomplete series (< 70% of values):
     * dev = stdDev * max(1, nbTotal / (1,4 * nbSeries))) ^ 3
     * >70% => dev = stdDev
     * 50%  => dev = ~3 x stdDev
     * 30%  => dev = ~14 x stdDev
     * <20% => dev = Infinity
     */
    orderedQ.forEach(q => {
      stdDev[q] = [];
      Object.keys(head).forEach(key => {
        const dev = { key, n: 0, d2: 0, d: 0, penalty: 1 };
        data.forEach((d, index) => {
          if (key in d) {
            dev.n++;
            dev.d2 += Math.pow(qData[index][q] - d[key], 2);
          }
        });
        // Apply handicap
        dev.penalty = dev.n > .2 * data.length ? Math.pow(Math.max(1, data.length / (1.4 * dev.n)), 3) : Infinity;
        dev.d = dev.n ? dev.d2 / dev.n * dev.penalty : Infinity;
        stdDev[q].push(dev);
      });
      stdDev[q] = orderBy(stdDev[q], 'd', 'asc').map(dev => dev.key);
    });
    // Find 'max' closest series for each quantile (starting with mean and removing already taken series)
    orderedQ.forEach(q => {
      closest.push(...stdDev[q].filter(key => !closest.includes(key)).slice(0, max));
    });
    const newHead: SeriesHeader = closest.reduce((qHead, key) => {
      qHead[key] = head[key];
      return qHead;
    }, {});
    // Append median as last to draw lines on top of series
    if (median) newHead['q50'] = 'Median';
    if (avg) {
      newHead['q50'] = 'Overall trend (' + Object.keys(head).length + ')';
    }
    return newHead;
  }

  /**
   * This function is there to replace the calls to dayjs.startOf (documentation below)
   * https://github.com/iamkun/dayjs/blob/dev/docs/en/API-reference.md#start-of-time-startofunit-string
   * date.startOf takes a unit of time and returns the date rounded to the given unit
   * ie date.startOf('day') will return the same date but with time at 00:00:00:000
   * We can keep using date.startOf for every valid unit of time but for custom time units we have to
   * do it manually
   * If the chart is in local timezone, the ouput will have the UTC timestamp of the local group start.
   * Returning correct data is handled in specific formatting function
   */
  private static roundTimeValueFromGroup(
    value: number,
    group: string,
    groupBy: SelectableGroupBy,
    timezone: DateTimezone,
  ): number {
    // No grouping
    if (group === 'none') {
      return value;
    }

    /*
     * authorizedValues is the valid values that can be sent to the dayjs.startOf function
     * if we have an unvalid value, we take the groupBy metric to round the value
     */
    const authorizedValues = ['hour', 'day', 'week', 'isoWeek', 'month', 'year', 'date', 'quarter'];

    // Native dayjs units
    const date = DateHelper.getDayjs(value, timezone);
    if (authorizedValues.includes(group)) {
      return date.startOf(group as dayjs.UnitType).valueOf();
    }

    /*
     * Below, we will handle grouping by hours/minutes
     * Grouping by hours respects the chart config (local or UTC)
     * If we group by 6hours we want the following buckets:
     * 31/10 00:00
     * 31/10 06:00
     * 31/10 12:00
     * 31/10 18:00
     */
    let { samplingUnit: unit, samplingDuration: duration = 1 } = groupBy;
    if (!unit) {
      unit = groupBy.value as dayjs.ManipulateType;
    }

    // Unsupported value
    if (!authorizedValues.includes(unit)) {
      if (duration) {
        // Only trigger warning if samplingDuration is non-zero (else keep time values as is)
        console.warn(`Invalid time unit for groupBy value ${unit} in ${JSON.stringify(groupBy)}`);
      }
      return value;
    }

    // for 6hours grouping, unit will be `hour` so this will give the hours part of the datetime
    const dateUnit = date[unit]();

    // we find the bucket hour (in case of 6hours: 0,6,12,18)
    const roundedDate = date[unit](dateUnit - dateUnit % duration);

    // set the date on the hour and get the "start of it" (removing minutes)
    return roundedDate.startOf(unit).valueOf();
  }

  /**
   * Takes a timestamp and a time related metric and renders the timestamp into a local string which is
   * coherent with the level of time in the metric
   * For any metric that has its unit of time greater than a day it will render only the local date
   * Else it will render the local date and time
   * This function renders time as local for it is intended to work with plotly which handles only
   * local times
   * @param value A timestamp in ms
   * @param groupBy A time related metric
   */
  public static renderTimeToGroupByLevel(date: dayjs.Dayjs, groupBy: SelectableGroupBy): string {
    const unit = groupBy?.value || groupBy?.samplingUnit;
    // these units are taken from dayjs types
    const upper = [
      'year',
      'y',
      'quarter',
      'month',
      'm',
      'week',
      'w',
      'day',
      'date',
      'datetime',
      'd',
    ];

    if (!date.isValid()) {
      return ChartingHelpers.nullGroupTitle;
    }

    if (!upper.includes(unit)) {
      return date.format('LLL');
    }

    let format = '';
    // These formats should be aligned with TimeCreator.getXAxisFormattingForTimeAxes to be consistent
    switch (unit) {
      case 'year':
      case 'y':
        format = 'YYYY';
        break;
      case 'quarter':
        return `Q${date.quarter()} ${date.year()}`;
      case 'month':
        format = 'MMMM YYYY';
        break;
      default:
        format = 'LL';
        break;
    }
    return date.format(format);
  }

  /**
   * Get operation & normalize values from mode
   *
   * @param  {string} mode  Config mode
   * @return {object}       Operation & normalization type
   */
  private static getOperation(mode: CalcOperation): Operation {
    let operation = mode;
    let normType: NormalizationType = null;

    /*
     * normCount is a metric which takes the count and normalizes it be the total (independ of groups)
     * so we switch the mode to count and apply the normalization later
     */
    if (mode === 'normCount') {
      operation = 'count';
      normType = 'totalCount';
    }

    if (mode === 'normSum') {
      operation = 'sum';
      normType = 'totalSum';
    }
    return { operation, normType };
  }

  private static adjustValueToInterval(
    val: number,
    calc: ChartCalcConfig,
    timeMode: TimeMode,
    prop: RawDataPoint,
    dateStart: string,
    dateEnd: string,
  ): number {
    /*
     * if we are in adjustToInterval mode, it means that we have to go over each contract/workpackage
     * and adjust the aggregated variable to the period of the workpackage/contract which might be
     * cut by the interval
     */
    const xAxis = calc.xaxis;
    if (xAxis.extent && timeMode == 'adjustToInterval') {
      // getting the dates applied on the interval
      const intervalStart = xAxis.extent[0];
      const intervalEnd = xAxis.extent[1];

      // getting the dates on the contract/workpackage
      let start = getChained<number>(prop, dateStart);
      let end = getChained<number>(prop, dateEnd);

      // shifting the dates to the interval if necessary (cut the WP/contract)
      if (getChained<number>(prop, dateStart) < intervalStart) {
        start = intervalStart;
      }

      if (getChained<number>(prop, dateEnd) > intervalEnd) {
        end = intervalEnd;
      }

      // check how much we have cut and multiply the aggregated variable by the proportion
      const proportion = (end - start) / (getChained<number>(prop, dateEnd) - getChained<number>(prop, dateStart));
      val = val * proportion;
    }

    return val;
  }

  public static makeSeriesForCharting(
    transitionalSeries: TransitionalSeries,
    dataSeries: DataSeries,
    yAxis: YAxis,
  ): ChartSeries {
    const data = transitionalSeries.data;
    // Make a shallow copy, as header is shared between all chart series of the same heavy series
    const header = { ...transitionalSeries.header };
    /*
     * find the extremes of current serie
     * all extremes have to be kept and later are used to align the series
     */
    let maxX = null;
    let minX = null;
    let minY = null;
    let maxY = null;
    const firstYvalue = Object.keys(header)[0];

    for (const d of data) {
      const y = getChained(d, firstYvalue);

      /*
       * Only values of type number are used for min and max values
       * we can have a mix of numeric and string values on x-axis.
       * On y-axis however we should have only numeric values
       */
      if (typeof d.x === 'number') {
        if (maxX == null || d.x > maxX) {
          maxX = d.x;
        }

        if (minX == null || d.x < minX) {
          minX = d.x;
        }
      }

      if (minY == null || y < minY) {
        minY = y;
      }

      if (maxY == null || y > maxY) {
        maxY = y;
      }
    }
    // Can't use lodash's orderBy function because xaxis may be of mixed type
    data.sort((a, b) => this.numOrStringCompare(a.x, b.x));

    // Create chart series
    const chartSeries: ChartSeries = { ...dataSeries, header, data, maxX, minX, minY, maxY };

    /** Options defined in the selected metric and reported to the chart series (see ChartSeriesOptions) */
    [
      'yaxisId',
      'title',
      // Plotly yaxis options (tickformat, ticksuffix, range & rangemod)
      'format',
      'suffix',
      'range',
      'rangemode',
      // Plotly line & bar options
      'color',
      'width',
      'lineMarkers',
      'lineOpacity',
      'dashed',
      'stepped',
      // Internal options
      'hideTotal',
      'connectGaps',
      'toPercent',
    ].forEach(optionName => {
      if (yAxis[optionName] && !chartSeries[optionName]) chartSeries[optionName] = yAxis[optionName];
    });

    return chartSeries;
  }

  public static findSelectValue<T extends SelectableValue>(
    name: ChartSelectKey,
    display: ComponentStateOptions,
    selects: ChartSelects,
  ): T {
    // this function might be called for other chart options (not only the selects)
    if (!isChartSelect(name)) {
      return;
    }

    const select = selects[name] as ChartSelect<T>;
    /*
     * in some situations the metric is not in the list of metrics
     * that can happen if the selects are not there, eg metric not in the list
     */
    if (!select?.values) {
      return null;
    }

    // In case multiple values is supported, always keep the first value
    let selectValue = display[name];
    if (selectValue) selectValue = StringHelper.splitByCommas(selectValue)[0];

    /*
     * go over all options for the given select (all groupbys/metrics etc)
     * and find the one that is selected
     */
    const values = select.values;
    for (const selectedMetric of values) {
      // metric or any select-like config should have a value or modes list
      if (selectedMetric.value == null) {
        console.error('Any select in the config should have a value: ' + JSON.stringify(selectedMetric));
      }
      if (selectedMetric.value.toString() === safeString(selectValue)) {
        return selectedMetric;
      }
    }

    // If force is set, we take the first defined metric
    if (select.force) {
      const value = ChartingHelpers.firstSelectValue<T>(select);
      if (value) {
        return value;
      }
    }

    if (ChartingHelpers.selectValueIsACustomField(selectValue)) {
      console.warn('Custom select value not found, falling back on first select value.');
      const value = ChartingHelpers.firstSelectValue<T>(select);
      if (value) {
        return value;
      }
    }

    // if we are here the select wasn't found inside the above loop.
    console.warn('Select found but didnt find value: ' + display[name] + ' select: ' + JSON.stringify(select));
    return null;
  }

  public static findSelectMetrics(serializedValue: string, select: ChartSelect<SelectableMetric>): SelectableMetric[] {
    const metrics: SelectableMetric[] = [];
    const selectValues = serializedValue ? StringHelper.splitByCommas(serializedValue).filter(val => val !== '') : [];

    /*
     * go over all options for the given select (all groupbys/metrics etc)
     * and find the one that is selected
     */
    const values = select.values;
    for (const selectedMetric of values) {
      // metric or any select-like config should have a value or modes list
      if (selectedMetric.value === null) {
        console.error('Series select in the config should have a value: ' + JSON.stringify(selectedMetric));
      }
      if (selectValues.includes(selectedMetric.value)) {
        metrics.push(selectedMetric);
      }
    }
    return metrics;
  }

  /**
   * Return the first select value of a ChartSelect
   */
  public static firstSelectValue<T extends SelectableValue>(select: ChartSelect<T>): T {
    if (!select?.values?.length) {
      return null;
    }
    return select.values[0];
  }

  /** Check if select value property name starts with 'cf_'. */
  private static selectValueIsACustomField(selectValue: string): boolean {
    return /cf_\w+$/gm.test(selectValue);
  }

  /**
   * Extract columns, modes & operator from literal formula
   * Eg. 'sum:columnX/count:columnY' => { [columnX, columnY], [sum, count], '/' }
   *
   * @param  {Aggregation}        aggregation  Chart calculus config
   * @return {ComplexAggregation}              Operations definition
   */
  private static getOperations(aggregation: Aggregation): ComplexAggregation {
    // Nested operations
    if (isNestedAggregation(aggregation)) {
      return { variables: ['__nestedCalculated'], operations: [aggregation.globalOperation], arithmeticOperator: null };
    }
    // Complex operations
    if (isComplexAggregation(aggregation)) {
      return { ...aggregation };
    }
    // Classic case with one operation
    return {
      variables: [aggregation.variable],
      operations: [aggregation.operation ?? 'none'],
      arithmeticOperator: null,
    };
  }

  /**
   * This function calculate a sub value. If we have an operator
   * it means we are in a complexe operation. So we have to calculte the result
   * from operation. Otherwise we just return the subValue
   */
  private static getSubValue(subValues: ChartSplit, operator: string): number {
    if (!operator) {
      return subValues.ops[0][0];
    }
    switch (operator) {
      case '+':
        return subValues.ops[0][0] + subValues.ops[1][0];
      case '-':
        return subValues.ops[0][0] - subValues.ops[1][0];
      case '*':
        return subValues.ops[0][0] * subValues.ops[1][0];
      case '/':
        return subValues.ops[1][0] ? subValues.ops[0][0] / subValues.ops[1][0] : 0;
    }
  }

  /**
   * Transform polar series
   *
   * @param  {ChartCalcConfig} calc  Chart options
   * @param  {RawDataPoint[]}  raw   Raw data
   * @return {TransitionalSeries}       Polar series
   */
  private static transformPolarSeries(calc: ChartCalcConfig, raw: RawDataPoint[]): TransitionalSeries {
    const { variables } = ChartingHelpers.getOperations(calc.yaxis.aggregation);
    const header = {};
    for (const col of variables) {
      header[col] = col;
    }
    return { header, data: raw, xAxisType: 'numeric' } as TransitionalSeries;
  }

  /**
   * Custom compare function for NumOrString.
   * String values are always put at the end, regardless of ascending or descending order.
   * Use Array.sort with this function when willing to sort NumOrString values,
   * as lodash.orderBy is inconsistent between browsers:
   *
   * testArray = [{ 'x': 12 }, { 'x': 3 }, { 'x': 'b' }, { 'x': 5 }, { 'x': 'a' }, { 'x': 10 }, { 'x': -3 }];
   *
   * lodash.orderBy(testArray, d => d.x);
   *   Chrome:  [{ 'x': -3 }, { 'x': 3 }, { 'x': 5 }, { 'x': 10 }, { 'x': 12 }, { 'x': 'a' }, { 'x': 'b' }]
   *   Firefox: [{ 'x': 3 }, { 'x': 12 }, { 'x': 'b' }, { 'x': 5 }, { 'x': 'a' }, { 'x': -3 }, { 'x': 10 }]
   *
   * testArray.sort((a, b) => ChartingHelper.numOrStringCompare(a.x, b.x));
   *   Chrome and firefox: [{ 'x': -3 }, { 'x': 3 }, { 'x': 5 }, { 'x': 10 }, { 'x': 12 }, { 'x': 'a' }, { 'x': 'b' }]
   */
  public static numOrStringCompare(a: NumOrString, b: NumOrString, order: 'asc' | 'desc' = 'asc'): number {
    if (order === 'desc') {
      // Inverse numA and numB
      const bTemp = b;
      b = a;
      a = bTemp;
    }

    const numA = Number(a);
    const numB = Number(b);
    const aIsNumber = !isNaN(numA);
    const bIsNumber = !isNaN(numB);

    // If only one of the inputs represents a number, make it first
    if (aIsNumber !== bIsNumber) {
      return aIsNumber ? -1 : 1;
    }

    // If both represent numbers, compare them like numbers
    if (aIsNumber) {
      if (numA < numB) return -1;
      if (numA > numB) return 1;
      if (typeof a === typeof b) return 0;

      // Both inputs represent the same number, but one is a string and the other a number. Numbers go first.
      return typeof a === 'number' ? -1 : 1;
    }

    // Otherwise, both inputs are non-number strings
    return (a as string).localeCompare(b as string);
  }

  /**
   * Custom sort used to sort Plotly traces.
   * We sort according to the order of the order array.
   * We always pull the null value last.
   *
   * @param nullTitle Name of the null value
   * @param order Array of ordered traces name
   */
  public static sortTraces(a: string, b: string, nullTitle: string, order: string[]): number {
    if (a === nullTitle) {
      return 1;
    }
    if (b === nullTitle) {
      return -1;
    }
    if (order == null) {
      return 0;
    }
    const i1 = order.indexOf(a);
    const i2 = order.indexOf(b);
    if (i1 === -1 && i2 === -1) {
      return 0;
    }
    if (i1 === -1 && i2 !== -1) {
      return 1;
    }
    if (i1 !== -1 && i2 === -1) {
      return -1;
    }
    return i1 - i2;
  }

  /**
   * Iterate over the results of an heavy query and create unique labels.
   * It will add spaces around duplicated values to create an unique label.
   */
  public static makeUniqueHeavyLabels(heavyData: HeavyDataSeries): void {
    // Make unique group titles
    const xLabels = [];
    heavyData.data.forEach((value, index) => {
      let x = value.x;
      /*
       * This piece of code surrounds x value with spaces until x label is unique.
       * This issue can happen for any discrete groupby where there are identical titles for different ids in heavy
       * charts (e.g. on Vessel Utilization page with groupby vessel)
       */
      if (x && typeof x !== 'number') {
        let changed = false;
        while (xLabels.includes(x)) {
          x = ` ${x} `;
          changed = true;
        }
        if (changed) {
          heavyData.data[index].x = x;
        }
      }
      xLabels.push(x);
    });
    // Make unique split titles
    const splitLabels = [];
    Object.entries(heavyData.header).forEach(([key, value]) => {
      let changed = false;
      while (splitLabels.includes(value)) {
        value = ` ${value as string} `;
        changed = true;
      }
      if (changed) {
        heavyData.header[key] = value;
      }
      splitLabels.push(value);
    });
  }

  public static getAdditionalProp(additionalProps: SeriesSplit[], key: string): SeriesSplit {
    return additionalProps?.find(additionalProp => additionalProp.prop === key);
  }

  /**
   * In series with temporal x, fill gap (i.e. empty intervals) with null values (default) or previous (cumulative).
   * It is done so that connectGaps = 'tozero' or 'hide' works on line and bar charts
   *
   * @param data TransitionalValues[]
   * @param groupbyStringDuration granularity of the groupby
   * @param interval the overall interval on which null values need to be added
   * @param cumulative if the metric is cumulative (then fill with previous value, not null)
   * @returns TransitionalValues[] with null values inserted at every empty 'groupbyPeriod'
   */
  public static fillGapsWithValues(
    data: TransitionalValues[],
    duration: Duration,
    interval?: Interval,
    cumulative?: boolean,
  ): TransitionalValues[] {
    const { n, unit } = duration;
    const result: TransitionalValues[] = [];

    /**
     * We want the whole selected interval to be filled with null values. We ensure it by pushing a null value
     * at the beginning and another at the end. Then passing through the following data.forEach will do the trick.
     */
    ChartingHelpers.addNullValuesAtExtrema(data, result, { n, unit }, interval);

    data.forEach((d: TransitionalValues) => {
      if (result.length === 0) return result.push(d);

      let newItemDate = dayjs.utc(result[result.length - 1].x).startOf(unit).add(n, unit);
      const currentItemDate = dayjs.utc(d.x).startOf(unit);

      // Fill interval until currentItemDate is reached
      while (currentItemDate.diff(newItemDate, unit) > 0) {
        const x = newItemDate.valueOf();
        // Either copy last value (cumulative) or fill with null value on 'All' split (default)
        const fillValue = cumulative ? { ...d, x } : { 'All': null, x };
        result.push(fillValue);
        newItemDate = newItemDate.add(n, unit);
      }
      // Finally we push the current element
      result.push(d);
    });

    return result;
  }

  /**
   * Return true if metric is cumulative
   */
  public static isCumulative(metric: SelectableMetric): boolean {
    /*
     * TODO: SP-8013
     * metric.value can be null in case of 'useReturnedMetric'
     * this will be replaced by metric<->dataseries relation
     * at then end metric.value should be always provided
     * currently when useReturnedMetric is used there is no aggregation only variable
     */
    const aggregation = ChartingHelpers.metricColumn(metric.value);

    return (
      (isSimpleAggregation(aggregation) && ['cum', 'pcum'].includes(aggregation.operation))
      || (isNestedAggregation(aggregation) && ['cum', 'pcum'].includes(aggregation.globalOperation))
    );
  }

  /** Push one null value at the beginning and at the end of interval, if it does not already exist in data. */
  private static addNullValuesAtExtrema(
    data: TransitionalValues[],
    result: TransitionalValues[],
    groupbyDuration: Duration,
    interval: Interval,
  ): void {
    if (!data?.length || !interval?.length) return;

    const [intervalStart, intervalEnd] = interval;
    const dataStart = data[0].x as number;
    const dataEnd = data[data.length - 1].x as number;

    if (
      intervalStart && intervalStart < dataStart
      && !DateHelper.areDatesInSameRange(intervalStart, dataStart, groupbyDuration)
    ) {
      // We push to result array instead of pushing at the beginning of data array for perf reasons
      result.push({ x: dayjs.utc(intervalStart).startOf(groupbyDuration.unit).valueOf(), 'All': null });
    }
    if (
      intervalEnd && intervalEnd > dataEnd
      && !DateHelper.areDatesInSameRange(intervalEnd, dataEnd, groupbyDuration)
    ) {
      data.push({ x: dayjs.utc(intervalEnd).startOf(groupbyDuration.unit).valueOf(), 'All': null });
    }
  }
}
