<fieldset class="spin-filters-fieldset">
  <ng-container *ngIf="fieldset.fields.length">
    <div>
      <h3 class="spin-filters-fieldset-master-filter-title">{{ fieldset.title }}</h3>
    </div>
    <div class="fieldset-fields" [ngStyle]="getFieldsetFieldsStyle()">
      <ng-container *ngFor="let field of fieldset.fields">
        <ng-container
          [ngSwitch]="field.filterType"
          *ngIf="field.filteringEnabled != false && field.filterType && field.visible != false">
          <spin-filters-interval
            *ngSwitchCase="'interval'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-interval
            *ngSwitchCase="'datetime'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-interval *ngSwitchCase="'date'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

          <spin-filters-number *ngSwitchCase="'number'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

          <spin-filters-doubledate
            *ngSwitchCase="'doubledate'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-doubledate
            *ngSwitchCase="'intersection'"
            [field]="$any(field)"
            (onchange)="onbubblingchange($event)" />

          <spin-filters-checkbox
            *ngSwitchCase="'checkbox'"
            [field]="$any(field)"
            (checkboxFilterChange)="onbubblingchange($event)" />

          <spin-filters-field *ngSwitchCase="'multi'" [field]="field" (onchange)="onbubblingchange($event)" />

          <spin-filters-field *ngSwitchCase="'excludemulti'" [field]="field" (onchange)="onbubblingchange($event)" />

          <spin-filters-field
            [haveNoTags]="true"
            *ngSwitchCase="'single'"
            [field]="field"
            (onchange)="onbubblingchange($event)">
          </spin-filters-field>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</fieldset>
