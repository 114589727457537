import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, inject, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { sortBy } from 'lodash-es';

import { FieldSettings, FilterApplied, MultiOption, OptionValue } from '../helpers/types';
import { AppInfoService } from '../app/app-info-service';
import { MultiFilter } from './multi-interface';
import { Ordering } from '../helpers/ordering';
import { MultiComponent, MultiHelper } from './multi';
import { SingleComponent } from './single';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { FilterHelper } from './filter-helper';
import { DataPoint } from '../data-loader/data-loader.types';

@Component({
  selector: 'spin-filters-field',
  templateUrl: 'field.html',
  styleUrls: ['field.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CdkScrollable,
    NgIf,
    MultiComponent,
    NgClass,
    MatTooltipModule,
    SingleComponent,
    NgFor,
  ],
})
export class FieldComponent {
  @Input()
  field: FieldSettings;
  @Input()
  editMode: boolean = true;
  @Input()
  haveNoTags = false;

  public readonly small = input<boolean>(true);
  public unavailableTags: MultiOption<null>[] = [];
  public tags: MultiOption<null>[] = [];

  @Output()
  onchange = new EventEmitter<FilterApplied>();

  @ViewChild('filters')
  public $filters: MultiFilter;

  private readonly appInfoService = inject(AppInfoService);

  public update(checkForDefault: boolean = false): void {
    const optionValues = this.sort(this.field.values).filter(v =>
      v.value !== MultiHelper.SELECT_ALL_VALUE && v.value !== MultiHelper.BLANK_OPTION_VALUE
    );
    this.$filters.updateData$.next(optionValues);

    const unavailableTagValues = this.unavailableTags.map(t => t.value);

    // After update the field values we check if we have some sett
    optionValues.forEach(optionValue => {
      if (optionValue.value && MultiHelper.includesStringOrNumber(unavailableTagValues, optionValue.value)) {
        this.unavailableTags = this.unavailableTags.filter(t => t.value != optionValue.value);
        this.addTags([optionValue]);
      }
    });

    this.refreshIsIndeterminate();
    if (checkForDefault) this.setDefault();
  }

  /**
   * Sort is done on field.ordered (which is the ordered list of item titles) if existing, otherwise on value.order or
   * value.title in alphabetical order.
   */
  public sort(values: OptionValue[]): OptionValue[] {
    if (!values) return [];

    /** Check if field ordered */
    if (this.field.ordered) {
      /** Sort based on ordered list */
      values.sort((a, b) => {
        return Ordering.fixedOrder(a.title, b.title, this.field.ordered);
      });
    } else {
      /** Sort based on propOrder or title */
      values = sortBy(values, value => value.order ?? value.title);
    }

    if (this.field.orderDirection && this.field.orderDirection === 'desc') {
      values.reverse();
    }

    return values;
  }

  /** If no value is currently selected and we have a default value, select it */
  public setDefault(): void {
    if (this.tags.length || !this.field.default) return;
    const defaultValue = FilterHelper.getDefaultFieldValue(this.field);
    if (!defaultValue) return;
    this.set(defaultValue, true);
  }

  /**
   * Would reset the field to its default value, or clear it if it doesn't have one.
   * @param fire
   */
  public reset(fire: boolean = true): void {
    if (this.field.default === undefined) {
      this.clear(fire);
      return;
    }

    this.set(FilterHelper.getDefaultFieldValue(this.field), fire);
    this.updateFieldValue(fire);
  }

  /**
   * Would clear the field, even if it has a default value.
   * @param fire if the field should emit that his value has changed
   */
  public clear(fire: boolean = true) {
    this.tags = [];
    this.unavailableTags.length = 0;
    this.$filters.clear();
    this.updateFieldValue(fire);
  }

  public set(valuesToSelect: unknown[] = [], fire: boolean = false): void {
    /*
     * if we set with no values it's like we reset the filter
     * but without default value
     */
    if (!valuesToSelect.length) {
      this.clear(fire);
      return;
    }

    /**
     * If we have a select all option, we need to add it to the list of values
     * and to the list of tags
     *
     * But don't set `alreadyChosen: true` for all the options because we have to toggle
     * only for the ones passed in `values`
     */
    const selectAllChecked = MultiHelper.includesSelectAll(valuesToSelect);
    const blankOptionChecked = MultiHelper.includesBlank(valuesToSelect);

    if (selectAllChecked) {
      if (!MultiHelper.includesSelectAll(this.field.values.map(v => v.value))) {
        this.field.values.unshift(MultiHelper.SELECT_ALL_OPTION);
      }

      this.addTags([MultiHelper.SELECT_ALL_OPTION]);
      valuesToSelect = valuesToSelect.filter(v => v != MultiHelper.SELECT_ALL_VALUE);
      this.$filters.setSelectAllOnly();
    }

    if (blankOptionChecked) {
      if (!MultiHelper.includesBlank(this.field.values.map(v => v.value))) {
        this.field.values.unshift(MultiHelper.BLANK_OPTION);
      }

      this.addTags([MultiHelper.BLANK_OPTION]);
      valuesToSelect = valuesToSelect.filter(v => v !== MultiHelper.BLANK_OPTION_VALUE);
      this.$filters.toggle$.next({ payload: [MultiHelper.BLANK_OPTION_VALUE], chosen: true });
    }

    const tags: MultiOption<null>[] = [];
    valuesToSelect.forEach(value => {
      const cmpAttr = typeof value === 'string' && isNaN(parseInt(value)) && !this.field.id.match(/[Cc]ountry/)
        ? 'title'
        : 'value';
      /*
       * Before testing setted values, we cast tested and testing value in string
       * We're doing this to avoid cast issue (e.g. we can populate multi with bool values
       * in this case we want to test if value == "false" and not value == false)
       */
      const relatedFieldValue = this.field.values
        ? this.field.values
          .filter(v =>
            (String(v[cmpAttr]) === String(value))
            || (v[cmpAttr] && String(v[cmpAttr]['id']) === String(value))
            || v['id'] === value
          )[0]
        : null;
      if (!relatedFieldValue) {
        this.handleTagWithNoValue(value);
        return;
      }
      tags.push(relatedFieldValue as MultiOption<null>);
    });
    this.addTags(tags, false);
    /*
     * apply fire only when we add all tags, we do this to
     * avoid emit to many events
     */
    if (fire) {
      this.fire();
    }

    this.$filters.toggle$.next({ payload: valuesToSelect, chosen: true });
    this.refreshIsIndeterminate();
  }

  /**
   * @description this function is used to find out three things
   *   - if we have to set the select all option, all option are selected except select all then we set it
   *   - if only one option is selected and it is select all then we remove it
   *   - if the list has some selected options in which case we set the indeterminate state
   *   - else we remove the indeterminate state
   * @returns {void}
   */
  public refreshIsIndeterminate(): void {
    if (!this.$filters.showSelectAll || this.field.filterType === 'entity') {
      return;
    }
    const optionsLength = this.$filters.getOptionsLength();

    if (this.tags.length === 1 && this.$filters.selectAllChecked) {
      this.$filters.toggleAll$.next();
      this.tags = [];
      return;
    }

    if (this.tags.length + 1 === optionsLength && !this.$filters.selectAllChecked) {
      this.$filters.isPartialSelection.set(false);
      this.$filters.toggleAll$.next();
      this.addTags([MultiHelper.SELECT_ALL_OPTION], false, false);
      return;
    }

    if (
      this.tags.length > 0
      && this.tags.length !== optionsLength
    ) {
      this.$filters.isPartialSelection.set(true);
      return;
    }

    this.$filters.isPartialSelection.set(false);
  }

  /**
   * Fires filter change (default).
   * @emits onchange<FilterApplied>
   */
  public fire(): void {
    this.refreshIsIndeterminate();

    const values = this.tags.map(tag => tag.value).concat(this.unavailableTags.map(tag => tag.value));
    const active = !!values.length;
    const filterWithValues = new FilterApplied({ ...this.field, filterTitle: this.field.title, active, values });
    this.onchange.emit(filterWithValues);
  }

  /**
   * Add tag to `FieldComponent.tags` if not already present (based on their value or title).
   * Those tags are later used in `FieldComponent.fire` to apply filters, update app data & url state
   *
   * @param {MultiOption<null>} multiOptions - option add to `FieldComponent.tags` if not already present
   * @param {boolean} fire - if true, fire filter change, update url state
   * @param {boolean} userAction - if true, user action is logged
   * @returns {void}
   */
  public addTags(
    multiOptions: MultiOption<null>[],
    fire: boolean = false,
    userAction: boolean = false,
  ): void {
    const titleOrValuesTags = this.tags.map(item => item.value ?? item.title);
    const missingValues = multiOptions.filter(item => !titleOrValuesTags.includes(item.value ?? item.title));

    /*
     * We should compare items by value, not title
     * `value` should always be set
     */
    if (missingValues.length === 0) {
      return;
    }

    /**
     * A field with single type can only have one tag at once
     */
    if (this.field.filterType === 'single') {
      this.tags = [];
    }

    missingValues.forEach(item => {
      if (item.title || typeof item.title === 'boolean') {
        this.tags.push(item);
        return;
      }

      this.unavailableTags.push(item);
    });

    this.$filters.hasUnavailableTags = this.unavailableTags.length > 0;

    if (userAction) {
      this.appInfoService.userAction(this.field.id, false, true);
    }

    this.updateFieldValue(fire);
  }

  /** Fill field.value with selected tags */
  private updateFieldValue(fire: boolean): void {
    this.field.value = this.tags.map(tag => tag.value);

    if (fire) {
      this.fire();
    }
  }

  // Many fields can share the same referential dataset (eg all the managers)
  public getFieldMappingId(): string {
    if (this.field.id === 'vesselManager' || this.field.id === 'rigManager') {
      return 'manager';
    }
    return this.field.id;
  }

  /**
   * @description Filter has been passed, but we didn't find any match within field.values
   * then we try to see if we can match with refData. Finally we add it in the unavailable tag list to display
   * it to the user
   *
   * @param value - value passed by set that itself receives value from filter state
   */
  public handleTagWithNoValue(value: any): void {
    // __nullValues is a special value and shouldn't been added to tag list
    if (value === '__nullValues') {
      return;
    }

    // Start with null if value is a number (entity ID)
    let title = (typeof value === 'number') || value.match(/^\d+$/) ? null : value;

    if (this.field.id === 'vessel' && RefDataProvider.currentProjectVesselDataset) {
      title = (RefDataProvider.currentProjectVesselDataset.find((spec: DataPoint) => spec['vesselId'] === value) as any)
        ?.title;
    } else {
      // If the tag we try to add is from persistent filter we get in refData the corresponding title
      title = RefDataProvider.getLocalItemIfLoaded(this.getFieldMappingId(), value)?.title;
    }
    this.addTags([{ title, value }], false);
  }

  /**
   * Remove all unavailable tags
   */
  public removeUnavailableTags(): void {
    this.appInfoService.userAction(this.field.id, true, true);
    this.unavailableTags.length = 0;
    this.$filters.hasUnavailableTags = false;
    this.fire();
  }

  /**
   * @description this function is triggered either when we remove tag from field (then we notify
   * spin-filters-multi to update `alreadyChosen`)
   *
   * or when we click a selected option in spin-filters-multi
   *
   * @param {any} value - value to remove from tags, corresponds to MultiOption.value
   * @param {boolean} fire - if true, fire filter change, update url state
   * @param {boolean} userAction - if true, user action is logged
   */
  public removeTag(value: any, fire: boolean = true, userAction: boolean = false): void {
    if (value === 'reset') {
      this.reset();
      return;
    }

    if (value === MultiHelper.SELECT_ALL_VALUE) {
      this.tags = [];
    }

    const i = this.tags.findIndex(tag => tag.value === value);

    if (i !== -1) {
      this.tags.splice(i, 1);
    }

    if (userAction) {
      this.appInfoService.userAction(this.field.id, true, true);
    }

    this.updateFieldValue(fire);

    this.$filters.toggle$.next({ payload: [value], chosen: false });
  }

  removeTagWhenSelectAllChecked(option: MultiOption<null>): void {
    this.$filters.handleElement(option);
  }

  public getNotChosenItems(): MultiOption<null>[] {
    return this.$filters.getNotChosenItems();
  }

  public tagValue(_: number, tag: MultiOption<null>): string | number {
    return tag.value;
  }
}
