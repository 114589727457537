import { get, set } from 'lodash-es';

import { DataLoader } from '../data-loader/data-loader';
import { NavigationHelper } from './navigation-helper';
import { ComponentOptionsTab, ComponentParameter, ComponentSettings, DynamicConfig, DynamicConfigParameters,
  FiltersState } from './types';
import { FilterMetric, RawDataPoint } from '../graph/chart-types';
import { DataHelpers } from './data-helpers';
import { Ordering } from './ordering';
import { getChained } from '../data-loader/ref-data-provider';

type DynamicConfigResult<T> = { dynamicConfigHasChanged: boolean; componentSettings: T };

/**
 * This class contains static methods that are required to setup parts
 * of components, mostly used in component wrappers
 */
export class ComponentHelper {
  /**
   * @description This method will replace all dynamic config part in componentSettings argument, with latest config
   * fetched from backend
   *
   * Nested or overlapping dynamic are supported, but not recommended, since we don't check the order
   *
   * @param dynamicConfig    parts of componentSettings that are dynamic, and might need to be updated
   * @param componentSettings  the current componentSettings or part of it
   * @param dataLoader       the dataLoader to use to fetch the dynamic config
   * @param parameters       the current data structure of parameters, used to inject in the dynamic config url
   * @returns                new config, and whether it has changed
   */
  public static async populateDynamicConfigs<T extends ComponentSettings>(
    dynamicConfig: DynamicConfig[],
    componentSettings: T,
    dataLoader: DataLoader,
    parameters?: DynamicConfigParameters,
  ): Promise<DynamicConfigResult<T>> {
    let dynamicConfigHasChanged = false;

    const promises = [];
    for (const config of dynamicConfig) {
      promises.push(
        ComponentHelper.fetchAndReplaceDynamicConfig(
          config,
          componentSettings,
          dataLoader,
          parameters,
        ),
      );
    }

    await Promise.all(promises).then((results: DynamicConfigResult<T>[]) => {
      results.forEach(result => {
        if (!result.dynamicConfigHasChanged) return;
        dynamicConfigHasChanged = true;
        componentSettings = result.componentSettings;
      });
    });

    return {
      dynamicConfigHasChanged,
      componentSettings,
    };
  }

  private static interpretKeywordsInDynamicConfigURL(
    urlWithKeywords: string,
    componentId: string,
    parameters: DynamicConfigParameters,
  ): string {
    let urlToComplete = urlWithKeywords;

    // Check @addConfigFiltersPath keyword
    if (urlToComplete.includes('@addConfigFiltersPath')) {
      // Adding filtersPath & componentId, required for tab filters
      urlToComplete = NavigationHelper.appendParamsToUrl(
        urlToComplete.replace('@addConfigFiltersPath', ''),
        {
          // We should always have a configFiltersPath
          _filtersPath: parameters.configFiltersPath,
          _componentId: componentId,
        },
      );
    }

    /**
     * Check @addFiltersState keyword
     *
     * "@addFiltersState" keyword retrieve the filtersState and apply them to the current dynamicConfig URL.
     * This behavior is useful to generically append all filters of the chart to the endpoint (its own Chart
     * filters, & sidebar filters, from filterState() function).
     * Useful to avoid adding all "filterStateKEY=[:filterStateVALUE:]" in the config of the endpoint.
     */
    if (urlToComplete.includes('@addFiltersState')) {
      // Instantiate url that will contain the filtersState
      urlToComplete = urlToComplete.replace('@addFiltersState', '');

      // Retrieving only relevant filtersState to append to the url
      const filtersStateToApply = DataLoader.getFiltersStateToApplyForHeavyQuery(
        parameters.componentFilters.filtersState,
        parameters.componentFilters.filtersConfig,
        'heavy',
      );

      /*
       * There is a risk of duplicated filters due to redundant "key=[:value:]" between
       * injectParametersInURL() && filtersStateToApply. Removing duplicated filters to prevent that risk
       */
      ComponentHelper.removeDuplicatedFilter(urlToComplete, filtersStateToApply);

      // Adding filtersState to url
      urlToComplete = NavigationHelper.appendParamsToUrl(
        urlToComplete,
        filtersStateToApply,
      );
    }

    return urlToComplete;
  }

  /**
   * Remove the duplicated filters from url query params & filtersStates
   *
   * @param url           url to parse to retrieve present filters
   * @param filtersState object of filters to reduce
   */
  private static removeDuplicatedFilter(url: string, filtersState: FiltersState): void {
    const searchParams = NavigationHelper.getUrlQueryParams(url);
    let isDuplicatedKey = false;

    for (const key of Object.keys(searchParams)) {
      if (key in filtersState) {
        isDuplicatedKey = true;
        delete filtersState[key];
      }
    }
    if (isDuplicatedKey) {
      console.error('@addFiltersState: Duplicated key between static config URL & filters state for url: ', url);
    }
  }

  /**
   * Complete dynamicConfigUrl.
   * - injectParamsInURL() that replace [:aValue:] syntax by corresponding value
   * - Interpret "@keywords" to append required parameters to URL
   *
   * @param baseUrl           Endpoint url to parse and complete using parameters
   * @param componentSettings Details of the component
   * @param parameters        Data structure of the required parameters to add, as query parameters, to the baseUrl
   *
   * @returns                 urlWithCurrentState - string : The completed url with the query parameters
   *                          isHeavyQuery - Boolean
   */
  private static completeUrlForDynamicConfig<T extends ComponentSettings>(
    baseUrl: string,
    componentSettings: T,
    parameters: DynamicConfigParameters,
  ): string {
    if (!parameters) return baseUrl;

    let parametersToInjectInUrl = parameters.componentParameters;

    /**
     * We first inject params, to resolve "key=[:value:]" pairs inside url
     * We later check the url to resolve keywords like: "@addFiltersState", "addConfigFiltersPath"
     */
    if (parameters.componentFilters?.filtersState) {
      /**
       * Injecting params for "key=[:value:]"
       */
      const filtersStateAsComponentParameters = Object.entries(parameters.componentFilters.filtersState).map((
        [key, value],
      ): ComponentParameter => ({
        name: key,
        value: value?.join(','),
      }));

      parametersToInjectInUrl = parametersToInjectInUrl.concat(filtersStateAsComponentParameters ?? []);
    }

    let urlWithCurrentState = ComponentHelper.injectParametersInURL(baseUrl, parametersToInjectInUrl);

    urlWithCurrentState = ComponentHelper.interpretKeywordsInDynamicConfigURL(
      urlWithCurrentState,
      componentSettings.id,
      parameters,
    );

    return urlWithCurrentState;
  }

  /**
   * @description This method will replace all dynamic config part in componentSettings argument, with latest config.
   * This replacement in done in place. However if the path of a change is an empty string, meaning it is at the root
   * of componentSettings, in that case we need to return the object because it has been replaced entirely. And since
   * we are assigning new object inplace update would be ignored
   *
   * @param dynamicConfig    part of componentSettings that is dynamic, and need to be updated
   * @param componentSettings  the current componentSettings or part of it
   * @param dataLoader       the dataLoader to use to fetch the dynamic config
   * @param parameters       the parameters of the page & the component, used to inject in the dynamic config url
   * @returns                new config, and whether it has changed
   */
  private static async fetchAndReplaceDynamicConfig<T extends ComponentSettings>(
    config: DynamicConfig,
    componentSettings: T,
    dataLoader: DataLoader,
    parameters?: DynamicConfigParameters,
  ): Promise<DynamicConfigResult<T>> {
    // Complete url with required parameters
    const urlWithCurrentState = ComponentHelper.completeUrlForDynamicConfig(
      config.baseUrl,
      componentSettings,
      parameters,
    );

    if (config.latestCalledUrl === urlWithCurrentState) {
      return {
        dynamicConfigHasChanged: false,
        componentSettings,
      };
    }
    config.latestCalledUrl = urlWithCurrentState;

    // Getting URLsearchParam from completedURL, then calling endpoint
    const { path, queryParams } = NavigationHelper.getUrlPathAndQueryParams(urlWithCurrentState);
    const configFromCurrentState = await dataLoader.getOrPost<T>(path, queryParams);

    const hash = DataHelpers.generateHashFromObject(configFromCurrentState);

    if (hash === config.latestResultHash) {
      return {
        dynamicConfigHasChanged: false,
        componentSettings,
      };
    }
    const currentConfig = config.path === '' ? componentSettings : get(componentSettings, config.path, undefined);
    const merged = {
      ...currentConfig,
      ...configFromCurrentState,
    };

    if (config.path !== '') {
      set(componentSettings, config.path, merged);
    } else {
      componentSettings = merged;
    }

    config.latestResultHash = hash;

    return {
      dynamicConfigHasChanged: true,
      componentSettings,
    };
  }

  /**
   * @description Replace all parameters in the url with the corresponding value
   *
   * @param url     the url to inject parameters in
   * @param params  the parameters to inject
   * @returns       the url with parameters injected
   */
  public static injectParametersInURL(url: string, params: ComponentParameter[]): string {
    const parameters = params.reduce((acc, { name, value }) => ({
      ...acc,
      [name]: value,
    }), {});

    return NavigationHelper.injectParamsInUrl(url, parameters);
  }

  public static getIndexFromTabValue({ value, tabs, tabFilterMetric, previousTabs }: {
    value: string;
    tabs: ComponentOptionsTab[];
    tabFilterMetric: FilterMetric;
    previousTabs?: ComponentOptionsTab[];
  }): number {
    if (!tabs || !tabFilterMetric) {
      return 0;
    }

    /**
     * If we detect that the number of tabs changed, we select the leftmost tab
     * We want this behavior only on components with a showAll, that
     * represent a default value to select when the tabs changed
     */
    if (previousTabs && tabFilterMetric.showAll && previousTabs.length !== tabs.length) {
      return 0;
    }

    const selectedValue = value ? value : tabFilterMetric.defaultTab;

    if (!selectedValue) {
      return 0;
    }
    const tabIndex = tabs.findIndex(t => t.value == selectedValue);
    return tabIndex != -1 ? tabIndex : 0;
  }

  public static getComponentTabs(
    data: RawDataPoint[],
    tabFilterMetric: FilterMetric,
  ): ComponentOptionsTab[] {
    const tabs: { [tabId: string]: ComponentOptionsTab } = {};
    const tabValueCount: { [id: string]: number } = {};

    if (!tabFilterMetric) {
      return;
    }
    let defaultTab: ComponentOptionsTab = null;

    if (tabFilterMetric.showAll) {
      defaultTab = {
        title: tabFilterMetric.allOptionTitle ? tabFilterMetric.allOptionTitle : 'All',
        value: null,
      };
      tabValueCount['All'] = 0;
    }
    data.forEach(d => {
      const tabValue = getChained<string>(d, tabFilterMetric.prop);
      const tabTitle = getChained<string>(d, tabFilterMetric.propTitle);
      if ('All' in tabValueCount) {
        tabValueCount['All']++;
      }
      if (!tabValue) {
        return;
      }
      if (tabs[tabValue]) {
        tabValueCount[tabValue]++;
        return;
      }
      tabs[tabValue] = {
        title: tabTitle || tabValue,
        value: tabValue,
      };
      tabValueCount[tabValue] = 1;
    });

    /*
     * We add the default tab All only if we have more than one tab,
     * otherwise the tab 'All' and the only other tab would be the same
     */
    const allTabs = defaultTab && Object.values(tabs).length > 1
      ? [defaultTab, ...Object.values(tabs)]
      : Object.values(tabs);

    if (tabFilterMetric.ordered) {
      return allTabs.sort((a, b) => {
        /*
         * 'All' should be the tab with the most value count
         * but if we have only two tabs 'All' and the other tab has the same
         * result number, in this case 'All' tab is place first
         */
        if (a.title === 'All') {
          return -1;
        } else if (b.title === 'All') {
          return 1;
        }
        /** We always want the tab order to be based on component selectFilter prop title */
        return Ordering.fixedOrder(a.title, b.title, tabFilterMetric.ordered);
      });
    }
    // If no fixed order given, we order the tabs by number of tab result
    return allTabs.sort((a, b) => {
      let aTabNumber = tabValueCount[a.value];
      let bTabNumber = tabValueCount[b.value];
      if (!aTabNumber) {
        aTabNumber = tabValueCount['All'] ? tabValueCount['All'] : 0;
      }
      if (!bTabNumber) {
        bTabNumber = tabValueCount['All'] ? tabValueCount['All'] : 0;
      }
      /*
       * 'All' should be the tab with the most value count
       * but if we have only two tabs 'All' and the other tab has the same
       * result number, in this case 'All' tab is place first
       */
      if (aTabNumber > bTabNumber || a.title == 'All') {
        return -1;
      }
      return 1;
    });
  }
}
