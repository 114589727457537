<div class="sidebar-layer" *ngIf="layer" [ngClass]="{ 'sidebar-layer-hidden': layer.hideInSidebar }">
  <div class="sidebar-layer-legend" [ngClass]="{ active: layer.visible }">
    <span class="fixed title mdc-label" *ngIf="layer.settings.checkable === false && !layer.hideInSidebar">
      {{ layer.settings.title }}
    </span>
    <mat-slide-toggle
      *ngIf="layer.settings.checkable !== false && !layer.hideInSidebar"
      class="toggle title"
      (change)="onfilterbubblinglayer($event.checked)"
      [(ngModel)]="layer.visible">
      {{ layer.settings.title }}
    </mat-slide-toggle>

    <div class="toggle-legend" [ngClass]="{ 'internal-use-feature': config.isInternal }">
      <span
        *ngIf="layer.visible && layer.settings.showCount !== false && layer.settings.layerType != 'tiles'"
        class="right counter">
        {{ layer.count }}
        <small> /{{ layer.forcedTotal ? layer.forcedTotal : layer.total }} </small>
        <pearl-button
          *ngIf="config.isInternal"
          size="small"
          type="icon"
          icon="search"
          (click)="openConfig(layer.settings)" />
      </span>
    </div>
  </div>
  <div class="layer-content" *ngIf="layer.visible">
    <div class="layer-margin" *ngIf="hasVisibleFields()"></div>
    <mat-divider *ngIf="hasVisibleFields()" [vertical]="true" [ngStyle]="getLayerDividerStyle()" />

    <div class="layer-fields" [ngClass]="'layer-fields-margin'">
      <mat-checkbox
        *ngIf="layerSetting?.showPointAlongsideShape && layerSetting?.hideShapeLabel"
        [color]="'primary'"
        [checked]="layer.shapeHidden"
        (change)="hideShapeFilterChanged($event.checked)">
        {{ layerSetting.hideShapeLabel }}
      </mat-checkbox>
      <master-fieldset
        class="spin-filters-fieldset"
        *ngIf="layer.settings.masterFieldset"
        [fieldset]="layer.settings.masterFieldset"
        (onchange)="masterFilterChanged($event)" />
      <div *ngIf="layer.settings.masterFieldset" [ngStyle]="mainFieldsetDivider()"></div>
      <spin-filters-field
        #vessel_field
        *ngIf="layer.vessel && (layer.visible || vesselFieldInitialization)"
        [field]="layer.vessel"
        (onchange)="onvesselchange($event)" />
      <div
        class="switch-button-container"
        (mouseover)="showLayerModeTooltip($event)"
        (mouseout)="$tooltip.hide()"
        *ngIf="switchButtonContainer()">
        <pearl-button
          data-testid="go-to-historical-mode"
          type="alternative"
          icon="historical_position"
          size="small"
          *ngIf="layer.settings.layerType === 'latest'; else historicalMode"
          (click)="handleButtonSwitchMode()"
          [disabled]="!checkIfCanEnterHistoricalMode()">
          Historical positions
        </pearl-button>
        <ng-template #historicalMode>
          <pearl-button type="alternative" size="small" icon="pois" (click)="handleButtonSwitchMode()"
            >Latest positions</pearl-button
          >
        </ng-template>
      </div>

      <div class="spin-filters">
        <spin-filters-fieldset
          *ngFor="let fieldset of layer.fieldsets"
          [fieldset]="fieldset"
          [onaction]="onaction"
          (onchange)="onFilterApplied($event)" />
      </div>

      <pearl-button
        *ngIf="layer.visible && layer.selector"
        class="advanced-filters-button"
        (click)="openSelector()"
        (mouseover)="showAdvancedFiltersTooltip($event)"
        (mouseout)="$tooltip.hide()"
        icon="sort"
        type="alternative"
        [disabled]="!advancedFiltersData">
        {{ selectorButtonLabel | upperFirstLetter }}
        <mat-spinner [diameter]="20" *ngIf="!advancedFiltersData" />
        <span *ngIf="numberFilterApplied" class="spin-rounded-div spin-bg-primary spin-padded spin-inline-flex">
          {{ numberFilterApplied }}
        </span>
      </pearl-button>
      <div class="sidebar-layer-buttons" *ngIf="layer.settings.buttons">
        <ng-container *ngFor="let button of layer.settings.buttons">
          <pearl-button
            [icon]="button.icon"
            type="alternative"
            *ngIf="button.type == 'addModal'"
            (click)="buttonClick(button)">
            {{ button.title }}
          </pearl-button>
        </ng-container>
      </div>

      <div class="sidebar-layer-link" *ngIf="layer.settings.link">
        <a [href]="layer.settings.link.url" (click)="redirectLinkClick($event, layer.settings.link.url)">
          {{ layer.settings.link.text }}
        </a>
      </div>
    </div>
  </div>
</div>
