import { ChangeDetectionStrategy, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { PlotData } from 'plotly.js';
import { scaleOrdinal } from 'd3-scale';
import { schemeSpectral } from 'd3-scale-chromatic';
import { select } from 'd3-selection';
import { format } from 'd3-format';
import { transition } from 'd3-transition';
import { merge } from 'lodash-es';

import { ColorHelper } from '../helpers/color-helper';
import { Color } from '../helpers/types';
import { ChartCalcConfig, ChartExportData, ChartSelectKey, ChartSeries, ChartStraightLine, DataSeries,
  SeriesHeader } from './chart-types';
import { getTranslateFromTransform, reportStyle } from '../helpers/d3-helpers';
import { NvGraph } from './nvgraph';
import { knotsToMeterPerSecond } from '../helpers/data-helpers';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { GraphOptionsComponent } from './graph-options';
import { DescriptionButtonComponent } from '../shared/description-button';
import { PlotlyMulti } from './plotly-multi';

const CARDINAL_DIRECTIONS = [
  'N',
  'NNE',
  'NE',
  'ENE',
  'E',
  'ESE',
  'SE',
  'SSE',
  'S',
  'SSW',
  'SW',
  'WSW',
  'W',
  'WNW',
  'NW',
  'NNW',
];
const LEGEND_RECT_WIDTH = 12;

const SPEEDS = ['Knots', 'Beaufort', 'm/s'];
type SpeedUnit = typeof SPEEDS[number];
type ColorFunction = (speedBin: string) => Color;

/*
 * Used only for wind-rose at the moment
 * As such, it is assumed that:
 *   - Only one series is provided in the config
 *   - This series will return, for each cardinal direction, the count of observed speeds on the time range
 *   - The speeds bins are determined by the backend, and are provided by a header.
 *   - The header keys (and incidently, split IDs) are numeric, representing the speed thresholds in knots
 *   - The speeds thresholds in knots must correspond to the beaufort scale
 */
@Component({
  selector: 'plotly-barpolar',
  templateUrl: 'plotly-barpolar.html',
  styleUrls: ['nvgraph.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DescriptionButtonComponent,
    GraphOptionsComponent,
    MatProgressSpinnerModule,
    NgClass,
    MatButtonToggleModule,
    FormsModule,
    NgFor,
    ChartTooltipComponent,
  ],
})
export class PlotlyBarPolar extends NvGraph {
  // header, as received by the call to the backend
  private originalHead: SeriesHeader = {};
  public override data: ChartSeries[] = [];
  public possibleSpeeds = SPEEDS;
  public showUnitsSwitch: boolean = null;
  public selectedUnit: SpeedUnit = 'Knots';

  // list of categories of the polar chart (i.e cardinal directions)
  private categories: string[];
  // split name -> percentage of observed samples
  private splitSamples: { [split: string]: number } = {};
  private totalSamples: number;
  private colorScale: ColorFunction = null;
  public override availableSelects: ChartSelectKey[] = [
    'metric',
  ];

  constructor(elementRef: ElementRef) {
    super(elementRef, 'barpolar');
    this.categories = CARDINAL_DIRECTIONS;
  }

  /**
   * FIXME: this is a temporary hack to fix JS-ANGULAR-4PP
   * PlotlyBarpolar does not extends PlotlyMulti but requires the same dataflow on dataSeries
   * @see SP-7394
   */
  public override plotDataSeries(
    dataSeries: DataSeries[],
    calc: ChartCalcConfig,
    linesData: ChartStraightLine[] = [],
  ): void {
    return PlotlyMulti.prototype.plotDataSeries.apply(this, [dataSeries, calc, linesData]);
  }

  private initSeries(series: ChartSeries, computeTotals: boolean = true): void {
    const data = series.data;
    const splits = Object.keys(series.header);
    // 1. Determine splits with no value, to remove
    const splitsToRemove = new Set(splits);
    let i = 0;
    while (i < data.length && splitsToRemove.size) {
      const d = data[i++];
      [...splitsToRemove].forEach(splitId => {
        if (d[splitId]) splitsToRemove.delete(splitId);
      });
    }
    splitsToRemove.forEach(splitId => delete series.header[splitId]);

    // 2. Report null values to 0 inplace
    data.forEach(d => {
      splits.forEach(splitId => d[splitId] = d[splitId] || 0);
    });

    // 3. Compute number of sample per category
    this.splitSamples = splits.reduce((samplesPerSplit, splitId) => {
      samplesPerSplit[splitId] = data.reduce((sum, row) => (sum + row[splitId]), 0);
      return samplesPerSplit;
    }, {});

    // total must be computed once only, i.e not recomputed on manual unit change
    if (!computeTotals) return;
    // 4. Compute total number of samples
    this.totalSamples = Object.values(this.splitSamples).reduce((total, splitSum) => (total + splitSum), 0);
    // 5. Change values to percents
    if (series.toPercent) {
      data.forEach(d => {
        splits.forEach(splitId => d[splitId] = (d[splitId] / this.totalSamples) * 100);
      });
    }

    // always change split samples to percent, as it is the reference for the legend entry dimensions
    splits.forEach(splitId => {
      this.splitSamples[splitId] = (this.splitSamples[splitId] / this.totalSamples) * 100;
    });
  }

  private sortedHeads(head): string[] {
    return Object.keys(head).sort((a, b) => parseInt(a) - parseInt(b));
  }

  private getColorScale(series: ChartSeries): ColorFunction {
    // keep color scale across plots (reset on metric change)
    if (this.colorScale !== null) return this.colorScale;
    const splits = this.sortedHeads(series.header);
    let colorSteps;
    if (!series.isBinDistribution) colorSteps = ColorHelper.getColorScale();
    else {
      /*
       * For consistency across plots, always use the same colors in the scale
       * We copy the array to prevent it from being inverted inplace
       */
      colorSteps = [...schemeSpectral[splits.length]].reverse();
    }
    this.colorScale = scaleOrdinal(colorSteps).domain(splits);
    return this.colorScale;
  }

  private buildSeriesTraces(series: ChartSeries, computeTotals: boolean = true): PlotData[] {
    const directionVariable = series.angleVariable;
    const colorScale = this.getColorScale(series);
    this.initSeries(series, computeTotals);

    const traces = [];
    for (const split of this.sortedHeads(series.header)) {
      const splitTitle = series.header[split];
      const color = colorScale(split);
      const id = this.getTraceId(series, split);
      const visible = this.isTraceVisible(id) || 'legendonly';
      const trace: PlotData = {
        id,
        r: series.data.map(d => d[split]),
        theta: series.data.map(d => d[directionVariable]),
        name: splitTitle,
        marker: { color },
        type: 'barpolar',
        visible,
        /*
         * custom values sections (not useful for plotly)
         * total: used to compute actual value in tooltip hover when toPercent is used
         */
        total: this.totalSamples,
        split,
      };

      traces.push(trace);
    }
    return traces;
  }

  /**
   * Event sent by graph options any time a select changes
   */
  public onSelectChange(name: ChartSelectKey, value: any): void {
    if (name === 'metric') {
      // reset color scale as 2 different metrics don't relate to each other
      this.colorScale = null;
      // on metric change, we re-evaluate on next ploting whether we display unit switch or not
      this.showUnitsSwitch = null;
      // reset custom legend
      select(`#${this.plotlyChartId} .custom-legend`).remove();
    }
    this.onchange(name, value);
  }

  /*
   * Convertion knots / beaufort / m/s
   * only series head values are changed, i.e the change is only visual (the plot won't change)
   */
  private changeSeriesNamesByUnit(unit: SpeedUnit): void {
    const formatter = format('.1~f');
    const head = this.data[0].header;
    /*
     * header keys are thresholds in knots. They must correspond to the beaufort scale thresholds
     * https://en.wikipedia.org/wiki/Beaufort_scale
     */
    Object.keys(this.originalHead).forEach((threshold, index) => {
      if (!(threshold in head)) return;
      if (unit === 'Beaufort') head[threshold] = index.toString();
      else if (unit === 'Knots') head[threshold] = this.originalHead[threshold];
      else if (unit === 'm/s') {
        head[threshold] = (this.originalHead[threshold] as string)
          .replace(/([0-9.]+)/g, x => formatter(knotsToMeterPerSecond(parseFloat(x))));
      } else console.error('Barpolar: unknown unit');
    });
  }
  public onUnitChange(unit: SpeedUnit): void {
    // Change suffix for tooltip. The changing of unit is handled in the chartSpecificPlot function
    this.selectedMetrics[0].suffix = unit.toLowerCase();
    this.chartSpecificPlot({}, this.data, null, true);
  }

  public async chartSpecificPlot(
    _,
    multiSeries: ChartSeries[],
    __,
    fromUnitChange: boolean = false,
  ): Promise<void> {
    this.initSelectedTraces(multiSeries);
    // Only one series is supported
    if (this.noRawData || multiSeries.length > 1) return;
    this.data = multiSeries;
    if (!fromUnitChange) this.originalHead = { ...multiSeries[0].header };
    if (this.showUnitsSwitch === null) {
      this.showUnitsSwitch = SPEEDS.some(speed =>
        speed.toLowerCase() === this.selectedMetrics[0].suffix?.toLocaleLowerCase()
      );
      // force knots as initial when we show units, as we assume that it is always the original value
      if (this.showUnitsSwitch) {
        this.selectedMetrics[0].suffix = 'knots';
        this.selectedUnit = 'Knots';
      }
    }
    // Change shown units according to selection
    if (this.showUnitsSwitch) this.changeSeriesNamesByUnit(this.selectedUnit);
    // build traces
    this.traces = this.buildSeriesTraces(multiSeries[0], !fromUnitChange);

    this.layout = merge(this.mapChartOptsInPlotlyLayout(), this.getYAxisLayout());
    this.layout.polar.angularaxis = {
      ...this.layout.polar.angularaxis,
      ...{
        type: 'category',
        categoryarray: this.categories,
        categoryorder: 'array',
        period: this.categories.length,
      },
    };

    await this.plotlyPlot();
    this.addPlotlyTooltip();
  }

  public override afterPlotActions(): void {
    this.replacePlotlyLegend();
  }

  /*
   * Default plotly legend works, but the custom legend aims at:
   * - being more expressive, by having bars sizes reflecting the number of occurence of the bin
   * - keeping a state between draws, transitioning to the new bar sizes to see more clearly the differences
   */
  private replacePlotlyLegend(): void {
    if (this.noRawData || !this.layout?.legend || !this.colorScale) return;
    const plotDiv = select(`#${this.plotlyChartId}`);
    const existingLegend = plotDiv.select('.legend');
    if (existingLegend.empty()) return;
    const customLegend = this.initCustomLegend(plotDiv, existingLegend);
    const isHorizontal = this.layout.legend.orientation === 'h';
    const existingLegendDimensions = (existingLegend.node() as HTMLElement).getBoundingClientRect();
    const plotDimensions = (plotDiv.node() as HTMLElement).getBoundingClientRect();
    const totWidth = isHorizontal ? plotDimensions.width - 30 : existingLegendDimensions.width;
    const totHeight = isHorizontal ? existingLegendDimensions.height : plotDimensions.height - 30;
    // remove entries with no sample, and sort to order the data by bin
    const legendData = Object.entries(this.splitSamples)
      .filter(entry => entry[1] > 0)
      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]));
    if (!isHorizontal) legendData.reverse();
    // compute the dimensions of each legend entry
    const entryDimensions = legendData.reduce((dimensions, curEntry, i) => {
      const rectWidth = isHorizontal ? (curEntry[1] / 100) * totWidth : LEGEND_RECT_WIDTH;
      const rectHeight = isHorizontal ? LEGEND_RECT_WIDTH : (curEntry[1] / 100) * totHeight;
      let x = 0, y = 0;
      // accumulate from previous entry to get x / y
      if (i > 0) {
        const previousEntryDim = dimensions[legendData[i - 1][0]];
        if (isHorizontal) x = previousEntryDim.x + previousEntryDim.width;
        else y = previousEntryDim.y + previousEntryDim.height;
      }
      // do not display text on small entries
      const isSmall = (isHorizontal ? rectWidth : rectHeight) < 15;
      dimensions[curEntry[0]] = { x, y, width: rectWidth, height: rectHeight, isSmall };
      return dimensions;
    }, {});
    const self = this;
    const formatPct = format('.0~%');
    const trans = transition().duration(500);
    const transitionToFinalPos = (entry: d3.Selection<any>): void => {
      entry.transition(trans)
        .attr('transform', d => `translate(${entryDimensions[d[0]].x},${entryDimensions[d[0]].y})`);
      entry.select('rect')
        .transition(trans)
        .attr('width', d => entryDimensions[d[0]].width)
        .attr('height', d => entryDimensions[d[0]].height);
      entry.select('text')
        .transition(trans)
        .attr('x', d => entryDimensions[d[0]].width / 2 + (isHorizontal ? 0 : LEGEND_RECT_WIDTH))
        .attr('y', d => entryDimensions[d[0]].height / 2 + (isHorizontal ? LEGEND_RECT_WIDTH : 0))
        .text(d =>
          entryDimensions[d[0]].isSmall ? '' : `${this.data[0].header[d[0]] as string} (${formatPct(d[1] / 100)})`
        );
    };
    customLegend.selectAll('g').data(legendData, d => d[0])
      .join(
        enter => {
          enter = enter.append('g');
          enter.attr('transform', d => `translate(${entryDimensions[d[0]].x},${entryDimensions[d[0]].y})`);
          enter.append('rect')
            .attr('x', 0).attr('y', 0)
            .attr('width', isHorizontal ? 0 : 10).attr('height', isHorizontal ? 10 : 0)
            .attr('fill', d => this.colorScale(d[0]));
          enter.append('text')
            .attr('dominant-baseline', isHorizontal ? 'hanging' : 'middle')
            .attr('text-anchor', isHorizontal ? 'middle' : 'start');
          /*
           * Attach click handler to dispatch event to plotly
           * mouseup is the event that triggers tracing redraw
           */
          enter.each(function(d) {
            const entry = select(this);
            transitionToFinalPos(entry);
            entry.on('click', () => {
              const plotlyElem = self.getPloltyLegendEntry(d);
              if (!plotlyElem) return;
              const plotlyToggle = plotlyElem.select('.legendtoggle');
              // emulate real click
              plotlyToggle.dispatch('mousedown');
              plotlyToggle.dispatch('mouseup');
            });
          });
          return enter;
        },
        update => {
          update.each(function() {
            transitionToFinalPos(select(this));
          });
          return update;
        },
        exit => exit.remove(),
      ).each(function(d) {
        self.styleNewLegend(this, d);
      });

    // swap node positions for the custom legend to be in front of the plotly one
    if (customLegend.node().nextSibling === existingLegend.node()) {
      customLegend.node().before(existingLegend.node());
    }
  }

  /*
   * Will create a new <g> container containing our legend, if it does not already exist
   * TODO: use d3 types when migrating to current d3 version
   */
  private initCustomLegend(plotDiv: d3.Selection<HTMLElement>, existingLegend: d3.Selection<any>): any {
    const isHorizontal = this.layout.legend.orientation === 'h';

    // hide plotly legend and disable pointer events
    existingLegend.style('opacity', 0);
    existingLegend.selectAll('.legendtoggle').style('pointer-events', 'none');
    let customLegend = plotDiv.select('.custom-legend');
    if (customLegend.empty()) {
      customLegend = existingLegend.select(function() {
        return this.parentNode;
      }).insert('g', '.legend')
        .classed('custom-legend', true)
        .attr('pointer-events', 'all');
    }
    const existingTranslate = getTranslateFromTransform(existingLegend);
    let x = 0, y = 0;
    if (existingTranslate) {
      x = isHorizontal ? 30 : existingTranslate.x - 20;
      y = isHorizontal ? existingTranslate.y : 10;
    }
    customLegend.attr('transform', `translate(${x}, ${y})`);
    return customLegend;
  }

  // Will style custom legend entries just like plotly ones
  private styleNewLegend(elem, d): void {
    const legendEntry = select(elem);
    const plotlyLegendEntry = this.getPloltyLegendEntry(d);
    // use plotly legend style text (for font, size, etc.) and entry (for opacity)
    reportStyle(plotlyLegendEntry.select('.legendtext'), legendEntry.select('text'));
    // use plotly legend entry style for opacity
    reportStyle(plotlyLegendEntry, legendEntry);
    // restore cursor pointer
    legendEntry.style('cursor', 'pointer');
  }

  // TODO: use d3 types when migrating to current d3
  private getPloltyLegendEntry(datum): any {
    const legendText = JSON.stringify(this.data[0].header[datum[0]] as string);
    // do not use d3 because it messes up events internaly to plotly for some reason
    const selector = `#${this.plotlyChartId} .groups .traces .legendtext[data-unformatted=${legendText}]`;
    return select(document.querySelector(selector)?.parentNode);
  }

  public override prepareCsvData(_: SeriesHeader, data: ChartSeries[]): ChartExportData {
    // Only one series is supported
    if (data.length !== 1) return { header: {}, data: [] };
    const toExport = data[0];
    toExport.data.sort((a, b) => {
      return this.categories.indexOf(a[toExport.angleVariable]) - this.categories.indexOf(b[toExport.angleVariable]);
    });
    if (!this.selectedMetrics[0].groupTitle) this.selectedMetrics[0].groupTitle = this.selectedMetrics[0].title;
    if (toExport.toPercent) this.selectedMetrics[0].title = 'Percentage of observed values';
    else this.selectedMetrics[0].title = 'Number of observations';
    const exportHeader = { ...toExport.header };
    // append the unit to each column header
    if (toExport.isBinDistribution) {
      const yFormatter = this.getYAxisFormat('yaxis', true);
      Object.keys(exportHeader)
        .forEach(split => exportHeader[split] = this.applyFormatTooltip(exportHeader[split] as string, yFormatter));
    }
    return {
      header: { ...exportHeader, [toExport.angleVariable]: 'Cardinal direction' },
      data: toExport.data,
    };
  }
}
