<div class="spin-eta" *ngIf="showEta" [class.isMapV2]="isMapV2">
  <div class="eta-body">
    <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
    <div class="eta-automatic" *ngIf="!loading">
      <table class="eta-table">
        <tr>
          <th>Vessel</th>
          <th>Destination</th>
          <th>ETA</th>
          <th>Distance</th>
        </tr>

        <tr *ngFor="let vesselEta of vesselsEta">
          <td
            class="eta-vessel-column"
            *ngIf="vesselEta.vesselTitleRowSpan"
            (click)="highlightEtaVessel(vesselEta, true)"
            [rowSpan]="vesselEta.vesselTitleRowSpan">
            {{ vesselEta.vesselTitle }}
          </td>
          <td [ngClass]="getRouteClass(vesselEta)" (click)="highlightEtaVessel(vesselEta, true)">
            {{ vesselEta.destination }}
          </td>
          <td [ngClass]="getRouteClass(vesselEta)" (click)="highlightEtaVessel(vesselEta, true)">
            {{ vesselEta.etaFormatted }}
          </td>
          <td [ngClass]="getRouteClass(vesselEta)" (click)="highlightEtaVessel(vesselEta, true)">
            {{ vesselEta.distanceReadable }} NM
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
