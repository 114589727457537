<div [id]="componentSettings.id" class="spin-graph full-height plotly-multi">
  <div *ngIf="title && !opts.scheduleGraph" class="component-title">
    <div class="component-title-subtitle">
      <h3>{{ title }}</h3>
      <div class="component-subtitle" *ngIf="subtitle">{{ subtitle }}</div>
    </div>
    <description-button [description]="description"></description-button>
  </div>

  <spin-graph-options
    *ngIf="!opts.hideOptions"
    #chartOptions
    [graphId]="componentSettings.id"
    [opts]="opts"
    [noData]="noRawData"
    (showTable)="onTable()"
    (onchange)="onchange($event.chartParam, $event.value)"
    (xls)="onXls()"
    (onintervalchange)="onintervalchange($event)"
    (png)="onpng($event)"
    [availableSelects]="availableSelects"
    [selects]="selects"
    [display]="display"
    [intervalField]="intervalField"
    [minX]="minX"
    [maxX]="maxX"
    [showTableButton]="datatable"
    [showBarModeControls]="showBarModeControls">
  </spin-graph-options>

  <div class="loading-indicator" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div
    #chartArea
    *ngIf="!noDataToShow()"
    id="{{ plotlyChartId }}"
    class="chart-area"
    [ngClass]="{ 'chart-hidden': loading }"></div>

  <div *ngIf="noDataToShow()" class="no-data-wrapper">
    <span
      >No data available
      <span *ngIf="!noRawData && selects.metric && selects.metric.values.length > 1"> - Try changing metric</span>
    </span>
  </div>
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
