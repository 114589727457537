import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Injector, Input, NgZone, Output, ViewChild,
  ViewEncapsulation, inject } from '@angular/core';
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';

import { flatMap, isEqual, merge } from 'lodash-es';

import { FilterHelper } from '../filters/filter-helper';
import { Aggregation, ChartCalcConfig, ChartComponentSettings, ChartIntervalChange, ChartOptions, ChartSettings,
  ChartStraightLine, DataSeries, DataSeriesConfig, RawDataPoint, SelectableGroupBy,
  SelectableMetric } from '../graph/chart-types';
import { ChartingHelpers } from '../graph/charting-helpers';
import { NvGraph } from '../graph/nvgraph';
import { EntityTableComponentSettings } from '../helpers/config-types';
import { DataHelpers } from '../helpers/data-helpers';
import { DateHelper } from '../helpers/date-helper';
import { TimezoneService } from '../helpers/timezone.service';
import { BaseEndpointPattern, ChartSelectOptions, CompleteExport, ComponentStateOptions, Fieldset, FilterApplied,
  FiltersState, HeavyAnalyticsQuery, HeavyEndpointType, HeavyQuery, INIT_BRUSH_KEYWORDS_FROM_DATA, Interval,
  IntervalOrNull, RefreshType, ResolvedInterval, SpinTimeUnit } from '../helpers/types';
import { Config } from '../config/config';
import { DialogManager } from '../database/dialog-manager';
import { VerticalBreakLine } from '../schedule/schedule-types';
import { WrapperComponent } from './component-wrapper';
import { EntityTableWrapperComponent } from './entity-table-wrapper';
import { ComponentHelper } from '../helpers/component.helper';
import { ChartsFormatting } from '../graph/charts-formatting';
import { PlotlyWaterfallComponent } from '../graph/plotly-waterfall';
import { PlotlyMulti } from '../graph/plotly-multi';
import { PlotlyBarPolar } from '../graph/plotly-barpolar';
import { PlotlyPolar } from '../graph/plotly-polar';
import { BoxplotComponent } from '../graph/boxplot';
import { NavigationHelper } from '../helpers/navigation-helper';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { FieldButtonComponent } from '../shared/field-button';
import { UIService } from '../shared/services/ui.service';
import { getChained } from '../data-loader/ref-data-provider';

/*
 * Bar chart agregation options
 */
// Try to limit to 10 bars, or 2x10 at most...
const DEFAULT_BARS_TARGET_NUMBER = 10;
// ... as long as "Other" bar value stays lower than 80% of the first value
const DEFAULT_BARS_OTHER_VALUE = .8;
// By default start aggregated
const DEFAULT_BARS_SHOW_ALL = false;

/*
 * Line chart "relevant" series
 */
// Enable all/relevant series dropdown when more than 10 series
const DEFAULT_LINES_MAX_SERIES = 10;
// Display 2 series for each percentile (10 / 50 / 90)
const DEFAULT_LINES_RELEVANT_SERIES = 2;
// Default mode to 'relevant'
const DEFAULT_LINES_DISPLAY_MODE = 'relevant';
// Add median line
const DEFAULT_LINES_SHOW_MORE = 'average';

@Component({
  selector: 'chart-wrapper',
  templateUrl: 'chart-wrapper.html',
  styleUrls: ['chart-wrapper.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TimezoneService }],
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgFor,
    NgSwitchCase,
    BoxplotComponent,
    PlotlyPolar,
    PlotlyBarPolar,
    PlotlyMulti,
    PlotlyWaterfallComponent,
    EntityTableWrapperComponent,
    FieldButtonComponent,
  ],
})
export class ChartWrapperComponent extends WrapperComponent<ChartComponentSettings> {
  @ViewChild(EntityTableWrapperComponent, { static: false })
  private $table: EntityTableWrapperComponent;
  @ViewChild('graph')
  public $graph: NvGraph;
  @Output()
  onShowTable = new EventEmitter<string>();

  // For direct data injection
  @Input()
  externalData: { [key: string]: unknown[] };
  @Input()
  isExternalDataLoading: boolean;
  @Input()
  public isScheduleChart: boolean = false;

  private elRef: ElementRef;
  private ngZone: NgZone;
  private dialogManager: DialogManager;
  public config: Config;
  protected uiService = inject(UIService);

  private dataSeries: DataSeries[] = [];
  private linesData: ChartStraightLine[] = [];
  private calc: ChartCalcConfig;
  private _intervalReleased: boolean = true;
  private _dataLoading: boolean = false;

  public chartSettings: ChartSettings;
  public tableSettings?: EntityTableComponentSettings;
  public table: boolean = false;
  public showTable: boolean = false;

  /**
   * @constructor
   *
   * @param {Injector} injector  Injector
   */
  constructor(injector: Injector) {
    super(injector);
    this.elRef = injector.get(ElementRef);
    this.ngZone = injector.get(NgZone);
    this.dialogManager = injector.get(DialogManager);
    this.config = injector.get(Config);
  }

  /**
   * On attach
   */
  public override ngOnAttach(): void {
    // Watch timezone change
    this.sink$.push(this.timezoneService.onChange.subscribe(() => {
      this.refresh(RefreshType.ViewOnly);
    }));
  }

  /**
   * Chart-wrapper is overriding fieldsets and it will add the interval field if it is defined
   * This way the config is passed along to construction of the filter state
   */
  protected override get fieldsets(): Fieldset[] {
    const fieldsets = super.fieldsets;

    if (this.$graph.intervalField) {
      return [...fieldsets, {
        title: 'Custom chart filters',
        expanded: false,
        fields: [this.$graph.intervalField],
      }];
    }

    return fieldsets;
  }

  override get componentStateOptions(): ComponentStateOptions {
    const stateOptions = super.componentStateOptions;
    return { ...this.$graph.display, ...stateOptions };
  }

  // we use getter and setter there to be sure that spinner is displayed when we change loading state
  public override set isLoading(loading: boolean) {
    this._loading = loading;
    this.cdRef.detectChanges();
  }

  public override get isLoading(): boolean {
    return this._loading || this.isExternalDataLoading;
  }

  // dataLoading is set when we try to get data from backend
  set dataLoading(dataLoading: boolean) {
    this._dataLoading = dataLoading;
    this._loading = this._dataLoading || !this.intervalReleased;
  }

  get dataLoading() {
    return this._dataLoading;
  }

  // intervalReleased is set when we move interval brush
  set intervalReleased(intervalReleased: boolean) {
    this._intervalReleased = intervalReleased;
    this._loading = this.dataLoading || !this._intervalReleased;
  }

  get intervalReleased() {
    return this._intervalReleased;
  }

  /**
   * As endpointType are defined at the series level, checking if a chart is light or heavy
   * is slightly different: being light is having all series light (previously `isFullyLight()`)
   */
  override get isLight(): boolean {
    return this.componentSettings.chart.series.every(series =>
      !ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType)
    );
  }

  override get isHeavyCustom(): boolean {
    return !this.isLight;
  }

  /**
   * Triggers the underlying graph to relayout
   */
  public relayout(update: unknown = {}): void {
    this.$graph.plotlyRelayout(update);
  }

  /**
   * Triggers the underlying graph to force relayout
   */
  public override forceRelayout(): void {
    this.$graph.forceRelayout();
  }

  public override setFullScreenIndicator(fullScreen: boolean): void {
    this.$graph.setFullScreenIndicator(fullScreen);
  }

  public isValidInterval(interval: number[]): number {
    return interval[0] && interval[1];
  }

  public viewInitialization(): void {
    // chart-wrapper needs to have chartSettings to correctly process the state
    this.chartSettings = this.componentSettings.chart;
    this.chartSettings.componentTimezone = this.timezoneService.timezone;

    this.setDefaultOwnFilters();
    if (!this.chartSettings.opts) {
      this.chartSettings.opts = {} as ChartOptions;
    }

    // Option for bar charts (-1 to disable aggregation)
    if (!this.chartSettings.opts.barsTargetNumber) {
      this.chartSettings.opts.barsTargetNumber = DEFAULT_BARS_TARGET_NUMBER;
    }
    if (!this.chartSettings.opts.barsOtherValue) {
      this.chartSettings.opts.barsOtherValue = DEFAULT_BARS_OTHER_VALUE;
    }
    if (this.chartSettings.opts.barsShowAll === undefined) {
      this.chartSettings.opts.barsShowAll = DEFAULT_BARS_SHOW_ALL;
    }

    // Options for line charts (-1 to disable relevant mode)
    if (!this.chartSettings.opts.linesMaxSeries) {
      this.chartSettings.opts.linesMaxSeries = DEFAULT_LINES_MAX_SERIES;
    }
    if (!this.chartSettings.opts.linesRelevantSeries) {
      this.chartSettings.opts.linesRelevantSeries = DEFAULT_LINES_RELEVANT_SERIES;
    }
    if (!this.chartSettings.opts.linesDisplayMode) {
      this.chartSettings.opts.linesDisplayMode = DEFAULT_LINES_DISPLAY_MODE;
    }
    if (!this.chartSettings.opts.linesShowMore) {
      this.chartSettings.opts.linesShowMore = DEFAULT_LINES_SHOW_MORE;
    }

    // Table settings
    if (this.chartSettings.table) {
      this.table = true;
      this.tableSettings = this.chartSettings.table;
    }
    this.cdRef.detectChanges();

    /**
     * Only after the cdRef.detectChanges(), the $graph is accessible and the interval can be set
     * The interval can comes from @dynamicConfig or from functions like initInterval()
     */
    if (
      this.chartSettings.intervalField && this.chartSettings.intervalField.interval
      && this.isValidInterval(this.chartSettings.intervalField.interval)
    ) {
      this.$graph.$chartOptions.setIntervalMinMax(this.chartSettings.intervalField.interval);
    }
  }

  public getDataTable(): RawDataPoint[] {
    if (this.componentSettings.chart.type === 'multi' || this.componentSettings.chart.type === 'polar') {
      // Multi series case. In this case we return the data of the series marked as tableSeries
      for (const series of this.dataSeries) {
        if (series.tableSeries) {
          return series.data;
        }
      }
      // fallback to the first series if no series is configured as "tableSeries"
      console.warn('No series configured to show on the table, fallbacking on first one');
      return this.dataSeries[0].data;
    }
    return this.getAllFilteredData();
  }

  public override getChartIntervalInfo(): 'noFilter' | Interval {
    if (!this.componentSettings.chart.intervalField) {
      return 'noFilter';
    }
    return (this.ownFiltersState[this.componentSettings.chart.intervalField.id] as ResolvedInterval)?.extent
      ?? 'noFilter';
  }

  async reloadData(): Promise<void> {
    this.dataSeries = null;
    if (this.$graph) {
      this.$graph.resetInterval();
    }
    await this.refresh();
  }

  /**
   * Sets the default values on the own filters. Currently the interval field is the only own filter
   */
  public setDefaultOwnFilters(): void {
    if (!this.componentSettings.chart.intervalField) {
      return;
    }

    const intervalConfig = this.componentSettings.chart.intervalField;
    if (intervalConfig.default) {
      const extent = DateHelper.period({ era: intervalConfig.default });
      this.ownFiltersState[intervalConfig.id] = { extent, era: intervalConfig.default };
    }
  }

  /**
   * Return all data filtered
   */
  getAllFilteredData(): RawDataPoint[] {
    return flatMap(this.dataSeries, d => d.data);
  }

  getPopulateData(): RawDataPoint[] {
    return flatMap(this.dataSeries, d => d.fullData);
  }

  private async refreshLines(): Promise<void> {
    /*
     * chart can have series and vertical/horizontal lines
     * the data for these lines has to be fetched from endpoint
     */
    if (this.chartSettings.lines) {
      /*
       * by default we can look in the valueBag if there any variables
       * that could be used as straight lines
       */
      let linesData = this.chartSettings.valueBag;

      if (this.chartSettings.lines.endpoint) {
        const lineEndpointUrl = this.injectParameters(this.chartSettings.lines.endpoint);
        linesData = await this.dataLoader.get<RawDataPoint>(lineEndpointUrl);
      }

      // Reset this.linesData after the above "linesData = await this.dataLoader.get..." or it may not work correctly
      this.linesData = [];

      if (Array.isArray(linesData) && linesData.length === 1) {
        linesData = linesData[0];
      }

      if (linesData) {
        for (const lineVariable in this.chartSettings.lines.variables) {
          const variableConfig = this.chartSettings.lines.variables[lineVariable];
          let variableValue = linesData[lineVariable];

          if (variableValue != null) {
            variableValue = ChartsFormatting.roundNumber(variableValue);

            this.linesData.push({
              ...variableConfig,
              point: variableValue,
            });
          }
        }
      }
    }
  }

  /**
   * Refresh as all refresh methods of components, is called any time filters or anything on the state
   * changes so that data should be reloaded. There are 3 types of charts: Heavy, Light and MultiCharts (Multi-Series)
   * Depending on the type of the chart the data will be loaded after the refresh is called
   */
  public override async refresh(refreshType: RefreshType = RefreshType.Default): Promise<void> {
    await super.refresh(refreshType);

    // Update timezone & interval
    this.chartSettings.componentTimezone = this.timezoneService.timezone;

    if (refreshType === RefreshType.ViewOnly) {
      this.$graph.plotlyPlot();
      return;
    }

    await this.refreshLines();

    /**
     * Checking that config contains values generated from "@dynamicConfig"
     * The tabFilterSelected is required before loadAllSeriesData for heavy charts
     */
    const selectFilterValues = this.chartSettings.selects?.selectFilter?.values;
    if (selectFilterValues && refreshType !== RefreshType.AllExceptTabs) {
      this.setChartTabsFromData(this.chartSettings.selects.selectFilter.values);
    }

    // Load and filter the data for each series
    await this.loadAllSeriesData(refreshType);

    this.$graph.masterPeriod = this.getMasterPeriodFromParameters();

    /**
     * Some lights charts do not have "@dynamicConfig" to retrieve there tabs
     * Therefore, they generate there tabs from there data series
     */
    if (!selectFilterValues) {
      const data = [].concat(...this.dataSeries.map(series => series.data));
      this.setChartTabsFromData(data);
    }

    // Transform all series and pass them to the $chart for plotting
    this.$graph.plotDataSeries(this.dataSeries, this.calc, this.linesData);

    // Update tooltip configuration depending on number of series and/or available splits in each
    this.$graph.updateTooltipLayout();

    // Pass the data to the chart's table
    this.passDataToTable();

    if (refreshType === RefreshType.Full) this.populateCommonFilters();
    this.cdRef.detectChanges();
  }

  public updateNoRawData(): void {
    const rawData = this.getAllFilteredData();
    this.noRawData = !rawData || !rawData.length;
    this.cdRef.detectChanges();
  }

  /**
   * Determine chart tabs from data and select the "selected" tab according to the display options
   * This method is called by all 3 types of charts: light, multi-series and heavy
   */
  public setChartTabsFromData(data: RawDataPoint[]): void {
    const graphTabs = ComponentHelper.getComponentTabs(data, this.chartSettings.selects?.selectFilter);
    const previousTabs = this.$graph.graphTabs;
    /** Only trigger re-render of tabs if they have changed */
    if (!isEqual(previousTabs, graphTabs)) this.$graph.graphTabs = graphTabs;
    const tabIndex = ComponentHelper.getIndexFromTabValue(
      {
        value: this.$graph.display.tabFilterSelected,
        tabs: graphTabs,
        tabFilterMetric: this.chartSettings.selects?.selectFilter,
        previousTabs,
      },
    );
    const selectedTab = graphTabs ? graphTabs[tabIndex] : null;
    this.$graph.setSelectedIndex(tabIndex);
    /*
     * If we have tabs but we don't have a selected tabFilterSelect
     * We will init tabFilterSelected with the value of the default tabIndex
     * Then we have to call updateComponentCalcConfig to update chartOptions
     * Same if the new tabValue doesn't match the old one
     *  - Happen when the tabs change after we applied a filter for exemple
     */
    if (
      graphTabs && graphTabs.length
      && (!this.$graph.display.tabFilterSelected || this.$graph.display.tabFilterSelected != selectedTab.value)
    ) {
      this.$graph.display.tabFilterSelected = selectedTab.value;
      this.updateComponentCalcConfig();
    }
  }

  public populateCommonFilters(): void {
    if (
      this.componentSettings.chart.series.every(series => ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType))
    ) {
      return;
    }

    const allData = this.getPopulateData();
    this.onDataReceived.emit({
      componentId: this.componentSettings.id,
      data: allData,
    });
  }

  /**
   * Loads all series of a multi-series chart into `this.dataSeries` and updates `this.noRawData` accordingly.
   * Multi-series charts can call multiple endpoints, each providing light or heavy data which has to be aligned.
   *
   * For light series, the constructed DataSeries contains both the unfiltered data and the data filtered by
   * LightFilteringOfDataSet.
   */
  public async loadAllSeriesData(_: RefreshType): Promise<void> {
    if (!this.componentSettings.chart.series) return;
    this.dataLoading = true;

    const series: (DataSeries | Promise<DataSeries>)[] = this.componentSettings.chart.series.map(
      (config, _) => {
        /**
         * We need to check for external data first. This means that dashboard responsible
         * for this chart has already requested the data and we just need to access it, from
         * subset of the data
         */
        if (this.externalData && config?.endpoint) {
          const fullData = this.externalData[config.endpoint];
          const data = this.lightFilteringOfDataSet(fullData);
          return { ...config, header: {}, data, fullData };
        }

        if (ChartingHelpers.isHeavyOrHeavyCustom(config.endpointType)) {
          return this.loadHeavySeries(config);
        }
        if (DataHelpers.needsParameterInjection(config.title)) {
          config.title = DataHelpers.injectParameters(config.title, this.chartSettings.valueBag as object);
        }
        const endpointWithParams = this.injectParameters(config.endpoint);
        return this.dataLoader.get<RawDataPoint[]>(endpointWithParams).then(d => this.processLightData(config, d));
      },
    );

    this.dataSeries = await Promise.all(series);
    this.updateNoRawData();
    this.dataLoading = false;
  }

  private processLightData(config: DataSeriesConfig, fullData: readonly RawDataPoint[]): DataSeries {
    if (config.dataKey) fullData = fullData[config.dataKey];
    if (!Array.isArray(fullData)) fullData = [];
    return { ...config, header: {}, data: this.lightFilteringOfDataSet(fullData), fullData };
  }

  /**
   * Loads heavy-endpoint for a single series. It is used by multichart which has multiple series configured
   */
  async loadHeavySeries(series: DataSeriesConfig): Promise<DataSeries> {
    // loadHeavySeries() will be called *before* plotDataSeries(), selectedMetrics may not be up-to-date
    this.$graph.findSelectValues();
    const metrics = series.metric ? [series.metric] : this.$graph.selectedMetrics;
    const heavySeries: DataSeries = { ...series, header: {}, headerMap: {}, data: [], fullData: null };
    const heavyDatas: { [x: string]: RawDataPoint } = {};

    return Promise.all(metrics.map(metric => {
      if (
        (metric.includeSeries && !metric.includeSeries.includes(series.id))
        || (metric.excludeSeries && metric.excludeSeries.includes(series.id))
      ) {
        return;
      }
      const query = this.createHeavyQuery(series.endpoint, series.endpointType as HeavyEndpointType, series, metric);
      return this.dataLoader.queryHeavyEndpoint(query).then(series => {
        ChartingHelpers.makeUniqueHeavyLabels(series);
        series.data.forEach(d => {
          const dx = getChained<string>(d, 'x');
          heavyDatas[dx] = merge(heavyDatas[dx] || {}, d);
        });
        heavySeries.headerMap[metric.value] = series.header;
        heavySeries.splitPageBaseUrl = series.splitPageBaseUrl;
      });
    })).then(() => {
      heavySeries.fullData = heavySeries.data = Object.values(heavyDatas);
      return heavySeries;
    });
  }

  public createHeavyQuery(
    endpoint: BaseEndpointPattern,
    endpointType: HeavyEndpointType,
    series: DataSeriesConfig,
    metric: SelectableMetric,
  ): HeavyQuery | HeavyAnalyticsQuery {
    const initialUrl = ComponentHelper.injectParametersInURL(endpoint, this.getParamsForInjection());
    const { path, queryParams } = NavigationHelper.getUrlPathAndQueryParams(initialUrl);
    let aggregation = this.calc.yaxis?.aggregation;
    let requestorId = this.componentSettings.id || '';
    const split = series.splitby ? series.splitby.value : this.calc.splitBy?.value;
    aggregation = ChartingHelpers.metricColumn(metric.value);
    if (series.id) requestorId += series.id;
    let query = this.createCustomHeavyQuery(path, queryParams, split, requestorId, aggregation);
    if (endpointType === 'heavy') {
      query = this.heavyAnalyticsQueryFromCustomHeavy(query, aggregation);
    }
    return query;
  }

  public heavyAnalyticsQueryFromCustomHeavy(
    heavyQuery: HeavyQuery,
    aggregation: Aggregation,
  ): HeavyAnalyticsQuery {
    return {
      ...heavyQuery,
      endpointType: 'heavy',
      operation: aggregation,
      nullGroupTitle: this.calc.xaxis.nullTitle,
      nullSplitTitle: this.calc.splitBy?.nullTitle,
      componentId: this.componentSettings.id,
      configFiltersPath: this.configFiltersPath,
    };
  }

  public createCustomHeavyQuery(
    pathName: string,
    searchParams: URLSearchParams,
    splitBy: string,
    requestorId: string,
    aggregation: Aggregation = null,
  ): HeavyQuery {
    return {
      baseUrl: pathName,
      searchParams,
      requestorId,
      endpointType: 'heavy-custom',
      operation: aggregation,
      group: this.calc.xaxis.group,
      split: splitBy,
      filtersState: this.filtersState,
      filterConfig: this.constructAppliedFiltersFromState(this.filtersState),
      tabFilterSelected: this.calc.tabFilterSelected,
    };
  }

  /**
   * Listener for the chart onselect (groupby/splitby or other change)
   */
  public onselect(event: ComponentStateOptions): void {
    this.calc = ChartingHelpers.chartCalcConfigFromStateAndConfig(
      event,
      this.chartSettings,
      this.config.availablePages,
    );
    this.emitComponentSelect(event);
    this.refresh(RefreshType.AllExceptTabs);
  }

  public override shouldRefreshLightDataAfterFilter(latestFilter: FilterApplied): boolean {
    let shouldRefreshLightData = false;
    // latestFilter defined: a filter was changed manually
    if (latestFilter) {
      if (latestFilter.masterFilter) {
        shouldRefreshLightData = true;
        if (latestFilter.timeSync && this.componentSettings.chart?.intervalField) {
          const currentIntervalRange = (
            this.ownFiltersState[this.componentSettings.chart.intervalField.id] as ResolvedInterval
          ).extent;
          if (currentIntervalRange === undefined) shouldRefreshLightData = true;
          else {
            shouldRefreshLightData = !FilterHelper.rangeIncludedInRange(
              latestFilter.values as IntervalOrNull,
              currentIntervalRange,
            );
          }
        }
      }
    }
    return shouldRefreshLightData;
  }

  /*
   * Will set filter state, determine the refresh type according to latest filter applied, and refresh
   * i.e for a light chart, will check if its a master filter and if the interval is bigger than the current one
   */
  public override setFilterState(newPageFilters: FiltersState, latestFilter: FilterApplied, _?: RefreshType): void {
    const shouldRefreshLightData = this.shouldRefreshLightDataAfterFilter(latestFilter);
    if (latestFilter?.timeSync && this.componentSettings.chart?.intervalField) {
      this.setInterval(FilterHelper.resolvedIntervalFromFilterApplied(latestFilter));
      // delete the period from the serialization, since it will inherit master filter one
      delete this.$graph.display.period;
      // emit componentSelect to update component url state
      this.emitComponentSelect();
    }
    const refreshType = shouldRefreshLightData ? RefreshType.Full : RefreshType.Default;
    super.setFilterState(newPageFilters, latestFilter, refreshType);
  }

  // Common function called when the interval is changed. Will refresh the filter state, adapt groupby, and refresh data
  private handleIntervalChange(newInterval: IntervalOrNull, refresh = true): void {
    if (this.componentSettings.chart.intervalField && newInterval !== undefined) {
      this.ownFiltersState[this.componentSettings.chart.intervalField.id] = { extent: newInterval };
    }
    this.updateComponentCalcConfig();

    let realInterval = newInterval;
    if (newInterval === null && this.$graph._interval) {
      realInterval = this.$graph._interval;
    }
    this.adaptDynamicGroupBy(realInterval);

    // we ask for a refresh of the component, but data-only (no layout changes)
    if (refresh) this.refresh();
  }

  /**
   * NvGraph notifies the chart-wrapper that the interval has been changed by the user
   * NvGraph contains the graph-options which do contain the slider
   * ScheduleWrapperComponent notifies chart-wrapper that the interval has changed
   */
  public chartIntervalChange(intervalChange: ChartIntervalChange): void {
    const interval = intervalChange.clearInterval ? null : intervalChange.extent;
    this.handleIntervalChange(interval);
    // emit componentSelect to update component url state
    this.emitComponentSelect();
  }

  public override componentNeedsDataForInterval(): boolean {
    if (!this.chartSettings.intervalField) return false;
    this.checkInitIntervalBrushValidityNotLightChart();
    return this.isLight;
  }

  public override setComponentInitialInterval(interval?: ResolvedInterval): void {
    if (!this.chartSettings.intervalField) return;
    this.$graph.initInterval();
    if (interval === undefined) {
      // we need to change the state of the component, but we know we will refresh after init, so don't refresh here
      this.handleIntervalChange(this.$graph.interval, false);
    } else {
      // set interval
      this.setInterval(interval);
    }
  }

  /*
   * We should not get an interval initialized from data bounds when the data is dynamic from the interval
   * TODO: move this into config validator
   */
  private checkInitIntervalBrushValidityNotLightChart(): void {
    const initIntervalParams = this.chartSettings.intervalField?.initBrushInterval;
    if (!initIntervalParams || this.chartSettings.intervalField.interval) return;
    const isDataBound = initIntervalParams === 'auto' || initIntervalParams.some(
      p => typeof p === 'string' && INIT_BRUSH_KEYWORDS_FROM_DATA.some(keyword => p.includes(keyword)),
    );
    if (isDataBound) {
      console.warn(`Time interval for ${this.componentSettings.title} configured to depend on loaded data, \
but loaded data is not light. Please use dynamicConfig instead to get valid bounds of the data`);
    }
  }

  /*
   * Set interval from outside. Will:
   * - Change the chart interval in graph options
   * - Change the state and config
   *
   * in the case of populated interval (via dynamicConfig), "whole dataset" has different meaning than
   * removing datetime from state,* so this function returns the real interval for that case
   */
  public setInterval = (newInterval: ResolvedInterval): void => {
    if (newInterval.era?.isCompleteDataset) {
      this.$graph.setIntervalFullTime();
    }
    this.$graph.setInterval(newInterval);

    // we will trigger the refresh from the calling function, so give false to refresh boolean
    this.handleIntervalChange(newInterval.extent, false);
    this.cdRef.detectChanges();
  };

  public setXAxisTickvals(tickvals: number[]): void {
    this.$graph.setXAxisTickvals(tickvals);
  }

  public setVerticalBreaklines(verticalBreakLines: VerticalBreakLine[], breaklineUnit: SpinTimeUnit): void {
    if (this.$graph) {
      this.$graph.setVerticalBreaklines(verticalBreakLines, breaklineUnit);
    }
  }
  /**
   * Automatically update the selected groupBy option according to the new interval. If the query is server-side,
   * disable some options as it would result in too much points being fetched
   * @param interval: the range interval for which we cant to show values
   * @returns true if the groupBy selected was updated, false otherwise
   */
  public adaptDynamicGroupBy(interval: Interval): boolean {
    if (!this.componentSettings.chart.opts?.dynamicGroupBy) {
      return;
    }

    // If interval is not defined or not correctly initialized we return false
    if (!interval || interval.length !== 2 || !interval[0] || !interval[1]) {
      return false;
    }

    // the width of the interval is used to determine if we should change the groupby
    const extentDiff = interval[1] - interval[0];
    const isServerSide = this.$graph.dynamicGroupByState?.serverSide;
    const groupBySelect = this.componentSettings.chart.selects.groupby;
    const selection = this.$graph.selectedGroup;
    /*
     * if there is already some active groupby we will check if it is ok for the current interval
     * If groupby serverside, do not return since we have to update the selecable options anyway
     */
    if (
      !isServerSide && selection && groupBySelect.values.includes(selection)
      && ChartWrapperComponent.isDurationInIntervalBoundaries(extentDiff, selection)
    ) {
      return false;
    }

    /*
     * we are in a case where either there was no groupby yet or the current groupby is
     * no longer good for the current interval, we will proceed to choose the good groupby
     * from the available groupbys
     */
    for (const [i, groupBy] of groupBySelect.values.entries()) {
      if (ChartWrapperComponent.isDurationInIntervalBoundaries(extentDiff, groupBy)) {
        // forbid some options only if serverside
        if (isServerSide) {
          this.$graph.dynamicGroupByState.groupByMinSelectable = Math.max(i - 1, 0);
        }
        // always automatically update selection when not manually selected
        if (!this.$graph.dynamicGroupByState?.groupByManuallySelected) {
          this.updateSelectedGroupBy(groupBy);
          return true;
        } // else, if serverside, check if previous selection still holds, otherwise change it
        else if (isServerSide) {
          const previouslySelectedIndex = groupBySelect.values.findIndex(opt => opt.value === selection.value);
          // If the manually selected sampling is still reasonable, keep it
          if (previouslySelectedIndex >= (i - 1)) return false;
          this.dialogManager.showMessage(
            'Selected sampling would result in too much results. Defaulting to automatic sampling.',
            'warn',
          );
          this.updateSelectedGroupBy(groupBy);
          this.$graph.dynamicGroupByState.groupByManuallySelected = false;
          return true;
        } // always keep selected option if light chart
        else return true;
      }
    }
    return false;
  }

  /**
   * Update the selected groupBy option
   * @param groupBy The new groupBy option to select
   */
  public updateSelectedGroupBy(groupBy: SelectableGroupBy): void {
    this.$graph.display.groupby = groupBy.value;
    this.$graph.selectedGroup = groupBy;
    this.updateComponentCalcConfig();
  }

  public static isDurationInIntervalBoundaries(duration: number, groupby: SelectableGroupBy): boolean {
    const currentInterval = groupby.interval;
    if (!currentInterval) {
      return false;
    }
    const parsedInterval = currentInterval.map(interval => DateHelper.parseDurationFromString(interval));
    /*
     * if current extentDiff is between current group by limits we keep the current
     * group by
     */
    if (duration > parsedInterval[0] && duration <= parsedInterval[1]) {
      return true;
    }

    /*
     * else the current extendDiff is out current group by boundaries so we have to find the
     * new group by
     */
    return false;
  }

  public override updateDisplayOptions(componentState: ComponentStateOptions): void {
    super.updateDisplayOptions(componentState);

    // In case the group by from the component state is not available, override the state with the first available
    const chartGroupbyOverride: ChartSelectOptions = {};
    if (componentState.groupby) {
      const isRequestedGroupbyAvailable: boolean = Boolean(ChartingHelpers.findSelectValue(
        'groupby',
        componentState,
        this.$graph.selects,
      ));
      if (!isRequestedGroupbyAvailable) {
        const firstGroupby = ChartingHelpers.firstSelectValue(this.$graph.selects.groupby);
        chartGroupbyOverride.groupby = firstGroupby.value;

        console.error(
          `Requested groupby value "${componentState.groupby}"`
            + ` is not available for chart ${this.componentSettings.id}.`,
        );
        this.dialogManager.showMessage(
          `The requested group by for the "${this.componentSettings.title}" chart is not available.\n`
            + `Using group by "${firstGroupby.title}" instead.`,
          'warn',
          { duration: 10000 },
        );
      }
    }

    this.$graph.display = {
      ...this.$graph.display,
      ...componentState,
      ...chartGroupbyOverride,
    };

    if (componentState.period && this.$graph.intervalField) {
      // Init doubledate/ intersection interval with state period
      if (FilterHelper.isEraFilterType(this.$graph.intervalField)) {
        this.$graph.setInterval(componentState.period);
      } else {
        /*
         * For interval with date/datetime type we only set initBrushInterval here
         * because interval isn't already init there and will be init during plot
         */
        this.$graph.intervalField.initBrushInterval = componentState.period.extent;
        this.$graph.intervalField.initBrushUnit = 'fixed';
      }
    }
  }

  public override updateComponentCalcConfig(): void {
    this.calc = ChartingHelpers.chartCalcConfigFromStateAndConfig(
      this.$graph.display,
      this.chartSettings,
      this.config.availablePages,
    );
    /**
     * Some graphs may have no groupBy defined, if they only contain heavy series or use bridges (see "Current and
     * adjusted supply per status" in fleet analysis dashboard of market intel app) but all others should have one.
     * Send a fingerprinted error to Sentry to pinpoint incorrect configurations
     */
    if (
      this.calc.xaxis.value === '__missing'
      && !this.componentSettings.chart.series.every(series => ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType))
      && !this.componentSettings.chart.bridges
    ) {
      const fingerPrint = `${this.componentSettings.id}.json-missing-groupby-metric`;
      console.error(
        new ErrorWithFingerprint(`Missing groupby in ${this.componentSettings.title}`, [fingerPrint]),
      );
    }
  }

  public reinitializeSelectValues(): void {
    this.$graph.initSelectValues();
    this.updateComponentCalcConfig();
  }

  public override resetComponentFilters(): void {
    this.setDefaultOwnFilters();
    this.pageStateService.resetPageFiltersState();
    if (this.filtersState?.[this.componentSettings.chart?.intervalField?.id]) {
      this.setInterval(
        { extent: this.filtersState[this.componentSettings.chart.intervalField.id] } as ResolvedInterval,
      );
    }
    this.reinitializeSelectValues();
    this.emitComponentSelect();
    this.cdRef.detectChanges();
  }

  public passDataToTable(): void {
    /*
     * Useless to pass data to table when it has an endpoint defined.
     * The data would be loaded from the endpoint, directly inside the table component
     */
    if (this.chartSettings.table?.endpoint) return;

    this.ngZone.runOutsideAngular(() =>
      setTimeout(() => {
        this.$table?.initDefinitionAndSetData(this.getDataTable());
      }, 1)
    );
  }

  public onTableShow(): void {
    if (!this.table) return;

    this.showTable = true;
    this.onShowTable.emit(this.componentSettings.id);
    this.cdRef.detectChanges();

    if (this.chartSettings.table?.endpoint) {
      this.$table.refresh(RefreshType.Default);
    } else {
      this.passDataToTable();
    }
  }

  public onTableHide(): void {
    if (!this.table) return;
    this.showTable = false;
    this.cdRef.detectChanges();
  }

  public export($event: CompleteExport): void {
    this.onexport.emit($event);
  }

  /**
   * Exports the current graph chart in XLS
   */
  public exportChart(): void {
    this.$graph.onXls();
  }

  /**
   * Update the interval field with the interval range min max and call chartOptions updateIntervalDoubleDate
   * to force double date to take in account the interval min max range
   */
  public updateIntervalMinMax(intervalMinMax: number[]): void {
    if (this.$graph.intervalField) {
      this.$graph.intervalField.interval = intervalMinMax;
      this.$graph.$chartOptions.updateIntervalDoubleDate();
    }
  }

  public getGraph(): NvGraph {
    return this.$graph;
  }
}
