<ng-container *ngIf="fullyLoaded">
  <div class="integrated-table-wrapper">
    <mat-label class="table-title">{{ parentField?.title }}</mat-label>
    <div *ngFor="let row of rows" class="entity-table-row">
      <ng-container *ngFor="let field of row.fields">
        <entity-detail-field
          *ngIf="showField(field)"
          #tableFields
          class="entity-table-component-field"
          [field]="field"
          [small]="true"
          [entityAccessor]="row.entityAccessor"
          [parent]="this" />
      </ng-container>
      <pearl-button type="icon" icon="delete" (click)="deleteRow(row)" />
      <pearl-button type="icon" icon="duplicate" (click)="duplicateRow(row)" />
    </div>
  </div>
  <div class="add-button-wrapper">
    <pearl-button icon="add" type="alternative" (click)="addEmptyRow()">Add new</pearl-button>
  </div>
</ng-container>
<div *ngIf="!fullyLoaded">
  <mat-spinner diameter="20" />
</div>
