<div class="spin-filters-interval mat-interval">
  <div class="interval-brush-and-dates">
    @if (!noMinMax) {
      @if (!field.force) {
        <mat-checkbox
          [color]="'primary'"
          [(ngModel)]="enabled"
          [spinTooltip]="field.description"
          [disabled]="field.noValue"
          (change)="ontoggle()">
          {{ field.title }}
        </mat-checkbox>
      }

      <div class="interval-slider" [ngClass]="{ 'desactivate-slider': field.noValue }">
        <nouislider
          [disabled]="!shouldDisplaySlider()"
          [connect]="true"
          [behaviour]="'drag'"
          [min]="_min"
          [max]="_max"
          [step]="getStep(field.step)"
          class="slider slider-primary"
          [ngModel]="selectedInterval"
          (sliderChange)="onInterval($event)"
          (end)="onIntervalReleased()" />
      </div>
    } @else if (selectedInterval && selectedInterval.length) {
      <div>
        <span>{{ field.title }} {{ selectedInterval[0] }}</span>
        <span></span>
      </div>
    }

    <!-- Datetime container interval -->
    <div class="interval-range">
      @if (field.filterType === "date" || field.filterType === "datetime") {
        <div class="left-date" data-testid="min">
          <pearl-form-field [small]="true" [hasLabel]="false">
            <input
              matInput
              [value]="left"
              [pearlDatepicker]="leftDatepicker"
              [disabled]="!enabled"
              [minDate]="min"
              [maxDate]="right"
              (dateChange)="onLeftDateChanged($event.value)" />
            <ng-container pearlFormSuffix>
              <pearl-datepicker-toggle size="small" [for]="leftDatepicker"></pearl-datepicker-toggle>
              <pearl-datepicker
                #leftDatepicker
                [precision]="this.field.filterType === 'date' ? 'day' : 'second'"></pearl-datepicker>
            </ng-container>
          </pearl-form-field>
        </div>
        <div class="right-date" data-testid="max">
          <pearl-form-field [hasLabel]="false" [small]="true">
            <input
              matInput
              [value]="right"
              [pearlDatepicker]="rightDatepicker"
              [disabled]="!enabled"
              [minDate]="left"
              [maxDate]="max"
              (dateChange)="onRightDateChanged($event.value)" />
            <ng-container pearlFormSuffix>
              <pearl-datepicker-toggle [for]="rightDatepicker" size="small" />
              <pearl-datepicker #rightDatepicker [precision]="this.field.filterType === 'date' ? 'day' : 'second'" />
            </ng-container>
          </pearl-form-field>
        </div>
      } @else if (!noMinMax) {
        <!-- standard integer interval -->
        <input
          #leftInput
          data-testid="min"
          [value]="left"
          [min]="min"
          [max]="right"
          [disabled]="!enabled"
          type="number"
          class="min"
          (mouseover)="leftInput.focus()"
          (mouseout)="leftInput.blur()"
          autocomplete="off" />
        <input
          #rightInput
          data-testid="max"
          [value]="right"
          [min]="left"
          [max]="max"
          [disabled]="!enabled"
          type="number"
          class="max"
          (mouseover)="rightInput.focus()"
          (mouseout)="rightInput.blur()"
          autocomplete="off" />
      }
    </div>
  </div>

  @if (selectPeriod) {
    <div class="interval-select-period">
      <spin-filters-doubledate #doubleDate [field]="field" (onchange)="onDoubleDateChange($event)" />
    </div>
  }
</div>
