import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';

import { BehaviorSubject, EMPTY, Observable, Subscription, timer } from 'rxjs';
import { catchError, switchMap, timeout } from 'rxjs/operators';

import { DprLoggingService } from '../live-dpr/services/dpr-logging.service';
import { SpinProduct } from './types';

@Injectable({
  providedIn: 'root',
})
/**
 * @description InternetStatusService is responsible to notify the app whether we are offline or online.
 * We can also trigger offline mode even if online is available. In which case we also need to know whether
 * we can go back online at any moment.
 *
 * In this service when we go from offline -> online we open a dialog to show all the reports that has been modified
 * and or created while offline.
 */
export class InternetStatusService implements OnDestroy {
  private readonly http = inject(HttpClient);
  private readonly logger = inject(DprLoggingService);

  private readonly sink$: Subscription[] = [];
  private readonly _internetUp$ = new BehaviorSubject<boolean>(true);
  private readonly _internetServiceReady$ = new BehaviorSubject<boolean>(false);
  private forceOffline = false;
  private _internetAvailable = false;
  // consecutive succeeded call of osv-status endpoint counter
  private consecutiveSucceededStatusCounter: number =
    InternetStatusService.STATUS_REQUEST_NUMBER_OF_CONSECUTIVE_SUCCESSES_TO_GO_ONLINE;

  public readonly internetUp$ = this._internetUp$.asObservable();
  // interval time in ms between each call to /osv-status
  public static STATUS_REQUEST_CALL_INTERVAL = 3000;
  // custom timeout in ms to wait for /osv-status endpoint response, must be shorter than STATUS_REQUEST_CALL_INTERVAL
  public static STATUS_REQUEST_TIMEOUT = 120000;
  // number of consecutive endpoint response to have to go back online
  public static STATUS_REQUEST_NUMBER_OF_CONSECUTIVE_SUCCESSES_TO_GO_ONLINE = 3;

  /**
   * This attribute is required because we init _internetUp$ with value true.
   *
   * There are some cases such as DPR endpoints that try to reach server on page reload
   * but the server isn't available.
   *
   * This attribute makes sure that we checked at least once that the server is up
   */
  public readonly internetServiceReady$ = this._internetServiceReady$.asObservable();

  public startChecking(project: SpinProduct): void {
    if (project !== 'osv') {
      return;
    }

    this.sink$.push(
      timer(0, InternetStatusService.STATUS_REQUEST_CALL_INTERVAL).pipe(
        switchMap(() =>
          this.http
            .get<boolean>(`${document.location.origin}/spindjango/osv-status`)
            .pipe(
              timeout(InternetStatusService.STATUS_REQUEST_TIMEOUT),
              catchError(() => {
                return this.handleEndpointStatusFail();
              }),
            )
        ),
      ).subscribe(() => this.handleEndpointStatusSucceed()),
    );
  }

  public handleEndpointStatusSucceed(): void {
    this.consecutiveSucceededStatusCounter++;
    if (
      this.consecutiveSucceededStatusCounter >= InternetStatusService
        .STATUS_REQUEST_NUMBER_OF_CONSECUTIVE_SUCCESSES_TO_GO_ONLINE
    ) {
      this._internetAvailable = true;
      if (!this._internetUp$.getValue() && !this.forceOffline) {
        this._internetUp$.next(true);
        this.logger.logMessage('[InternetStatusService] - App online');
      }
    }
    if (!this._internetServiceReady$.getValue()) {
      this._internetServiceReady$.next(true);
    }
  }

  public handleEndpointStatusFail(): Observable<never> {
    this._internetAvailable = false;
    this.consecutiveSucceededStatusCounter = 0;
    if (this._internetUp$.getValue() !== false) {
      this.logger.logMessage('[InternetStatusService] - App offline');
      this._internetUp$.next(false);
    }
    if (!this._internetServiceReady$.getValue()) {
      this._internetServiceReady$.next(true);
    }
    return EMPTY;
  }

  public stopChecking(): void {
    this.sink$.forEach(subscription => subscription.unsubscribe());
    this._internetServiceReady$.next(true);
  }

  public isOnline(): boolean {
    return this._internetUp$.value;
  }

  public internetAvailable(): boolean {
    return this._internetAvailable;
  }

  public toggle(): void {
    this.forceOffline = !this.forceOffline;
    this._internetUp$.next(!this.forceOffline);
    this.logger.logMessage(`[InternetStatusService] - Force app ${
      this._internetUp$.getValue()
        ? 'online'
        : 'offline'
    }`);
  }

  ngOnDestroy(): void {
    this.sink$.forEach(subscription => subscription.unsubscribe());
  }
}
