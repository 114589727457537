import { SelectionModel } from '@angular/cdk/collections';

import { EntityTableComponentSettings } from '../../helpers/config-types';
import { Button, EntityFieldDefinition, EntityTableCategory, SomeEntity } from '../../helpers/types';

/**
 * @description For table with attribute categories, we init the categories, adding
 * a first and last category, and a no category
 *
 * first category is added for drag & drop and select rows
 * last category is added for description field and actions
 */
export function initCategories(
  categories: EntityTableCategory[],
): EntityTableCategory[] | undefined {
  if (!categories || categories.length === 0) {
    return;
  }

  const categoriesIds = categories.map(category => category.id);

  if (!categoriesIds.includes(EntityTableHelper.FIRST_CATEGORY_COLUMN)) {
    categories.push({
      id: EntityTableHelper.LAST_CATEGORY_COLUMN,
      title: '',
    });
  }

  if (!categoriesIds.includes(EntityTableHelper.NO_CATEGORY_COLUMN)) {
    categories.unshift({
      id: EntityTableHelper.NO_CATEGORY_COLUMN,
      title: '',
    });
  }

  if (!categoriesIds.includes(EntityTableHelper.LAST_CATEGORY_COLUMN)) {
    categories.unshift({
      id: EntityTableHelper.FIRST_CATEGORY_COLUMN,
      title: '',
    });
  }

  return categories;
}

export class EntityTableHelper {
  public static FIRST_CATEGORY_COLUMN = 'first_category_column';
  public static LAST_CATEGORY_COLUMN = 'last_category_column';
  public static NO_CATEGORY_COLUMN = 'no_category_column';

  public static DESCRIPTION_FIELD: EntityFieldDefinition = {
    id: 'hiddenInfo',
    title: 'Additional information',
    type: 'html',
    category: EntityTableHelper.LAST_CATEGORY_COLUMN,
    checkBoxTitle: 'Show additional information',
    tooltip: 'Show additional information in description column',
    row: true,
  };

  private static LAST_CATEGORY_CELL_CSS = 'last-cell-of-category ';
  private static FIRST_CATEGORY_CELL_CSS = 'first-cell-of-category ';
  private static CATEGORY_CELL_CSS = 'category-cell ';

  private static getConfigColumn(columns: EntityFieldDefinition[]): EntityFieldDefinition[] {
    return columns.filter(
      column =>
        column.id !== 'selectRow' && column.id !== 'dragColumn'
        && column.id !== 'actions',
    );
  }

  private static increaseColspan(
    categoryColspan: { [key: string]: number },
    category: string,
    categoryIds: string[] = [],
    hasCategory = true,
    addMethod: 'unshift' | 'push' = 'push',
  ): void {
    if (!hasCategory) {
      return;
    }

    if (!categoryColspan[category]) {
      categoryColspan[category] = 0;
      categoryIds[addMethod](category);
    }

    categoryColspan[category]++;
  }

  /**
   * @description Sort visible columns according to their category attribute. Order is based on the one from
   * categories, that is an attribute of table component.
   *
   * We define displayedColumnIds & categoryIds in this method.
   */
  public static organizeColumns(columns: EntityFieldDefinition[], options: {
    config: Partial<EntityTableComponentSettings>;
    hasOrderSort: boolean;
    editMode: boolean;
    selectedRows: SelectionModel<SomeEntity>;
    buttonList: Button[];
    categoryIds: string[];
  }): {
    categoryIds: string[];
    displayedColumnIds: string[];
    hasCategory: boolean;
    categoryColspan: { [key: string]: number };
  } {
    /**
     * Reset of visible columns to take only the ones from config and description
     * field if exists
     */
    const visibleColumns = EntityTableHelper.getConfigColumn(columns);

    const categoryIds = [];
    const categoryColspan = {};
    if (options.categoryIds?.length > 0) {
      visibleColumns.sort((a, b) => options.categoryIds.indexOf(a.category) - options.categoryIds.indexOf(b.category));
      visibleColumns.forEach((column, i) => {
        if (!column?.category) {
          column.category = EntityTableHelper.NO_CATEGORY_COLUMN;
        }

        const isFirstCategoryColumn = column.category !== visibleColumns[i - 1]?.category;
        const isLastCategoryColumn = column.category !== visibleColumns[i + 1]?.category;

        column.class = EntityTableHelper.CATEGORY_CELL_CSS;

        if (isLastCategoryColumn) {
          column.class += EntityTableHelper.LAST_CATEGORY_CELL_CSS;
        }

        if (isFirstCategoryColumn) {
          column.class += EntityTableHelper.FIRST_CATEGORY_CELL_CSS;
        }

        EntityTableHelper.increaseColspan(categoryColspan, column.category, categoryIds);
      });
    }

    const displayedColumnIds = visibleColumns.map(column => column.id);
    const hasCategory = categoryIds.length > 0;
    if (options.config.canChooseRows && options.selectedRows) {
      displayedColumnIds.unshift('selectRow');
      EntityTableHelper.increaseColspan(
        categoryColspan,
        EntityTableHelper.FIRST_CATEGORY_COLUMN,
        categoryIds,
        hasCategory,
        'unshift',
      );
    }

    if (options.editMode || options.buttonList) {
      if (options.hasOrderSort) {
        displayedColumnIds.unshift('dragColumn');
        EntityTableHelper.increaseColspan(
          categoryColspan,
          EntityTableHelper.FIRST_CATEGORY_COLUMN,
          categoryIds,
          hasCategory,
          'unshift',
        );
      }

      displayedColumnIds.push('actions');
      EntityTableHelper.increaseColspan(
        categoryColspan,
        EntityTableHelper.LAST_CATEGORY_COLUMN,
        categoryIds,
        hasCategory,
        'push',
      );
    }

    return {
      categoryIds,
      displayedColumnIds,
      hasCategory: categoryIds.length > 0,
      categoryColspan,
    };
  }
}
