import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';

import { PearlButtonComponent, PearlButtonLinkComponent, PearlIcon } from './pearl-components';

export type LinkData = { [title: string]: string } | string;

// See https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export function isLinkData(prop: unknown): prop is LinkData {
  // Beware! typeof null === 'object', then a !== null is required
  return typeof prop === 'string'
    || typeof prop === 'object' && prop !== null && Object.values(prop).every(value => typeof value === 'string');
}

@Component({
  selector: 'spin-link',
  templateUrl: 'spin-link.html',
  styleUrls: ['spin-link.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    PearlButtonComponent,
    PearlButtonLinkComponent,
    NgFor,
  ],
})
export class SpinLinkComponent {
  constructor(private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('click', _ev => {
        // We check if the click is outside of the tooltip component
        if (this.tooltip && this.menuOpened && !this.tooltipElRef?.nativeElement.contains(event.target)) {
          this.hideTooltip.emit(true);
        }
      });
    });
  }

  @Input()
  set link(link: LinkData) {
    this._link = link;
    this.formatSimpleLink();
  }
  @Input()
  linkTitle: string;
  @Input()
  description: string;
  @Input()
  icon: PearlIcon = null;
  @Input()
  button: boolean = false;
  @Input()
  tooltip: boolean = false;
  @Input()
  tooltipElRef: ElementRef;

  public _link: LinkData;

  @Output()
  public hideTooltip = new EventEmitter<boolean>();

  private menuOpened: boolean = false;

  /**
   * If the link data is an array of only one link, we change it to the bare url string
   */
  private formatSimpleLink(): void {
    if (this._link) {
      const keys = Object.keys(this._link);
      if (keys?.length === 1 && !(typeof this._link === 'string')) {
        const uniqueKey = keys[0];
        this._link = this.checkLinkProtocol(this._link[uniqueKey]);
      } else if (typeof this._link === 'string') {
        this._link = this.checkLinkProtocol(this._link);
      }
    }
  }

  /**
   * Check if the link attribute contains one or multiple links
   */
  public isSimpleLink(): boolean {
    return typeof this._link === 'string';
  }

  /**
   * Create a list of elements with proper format for menu
   */
  public extractLinks() {
    if (!(typeof this._link === 'string')) {
      const extractedLinks = [];

      for (const title in this._link) {
        extractedLinks.push({
          title: title,
          link: this.checkLinkProtocol(this._link[title]),
        });
      }

      return extractedLinks;
    }
  }

  public onClick(event: Event): void {
    event.stopPropagation();
    if (this.tooltip) {
      this.menuOpened = true;
      this.hideTooltip.emit(false);
    }
  }

  /**
   * If the external link does not contain HTTP(S) protocol in the url,
   * the browser use the current protocol + domain to redirect - thus redirect to spinergie.com/url
   * We add http protocol in case no protocol is provided, but only if the URL does not start with `/`
   * eg.
   *   - docs.stena.com/xxx will be prefixed by http
   *   - /monitoring/output/xx won't be prefixed and so will go to monitoring.spinergie.com/monitoring/output/xx
   */
  private checkLinkProtocol(url: string): string {
    if (url.startsWith('/')) {
      return url;
    }
    const httpRegex = /^http/;
    if (!url.match(httpRegex)) {
      return 'http://' + url;
    }
    return url;
  }
}
