<div [id]="componentSettings.id" class="spin-graph full-height">
  <div *ngIf="title && !opts.scheduleGraph" class="component-title">
    <h3>{{ title }}</h3>
    <description-button [description]="description"></description-button>
  </div>

  <spin-graph-options
    *ngIf="!opts.hideOptions"
    #chartOptions
    [graphId]="componentSettings.id"
    [opts]="opts"
    [noData]="noRawData"
    (showTable)="onTable()"
    (onchange)="onchange($event.chartParam, $event.value)"
    (xls)="onXls()"
    (onintervalchange)="onintervalchange($event)"
    (png)="onpng($event)"
    [availableSelects]="availableSelects"
    [selects]="selects"
    [display]="display"
    [intervalField]="intervalField"
    [minX]="minX"
    [maxX]="maxX"
    [showTableButton]="datatable"
    [dynamicGroupByState]="dynamicGroupByState">
  </spin-graph-options>

  <div class="loading-indicator" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div
    #chartArea
    *ngIf="!noRawData"
    id="{{ plotlyChartId }}"
    class="chart-area"
    [ngClass]="{ 'chart-hidden': loading }"></div>

  <div *ngIf="noRawData" class="no-data-wrapper">
    <span>No data available</span>
  </div>
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
