import { Subject } from 'rxjs';

import { FieldSettings, Fieldset, FilterApplied, HistoricalModeStyle, LayerFilter, LayerId, SpinGeoDataPoint,
  TraceData } from '../../helpers/types';
import { SelectorConfig } from '../../selector/selector.types';
import { RawDataPoint } from '../../graph/chart-types';
import { LayerSettings, MapLayerSettings } from '../../helpers/config-types';
import { FilterHelper } from '../../filters/filter-helper';
import { LayerLegendState } from '../../helpers/legend-types';
import { LegendHelper } from '../../shared/legend-helper';

export interface LayersDictionary<T extends StandardLayerState<RawDataPoint>> {
  [layerId: string]: T;
}

export type UpdateMode = 'overwrite' | 'add';

// Properties used both for StandardLayerState as well as Map-specific state (LayerDrawingState)
export class StandardLayerState<T extends RawDataPoint = RawDataPoint> {
  readonly settings: Readonly<LayerSettings>;
  protected _data: T[];
  protected _shown: T[];
  protected _shapeHidden?: boolean;

  filters: LayerFilter;
  count: number;
  // Filters currently applied on the layer
  currentFilter: FilterApplied = {};
  /** If false, the layer is not displayed on the map. Bound to the sidebar layer toggle */
  visible: boolean;
  /** If true, layer is hidden in sidebar. If true, visible should be false */
  hideInSidebar: boolean;
  total?: number;
  loaded: boolean;
  forcedTotal?: number;
  tabData?: T[];

  public legendState: LayerLegendState;
  /** This is used to notify listeners (legend component) when there is an update on the legend */
  public _legendStateUpdate$ = new Subject<string>();
  readonly id: LayerId;
  readonly fieldsets: Fieldset[];
  readonly selector?: SelectorConfig;

  constructor(layerSettings: LayerSettings, layerVisible: boolean) {
    this.settings = layerSettings;
    this.id = layerSettings.id;
    this.loaded = false;
    this._data = [];
    this._shown = [];
    this.filters = {};
    this.count = 0;
    this.visible = layerVisible;
    this.hideInSidebar = layerSettings.hideSidebarLayer || false;
    this.fieldsets = FilterHelper.initializeFieldsetTypeAndProp(layerSettings.fieldsets) ?? [];
    if (layerSettings.selector) {
      this.selector = layerSettings.selector;
      this.selector.fieldsets = FilterHelper.initializeFieldsetTypeAndProp(this.selector.fieldsets);
    }
    this.legendState = LegendHelper.constructLegendState(this);
  }

  public toggle(visible: boolean): void {
    this.visible = visible;
    if (this.legendState) this.legendState.visible = visible;
    this.updateLegend();
  }

  /** map-v2: remove last argument */
  public setData(d: T[], setTotal: boolean = true, _?): void {
    this._data = d;
    if (setTotal) this.total = d.length;
    if (!this._shown.length) {
      this._shown = d;
      this.count = d.length;
    }
    this.updateLegend();
  }

  public get data(): T[] {
    return this._data;
  }

  /** Will update this.legendState inplace */
  public updateLegend(afterMapMove: boolean = false): boolean {
    if (!this.visible) return false;
    const shouldUpdateAfterMapMove = this.legendState?.currentColorBy?.autoUpdate === true;
    if (afterMapMove && !shouldUpdateAfterMapMove) return false;
    LegendHelper.updateLayerLegend(this, this.getDataForLegend(shouldUpdateAfterMapMove));
    this._legendStateUpdate$.next(this.id);
    return true;
  }

  public getDataForLegend(forCurrentViewPort: boolean): T[] {
    return this._data;
  }

  public setFilteredData(filteredData: T[], setCount: boolean = true): void {
    this._shown = filteredData;
    /** Set data if none is defined yet */
    if (!this.data.length) this.setData(filteredData);
    if (setCount) this.count = filteredData.length;
    this.updateLegend();
  }

  public get shown(): T[] {
    return this._shown;
  }

  public getDefaultFilters(): LayerFilter {
    return FilterHelper.getFieldsetsDefaultFilters(this.fieldsets);
  }

  public set shapeHidden(shapeHidden: boolean) {
    this._shapeHidden = shapeHidden;
  }

  public get shapeHidden(): boolean {
    return this._shapeHidden;
  }
}

/** MapV2: merge content directly inside MapLibreLayer */
export class MapLayerState<T extends SpinGeoDataPoint = SpinGeoDataPoint> extends StandardLayerState<T> {
  override readonly settings: Readonly<MapLayerSettings>;

  // Mapv2: will disappear
  reallyShown?: T[];
  /** Special field present on vessel layer */
  vessel?: FieldSettings;
  constructor(
    layerSettings: MapLayerSettings,
    layerVisible: boolean,
  ) {
    super(layerSettings, layerVisible);
    /** Historical layer hidden by default. Will be set visible by map dashboard if necessary. */
    this.hideInSidebar = layerSettings.hideSidebarLayer || layerSettings.layerType === 'historical' || false;
    if (layerSettings.vessel) {
      this.vessel = FilterHelper.initializeFieldTypeAndProp(layerSettings.vessel);
    }
    if (!layerSettings.layerType) layerSettings.layerType = 'geometries';
    this.reallyShown = [];
  }

  public override getDataForLegend(forCurrentViewPort: boolean): T[] {
    if (forCurrentViewPort) {
      return this.legendState.currentColorBy.autoUpdate
        ? this.reallyShown
        : this.data;
    } else {
      return this.shown ?? this.data;
    }
  }
}

export class MapLayerStateHistorical<T extends SpinGeoDataPoint = TraceData> extends MapLayerState<T> {
  /** Whether the playback button for historical vessel positions is playing */
  isPlaying: boolean;
  /** Keep track of the number of vessels, to zoom when there is only 1 */
  nbLoadedVessels: number;
  historicalModeStyle: HistoricalModeStyle;
  requestedTimeFrame: number[];
  vesselMinDate: number;
  vesselMaxDate: number;
  latestLoc: { [vesselId: number]: TraceData };

  constructor(
    settings: MapLayerSettings,
    layerVisible: boolean,
  ) {
    super(settings, layerVisible);
    this.isPlaying = false;
    this.nbLoadedVessels = 0;
    this.historicalModeStyle = settings.historicalModeStyle || 'pointMode';
  }
}

export function isVesselLayer(layerSettings: MapLayerSettings): boolean {
  return layerSettings.layerType === 'historical' || layerSettings.layerType === 'latest';
}

export function getLatestPositionLayer(layers: LayersDictionary<MapLayerState>, layerGroup: string): MapLayerState {
  return Object.values(layers).find(layer =>
    layer.settings.layerType === 'latest' && layer.settings.layerGroup === layerGroup
  );
}

export function getHistoricalPositionLayer(
  layers: LayersDictionary<MapLayerState>,
  layerGroup: string,
): MapLayerStateHistorical {
  return Object.values(layers).find(layer =>
    layer.settings.layerType === 'historical' && layer.settings.layerGroup === layerGroup
  ) as MapLayerStateHistorical;
}
