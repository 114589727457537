import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector,
  Input, NgZone, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation,
  inject } from '@angular/core';
import { CdkDrag, CdkDragDrop, CdkDragPlaceholder, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatRow, MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { SelectionModel } from '@angular/cdk/collections';
import { MatMenuModule } from '@angular/material/menu';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault,
  NgTemplateOutlet } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

import dayjs, { Dayjs } from 'dayjs';
import { clone, debounce, isNil } from 'lodash-es';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';

import { Config } from '../config/config';
import { Client, ClientsAndGroups } from '../dashboards/admin/admin-model';
import { FilterHelper } from '../filters/filter-helper';
import { SelectorState } from '../selector/selector.types';
import { ColorHelper } from '../helpers/color-helper';
import { DataLoader } from '../data-loader/data-loader';
import { NavigationHelper } from '../helpers/navigation-helper';
import { StringHelper } from '../helpers/string-helper';
import { ActionEvent, ActionType, AfterSave, ArrowComparison, Button, ComparisonData, ComponentOptionsTab, EntityAction,
  EntityDefinition, EntityFieldDefinition, EntityInformation, EntityOrder, EntityTableCategory, LayerFilter, LinkButton,
  LinkBy, NumOrString, OptionValue, PageLinkSpec, SomeEntity, SomeEntityChainable, SortDirection, UpdateEventType,
  ValidityDateEntity } from '../helpers/types';
import { TimezoneService } from '../helpers/timezone.service';
import { ComplexTooltipComponent } from '../shared/complex-tooltip';
import { DatabaseHelper } from './database-helper';
import { DeleteEntityDialog } from './delete-entity-dialog';
import { DialogManager } from './dialog-manager';
import { EntityDataAccessor } from './entity-data-accessor';
import { FilterColumnsData, FilterColumnsDialogComponent } from './filter-columns.dialog';
import { MultiFormFieldComponent } from './multi-form-field';
import { FieldsHelperService } from '../shared/services/helpers/fields-helper.service';
import { TableStateService } from '../shared/services/helpers/table-state.service';
import { SelectableValue } from '../graph/chart-types';
import { EntityTableComponentSettings } from '../helpers/config-types';
import { EntityTableHelper, initCategories } from './helper/entity-table-helper';
import { OrderByPipe, SafeHtmlPipe } from '../helpers/pipes';
import { SearchBarComponent } from '../shared/search-bar';
import { LinkData, SpinLinkComponent } from '../shared/spin-link';
import { FieldButtonComponent } from '../shared/field-button';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { getAdditionalData, getPropKeyForNonFilterField } from '../helpers/data-helpers';
import { getChained } from '../data-loader/ref-data-provider';
import { PearlButtonComponent, PearlButtonLinkComponent, PearlDatepickerComponent, PearlDatepickerInput,
  PearlDatepickerToggleComponent, PearlFormFieldComponent, PearlIcon, PearlIconComponent,
  PearlIconSize } from '../shared/pearl-components';
import { UIService } from '../shared/services/ui.service';
import { SelectorHelper } from '../selector/selector.helper';

@Component({
  selector: 'entity-table',
  templateUrl: './entity-table.html',
  styleUrls: ['entity-table.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [ColorPickerService],
  imports: [
    NgIf,
    MatFormFieldModule,
    MatTabsModule,
    PearlButtonComponent,
    PearlButtonLinkComponent,
    PearlIconComponent,
    NgFor,
    MatPaginatorModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    SpinTooltipDirective,
    MatIconModule,
    MatButtonToggleModule,
    FormsModule,
    MatCheckboxModule,
    CdkScrollable,
    MatTableModule,
    MatSortModule,
    CdkDropList,
    NgClass,
    FieldButtonComponent,
    NgTemplateOutlet,
    NgSwitch,
    NgStyle,
    NgSwitchCase,
    SpinLinkComponent,
    NgSwitchDefault,
    SearchBarComponent,
    MatSelectModule,
    MatOptionModule,
    MultiFormFieldComponent,
    PearlFormFieldComponent,
    ColorPickerModule,
    TextFieldModule,
    MatMenuModule,
    CdkDrag,
    CdkDragPlaceholder,
    OrderByPipe,
    SafeHtmlPipe,
    PearlDatepickerComponent,
    PearlDatepickerInput,
    PearlDatepickerToggleComponent,
  ],
})
export class EntityTableComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: Config;
  private dataLoader: DataLoader;
  private dialogManager: DialogManager;
  private editableDefinitionLoaded: boolean;
  private entityAccessor: EntityDataAccessor;
  private cdRef: ChangeDetectorRef;
  private elRef: ElementRef;
  private zone: NgZone;
  private visibleByDefault: string[];
  private editedRow: SomeEntity = null;
  private timezoneService: TimezoneService;

  public definition: EntityDefinition;
  public columns: EntityFieldDefinition[];
  public deleting: boolean;
  public loading: number = 0;
  public dataSource: MatTableDataSource<SomeEntityChainable>;
  public selectedRows: SelectionModel<SomeEntity>;
  public saving: boolean;
  public searching: boolean = false;
  public availableClients: Client[];
  public dialog: MatDialog;
  public visibleColumns: EntityFieldDefinition[];
  public tabFilterSelected: string;
  public selectedTabIndex: number = 0;
  public selectedTableMode: string = null;
  public displayedColumnIds: string[] = [];
  public categoryIds: string[] = [];
  public categoryColspan: { [key: string]: number } = {};

  public hasCategory = false;

  /**
   * Built in description field that will be the concatenation of all the invisible fields
   */
  public descriptionField = EntityTableHelper.DESCRIPTION_FIELD;
  public validityDateFilter: boolean = true;
  public sortFromHeaderCellActivated = false;
  public parentDefaultScrollStyle: string = null;
  public dragDisabled = true;
  public orderingMode: 'swap' | 'reordering' = 'reordering';

  public entityTableConfig: Partial<EntityTableComponentSettings> = {
    canChooseColumns: false,
    canChooseRows: false,
  };

  readonly orderingLimitValueBeforeBigOperation = 500;

  @Input()
  editMode: boolean;
  @Input()
  readOnly: boolean = false;
  @Input()
  hideHeader: boolean;
  @Input()
  hideSearchbar: boolean;
  @Input()
  unopenable: boolean;
  @Input()
  formTable: boolean;
  @Input()
  tabs: ComponentOptionsTab[];
  @Input({ required: false, transform: initCategories })
  categories?: EntityTableCategory[];

  /*
   * the table can have an actions specified that will be executed after an
   * element is succesfully saved
   */
  @Input()
  afterSaveAction: (afterSaveData: AfterSave) => void;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChildren('matSelect')
  $selectFields: QueryList<MatSelect>;
  @ViewChildren('matAutoComplete')
  $autocompleteFields: QueryList<MatAutocomplete>;
  @ViewChildren('textArea')
  $textAreas: QueryList<ElementRef>;
  @ViewChildren('collectionMulti')
  $collectionMultis: QueryList<MultiFormFieldComponent>;
  @ViewChildren(MatRow, { read: ElementRef })
  tableRows: QueryList<ElementRef>;

  @Input()
  parentEntity: SomeEntity;
  @Input()
  parentEntityDefinition: EntityDefinition;
  @Input()
  parentField: EntityFieldDefinition;
  @Input()
  parentTitle: string = null;
  @Input()
  tableInteraction: boolean = true;
  @Input()
  linkBy: LinkBy = null;
  @Input()
  addNewEnable: boolean = true;
  @Input()
  headerClass: string = '';
  @Input()
  buttonList: Button[] = null;
  @Input()
  headerButtonList: Button[] = [];
  @Input()
  showDescriptionColumn: boolean;
  @Input()
  selectableRowPropId: string = null;
  @Input()
  tableModes: SelectableValue[] = [];

  @Output()
  exportFunction = new EventEmitter<SomeEntity[]>();
  @Output()
  onaction = new EventEmitter<ActionEvent>();
  @Output()
  openEntityRequest = new EventEmitter<EntityInformation>();
  @Output()
  columnsFiltered = new EventEmitter<string[]>();
  @Output()
  onrowselected = new EventEmitter<any>();
  @Output()
  ontabchanged = new EventEmitter<string>();
  @Output()
  onSelectionChange = new EventEmitter<SomeEntity[]>();
  @Output()
  showDescriptionColumnChanged = new EventEmitter<boolean>();

  private uiService = inject(UIService);

  constructor(
    injector: Injector,
    public filterColumnsDialog: MatDialog,
    public readonly fieldsHelperService: FieldsHelperService,
    private readonly tableStateService: TableStateService,
  ) {
    this.dataLoader = injector.get(DataLoader);
    this.zone = injector.get(NgZone);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.elRef = injector.get(ElementRef);
    this.config = injector.get(Config);
    this.dialogManager = injector.get(DialogManager);
    this.entityAccessor = new EntityDataAccessor(injector);
    this.dialog = injector.get(MatDialog);
    this.timezoneService = injector.get(TimezoneService);
  }

  ngOnInit(): void {
    document.addEventListener('keydown', event => {
      /*
       * since we need a global event on document that has to catch every enter keypress,
       * we have to add an exception for inputs of type select and autocomplete when selecting a field using enter
       */
      if (
        event.key === 'Enter'
        && ((event.srcElement as any).className.includes('mat-select')
          || (event.srcElement as any).className.includes('autocomplete-input')
          || this.parentField?.style === 'integrated')
      ) {
        return;
      }
      if (event.key === 'Enter' && !DatabaseHelper.isSpecialClick(event)) {
        const row = this.entityAccessor.entity;
        if (row && row.editable) {
          this.saveRow(row);
        }
      } else {
        DatabaseHelper.handleKeyEventForPanel(this.$selectFields, event);
        DatabaseHelper.handleKeyEventForPanel(this.$autocompleteFields, event);
      }
    });

    if (this.config.isLightOrFullAdmin) {
      this.dataLoader.get<ClientsAndGroups>('/sso/clients-groups').then(data => {
        this.availableClients = data.clients;
      });
    }

    if (this.tableHasModes()) {
      this.selectedTableMode = this.tableModes[0].value;
    }
  }

  ngAfterViewInit() {
    this.onTextAreaChange();
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }

  /**
   * Entity detail table has input parameters & in its parent components we use
   * @ViewChild. Those two decorator serves different purposes, but knowing that
   * we are also using reusable components, some params should be init through the
   * following method.
   */
  public init(entityTableConfig: Partial<EntityTableComponentSettings>): void {
    // Override provided config, keeping default for values not specified
    this.entityTableConfig = { ...this.entityTableConfig, ...entityTableConfig };
  }

  get showTable(): boolean {
    return this.visibleColumns && this.visibleColumns.length > 0
      && (this.hasCategory && this.categoryIds?.length > 0 || !this.hasCategory);
  }

  private onTextAreaChange() {
    this.$textAreas.changes.subscribe(() => {
      this.$textAreas.forEach(item => {
        this.zone.runOutsideAngular(() => {
          item.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter' && !DatabaseHelper.isSpecialClick(event)) {
              item.nativeElement.blur();
              return false;
            } else if (event.key === 'Enter' && (event.ctrlKey || event.altKey || event.metaKey)) {
              const textArea = item.nativeElement as HTMLTextAreaElement;
              // get the position where the return is add
              const start = textArea.selectionStart;
              // get the full text
              const fullText = `${textArea.value.slice(0, start)}\n${textArea.value.slice(textArea.selectionEnd)}`;
              // first we add the text until the selection and scroll to it
              textArea.value = fullText.substring(0, start + 1);
              textArea.scrollTop = textArea.scrollHeight;
              // then we put the fulltext in the textArea
              textArea.value = fullText;
              // lastly we put the correct value of selection to be just after the newline
              textArea.selectionStart = textArea.selectionEnd = start + 1;
            }
          });
        });
      });
    });
  }

  public onclick(event: MouseEvent, button: Button, values: any): void {
    /*
     * If user click on navigate button with ctrl or shift we navigate in a new window
     * with href so we don't construct tooltip button event
     */
    if (button.type === 'navigate' && (DatabaseHelper.isSpecialClick(event))) {
      return;
    }
    event.preventDefault();

    const actionEvent = NavigationHelper.constructActionEvent({
      event,
      action: button,
      values,
    });
    this.onaction.emit(actionEvent);
  }

  public shouldShowButton(button: Button, datum: SomeEntity, field?: EntityFieldDefinition): boolean {
    /*
     * specific button is something not coming from config like "remove_link"
     * if this table is nested in linked-entity-table container
     * those buttons are globally visible if passed
     */
    if (button.type === 'afterCreation' || button.type === 'removeLink') {
      return true;
    }
    // No data means no modal button
    if (field && isNil(datum[field.id])) {
      return false;
    }
    return ComplexTooltipComponent.shouldShowButton(button, datum, this.config);
  }

  public exportCsv(): void {
    this.exportFunction.emit(this.dataSource.data);
  }

  public applyFilter = debounce((filterValue: string) => {
    this.searching = true;
    this.cdRef.detectChanges();

    filterValue = filterValue.replace(',', '');
    filterValue = StringHelper.cleanString(filterValue);

    // clean the filterValue if __validity_date_filter was already added to filter search string
    filterValue = filterValue.replace('__validity_date_filter', '');
    /*
     * If entity has a validity date, we add this special keyword '__validity_date_filter'
     * it will be used in the predicate function to include/exclude the data outside the validity date range
     */
    if (this.isValidityDateEntity() && !this.hideHeader) {
      filterValue = this.validityDateFilter ? `__validity_date_filter ${filterValue}` : filterValue;
    }

    // If filterValue is an empty string, the filterPredicate is not triggered, so we must reset the exactMatch flags
    if (filterValue === '') {
      this.resetExactMatch();
    }

    if (this.dataSource.filter !== filterValue) {
      this.dataSource.filter = filterValue;
    }

    this.searching = false;
    this.cdRef.detectChanges();
  }, 400);

  get isEditable(): boolean {
    return this.parentField == null || this.editMode;
  }

  private resetExactMatch(): void {
    this.dataSource.data.forEach(d => d.exactMatch = false);
  }

  public resetToDefaultSorting(): void {
    if (this.dataSource) {
      this.dataSource.sort.active = undefined;
      this.dataSource.sort.direction = '';
      this.dataSource.sort = this.sort;
    }
  }

  public hasAnOrder(): boolean {
    if (this.definition) {
      return this.definition.fields.find(f => f.id === 'order') !== undefined;
    }
    return false;
  }

  public isEntityWithOrderSort(): boolean {
    if (this.dataSource) {
      return this.hasAnOrder() && !this.sortFromHeaderCellActivated;
    }
    return false;
  }

  private sortEntities(data: SomeEntityChainable[], sort: MatSort): SomeEntityChainable[] {
    // Check and set if sort from header cells is activated
    this.sortFromHeaderCellActivated = sort.direction !== '' && sort.active !== undefined
      && !(sort.direction === 'asc' && sort.active === 'order');

    /**
     *  When Drag is enable, we deduct that the entity has an order.
     *  Therefore, We want the default sorting to use the order instead of the Id
     *  Accordingly, when there is no active sorting, that can be deduced when neither
     *  sort.direction or sort.active is set. We base the data sort on the order property of the entity
     */
    if (this.isEntityWithOrderSort()) {
      return data.sort((a, b) => DatabaseHelper.compareRaw(a.order, b.order, 'asc'));
    }
    /*
     * When passing from one database to another the active sort isn't refreshed and can be undefined
     * for our data type. In that case, field is undefined so we can't use the compare() method to sort the data.
     */
    const field = this.definition.fields.find(d => d.id === sort.active);
    if (sort.active && sort.direction !== '' && field !== undefined) {
      data.sort((a, b) => DatabaseHelper.compare(a, b, sort.direction as SortDirection, field));
    }
    return data.sort((a, b) => a.exactMatch ? (b.exactMatch ? 0 : -1) : b.exactMatch ? 1 : 0);
  }

  private isEntityMatchedByFilter(data: SomeEntityChainable, filter: string): boolean {
    // if the entity has recently been edited (i.e. it has editable = true), it is never hidden
    if (data.editable) {
      return true;
    }

    data.exactMatch = false;

    // we split our string on one or more spaces to have all the different keywords that we are looking for
    const remainingKeywordsToFind = new Set(filter.split(/\s+/));

    /*
     * __validity_date_filter is special keyword to filter date which have outdated validity date
     * if the filter is applied and the entity is outdated we filter the data out
     * otherwise we consider the __validity_date_filter keyword found.
     * We dynamically set validityDateStart and validityDateEnd for entities that have a validityDateAttributes
     * defined
     */
    if (remainingKeywordsToFind.has('__validity_date_filter')) {
      const entityDates: ValidityDateEntity = !this.definition.validityDateAttributes
        ? data as unknown
        : {
          validityDateStart: getChained(data, this.definition.validityDateAttributes.start)
            ?? (data as ValidityDateEntity).validityDateStart,
          validityDateEnd: getChained(data, this.definition.validityDateAttributes.end)
            ?? (data as ValidityDateEntity).validityDateEnd,
        };
      if (this.validityDateFilter && DatabaseHelper.isEntityOutDated(entityDates, dayjs.utc().startOf('day'))) {
        return false;
      }
      remainingKeywordsToFind.delete('__validity_date_filter');
    }

    // go over all visible columns and try to match the keywords
    for (const fieldDef of this.visibleColumns) {
      // the value inside the column that we will try to match
      let value: string;

      /*
       * for numbers that have value we format it directly to string
       * without additional formatting (no separators etc)
       */
      if (fieldDef.type === 'number' && getChained(data, fieldDef.id)) {
        value = String(getChained(data, fieldDef.id));
      } // for all others value is just the formattedString
      else {
        value = this.formattedFieldValue(data, fieldDef)?.toString();
      }

      /*
       * if there is a value in the column, we compare it to the keywords we did not find yet.
       * if the value matches the filter exactly, we can stop the loop
       * otherwise, we still look in the following columns for an exact match.
       */
      if (value && value !== '') {
        value = StringHelper.cleanString(value);
        if (filter === value) {
          data.exactMatch = true;
          return true;
        }

        for (const keyword of remainingKeywordsToFind.values()) {
          if (value.includes(keyword)) {
            remainingKeywordsToFind.delete(keyword);
          }
        }
      }
    }

    // if all the keywords were found, the Set is empty
    return remainingKeywordsToFind.size === 0;
  }

  public setDefinition(definition: EntityDefinition): void {
    /*
     * we get the simple table definition from the outside
     * that might be first load or change of entities
     */
    this.definition = definition;

    this.dataSource = new MatTableDataSource();

    // Change the MatSort algorithm to correctly sort the different objects by the data we print
    this.dataSource.sortData = (data, sort): SomeEntityChainable[] => this.sortEntities(data, sort);

    /*
     * We use our own filterPredicate because the default one concatenates each field before testing the filter
     * Which provoke some weird case like searching armada and finding an object With two adjacent madagascar
     * (filtered as madagascarmadagascar)
     */
    this.dataSource.filterPredicate = (data, filter): boolean => this.isEntityMatchedByFilter(data, filter);

    if (this.paginator) {
      this.dataSource.paginator = this.paginator;
    }
    this.dataSource.sort = this.sort;

    /*
     * we pass the definition to data accessor as well
     * data accessor takes care of getting and seting values inside the entity
     */
    this.entityAccessor.setDefinition(definition);

    // we don't have editable definition for the new entity yet
    this.editableDefinitionLoaded = false;

    this.setColumns(this.definition.fields);
    this.updateVisibleColumns();

    // If entity has a validity date by default we hide the data outdated
    if (this.isValidityDateEntity() && !this.hideHeader) {
      this.setDateValidityVisibility({ checked: this.validityDateFilter, source: null });
    }
  }

  async loadEditableDefinitionIfNecessary(): Promise<void> {
    if (!this.editableDefinitionLoaded) {
      this.loading++;
      const editableDefinition = await this.dataLoader.getEntityDefinition(this.definition.class, true);
      this.updateTableDefinition(editableDefinition);
      this.editableDefinitionLoaded = true;
      this.loading--;
    }
  }

  async makeEditable(row: SomeEntity): Promise<void> {
    this.loading++;
    const currentEdited = this.entityAccessor.entity;
    this.removeEditedRowIfNewOne(currentEdited);
    this.tableStateService.setEditMode(row, this);

    if (this.editedRow != null) {
      this.exitEditMode(this.editedRow);
    }
    // we might have to load the full editable definition of the entity (if needed)
    await this.loadEditableDefinitionIfNecessary();

    // we might have to load the full entity
    await this.loadCompleteEntity(row);
    row.editable = true;
    this.entityAccessor.setEntity(this.definition.class, row);
    this.loading--;
    this.cdRef.detectChanges();
    /*
     * disable input once the view was painted
     * TODO: post circular refacto
     * EntityDetailComponent.disableScrollForInputTypeNumber();
     */

    this.editedRow = row;
  }

  public startLoad(): void {
    this.loading++;
  }

  public endLoad(): void {
    this.loading--;
  }

  /*
   * When we add a new row inline, this is added at the end and is in edit mode
   * we can either edit inline and save, or we can doubleClick on this row
   * to open an edit popup. When we do that we need the row with what we
   * already filled inline to be prefilled in the popup
   */
  public newEntity(row: SomeEntity = null): void {
    const currentEdited = this.entityAccessor.entity;
    this.removeEditedRowIfNewOne(currentEdited);
    this.tableStateService.setNormalMode();
    const actionEvent: ActionEvent = {
      action: {
        type: 'addModal',
        entityName: this.definition.class,
      },
      afterEntitySave: this.afterEntitySave,
      isNew: true,
    };

    if (row) {
      actionEvent.data = row;
    }

    this.onaction.emit(actionEvent);
  }

  private updateColumns(): void {
    if (this.entityTableConfig.autoColumnsBasedOnData) {
      this.visibleColumns = this.columns.filter((colDef: EntityFieldDefinition) => this.columnHasData(colDef));
    }
    const colsAndCats = EntityTableHelper.organizeColumns(this.visibleColumns, {
      config: this.entityTableConfig,
      selectedRows: this.selectedRows,
      hasOrderSort: this.isEntityWithOrderSort(),
      editMode: this.editMode,
      buttonList: this.buttonList,
      categoryIds: this.categories?.map(category => category.id),
    });

    this.displayedColumnIds = colsAndCats.displayedColumnIds;
    this.hasCategory = colsAndCats.hasCategory;
    this.categoryIds = colsAndCats.categoryIds;
    this.categoryColspan = colsAndCats.categoryColspan;
  }

  /**
   * Sets the columns of the table
   */
  private setColumns(columns: EntityFieldDefinition[]): void {
    this.columns = columns;

    /*
     * by default all the fields from the definition are shown where row != false
     * but in case where this table is nested collection and parent field is available
     * then the parent field definition of the ManyToMany or OneToMany can contain only limited
     * number of columns to be shown
     * In nested collection we also don't show the column mapped to the parent
     */
    this.visibleColumns = columns.filter(d => d.row === true && this.isntParentColumn(d));

    if (this.parentField?.columns) {
      this.visibleColumns = this.visibleColumns.filter(column => this.parentField.columns.indexOf(column.id) > -1);
    }
    if (this.tableHasModes()) {
      this.setTableModeColumns();
    }

    if (this.entityTableConfig.canChooseColumns) {
      this.visibleByDefault = this.visibleColumns.map(field => field.originalId ?? field.id);
    }
  }

  private afterEntitySave = (afterSaveData: AfterSave, newEntity = true): void => {
    /*
     * newly added items have to be added
     * note that inline creations and duplicates are ignored, the standard duplicates are just like creations
     */
    if (newEntity) {
      this.addRowToEndOfTable(afterSaveData.entity);
    }

    if (
      afterSaveData.eventType === UpdateEventType.Delete
      || afterSaveData.eventType === UpdateEventType.InlineDelete
      || (this.getActionEventType(afterSaveData.entity) === 'editModal' && newEntity)
    ) {
      this.removeRowById(afterSaveData.modifiedEntityId);
    }

    if (this.afterSaveAction) {
      this.afterSaveAction(afterSaveData);
    }

    this.update();
    this.dataSource.sort = this.sort;
  };

  private getActionEventType(row: SomeEntity): ActionType {
    return this.canEdit(row) ? 'editModal' : 'openModal';
  }

  public openEntity(row: SomeEntity, event: MouseEvent): void {
    if (event) {
      if (DatabaseHelper.isSpecialClick(event)) {
        return;
      }
    }
    event.preventDefault();

    // we don't open entity that we can't modal edit
    if (!this.canOpenModal(row)) {
      return;
    }
    // Saving the old id to later check if we are dealing with a new entity or not
    const entityId = row[this.definition.idField];
    const actionEvent: ActionEvent = {
      action: {
        type: this.getActionEventType(row),
        entityName: this.definition.class,
      } as EntityAction,
      data: this.canEdit(row) ? row : { id: row[this.definition.idField] },
      afterEntitySave: (afterSaveData: AfterSave) => {
        this.afterEntitySave(afterSaveData, entityId == null);
      },
      event,
    };
    this.onaction.emit(actionEvent);
  }

  linkedEntityDetail(row: SomeEntity, col: EntityFieldDefinition, event: MouseEvent) {
    if (event) {
      if (DatabaseHelper.isSpecialClick(event)) {
        return;
      }
      event.preventDefault();
    }
    const entity = {};

    // id field is the name of the field that holds id for the linked entity
    entity[col.idField] = row[col.id];

    const entityInformation: EntityInformation = {
      entityName: col.class,
      entity: entity,
      editMode: true,
      idField: col.idField,
      closeAfterSave: false,
      afterSaveAction: this.afterSaveAction,
      creation: false,
      afterCloseAction: () => this.cdRef.detectChanges(),
      layerId: null,
    };
    this.openEntityRequest.emit(entityInformation);
  }

  private async loadCompleteEntity(row: SomeEntity) {
    if (!this.entityAccessor.isNew(row)) {
      const fullEntity = await this.dataLoader.getEntity(this.definition.class, row);
      for (const key in fullEntity) {
        row[key] = fullEntity[key];
      }
      /*
       * If parentField has a prefill in this definition
       * We iterate trough our entities and set mapped values if we don't have
       * value in the entity
       */
      if (this.parentField?.prefill) {
        for (const fieldId in this.parentField.prefill) {
          const parentFieldId = this.parentField.prefill[fieldId];
          if (row[fieldId] === null) {
            row[fieldId] = this.parentEntity[parentFieldId];
          }
        }
      }
    }
  }

  async duplicateRow(row: SomeEntity) {
    if (this.loading) {
      return;
    }
    this.loading++;
    await this.loadEditableDefinitionIfNecessary();
    await this.loadCompleteEntity(row);
    const newRow = await EntityDataAccessor.duplicate(row, this.definition, this.dataLoader);
    newRow.entity.editable = true;
    this.addRowToEndOfTable(newRow.entity);
    this.loading--;
    this.dialogManager.showMessage(newRow.message, 'success');
    this.update();
  }

  // if this is a new item that has not yet been saved,then we just remove it from the list
  removeEditedRowIfNewOne(row: SomeEntity) {
    if (!row) {
      return;
    }

    if (!row[this.definition.idField]) {
      this.removeRow(row);
    }
  }

  public async addRow() {
    await this.loadEditableDefinitionIfNecessary();
    const currentEdited = this.entityAccessor.entity;
    this.removeEditedRowIfNewOne(currentEdited);
    // if data is orderable & the order is defined for some rows but not all, we update the order of the full table
    if (this.definition.emptyEntity?.order) {
      const isDataFullyOrdered = this.dataSource.data.filter(d =>
        d.order === undefined || d.order === null
      ).length === 0;
      if (!isDataFullyOrdered) {
        await this.updateOrderForEntities(0, 0);
        // Need to reload the definition to get the right order for the new row
        this.editableDefinitionLoaded = false;
        await this.loadEditableDefinitionIfNecessary();
      }
    }

    /*
     * take the template from the definition and just make it editable
     * create a new element if there is no template
     */
    let newRow = clone(this.definition.emptyEntity);
    if (!newRow) {
      newRow = {};
    }
    newRow.editable = true;

    /*
     * if parent field is available, it means this table is nested in OneToMany relationship
     * TODO: JF - we set the id, but we don't set the title of the linked entity (which should go to the _title field)
     */
    if (this.parentField) {
      const parentId = this.parentEntity[this.parentEntityDefinition.idField];
      newRow[this.parentField.mappedBy] = parentId;

      if (this.parentField.prefill) {
        EntityDataAccessor.fillEntityWithParentPrefill(this.parentField, this.parentEntity, newRow);
      }
    }
    this.addRowToEndOfTable(newRow);
    this.tableStateService.setEditMode(newRow, this);
    // alignToTop as false otherwise cause a display bug when on fullscreen mode
    this.tableRows?.last?.nativeElement.scrollIntoView(false);
  }

  public addRowToEndOfTable(newRow: SomeEntity) {
    this.setDataSourceData([...this.dataSource.data, newRow]);
    if (this.paginator) {
      this.paginator.lastPage();
    }
    this.entityAccessor.setEntity(this.definition.class, newRow);
    this.update();
  }

  private getUpdateEventType(row: SomeEntity) {
    if (row.duplicate) {
      return UpdateEventType.InlineDuplicate;
    }
    return this.entityAccessor.isNew() ? UpdateEventType.InlineNew : UpdateEventType.InlineUpdate;
  }

  public scrollDisableForComponentPage() {
    const componentParentPage: Element = this.elRef.nativeElement.closest('.components-page-right-content');
    if (componentParentPage) {
      this.parentDefaultScrollStyle = componentParentPage.getAttribute('style');
      componentParentPage.setAttribute('style', 'overflow: hidden;');
    }
  }

  public scrollEnableForComponentPage() {
    const componentParentPage: Element = this.elRef.nativeElement.closest('.components-page-right-content');
    if (componentParentPage) {
      componentParentPage.setAttribute('style', this.parentDefaultScrollStyle);
    }
  }

  onDragStart() {
    this.scrollDisableForComponentPage();
  }

  onDragEnd() {
    this.scrollEnableForComponentPage();
  }

  enableDrag() {
    this.dragDisabled = false;
  }

  disableDrag() {
    this.dragDisabled = true;
  }

  /**
   *  Drop work with filters activated, as long as entities are sorted using order
   */
  async drop(event: CdkDragDrop<SomeEntity[]>) {
    this.disableDrag();

    /** Exit if same index */
    if (event.currentIndex === event.previousIndex) {
      return;
    }

    /** Requirement: FilteredData & data is always sort by Order */
    this.dataSource.filteredData.sort((a, b) => DatabaseHelper.compareRaw(a.order, b.order, 'asc'));

    this.dataSource.data.sort((a, b) => DatabaseHelper.compareRaw(a.order, b.order, 'asc'));

    /** Find index of FilteredData items - pagination should be taken into account */
    let filteredDatasetIndexFrom = event.previousIndex;
    let filteredDatasetIndexTo = event.currentIndex;

    /*
     * event.currentIndex and event.previousIndex are indexes in the current page so we need to take previous pages
     * into account
     */
    if (this.paginator) {
      filteredDatasetIndexFrom += this.paginator.pageIndex * this.paginator.pageSize;
      filteredDatasetIndexTo += this.paginator.pageIndex * this.paginator.pageSize;
    }

    /** Find index of FilteredData items inside Data */
    const fromIndex = this.dataSource.data.indexOf(this.dataSource.filteredData[filteredDatasetIndexFrom]);
    const toIndex = this.dataSource.data.indexOf(this.dataSource.filteredData[filteredDatasetIndexTo]);

    /**
     * Loading spinner when reordering tables with a lot of data
     * The operation can last a couple seconds
     * TODO: In the futur, when reordering using swap will be used, will have to check
     *       the nb of entities between fromIndex and ToIndex instead of nb of elem inside data
     */
    if (this.dataSource.data.length >= this.orderingLimitValueBeforeBigOperation) {
      this.loading++;
    }

    await this.updateOrderForEntities(fromIndex, toIndex);

    /** Refresh Sorting */
    this.dataSource.sort = this.sort;

    /** End of spinner for reordering */
    if (this.dataSource.data.length >= this.orderingLimitValueBeforeBigOperation) {
      this.loading--;
    }
  }

  /**
   * Update Orders of entities
   *
   * @param fromIndex Drag from Index
   * @param toIndex   To index where drop
   */
  async updateOrderForEntities(fromIndex: number, toIndex: number) {
    /** Update the orderingMode */
    this.defineBestOrderingMode(fromIndex, toIndex);

    /** Update Item place because of drop */
    moveItemInArray(this.dataSource.data, fromIndex, toIndex);

    const orders = this.determineNewOrders(fromIndex, toIndex);
    /** Save of new orders */
    await this.saveOrders(orders, this.definition.class);
  }

  /**
   * Define the best ordering mode based on the drag&Drop boundaries
   *
   * Conditions for "swap" mode:
   *    - Big dataSource, exceeding orderingLimitValueBeforeBigOperation number of items
   *    - Small drag and drop distance, smaller than 100 items
   *    - valid checkOrderCoherency
   *
   * Default mode to "reordering"
   */
  public defineBestOrderingMode(fromIndex: number, toIndex: number) {
    const dataLength = this.dataSource.data.length;

    if (
      dataLength > this.orderingLimitValueBeforeBigOperation && this.checkOrderCoherency(fromIndex, toIndex, dataLength)
    ) {
      this.orderingMode = 'swap';
      return;
    }
    this.orderingMode = 'reordering';
  }

  /**
   * Check order coherency
   * Condition to be valid:
   *    - 1st condition:
   *        - datas should have an ascendent order
   *        - order should start from 1 to +infinity, with order of n+1 equal to order+1 of n
   *           - with n <=> 0 <= n < dataLength
   *    - 2nd condition:
   *        - for all items inside n <=> smallestIndex < n < biggestIndex, order != null
   *          - smallestIndex <=> smallest index between from & to indexes
   *          - biggestIndex <=> biggest index between from & to indexes
   */
  public checkOrderCoherency(
    fromIndex: number = 0,
    toIndex: number | undefined = undefined,
    dataLength: number,
  ): boolean {
    const firstDataItemOrder = this.dataSource.data[0].order;
    const lastDataItemOrder = this.dataSource.data[dataLength - 1].order;

    /** 1st condition check */
    if (!firstDataItemOrder || !lastDataItemOrder || firstDataItemOrder != 1 || lastDataItemOrder != dataLength) {
      return false;
    }

    const smallestIndex = fromIndex <= toIndex ? fromIndex : toIndex;
    const biggestIndex = fromIndex > toIndex ? fromIndex : toIndex;

    /** 2nd condition check */
    for (let i = smallestIndex; i <= biggestIndex; i++) {
      if (!this.dataSource.data[i] || !this.dataSource.data[i].order) {
        return false;
      }
    }
    return true;
  }

  /**
   * Determine array of new orders using two different methods:
   *    - swap orders between entities
   *    - reorganise the orders of entities from the first one to the last one
   */
  private determineNewOrders(smallestBoundary: number, biggestBoundary: number): EntityOrder[] {
    if (this.orderingMode === 'swap') {
      return this.orderUsingSwap(this.dataSource.data, smallestBoundary, biggestBoundary);
    }
    return this.orderUsingReorganization(this.dataSource.data);
  }

  /**
   * Reorganise the orders of entities from the first one to the last one
   * Iterate on every elements
   */
  public orderUsingReorganization(dataToReorder: SomeEntity[]): EntityOrder[] {
    const newOrders: EntityOrder[] = [];

    for (const [index, entity] of dataToReorder.entries()) {
      newOrders.push({ id: entity.id, order: (index + 1) });
    }
    return newOrders;
  }

  /**
   * Swap orders between entities
   * Swap the orders from top item at index 0 to bottom item at last index
   *
   * Work the following way:
   * [indexNumber] -> is the index of the order
   *  {
   *    [toIndex]   => "order": 5  <- dropped item
   *    [1]         => "order": 1
   *    [2]         => "order": 2
   *    [3]         => "order": 3
   *    [fromIndex] => "order": 4
   *  }
   *
   * ForEach [n]
   *   Swap performed:
   *    [ n ] => "order":  -> 5 --
   *                       |     |
   *    [n+1] => "order":  -- 1 <-
   *
   *  result:
   *    [ n ] => "order":     1
   *    [n+1] => "order":     5
   *
   * In the following case fromIndex < toIndex:
   *    [fromIndex] => "order": 2
   *    [1]         => "order": 3
   *    [2]         => "order": 4
   *    [3]         => "order": 5
   *    [toIndex]   => "order": 1  <- dropped item
   *
   * -> The array is reverse.
   */
  public orderUsingSwap(dataToReorder: SomeEntity[], fromIndex: number, toIndex: number): EntityOrder[] {
    /** Finding boundaries of swap items */
    const smallestBoundary = fromIndex <= toIndex ? fromIndex : toIndex;
    const biggestBoundary = fromIndex > toIndex ? fromIndex : toIndex;
    const direction = fromIndex < toIndex ? 'asc' : 'dsc';

    let slice: SomeEntity[];
    const sliceLimit = biggestBoundary - smallestBoundary;

    /** Slice the array of entities to only isolated entities to update */
    slice = dataToReorder.slice(smallestBoundary, biggestBoundary + 1);
    /** Reverse Array to easily swap orders */
    if (direction === 'asc') {
      slice = slice.reverse();
    }

    let currentOrder = 0;
    let nextOrder = 0;
    let index = 0;

    let currentEntity: SomeEntity;
    let nextEntity: SomeEntity;
    /** Array of struct (id,order) to update */
    const newOrders: EntityOrder[] = [];

    for ([index, currentEntity] of slice.entries()) {
      /** Get Next entity */
      nextEntity = slice[index + 1];

      /** Get Orders of current & next */
      currentOrder = currentEntity.order;
      nextOrder = nextEntity.order;

      /** Swap order values */
      currentEntity.order = nextOrder;
      nextEntity.order = currentOrder;

      /** Add new orders to slice */
      newOrders.push({ id: currentEntity.id, order: currentEntity.order });

      /** Check if limit reached */
      if (index + 1 >= sliceLimit) {
        break;
      }
    }
    newOrders.push({ id: nextEntity.id, order: nextEntity.order });
    return newOrders;
  }

  /**
   * Save an array of new orders, and then update modified entities
   *
   * @param orders      Array of pair id & orders to update
   * @param entityName  Name of entity to update orders
   * @returns           Success of saving
   */
  async saveOrders(orders: EntityOrder[], entityName: string): Promise<boolean> {
    if (this.saving) {
      return false;
    }
    this.saving = true;
    const saveResult = await this.entityAccessor.saveOrdersFor(entityName, orders);

    if (saveResult.success) {
      if (this.afterSaveAction) {
        /** Reload the complete updated entities */

        /**
         * 1. try to fetch data with ids
         * 2. if it fails (e.g. because list of ids is too long), try to fetch th whole table
         */
        let dataResult: SomeEntity[];
        await this.dataLoader.getEntitiesWithIds(entityName, saveResult.ids)
          .then((res: SomeEntity[]) => {
            dataResult = res;
          })
          .catch(async () => {
            await this.dataLoader.getTable(entityName)
              .then((res: SomeEntity[]) => {
                dataResult = res;
              });
          });

        /** We updated every orders of dataSource when "reordering" */
        if (this.orderingMode === 'reordering') {
          this.setDataSourceData(dataResult);
        } else {
          /** We only updated swap related items */
          this.updateDataSourceEntitiesWithArray(dataResult);
        }

        this.cdRef.detectChanges();
        this.update();
        this.dataSource.sort = this.sort;
      }
      this.dialogManager.showMessage(saveResult.message, 'success');
    } else {
      this.dialogManager.showMessage(saveResult.message ? saveResult.message : saveResult.error, 'error');
    }
    this.update();
    this.saving = false;
    return true;
  }

  /**
   * Restricts update of entities to those inside array
   */
  public updateDataSourceEntitiesWithArray(array: SomeEntity[]): void {
    this.setDataSourceData(this.dataSource.data.map(currentItem => {
      const updatedCurrentItem = array.find(arrayItem => arrayItem.id === currentItem.id);
      return updatedCurrentItem ? updatedCurrentItem : currentItem;
    }));
  }

  async saveRow(row: SomeEntity): Promise<boolean> {
    if (this.saving) {
      return false;
    }

    const coherencyErrors = this.entityAccessor.getFieldsErrors(
      this.parentField ? this.parentField.mappedBy : null,
      false,
    );
    if (coherencyErrors.length > 0) {
      this.dialogManager.showMessage('Issues to be fixed: ' + coherencyErrors.join('\n'), 'error');
      return false;
    }
    this.saving = true;
    const updateType = this.getUpdateEventType(row);

    // Inline files can't be save in row, so we remove them from row
    if (this.definition) {
      this.definition.fields.forEach(field => {
        if (field['type'] === 'files') {
          delete row[field.id];
        }
      });
    }

    /*
     * Since we save a new entity we have to force reload editableDefinition next time we want to
     * make a quick add. We have to do this because the next emptyEntity can change (e.g. for the color picker)
     */
    this.editableDefinitionLoaded = false;

    /*
     * If we update an element that does not yet exists (does not have an ID) and
     * We are currently on a collection inside an entity, we let the parent element save the item
     */
    if (this.linkBy && this.linkBy.parentWillSave && !this.entityAccessor.entity[this.definition.idField]) {
      const actionEvent: ActionEvent = {
        action: {
          type: 'afterCreation',
        },
        data: this.entityAccessor.entity,
      };
      this.onaction.emit(actionEvent);
      this.editedRow = null;
      this.entityAccessor.leaveEditMode();
      this.saving = false;
      this.tableStateService.setNormalMode();
      return true;
    }

    /*
     * If the table is inside entity-linked-collection and we are in One-To-Many relation
     * then we will pass the parent ID into entity, so that the "relation" between them is stored
     * eg: workpackageActivity['workpackage'] = wpId
     */
    if (this.linkBy && !this.linkBy.parentWillSave) {
      this.entityAccessor.entity[this.linkBy.fieldName] = this.linkBy.id;
    }

    const saveResult = await this.entityAccessor.saveEntity();
    /*
     * after inline save finished - just exit the edit mode, we should have the latest information, no need to reload
     * except that we need to let the parent component know of the update
     */
    if (saveResult.success) {
      this.entityAccessor.leaveEditMode();
      this.entityAccessor.setIdFromServer(saveResult.id);

      if (this.afterSaveAction) {
        this.afterSaveAction({
          entityName: this.definition.class,
          eventType: updateType,
          entity: saveResult.entity,
          layerId: null,
          idToReload: saveResult.entity.id,
          modifiedEntityId: saveResult.entity.id,
        });
      }
      this.dialogManager.showMessage(saveResult.message, 'success');
    } else {
      this.dialogManager.showMessage(saveResult.message ? saveResult.message : saveResult.error, 'error');
    }
    this.editedRow = null;
    this.update();
    this.saving = false;
    this.tableStateService.setNormalMode();
    this.dataSource.sort = this.sort;
    return true;
  }

  public updateTableDefinition(editableDefinition: EntityDefinition): void {
    this.entityAccessor.setDefinition(editableDefinition);
    for (const column of editableDefinition.fields) {
      const toBeUpdated = this.columns.find(d => d.id === column.id);
      /*
       * if we have found a column that can be updated let's do it
       * note that some fields are in the full entity-definition but are not in the table definition
       * those won't be found
       */
      if (toBeUpdated) {
        toBeUpdated.values = column.values;
        toBeUpdated.editable = column.editable;
        toBeUpdated.req = column.req;
      }
    }

    this.definition.emptyEntity = editableDefinition.emptyEntity;
  }

  public isOverridden(row: SomeEntity, field: EntityFieldDefinition): boolean {
    return DatabaseHelper.shouldShowOverride(row, field);
  }

  public getSpinergieValue(row: SomeEntity, field: EntityFieldDefinition): string {
    return DatabaseHelper.getSpinergieValue(row, field);
  }

  public getFieldTooltip(row: SomeEntityChainable, field: EntityFieldDefinition): string {
    return DatabaseHelper.getFieldTooltip(row, field);
  }

  public getComparison(row: SomeEntityChainable, field: EntityFieldDefinition): ComparisonData<unknown> {
    return getAdditionalData(row, field.id)?.comparison;
  }

  public getMaterialArrowIcon(arrow: ArrowComparison): PearlIcon {
    if (arrow.direction === 'flat') return 'flat';
    if (arrow.direction === 'up') return 'up';
    return 'down';
  }

  public getMaterialArrowIconSize(arrow: ArrowComparison): PearlIconSize {
    if (arrow.direction === 'flat') return 24;
    return 40;
  }

  public setData(values: SomeEntity[]): void {
    // we move to first page each time we set data to avoid the counter to be lost
    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.setDataSourceData(values);
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      const propDef = this.definition.fields.find(d => d.id === property);
      switch (propDef.type) {
        case 'text':
          return getChained(item, propDef.id);
        default:
          return getChained(item, property);
      }
    };

    this.updateVisibleColumns();
    this.cdRef.detectChanges();
  }

  public canEdit(row: SomeEntity): boolean {
    return !row.editable && !row.deleteMode && (
      ((this.parentField == null && this.definition.generalInfo.inlineEdit)
        || (this.parentField && this.parentField.inlineEdit)) && DatabaseHelper.hasEditRight(this.definition)
    );
  }

  public canDuplicate(row: SomeEntity): boolean {
    return !row.editable && !row.deleteMode && (
      ((this.parentField == null && this.definition.generalInfo.inlineDuplicate)
        || (this.parentField && this.parentField.inlineDuplicate)) && DatabaseHelper.hasEditRight(this.definition)
    ) && !this.readOnly;
  }

  public canDelete(row: SomeEntity): boolean {
    return !row.editable && !row.deleteMode && (
      ((this.parentField == null && this.definition.generalInfo.inlineDelete)
        || (this.parentField && this.parentField.inlineDelete)) && DatabaseHelper.hasEditRight(this.definition)
    ) && !this.readOnly;
  }

  public canAdd(): boolean {
    return ((this.parentField == null && this.definition.generalInfo.inlineAdd)
      || (this.parentField && this.parentField.inlineAdd)) && DatabaseHelper.hasEditRight(this.definition);
  }

  get canAddInModal(): boolean {
    if (!this.definition) {
      return false;
    }
    /*
     * the button add in modal is displayed only if it isn't displayed in his parentField
     * collection displayed the add new only if the add existing also appear
     */
    return this.addNewEnable
      && ((this.parentField == null && this.definition.generalInfo.addNew)
        || (this.parentField && !this.parentField.addExisting))
      && !this.readOnly && DatabaseHelper.hasEditRight(this.definition);
  }

  /*
   * function to see if the field is a reference to our parentField.
   * in this case we don't display this column
   */
  public isntParentColumn(field: EntityFieldDefinition): boolean {
    if (!this.parentField) {
      return true;
    }
    return field.id !== this.parentField.mappedBy;
  }

  public enterDeleteMode(row: SomeEntity): void {
    if (!this.definition.deleteMessage) {
      row.deleteMode = true;
      return;
    }
    const dialogRef = this.dialog.open(DeleteEntityDialog, {
      width: '355px',
      height: 'fit-content',
      data: {
        entityTitle: this.definition.header,
        deleteMessage: this.definition.deleteMessage,
      },
      panelClass: ['spin-dialog-box'],
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteRow(row);
      }
    });
  }

  public exitDeleteMode(row: SomeEntity): void {
    row.deleteMode = false;
  }

  private removeRowById(entityId: NumOrString): void {
    const index = this.dataSource.data.findIndex(entity => entity.id === entityId);
    this.removeRowByIndex(index);
  }

  private removeRow(row: SomeEntity): void {
    const index = this.dataSource.data.indexOf(row);
    this.removeRowByIndex(index);
    this.dataSource.sort = this.sort;
  }

  public removeRowByIndex(index: number): void {
    if (index < 0) {
      return;
    }

    const newData = this.dataSource.data;
    newData.splice(index, 1);
    this.setDataSourceData(newData);
  }

  public exitEditMode(row: SomeEntity): void {
    this.tableStateService.setNormalMode();
    row.editable = false;
    this.editedRow = null;
    this.removeEditedRowIfNewOne(row);
    // get index of non-edited data
    const index = this.dataSource.data.indexOf(row);
    if (index < 0) {
      return;
    }
    this.dataSource.data[index] = this.entityAccessor.leaveEditMode();
    // re-render table by assigning data
    // eslint-disable-next-line no-self-assign
    this.setDataSourceData(this.dataSource.data);
  }

  public setDataSourceData(data: SomeEntity[]): void {
    this.dataSource.data = data;
    if (this.entityTableConfig.canChooseRows && !this.selectedRows) {
      this.selectedRows = new SelectionModel<SomeEntity>(true, data);
    }
  }

  async deleteRow(row: SomeEntity): Promise<void> {
    /*
     * If the ID of the entity isn't fixed we aren't deleting an existing entity but one that doesn't exist yet.
     * This one has been added by a parent object that must handle the destruction of this new entity.
     */
    if (!row[this.definition.idField]) {
      const actionEvent: ActionEvent = {
        action: {
          type: 'removeLink',
        },
        data: row,
      };
      this.onaction.emit(actionEvent);
      return;
    }

    this.deleting = true;
    const deleteResult = await this.entityAccessor.deleteEntity(row);

    if (deleteResult.success) {
      this.dialogManager.showMessage(deleteResult.message, 'success');

      this.afterEntitySave({
        entityName: this.definition.class,
        eventType: UpdateEventType.InlineDelete,
        entity: null,
        layerId: null,
        idToReload: null,
        modifiedEntityId: row[this.definition.idField],
      }, false);
    } else {
      this.dialogManager.showMessage(deleteResult.message, 'error');
    }
    this.deleting = false;
  }

  public openFilterColumnsDialog(): void {
    const choosableFields = [];

    /*
     * Columns Filtering dialog was designed to work on a list of field
     * it expect every field to have fieldsetTitle value which it will use to group
     * the fields into fieldsets (categories)
     */
    this.columns.forEach(column => {
      // Don't keep collections except many to many that are row=>true (unspecified collectionType are not kept)
      if (column.type === 'collection' && !(column.collectionType === 'ManyToMany' && column.row)) {
        return;
      }

      choosableFields.push(
        { id: column.id, name: column.title, fieldsetTitle: column.fieldsetTitle },
      );
    });

    const columnsDialog = this.filterColumnsDialog.open<FilterColumnsDialogComponent, FilterColumnsData>(
      FilterColumnsDialogComponent,
      {
        'width': '600px',
        'maxHeight': '750px',
        autoFocus: false,
        data: {
          default: this.visibleByDefault,
          allFields: choosableFields,
          shownColumnsIds: this.visibleColumns.map(vc => vc.id),
        },
      },
    );

    columnsDialog.afterClosed().subscribe((result: string[]) => {
      this.setVisibleColumns(result);
      this.updateColumns();
      this.columnsFiltered.emit(result);
    });
  }

  /**
   * Triggered either on edit action button click or on table row double-click
   */
  public onOpenEntityClick(row: SomeEntity, event: MouseEvent): void {
    this.tableStateService.setNormalMode();

    if (row.id || !DatabaseHelper.hasEditRight(this.definition)) {
      this.openEntity(row, event);
      return;
    }

    event.preventDefault();
    this.newEntity(row);
  }

  public onRowClick(row: SomeEntity): void {
    if (!this.selectableRowPropId) {
      return;
    }
    const rowId = row[this.selectableRowPropId];
    this.onrowselected.emit(rowId);
  }

  getFieldClass(field: EntityFieldDefinition): object {
    return {
      'has-error': this.fieldInvalid(field),
    };
  }

  getNumber(field: EntityFieldDefinition): string | number {
    return this.entityAccessor.getNumber(field);
  }

  getDate(field: EntityFieldDefinition) {
    return this.entityAccessor.getDatetime(field);
  }

  getChoice(field: EntityFieldDefinition) {
    return this.entityAccessor.getChoice(field);
  }

  getValue(field: EntityFieldDefinition) {
    return this.entityAccessor.getValue(field);
  }

  isChecked(field: EntityFieldDefinition): boolean {
    const value = this.entityAccessor.getValue(field);
    return DatabaseHelper.isTrue(value);
  }

  isValidityDateEntity() {
    return DatabaseHelper.isValidityDateEntity(this.definition);
  }

  public shouldHaveButtonList(row: SomeEntity): boolean {
    const collapsedButtonCount = this.uiService.isSmallDisplay() ? 2 : 3;
    return this.buttonCollapsed(row) < collapsedButtonCount || !!this.parentField;
  }

  buttonCollapsed(row: SomeEntity): number {
    /*
     * TODO : this version of the code is not really dynamic because
     * we must update it
     */
    let numButton = 0;
    if (this.canEdit(row)) {
      numButton++;
    }
    if (this.canDelete(row)) {
      numButton++;
    }
    if (this.canOpenModal(row)) {
      numButton++;
    }
    if (this.canDuplicate(row)) {
      numButton++;
    }
    if (row.editable) {
      numButton += 2;
    }
    if (row.deleteMode) {
      numButton += 2;
    }
    if (this.buttonList && !row.deleteMode && !row.editable) {
      this.buttonList.forEach(button => {
        if (this.shouldShowButton(button, row)) {
          numButton++;
        }
      });
    }
    return numButton;
  }

  setDate(field: EntityFieldDefinition, value: Dayjs): void {
    this.entityAccessor.setDatetime(field, value);
  }

  /**
   * Set color only detect valid change color
   * we add this function to detect when color picker is reset
   * and we reset color value
   */
  anyColorChange(field: EntityFieldDefinition, event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.value || input.value == '') {
      this.entityAccessor.setAnything(field, '');
    }
  }

  setColor(field: EntityFieldDefinition, event): void {
    this.entityAccessor.setAnything(field, event);
    this.cdRef.detectChanges();
  }

  setAnything(field: EntityFieldDefinition, event): void {
    this.entityAccessor.setAnything(field, event.target.value);
  }

  setNumber(field: EntityFieldDefinition, event): void {
    this.entityAccessor.setNumber(field, event);
  }

  setCheckbox(field: EntityFieldDefinition, event): void {
    this.entityAccessor.setAnything(field, event.checked);
  }

  setCollection(field: EntityFieldDefinition, event: OptionValue[]): void {
    this.entityAccessor.setCollection(field, event);
  }

  fieldInvalid(field: EntityFieldDefinition): boolean {
    return !this.entityAccessor.fieldValidity(field, this.parentField ? this.parentField.mappedBy : null).valid;
  }

  getErrorMessage(field: EntityFieldDefinition): string {
    return this.entityAccessor.fieldValidity(field, this.parentField ? this.parentField.mappedBy : null).msg;
  }

  optionSelected(field: EntityFieldDefinition, option: OptionValue): void {
    this.entityAccessor.optionSelected(field, option);
  }

  formattedFieldValue(row: SomeEntityChainable, field: EntityFieldDefinition): string | null {
    return DatabaseHelper.formatFieldValue(field, row, null, false, this.timezoneService.timezone);
  }

  /**
   * Modal Edit button is visible on all rows, except new items.
   * New items are the one added by inline add
   * However we don't let Modal edit button appear when we are already
   * editing the row or when we are deleting the row
   */
  canOpenModal(row: SomeEntity): boolean {
    /*
     * we have to check if the table interaction by default is allowed
     * and also if the collection is "navigable"
     */
    const tableInteractionAllowed = this.tableInteraction
      && (
        !this.parentField
        || this.parentField.link !== false
      );

    /*
     * We want to avoid the case where we are opening an inline added entity that belongs to another entity
     * This would cause issues when saving the children entity because
     * saving the children entity doesn't actually save the data
     * and the parent entity will need to be saved for the children entity to be saved
     * If the parentEntity is null, we are safe
     * const existingRow = !this.entityAccessor.isNew() || this.parentEntity == null
     */
    return !this.unopenable && tableInteractionAllowed && !row.deleteMode && !this.readOnly;
  }

  isRowEditable(row: SomeEntity): boolean {
    const editable = row.editable === true;
    return editable;
  }

  urlFromLink(row: SomeEntity, field: EntityFieldDefinition = null): string {
    if (field) {
      return '/dashboard/table/' + field.class + '/' + row[field.id];
    }
    return '/dashboard/table/' + this.definition.class + '/' + row.id;
  }

  externalUrlFromLink(row: SomeEntity, field: EntityFieldDefinition): string {
    return row[`${field.id}__link`] ? row[`${field.id}__link`] : row[field.id];
  }

  externalLinkTitle(row: SomeEntity, field: EntityFieldDefinition): string {
    return field?.linkPropTitle ? NavigationHelper.getLinkTitle(field, row) : field.title;
  }

  public linkButtonData(button: Button, row: SomeEntity): LinkData {
    return NavigationHelper.getLinkActionData(button as LinkButton, row);
  }

  public update(): void {
    this.cdRef.detectChanges();
  }

  public defaultAdd(): void {
    if (
      this.canAdd() && this.editMode && this.dataSource
      && (!this.dataSource.data.length || this.parentField?.style === 'integrated')
    ) {
      this.addRow();
    }
  }

  public advancedFiltersClick(field: EntityFieldDefinition): void {
    EntityTableComponent.openSelector(field, this.$collectionMultis, this.dialogManager, this.cdRef);
  }

  public getCollectionFieldClass(field: EntityFieldDefinition): object {
    const fieldClass = this.getFieldClass(field);
    if (field.selector) {
      fieldClass['collection-advanced-filters'] = true;
    } else {
      fieldClass['collection-without-advanced'] = true;
    }
    return fieldClass;
  }

  public getButtonRouterLink(button: Button, row: SomeEntity): string {
    return NavigationHelper.getNavigateLink(button as LinkButton, row);
  }

  public getOpenSelectorTooltip(field: EntityFieldDefinition): string {
    return SelectorHelper.getSelectorButtonLabel(field.selector); // e.g. 'vessel selection' or 'rig selection'
  }

  public static openSelector(
    field: EntityFieldDefinition,
    collectionMultis: QueryList<MultiFormFieldComponent>,
    dialogManager: DialogManager,
    cdRef: ChangeDetectorRef,
    restrictedValues?: OptionValue[],
  ): void {
    const { filterId } = field.selector.entity;
    let selectedValues = [];
    collectionMultis.forEach($multi => {
      if ($multi.parentField.id === field.id) {
        selectedValues = $multi.selectedOptions.map(option => option.id);
      }
    });

    const appliedFilters: LayerFilter = {
      [filterId]: {
        filterType: 'multi',
        values: selectedValues,
      },
    };

    const afterFilterAction = (filters: LayerFilter): void => {
      if (filters?.[filterId]?.values) {
        collectionMultis.forEach($multi => {
          if ($multi.parentField.id === field.id) {
            $multi.setValues(filters[filterId].values);
            cdRef.detectChanges();
          }
        });
      }
    };

    const selectorState: SelectorState = {
      ...field.selector,
      fieldsets: FilterHelper.initializeFieldsetTypeAndProp(field.selector.fieldsets),
      appliedFilters,
      afterFilterAction,
      mustSelectItems: true,
    };
    if (restrictedValues) {
      selectorState.entityIdsFromData = FilterHelper.findDistinctPropValues<number>(restrictedValues, 'id');
    }
    dialogManager.openSelectorDialog(selectorState);
  }

  public tableLeaveEditMode(): void {
    if (!this.dataSource || !this.dataSource.data) {
      return;
    }
    for (const row of this.dataSource.data) {
      if (row.editable) {
        this.exitEditMode(row);
      }
    }
  }

  public trackRow = (index, row: SomeEntity) => {
    return row[this.definition.idField];
  };

  public trackField = (index, field: EntityFieldDefinition) => {
    return field.id;
  };

  public getPageRouterLink(row: SomeEntity, field: EntityFieldDefinition) {
    return NavigationHelper.getPageRouterLink(row, field);
  }

  public onPageLinkClick(event: MouseEvent, field: EntityFieldDefinition, row: SomeEntity, valueIndex?: number): void {
    const buttonEvent = field.openInNewTab
      ? NavigationHelper.getPageNewTabLinkButtonEvent(event, field, row, valueIndex)
      : NavigationHelper.getPageNavigationButtonEvent(event, field, row, valueIndex);

    if (!buttonEvent) {
      return;
    }
    this.onaction.emit(buttonEvent);
  }

  public getPageLinkStyle(field: EntityFieldDefinition, row: SomeEntityChainable, valueIndex?: number): object {
    return NavigationHelper.getPageLinkStyle(field.href, this.config.urls, row, field.require, valueIndex);
  }

  public scrollTop(): void {
    const tableContainer: Element = this.elRef.nativeElement.querySelector('.entity-table-container');
    tableContainer.scrollTo(0, 0);
  }

  private columnHasData(columnDefinition: EntityFieldDefinition): boolean {
    const propToCheck = getPropKeyForNonFilterField(columnDefinition);
    return this.dataSource.data.some(row => getChained(row, propToCheck) != null);
  }

  /**
   * Set the visible columns in the table and compute the description column
   * by concatenating all the invisible columns
   * @param columns checked columns in column selection dialog
   */
  public setVisibleColumns(columns: string[]): void {
    // We need this variable to compute the description column data
    let invisibleColumns: EntityFieldDefinition[] = [];

    if (columns && columns.length) {
      invisibleColumns = this.columns.filter((entityFieldDef: EntityFieldDefinition) =>
        !columns.includes(entityFieldDef.id)
      );

      this.visibleColumns = this.columns.filter((entityFieldDef: EntityFieldDefinition) =>
        invisibleColumns.find(hiddenFieldDef => hiddenFieldDef.id === entityFieldDef.id) === undefined
      );
      if (this.showDescriptionColumn) {
        this.visibleColumns.push(this.descriptionField);
      } else {
        // Remove description field from visible columns
        this.visibleColumns = this.visibleColumns.filter((entityFieldDef: EntityFieldDefinition) =>
          entityFieldDef.id !== 'hiddenInfo'
        );
      }
    }

    if (columns && columns.length === 0) {
      this.visibleColumns = this.showDescriptionColumn ? [this.descriptionField] : [];
      invisibleColumns = this.columns;
    }
    if (this.tableHasModes()) {
      this.setTableModeColumns();
    }
    if (columns && this.showDescriptionColumn) {
      invisibleColumns = invisibleColumns.sort((a, b) => a.order < b.order ? 1 : -1);
      // Here we loop on each row and concatenate invisibleColumns value to hidden information
      for (const row of this.dataSource.data) {
        let hiddenInfo = '';
        invisibleColumns.forEach((entityFieldDef: EntityFieldDefinition) => {
          const formattedId = 'formatted_' + entityFieldDef.id;
          let formattedValue = getChained<any>(row, formattedId) ?? this.formattedFieldValue(
            row,
            entityFieldDef,
          );
          // If field type is files, formattedValue is an array of file info
          if (entityFieldDef.type === 'files' && Array.isArray(formattedValue)) {
            formattedValue = formattedValue.map(file => `<a href="${file.link}" target="_blank">${file.title}</a>`)
              .join(', ');
          }
          if (getChained(row, entityFieldDef.id)) hiddenInfo += `${entityFieldDef.title}: <b>${formattedValue}</b>; `;
        });
        row['hiddenInfo'] = hiddenInfo;
        row['formatted_hiddenInfo'] = hiddenInfo;
      }
    }

    this.updateColumns();
    this.cdRef.detectChanges();
  }

  public onCheckBoxShowDescriptionColumnChange(): void {
    this.updateVisibleColumns();
    this.showDescriptionColumnChanged.emit(this.showDescriptionColumn);
  }

  /**
   * Update visible columns, if showDescriptionColumn is true, we need to add the description column
   *
   * @param result checked columns in column selection dialog
   * @returns void
   */
  private updateVisibleColumns(): void {
    if (this.showDescriptionColumn) {
      // When we check the box for the first time the data needs to be computed
      this.setVisibleColumns(this.visibleColumns.map(e => e.id));
    } else {
      // Else we need to remove the description column from the visibleColumns
      this.visibleColumns = this.visibleColumns.filter((entityFieldDef: EntityFieldDefinition) =>
        entityFieldDef.id !== 'hiddenInfo'
      );
      this.updateColumns();
    }
  }

  public getPageLinkList(row: SomeEntity, field: EntityFieldDefinition): PageLinkSpec[] {
    return NavigationHelper.getPageLinkFromList(this.config, row, field, row[field.id]);
  }

  public getExternalLink(field: EntityFieldDefinition, row: SomeEntityChainable): string {
    return field.url ? getChained(row, field.url) : null;
  }

  public getColorPickerPresetColors() {
    return ColorHelper.colors;
  }

  public getColorFieldStyle(field: EntityFieldDefinition, row: SomeEntity) {
    const color = this.formattedFieldValue(row, field);
    if (!color) {
      return {};
    }

    return {
      'border-style': 'solid',
      'width': '120px',
      'height': '25px',
      'padding': '2px',
      'border-width': '2px',
      'background': color,
      'border-color': '#bbbbbb',
    };
  }

  public onFieldButton(buttonEvent: ActionEvent) {
    this.onaction.emit(buttonEvent);
  }

  public setSelectedIndex(tabIndex: number) {
    if (this.selectedTabIndex != tabIndex) {
      this.selectedTabIndex = tabIndex;
    }
  }

  public tabChanged(event: MatTabChangeEvent) {
    this.ontabchanged.emit(this.tabs[event.index].value);
  }

  public setDateValidityVisibility(checkboxEvent: MatCheckboxChange) {
    this.validityDateFilter = checkboxEvent.checked;
    // We force the filter predicate, to hide/unhide the data outside validity range
    this.applyFilter(this.dataSource.filter);
  }

  public tableHasModes() {
    return this.tableModes && this.tableModes.length;
  }

  public onTableModeChange(tableMode: string) {
    this.selectedTableMode = tableMode;
    this.setTableModeColumns();
  }

  /**
   * When user change table mode we adapt field id for each field which are modeSensitive: true
   * The new id will be the original field id + selectedTableMode
   */
  public setTableModeColumns() {
    let columnChanged = false;
    this.visibleColumns = this.visibleColumns.map(field => {
      if (!this.tableHasModes() || !field.modeSensitive) {
        return field;
      }
      columnChanged = true;
      if (!field.originalId) {
        field.originalId = field.id;
      }
      field.id = `${field.originalId}${this.selectedTableMode}`;
      return field;
    });
    if (columnChanged) this.updateColumns();
  }

  /**
   * If table has modes and the field is modeSensitive
   * We suffix the title with selected mode otherwise we simply return field title
   */
  public getFieldHeaderTitle(field: EntityFieldDefinition) {
    if (!this.tableHasModes() || !field.modeSensitive) {
      return field.title;
    }
    return `${field.title} (${this.selectedTableMode})`;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    const numSelected = this.selectedRows.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public toggleAllRows(): void {
    if (this.isAllSelected()) {
      this.selectedRows.clear();
      this.onSelectionChange.emit(this.selectedRows.selected);
      return;
    }

    this.selectedRows.select(...this.dataSource.data);
    this.onSelectionChange.emit(this.selectedRows.selected);
  }

  /**
   * Update selectedRows and emit onSelectionChange
   * @param row Row to be toggle/untoggle
   */
  public onToggleRow(row: SomeEntity): void {
    this.selectedRows.toggle(row);
    this.onSelectionChange.emit(this.selectedRows.selected);
  }

  /** The label for the checkbox on the passed row */
  public checkboxLabel(row?: SomeEntity): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selectedRows.isSelected(row) ? 'deselect' : 'select'} row ${row.order + 1}`;
  }
}
