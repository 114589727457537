import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, computed, effect, inject,
  signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';

import { AppInfoService } from '../../app/app-info-service';
import { FieldSettings, FilterApplied } from '../../helpers/types';
import { ProductAnalyticsService } from '../../shared/product-analytics/product-analytics.service';

export const CHECKBOX_EXCLUDE = '__exclude';
export type CheckboxExclude = boolean | typeof CHECKBOX_EXCLUDE;

/**
 * @description Checkbox component can be use for both FieldSettings.filterType === 'checkbox' and 'checkbox_exclude'.
 * The first one is a simple checkbox. The second one:
 *   - add third state: __exclude.
 *   - HTMLElement exclude toggle this state. state following __exclude is always unchecked.
 */
@Component({
  selector: 'spin-filters-checkbox',
  templateUrl: 'spin-filters-checkbox.component.html',
  styleUrls: ['spin-filters-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCheckboxModule, MatTooltipModule, FormsModule, NgIf, NgClass],
})
export class SpinFiltersCheckboxComponent {
  private readonly appInfoService = inject(AppInfoService);
  private readonly status = signal<CheckboxExclude>(false);
  private readonly productAnalyticsService = inject(ProductAnalyticsService);
  private readonly router = inject(Router);

  public readonly spanExcludeLabel = computed<'clear' | 'exclude'>(() =>
    this.status() === CHECKBOX_EXCLUDE ? 'clear' : 'exclude'
  );

  public isIndeterminate = computed<boolean>(() => this.status() === CHECKBOX_EXCLUDE);

  @ViewChild(MatCheckbox)
  public readonly $checkbox: MatCheckbox;

  @Input()
  public readonly field: FieldSettings;

  @Output()
  public readonly checkboxFilterChange = new EventEmitter<FilterApplied>();

  constructor() {
    effect(() => {
      if (!this.isIndeterminate()) return;
      this.productAnalyticsService.trackAction('checkboxExcludeUsed', {
        path: this.router.url,
        filterId: this.field.id,
      });
    });
  }

  public reset(): void {
    this.setFilterValue(!!this.field.default);
  }

  public clear(): void {
    this.setFilterValue(false);
  }

  public setFilterValue(value: CheckboxExclude): void {
    switch (value) {
      case CHECKBOX_EXCLUDE:
        this.setIndeterminateState(true);
        break;
      default:
        this.$checkbox.indeterminate = false;
        this.$checkbox.checked = Boolean(value);
        this.status.set(Boolean(value));
        break;
    }
  }

  public checkboxChange(checkbox: MatCheckbox): void {
    this.appInfoService.userAction(String(this.field.id), !this.$checkbox.checked);
    this.status.set(checkbox.checked);
    this.fire();
  }

  public checkboxIndeterminateChange(): void {
    this.setIndeterminateState(!this.$checkbox.indeterminate);
    this.fire();
  }

  private fire(): void {
    const filter = new FilterApplied({ ...this.field, values: [this.status()], active: true });
    this.checkboxFilterChange.emit(filter);
  }

  /**
   * @description Set the state of MatCheckbox.indeterminate. Next click, whether it is
   * on exclude HTMLElement or checkbox itself will be unchecked
   */
  private setIndeterminateState(isIndeterminate: boolean): void {
    this.$checkbox.indeterminate = isIndeterminate;
    this.$checkbox.checked = isIndeterminate;
    this.status.set(isIndeterminate ? CHECKBOX_EXCLUDE : false);
  }
}
