import { Injectable } from '@angular/core';

import { SelectableSplitBy } from '../graph/chart-types';
import { ChartingHelpers } from '../graph/charting-helpers';
import { ColorHelper } from './color-helper';
import { Color } from './types';
import { ColorDefinition } from './legend-types';

// This service intends to keep a "cache" at the app level of legends colors for the split by selects
@Injectable({
  providedIn: 'root',
})
export class SplitByColorService {
  // Default color (template blue)
  public static DEFAULT_BLUE: Color = '#607D8B';

  // Split-to-colors dictionary
  public colorsBySplit: { [splitName: string]: ColorDefinition[] } = {};

  /**
   * If we dectect duplicated colors in the subset of split values in the displayedSplitValues paramater
   * We reset the colors of the split to reassign unique colors
   * it's only possible if the displayedSplitValues param contains less elements than available colors in the
   * color scale
   *
   * This method should be called before we retrieve or assign colors with getOrAssignColor()
   *
   * @param splitValues the list of split values to color displayed in the chart
   * @param splitName the split name (e.g. "region", "country" etc.)
   */
  public resetColorsIfDuplicatedValues(
    splittedValues: string[] | object,
    selectedSplit: SelectableSplitBy | undefined,
  ): void {
    // if we have more split than defined colors in the scale we can't avoid duplications
    const colors = ColorHelper.getColorScale();
    let displayedSplitValues: string[];

    /**
     * When passing series as splittedValues argument from heavy analytics, there is an issue.
     *
     * Attribute colorBySplit of this class has following structure:
     *   - { keyStringValue: color }
     *
     * But sometimes series has following structure:
     *   - { id: keyStringValue }
     *
     * In that case ween need to use Object.values() and not Object.keys(), that's why
     * below we check typeof key to whether to use Object.keys() or Object.values()
     */
    if (Array.isArray(splittedValues)) {
      displayedSplitValues = splittedValues as string[];
    } else {
      const keys = Object.keys(splittedValues);
      displayedSplitValues = (/^\d+$/.test(keys[0])) ? Object.values(splittedValues) : keys;
    }

    if (!selectedSplit?.value) {
      return;
    }

    const splitName = selectedSplit.value;

    if (!(splitName in this.colorsBySplit) || displayedSplitValues.length > colors.length) {
      return;
    }

    const splitColors: Color[] = [];
    displayedSplitValues.forEach(value => {
      const matchingColor = this.colorsBySplit[splitName].find(color => color.id === value);
      if (matchingColor) {
        splitColors.push(matchingColor.fill);
      }
    });

    const duplicatedColors = splitColors.filter((element, index) => {
      return splitColors.indexOf(element) !== index;
    });

    // no duplicated colors
    if (duplicatedColors.length === 0) {
      return;
    }

    // compute a list of indexes of available colors, excluding those who are duplicated
    const indexesAvailableColors = [];
    for (let index = 0; index < colors.length; index++) {
      if (!splitColors.some(color => color === colors[index])) {
        indexesAvailableColors.push(index);
      }
    }

    if (indexesAvailableColors.length === 0) {
      /*
       * it shouldn't happen as the number displayedSplitValues is lower than the available colors
       * but let's handle it by safety
       */
      return;
    }

    for (let i = 0; i < duplicatedColors.length; i++) {
      const splitsDuplicated = this.colorsBySplit[splitName].filter(
        color => displayedSplitValues.includes(color.id) && duplicatedColors[i] === color.fill,
      );
      if (splitsDuplicated.length < 2) {
        continue;
      }

      // we want to replace the color of the 2nd split duplicated
      const secondSplit = this.colorsBySplit[splitName].find(color => color.id === splitsDuplicated[1].id);
      secondSplit.fill = ColorHelper.pickFromScale(indexesAvailableColors[i], false);
    }
  }

  /**
   * Retrieve or assign a new color based on the pickFromScale method
   *
   * @param splitName The split name (e.g. "region", "country" etc.)
   * @param splitValue The split values (e.g. "Asia", "Vessel B40" etc.)
   * @param isNotAvailable true if we want to assign the unaivailable color of the color scale
   * @returns
   */
  public getOrAssignColor(selectedSplit: SelectableSplitBy | undefined, splitValue: string): ColorDefinition {
    /**
     * We give a "none" splitName that will not match any existing this.colorsBySplit[] entry
     * Therefore, the colors will be generated by ColorHelper.pickFromScale()
     */
    const splitName = selectedSplit?.value || 'none';

    if (!(splitName in this.colorsBySplit)) {
      this.colorsBySplit[splitName] = [];
    }

    const matchingColor = this.colorsBySplit[splitName].find(color => color.id === splitValue);
    if (matchingColor) {
      return matchingColor;
    }

    const isNotAvailable = this.isNotAvailable(selectedSplit, splitValue);
    const color = {
      id: splitValue,
      fill: ColorHelper.pickFromScale(this.colorsBySplit[splitName].length, isNotAvailable),
    };

    this.colorsBySplit[splitName].push(color);

    return color;
  }

  /**
   * Determine if splitValue is the "unavailable" generic value
   * @param selectedSplit
   * @param splitValue
   * @returns
   */
  private isNotAvailable(selectedSplit: SelectableSplitBy, splitValue: string) {
    const nullTitle = selectedSplit?.nullTitle || ChartingHelpers.nullGroupTitle;
    return splitValue === nullTitle;
  }
}
