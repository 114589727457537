import { Injectable } from '@angular/core';

import { FieldRangeSpecs, FieldSettings, FieldType, SpinGeoDataPoint } from '../../../helpers/types';
import { RawDataPoint } from '../../../graph/chart-types';
import { getChained } from '../../../data-loader/ref-data-provider';
import { PearlIcon } from '../../pearl-components';

@Injectable({
  providedIn: 'root',
})
export class FieldsHelperService {
  /**
   * Visual type of the field. This method exists mainly because *ngSwitchCase cannot handle
   * more advanced conditions (it handles only a case), so we use ngSwitch on this method
   */
  public getFieldType(field: FieldSettings, result: RawDataPoint): FieldType | 'icon-field' {
    if (field['__visualType']) {
      return field['__visualType'];
    }

    let visualType: any = field.type;

    if (!field.colorRanges) {
      if (field.button) {
        visualType = 'button';
      }

      field['__visualType'] = visualType;
    }

    const icon = this.getResultFieldIcon(field as any, result);

    if (icon) {
      return 'icon-field';
    }

    return field.type;
  }

  public getResultFieldIcon(field: FieldSettings, result: RawDataPoint): PearlIcon {
    const range = this.getResultFieldRange(field, result);
    return range?.icon;
  }

  public getResultFieldRange(field: FieldSettings, result: RawDataPoint): FieldRangeSpecs {
    if (!field.colorRanges || !(field.id in result)) {
      return null;
    }
    const value = getChained(result, field.id);
    return field.colorRanges.find(r => r.range[0] === value);
  }

  public getResultFieldTooltip(field: FieldSettings, result: RawDataPoint): string {
    const range = this.getResultFieldRange(field, result);
    return range?.tooltip;
  }

  /**
   * A field can have a number of custom css parameters. (css, conditionalCss, colorRanges etc..)
   * We see if the field defines these attributes and we apply them if necessary
   * @param field
   * @param result
   * @returns a style dict
   */
  public getCustomCssStyle(field: FieldSettings, result: SpinGeoDataPoint): {
    [attribute: string]: unknown;
  } {
    // style might be defined directly on the field config -- might do a cloning so that header styling is not affected.
    const resultingStyle = { ...field.cssStyle };

    if (field.colorRanges && (getChained(result, field.id) || getChained(result, field.id + 'Color'))) {
      let id = field.id;
      /*
       * When the backend sends the color of the field, it sends it with the following format:
       * {parameter_name}_color which becomes {parameterName}Color
       * If such parameter exists, we will use it to color the column
       */
      if (getChained(result, field.id + 'Color')) {
        id += 'Color';
      }
      const range = field.colorRanges.find(r => r.range[0] == getChained(result, id));
      if (range) {
        resultingStyle['color'] = range.color;
      }
    }

    // if conditional styles are specified on the column we will evaluate and apply
    if (field.conditionalCss) {
      for (const style of field.conditionalCss) {
        const conditionFunc = Function('value', 'return ' + style.condition);
        const conditionResult = conditionFunc(result);
        if (conditionResult) {
          for (const styleValue in style.css) {
            resultingStyle[styleValue] = style.css[styleValue];
          }
        }
      }
    }
    return resultingStyle;
  }
}
