import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass, NgFor, NgIf } from '@angular/common';

import dayjs from 'dayjs';

import { DrawioDiagramRequestParams, OptionValue } from '../../helpers/types';
import { PearlButtonComponent, PearlDatepickerComponent, PearlDatepickerInput, PearlDatepickerToggleComponent,
  PearlFormFieldComponent } from '../../shared/pearl-components';

export interface DrawioDiagramData {
  projects: OptionValue[];
  vessels: OptionValue[];
  documentTypes: OptionValue[];
  isForCompare: boolean;
}

@Component({
  selector: 'drawio-diagram-dialog',
  templateUrl: 'dpr-drawio-diagram-dialog.component.html',
  styleUrl: 'dpr-drawio-diagram-dialog.component.scss',
  standalone: true,
  imports: [
    MatDialogModule,
    NgClass,
    PearlFormFieldComponent,
    MatInput,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    NgFor,
    MatOptionModule,
    NgIf,
    MatInputModule,
    PearlButtonComponent,
    PearlDatepickerComponent,
    PearlDatepickerInput,
    PearlDatepickerToggleComponent,
  ],
})
export class DprDrawioDiagramDialogComponent {
  public selectedDate: dayjs.Dayjs;
  public selectedProjectId: number;
  public selectedVesselId: number;
  public selectedDocumentTypeId: number;
  public urlComparison: string;
  public confirmationMessage: string;

  constructor(
    public dialogRef: MatDialogRef<DprDrawioDiagramDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DrawioDiagramData,
  ) {
    this.selectedDate = dayjs();
    this.confirmationMessage = data.isForCompare ? 'Compare diagrams' : 'Create diagram';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  dateChange(newDate: dayjs.Dayjs) {
    this.selectedDate = newDate;
  }

  validateDisabled() {
    return !this.selectedDate || !this.selectedProjectId || !this.selectedVesselId
      || this.data.isForCompare && !this.urlComparison;
  }

  public confirmAndClose() {
    this.dialogRef.close({
      datetime: this.selectedDate.valueOf(),
      projectId: this.selectedProjectId,
      vesselId: this.selectedVesselId,
      documentTypeId: this.selectedDocumentTypeId,
      cloudUrl: this.urlComparison,
    } as DrawioDiagramRequestParams);
  }
}
