import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import tinycolor from 'tinycolor2';
import { interpolateHcl } from 'd3-interpolate';
import { pickBy } from 'lodash-es';

import { StandardLayerState } from '../dashboards/model/layer-state';
import { ColorHelper } from '../helpers/color-helper';
import { ColorByConfig, ColorByState, ColorDefinition, ColorState, LayerLegendState, LayerPaintStyle,
  LegendDashArrayDefinition } from '../helpers/legend-types';
import { RawDataPoint } from '../graph/chart-types';
import { Color, GeoDataPoint, LayerId, NumOrString } from '../helpers/types';
import { getChained } from '../data-loader/ref-data-provider';
import { LegendColor, SchedulePatterns } from '../schedule/schedule-types';

export const NOT_AVAILABLE = 'Not available';
const DEFAULT_STYLE: LayerPaintStyle = {
  fill: '#009688',
};

export class LegendHelper {
  public static defaultColors = ColorHelper.getColorScale();

  /**
   * Determines whether a colorBy legend should auto-update it's content when moving the map
   * By default discrete colorBys don't and continuous DO update
   */
  public static isLayerAutoUpdate(config: ColorByConfig): boolean {
    if (config.autoUpdate !== undefined && config.autoUpdate !== null) {
      return config.autoUpdate;
    }

    return config.continuous ?? false;
  }

  public static createColorBy(colorByConfig: ColorByConfig, layer: StandardLayerState): ColorByState {
    const colorByState: ColorByState = {
      title: colorByConfig.title,
      continuous: colorByConfig.continuous ?? false,
      value: colorByConfig.value,
      alwaysShowInLegend: colorByConfig.alwaysShowInLegend ?? false,
      autoUpdate: LegendHelper.isLayerAutoUpdate(colorByConfig),
    };

    if (colorByConfig.colorsRange) colorByState.colorsRange = colorByConfig.colorsRange;
    if (colorByConfig.opacityBy) colorByState.opacityBy = colorByConfig.opacityBy;

    if (colorByConfig.fixedColor) {
      colorByState.fixedColor = true;
      colorByState.colors = [
        LegendHelper.colorDefToState({ id: colorByConfig.title, fill: colorByConfig.fixedColor }),
      ];
    } else if (colorByConfig.colors?.length) {
      colorByState.colors = colorByConfig.colors?.map(colorDef => LegendHelper.colorDefToState(colorDef));
    }
    LegendHelper.updateColorBy(colorByState, layer.data);
    return colorByState;
  }

  public static colorDefToState(colorDef: ColorDefinition): ColorState {
    return {
      id: colorDef.id,
      visible: true,
      style: pickBy(colorDef, (_, key) => key !== 'id'),
    };
  }

  public static constructLegendState(layer: StandardLayerState): LayerLegendState {
    const legendConfig = layer.settings.legend;
    const layerId = layer.id;
    if (!legendConfig?.colorBys && !legendConfig?.style) return;
    const gradientId = 'gradient_' + layerId;
    const layerState: LayerLegendState = {
      id: layerId,
      title: layer.settings.title,
      show: true,
      total: layer.data.length,
      colorBys: [],
      globalStyle: legendConfig.style,
      visible: legendConfig.colorBys !== null,
      gradientId: 'gradient_' + layerId,
      gradientStyle: `url(#${gradientId})`,
      maxExportableNumber: legendConfig.maxExportableNumber,
      mapDash: legendConfig.mapDash,
      noExport: legendConfig.noExport,
      layer,
    };

    /*
     * if there are no colors bys, it means the layer doesn't have any specific coloring
     * and it is just colored with the global fixed style
     */
    if (!legendConfig.colorBys) return layerState;

    layerState.colorBys = legendConfig.colorBys.map(colorByConfig => {
      return LegendHelper.createColorBy(colorByConfig, layer);
    });
    layerState.currentColorBy = layerState.colorBys.find(d => d.value === legendConfig.default);
    return layerState;
  }

  public static getDiscreteColorId(item: RawDataPoint, colorBy: ColorByState): NumOrString {
    return getChained(item, colorBy.value) || NOT_AVAILABLE;
  }

  /**
   * Update map layer legend: will update the currentColorBy to take into account the new provided data
   * (change range for continuous, re-compute counts for discrete for instance)
   */
  public static updateLayerLegend(
    layer: StandardLayerState,
    data: readonly RawDataPoint[],
  ): void {
    const legendState = layer.legendState;
    if (!legendState) return;

    legendState.total = layer.shown.length;
    if (!legendState.currentColorBy) return;
    LegendHelper.updateColorBy(legendState.currentColorBy, data);
  }

  public static updateColorBy(colorBy: ColorByState, data: readonly RawDataPoint[]): void {
    if (colorBy?.opacityBy && data.length) {
      const domain = extent(data, (d: RawDataPoint) => getChained<number>(d, colorBy.opacityBy.value)) as Range;
      colorBy.opacityBy.opacityFunc = scaleLinear(domain, colorBy.opacityBy.range);
      colorBy.opacityBy.min = domain[0];
      colorBy.opacityBy.max = domain[1];
    }
    if (colorBy.continuous) {
      return LegendHelper.updateContinuousColorBy(data, colorBy);
    } /*
     * if colorBy is a fixed Color - it means that we want  all the items of this layer to be colored
     * in a single way
     */
    else if (colorBy.fixedColor) {
      return LegendHelper.updateFixedColorBy(colorBy, data);
    } // if the color by is not continuous nor fixed, it is a discrete set of colors
    else {
      return LegendHelper.updateDiscreteColorBy(data, colorBy);
    }
  }

  private static updateDiscreteColorBy(
    items: readonly RawDataPoint[],
    colorBy: ColorByState,
  ): void {
    /** Do not bother computing the number of colored items since this is for special cases (map tiles, camembert) */
    if (colorBy.alwaysShowInLegend) return;
    const valueCounts = {};
    for (const item of items) {
      const itemValue = LegendHelper.getDiscreteColorId(item, colorBy);
      valueCounts[itemValue] ? valueCounts[itemValue]++ : (valueCounts[itemValue] = 1);
    }
    if (!colorBy.colors) colorBy.colors = [];
    /*
     * we have to hide or show the colorBys that are already in the list
     * we will update the count as well
     */
    colorBy.colors.forEach(color => {
      const foundColorCount = valueCounts[color.id];
      if (foundColorCount) {
        color.count = foundColorCount;
        color.visible = true;
        delete valueCounts[color.id];
      } else {
        color.visible = false;
      }
    });

    /*
     * go over the rest of the colors not in the current color list and add them
     * for layers that load all data at once, the colors will be already defined just not shown
     * for layers loaded by pieces (vessel trace) we have to decide on the new color as well
     * for that we need to now the number of colors already in the list so that we can pick
     * new colors for new added items (eg. vessel traces)
     */
    let counter = colorBy.colors.length;
    for (const color in valueCounts) {
      if (!colorBy.colors.some(d => d.id === color)) {
        const colorDef: ColorDefinition = {
          id: color,
          fill: this.defaultColors[counter++ % this.defaultColors.length],
        };
        const colorState = LegendHelper.colorDefToState(colorDef);
        colorState.count = valueCounts[color];
        colorBy.colors.push(colorState);
      }
    }
  }

  private static updateFixedColorBy(
    colorBy: ColorByState,
    items: readonly RawDataPoint[],
  ): void {
    const fixedColor = colorBy.colors[0];
    fixedColor.count = items.length;
    fixedColor.visible = true;
  }

  public static updateContinuousColorBy(
    items: readonly RawDataPoint[],
    colorBy: ColorByState,
  ): void {
    if (!items.length) return;
    const itemExtent = extent(items, (d: RawDataPoint) => getChained(d, colorBy.value)) as Range;
    colorBy.coloringFunc = scaleLinear(itemExtent, colorBy.colorsRange).interpolate(interpolateHcl);
    colorBy.min = itemExtent[0];
    colorBy.max = itemExtent[1];
  }

  /**
   * Compute style of a single item, according to provided legend state
   */
  public static styleItem(
    item: GeoDataPoint,
    legendState: LayerLegendState | ColorByState,
  ): LayerPaintStyle {
    let colorBy: ColorByState;
    let mapDash: LegendDashArrayDefinition;
    const style = { ...DEFAULT_STYLE };
    if (LegendHelper.isLegendState(legendState)) {
      colorBy = legendState?.currentColorBy;
      mapDash = legendState?.mapDash;
      if (legendState?.globalStyle) Object.assign(style, legendState.globalStyle);
    } else {
      colorBy = legendState;
    }

    if (colorBy?.opacityBy) {
      const value = getChained<number>(item, colorBy.opacityBy.value);
      if (value != null) style['opacity'] = colorBy.opacityBy?.opacityFunc(value);
    }

    /** Return a static color function, returning always the same style */
    if (!colorBy || colorBy.fixedColor) {
      if (colorBy?.colors) Object.assign(style, colorBy.colors[0].style);
      return LegendHelper.setAdditionalPaintProperties(style, item, mapDash);
    }

    if (colorBy.continuous) {
      const value = getChained<number>(item, colorBy.value);
      if (value == null) return style;
      const color = colorBy.coloringFunc(value);
      Object.assign(style, { fill: color, stroke: color });
      return LegendHelper.setAdditionalPaintProperties(style, item, mapDash);
    }

    /** Discrete case */
    const value = getChained(item, colorBy.value) || NOT_AVAILABLE;
    const definedColor = colorBy.colors?.find(colorState => colorState.id === value);
    return LegendHelper.setAdditionalPaintProperties(
      Object.assign(style, definedColor?.style ?? {}),
      item,
      mapDash,
    );
  }

  private static isLegendState(state: LayerLegendState | ColorByState): state is LayerLegendState {
    return 'globalStyle' in state;
  }

  /**
   * Will set additional paint properties on given item, according to its geometry type, for instance a "fill" attribute
   * is not useful on a line geometry
   */
  public static setAdditionalPaintProperties(
    style: LayerPaintStyle,
    dataPoint: GeoDataPoint,
    mapDash: LegendDashArrayDefinition,
  ): LayerPaintStyle {
    if (mapDash?.field && getChained(dataPoint, mapDash.field)) {
      style['stroke-dasharray'] = '8 4';
    }
    /** For lines, we want high luminosity on hover */
    if (dataPoint.geometry.type.includes('Line')) {
      if (style.fill) {
        if (!style.stroke || style.stroke === 'black') style.stroke = style.fill;
      }
      if (style.stroke) style['stroke-hover-color'] = tinycolor(style.stroke).lighten(50).toString() as Color;
    } /** For polygons, we want a slight lightening on hover, and big highlight on stroke */
    else if (dataPoint.geometry.type.includes('Polygon')) {
      const baseColor = style.fill ?? style.stroke;
      if (style.fill) style['fill-hover-color'] = tinycolor(style.fill).lighten(10).toString() as Color;
      if (baseColor) style['stroke-hover-color'] = tinycolor(baseColor).lighten(50).toString() as Color;
      if (!style.opacity && !style['fill-opacity']) style['fill-opacity'] = 0.5;
    }
    return style;
  }

  /**
   * Builds and return a new LayerLegendState based on provided patterns, colors and counts. This is used
   * for schedule, which simply build and pass it to the legend component.
   */
  public static createScheduleLegend(
    id: LayerId,
    title: string,
    patterns: SchedulePatterns,
    colors: LegendColor,
    counts: {
      colors: { [colorValue: string]: number };
      patterns: { [patternId: string]: number };
    },
  ): LayerLegendState {
    // Create unique ColorBy
    const colorBy: ColorByState = {
      title: title,
      value: id,
      continuous: false,
      alwaysShowInLegend: false,
      colors: Object.entries(colors).map(([id, color]) => ({
        id,
        visible: true,
        style: { fill: color },
        count: counts.colors[id] ?? 0,
      })),
      autoUpdate: false,
    };

    // Update patterns counts
    for (const style in patterns) {
      patterns[style].forEach(pattern => {
        pattern.count = counts.patterns[pattern.field] || 0;
      });
    }
    const gradientId = 'gradient_' + id;
    const newLayer: LayerLegendState = {
      id: id,
      title: title,
      show: true,
      total: 0,
      colorBys: [colorBy],
      currentColorBy: colorBy,
      globalStyle: undefined,
      visible: true,
      gradientId: gradientId,
      gradientStyle: `url(#${gradientId})`,
      patterns: patterns,
    };
    return newLayer;
  }
}
