<div class="comparison-select" *ngIf="componentSettings.comparisonConfig">
  <spin-filters-doubledate
    #comparisonComponent
    [field]="componentSettings.comparisonConfig"
    (onchange)="onComparisonPeriodChanged($event)"
    [disabled]="comparisonPeriodDisabled" />
  <div *ngIf="getComparedPeriods() as periods" class="period-str">
    <span> {{ intervalToString(periods[0]) }} </span>
    <div class="previous">prev. {{ intervalToString(periods[1]) }}</div>
  </div>
</div>
<div class="entity-table-wrapper">
  <div class="component-title" *ngIf="header">
    <h3>{{ header }}</h3>
    <description-button [description]="description" />
  </div>

  <div class="entity-table-wrapper-subtitle" *ngIf="subTitle">{{ subTitle }}</div>

  <div class="entity-table-wrapper-table-container">
    <mat-spinner [diameter]="100" *ngIf="isLoading" class="table-loading" />

    <entity-table
      [editMode]="editMode"
      [afterSaveAction]="afterEntitySave"
      [tableInteraction]="tableInteraction"
      [hideHeader]="componentSettings.hideHeader"
      [hideSearchbar]="componentSettings.hideSearchbar"
      [headerClass]="'noRightPadding'"
      [addNewEnable]="tableInteraction"
      [showDescriptionColumn]="componentSettings.showDescriptionColumn"
      [categories]="componentSettings.categories"
      [buttonList]="componentSettings.buttons"
      [headerButtonList]="componentSettings.headerButtons"
      [selectableRowPropId]="componentSettings.selectableRowPropId"
      [tableModes]="componentSettings.modes"
      (exportFunction)="emitOnExport($event)"
      [parentTitle]="componentSettings.title"
      (onaction)="onaction($event)"
      (openEntityRequest)="bubbleOpenEntityRequest($event)"
      (columnsFiltered)="onColumnsFiltered()"
      (onrowselected)="emitRowSelected($event)"
      (ontabchanged)="tableTabChanged($event)"
      (showDescriptionColumnChanged)="onShowDescriptionColumnChanged()"
      (onSelectionChange)="onselectionchange.emit($event)" />
  </div>
</div>
