<div [id]="componentSettings.id" class="spin-graph full-height plotly-barpolar">
  <div *ngIf="title && !opts.scheduleGraph" class="component-title">
    <h3>{{ title }}</h3>
    <description-button [description]="description"></description-button>
  </div>

  <spin-graph-options
    *ngIf="!opts.hideOptions"
    #chartOptions
    [graphId]="componentSettings.id"
    [opts]="opts"
    [noData]="noRawData"
    (showTable)="onTable()"
    (onchange)="onSelectChange($event.chartParam, $event.value)"
    (xls)="onXls()"
    (onintervalchange)="onintervalchange($event)"
    (png)="onpng($event)"
    [availableSelects]="availableSelects"
    [selects]="selects"
    [display]="display"
    [intervalField]="intervalField"
    [minX]="minX"
    [maxX]="maxX"
    [showTableButton]="datatable"
    [showBarModeControls]="showBarModeControls"
    [dynamicGroupByState]="dynamicGroupByState">
  </spin-graph-options>

  <div class="loading-indicator" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>

  <div
    *ngIf="!noRawData"
    #chartArea
    id="{{ plotlyChartId }}"
    class="chart-area"
    [ngClass]="{ 'chart-hidden': loading }">
    <mat-button-toggle-group
      *ngIf="showUnitsSwitch"
      [(ngModel)]="selectedUnit"
      class="bar-mode-toggle"
      style="position: absolute; top: 0">
      <mat-button-toggle *ngFor="let speed of possibleSpeeds" [value]="speed" (change)="onUnitChange($event.value)">
        {{ speed }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div *ngIf="noRawData" class="no-data-wrapper">
    <span>No data available</span>
  </div>
</div>

<chart-tooltip (onNavigateClick)="navigateToPage($event)"> </chart-tooltip>
