import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges,
  booleanAttribute } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appScrollIntoView]',
})
export class ScrollIntoViewDirective implements OnChanges {
  @Input()
  appScrollIntoView: boolean | undefined;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private elementRef: ElementRef,
  ) {}

  ngOnChanges(_: SimpleChanges): void {
    if (isPlatformBrowser(this.platformId)) {
      if (booleanAttribute(this.appScrollIntoView)) {
        (this.elementRef.nativeElement as HTMLInputElement).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }
}
