import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Injector, Input, OnDestroy,
  Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DecimalPipe, NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

import { Subscription, merge } from 'rxjs';

import { SchedulePatterns } from '../schedule/schedule-types';
import { ExportConfig } from '../helpers/types';
import { BasePaintStyle, ColorByState, LayerLegendState, LegendChangeEvent } from '../helpers/legend-types';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';
import { Config } from '../config/config';
import { PearlFormFieldComponent } from './pearl-components';

@Component({
  selector: 'spin-legend',
  templateUrl: 'legend.html',
  styleUrls: ['legend.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgStyle,
    MatSlideToggleModule,
    MatTooltipModule,
    PearlButtonComponent,
    PearlFormFieldComponent,
    MatSelectModule,
    MatOptionModule,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    DecimalPipe,
  ],
})
export class LegendComponent implements OnDestroy {
  @Output()
  onlegend = new EventEmitter<LegendChangeEvent>();
  @Output()
  onexport = new EventEmitter<ExportConfig>();

  @Input()
  labelsTooltip: string = '';
  @Input()
  showLabels: boolean = true;
  @Input()
  askedDisplayedLayers: string[];

  @Output()
  onToggleLabels = new EventEmitter<boolean>();

  @ViewChild('legendBody')
  public legendBody: ElementRef<HTMLInputElement>;

  public cdRef: ChangeDetectorRef;
  private legendChangeSubscription: Subscription;
  private _legends: LayerLegendState[];

  public config: Config = inject(Config);
  public showLegend: boolean = false;
  public isExportable: boolean = true;

  constructor(injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  @Input()
  set legends(newLegends: LayerLegendState[]) {
    if (newLegends) {
      if (this.legendChangeSubscription) this.legendChangeSubscription.unsubscribe();
      this.legendChangeSubscription = merge(
        ...newLegends.map(legend => legend.layer?._legendStateUpdate$).filter(l => l),
      ).subscribe(() => this.cdRef.detectChanges());
    }
    this._legends = newLegends;
  }

  get legends(): LayerLegendState[] {
    return this._legends;
  }

  public ngOnDestroy(): void {
    this.legendChangeSubscription?.unsubscribe();
  }

  /**
   * Get visible layers to display
   */
  public get visibleLegends(): LayerLegendState[] {
    return this.legends.filter(d =>
      d.id !== 'eta' && (Array.isArray(this.askedDisplayedLayers)
        ? d.visible && this.askedDisplayedLayers.includes(d.id)
        : d.visible)
    );
  }

  public colorByChanged(legendState: LayerLegendState, newColorBy: ColorByState): void {
    legendState.currentColorBy = newColorBy;
    legendState.layer.updateLegend();
    this.emitLegendChange(legendState);
  }

  public export(layer: LayerLegendState): void {
    this.onexport.emit({
      layerId: layer.id,
      filename: layer.id,
    });
  }

  /**
   * Show legend button on the sidebar
   *
   * @return {boolean}  True if at least one layer with legend
   */
  public hasLayers(): boolean {
    if (!this.legends) return false;
    return this.legends.filter(legendState => legendState?.show).length > 0;
  }

  public emitLegendChange(latest: LayerLegendState = null): void {
    const currentLayers = this.legends.map(d => ({
      layerId: d.id,
      colorBy: d.currentColorBy,
    }));
    this.onlegend.emit({
      pin: true,
      layers: currentLayers,
      latest: latest,
    });
  }

  /**
   * Check if layer has patterns
   */
  public layerHasPatterns(patterns: SchedulePatterns): boolean {
    return patterns && Object.keys(patterns).length > 0;
  }

  /**
   * Create and prepare a list of patterns from the SchedulePattern object.
   *
   * @param  {SchedulePatterns} patterns          Schedule patterns
   * @return {object[]}         preparedPatterns  { SVG url, title, count } pairs
   */
  public prepareLayerPatterns(
    patterns: SchedulePatterns,
  ): { url: string; title: string; fill: string; count: number; id: string }[] {
    const preparedPatterns: { url: string; title: string; fill: string; count: number; id: string }[] = [];
    Object.entries(patterns).forEach(([patternId, pattern]) => {
      const title = pattern.map(p => p.title).join(', ');
      const fill = pattern[0].fill;
      const count = pattern.reduce((v, p) => v + p.count, 0);
      preparedPatterns.push({
        url: `url(#${patternId}-pattern)`,
        title,
        fill,
        count,
        id: patternId,
      });
    });
    return preparedPatterns;
  }

  /**
   * A layer can have a maximum
   */
  public isLayerLegendInvalidForExport(layer: LayerLegendState): boolean {
    return layer.maxExportableNumber && layer.total && layer.total > layer.maxExportableNumber;
  }

  public isLayerExportable(layer: LayerLegendState): boolean {
    return this.isExportable && layer.total > 0;
  }

  protected getExportTooltipText(legendState: LayerLegendState): string {
    if (this.isLayerLegendInvalidForExport(legendState)) {
      return `You cannot export more than ${legendState.maxExportableNumber} entities. \
      Filter out some elements to enable XLSX download`;
    }
    return this.config.getXlsxDownloadTooltip(legendState.noExport);
  }

  public getGradient(continuousColorBy: ColorByState): string {
    return `linear-gradient(to right in hsl, ${continuousColorBy.colorsRange[0]}, ${continuousColorBy.colorsRange[1]})`;
  }

  /**
   * Toggle labels
   *
   * @param  {Event} $event  Toggle bubbling event
   * @emits  onToggleLabels<boolean>
   * @return {void}
   */
  public toggleLabels($event): void {
    this.showLabels = $event['checked'] as boolean;
    this.onToggleLabels.emit(this.showLabels);
  }

  public svgPaintAttributesToCss(svgAttributes: BasePaintStyle): object {
    const style = {
      'background': svgAttributes.fill ?? svgAttributes.stroke,
    };

    if (svgAttributes['stroke-width']) {
      const borderColor = svgAttributes.stroke === '#000000' ? svgAttributes?.fill : svgAttributes?.stroke;
      style['border'] = `1px solid ${borderColor}`;
    }

    return style;
  }
}
