<div class="coords">
  <ng-container [ngSwitch]="showCoords">
    <ng-container *ngSwitchCase="'DD'">
      <span>{{ coords[0] | number: "1.0-5" }}, {{ coords[1] | number: "1.0-5" }} </span>
      <pearl-button type="sticky" size="small" (click)="switchToDDM()">Switch to DDM</pearl-button>
    </ng-container>

    <ng-container *ngSwitchCase="'DDM'">
      <span> {{ ddmCoordinates[0] }}, {{ ddmCoordinates[1] }} </span>
      <pearl-button type="sticky" size="small" (click)="switchToDD()">Switch to DD</pearl-button>
    </ng-container>
  </ng-container>
</div>
