<pearl-form-field
  [small]="small()"
  *ngIf="field.preset"
  [matTooltip]="field.description"
  matTooltipPosition="after"
  [label]="field.title">
  <mat-select
    [(value)]="selectedEra"
    [disabled]="isDisabled()"
    (selectionChange)="selectEra($event.value, true, true)"
    (openedChange)="onOpened($event)"
    panelClass="doubledate-preset"
    [compareWith]="compareEras">
    <mat-option *ngFor="let era of eras" [value]="era" (focus)="presetCustom = false"> {{ era.title }} </mat-option>

    <!-- displayed title can only be changed by mat-option via value -> used when chosing custom dates -->
    <mat-option style="display: none" [value]="serializedEra"> {{ serializedEra.title }} </mat-option>

    <mat-option *ngIf="!field.onlyConfigOptions" [value]="completeEra"> {{ completeEra.title }} </mat-option>
    <!-- preset custom mode toggle button, note the click handler has to be on the div, not on mat-option because IE 11 prevent default-->
    <mat-option *ngIf="!presetCustom && !field.onlyConfigOptions" class="preset-custom-option">
      <div (click)="presetCustom = true" class="preset-custom-label">
        <pearl-icon icon="date_range" [size]="24" /> Custom...
      </div>
    </mat-option>

    <mat-option
      *ngIf="presetCustom && !field.onlyConfigOptions"
      (click)="$event.stopPropagation()"
      [appScrollIntoView]="presetCustom"
      class="full-dataset-option">
      <div class="datepickers-container">
        <pearl-form-field [label]="field.startLabel" [hasLabel]="!!field.startLabel" [small]="true">
          <input
            matInput
            [value]="start"
            [pearlDatepicker]="startDatepicker"
            [minDate]="min"
            [maxDate]="end"
            (dateChange)="selectStartDate($event.value)"
            (click)="$event.stopPropagation()" />
          <ng-container pearlFormSuffix>
            <pearl-datepicker-toggle size="small" [for]="startDatepicker"></pearl-datepicker-toggle>
            <pearl-datepicker #startDatepicker [precision]="granularity"></pearl-datepicker>
          </ng-container>
        </pearl-form-field>

        <pearl-form-field [small]="true" [label]="field.endLabel" [hasLabel]="!!field.endLabel">
          <input
            matInput
            [value]="end"
            [pearlDatepicker]="endDatepicker"
            [minDate]="start"
            [maxDate]="max"
            (dateChange)="selectEndDate($event.value)"
            (click)="$event.stopPropagation()" />
          <ng-container pearlFormSuffix>
            <pearl-datepicker-toggle size="small" [for]="endDatepicker"></pearl-datepicker-toggle>
            <pearl-datepicker #endDatepicker [precision]="granularity" endOfPeriod></pearl-datepicker>
          </ng-container>
        </pearl-form-field>

        <pearl-button
          color="primary"
          size="small"
          title="Apply custom"
          type="secondary"
          icon="checked"
          (click)="validateCustom()"
          >Apply custom</pearl-button
        >
      </div>
    </mat-option>
  </mat-select>
</pearl-form-field>

<div *ngIf="!field.preset" [matTooltip]="field.description" matTooltipPosition="after">{{ field.title }}</div>

<div *ngIf="!field.preset" class="spin-filters-doubledate mat-interval">
  <div class="datepickers-container">
    <pearl-form-field [small]="true" [label]="field.startLabel">
      <input
        matInput
        [value]="start"
        [pearlDatepicker]="startDatepicker"
        [maxDate]="end"
        (dateChange)="selectStartDate($event.value, true)"
        (click)="$event.stopPropagation()" />
      <pearl-datepicker-toggle pearlFormSuffix size="small" [for]="startDatepicker" />
      <pearl-datepicker #startDatepicker [precision]="granularity" />
    </pearl-form-field>

    <pearl-form-field [label]="field.endLabel" [small]="true">
      <input
        matInput
        [value]="end"
        [pearlDatepicker]="endDatepicker"
        [minDate]="start"
        (dateChange)="selectEndDate($event.value, true)"
        (click)="$event.stopPropagation()" />
      <pearl-datepicker-toggle pearlFormSuffix size="small" [for]="endDatepicker" />
      <pearl-datepicker #endDatepicker [precision]="granularity" endOfPeriod />
    </pearl-form-field>
    <pearl-button class="datepicker-reset-button" type="alternative" (click)="resetFields()" icon="cancel" size="small"
      >Reset</pearl-button
    >
  </div>
</div>
