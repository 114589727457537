import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, forwardRef } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgFor, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';

import { cloneDeep } from 'lodash-es';

import { DataLoader } from '../data-loader/data-loader';
import { AfterSave, EntityDefinition, EntityFieldDefinition, IntegratedTableDefinition, LinkedTableQuery,
  RowDefinition as Row, SomeEntity } from '../helpers/types';
import { EntityDataAccessor } from './entity-data-accessor';
import { IEntityDetailFieldParent } from './entity-detail-field-parent';
import { EntityDetailFieldComponent } from './entity-detail-field';
import { PearlButtonComponent } from '../shared/pearl-components';

@Component({
  selector: 'entity-detail-table',
  templateUrl: './entity-detail-table.html',
  styleUrls: ['entity-detail-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    forwardRef(() => EntityDetailFieldComponent),
    PearlButtonComponent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
  ],
})
export class EntityDetailTableComponent implements IEntityDetailFieldParent {
  @Input()
  public parentField: IntegratedTableDefinition;
  @Input()
  public parentAccessor: EntityDataAccessor;
  @Input()
  public parentEntity: SomeEntity;
  @Input()
  public parentEntityDefinition: EntityDefinition;
  @Input()
  public parent: IEntityDetailFieldParent;
  public coherencyErrors: string[] = [];
  private dataLoader: DataLoader;
  private cdRef: ChangeDetectorRef;
  public editMode: boolean = true;
  public fullyLoaded: boolean = false;
  public afterClose: any;
  public MappedBy: string;
  public prefill?: any;
  public isNew: boolean;

  constructor(private injector: Injector) {
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  /** Getters */

  public get definition(): EntityDefinition {
    return this.parentField.definition;
  }
  public get title(): string {
    return this.parentField.title;
  }
  public get rows(): Row[] {
    return this.parentField.rows;
  }
  public get deletedRows(): Row[] {
    return this.parentField.deletedRows;
  }
  public get linkedEntities(): SomeEntity[] {
    return this.rows.map(row => row.entityAccessor.entity);
  }
  public get parentId() {
    return this.parentEntity[this.parentEntityDefinition.idField];
  }

  /** Load definition at first instantiation. It is stored at parent level, so it is not lost when changing tab. */
  private async loadDefinition() {
    const definition = await this.dataLoader.getEntityDefinition(this.parentField.class, false);
    // Add custom fields if any. Unless they are specified row false, they are shown
    definition.freeStyleFields?.forEach(f => f.row ??= true);
    definition.fields.push(...definition.freeStyleFields);
    Object.assign(this.parentField, { definition, rows: [], deletedRows: [] });
  }

  /** Load rows. Rows are stored at parent level so there are not lost when changing tab. */
  private async loadRowsValues() {
    await this.getValues();

    if (!this.rows.length) {
      this.addEmptyRow();
    }
  }

  public async loadDefinitionAndValues() {
    await this.loadDefinition();
    await this.loadRowsValues();
    this.afterUpdate();
  }

  public afterUpdate() {
    if (!this.rows) {
      return;
    }

    this.fullyLoaded = true;
    this.cdRef.detectChanges();
    this.rows.forEach(row => row.fields.forEach(field => row.entityAccessor.afterUpdate(field.id)));
  }

  public checkCoherency(): number[] {
    this.coherencyErrors = [];
    const badRowIndexes: number[] = [];
    this.rows.forEach((row, i) => {
      const rowCoherencyErrors = row.entityAccessor.getFieldsErrors(this.parentField?.mappedBy, false);
      if (rowCoherencyErrors.length) {
        this.coherencyErrors.push(...rowCoherencyErrors);
        badRowIndexes.push(i);
      }
    });
    return badRowIndexes;
  }

  public showField(field: EntityFieldDefinition): boolean {
    return field.editable && field.id != this.parentField.mappedBy && field.row;
  }

  public async getValues() {
    if (!this.parentEntity[this.parentField.id]) {
      return;
    }

    const query: LinkedTableQuery = {
      linkedEntity: this.parentField.class,
      parentEntity: this.parentEntityDefinition.class,
      linkedCollection: this.parentField.id,
      forOptions: false,
      idsSearched: this.parentEntity[this.parentField.id] as number[],
    };
    this.fillTable(await this.dataLoader.getPossibleValues(query));

    /*
     * When importing data from an other component, the table rows are stored in a "__to_contruct_for..." entry
     * This add the rows corresponding to this data
     */
    this.fillTable(this.parentEntity['__to_construct_for_' + this.parentField.id]);
  }

  public cancelModifications() {
    this.rows.length = 0; // empty rows without reassigning
    this.deletedRows.length = 0;
    this.fillTable(this.linkedEntities);
  }

  private fillTable(entities: SomeEntity[]) {
    entities?.forEach(entity => this.addRow(entity));
  }

  public addRow(entity: SomeEntity) {
    const entityAccessor = new EntityDataAccessor(this.injector);
    entityAccessor.setDefinition(this.definition);
    (entity ??= {})[this.parentField.mappedBy] = this.parentId;
    entityAccessor.setEntity(this.definition.class, entity);

    this.rows.push({ entityAccessor: entityAccessor, fields: this.definition.fields });
  }

  public deleteRow(row: Row) {
    this.parentAccessor.deleteLinkedEntity(this.parentField.id, row.entityAccessor.entity.id);
    if (row.entityAccessor.entity.id) {
      this.deletedRows.push(row);
    }
    this.rows.splice(this.rows.indexOf(row), 1);
    if (!this.rows.length) {
      this.addEmptyRow();
    }
  }

  public addEmptyRow() {
    this.addRow(this.definition.emptyEntity);
  }

  public duplicateRow(row: Row) {
    const newEntity: SomeEntity = {};
    this.definition.fields.forEach(field => {
      if (field.row) {
        newEntity[field.id] = cloneDeep(row.entityAccessor.entity[field.id]);
      }
    });
    this.addRow(newEntity);
  }

  getEntity() {
    return this.parentEntity;
  }

  pushAfterSave(afterSave: AfterSave) {
    this.parent.pushAfterSave(afterSave);
  }
  saveEntity() {
    this.parentAccessor.saveEntity();
  }

  public isGroupDisabled(groupNames?: string[]): boolean {
    return false;
  }
}
