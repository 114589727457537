import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';

import { ProductAnalyticsService } from './product-analytics.service';
import { TrackedActionType } from './product-analytics.types';

@Directive({
  selector: '[analyticsAction]',
  standalone: true,
})
export class ProductAnalyticsDirective implements OnInit {
  @Input()
  analyticsAction: { title: TrackedActionType; trackedValues: unknown };

  constructor(
    private analyticsService: ProductAnalyticsService,
    private elementRef: ElementRef,
    private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    /** Add event listener on click outside Angular zone to prevent from performance issues due to change detection */
    this.ngZone.runOutsideAngular(() => {
      this.trackActionOnClick();
    });
  }

  private trackActionOnClick(): void {
    this.elementRef.nativeElement.addEventListener('click', () => {
      this.analyticsService.trackAction(this.analyticsAction.title, this.analyticsAction.trackedValues);
    });
  }
}
