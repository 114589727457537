import { ChangeDetectionStrategy, Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';

import { SpinTooltipDirective } from './directives/spin-tooltip.directive';
import { SimpleTooltipComponent } from './simple-tooltip';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';

@Component({
  selector: 'description-button',
  templateUrl: 'description-button.html',
  styleUrls: ['description-button.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PearlButtonComponent,
    SpinTooltipDirective,
  ],
})
export class DescriptionButtonComponent {
  @Input()
  description: string;

  @Input()
  skipMarkdown: boolean = false;

  @Input()
  iconFontSizePx: number = 24; // This is the font-size of the mat-icon.

  @ViewChild(SimpleTooltipComponent)
  $tooltip: SimpleTooltipComponent;

  public get content(): string {
    /*
     * For now we just format QC description as a standard paragraph.
     * It's needed because QC description strings are formatting using python """ """ which keeps the indent spaces at
     * every line
     * If we don't remove this blank spaces, it is considered as code block by markdown engine
     * It prevents data team to use indents to format markdown but they don't use it for now.
     * NOT Supported by IE 11
     */
    if (this.skipMarkdown) {
      return '<p>' + this.description + '</p>';
    }

    return this.description;
  }
}
