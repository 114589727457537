import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, NgZone, Output, ViewChild, ViewEncapsulation,
  inject } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgFor, NgIf } from '@angular/common';

import { DataLoader } from '../data-loader/data-loader';
import { ChartWrapperComponent } from './chart-wrapper';
import { ActionEvent, ChartModalConfig, FiltersState, Interval, LinkButton } from '../helpers/types';
import { isValidRecentInterval } from '../helpers/data-helpers';
import { PageStateService } from '../shared/services/page-state.service';
import { PearlButtonComponent, PearlButtonLinkComponent } from '../shared/pearl-components';
import { NavigationHelper } from '../helpers/navigation-helper';

export interface ChartModalConfigAndState {
  component: ChartModalConfig;
  modalComponentState: FiltersState;
}

@Component({
  templateUrl: 'chart-modal.html',
  styleUrls: ['chart-modal.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    MatDialogModule,
    ChartWrapperComponent,
    PearlButtonLinkComponent,
    PearlButtonComponent,
  ],
})
export class ChartModalComponent implements AfterViewInit {
  private readonly elRef = inject(ElementRef);
  private readonly ngZone = inject(NgZone);
  private chartRef: HTMLElement;
  private tableRef: HTMLElement;
  private readonly pageStateService = inject(PageStateService);
  public readonly dataLoader = inject(DataLoader);
  public tableShown: boolean = false;
  public externalData: any;

  @Output()
  onclick = new EventEmitter<ActionEvent>();
  @Output()
  onaction = new EventEmitter<ActionEvent>();
  @ViewChild(ChartWrapperComponent)
  private $chart: ChartWrapperComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public config: ChartModalConfigAndState,
    private dialogRef: MatDialogRef<ChartModalComponent>,
  ) {
    config.component.id = 'modal-chart';
  }

  public forwardAction = (event: ActionEvent): void => {
    this.onaction.emit(event);
  };

  ngAfterViewInit(): void {
    // We must wait that the entity-table-component has correctly set his definition
    setTimeout(() => {
      this.$chart.ownFiltersState = this.config.modalComponentState;
      this.$chart.modalComponentState = this.config.modalComponentState;
      if (this.$chart.chartSettings?.intervalField) {
        const masterPeriod = this.pageStateService.getMasterFilterValue() as Interval;
        const initialInterval = isValidRecentInterval(masterPeriod) ? { extent: masterPeriod } : undefined;
        this.$chart.setComponentInitialInterval(initialInterval);
      }
      if (this.$chart.chartSettings) {
        // Used for templating in chart titles
        this.$chart.chartSettings.valueBag = this.config.modalComponentState;
      }
      this.$chart.updateComponentCalcConfig();
      this.$chart.refresh();
    }, 100);
    this.chartRef = this.elRef.nativeElement.querySelector('.spin-graph');
    this.tableRef = this.elRef.nativeElement.querySelector('.chart-table');

    // If chart table exists, hide it (crane charts don't)
    if (this.tableRef) {
      this.tableRef.style.display = 'none';
    }
  }

  public showTable(): void {
    if (this.tableRef) {
      this.tableRef.style.display = 'block';
      this.tableShown = true;
    }
  }

  /**
   * When in table mode, put the chart back and hide the table
   */
  public goBackToChart(): void {
    // Put the chart back
    this.chartRef.style.display = 'block';

    // Hide the table
    if (this.tableRef) {
      this.tableRef.style.display = 'none';
      this.tableShown = false;
    }
  }

  public close(): void {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  public onButtonClicked(event: MouseEvent, button: LinkButton): void {
    /** Prevent default <a> tag behavior */
    event.preventDefault();
    const actionEvent = NavigationHelper.constructActionEvent({
      event,
      action: button,
      values: this.config.modalComponentState,
    });

    this.forwardAction(actionEvent);
  }

  public getConfigFilterPath(): string {
    return `page/${this.pageStateService.pageType()}`;
  }
}
