import { Injectable, inject } from '@angular/core';

import { Buffer, RichText, Workbook, Worksheet } from 'exceljs';
import { flatMap } from 'lodash-es';
import * as saveSvgAsPng from 'save-svg-as-png';

import { DatabaseHelper } from '../database/database-helper';
import { DataHelpers, getChainedPropForNonFilterField, getNumberPrecisionFromFormat } from './data-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { DateHelper } from './date-helper';
import { NavigationHelper } from './navigation-helper';
import { ColumnOption, CompleteExport, EntityDefinition, EntityFieldDefinition, ExportConfig, ExportData,
  ExportTrackingInfo, FieldSettings, FiltersState, PngExport, SomeEntityChainable, SpinTimeUnit,
  TooltipSettings } from './types';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { TimezoneService } from '../helpers/timezone.service';
import { Config } from '../config/config';
import { RawDataPoint } from '../graph/chart-types';
import { getChained } from '../data-loader/ref-data-provider';

@Injectable()
export class BrowserHelper {
  private dateRegex = RegExp(
    '^(([0-9])|([0-2][0-9])|([3][0-1]))(/|-)(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)(/|-)[0-9]{4}$',
  );

  private readonly timezoneService: TimezoneService = inject(TimezoneService);
  private config = inject(Config);

  constructor(
    private dataLoader: DataLoader,
    private productAnalyticsService: ProductAnalyticsService,
  ) {
  }

  public async exportXls(data: ExportData, filename: string): Promise<void> {
    if (this.config.trialModeDownloadsDisabled) {
      console.error('User in trial mode tried to export without having the rights to.');
      return;
    }

    const workbook = await this.generateExcel(data.data, data.header, data.columnOpts);
    this.downloadXls(workbook, filename);
    this.productAnalyticsService.trackAction('xlsxDownloaded', data.trackingInfo);
  }

  public downloadXls(workSheet: Buffer, exportFileName: string): void {
    const link = document.createElement('a');

    const blobUrl = URL.createObjectURL(
      new Blob([workSheet], { type: 'application/octet-stream;charset=utf-8;' }),
    );

    link.target = '_blank';
    link.href = blobUrl;
    link.download = `${exportFileName}.xlsx`;
    link.click();
  }

  private applyColOpt(worksheet: Worksheet, data: string[][], colCount: number, columnOptions: ColumnOption[]): void {
    for (let i = 0; i < colCount; i++) {
      const column = worksheet.getColumn(i + 1);
      column.width = 20;
      if ('col' in columnOptions[i]) columnOptions[i]?.col(column);
      /*
       * If column option have htmlRichText to true, we need to format html column content bold <b> to
       * Excel rich text
       */
      if (columnOptions[i].htmlRichText) {
        for (let dataRow = 0; dataRow < data.length; dataRow++) {
          const richText: RichText[] = BrowserHelper.parseHtmlTextForSpreadsheet(data[dataRow][i]);
          const cell = worksheet.getRow(dataRow + 2).getCell(i + 1);
          cell.value = {
            richText,
          };
        }
      }
    }
  }

  /*
   * We want to remove/handle most common html tags we'll find in this kind of cell:
   * - <li>: replace opening tag with a bullet point, replace the closing tag with a space
   * - <span>: nothing is done with with tag, so we just need to remove them from the string. Using a regexp
   *           to handle both `<span>` and `<span class="...">`
   */
  private static parseHtmlTextForSpreadsheet(htmlText: string): RichText[] {
    const parsedText = htmlText.replaceAll('<li>', '- ').replaceAll('</li>', ' ')
      .replaceAll(/<(?:span[^<>]*)>/g, '').replaceAll(/<\/(?:span)>/g, '');
    const textArray = parsedText.split(/(<b>)|(<\/b>)/).filter(s => s);
    const richText: RichText[] = [];
    for (let j = 0; j < textArray.length; j++) {
      if (textArray[j] === '<b>' || textArray[j] === '</b>') {
        continue;
      }
      if (j > 0 && textArray[j - 1] === '<b>') {
        richText.push({ text: textArray[j], font: { bold: true } });
      } else {
        richText.push({ text: textArray[j] });
      }
    }
    return richText;
  }

  private applyStyles(worksheet: Worksheet, maxLength): void {
    const firstRow = worksheet.getRow(1);
    firstRow.eachCell((cell, _) => {
      cell.style.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '00014D79' },
      };
      cell.style.font = {
        bold: true,
        name: 'Calibri',
        color: { argb: '00FFFFFF' },
      };
    });
    worksheet.getRow(1).height = 20;
    worksheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    worksheet.getCell('A' + (maxLength + 1).toString()).style.font = { underline: true };

    worksheet.getCell('A' + (maxLength + 2).toString()).style.alignment = { wrapText: false };
  }

  public async addSpinergieLogoAndConfidential(
    workbook: Workbook,
    worksheet: Worksheet,
    maxLength: number,
  ): Promise<void> {
    const b64img = await this.dataLoader.getText('/assets/img/b64-spinergie-logo-confidential.txt');
    const image = workbook.addImage({
      base64: b64img,
      extension: 'png',
    });
    worksheet.addImage(image, {
      tl: { col: 0, row: maxLength + 3 },
      ext: { width: 161.5, height: 36.5 },
      hyperlinks: {
        hyperlink: 'https://www.spinergie.com/',
        tooltip: 'https://www.spinergie.com/',
      },
    });
  }

  private async generateExcel(data: string[][], header: string[], columnOptions: ColumnOption[]): Promise<Buffer> {
    const ExcelJS = await import('exceljs').then(m => m.default);
    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getRow(1).values = header;
    for (let i = 0; i < data.length; i++) {
      worksheet.getRow(i + 2).values = data[i];
    }

    /**
     * Let a blank row between data and footer
     */
    const rowCount = worksheet.rowCount + 1;

    worksheet.getCell('A' + (rowCount + 1)).value = {
      text: 'Link to the solution',
      hyperlink: window.location.href,
    };
    worksheet.getCell('A' + (rowCount + 2)).value = 'Exported on ' + (new Date()).toString();
    if (columnOptions) {
      this.applyColOpt(worksheet, data, header.length, columnOptions);
    }
    this.applyStyles(worksheet, rowCount);
    await this.addSpinergieLogoAndConfidential(workbook, worksheet, rowCount);
    return workbook.xlsx.writeBuffer();
  }

  private getHeaderRow(columns: FieldSettings[]): string[] {
    const headerRow = [];
    columns.map(column => {
      const title = column.exportTitle ?? column.title ?? column.description;
      const suffix = column.suffix ? ` (${column.suffix})` : '';
      const cell = title + suffix;
      headerRow.push(cell);
    });
    return headerRow;
  }

  public prepareExport(
    completeExport: CompleteExport,
    transpose = false,
  ): {
    data: string[][];
    fileName: string;
    header: string[];
    columnOpts: ColumnOption[];
    trackingInfo: ExportTrackingInfo;
  } {
    const config = completeExport.config;

    const exportColumns = BrowserHelper.extractColumns(config);

    const data = this.formatExportData(completeExport, exportColumns);

    const header = this.getHeaderRow(exportColumns);

    const columnsOpts = this.getColumnExportOptions(exportColumns);

    if (transpose) {
      data.unshift(header);
      const transposed = BrowserHelper.transposeArray(data);

      const newHeader = ['Specification', ...completeExport.exportData.data.map(v => v.vessel)];
      columnsOpts['transpose'] = true;

      return {
        data: transposed,
        fileName: completeExport.config.filename,
        header: newHeader,
        columnOpts: columnsOpts,
        trackingInfo: completeExport.exportData.trackingInfo,
      };
    }

    return {
      data: data,
      fileName: completeExport.config.filename,
      header: header,
      columnOpts: columnsOpts,
      trackingInfo: completeExport.exportData.trackingInfo,
    };
  }

  private static transposeArray = (array: [][]) => array.reduce((r, a) => a.map((v, i) => [...(r[i] || []), v]), []);

  private getExportEntityDefinition(completeExport: CompleteExport) {
    let entityDefinition = completeExport.config.definition;
    if (!entityDefinition && completeExport.config.tooltip) {
      entityDefinition = this.getDefinitionFromTooltip(completeExport.config.tooltip) as any;
    }

    if (!entityDefinition && completeExport.config.columns) {
      entityDefinition = {
        fields: completeExport.config.columns,
      } as EntityDefinition;
    }
    return entityDefinition;
  }

  private formatExportData(completeExport: CompleteExport, columns: FieldSettings[]): any[] {
    let flatData: SomeEntityChainable[] = completeExport.exportData.data as SomeEntityChainable[];
    const entityDefinition = this.getExportEntityDefinition(completeExport);

    if (completeExport.exportData.data.length && completeExport.exportData.data[0]) {
      flatData = completeExport.exportData.data.map(d => d);
    }

    const finalData: string[][] = [];

    // for entity table export, we use the same formatting functions
    if (entityDefinition) {
      for (const entity of flatData) {
        const entityRow = {};
        for (const field of entityDefinition.fields) {
          entityRow[field.id] = this.formatCellValue(field, entity);
        }
        /*
         * If field definition is create from tooltip
         * some export column might not be present (e.g. title in schedule is not a tooltip field)
         * In this case if the entity has a value for this column
         * we add it without format
         */
        const _row: string[] = [];
        for (const column of columns) {
          const entityValue = getChainedPropForNonFilterField(entity, column);
          if (!(column.id in entityRow) && entityValue) {
            entityRow[column.id] = entityValue;
          }
          _row.push(entityRow[column.id]);
        }
        finalData.push(_row);
      }
    } else {
      flatData.forEach(row => {
        const _row: string[] = [];
        for (const column of columns) {
          // legend for example has no entity definition, but it has fieldsettings
          const rowValue = getChainedPropForNonFilterField<string>(row, column);
          if (['datetime', 'date'].includes(column.type)) {
            const cell = this.formatDate(rowValue ? parseFloat(rowValue) : undefined, column);
            _row.push(cell);
          } else {
            _row.push(rowValue);
          }
        }
        finalData.push(_row);
      });
    }

    return finalData;
  }

  private formatCellValue(field: EntityFieldDefinition, entity: SomeEntityChainable): string | null {
    let value: any = null;
    const entityValue = getChainedPropForNonFilterField<string>(entity, field);

    if (field.colorRanges) {
      const range = field.colorRanges.find(r => r.range[0] === entityValue);
      if (range?.exportValue) {
        return range.exportValue;
      }
    }

    if (['date', 'datetime'].includes(field.type)) {
      value = this.formatDate(entityValue ? parseFloat(entityValue) : undefined, field);
    } else if (field.type === 'number') {
      value = entityValue;
    } else if (field.type === 'duration') {
      const rawValue: number = parseFloat(entityValue);
      const appliedUnit: SpinTimeUnit = field.durationUnit ? field.durationUnit : 'millisecond';
      const momentDuration = DataHelpers.getDayjsDuration(rawValue, appliedUnit);

      /*
       * For a duration cell, excel need a value in day. We will format later
       * as a duration with wanted format (hh:mm)
       */
      value = momentDuration.asDays();
    } else {
      value = DatabaseHelper.formatFieldValue(
        field,
        entity,
        null,
        true,
      );
    }

    // Casting null/NaN values to empty string because Excel doesn't handle these properly
    if (value == null || (typeof value === 'object' && !value.enabled) || Number.isNaN(value)) {
      value = '';
    }

    return value;
  }

  private formatDate(value: number | null | undefined, field: FieldSettings): string {
    // note: 0 is a valid value as a timestamp
    if (value === null || value === undefined) {
      return '';
    }

    // If we try to use date format we have to make sure that the value emitted here is what excel expects
    const formatIsValid = DateHelper.excelFormats.includes(field.format);

    // we use a regex to detect strings that have the shape of a date. In this case we format this string as a date
    if (field.type === 'datetime' && !this.dateRegex.test(value.toString())) {
      return DateHelper.formatDatetime(
        value,
        formatIsValid ? field.format : 'YYYY-MM-DD HH:mm',
        this.timezoneService.timezone,
      );
    }
    return DateHelper.formatDate(value, formatIsValid ? field.format : 'YYYY-MM-DD');
  }

  /**
   * Create a field definition from tooltip to be able to format
   * data when export from a dashboard without entity definition (from a schedule for exemple)
   */
  public getDefinitionFromTooltip(tooltip: TooltipSettings) {
    const fieldDefinition = flatMap(tooltip.tabFieldsets, fieldset => fieldset.fields);
    return {
      fields: fieldDefinition,
    };
  }

  /**
   * Updated version of the ExcelJS function.
   * columnOptions is now index-based
   * XLSX is just a big XML. Each cell has multiple parameters that
   * can be added the list of parameters is available here:
   * https://github.com/SheetJS/sheetjs/blob/master/docbits/51_cell.md
   * Note about **Date** handling:
   * We have used to set the type of the column to 'd' for Dates. We do not do it any more,
   * please read time-hadling.md
   */
  public getColumnExportOptions(columns: FieldSettings[]): ColumnOption[] {
    const columnOptions: ColumnOption[] = [];
    for (let i = 0; i < columns.length; i++) {
      const columnOption = {} as ColumnOption;
      const column = columns[i];
      // format cell for duration
      if (column.type === 'duration') {
        columnOption.col = col => col.numFmt = '[h]:mm';
      }
      if (column.id === 'hiddenInfo' || column.type === 'html') {
        columnOption.htmlRichText = true;
      } else if (column.type === 'number' && column.format) {
        const numberPrecision = getNumberPrecisionFromFormat(column.format);
        let fmt = '0.';
        for (let i = 0; i < numberPrecision; i++) {
          fmt = `${fmt}0`;
        }
        // Remove point if precision is at integer
        if (fmt === '0.') {
          fmt = '0';
        }
        columnOption.col = col => col.numFmt = fmt;
      }
      columnOptions.push(columnOption);
    }

    return columnOptions;
  }

  /**
   * Extracts columns that should be exported from the export config.
   * Export config is different and it depends on the exported components.
   * - DataEntry table table export will use the entity definition
   * - Other tables will use the "query" object
   * - Most other components will use the tooltip definition
   */
  public static extractColumns(config: ExportConfig): Array<any> {
    let columns = [];
    if (config.layer) {
      columns = config.layer
        .map(fieldset => fieldset.fields)
        .reduce((acc, fields) => acc.concat(fields), []);
    } else if (config.columns) {
      columns = config.columns.map(c => c);
    } else if (config.tooltip) {
      if (!config.tooltip.tabFieldsets) {
        return [];
      }

      columns = config.tooltip.tabFieldsets
        // keep tab name
        .map(fieldset => {
          fieldset.fields.forEach(t => (t.fieldsetTitle = fieldset.title));
          return fieldset;
        })
        // flatten
        .map(fieldset => fieldset.fields)
        .reduce((acc, tabFieldsets) => acc.concat(tabFieldsets), [])
        // format
        .map(col => {
          return {
            id: col.id,
            title: col.fieldsetTitle + ' - ' + col.title,
            type: col.type,
            format: col.format,
            suffix: col.suffix,
            descriptionProperty: col.descriptionProperty ?? '',
          };
        });

      // first column : name
      columns = [
        {
          id: config.tooltip.title.name,
          title: 'Name',
          type: 'string',
        },
      ].concat(columns);
    } else if (config.definition) {
      columns = config.definition.fields;
    }
    const finalColumns = [];
    for (const field of columns) {
      finalColumns.push(field);
      if (field.descriptionProperty) {
        finalColumns.push({
          id: field.descriptionProperty,
          title: field.title + ' - Additional info',
        });
      }
    }
    return finalColumns;
  }

  public async exportSvgToPng(exportParameters: PngExport) {
    const element = document.getElementById(exportParameters.svgId);
    await this.exportSvgElementToPng(element, exportParameters);
  }

  public async exportSvgElementToPng(svg: HTMLElement, exportParameters: PngExport) {
    const options = {
      scale: exportParameters.scale,
      backgroundColor: '#FFFFFF',
      width: exportParameters.width,
      height: exportParameters.height,
    } as any;
    await saveSvgAsPng.saveSvgAsPng(svg, exportParameters.fileName, options);
    this.productAnalyticsService.trackAction('pngDownloaded', exportParameters.trackingInfo);
  }

  /**
   * In case the export's tooltip has an url, we need to request additional data to fill the export
   */
  public static async injectDataForExport(
    exportConfig: CompleteExport,
    dataLoader: DataLoader,
    layerFilters: FiltersState,
  ): Promise<void> {
    const tooltipConfig = exportConfig.config.tooltip;
    if (tooltipConfig?.url) {
      let url = `${tooltipConfig.url.split('?')[0]}?calledForDownload=1`;
      const data = exportConfig.exportData.data.sort(function(a, b) {
        return a.vesselId - b.vesselId;
      });
      let remoteData: readonly RawDataPoint[];
      // If the config contains download parameters, we will add them to the url and perform a GET query
      if (tooltipConfig.downloadParams) {
        Object.keys(tooltipConfig.downloadParams).forEach(param => {
          let value = tooltipConfig.downloadParams[param];
          if (value.charAt(0) == ':') {
            value = NavigationHelper.substituteProperty(value, layerFilters);
            if (Array.isArray(value)) {
              value = value.join(',');
            }
          }
          url = `${url}&${param}=${value}`;
        });
        remoteData = await dataLoader.get<RawDataPoint[]>(url);
      } // Otherwise we perform a POST query using all the ids of the data
      else {
        const body = {
          id: data.map(({ id }) => id),
          vesselId: data.map(({ vesselId }) => vesselId), // Vessel may be necessary
        };
        remoteData = await dataLoader.post<any, RawDataPoint[]>(url, body);
      }
      const toExport = data.map(d => {
        const remoteItem = remoteData.find(item => getChained(item, 'id') === d.id);
        return { ...d, ...remoteItem };
      });
      exportConfig.exportData.data = toExport;
    }
  }
}
