/**
 * ordering.ts
 *
 * Helper for ordering functions
 */

export class Ordering {
  public static fixedOrder(value1: string, value2: string, fixedOrder: string[]) {
    const indexValue1 = fixedOrder.indexOf(value1);
    const indexValue2 = fixedOrder.indexOf(value2);

    if (indexValue1 === -1 && indexValue2 >= 0) {
      return 1;
    }
    if (indexValue1 >= 0 && indexValue2 === -1) {
      return -1;
    }
    return indexValue1 > indexValue2 ? 1 : -1;
  }
}
