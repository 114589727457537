<div class="spin-filters-number mat-interval" [ngClass]="{ 'desactivate-values': !enabled }">
  <mat-checkbox
    [color]="'primary'"
    [(ngModel)]="enabled"
    [matTooltip]="field.description"
    [disabled]="false"
    matTooltipPosition="after"
    (change)="ontoggle()">
    {{ field.title }} : {{ selectedValue }}
  </mat-checkbox>
  <div class="single-value-slider">
    <nouislider
      [disabled]="!enabled"
      [behaviour]="'drag'"
      [min]="min"
      [max]="max"
      [step]="step"
      class="slider slider-primary"
      [(ngModel)]="selectedValue"
      (ngModelChange)="onslider()"
      (end)="onSliderReleased()" />
  </div>
</div>
