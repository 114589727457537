import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges,
  inject, signal } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AfterSave, EntityDefinition, EntityFieldDefinition, FieldType, NumOrString,
  UpdateEventType } from '../helpers/types';
import { EntityDataAccessor } from './entity-data-accessor';
import { DataPoint, EntityMapping } from '../data-loader/data-loader.types';
import { DataLoader } from '../data-loader/data-loader';
import { DialogManager } from './dialog-manager';
import { EntityDetailFieldComponent } from './entity-detail-field';
import { IEntityDetailFieldParent } from './entity-detail-field-parent';
import { getChained } from '../data-loader/ref-data-provider';
import { PearlIconComponent } from '../shared/pearl-components';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';

const SUPPORTED_FIELD_TYPES: FieldType[] = ['date', 'datetime'];

@Component({
  selector: 'light-edit-field',
  templateUrl: 'light-edit-field.component.html',
  styleUrls: ['light-edit-field.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatProgressSpinnerModule,
    MatIconModule,
    NgClass,
    MatFormFieldModule,
    NgForOf,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    EntityDetailFieldComponent,
    PearlIconComponent,
  ],
  providers: [
    EntityDataAccessor, // each light edit field gets its own instance, allows caching of entity definition
  ],
})
export class LightEditFieldComponent implements OnChanges, IEntityDetailFieldParent {
  private _field: EntityFieldDefinition;

  @Input({ required: true })
  protected set field(field: EntityFieldDefinition) {
    if (!SUPPORTED_FIELD_TYPES.includes(field.type)) {
      throw new Error(`Light edit field is not yet implemented for field type ${field.type}`);
    }
    this._field = field;
  }

  /**
   * DataPoint whose properties can be light edited if they have a mapping associated with them
   */
  @Input()
  dataToEdit: DataPoint;

  @Input()
  darkMode: boolean = false;

  @Input()
  hideLabel: boolean = false;

  @Output()
  fieldUpdated = new EventEmitter<AfterSave>();

  @HostListener('mouseenter')
  protected onMouseEnter(): void {
    this.hover.set(true);
  }

  @HostListener('mouseleave')
  protected onMouseLeave(): void {
    this.hover.set(false);
  }

  protected entityDataAccessor: EntityDataAccessor = inject(EntityDataAccessor);
  private dataLoader: DataLoader = inject(DataLoader);
  private dialogManager: DialogManager = inject(DialogManager);
  private analyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);
  private router: Router = inject(Router);

  protected readonly hover = signal(false);
  protected readonly loading = signal(false);
  protected coherencyErrors: string[] = [];

  private get entityFieldMapping(): EntityMapping {
    return this.dataToEdit.__propertyToEntityMappings?.[this._field.id];
  }

  private get entityName(): string {
    return this.entityFieldMapping.entityName;
  }

  private get entityProp(): string {
    return this.entityFieldMapping.entityProp;
  }

  private get entityId(): string | number {
    return this.dataToEdit[this.entityFieldMapping.idPropertyName] as string | number;
  }

  /** Getter retrieving the entity definition from the entity data accessor */
  public get entityDefinition(): EntityDefinition {
    return this.entityDataAccessor.definition;
  }

  public get entityFieldDefinition(): EntityFieldDefinition {
    return this.entityDataAccessor.definition.fields.find((field: EntityFieldDefinition) =>
      field.id === this.entityProp
    );
  }

  // IEntityDetailFieldParent implementation
  public editMode: boolean = false;
  /** Alias for entity data accessor isNew method */
  public get isNew(): boolean {
    return this.entityDataAccessor.isNew();
  }
  public MappedBy: string = null;
  public fullyLoaded: boolean = false;
  public afterClose: undefined;
  /** Retrieve errors from entity data accessor */
  public checkCoherency(): void {
    this.coherencyErrors = this.entityDataAccessor.getFieldsErrors(this.MappedBy, false);
  }
  public pushAfterSave: undefined;
  public isGroupDisabled(groupNames?: string[]): boolean {
    const group = this.entityDefinition.generalInfo.groupDisabled;

    if (!groupNames?.length || !group || Object.keys(group).length === 0) {
      return false;
    }

    for (const groupName of groupNames) {
      if (group[groupName] && group[groupName](this.entityDataAccessor)) {
        return true;
      }
    }

    return false;
  }

  public ngOnChanges(_: SimpleChanges): void {
    // Leave edit mode when inputs change
    this.leaveEditMode();
  }

  protected onEditButtonClick(): void {
    if (this.editMode) {
      return;
    }

    this.enterEditMode();
  }

  protected onCancelButtonClick(): void {
    if (!this.editMode) {
      return;
    }

    this.leaveEditMode();
  }

  protected async onSaveButtonClick(): Promise<void> {
    if (!this.editMode) {
      return;
    }

    if (this.coherencyErrors.length) {
      this.dialogManager.showMessage(`${this.coherencyErrors.join(', ')}.`, 'error');
      return;
    }

    this.loading.set(true);

    const previousValue = getChained(this.dataToEdit, this._field.id);
    const saveResult = await this.entityDataAccessor.saveEntityField(this.entityFieldDefinition);
    const currentValue: unknown = saveResult.entity?.[this.entityProp];

    this.analyticsService.trackAction('lightEditFieldSaved', {
      path: this.router.url,
      layer: this.dataToEdit.layerId,
      field: this._field.id,
      previousValue: previousValue,
      currentValue: currentValue,
    });

    this.loading.set(false);

    this.dialogManager.showMessage(saveResult.message, saveResult.success ? 'success' : 'error');

    if (saveResult.success) {
      this.leaveEditMode();
      const afterSave: AfterSave = {
        entityName: this.entityName,
        eventType: UpdateEventType.LightUpdate,
        layerId: String(this.dataToEdit.layerId),
        entity: saveResult.entity,
        idToReload: (this.dataToEdit.uniqueId ?? this.dataToEdit.id) as NumOrString,
        modifiedEntityId: this.entityId,
      };
      this.fieldUpdated.emit(afterSave);
    }
  }

  /**
   * When entering edit mode, the component:
   *   - loads the entity definition if not already loaded
   *   - loads the latest entity value
   * Once all is loaded, display the entity detail field component
   */
  private async enterEditMode(): Promise<void> {
    this.fullyLoaded = false;

    this.loading.set(true);

    // Load definition
    try {
      await this.loadEntityDefinition();
    } catch (error) {
      this.dialogManager.showMessage('Error while loading entity definition.', 'error');
      this.loading.set(false);
      return;
    }

    // Load entity
    try {
      await this.loadEntity();
    } catch (error) {
      this.dialogManager.showMessage('Error while loading the entity.', 'error');
      this.loading.set(false);
      return;
    }

    /*
     * Ensure loaded entity value is coherent with initial data. Otherwise, prevent any modification based on the
     * current mapping information, it could lead to the wrong entity or property being edited!
     */
    if (!this.validateLoadedEntityValue()) {
      this.dialogManager.showMessage('This value cannot be edited right now. Please try again later.', 'error');
      this.loading.set(false);
      return;
    }

    this.coherencyErrors = [];
    this.entityDataAccessor.enterEditMode();
    this.fullyLoaded = true;
    this.loading.set(false);
    this.editMode = true;
  }

  private leaveEditMode(): void {
    this.entityDataAccessor.leaveEditMode();
    this.editMode = false;
    this.hover.set(false);
  }

  /**
   * Loads the entity definition and sets it to the entity data accessor if not already loaded.
   */
  private async loadEntityDefinition(): Promise<EntityDefinition> {
    if (this.entityDataAccessor.definition) {
      return this.entityDataAccessor.definition;
    }

    const definition = await this.dataLoader.getEntityDefinition(this.entityName, false);
    this.entityDataAccessor.setDefinition(definition);
  }

  /**
   * Loads the entity value and sets it on the entity data accessor
   */
  private async loadEntity(): Promise<void> {
    const entity = await this.dataLoader.getEntity(this.entityName, { id: this.entityId });
    this.entityDataAccessor.setEntity(this.entityName, entity);
  }

  /**
   * Validate that the loaded entity value (using the mapping information) is coherent with the initial
   * data (loaded via the initial endpoint).
   * If the values are different, either the mapping information is erroneous and needs fixing, or the value has changed
   * between the time the original data was loaded and the time the light edit field switched to edit mode.
   */
  private validateLoadedEntityValue(): boolean {
    const valueToEdit = getChained(this.dataToEdit, this._field.id);
    const valueFromEntity = getChained(this.entityDataAccessor.entity as DataPoint, this.entityFieldMapping.entityProp);
    if (valueToEdit !== valueFromEntity) {
      // Tolerate 1ms difference for date fields (sometimes added by the backend to prevent overlap on schedule)
      if (
        (['date', 'datetime'].includes(this._field.type))
        && typeof valueToEdit === 'number' && typeof valueFromEntity === 'number'
        && Math.abs(valueToEdit - valueFromEntity) <= 1
      ) {
        return true;
      }

      // Log an error to be fixed, this can indicate that the mapping information is erroneous and should be fixed.
      console.error(
        `Mapping error: ${this.entityName}.${this.entityProp} value is different `
          + 'from the value given by the data endpoint.',
      );
      return false;
    }

    return true;
  }
}
