import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, NgZone, ViewChild, ViewEncapsulation,
  inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgIf } from '@angular/common';

import { debounce, flatten, isEqual, isNumber, isString, maxBy, merge, minBy } from 'lodash-es';
import { Layout, PlotData } from 'plotly.js';
import { select } from 'd3-selection';
import tinycolor from 'tinycolor2';
import dayjs from 'dayjs';
import { pathOr } from 'ramda';

import { AxisConfig, ChartCalcConfig, ChartExportData, ChartSelectKey, ChartSeries, ChartSettings, ChartStraightLine,
  ConnectGapsValues, CustomTooltipData, DataSeries, HorizontalPosition, RealPlotDatum, SeriesCommonTypeValues,
  SeriesHeader, TransitionalSeries, TransitionalValues, YAxisLayout, YAxisMap, YAxisName } from './chart-types';
import { Color, TooltipSeries } from '../helpers/types';
import { ChartingHelpers } from './charting-helpers';
import { NvGraph, Y_AXIS_WIDTH } from './nvgraph';
import { ColorHelper } from '../helpers/color-helper';
import { ChartOrdering } from './chart-ordering';
import { LegendManager } from './legend-manager';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { GraphOptionsComponent } from './graph-options';
import { DescriptionButtonComponent } from '../shared/description-button';
import { DataHelpers } from '../helpers/data-helpers';
import { ChartsFormatting } from './charts-formatting';
import { Config } from '../config/config';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { TimeCreator } from '../helpers/time-creator';

type CustomLegendProperties = {
  legendEntries?: Map<string, string>;
  legendsMarginTop?: number;
  legendsMarginBottom?: number;
  plotDimensions?: DOMRect;
};

@Component({
  selector: 'plotly-multi',
  templateUrl: 'plotly-multi.html',
  styleUrls: ['nvgraph.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    DescriptionButtonComponent,
    GraphOptionsComponent,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    FormsModule,
    NgClass,
    ChartTooltipComponent,
  ],
})
export class PlotlyMulti extends NvGraph implements AfterViewInit {
  /*
   * removing all default chart options for plotly charts
   * default options are only usefull for nvd3 charts
   */

  /**
   * FIXME: to be removed, this is a duplicate of `this.data` (SP-8012)
   */
  public multiSeries: ChartSeries[];
  /**
   * Map series key (id or name if missing) with series name
   */
  public legendEntries: Map<string, string>;

  private duplicateSplitsPresents = false;
  private ngZone: NgZone = inject(NgZone);
  private initRange: number[];
  private groupMinErrors = {};
  private groupMaxErrors = {};
  private legendManager: LegendManager;
  private cachedCustomLegendProperties: CustomLegendProperties;
  private hasLineTraces = false;

  public override availableSelects: ChartSelectKey[] = ['metric', 'groupby', 'size', 'splitby'];
  public messages: { [select: ChartSelectKey]: string } = {};

  private static MAX_Y_AXIS = 4;
  private static LEGEND_MARGIN_PERCENT = 0.20;
  private static DEFAULT_CONNECT_GAPS: {
    [chartType in SeriesCommonTypeValues]: ConnectGapsValues;
  } = {
    line: 'tozero',
    bar: 'hide',
    scatter: 'hide',
  };

  @ViewChild('chartArea')
  protected $chartArea: ElementRef;

  constructor(elementRef: ElementRef) {
    super(elementRef, 'multi');
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.legendManager = new LegendManager(this.plotlyChartId, null, null);
    this.cachedCustomLegendProperties = {};
  }

  /** @override For plotly-multi and charts that inherit from it, data structure is series[] instead of values[] */
  protected override isMultiSeriesChart(): boolean {
    return true;
  }

  /**
   * Return true if chart support multi-metrics, for now:
   * - 'chart.opts.selects.metric.multiple' set to true (SP-8011)
   * - only lines & scatter series (SP-8010)
   * - no splits (SP-8006)
   */
  public allowMultiMetrics(): boolean {
    return (
      !this.componentSettings.chart.selects.splitby
      && this.componentSettings.chart.selects.metric?.multiple
      && this.componentSettings.chart.series.every(series => ['line', 'scatter'].includes(series.type))
    );
  }

  public override updateBarsDetails(chartSettings: ChartSettings): void {
    if (this.display.showTail || !chartSettings.toggleTailEnabled) {
      this.display.aggregateDetails = 'All values are shown';
      if (chartSettings.toggleTailEnabled) {
        this.display.aggregateTooltip = 'Uncheck to aggregate tail';
      } else {
        this.display.aggregateTooltip = '';
      }
    } else {
      this.display.aggregateDetails = 'Only ' + (chartSettings.shownBarValues - 1) + ' first values are shown';
      this.display.aggregateTooltip = 'Check to display all values';
    }
  }

  public override updateLinesDetails(chartSettings: ChartSettings): void {
    switch (this.display.displayMode) {
      case 'all':
        this.display.aggregateDetails = 'All series are displayed';
        this.display.aggregateTooltip = 'Uncheck to display only relevant series and trend';
        break;
      case 'relevant':
        this.display.aggregateDetails = chartSettings.shownSeries[0] + ' series out of ' + chartSettings.shownSeries[1]
          + ' are displayed';
        this.display.aggregateTooltip = 'Check to see the entire dataset';
        break;
      default:
    }
  }

  /**
   * Iterates through `this.seriesData`, transform them and call the $chart to plot them.
   * This method expects `dataSeries` to have been populated by `loadAllSeriesData()`
   * which takes care of light data filtering.
   *
   * Multi series chart inside chart wrapper has multiple series configured which can be either light or heavy.
   * Heavy series are queried as they are. Light series have been filtered when loaded.
   * We have to perform transformation on light and heavy series.
   * Groupby and splitby have to be handled in different ways for light vs heavy.
   * Metrics come from the chart config.
   * Filters depend only on the chart config.
   */
  public override plotDataSeries(
    dataSeries: DataSeries[],
    commonCalc: ChartCalcConfig,
    linesData: ChartStraightLine[] = [],
  ): void {
    // Create specific copies of all series data prepared and filtered for charting
    const chartSeries: ChartSeries[] = [];

    // Load selected metrics & chart settings
    this.findSelectValues();
    this.updateMetricsAvailability();
    const chartSettings = { ...this.componentSettings.chart };

    // Keep timezone
    commonCalc.timezone = this.timezoneService.timezone;

    let onlyValidNumeric = true;
    let onlyValidString = true;

    for (const series of dataSeries) {
      const seriesData = series.data.slice();

      const calc: ChartCalcConfig = { ...commonCalc, series };
      if (series.splitby) {
        calc.splitBy = { ...series.splitby };
        calc.splitBy.nullTitle ??= ChartingHelpers.nullGroupTitle;
      }

      const metrics = series.metric ? [series.metric] : this.selectedMetrics;

      /**
       * At least one metric should be set for all single-metrics graph
       * Multi-metrics graphs may have no metrics selected, this should not trigger a Sentry error
       */
      if (!metrics?.length && !this.allowMultiMetrics()) {
        const fingerPrint = `${this.componentSettings.id}.json-missing-metric-in-${series.id}`;
        console.error(
          new ErrorWithFingerprint(
            `Missing metric for ${series.id} in ${this.componentSettings.title}`,
            [fingerPrint],
          ),
        );
        continue;
      }

      metrics.forEach(metric => {
        // Check whether current metric supports current data series
        if (
          (metric.includeSeries && !metric.includeSeries.includes(series.id))
          || (metric.excludeSeries && metric.excludeSeries.includes(series.id))
        ) {
          return;
        }
        // Inject variables inside metric title
        let title = metric.title ?? series.title ?? '';
        if (DataHelpers.needsParameterInjection(title)) {
          title = DataHelpers.injectParameters(title, chartSettings.valueBag);
        }

        // Set y-axis from metric & its value
        calc.yaxis = { ...metric, title, aggregation: ChartingHelpers.metricColumn(metric.value) };
        if (!calc.yaxis.connectGaps) calc.yaxis.connectGaps = PlotlyMulti.DEFAULT_CONNECT_GAPS[series.type];

        // Append tooltipPageLink
        calc.titlePageLink = this.getTooltipTitlePageLink();

        // Append additionalProps from tooltip extend
        calc.additionalProps = this.getTooltipAdditionalProps();

        // Structure holding the data and header
        let transitionalSeries: TransitionalSeries;

        if (!ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType)) {
          /**
           * Light series data is "raw", it must go through the whole `transformSeries()` pipe.
           */
          calc.forceNoTabFiltering = series.forceNoTabFiltering;
          transitionalSeries = ChartingHelpers.transformSeries(calc, seriesData, chartSettings);
          onlyValidNumeric = onlyValidNumeric && transitionalSeries.xAxisType === 'numeric';
          onlyValidString = onlyValidString && transitionalSeries.xAxisType === 'string';
        } else {
          /**
           * Heavy series data is already filtered and aggregated on the backend
           * Working on a shallow copy to avoid altering original data, and using headerMap to generate header
           */
          transitionalSeries = {
            data: seriesData as TransitionalValues[],
            header: series.headerMap[metric.value],
            xAxisType: 'mixed',
          };
          // We still need to do the last part of the `transformSeries()` pipe (bars & lines limit).
          ChartingHelpers.transformAggregatedSeries(transitionalSeries, calc, chartSettings);
          const data = transitionalSeries.data;
          onlyValidNumeric = onlyValidNumeric && data.every(d => isNumber(d.x));
          // isString does not seem to return false for numbers..
          onlyValidString = onlyValidString && data.every(d => isString(d.x));
        }

        // Build chart series
        const forCharting = ChartingHelpers.makeSeriesForCharting(transitionalSeries, series, calc.yaxis);
        chartSeries.push(forCharting);
      });

      // Report tail/modes - reassigning so it is properly updated in the child component
      this.opts = {
        ...this.componentSettings.chart.opts,
        enableRangeSlider: chartSettings.rangeSliderEnabled,
        enableToggleTail: chartSettings.toggleTailEnabled,
        enableModes: chartSettings.modesEnabled,
      };

      if (chartSettings.rangeSliderEnabled) this.updateBarsDetails(chartSettings);
      if (chartSettings.modesEnabled) this.updateLinesDetails(chartSettings);
    }

    if (!chartSeries.length) {
      return;
    }

    /** TODO: get min/max of minX/maxX of *all* series before plotting! SP-8014 */

    /**
     * FIXME: if one of the series is of type 'bar' but only contains one temporal value
     * Plotly will fail guessing the bar width and set it to zero
     * Waiting for a uniform x-axis span accross multiple series & metric & adding empty values
     */
    const barData = chartSeries.find(series => series.type === 'bar')?.data;
    if (barData?.length === 1 && chartSeries.some(series => series.type === 'line')) {
      const lineData = chartSeries.find(series => series.type === 'line').data;
      lineData.forEach(dl => barData.find(db => db.x === dl.x) || barData.push({ x: dl.x }));
    }

    // Determine xAxis type
    const xAxisType = onlyValidNumeric ? 'numeric' : (onlyValidString ? 'string' : 'mixed');

    this.plot(chartSeries[0].header, chartSeries, linesData, xAxisType);
  }

  public override async chartSpecificPlot(
    head: any,
    multiSeries: ChartSeries[],
    linesData: ChartStraightLine[],
  ): Promise<void> {
    // Prepare layout first to assign y-axis
    this.init(multiSeries, linesData);

    // Removing lines with no data
    linesData = linesData.filter(line => typeof (line.point) === 'number');

    // Assign y-axis and set/copy axis options
    const yAxisLayout = this.getYAxisLayout();

    // Prepare standard traces first (retrieve min/max values)
    this.prepareStandardTraces();

    // Build global layout and merge with axis layout
    this.layout = merge(this.mapChartOptsInPlotlyLayout(), yAxisLayout);

    // Update figures on chart
    this.updateFiguresOnChart();

    /**
     * Updating Layout for custom legends generation
     * Putting legend above plot in order to generate the most optimal chart
     * Size of 1 to avoid big character, the legend will later be hidden by the LegendManager
     */
    this.layout.legend = { x: 1, xanchor: 'right', y: 1, font: { size: 1 } };
    // Using cached properties makes the plot layout properly at any case
    this.layout.margin.t = this.cachedCustomLegendProperties.legendsMarginTop ?? 30;
    this.layout.margin.b = this.cachedCustomLegendProperties.legendsMarginBottom ?? 30;

    this.layout.legend.traceorder = 'normal';

    this.addMarginIfNecessary(linesData);

    // If there's at least one bar-chart series in the config, we apply the bar mode
    if (multiSeries.some(d => d.type === 'bar') && this.selectedMetrics?.[0]) {
      // Show bar mode control buttons from the config
      this.showBarModeControls = this.selectedMetrics[0].showControls
        || this.selectedMetrics[0].showControls === undefined;

      // Setting barMode based on selectedMetric config.
      if (!this.barModeChanged && this.selectedMetrics[0].stacked === false) {
        this.barMode = 'group';
      }
      this.layout.barmode = this.barMode;
    } else {
      /*
       * we force the barmode because it stacks bars both with negative and positive values
       * https://plotly.com/python/reference/layout/#layout-barmode
       */
      this.layout.barmode = 'relative';
    }

    // Explicitly set the bar width on bar charts in some cases
    this.setBarsWidthForAllTraces();

    /*
     * Update colors & spline for quantile
     * When displaying quantiles distribution, change shape from "line" to "spline"
     * and apply gradient colors to each series, from white to blue (with a line for q50)
     *
     * chart-studio.plotly.com/~vigneshbabu/9/_10th-percentile-25th-percentile-median-75th-percentile-90th-percentile
     */
    if (this.settings.modesEnabled) {
      if (this.display.displayMode === 'quantiles') {
        this.traces.forEach(trace => {
          trace.line = {
            shape: 'spline',
            color: ColorHelper.quantilesColors.median,
            width: trace.split === 'q50' ? 3 : 0,
            smoothing: .6,
          };
          trace.fill = trace.split === 'q10' ? 'none' : 'tonexty';
          if (trace.split === 'q25' || trace.split === 'q90') {
            trace.fillcolor = ColorHelper.quantilesColors.q10_alpha;
          }
          if (trace.split === 'q50' || trace.split === 'q75') {
            trace.fillcolor = ColorHelper.quantilesColors.q25_alpha;
          }
        });
      }
      if (this.display.displayMode === 'relevant') {
        this.traces.forEach(trace => {
          if (trace.split !== 'q50') return;
          trace.marker = {
            size: 0,
            color: 'transparent',
          };
          trace.line = {
            shape: 'spline',
            color: ColorHelper.quantilesColors.average,
            width: 3,
            smoothing: .6,
          };
        });
      }
    }

    /*
     * straight lines (vertical or horizontal) depend on the determined yaxis range
     * range can change due to the previous call to adjustMultipleAxisTicks
     */
    this.traces = this.traces.concat(this.buildLineTraces(linesData));

    this.hasLineTraces = this.traces.some(trace => {
      return trace.type === 'line' || trace.mode === 'lines';
    });

    // We need to generate a map of legendEntries in order to generate the customLegends
    this.legendEntries = new Map();
    this.traces.forEach(trace => {
      const key = trace.id ?? trace.name;
      this.legendEntries.set(key, trace.name);
    });

    // Today line must be created right before plotting because it's using the results of previous calculations
    this.createTodayLine();

    // Perform only one change detection for this class, after every calculations have been made
    this.cdRef.detectChanges();

    await this.plotlyPlot();
    this.addPlotlyTooltip();

    // Add mouseup event on rangeslider to force relayout after drag (avoid misplaced bars & wrong crops)
    const $slider = this.plotElement?.querySelector('.rangeslider-container');
    if (!$slider) return;
    this.ngZone.runOutsideAngular(() => {
      const mouseUp = (): void => {
        document.removeEventListener('mouseup', mouseUp, true);
        this.plotlyUpdate();
      };
      $slider.addEventListener('mousedown', () => {
        document.addEventListener('mouseup', mouseUp, true);
      }, true);
    });
  }

  public override mapChartOptsInPlotlyLayout(): Partial<Layout> {
    const layout = super.mapChartOptsInPlotlyLayout();
    // Set the intial zoom interval if set
    if (this.opts.initZoomInterval) {
      const zoomInterval = TimeCreator.getInitTimestamps(
        dayjs(),
        this.opts.initZoomInterval,
        this.opts.initZoomUnit,
        this.minX,
        this.maxX,
        null,
      );
      // Manually set range (plotly is expecting strings in the form Y-m-d H:i:s)
      layout.xaxis.autorange = false;
      layout.xaxis.range = zoomInterval.map(number => dayjs(number).utc().format('YYYY-MM-DD HH:mm:ss'));
    }
    /**
     * For pure scatter plots, change hovering mode to 'closest' and set all legend items to the same size
     * TODO: currently, using more than 1 y-axis for scatter plots prevent hovering method from picking other
     * points than the last trace. This has been an open ticket on Github for... 4 years, and somehow requires
     * spon$or... https://github.com/plotly/plotly.js/issues/4294
     */
    if (this.multiSeries.every(series => series.type === 'scatter') && layout.xaxis.type !== 'category') {
      layout.hovermode = 'closest';
      layout.legend.itemsizing = 'constant';
      /**
       * Disable X-axis automargin for scatter plots, as it is causing an infinite-loop
       * will be dealt with in `afterPlotActions()` (see `resizeXAxisMargin()`)
       * @see https://github.com/plotly/plotly.js/issues/4572
       */
      layout.xaxis.automargin = false;
    }

    return layout;
  }

  /**
   * Show/hide figures on fullscreen charts
   */
  private updateFiguresOnChart(): void {
    /**
     * Updates the chart layout and trace elements to either display or hide figures on the chart
     * based on the current settings and fullscreen mode.
     */
    if (this.isFullScreen && !this.settings.selects?.metric?.hideFiguresOnChart) {
      this.layout.uniformtext = {
        minsize: Config.SPIN_DEFAULT_FONT_SIZE,
        mode: 'hide',
      };
      this.traces.forEach(trace => {
        const yAxisFormat = this.getYAxisFormat(trace.yaxis);
        trace.textposition = 'inside';
        trace.textfont = { size: Config.SPIN_DEFAULT_FONT_SIZE };
        trace.text = trace.y.map(value => value != null ? this.applyFormatTooltip(value, yAxisFormat) : null);
      });
    } else {
      this.traces.forEach(trace => {
        delete trace.text;
      });
    }
  }

  /**
   * Check for selected metrics and assign them to yaxis1...4,
   * then report metric options to axis options
   */
  private assignYAxis(): YAxisMap {
    const yaxisMap: YAxisMap = {};
    if (!this.display.metric) return yaxisMap;
    let index = 0;
    const metrics = ChartingHelpers.findSelectMetrics(this.display.metric, this.selects.metric);
    metrics.forEach(metric => {
      if (!yaxisMap[metric.yaxisId]) {
        if (++index > PlotlyMulti.MAX_Y_AXIS) {
          console.error(
            // throw new ErrorWithFingerprint(
            `More than ${PlotlyMulti.MAX_Y_AXIS} y-axis in use`,
            ['plotly-multi', this.componentSettings.id, this.componentSettings.title],
          );
        }
        yaxisMap[metric.yaxisId] = index;
      }
    });
    return yaxisMap;
  }

  /**
   * Update layout with axis options
   */
  protected override getYAxisLayout(): Partial<Layout> {
    if (!this.allowMultiMetrics()) {
      return super.getYAxisLayout();
    }
    const yAxisMap: YAxisMap = this.assignYAxis();
    const yAxisLayout: YAxisLayout = {};
    this.multiSeries.forEach(chartSeries => {
      this.updateYAxisLayout(yAxisMap, yAxisLayout, chartSeries);
      // Report axis name to config
      chartSeries.yaxis = 'y' + yAxisMap[chartSeries.yaxisId] as YAxisName;
    });
    this.adjustYAxisTitles(yAxisLayout);
    return yAxisLayout;
  }

  protected override afterPlotActions(): void {
    this.handleCustomLegends();
    this.resizeElements();
  }

  public resizeElements = debounce(() => {
    this.resizeXAxisMargin();
    this.resizeXAxisSlider();
  }, 200);

  public override forceRelayout(): void {
    super.forceRelayout();
  }

  public hasSamplingIndicator(): boolean {
    return this.opts.hideOptions && this.opts.dynamicGroupBy && this.selectedGroup && !this.loading;
  }

  private handleCustomLegends(): void {
    if (this.layout.showLegend === false || this.opts.showlegend === false) {
      return;
    }

    const d3Chart = select(`#${this.plotlyChartId}`);
    const existingPlotlyLegend = d3Chart.select('.legend');

    if (d3Chart.empty() || d3Chart.select('.plot-container').empty()) {
      return;
    }
    /**
     * We want to hide plotly legends as soon as possible
     * existingPlotlyLegend will always be empty because of the showLegend set to false by default
     * Only work after plotlyRelayout trigger inside 'ChartCustomLegends'
     */
    if (!existingPlotlyLegend.empty()) {
      this.legendManager.hidePlotlyLegends(existingPlotlyLegend);
    }

    this.replacePlotlyLegendsWithManager(d3Chart);
  }

  /**
   * Optimize the chart legend layout:
   *  - plotlyRelayout the chart to allocate interspace for custom legends, when required
   *  - generate the customLegend layout objects, then call the build
   *
   * @param d3Chart Chart d3 selection. The corresponding chart for the customLegendsHorizontalPosition
   * @returns
   */
  private replacePlotlyLegendsWithManager(d3Chart: d3.Selection<HTMLElement>): void {
    /**
     *  The code calculate the interspace required for the customLegends
     */

    const plotDimensions = (d3Chart.node() as any).getBoundingClientRect();

    // Default value of margin for xAxis Labels & yAxis
    const legendContainerHeight = plotDimensions.height * PlotlyMulti.LEGEND_MARGIN_PERCENT;
    let legendsMarginTop = 0; // We don't need top margin by default
    let legendsMarginBottom = 30; // Margin for bottom trace's legends

    // We placed legends on the top by default
    const legendPosition = this.settings.opts?.legendPosition ?? 'top';
    if (legendPosition === 'top') {
      legendsMarginTop = legendContainerHeight;
    } else {
      legendsMarginBottom = legendContainerHeight;
      /**
       * Custom Behavior for bottom legends:
       * In order to improve buttom legend display, when width smaller than height, margin the plot as a square
       */
      if (plotDimensions.height > plotDimensions.width) {
        legendsMarginBottom = Math.max(legendsMarginBottom, plotDimensions.height - plotDimensions.width);
      }
    }

    let isLegendLayoutUpdated = false;
    let isLegendTracesUpdated = false;

    // Check that the legend layout changed
    if (
      this.cachedCustomLegendProperties?.legendsMarginBottom !== legendsMarginBottom
      || this.cachedCustomLegendProperties?.legendsMarginTop !== legendsMarginTop
      || this.cachedCustomLegendProperties?.plotDimensions.width !== plotDimensions.width
      || this.cachedCustomLegendProperties?.plotDimensions.height !== plotDimensions.height
    ) {
      isLegendLayoutUpdated = true;
      this.cachedCustomLegendProperties.plotDimensions = plotDimensions;
      this.cachedCustomLegendProperties.legendsMarginTop = legendsMarginTop;
      this.cachedCustomLegendProperties.legendsMarginBottom = legendsMarginBottom;
    }

    // Check that the legend traces changed
    if (!isEqual(this.cachedCustomLegendProperties.legendEntries, this.legendEntries)) {
      isLegendTracesUpdated = true;
      this.cachedCustomLegendProperties.legendEntries = this.legendEntries;
    }

    /**
     *  When the legend layout or the legend traces is different, we relayout the chart to apply right interspace
     */

    // When the chart legend margin or traces changed, we call a relayout to update the plot
    if (isLegendLayoutUpdated || isLegendTracesUpdated) {
      /** Updating Margin to place custom Legends */
      this.plotlyRelayout(
        {
          showlegend: true,
          margin: {
            l: this.layout.margin.l,
            r: this.layout.margin.r,
            t: legendsMarginTop,
            /** Default value of margin for xAxis Labels & yAxis */
            b: this.hasSamplingIndicator() ? legendsMarginBottom + 10 : legendsMarginBottom,
            pad: this.layout.margin.pad,
          },
        },
      );
      // A relayout will call the function once again. Then the next steps will be performed
      return;
    }
    // After switching showlegend to true, check if not empty
    const existingPlotlyLegend = d3Chart.select('.legend');
    if (existingPlotlyLegend.empty()) {
      return;
    }

    /**
     *  Generating the customLegend data structures to build the customLegends
     */

    const rightMargin = this.settings.opts?.legendPosition === 'bottom' && this.hasSamplingIndicator() ? 100 : 50;
    // Special case for schedule charts, set left margin to 2 * Y_AXIS_WIDTH
    const leftMargin = this.isScheduleChart ? 2 * Y_AXIS_WIDTH : 10;

    const containerLayout = {
      margin: { top: 10, bottom: 10, right: rightMargin, left: leftMargin, pad: 5 },
      boundingBox: { width: plotDimensions.width, height: legendContainerHeight, x: 0, y: 0 },
      horizontalPosition: (this.isScheduleChart ? 'left' : 'right') as HorizontalPosition,
      // We always wants the schedule chart's customLegends to be on the left
      verticalPosition: legendPosition,
    };

    const legendLayout = {
      margin: { top: 2, bottom: 3, right: 5, left: 5, pad: 5 },
      orientation: 'h' as const,
      font: { size: 12 },
      // Plotly's lines Legend are 30px long, matching it
      icon: { width: this.hasLineTraces ? 30 : 12 },
      maxStringLength: 25,
    };

    this.legendManager.buildCustomLegends(this.plotlyChartId, containerLayout, legendLayout, this.legendEntries);
  }

  /**
   * Overriding prepareSvgForOnPng
   * Adding customLegends to the svgElement using the LegendManager
   */
  protected override prepareSvgForOnPng(
    svgElement: d3.Selection<HTMLElement>,
    dimensions: { height: number; width: number },
    scale: number,
    fullExport: boolean = true,
  ): void {
    // Default Plotly Chart top Margin for legends
    const defaultPlotlyChartTopMargin = dimensions.height * PlotlyMulti.LEGEND_MARGIN_PERCENT;

    // Check if customLegends are bigger than plotly top margin
    const needToResizeForCustomLegends = this.legendManager.legendEntriesTotalHeight > defaultPlotlyChartTopMargin;
    // Calc height difference between plotly top Margin (from layout.margin.t) and customLegend height
    const customLegendVsDefaultLayoutHeightDifference = Math.abs(
      this.legendManager.legendEntriesTotalHeight - defaultPlotlyChartTopMargin,
    );

    // Adding difference height to allocate enough height for the legends
    dimensions.height += needToResizeForCustomLegends ? customLegendVsDefaultLayoutHeightDifference : 0;

    super.prepareSvgForOnPng(svgElement, dimensions, scale, fullExport);

    const graphSvg = svgElement.select('.main-svg');

    // Retrieving generated header

    const header = graphSvg.select('.export-header');

    // Adding small margin of 15px for better layout
    const headerDimensionsHeight = (header.node() as any).getBoundingClientRect().height + 15;
    const customLegendsTranslationHeight = this.isScheduleChart
      ? dimensions.height // Bottom Legends
      : headerDimensionsHeight; // Top Legends
    // Adding customLegendsSvg
    const customLegendsSvg = graphSvg.insert('svg')
      .attr('style', 'background: transparent; position: absolute; pointer-events: all;')
      .attr('width', `${dimensions.width}`)
      .attr('height', `${this.legendManager.legendEntriesTotalHeight}`)
      .attr('transform', `translate(0, ${customLegendsTranslationHeight})`);

    if (this.opts.showlegend !== false) {
      const legendEntryBoundingBoxes = this.legendManager.calcLegendEntryBoundingBoxes(dimensions.width);
      this.legendManager.appendPlotlyLegendsToCustomLegendsContainer(customLegendsSvg, legendEntryBoundingBoxes);
    }
    // Full export contains  logo, custom legends and translated graph
    if (fullExport) {
      // place customLegendsSvg just after header.
      customLegendsSvg.attr(
        'transform',
        `translate(0, ${
          /**
           * headerDimensionsHeight avoids overlapping with header.
           * When no need to resize, dividing the available space for better layout
           */
          headerDimensionsHeight
          + (needToResizeForCustomLegends ? 0 : customLegendVsDefaultLayoutHeightDifference / 3)})`,
      );

      // Cartesian Layer is the actual graph, Info layer is axis titles and legend
      const graphLayer = this.isPolarPlot ? '.polarlayer' : '.cartesianlayer';

      // Adding margin to plotly graph, plotly legends & axis titles
      graphSvg.selectAll(graphLayer + ', .layer-above, .infolayer')
        // Translate graphSvg to avoid overlapping with header & custom legends. Will be placed after customLegends
        .attr(
          'transform',
          `translate(0, ${
            headerDimensionsHeight
            + (needToResizeForCustomLegends ? customLegendVsDefaultLayoutHeightDifference + 15 : 0)
          })`,
        );
    }
    this.legendManager.hidePlotlyLegends(graphSvg.select('.legend'));
  }

  /**
   * On select or tab change
   */
  public override onchange(name: ChartSelectKey, value: any): void {
    super.onchange(name, value);
    // Enable or disabled metrics if no more y-axis available
    if (name === 'metric') this.updateMetricsAvailability();
  }

  public onBarModeChange(barMode: string): void {
    this.barModeChanged = true;
    this.barMode = barMode;
    this.onselect.emit(this.display);
    this.productAnalyticsService.trackAction('barModeSelected', { selectedBarMode: barMode, graphTitle: this.title });
  }

  public onshowlabels(value: boolean): void {
    this.$chartOptions.showLabel = value;
    this.chartSpecificPlot(null, this.data, this.straightLines);
  }

  /**
   * Init selectable values
   */
  public override initSelectValues(): void {
    super.initSelectValues();
    if (this.allowMultiMetrics()) {
      // Use `suffix` or `value` as default `yaxisId` if not set (to be changed as `unit` SP-8015)
      this.selects.metric.values.forEach(metric => {
        if (!metric.yaxisId) metric.yaxisId = metric.suffix ?? metric.value;
      });
    }
  }

  /**
   * Enable or disable metrics in dropdown
   */
  public override updateMetricsAvailability(): void {
    if (!this.selects.metric || !this.allowMultiMetrics()) return;
    const yaxisMap = this.assignYAxis();
    const maxReached = Object.values(yaxisMap).length >= PlotlyMulti.MAX_Y_AXIS;
    const metrics = this.selects.metric.values;
    metrics.forEach(metric => (metric.disabled = maxReached && !(metric.yaxisId in yaxisMap)));
    // Display a custom message if all options are either disabled or selected, and at least one is disabled
    const disabled = metrics.filter(v => v.disabled).length;
    const selected = this.selectedMetrics.length;
    this.messages.metric = disabled && disabled + selected === metrics.length ? 'Max number of metrics selected' : '';
  }

  /**
   * Hide graph and display message if multi-metrics graph has no metrics selected
   */
  public noMetricsSelected(): boolean {
    return this.allowMultiMetrics() && !this.selectedMetrics?.length;
  }

  private addMarginIfNecessary(linesData: ChartStraightLine[]): void {
    /*
     * If there is a vertical line at the end of the graph, the line might be hard to see.
     * In that case, we will add some margin on the right/left of the graph to make the line more visible.
     */
    const margin = (this.maxX - this.minX) / 20; // 5% margin on (possibly) both sides
    let hasVerticalLines = false;
    for (const line of linesData) {
      if (line.direction === 'vertical') {
        hasVerticalLines = true;
        if (line.point + margin > this.maxX) {
          this.maxX = line.point + margin;
        }
        if (line.point - margin < this.minX) {
          this.minX = line.point - margin;
        }
      }
    }
    if (hasVerticalLines) {
      this.layout.xaxis.range = [this.minX, this.maxX];
      this.layout.xaxis.autorange = false;
    }
  }

  public prepareStandardTraces() {
    /*
     * Note that we have to do these two actions in this order because we need the series' traces in
     * buildLineTraces
     */

    if (!this.multiSeries || !this.multiSeries.length) {
      return [];
    }

    const singleSeries = this.multiSeries.length === 1;

    /*
     * We remove 'All' split from the duplicate split check
     * 'All' split does not account for an actual split but a null value
     */
    const allSplits = flatten(this.multiSeries.map(d => Object.keys(d.header).filter(split => split !== 'All')));
    const uniqueSplits = new Set(allSplits);
    this.duplicateSplitsPresents = allSplits.length !== uniqueSplits.size;

    for (const series of this.multiSeries) {
      this.traces = this.traces.concat(...this.buildSeriesTraces(series, singleSeries));
    }

    this.noRawDataForMetric = this.traces.length === 0;

    if (this.noDataToShow()) {
      return [];
    }

    return this.traces;
  }

  /**
   * Return the prepared chart data for XLSX export.
   * It creates unique ID for each series split.
   */
  public override prepareCsvData(_: SeriesHeader, data: ChartSeries[]): ChartExportData {
    /** The mapping series split ID -> series split title */
    const exportHeader: SeriesHeader = {};
    /** All series data per x group */
    const dataSeries = {};
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(this.selectedGroup);
    const isXaxisNumeric = this.opts?.xAxisType === 'numeric';
    const singleSeries = data.length === 1;

    for (let seriesInd = 0; seriesInd < data.length; ++seriesInd) {
      const series = data[seriesInd];
      const splits = [];

      for (const splitId in series.header) {
        const splitTitle = this.getSeriesSplitName(series, singleSeries, series.header[splitId] as string);
        /*
         * We prefix each split ID with the series ID to have an unique overall split ID
         * (multiple series might have the same split ID)
         */
        exportHeader[`${seriesInd}_${splitId}`] = splitTitle;
        splits.push(splitId);
      }

      for (const d of series.data) {
        if (!dataSeries[d.x]) {
          dataSeries[d.x] = {};
        }
        // Report split values & additional properties
        for (const splitId of splits) {
          dataSeries[d.x] = {
            ...dataSeries[d.x],
            [`${seriesInd}_${splitId}`]: d[splitId],
          };
          // In case additional properties are defined for more than 1 split, last one wins
          if (d.__additionalProps?.[splitId]) {
            d.__additionalProps[splitId].forEach(additionalProp => {
              dataSeries[d.x][additionalProp.prop] = additionalProp.value;
            });
          }
        }
      }
    }

    let groupByKeyParser: CallableFunction;
    if (isTimeGrouping) {
      groupByKeyParser = parseInt;
    } else if (isXaxisNumeric) {
      groupByKeyParser = parseFloat;
    } else {
      groupByKeyParser = x => x;
    }
    const exportedData = Object.keys(dataSeries).map(groupByKey => ({
      ...dataSeries[groupByKey],
      /*
       * we are iterating over object keys here so the timestamps have been
       * converted to strings - so we have to put them back eventually
       */
      x: groupByKeyParser(groupByKey),
    }));

    // Add label for X axis
    exportHeader.x = this.selectedGroup.title;

    // Add labelProperty column if set
    if (this.opts.labelProperty) {
      exportHeader[this.opts.labelProperty] = 'Label';
    }

    // Add tooltip fields to export as well
    for (const field of this.opts.tooltip?.extend ?? []) {
      const fieldTitle = field.title;
      // Skip already added fields (including x-axis)
      if (Object.values(exportHeader).some(d => d == fieldTitle)) continue;
      exportHeader[field.id] = fieldTitle;
    }

    return {
      header: exportHeader,
      data: exportedData,
    };
  }

  public init(multiSeries: ChartSeries[], linesData: ChartStraightLine[]) {
    this.data = multiSeries;
    this.namesBySeries = {};

    // store series and lines
    this.multiSeries = multiSeries;
    this.straightLines = linesData;

    delete this.minY;
    delete this.maxY;
    this.traces = [];

    // calculate the minX and maxX from the series
    if (multiSeries.some(series => series.data.length)) {
      this.minX = minBy(multiSeries, d => d.minX)?.minX;
      this.maxX = maxBy(multiSeries, d => d.maxX)?.maxX;
    }

    /**
     * Case of temporal groupby and minX === maxX, i.e. single temporal value to display.
     * The scale of the xaxis is not handled well by plotly, we force it to be a +/- 3 samplingUnit, falling back to day
     * if there is no sampling at stake.
     * 3 samplingUnit is the minimal range so that only samplingUnit is shown on the xAxis range.
     * If minX is not equal to maxX, enable Plotly's automatic range calculation
     */
    if (ChartingHelpers.isTimeGrouping(this.selectedGroup)) {
      this.opts.xaxis ??= {};

      if (this.minX === this.maxX && this.minX != null) {
        const rangeUnit = this.selectedGroup.samplingUnit ?? 'day';
        const samplingInMs = DataHelpers.getDayjsDuration(3, rangeUnit).asMilliseconds();

        this.minX = this.minX - samplingInMs;
        this.maxX = this.maxX + samplingInMs;

        this.opts.xaxis.range = [this.minX, this.maxX];
        this.opts.xaxis.autorange = false;
      } else {
        this.opts.xaxis.autorange = true;
      }
    }

    this.cdRef.detectChanges();
    this.initSelectedTraces(multiSeries);
  }

  public buildSeriesTraces(series: ChartSeries, singleSeries: boolean): PlotData[] {
    const traces = [];
    const opts = this.opts?.tooltip ?? {};
    const yaxis = series.yaxis === 'y1' || !series.yaxis ? 'y1' : 'y2';
    const data = ChartOrdering.orderBars(
      series.data,
      this.selectedGroup,
      series.type as SeriesCommonTypeValues,
      this.chartTimeZone(),
      this.opts.xAxisType,
    );

    // Empty series
    if (!data.length) {
      return [];
    }

    // Error bars
    const minMaxErrorBar = series.errorBars && series.errorBars.type === 'data';
    const stackedErrorMin = [];
    const stackedErrorMax = [];

    // Custom data
    const customData: CustomTooltipData[] = [];
    const x = data.map(d => {
      let title = null;
      // Construct comments for each split from additionalProps & optional tooltip title
      const commentsForEachSplit = {};
      Object.keys(d.__additionalProps ?? {}).forEach(split => {
        const additionalProps = d.__additionalProps[split];
        if (opts.comment) {
          commentsForEachSplit[split] = ChartingHelpers.getAdditionalProp(additionalProps, opts.comment)?.value;
        }
        if (opts.title) {
          const tooltipTitle = ChartingHelpers.getAdditionalProp(additionalProps, opts.title);
          if (tooltipTitle) title = tooltipTitle.value;
        }
      });

      customData.push({
        fullX: d.x,
        title,
        xId: d.__xId,
        comments: commentsForEachSplit,
        additionalProps: d.__additionalProps,
      });
      return this.adaptValueToXAxisTick(d.x);
    });

    let i = 0;
    if (!(series.title in this.namesBySeries)) {
      this.namesBySeries[series.title] = [];
    }

    this.splitByColorService.resetColorsIfDuplicatedValues(series.header, this.selectedSplit);

    for (const split in series.header) {
      if (split === '__splitIds') continue;
      const splitTitle = series.header[split] as string;
      const lastSplit = Object.keys(series.header).indexOf(split) === Object.keys(series.header).length - 1;
      let yNonNullValuesCount = 0;

      // Error bars
      const splitMaxErrors = [];
      const splitMinErrors = [];

      const y = data.map((d, i) => {
        const value = d[split];

        /*
         * The error values calculation depends if we're in stacked mode or grouped mode
         * In grouped mode we just create an array with a point for the split value for each x in SplitMaxErrors and splitMinErrors
         * In stacked mode it's a little bit tricky because we don't want an error bar for each split
         * but an error bar only on the last non-null split corresponding to the sum of all split error values
         * So in this case we additionate the split error values in tracesErrorMax and tracesErrorMin
         */
        if (minMaxErrorBar) {
          const splitErrorMax = d.__errorMax?.[split] ? d.__errorMax[split] : 0;
          const splitErrorMin = d.__errorMin?.[split] ? d.__errorMin[split] : 0;
          splitMaxErrors.push(splitErrorMax);
          splitMinErrors.push(splitErrorMin);
          stackedErrorMax[i] = (stackedErrorMax[i] ?? 0) + splitErrorMax;
          stackedErrorMin[i] = (stackedErrorMin[i] ?? 0) + splitErrorMin;

          // groupMinErrors and groupMaxErrors are used to construct the tooltip values
          (this.groupMinErrors[d.x] ??= {})[split] = splitErrorMin;
          (this.groupMaxErrors[d.x] ??= {})[split] = splitErrorMax;

          // Update the total errors (last split wins)
          this.groupMinErrors[d.x]['__total'] = stackedErrorMin[i];
          this.groupMaxErrors[d.x]['__total'] = stackedErrorMax[i];
        }

        if (!value) {
          return series.connectGaps === 'tozero' ? 0 : value;
        }

        // Min/max values
        if (!this.minY || this.minY > value) {
          this.minY = value;
        }
        if (!this.maxY || this.maxY < value) {
          this.maxY = value;
          this.maxYAxis = yaxis;
        }
        yNonNullValuesCount++;
        return value;
      });

      const color = this.getColor(series, splitTitle, i);
      const name = this.getSeriesSplitName(series, singleSeries, splitTitle);

      if (!this.namesBySeries[series.title].includes(name)) {
        this.namesBySeries[series.title].push(name);
      }
      const id = this.getTraceId(series, split);
      const visible = this.isTraceVisible(id) || 'legendonly';
      const trace: PlotData = {
        id,
        /**
         * x represents the xaxis values, meaning that if we have multiple
         * series, we'll take the longest xaxis values
         */
        x,
        /**
         * y represents the yaxis values, meaning that if we have multiple
         * series, we'll take the longest yaxis values and fill with null
         * values the missing ones
         */
        y,
        name,
        /*
         * customData holds the full xaxis values
         * that we want to appear in the tooltip
         */
        customData,
        // every variable that we add here is available on the tooltip data
        split,
        splitTitle,
        series: series.title ?? '',
        type: series.type,
        /**
         * To determine mode we need both x and y length to see if we have
         * more that one values for the serie
         */
        mode: series.type === 'line'
          ? (series.lineMarkers || x.length === 1 || yNonNullValuesCount === 1 ? 'lines+markers' : 'lines')
          : (series.type === 'scatter' ? 'markers' : 'bars'),
        connectgaps: series.connectGaps === 'link',
        yaxis: series.yaxis || 'y1',
        // for now we assumer all y-axis share the same x-axis
        xaxis: 'x1',
        visible,
      };

      /**
       * Error bars
       * When in stacked mode, only add bars to the last (upper) split
       */
      if (series.errorBars) {
        if (series.errorBars.type === 'percent') {
          trace['error_y'] = {
            type: 'percent',
            value: series.errorBars.value,
            color: series.errorBars.color,
          };
        } else if (this.barMode === 'group' || lastSplit) {
          trace['error_y'] = {
            type: 'data',
            array: this.selectedMetrics[0].stacked ? stackedErrorMax : splitMaxErrors,
            arrayminus: this.selectedMetrics[0].stacked ? stackedErrorMin : splitMinErrors,
            symmetric: false,
            color: series.errorBars.color,
          };
        }
      }

      if (series.type === 'line') {
        const lineColor = series.lineOpacity ? ColorHelper.applyOpacity(color, series.lineOpacity) : color;
        // FIXME: Add an option to allow line.shape = 'spline' for average, trends & evolution
        trace.line = { color: lineColor, width: series.width };
        if (series.lineMarkers) {
          trace.marker = { size: series.lineMarkers, color };
        }
      } else if (series.type === 'bar') {
        trace.marker = { color };
      } else if (series.type === 'scatter') {
        const sizeScale = ChartingHelpers.getSizeScale(
          data.filter(d => d.__scatterSize).map(d => d.__scatterSize[split]),
        );
        trace.marker = {
          color: this.lightenBackgroundColor(color),
          line: { color, width: 1 },
          size: series.data.map(d => d.__scatterSize ? sizeScale(d.__scatterSize[split]) : 10),
        };
      }
      if (series.dashed) {
        trace.line.dash = 'dash';
      }
      if (series.stepped) {
        trace.line.shape = 'hv';
      }

      traces.push(trace);
      i++;
    }

    /*
     * Sort series splits
     * We always put the null split on top
     * If colors are specified, we sort according to the order of the colors
     */
    const orderObject = this.selectedSplit?.colors || series?.splitby?.colors;
    const splitOrder = orderObject ? orderObject.map(object => object.id) : null;
    return traces.sort((a, b) =>
      ChartingHelpers.sortTraces(
        a.splitTitle,
        b.splitTitle,
        this.splitNullTitle,
        splitOrder,
      )
    );
  }

  /**
   * Building straight line traces. Converting the specs of straight-lines to ploty-series
   */
  public buildLineTraces(lines: ChartStraightLine[]): PlotData[] {
    if (!lines || !lines.length) {
      return [];
    }

    const traces = [];
    /*
     * Go over all straight lines and create scatter points for them
     * Currently Plotly.js does not support legend for shapes, there is an open request for the solution
     * https://github.com/plotly/plotly.js/issues/98
     * In order to have something visually nice we add 2 points and connect them by a line
     */
    for (const line of lines) {
      const defaultLineStyle: { width: number; dot: 'none'; color?: Color } = {
        width: 1,
        dot: 'none',
      };
      if (line.color) {
        defaultLineStyle.color = line.color;
      }

      // the straight line upper marker is 10% above the max of the dataset of regular series
      const lineTrace: PlotData = {
        x: [line.point, line.point],
        y: [0, this.getHighestGraphPoint()],
        type: 'scatter',
        mode: 'lines',
        name: line.title,
        customData: { hoverType: NvGraph.straightLineHoverType },
        // we are making the point of the same size as the line
        marker: {
          size: 1,
        },
        line: {
          ...defaultLineStyle,
          ...(line.lineStyle || {}),
        },
        yaxis: this.maxYAxis,
        opacity: line.opacity,
      };

      if (line.color) {
        lineTrace.marker.color = line.color;
      }
      traces.push(lineTrace);
    }
    return traces;
  }

  private applyOpacity(color: Color, seriesType: string): Color {
    if (seriesType === 'bar') {
      return ColorHelper.applyBarOpacity(color);
    }
    return color;
  }

  private lightenBackgroundColor(color: Color): Color {
    return tinycolor(color).setAlpha(0.5).toRgbString() as Color;
  }

  public getColor(series: ChartSeries, split: string, i: number): Color {
    const possibleColorPaths: { entity: any; path: (string | number)[] }[] = [
      { entity: series, path: ['color'] },
      { entity: series, path: ['splitby', 'colorScale', i] },
      { entity: this.selectedSplit, path: ['colorScale', i] },
    ];

    for (const { entity, path } of possibleColorPaths) {
      const color = pathOr<Color>(null, path, entity);
      if (color !== null) {
        return this.applyOpacity(color, series.type);
      }
    }

    /**
     * We can't define a color path here the same way as above since selectedSplit.colors and series.splitby?.colors
     * are arrays of objects
     */

    const matchedColor = series.splitby?.colors?.find(color => color.id === split)
      ?? this.selectedSplit?.colors?.find(color => color.id === split);

    if (matchedColor) {
      return this.applyOpacity(matchedColor.fill, series.type);
    }

    const color = this.splitByColorService.getOrAssignColor(
      this.selectedSplit,
      split,
    );

    return this.applyOpacity(color.fill, series.type);
  }

  /**
   * Get the split name of the given series split according to the given context. \
   * It returns either the split name, the series name or the concatenation of both.
   *
   * @param series        The series
   * @param singleSeries  Whether the context has only one series
   * @param split         Ths split name
   * @param csvExport     Whether the context is a CSV export
   * @return              The split name in the given context
   */
  public getSeriesSplitName(series: ChartSeries, singleSeries: boolean, split: string): string {
    // Split if multi series or multiple values in series
    const singleSplit = singleSeries && Object.keys(series.header).length === 1;

    if (split && split !== 'All' && (singleSplit || singleSeries) && !series.useTitle) {
      return split;
    }

    if (series.title && (split === 'All' || singleSplit)) {
      return series.title;
    }

    if (this.duplicateSplitsPresents) {
      return series.title + ' - ' + split;
    }

    return split;
  }

  getAxisDticksData(axisSeries: PlotData[], minTicks: number, maxTicks: number) {
    let maxValue__original = 0;

    if (axisSeries[0].type === 'bar') {
      maxValue__original = this.getMaxTotalOfBars(axisSeries);
    } else {
      const seriesValues = [].concat(...axisSeries.map(trace => trace.y));
      maxValue__original = Math.max(...seriesValues.filter(v => v !== null && !isNaN(v)));
    }

    /*
     * We apply 5% margin to the max
     * If the max is null, we arbitrary take 1, otherwise log(0) will yield NaN
     */
    const maxValue = maxValue__original > 0 ? maxValue__original * 1.05 : 1;

    // Get the exponent of the number (n in number = a * 10^n)
    const exp = Math.floor(Math.log10(maxValue));

    const availableDticksFromPower = [
      (exp: number): number => 2 * Math.pow(10, exp - 1),
      (exp: number): number => 2.5 * Math.pow(10, exp - 1),
      (exp: number): number => 5 * Math.pow(10, exp - 1),
      (exp: number): number => Math.pow(10, exp),
      (exp: number): number => 2 * Math.pow(10, exp),
      (exp: number): number => 2.5 * Math.pow(10, exp),
      (exp: number): number => 5 * Math.pow(10, exp),
    ];

    // Dticks for each possibility
    const dticksData = availableDticksFromPower.map(fn => {
      const dtickValue = fn(exp);
      return {
        function: fn,
        dtick: dtickValue,
        ticks: Math.ceil(maxValue / dtickValue),
      };
    });

    const validDticks = dticksData.filter(dtick => dtick.ticks >= minTicks && dtick.ticks <= maxTicks);

    // Select the dtick yielding the maximum acceptable number of ticks
    const bestDtick = validDticks.filter(dtick => dtick.ticks = Math.max(...validDticks.map(tick => tick.ticks)))[0];

    // Only maxValue, dtick and dtickRatio are used atm. If we improve the logic, we might want to use the other parameters
    return {
      yaxis: axisSeries[0].yaxis,
      maxValue__original: maxValue__original,
      maxValue: maxValue,
      validDticks: dticksData,
      dtick: bestDtick.dtick,
      ticks: bestDtick.ticks,
      dtickRatio: maxValue / bestDtick.dtick,
    };
  }

  /**
   * Set the bar width for numeric/temporal data when possible to solve 2 issues:
   * 1) For sparse data, plotly enlarges the bars that end up covering several numbers/dates
   * 2) If there is a mix of traces and the bar trace has only one X value ,plotly will show very
   * thin bars all the following 3 issue provide images very similar to our issue:
   * https://stackoverflow.com/questions/67627406/how-to-set-the-width-of-a-group-of-bars-in-a-bar-graph
   * https://community.plotly.com/t/set-minimum-and-maximum-width-of-the-bar-in-barchart/18006
   * https://community.plotly.com/t/setting-width-of-bar-graphs/7143
   */
  protected setBarsWidthForAllTraces(): void {
    const axisFormatting = this.getXAxisFormat();
    for (const trace of this.traces) {
      // We only set the bar width for stacked bar charts as no issue was observed with group mode
      if (trace.type !== 'bar' || this.layout.barmode !== 'stack') {
        continue;
      }

      /*
       * To set the minimal width for dates we use the estimated avgTickLength. If we could not estimate the width of
       * the bars, we let plotly handle it.
       */
      if (axisFormatting.isTimeFormatting && axisFormatting.avgTickLength) {
        trace.width = axisFormatting.avgTickLength * 0.8;
      } /*
       * For plain numbers, we use the smallest distance between 2 bars for decimal scales (0.5, 1, 1.5, 2... ),
       * 1 for integer scales (1,2,3,4... / 4,8,12...)
       */
      else if (!axisFormatting.isTimeFormatting && this.opts.xAxisType === 'numeric') {
        const sortedXValues = [...trace.x as number[]].sort((a, b) => a - b);
        let minDistanceBetweenBars = 1;
        for (let i = 1; i < sortedXValues.length; i++) {
          minDistanceBetweenBars = Math.min(minDistanceBetweenBars, sortedXValues[i] - sortedXValues[i - 1]);
        }
        trace.width = minDistanceBetweenBars * 0.8;
      }
    }
  }

  /**
   * Update tooltip conf for quantile distributions
   *
   * @param {any}          point        Current point
   * @param {TooltipSeries} tooltipOpts  Series tooltip conf
   */
  protected override specificTooltip(point: RealPlotDatum, tooltipOpts: TooltipSeries): void {
    // Error bars
    if (tooltipOpts.showErrors) {
      const x = this.getPointCustomData(point).fullX ?? point.x;
      const split = point.data.name;
      tooltipOpts.errorMins[split] = ChartsFormatting.formatNumber(this.groupMinErrors[x][split]);
      tooltipOpts.errorMaxs[split] = ChartsFormatting.formatNumber(this.groupMaxErrors[x][split]);
      tooltipOpts.errorMins['__total'] = ChartsFormatting.formatNumber(this.groupMinErrors[x]['__total']);
      tooltipOpts.errorMaxs['__total'] = ChartsFormatting.formatNumber(this.groupMaxErrors[x]['__total']);
    }

    // Special modes
    if (this.settings.modesEnabled) {
      if (this.display.displayMode === 'quantiles') {
        tooltipOpts.splits.forEach((split, i) => {
          if (i === 0 || i === 4) split.color = ColorHelper.quantilesColors.q10;
          if (i === 1 || i === 3) split.color = ColorHelper.quantilesColors.q25;
          if (i === 2) split.color = ColorHelper.quantilesColors.median;
        });
        tooltipOpts.type = 'line';
      }
      if (this.display.displayMode === 'relevant') {
        tooltipOpts.splits.forEach(split => {
          if (split.title === 'Median' || split.title.startsWith('Overall trend')) {
            split.color = ColorHelper.quantilesColors.average;
          }
        });
      }
    }
  }

  /**
   * Resize x-axis height
   */
  private resizeXAxisMargin(): void {
    if (!this.layout || !this.layout.xaxis || !this.plotElement || this.layout.xaxis.automargin) return;

    const $graph = this.plotElement;
    // Resize E-W slider
    const $xaxis = $graph.querySelector<SVGGraphicsElement>('.xaxislayer-above');
    const ticksHeight = Math.ceil($xaxis.getBBox().height) + 10;
    if (this.layout.margin.b !== ticksHeight) {
      this.layout.margin.b = ticksHeight;
      this.plotlyRelayout({ 'margin.b': ticksHeight });
    }
  }

  /**
   * Resize x-axis slider (E-W)
   */
  private resizeXAxisSlider(): void {
    // Slider is not visible so there is nothing to do
    if (!this.plotElement?.querySelector('.rangeslider-container')) {
      return;
    }

    if (!this.layout || !this.layout.xaxis || !this.plotElement) return;

    const $graph = this.plotElement;
    // Resize E-W slider
    const $xaxis = $graph.querySelector<SVGGraphicsElement>('.xaxislayer-above');
    const $slider = $graph.querySelector('.draglayer .ewdrag');
    if ($xaxis && $slider) $slider.setAttribute('height', '' + $xaxis.getBBox().height);
    // Use initial range
    if (!this.initRange) {
      if (!this.layout.xaxis.range || !this.layout.xaxis.range.length) return;
      this.initRange = this.layout.xaxis.range.slice(0);
    }
    // Check range is not out of bound only for charts with discrete values (aka of type number)
    if (this.layout.xaxis.type !== 'date' && (typeof this.layout.xaxis.range[0] === 'number')) {
      let [min, max] = this.initRange;
      // When range slider is enabled, use data length instead
      if (this.settings.rangeSliderEnabled) {
        min = -.5;
        max = this.data[0].data.length - .5;
      }
      // Preserve current span
      const span = this.layout.xaxis.range[1] - this.layout.xaxis.range[0];
      if (this.layout.xaxis.range[0] < min || this.layout.xaxis.range[1] > max) {
        if (this.layout.xaxis.range[0] < min) {
          min = Math.max(this.layout.xaxis.range[0], min);
          max = Math.min(min + span, max);
        } else {
          max = Math.min(this.layout.xaxis.range[1], max);
          min = Math.max(max - span, min);
        }
        // Relayout only (faster)
        this.plotlyRelayout({ 'xaxis.range': [min, max] });
      }
    }
  }
}
