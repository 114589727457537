import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild,
  computed, input, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { Subject } from 'rxjs';
import { uniqBy } from 'lodash-es';

import { MultiFilter } from './multi-interface';
import { MultiFieldStyle, MultiOption } from '../helpers/types';
import { DataHelpers } from '../helpers/data-helpers';
import { PearlFormFieldComponent } from '../shared/pearl-components';

export class MultiHelper {
  public static SELECT_ALL_VALUE = -1;
  public static BLANK_OPTION_VALUE = '__blank';

  static get SELECT_ALL_OPTION(): MultiOption<null> {
    return {
      order: 0,
      title: 'Select all',
      value: MultiHelper.SELECT_ALL_VALUE,
      alreadyChosen: false,
    };
  }

  static get BLANK_OPTION(): MultiOption<null> {
    return {
      order: 0,
      title: '(Blanks)',
      value: MultiHelper.BLANK_OPTION_VALUE,
      alreadyChosen: false,
    };
  }

  /**
   * @description - return whether the search string is included in the option
   * When passed from url option value can either be string or number depending
   * on the way it was passed
   *
   * @param searchIn - array of options
   * @param searchFor - search string or number
   */
  public static includesStringOrNumber(searchIn: unknown[], searchFor: string | number): boolean {
    return searchIn.includes(searchFor) || searchIn.includes(searchFor.toString());
  }

  public static includesSelectAll(searchIn: unknown[]): boolean {
    return MultiHelper.includesStringOrNumber(searchIn, MultiHelper.SELECT_ALL_VALUE);
  }

  public static includesBlank(searchIn: unknown[]): boolean {
    return MultiHelper.includesStringOrNumber(searchIn, MultiHelper.BLANK_OPTION_VALUE);
  }
}

@Component({
  selector: 'spin-filters-multi',
  templateUrl: 'multi.html',
  styleUrl: 'multi.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatInputModule,
    FormsModule,
    PearlFormFieldComponent,
    MatAutocompleteModule,
    NgFor,
    NgClass,
    NgIf,
    MatCheckboxModule,
    MatIconModule,
  ],
})
export class MultiComponent implements MultiFilter, OnInit, AfterViewInit {
  @Output()
  public selected = new EventEmitter<MultiOption<any>[]>();
  @Output()
  public unselected = new EventEmitter<any>();

  public readonly small = input<boolean>(true);

  @Input()
  public fieldId = '';

  public placeholder = input<string>('Loading ...');

  @Input()
  public set style(style: MultiFieldStyle) {
    this.className = style === 'searchBar' ? 'field-search-bar' : 'simple-field-form';
  }

  private _isBlankOptionActivated = false;

  @Input({ required: false })
  public set isBlankOptionActivated(hasNullValue: boolean | undefined) {
    this._isBlankOptionActivated ||= hasNullValue;
    this.updateData$.next(
      this.options().filter(v =>
        v.value !== MultiHelper.SELECT_ALL_VALUE && v.value !== MultiHelper.BLANK_OPTION_VALUE
      ),
    );
  }

  @Input()
  public showSelectAll: boolean;

  @Input()
  public set data(options: MultiOption<unknown>[]) {
    this.updateData$.next(options);
  }

  @Input()
  public isEntity = false;

  @Input()
  public clearInputAfterSelection = false;

  @Input()
  public disabled = false;

  @ViewChild('input')
  $input: ElementRef;
  @ViewChild('input', { read: MatAutocompleteTrigger })
  $autoComplete: MatAutocompleteTrigger;

  private static DISPLAY_LIMIT = 40;
  private readonly options = signal<MultiOption<unknown>[]>([]);

  protected readonly _searchStr = signal('');
  protected readonly isOptionsDisplayLimited = signal<boolean>(false);
  protected readonly optionsDisplayedLimit = computed(() =>
    this.isOptionsDisplayLimited() ? this.options().length : MultiComponent.DISPLAY_LIMIT
  );

  public autocomplete = 'off';
  public selectAllChecked = false;
  public className: string;

  public readonly toggleAll$ = new Subject<void>();
  public readonly toggle$ = new Subject<{ payload: unknown[]; chosen?: boolean }>();
  public readonly updateData$ = new Subject<MultiOption<unknown>[]>();
  public readonly showMessage = computed(() =>
    this.isOptionsDisplayLimited() ? 'Show less options' : 'Show all options'
  );

  public filteredOptions = computed(() => {
    const filteredOptions = this.searchStr === '' ? this.options() : this.options().filter(el => {
      return el.title?.toString()
        .toLowerCase()
        .includes(this.searchStr.toLowerCase());
    });

    DataHelpers.sortExactMatch(filteredOptions, d => d.title.toString().toLowerCase(), this.searchStr);
    return filteredOptions.slice(0, this.optionsDisplayedLimit());
  });

  /**
   * isPartialSelection is true when neither all options are selected nor none of them,
   * i.e, we are in between select all and select none
   */
  public isPartialSelection = signal<boolean>(false);
  public hasUnavailableTags = true;

  /**
   * @description - Used to prevent the panel from being fixed when scrolling
   *
   * @param event - scroll event
   */
  public scrollEvent = (): void => {
    if (this.$autoComplete.panelOpen) {
      this.$autoComplete.updatePosition();
    }
  };

  ngOnInit(): void {
    /**
     * Add custom event on scroll
     */
    window.addEventListener('scroll', this.scrollEvent, true);

    /** Default to true. But the default value is set here, to be sure that the @Input value has been received. */
    this.showSelectAll ??= true;
  }

  constructor() {
    /**
     * @description - toggle action is used to toggle the selection of options
     * if no chosen parameter is passed, options already chosen attribute will be toggled
     *
     * @param payload - array of values to toggle
     * @param chosen - if true, the options will be selected, if false - unselected
     * @example
     * ```ts
     * // select options with values 1 and 2
     * this.toggle$.next({ payload: [1, 2], chosen: true });
     * // unselect options with values 1 and 2
     * this.toggle$.next({ payload: [1, 2], chosen: false });
     * ```
     */
    this.toggle$.pipe(takeUntilDestroyed()).subscribe(toggle => {
      if (MultiHelper.includesSelectAll(toggle.payload)) {
        this.toggleAll$.next();
        return;
      }

      this.options.update(options =>
        options
          .map(element =>
            MultiHelper.includesStringOrNumber(toggle.payload, element.value)
              ? { ...element, alreadyChosen: toggle?.chosen ?? !element.alreadyChosen }
              : element
          )
      );
    });

    /**
     * @description - toggleAll action is used to toggle the selection of all options
     */
    this.toggleAll$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.selectAllChecked = !this.selectAllChecked;

      this.options.update(options => options.map(element => ({ ...element, alreadyChosen: this.selectAllChecked })));

      this.isPartialSelection.set(false);
    });

    /**
     * @description - updateData action is used to update the list of options
     * @param data - array of options
     * @example
     * ```ts
     * this.updateData$.next([
     *  { id: 1, title: 'Option 1', value: 1, alreadyChosen: false },
     *  { id: 2, title: 'Option 2', value: 2, alreadyChosen: false },
     *  { id: 3, title: 'Option 3', value: 3, alreadyChosen: false },
     * ]);
     * ```
     */
    this.updateData$.pipe(takeUntilDestroyed()).subscribe(data => {
      const nonEmpty = data
        .filter(d => (d.title || ((d.title as any) === false)) && d.value || ((d.value) === false));

      const uniqueOptions = uniqBy(
        nonEmpty,
        (d: { value?: unknown; title?: unknown }) => d.value ?? d.title,
      );

      const noOptions = uniqueOptions.length === 0;

      if (this._isBlankOptionActivated) {
        uniqueOptions.unshift(MultiHelper.BLANK_OPTION);
      }

      if (this.showSelectAll && !this.isEntity) {
        uniqueOptions.unshift(MultiHelper.SELECT_ALL_OPTION);
      }

      this.options.update(options => {
        if (noOptions) return [];

        const selectedValues = options.filter(v => v.alreadyChosen).map(v => v.value);
        if (MultiHelper.includesSelectAll(selectedValues)) {
          this.selectAllChecked = true;
        }

        return uniqueOptions.map((el: MultiOption<unknown>) => ({
          ...el,
          alreadyChosen: MultiHelper.includesStringOrNumber(selectedValues, el.value),
        }));
      });
    });
  }

  public getNotChosenItems(): MultiOption<any>[] {
    return this.options().filter(v => !v.alreadyChosen);
  }

  public setSelectAllOnly(): void {
    this.options.update(options =>
      options.map(item => item.value === MultiHelper.SELECT_ALL_VALUE ? { ...item, alreadyChosen: true } : item)
    );

    this.selectAllChecked = true;
  }

  public get searchStr(): string {
    return this._searchStr();
  }

  public set searchStr(value: string) {
    if (typeof value !== 'string') {
      value = '';
    }

    this._searchStr.set(value ?? '');
  }

  public ngAfterViewInit(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const func = this.$autoComplete._handleKeydown;
    this.$autoComplete._handleKeydown = function(event): void {
      if (event.key === 'Enter' || event.key === 'Space') {
        return;
      }

      return func.bind(this)(event);
    };

    /*
     * This is an attempt to disable chrome autocomplete
     * the issue is discrebed here: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
     * as of 2021-06 chrome will still ingore automplete="off", but it respects
     * autocomplete="new-password" = but only if there are not too many of them
     * It looks that if we set autocomplete="new-password" on country and region fields
     * chrome won't try to fill them with addresses and it won't affect other fields
     * NOTE: that I have tried autocomplete="new-password" on all fields - the result was
     * very strange chrome filling all fields with some random data that was previously typed
     * into some fields.
     */
    if (this.placeholder() === 'Country' || this.placeholder() === 'Region') {
      this.autocomplete = 'new-password';
    }
  }

  public keyHandling(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Enter':
        this.onSelected(event, this.$autoComplete.activeOption?.value);
        break;
      case 'Escape':
      case 'Tab':
        this.clearInput();
        break;
      default:
        break;
    }
  }

  public toggleShow(event: KeyboardEvent | MouseEvent): void {
    this.isOptionsDisplayLimited.update(s => !s);
    event.preventDefault();
    event.stopPropagation();
  }

  public clear(): void {
    this.selectAllChecked = false;
    this.isPartialSelection.set(false);
    this.clearInput();
    this.options.update(options => {
      options.forEach(item => item.alreadyChosen = false);
      return options;
    });
  }

  public onSelected(event: MouseEvent | KeyboardEvent, element: string | MultiOption<unknown> | undefined): void {
    if (!element) {
      return;
    }

    if (typeof element === 'string') {
      if (element === 'toggleShow') {
        this.toggleShow(event);
      }
      return;
    }

    if (!this.showSelectAll) {
      this.$autoComplete.closePanel();
    }

    event.preventDefault();
    event.stopPropagation();
    this.handleElement(element);
  }

  public handleElement(chosenElement: MultiOption<unknown> | undefined): void {
    if (!chosenElement) {
      return;
    }

    if (chosenElement.alreadyChosen) {
      this.unselected.emit(chosenElement.value);
      return;
    }

    this.toggle$.next({ payload: [chosenElement.value] });
    this.selected.emit(
      chosenElement.value === MultiHelper.SELECT_ALL_VALUE
        ? this.options().filter(v => v.alreadyChosen)
        : [chosenElement],
    );

    if (this.clearInputAfterSelection) {
      this.clearInput();
    }
  }

  public trackByTitle(index: number, item: MultiOption<unknown>): string | number {
    return item.title ?? String(item.value) ?? index;
  }

  protected clearInput(): void {
    this.searchStr = '';
    (this.$input.nativeElement as HTMLElement).blur();
  }

  public getSelectAllValue(): number {
    return MultiHelper.SELECT_ALL_VALUE;
  }

  /**
   * When closing popup with all options selected, we reset the state
   * as if nothing was selected, for url size optimization amongst other
   * reasons
   */
  public onClose(): void {
    if (
      !this.isEntity
      && this.options().filter(v => v.alreadyChosen).length === this.options().length
      && !this.hasUnavailableTags
    ) {
      this.unselected.emit('reset');
    }
  }

  public getOptionsLength(): number {
    return this.options().length;
  }
}
