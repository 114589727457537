import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output, ViewChild,
  input } from '@angular/core';

import { FieldComponent } from '../filters/field';
import { EntityDefinition, EntityFieldDefinition, FieldSettings, FilterApplied, LinkedTableQuery, NumOrString,
  OptionValue, SomeEntity } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';

@Component({
  selector: 'multi-form-field',
  templateUrl: 'multi-form-field.html',
  styleUrls: ['multi-form-field.css'],
  standalone: true,
  imports: [FieldComponent],
})
export class MultiFormFieldComponent implements AfterViewInit {
  // Use a default field to not create error with fieldComponent
  public compatibleField: FieldSettings = { id: '0', filterType: 'entity', title: '', values: [] };
  public selectedValues: Array<string> = [];
  public dataLoader: DataLoader;
  private cdRef: ChangeDetectorRef;

  @ViewChild('field')
  public $field: FieldComponent;

  public readonly small = input<boolean>(true);

  @Input()
  label: string = '';
  @Input()
  readonly: boolean = false;
  @Input()
  editMode: boolean = true;
  @Input()
  disabled: boolean = false;
  @Input()
  parentField: EntityFieldDefinition;
  @Input()
  parentEntityDefinition: EntityDefinition;
  @Input()
  entity: SomeEntity;

  @Output()
  onchange = new EventEmitter<OptionValue[]>();
  @Output()
  onStartLoad = new EventEmitter();
  @Output()
  onEndLoad = new EventEmitter();

  constructor(injector: Injector) {
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  ngAfterViewInit() {
    this.onStartLoad.emit();
    this.setPossibleValues().then(() => {
      this.$field.update();
      this.setTags();
      this.cdRef.detectChanges();
      this.onEndLoad.emit();
    });
  }

  /** Return selected options object (with title and id) */
  public get selectedOptions(): OptionValue[] {
    return this.compatibleField.values.filter(value => this.selectedValues.includes(value.title));
  }

  private async setPossibleValues() {
    const query: LinkedTableQuery = {
      linkedEntity: this.parentField.class,
      parentEntity: this.parentEntityDefinition.class,

      /*
       * Id of the fields, suppose we navigate from contract to it's sources
       * then it will be sources
       */
      linkedCollection: this.parentField.id,
      forOptions: true,
    };

    const values = await this.dataLoader.getPossibleValues(query) as OptionValue[];

    this.compatibleField.id = this.parentField.id;
    this.compatibleField.title = this.label;
    this.compatibleField.values = values.map(value => {
      return { id: value.id, title: value.title, value: value.title, propOrder: value.title };
    });
    this.compatibleField.hideTags = this.parentField.hideTags;
  }

  public onChange(event: FilterApplied) {
    this.selectedValues = event.values;
    this.onchange.emit(this.selectedOptions);
  }

  public async setTags() {
    if (!this.compatibleField || !this.compatibleField.values || !this.compatibleField.values.length) {
      await this.setPossibleValues();
    }
    if (this.entity[this.parentField.id] && this.entity[this.parentField.id].length) {
      this.selectedValues = (this.entity[this.parentField.id] as string[])
        .map(item => {
          item = String(item);
          item = item.trim();
          const optionValue = this.compatibleField.values.find(val => val.id == Number(item));
          return optionValue.title;
        });
      this.$field.set(this.selectedValues);
    }
    this.cdRef.detectChanges();
  }

  public setValues(values: string[]) {
    this.selectedValues = values;
    this.$field.set(this.selectedValues, true);
  }
}
