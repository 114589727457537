import { Injectable } from '@angular/core';

import { ModeEnum } from '../../../helpers/types';

@Injectable({
  providedIn: 'root',
})
export class TableStateService {
  editingState: ModeEnum = ModeEnum.NORMAL;

  public row = null;
  public fn = null;

  setEditMode(row?: any, fn?: any): void {
    this.row = row;
    this.fn = fn;
    this.editingState = ModeEnum.EDIT;
  }

  setNormalMode(): void {
    this.row = null;
    this.fn = null;
    this.editingState = ModeEnum.NORMAL;
  }
}
