import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation,
  inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import dayjs, { Dayjs } from 'dayjs';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NouisliderComponent } from './ng2-nouislider-component';
import { NumberHelper } from '../helpers/number-helper';
import { DateHelper } from '../helpers/date-helper';
import { Era, FilterApplied, Interval, IntervalField, IntervalFilterApplied, ResolvedInterval } from '../helpers/types';
import { AppInfoService } from '../app/app-info-service';
import { DoubleDateComponent } from './doubledate';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { PearlButtonComponent, PearlDatepickerComponent, PearlDatepickerInput, PearlDatepickerToggleComponent,
  PearlFormFieldComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-filters-interval',
  templateUrl: 'interval.html',
  styleUrls: ['interval.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    NgClass,
    NouisliderComponent,
    PearlButtonComponent,
    DoubleDateComponent,
    SpinTooltipDirective,
    PearlDatepickerComponent,
    PearlDatepickerToggleComponent,
    PearlFormFieldComponent,
    PearlDatepickerInput,
    MatInput,
    MatFormFieldModule,
  ],
})
export class IntervalComponent implements AfterViewInit {
  private cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  @Input()
  field: IntervalField;
  @Input()
  selectPeriod: boolean = false;
  @Output()
  onchange = new EventEmitter<IntervalFilterApplied>();
  @Output()
  onreleased = new EventEmitter<IntervalFilterApplied>();

  @ViewChild(NouisliderComponent)
  private $slider: NouisliderComponent;
  @ViewChild('leftInput')
  $leftInput;
  @ViewChild('rightInput')
  $rightInput;
  @ViewChild('doubleDate')
  $doubleDate: DoubleDateComponent;

  private productAnalyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);
  private router: Router = inject(Router);

  constructor() {
    this.min = 0;
    this.max = 1;
    this.selectedInterval = [0, 1];
  }

  ngAfterViewInit(): void {
    if (this.$leftInput !== undefined && this.$rightInput !== undefined) {
      fromEvent(this.$leftInput.nativeElement, 'keyup').pipe(
        distinctUntilChanged(),
        debounceTime(1000),
      ).subscribe((event: any) => this.setLeftNumerical(Number(event.target.value)));

      fromEvent(this.$rightInput.nativeElement, 'keyup').pipe(
        distinctUntilChanged(),
        debounceTime(1000),
      ).subscribe((event: any) => this.setRightNumerical(Number(event.target.value)));
    }
    this.field.onlyConfigOptions = true;
  }

  /*
   * Internal & Range are stored as numerical values
   * Current Left & Right, Min and Max can be Date or Number, so that they can be easily used by DatePicker
   */
  public selectedInterval: number[] = [null, null]; // @View

  @Input()
  public enabled: boolean = false; // @View
  public constant: number = -1; // @View

  public _min: number;
  private _minDate: Dayjs;
  public _max: number;
  private _maxDate: Dayjs;

  private _left: number;
  private _leftDate: Dayjs;
  private _right: number;
  private _rightDate: Dayjs;

  public noMinMax: boolean;

  private floor(value: number): number {
    return NumberHelper.floor(value, this.field.precision);
  }

  private ceil(value: number): number {
    return NumberHelper.ceil(value, this.field.precision);
  }

  private appInfoService: AppInfoService = inject(AppInfoService);

  /**
   * Update is called by the sidebar after the populate.
   * It takes the value from field.interval and set's it as the range
   */
  public update(): void {
    if (this.field.interval == null) {
      if (!this.field.noValue) {
        // Only trigger error if values are already loaded
        console.warn('Update on interval field without data');
      }
      return;
    }

    const range: any[] = this.field.interval.slice(0);
    range[0] = this.floor(range[0]);
    range[1] = this.ceil(range[1]);

    this.noMinMax = this.min === this.max;

    // already populated
    if (
      (this.field.filterType === 'interval'
        && this.min != null && this.min === range[0]
        && this.max != null && this.max === range[1])
      || ((this.field.filterType === 'datetime' || this.field.filterType === 'date')
        && this.min != null && this.min.valueOf() === range[0]
        && this.max != null && this.max.valueOf() === range[1])
    ) {
      return;
    }

    // Invalid value
    if (isNaN(parseInt(range[0])) || isNaN(parseInt(range[1]))) {
      return;
    }

    this.setNewBounds(range);
    /*
     * after the populate we set the selected interval to the available range
     * but we don't enable the fitler
     */
    this.setFilter({ extent: range as Interval }, false, false);
  }

  /**
   * Sets the new bounds (min and max) values. The slider is bound to these values
   */
  public setNewBounds(range: number[]): void {
    this.min = range[0];
    this.max = range[1];
    this.noMinMax = this.min === this.max;
  }

  /**
   * Force interval refresh (on timezone change)
   */
  public refresh(): void {
    // Only way to force update
    this._leftDate = dayjs.utc(this._left).local();
    this._rightDate = dayjs.utc(this._right).local();
    this.cdRef.detectChanges();
  }

  public clear(fire: boolean): void {
    const fullInterval: Interval = [this._min, this._max];
    this.applyInterval(fullInterval, fire);
  }

  /** Reset to default value or clear if there is no default value. */
  public reset(fire: boolean = true): void {
    if (this.field.default == null) {
      this.clear(fire);
      return;
    }

    const defaultInterval = DateHelper.period({ era: this.field.default });
    this.applyInterval(defaultInterval, fire);
  }

  /**
   * Responsible for checking that given interval values are different and firing if the fire flag is set.
   */
  private applyInterval(interval: Interval, fire: boolean): void {
    if (interval.some(d => d === undefined)) {
      return;
    }

    this.selectedInterval = interval;
    /*
     * we need to force the fire because now the interval isn't enabled
     * so onchange wouldn't be emitted
     */
    if (fire) {
      this.fire(true, true);
    }
    this.noMinMax = this.min === this.max;
  }

  /**
   * Sets the interval, but accepts strings values as parameters,
   * because they might come from the URL
   */
  public setFilterStr(interval: string[], fire: boolean = true): void {
    const numberInterval = interval.map(d => Number(d + ''));
    this.setFilter({ extent: numberInterval as Interval }, true, fire);
  }

  /**
   * Sets the selected interval in the means of numerical values
   * the slider is bound to the interval so it will update
   */
  public setFilter(interval: ResolvedInterval, enableAutomatically = true, fire: boolean = true): void {
    if (!interval) {
      return;
    }
    const extent = interval.extent;
    this._left = this.floor(extent[0]);
    this._leftDate = dayjs.utc(extent[0]).local();
    this._right = this.ceil(extent[1]);
    this._rightDate = dayjs.utc(extent[1]).local();
    this.selectedInterval = extent;
    // update select dropdown if it exists
    if (this.$doubleDate) {
      this.$doubleDate.update();
      this.$doubleDate.setFilter(interval, false);
    }
    this.enabled = enableAutomatically;

    /*
     * Currently we don't listen the model changes. So after set the numerical filter
     * we need to manually emit an event to notify the interval change
     */
    if (this.enabled && fire) {
      this.fire(true);
    }
  }

  public ontoggle(): void {
    this.fire(true, true);

    this.appInfoService.userAction(this.field.id);
  }

  get min(): number | Dayjs {
    return this.field.filterType === 'datetime' || this.field.filterType === 'date'
      ? this._minDate
      : this._min;
  }

  set min(value) {
    this._min = DateHelper.numericalValue(value);
    this._minDate = dayjs.utc(value).local();
    this.noMinMax = this._min === this._max;
  }

  get max(): number | Dayjs {
    return this.field.filterType === 'datetime' || this.field.filterType === 'date'
      ? this._maxDate
      : this._max;
  }

  set max(value) {
    this._max = DateHelper.numericalValue(value);
    this._maxDate = dayjs.utc(value).local();
    this.noMinMax = this._min === this._max;
  }

  // update model values thanks to getters and setters
  get left(): number | Dayjs {
    if (this.field.filterType === 'datetime' || this.field.filterType === 'date') {
      return this._leftDate ?? null;
    } else {
      return this._left;
    }
  }

  set left(value: any) {
    let numericalValue = DateHelper.numericalValue(value);
    // if we type a value that is bigger than the right value, than we put the right value
    if (numericalValue > this._right) {
      numericalValue = this._right;
    }
    if (numericalValue < this._min) {
      numericalValue = this._min;
    }
    const bothNotDefined = isNaN(this._left) && isNaN(numericalValue);
    if (!bothNotDefined) {
      this.setLeft(numericalValue, true);
    }
  }

  /**
   * Sets the left value of the interval and the related variables.
   * This is dissociated from the actual setter function to be able to pass the `changeEnded` option.
   *
   * @param value           The left value to be set.
   * @param changeEnded     Whether the change is finished.
   *                        This is false when both left and right values are changed together.
   */
  private setLeft(value: any, changeEnded: boolean = true) {
    this._left = value;
    this._leftDate = dayjs.utc(this._left).local();
    this.selectedInterval = [this._left, this._right];
    this.cdRef.detectChanges();
    this.fire(changeEnded);
  }

  setLeftNumerical(value: number): void {
    let numericalValue = DateHelper.numericalValue(value);
    // if we type a value that is bigger than the right value, than we put the right value
    if (numericalValue > this._right) {
      numericalValue = this._right;
    }
    if (numericalValue < this._min) {
      numericalValue = this._min;
      this.$leftInput.nativeElement.value = numericalValue;
    }
    const bothNotDefined = isNaN(this._left) && isNaN(numericalValue);
    if (!bothNotDefined) {
      this._left = numericalValue;
      this.selectedInterval = [this._left, this._right];
      this.cdRef.detectChanges();
      this.fire(true);
    }
  }

  get right(): number | Dayjs {
    if (this.field.filterType === 'datetime' || this.field.filterType === 'date') {
      return this._rightDate ?? null;
    } else {
      return this._right;
    }
  }

  set right(value: any) {
    let numericalValue = DateHelper.numericalValue(value);
    // if we try to set a value that is smaller then what we set to the left, than we set the same value as the left
    if (numericalValue < this._left) {
      numericalValue = this._left;
    }
    if (numericalValue > this._max) {
      numericalValue = this._max;
    }

    const bothNotDefined = isNaN(this._right) && isNaN(numericalValue);
    if (this._right !== numericalValue && !bothNotDefined) {
      this.setRight(numericalValue, true);
    }
  }

  /**
   * Sets the right value of the interval and the related variables.
   * This is dissociated from the actual setter function to be able to pass the `changeEnded` option.
   *
   * @param value           The right value to be set.
   * @param changeEnded     Whether the change is finished.
   *                        This is false when both left and right values are changed together.
   */
  private setRight(value: any, fire: boolean = true): void {
    this._right = value;
    this._rightDate = dayjs.utc(this._right).local();
    this.selectedInterval = [this._left, this._right];
    this.cdRef.detectChanges();
    this.fire(fire);
  }

  setRightNumerical(value: number): void {
    let numericalValue = DateHelper.numericalValue(value);
    // if we try to set a value that is smaller then what we set to the left, than we set the same value as the left
    if (numericalValue < this._left) {
      numericalValue = this._left;
    }
    if (numericalValue > this._max) {
      numericalValue = this._max;
      this.$rightInput.nativeElement.value = numericalValue;
    }

    const bothNotDefined = isNaN(this._right) && isNaN(numericalValue);
    if (this._right !== numericalValue && !bothNotDefined) {
      this._right = numericalValue;
      this.selectedInterval = [this._left, this._right];
      this.cdRef.detectChanges();
      this.fire(true);
    }
  }

  /**
   * On interval change is received when the slidder changes the interval.
   * This method sets the interval left and right values.
   */
  public onInterval(interval: number[]): void {
    // we check if this is a real change of the interval
    if (this._left === interval[0] && this._right === interval[1]) {
      return;
    }
    this.appInfoService.userAction(this.field.id);

    this._left = interval[0];
    this._right = interval[1];
    this._leftDate = dayjs.utc(this._left).local();
    this._rightDate = dayjs.utc(this._right).local();

    /*
     * Change detection is necessary here, because datepickers are binded on left() and right()
     * properties, so they don't get notified - as we now ignore the mouse events in zonejs
     * that would do it otherwise
     */
    this.cdRef.detectChanges();
    this.fire(false);
    /*
     * as soon as the interval changes if there is a double-date, we will reset it
     * the double-date does not try to be in sync with the schedule interval
     */
    this.resetDoubleDate();
  }

  public onIntervalReleased(selectedEra?: Era): void {
    // when the interval is released, we send the latest interval applied
    const event = new IntervalFilterApplied({
      id: this.field.id,
      filterType: this.field.filterType,
      active: this.enabled,
      values: [this._left, this._right],
      propValue: this.field.propValue,
      changeEnded: true,
    });
    if (selectedEra) event.selectedEra = selectedEra;
    this.onreleased.emit(event);
  }

  private fire(changeEnded: boolean, reset: boolean = false): void {
    if (this.enabled || reset) {
      this.onchange.emit({
        id: this.field.id,
        filterType: this.field.filterType,
        active: this.enabled,
        values: [this._left, this._right],
        propValue: this.field.propValue,
        changeEnded: changeEnded,
      });
    }
  }

  public getStep(stepCap = 1): number {
    // Ensure max and min are set
    if (isNaN(this._min) || isNaN(this._max)) return stepCap;
    // Rounding the interval to the nearest multiple of 10
    const interval = Math.round((this._max - this._min) / 10) * 10;
    if (interval >= 1) {
      // The real interval is closer to 10
      return Math.min(stepCap, Math.pow(10, Math.floor(Math.log10(interval)) - 1));
    } // The real interval is closer to 0
    else {
      return Math.min(stepCap, Math.pow(10, Math.round(Math.log10(this._max - this._min)) - 1));
    }
  }

  public onLeftDateChanged($event: Dayjs): void {
    this.appInfoService.userAction(this.field.id);
    this.left = $event;
    this.resetDoubleDate();
  }

  public onRightDateChanged($event: Dayjs): void {
    this.appInfoService.userAction(this.field.id);
    this.right = $event;
    this.resetDoubleDate();
  }

  /**
   * This event handler is called when the interval's doubledate component emits a change.
   * We need to change both left and right bounds "together".
   */
  public onDoubleDateChange($event: FilterApplied): void {
    if ($event.values?.length === 2 && $event.values.every(v => v != null)) {
      // Update left and right bounds accordingly
      let leftValue = $event.values[0] < this._min ? this._min : $event.values[0];
      let rightValue = $event.values[1] > this._max ? this._max : $event.values[1];
      if (leftValue > rightValue) {
        leftValue = rightValue;
      } else if (rightValue < leftValue) {
        rightValue = leftValue;
      }

      // If the new left value is above the current right value, update the right value first
      if (leftValue > this.right) {
        this.setRight(rightValue, false);
        this.setLeft(leftValue, false);
      } else {
        this.setLeft(leftValue, false);
        this.setRight(rightValue, false);
      }

      this.productAnalyticsService.trackAction('scheduleSelectPeriodUpdated', {
        path: this.router.url,
        selectedPeriod: $event.selectedEra,
      });

      // Fire an interval released event
      this.onIntervalReleased($event.selectedEra);
    }
  }

  private resetDoubleDate(): void {
    if (this.$doubleDate) {
      this.$doubleDate.reset(false);

      // Remove input focus from DoubleDate Select
      this.$doubleDate.$preset._elementRef.nativeElement.blur();
    }
  }

  public shouldDisplaySlider(): boolean {
    return this.enabled && !isNaN(this._min) && !isNaN(this._max);
  }
}
