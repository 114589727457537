import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Host, Injector, Input, Output,
  ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault,
  NgTemplateOutlet } from '@angular/common';

import { merge } from 'lodash-es';

import { ActionEvent, AfterSave, Button, EntityFieldDefinition, Fieldset, LinkButton, ModeEnum, PageLinkSpec,
  TooltipSettings, TooltipShowingOption, TooltipTabTable } from '../helpers/types';
import { Config } from '../config/config';
import { DatabaseHelper } from '../database/database-helper';
import { textWidth } from '../helpers/d3-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { DateHelper } from '../helpers/date-helper';
import { NavigationHelper } from '../helpers/navigation-helper';
import { TimezoneService } from '../helpers/timezone.service';
import { PositionedTooltip } from './tooltip';
import { LinkData, SpinLinkComponent } from './spin-link';
import { ComponentHelper } from '../helpers/component.helper';
import { SafeHtmlPipe } from '../helpers/pipes';
import { FieldButtonComponent } from './field-button';
import { RawDataPoint } from '../graph/chart-types';
import { getChained } from '../data-loader/ref-data-provider';
import { AdditionalPropertyHighlight } from '../schedule/schedule-types';
import { UIService } from './services/ui.service';
import { DataHelpers, getPropKeyForNonFilterField } from '../helpers/data-helpers';
import { EntityDataAccessor } from '../database/entity-data-accessor';
import { LightEditFieldComponent } from '../database/light-edit-field.component';
import { PearlButtonLinkComponent } from './pearl-components';

@Component({
  selector: 'spin-tooltip',
  templateUrl: 'complex-tooltip.html',
  styleUrls: ['complex-tooltip.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TimezoneService }],
  standalone: true,
  imports: [
    NgStyle,
    NgIf,
    MatProgressSpinnerModule,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MatIconModule,
    PearlButtonLinkComponent,
    NgClass,
    MatTabsModule,
    NgFor,
    SpinLinkComponent,
    FieldButtonComponent,
    MatButtonModule,
    SafeHtmlPipe,
    LightEditFieldComponent,
    NgTemplateOutlet,
  ],
})
export class ComplexTooltipComponent extends PositionedTooltip {
  public buttons: Button[];
  public config: Config;
  public tabs: TooltipTabTable[];
  // map-v2: remove
  public mode: ModeEnum = ModeEnum.NORMAL;

  public values: RawDataPoint;
  public onHide: () => void;
  public shownOn: TooltipShowingOption = null; // keeps the way tooltip has been shown

  private dataLoader: DataLoader = null;

  public uiService = inject(UIService);

  public maxTextToolTipWidth: number = 330;
  public getChained = getChained;

  @Input()
  public preventOpen: boolean = false;

  @Host()
  public timezoneService: TimezoneService;

  @ViewChild('illustration')
  protected illustration: ElementRef<HTMLImageElement>;

  @Output()
  complexTooltipFieldUpdated = new EventEmitter<AfterSave>();

  constructor(elRef: ElementRef<HTMLElement>, cdRef: ChangeDetectorRef, injector: Injector) {
    super(elRef, cdRef);

    this.config = injector.get(Config);
    this.dataLoader = injector.get(DataLoader);

    // Bypass app default timezone and use local (until user selects another timezone)
    this.timezoneService = injector.get(TimezoneService);
    this.timezoneService.name = this.constructor.name;
    this.timezoneService.timezoneConfig = { timezone: 'local' };
    this.values = {};
  }

  public async show(
    opts: TooltipSettings,
    coords: [number, number],
    d: RawDataPoint,
    shownOn?: TooltipShowingOption,
  ): Promise<void> {
    /**
     * Map can access complex tooltips mode and changing its state
     * When we edit shapes on map we don't want the tooltip to show
     * on hover. Because it can make it impossible to edit a shape
     * with all the tooltip showing up
     */
    if (this.mode === ModeEnum.EDIT || this.preventOpen) {
      return;
    }

    /**
     * So that shapes are findable when zoom is far away, a static point is drawn alongside shapes.
     * A unique id is constructed for drawing update. It is constructed in the following way: "id__point".
     * One need to retrieve the parent shape which has the right id.
     */
    const id = getChained(d, 'id');
    /** MapV2: to remove */
    if (typeof id === 'string' && id.includes('__point')) {
      d = getChained<RawDataPoint>(d, 'parentShape');
    }

    // Callback function on hide
    this.resetTimeout();
    this.onHide = opts.onHide;

    // Keep showing method
    this.shownOn = shownOn;

    if (opts.url) {
      await this.requestAndAddDetails(opts, d);
    }

    this.initView(opts, d, coords);
  }

  private initView(opts: TooltipSettings, d: RawDataPoint, coords: [number, number]): void {
    this.resetTimeout();
    /*
     * Clean buttons & tabs state.
     * As tooltip can be load from previous instance
     */
    this.buttons = [];
    this.tabs = [];

    this.maxTextToolTipWidth = 330;

    this.title = opts.title;
    this.imageURIField = opts.imageURIField;
    // image was rendered before, and different than previous
    if (
      this.illustration && this.illustration.nativeElement.getAttribute('src') !== getChained(d, this.imageURIField)
    ) {
      // remove previous illustration while the current is loading
      this.illustration.nativeElement.removeAttribute('src');
      this.illustration.nativeElement.classList.add('loading');
    }
    this.values = d;

    const configuredTabs = opts.tabFieldsets;
    this.tabs = configuredTabs.filter(tab => this.tabNotEmpty(tab)) as TooltipTabTable[];

    if (opts.buttons) {
      this.buttons = opts.buttons;
    }

    this.tabs.forEach(tab => {
      tab.columnTextWidths = [[], []];
      tab.fields.forEach(field => {
        if (getChained(this.values, getPropKeyForNonFilterField(field)) === null) {
          /*
           * Value is null and would not be displayed. So we leave its title and value widths at 0 (init values).
           * Otherwise its title width is taken into account to determine the overall column title width.
           */
          return;
        }
        const formattedValue = this.getFormattedField(field);
        const isLink = field?.type?.toLocaleLowerCase()?.includes('link');
        const titleWidth = textWidth(field.title, 14);
        let valueWidth = isLink ? 30 : textWidth(`${formattedValue}`, 14);

        /**
         * We calculate max width of a field
         * textWidth is a method from d3 that takes our string and our fontsize as input
         * 55 is a constant corresponding to our margin + padding + border
         */
        let maxTextToolTipWidth = titleWidth + valueWidth + 55;
        if (maxTextToolTipWidth > 400) {
          maxTextToolTipWidth = 400;
        }

        if (maxTextToolTipWidth < 330) {
          maxTextToolTipWidth = 330;
        }

        if (maxTextToolTipWidth >= this.maxTextToolTipWidth) {
          this.maxTextToolTipWidth = maxTextToolTipWidth;
        }
        // big values will be clamped, but we still want them to take up a lot of space
        valueWidth = Math.min(valueWidth, this.maxTextToolTipWidth * 0.5);
        tab.columnTextWidths[0].push(titleWidth);
        tab.columnTextWidths[1].push(valueWidth);
      });
      [0, 1].forEach(i => tab.columnTextWidths[i].sort((a, b) => a - b));
      this.determineTitleColumnWidth(tab);
    });
    this.showTooltipAtPosition(coords, opts);

    // single tab => no tab header shown
    const tabHeader = this.elRef.nativeElement.querySelector<HTMLElement>('.mat-mdc-tab-header');
    tabHeader.style.setProperty('display', this.tabs?.length > 1 ? 'flex' : 'none');
    this.afterRender();
  }

  public determineTitleColumnWidth(tab: TooltipTabTable): void {
    const titleColWidths = tab.columnTextWidths[0];
    const maxTitleWidth = titleColWidths[titleColWidths.length - 1];
    const tableWidth = this.maxTextToolTipWidth - 50; // 30px of border, 20px of padding
    // if title col is wider than roughly one third of the tooltip...
    if (maxTitleWidth > tableWidth * 0.4) {
      const valueColWidths = tab.columnTextWidths[1];
      /*
       * ... find max value width, which subtracted the width of the tooltip,
       * gives a title width of roughly one third of the total width
       */
      let i = valueColWidths.length - 1;
      while (i > 0 && tableWidth - valueColWidths[i] < (tableWidth * 0.35)) {
        --i;
      }
      /*
       * special case: no cutoff was found, extend title as much as possible, done by auto layout.
       * we subtract 20px to give more importance to value column
       */
      tab.titleColWidth = i === valueColWidths.length - 1 ? null : (tableWidth - valueColWidths[i] - 20);
    } else {
      tab.titleColWidth = maxTitleWidth;
    }
  }

  public getTitle(): any {
    return getChained(this.values, this.title.name);
  }

  public onImgLoad(): void {
    this.illustration.nativeElement.classList.remove('loading');
  }

  private onExpandableTextClick = (event: MouseEvent): void => {
    if (event.target instanceof HTMLElement) {
      event.target.classList.toggle('expanded');
    }
  };

  // additional work to perform after tooltip render
  protected afterRender(): void {
    // wait for the tooltip to render
    setTimeout(() => {
      // Freeze title column width to prevent change on light edit field hover on firefox
      this.tabs.forEach((tab, tabIndex) => {
        if (tab.titleColWidth) {
          // Tab width is already set based on fields title and value lengths
          return;
        }

        const titleColElement = this.elRef.nativeElement.querySelectorAll<HTMLTableColElement>(
          '.spin-tooltip-tab-body table col.tooltip-title-col',
        )[tabIndex];

        if (titleColElement?.offsetWidth) {
          this.tabs[tabIndex].titleColWidth = titleColElement.offsetWidth;
        }
      });

      // Handle line clamping
      const clampedLines = this.nativeDom.querySelectorAll<HTMLElement>('.line-clamped');
      clampedLines.forEach(element => {
        // Reset expanded state
        element.classList.remove('expanded');

        // Checking isClamped using "node height + 1" to account for rendering difference between FF and chrome
        const isClamped = element.scrollHeight > (Math.max(element.offsetHeight, element.clientHeight) + 1);

        if (isClamped) {
          element.classList.add('expandable-text');
          element.addEventListener('click', this.onExpandableTextClick);
        } else {
          element.classList.remove('expandable-text');
          element.removeEventListener('click', this.onExpandableTextClick);
        }
      });
    }, 0);
  }

  public getTooltipWidth(): string {
    return `${this.maxTextToolTipWidth}px`;
  }

  /**
   * Get highlight icon description
   *
   * @param  {any}         d  Tooltip object values
   * @return {object|null}    Icon properties (icon name & color + style)
   */
  public getTitleIcon(d: RawDataPoint): { name: string; color: string; css: string } | null {
    const highlight = getChained<AdditionalPropertyHighlight>(d, '__highlight');
    if (!highlight?.label?.icon) {
      return null;
    }
    const label = highlight.label;
    return { name: label.icon, color: label.color || '', css: label.style || '' };
  }

  private async requestAndAddDetails(
    opts: TooltipSettings,
    d: RawDataPoint,
  ): Promise<RawDataPoint> {
    // Query the server for details of the given requested item
    const parameters = Object.entries(d).map(([name, value]: [string, string]) => ({ name, value }));
    const url = ComponentHelper.injectParametersInURL(opts.url, parameters);
    const data = await this.dataLoader.get<RawDataPoint | RawDataPoint[]>(url, { forceUpdate: true });

    /*
     * if the server provided any details, merge it with the tooltip data and return. We need to use merge of lodash
     * to keep the prototype of d
     */
    if (data instanceof Array) {
      if (data[0]) {
        return merge(d, data[0]);
      }
    } else if (data) {
      return merge(d, data);
    }

    // if server did not provide any details just return the values
    return d;
  }

  /**
   * Trigger when click on a page Link
   */
  public pageLinkClick(
    event: MouseEvent,
    field: EntityFieldDefinition,
    values: RawDataPoint,
    valueIndex?: number,
  ): void {
    const buttonEvent = field.openInNewTab
      ? NavigationHelper.getPageNewTabLinkButtonEvent(event, field, values, valueIndex)
      : NavigationHelper.getPageNavigationButtonEvent(event, field, values, valueIndex);

    if (!buttonEvent) {
      return;
    }
    this.onaction.emit(buttonEvent);
  }

  public onclick(event: MouseEvent, button: Button): void {
    /*
     * If user click on navigate button with ctrl or shift
     * we navigate in a new window with href so we don't construct tooltip button event
     */
    if (button.type === 'navigate' && (DatabaseHelper.isSpecialClick(event))) {
      return;
    }

    event.preventDefault();
    const buttonEvent = NavigationHelper.constructActionEvent({
      event,
      action: button,
      values: this.values,
    });
    // on smallDisplay the tooltip stays visible after the click, so we force to hide it
    this.forceHide();

    this.onaction.emit(buttonEvent);
  }

  /**
   * This function should test if the button must appear by looking at his parameters
   * @param button button that should appear which contain the parameters
   * @param datum data that must contain the information needed by the button
   */
  public static shouldShowButton(button: Button, datum: RawDataPoint, config: Config): boolean {
    if (button.type === 'openSelector' || button.type === 'openIntercom') {
      return true;
    }

    const hasData = datum != null;
    const buttonShown =
      // if there is a requirement it must be respected
      (!button.require || (hasData && getChained(datum, button.require)))
      // if layer exclude is specified it must be respected
      && (!button.excludeForLayers || (hasData && !button.excludeForLayers.includes(getChained(datum, 'layerId'))))
      // Then the datum must either own a value corresponding to the button key
      && (!button.key || (hasData && getChained(datum, button.key)))
      // Or containing value for each key in button.keys
      && (!button.keys || (hasData && button.keys.reduce((bool, key) => bool && !!getChained(datum, key), true)))
      /*
       * Or having at least one value for the parameters in button.params or not params at all
       * check if there is at least either one static parameter or either one parameter
       * in the data object (removing the prefix ":")
       */
      && (!button.params || !Object.values(button.params).length
        || Object.values(button.params).some(param => {
          return typeof param !== 'string' || !DataHelpers.isDataParam(param)
            || (hasData && !!getChained(datum, DataHelpers.extractDataParam(param)));
        }));
    if (buttonShown && button.type === 'navigate') {
      return config.checkRightsFromUrl(button.href);
    }

    return buttonShown;
  }

  public getTooltipItemStyle(key: string): { [klass: string]: string } {
    return this.isValueOverridden(key)
      ? { 'background-color': '#1DE9B6', 'padding-left': '0.5em', 'padding-right': '0.5em', 'border-radius': '3px' }
      : {};
  }

  public getPageLinkStyle(field: EntityFieldDefinition, valueIndex?: number): object {
    return NavigationHelper.getPageLinkStyle(field.href, this.config.urls, this.values, field.require, valueIndex);
  }

  public shouldShowField(field: EntityFieldDefinition): boolean {
    return DatabaseHelper.shouldShowField(field, this.values);
  }

  public getFormattedField(field: EntityFieldDefinition): any {
    return DatabaseHelper.formatFieldValue(field, this.values, null, false, this.timezoneService.timezone);
  }

  public isValueOverridden(key: string): boolean {
    const spinField = key + '_original';
    return key in this.values && spinField in this.values
      && getChained(this.values, key) !== getChained(this.values, spinField);
  }

  public getFormattedSpinergieValue(field: EntityFieldDefinition): string {
    return DatabaseHelper.getSpinergieValue(this.values, field);
  }

  public showButton(button: Button, datum: RawDataPoint): boolean {
    if (this.uiService.isSmallDisplay() && this.isEditButton(button)) {
      return false;
    }
    return ComplexTooltipComponent.shouldShowButton(button, datum, this.config);
  }

  public quarter(timestamp: number): string {
    return 'Q' + DateHelper.quarter(timestamp);
  }

  public dateTimeFormat(cell: number, format: string): string {
    return DateHelper.formatDatetime(cell, format, this.timezoneService.timezone);
  }

  public dateFormat(cell: number, format: string): string {
    return DateHelper.formatDate(cell, format);
  }

  public tabNotEmpty(tab: Fieldset): boolean {
    return tab.fields.some(field => getChained(this.values, getPropKeyForNonFilterField(field)) !== null);
  }

  public linkButtonData(button: Button, values: RawDataPoint): LinkData {
    return NavigationHelper.getLinkActionData(button as LinkButton, values);
  }

  public getNavigateLink(button: Button | EntityFieldDefinition): string {
    return NavigationHelper.getNavigateLink(button as LinkButton, this.values);
  }

  public getLinkTitle(field: EntityFieldDefinition): string {
    return NavigationHelper.getLinkTitle(field, this.values);
  }

  public getPageLinkList(field: EntityFieldDefinition): PageLinkSpec[] {
    return NavigationHelper.getPageLinkFromList(
      this.config,
      this.values,
      field,
      getChained(this.values, getPropKeyForNonFilterField(field)),
    );
  }

  public getExternalLink(field: EntityFieldDefinition): string {
    return this.values && field.url ? getChained(this.values, field.url) : null;
  }

  public onFieldButton(buttonEvent: ActionEvent): void {
    this.onaction.emit(buttonEvent);
  }

  /**
   * Callback before hiding
   */
  public override forceHide(): void {
    if (this.onHide) this.onHide();
    super.forceHide();
  }

  protected override resetTogglingOptions(): void {
    super.resetTogglingOptions();
    this.shownOn = null;
  }

  public isEditButton(button: Button): boolean {
    return ['editModal', 'addModal', 'editShape', 'editPosition'].includes(button.type);
  }

  protected isLightEditable(field: EntityFieldDefinition): boolean {
    return EntityDataAccessor.isLightEditable(this.config, field, this.values);
  }

  // Bubble field updated event
  protected onFieldUpdated(afterSave: AfterSave): void {
    this.complexTooltipFieldUpdated.emit(afterSave);
  }
}
