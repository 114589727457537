import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass } from '@angular/common';

import { NouisliderComponent } from './ng2-nouislider-component';
import { FieldSettings, FilterApplied, IntervalFilterApplied } from '../helpers/types';
import { AppInfoService } from '../app/app-info-service';

@Component({
  selector: 'spin-filters-number',
  templateUrl: 'number.html',
  styleUrls: ['number.scss', 'interval.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    MatCheckboxModule,
    FormsModule,
    MatTooltipModule,
    NouisliderComponent,
  ],
})
export class NumberComponent implements OnInit {
  @Input()
  field: FieldSettings;
  @Input()
  public enabled: boolean = false; // @View
  @Output()
  onchange = new EventEmitter<IntervalFilterApplied>();
  @Output()
  onreleased = new EventEmitter<FilterApplied>();

  private appInfoService: AppInfoService;

  public selectedValue: number = 1;
  public min: number = 0;
  public max: number = 1;
  public step: number = .1;

  constructor(injector: Injector) {
    this.appInfoService = injector.get(AppInfoService);
  }

  public ngOnInit(): void {
    this.min = this.field.min;
    this.max = this.field.max;
    this.step = this.field.step;
    this.selectedValue = this.field.default as number;
  }

  public clear(): void {
    this.selectedValue = this.max;
    this.enabled = false;
    this.fire(true, true);
  }

  /** Reset to default value or clear if there is no default value. */
  public reset(): void {
    if (this.field?.default) {
      this.selectedValue = this.field.default as number;
      this.enabled = true;
      this.fire(true, true);
    } else {
      this.clear();
    }
  }

  public update(): void {
    return;
  }

  /**
   * Sets the interval, but accepts strings values as parameters,
   * because they might come from the URL
   */
  public setFilter(filter: FilterApplied, fire: boolean): void {
    if (!filter || !filter.values || !filter.values.length) {
      return;
    }
    const numberValue = Number(filter.values[0]);
    this.selectedValue = numberValue;
    this.enabled = true;

    if (fire) {
      this.fire(true, false);
    }
  }

  public ontoggle(): void {
    this.appInfoService.userAction(this.field.id);
    this.fire(true, true);
  }

  /**
   * On interval change is received when the slidder changes the interval
   */
  public onslider(): void {
    this.appInfoService.userAction(this.field.id);
    this.fire(false);
  }

  public onSliderReleased(): void {
    this.fire(true);
  }

  private fire(changeEnded: boolean, reset: boolean = false): void {
    if (this.enabled || reset) {
      this.onchange.emit({
        id: this.field.id,
        filterType: this.field.filterType,
        active: this.enabled,
        values: [this.selectedValue],
        propValue: this.field.propValue,
        changeEnded: changeEnded,
      });
    }
  }
}
