<div [id]="componentSettings.id" class="spin-graph full-height plotly-multi">
  <div *ngIf="title && !opts.scheduleGraph" class="component-title">
    <div class="component-title-subtitle">
      <h3>{{ title }}</h3>
      <div class="component-subtitle" *ngIf="subtitle">{{ subtitle }}</div>
    </div>
    <description-button [description]="description"></description-button>
  </div>

  <spin-graph-options
    *ngIf="!opts.hideOptions"
    #chartOptions
    [graphId]="componentSettings.id"
    [opts]="opts"
    [noData]="noRawData"
    (showTable)="onTable()"
    (onchange)="onchange($event.chartParam, $event.value)"
    (xls)="onXls()"
    (ontoggletail)="ontoggletail($event)"
    (ondisplaymode)="ondisplaymode($event)"
    (onintervalchange)="onintervalchange($event)"
    (png)="onpng($event)"
    (onshowlabels)="onshowlabels($event)"
    [availableSelects]="availableSelects"
    [selects]="selects"
    [multiple]="allowMultiMetrics()"
    [messages]="messages"
    [display]="display"
    [intervalField]="intervalField"
    [minX]="minX"
    [maxX]="maxX"
    [labelCheckbox]="shouldShowLabelsCheckbox()"
    [showTableButton]="datatable"
    [showBarModeControls]="showBarModeControls"
    [dynamicGroupByState]="dynamicGroupByState">
  </spin-graph-options>

  <div class="loading-indicator" *ngIf="showLoading()">
    <mat-spinner />
  </div>

  <div
    *ngIf="hasSamplingIndicator()"
    class="sampling-indicator"
    [innerHTML]="formatSamplingText(selectedGroup.title)"></div>

  <ng-container *ngIf="!noDataToShow()">
    <mat-button-toggle-group [(ngModel)]="barMode" class="bar-mode-toggle" *ngIf="showBarModeControls">
      <mat-button-toggle [value]="'group'" (change)="onBarModeChange($event.value)">Grouped</mat-button-toggle>
      <mat-button-toggle [value]="'stack'" (change)="onBarModeChange($event.value)">Stacked</mat-button-toggle>
    </mat-button-toggle-group>
    <div
      #chartArea
      id="{{ plotlyChartId }}"
      data-testid="spin-plotly-multi"
      class="chart-area"
      [style]="{ display: noMetricsSelected() ? 'none' : '' }"
      [ngClass]="{ 'chart-hidden': loading }"></div>
  </ng-container>

  <div *ngIf="!noMetricsSelected() && noDataToShow()" class="no-data-wrapper">
    <span
      >No data available
      <span *ngIf="!noRawData && selects.metric && selects.metric.values.length > 1"> - Try changing metric</span>
    </span>
  </div>
  <div *ngIf="noMetricsSelected()" class="no-data-wrapper">
    <span>No metrics selected - Please select a metric</span>
  </div>
</div>

<chart-tooltip (navigate)="navigateToPage($event)"> </chart-tooltip>
