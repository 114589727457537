<div class="graph-options">
  <!-- We used to listen to `selectedTabChange` event but it was causing infinite loop.
    -- I found the fix by going through Angular issues https://github.com/angular/components/issues/24096
    -- even though it's not a 'rapid' tab change -->
  <mat-tab-group
    class="chart-tabs"
    *ngIf="tabs && tabs.length"
    (selectedIndexChange)="indexChanged($event)"
    [selectedIndex]="selectedIndex">
    <ng-container *ngFor="let tab of tabs; let i = index">
      <mat-tab [label]="tab.title">
        <ng-template mat-tab-label>
          <div>
            <span> {{ tab.title }}</span>
          </div>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
  <div class="graph-options-content">
    <div class="graph-selects" *ngIf="!opts.hideSelects && !uiService.isSmallDisplay(); else margin">
      <ng-container *ngFor="let select of availableSelects">
        <!-- Display currently stores not onlu string values but also the "interval"
          -- in order to fix the bellow $any() call we would have to split the object -->
        <spin-filters-select
          *ngIf="selects[select] && selects[select].values?.length"
          (onchange)="onChange(select, $event)"
          [id]="getSelectId(select)"
          [multiple]="select == 'metric' && multiple"
          [message]="messages[select]"
          [value]="$any(display[select])"
          [field]="selects[select]"
          [minSelectable]="getMinSelectable(select)">
        </spin-filters-select>
      </ng-container>
      <div *ngIf="opts.enableRangeSlider" class="spin-checkbox">
        <mat-checkbox
          (change)="toggleTail($event.checked)"
          [checked]="display.showTail || !opts.enableToggleTail"
          [matTooltip]="display.aggregateTooltip"
          matTooltipPosition="above"
          [disabled]="!opts.enableToggleTail">
          Show all
        </mat-checkbox>
        <span class="caption">{{ display.aggregateDetails }}</span>
      </div>
      <div *ngIf="opts.enableModes" class="spin-checkbox">
        <mat-checkbox
          (change)="toggleMode($event.checked)"
          [checked]="display.displayMode == 'all'"
          [matTooltip]="display.aggregateTooltip"
          matTooltipPosition="above">
          Show all
        </mat-checkbox>
        <span class="caption">{{ display.aggregateDetails }}</span>
      </div>
      <div *ngIf="labelCheckbox">
        <mat-checkbox (change)="showLabels($event.checked)" [ngModel]="showLabel"> Show labels </mat-checkbox>
      </div>
    </div>
    <div class="graph-selects-menu" *ngIf="!opts.hideSelects && uiService.isSmallDisplay()">
      <spin-filters-menu
        [selectsgroup]="availableSelects"
        [selects]="selects"
        [display]="display"
        [multiple]="multiple"
        (onchange)="onChange($event.chartParam, $event.value)">
      </spin-filters-menu>
    </div>
    <ng-template #margin>
      <div class="left-margin"></div>
    </ng-template>
    <spin-filters-interval
      class="chart-interval"
      *ngIf="
        intervalField &&
        (intervalField.filterType == 'date' || intervalField.filterType == 'datetime') &&
        !opts.hideInterval
      "
      [ngClass]="{
        'interval-hidden': (noData && !intervalHasValues()) || (intervalField && intervalField.fixedInterval),
      }"
      #chartInterval
      [field]="intervalField"
      [selectPeriod]="intervalField.selectPeriod"
      (onchange)="onChartIntervalChange($event, $event.changeEnded)"
      (onreleased)="onChartIntervalChange($event, true)"
      [enabled]="true">
    </spin-filters-interval>

    <div class="graph-buttons" *ngIf="!opts.hideButtons">
      <spin-filters-doubledate
        class="chart-doubledate"
        [field]="intervalField"
        #chartDoubleDate
        *ngIf="
          intervalField &&
          (intervalField.filterType == 'doubledate' || intervalField.filterType == 'intersection') &&
          !opts.hideInterval
        "
        (onchange)="onChartIntervalChange($event, true)"
        [ngClass]="{ 'doubledate-hidden': intervalField && intervalField.fixedInterval }">
      </spin-filters-doubledate>
      <pearl-button
        *ngIf="showTableButton"
        type="icon"
        icon="show_data_table"
        matTooltip="Show data table"
        matTooltipPosition="above"
        (click)="onShowTable()" />

      <pearl-button
        type="icon"
        icon="download_png"
        matTooltip="Download PNG"
        matTooltipPosition="above"
        (click)="onpng($event)" />

      <span [matTooltip]="config.getXlsxDownloadTooltip()" matTooltipPosition="above">
        <pearl-button
          type="icon"
          icon="download"
          [disabled]="config.trialModeDownloadsDisabled"
          (click)="onXls($event)" />
      </span>
    </div>
  </div>
</div>
