import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf } from '@angular/common';

import { cloneDeep } from 'lodash-es';

import { DatabaseHelper } from '../database/database-helper';
import { EntityDataAccessor } from '../database/entity-data-accessor';
import { EntityTableComponent } from '../database/entity-table';
import { FilterHelper } from '../filters/filter-helper';
import { EntityTableComponentSettings } from '../helpers/config-types';
import { AfterSave, ComponentStateOptions, EntityDefinition, EntityInformation, ExportConfig, Fieldset, LayerFilter,
  RefreshType, SomeEntity, SomeEntityChainable, SortDirection, TableType, UpdateEventType } from '../helpers/types';
import { TimezoneService } from '../helpers/timezone.service';
import { WrapperComponent } from './component-wrapper';
import { ComponentHelper } from '../helpers/component.helper';
import { DescriptionButtonComponent } from '../shared/description-button';
import { DoubleDateComponent } from '../filters';
import { ChartingHelpers } from '../graph/charting-helpers';
import { RawDataPoint } from '../graph/chart-types';

const ADD_TO_DATA_EVENT_TYPES = [UpdateEventType.New, UpdateEventType.InlineNew, UpdateEventType.InlineDuplicate];

/**
 * Table wrapper
 */
@Component({
  selector: 'entity-table-wrapper',
  templateUrl: './entity-table-wrapper.html',
  styleUrls: ['entity-table-wrapper.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TimezoneService }],
  standalone: true,
  imports: [
    NgIf,
    DescriptionButtonComponent,
    MatProgressSpinnerModule,
    EntityTableComponent,
    DoubleDateComponent,
  ],
})
export class EntityTableWrapperComponent extends WrapperComponent<EntityTableComponentSettings> {
  @ViewChild(EntityTableComponent, { static: false })
  $table: EntityTableComponent;

  public header: string;
  public description: string;
  public editMode: boolean;
  public canChooseColumns: boolean;
  public canChooseRows: boolean;
  public noExport: boolean;

  private tableType: TableType;
  private entityName: string;
  private nonFilteredData: SomeEntity[] = [];
  private data: SomeEntity[] = [];
  private definition: EntityDefinition;

  @Input()
  public subTitle: string;

  /**
   * Notifies the parent that this component would like to open entity details
   */
  @Output()
  openEntityRequest = new EventEmitter<EntityInformation>();
  @Output()
  onrowselected = new EventEmitter<any>();
  @Output()
  onselectionchange = new EventEmitter<SomeEntity[]>();
  @Output()
  showDescriptionColumnChanged = new EventEmitter<boolean>();

  /**
   * On attach
   */
  public override ngOnAttach(): void {
    // Watch timezone change
    this.sink$.push(this.timezoneService.onChange.subscribe(() => {
      // console.info("Redraw table", this.componentSettings.title, this.componentTimezone());
      this.data = DatabaseHelper.removeFormattedFieldsEntities(this.data);
      this.$table.update();
    }));
  }

  public viewInitialization(): void {
    this.tableType = this.componentSettings.tableType;
    this.entityName = this.componentSettings.entity;
    this.selectFilter = this.componentSettings.selectFilter;
    // all entity tables are in edit mode by default, maybe subject to change.
    this.editMode = this.tableType === 'entity';
  }

  /* * Getters/setters * */

  // only entity tables are navigable and clickable
  public get tableInteraction(): boolean {
    return this.tableType === 'entity';
  }

  override get componentStateOptions(): ComponentStateOptions {
    const stateOptions = super.componentStateOptions;
    // Only those options when applicable, to not clutter URL
    if (this.canChooseColumns) {
      stateOptions.filteredColumns = this.$table.visibleColumns.map(c => c.id);
      stateOptions.showDescriptionColumn = this.$table.showDescriptionColumn;
    }
    return stateOptions;
  }

  /* * Public * */

  /**
   * Subscribes to the requests of entity-table and bubbles them up to entity-detail
   */
  public bubbleOpenEntityRequest(info: EntityInformation) {
    this.openEntityRequest.emit(info);
  }

  public async reloadData(afterSave: AfterSave = null, refreshType: RefreshType = RefreshType.Default): Promise<void> {
    /*
     * wait for the config to be loading before trying to load the data
     * we need to know which table type we are loading before we can actually load it
     */
    if (!this.componentSettings || !this.tableType) {
      return;
    }

    this._loading = true;
    this.cdRef.detectChanges();

    // we will load the definition, only if it is not already loaded
    await this.getDefinition();
    this.cdRef.detectChanges(); // So that column names are shown when loaded
    /*
     * if this component does neither use the standard for data entry
     * nor does it have a custom endpoint to call it means we don't
     * have to reload any data. You can see discussion on
     * https://github.com/spinergie/spinapp/pull/1475
     */
    if (this.tableType !== 'entity' && !this.componentSettings.endpoint) {
      this._loading = false;
      this.cdRef.detectChanges();
      return;
    }

    // load and store the raw data if necessary
    const shouldGetValues = this.componentSettings.endpointType === 'heavy-custom'
      || !(this.nonFilteredData?.length > 0)
      || refreshType === RefreshType.Full;
    if (shouldGetValues) {
      this.nonFilteredData = this.tableType === 'entity'
        ? await this.getValuesForEntity()
        : await this.getValuesForQuery();
    }

    this.setTableTabsFromData(this.nonFilteredData);

    if (afterSave != null && !afterSave.reloadAllLayers) {
      this.afterEntitySave(afterSave);
    } else {
      this.filterAndSetDataOnTable();
    }

    // We should repopulate side filters on full refresh
    if (refreshType === RefreshType.Full) {
      /**
       * In case of heavy & heavy-custom endpoints, populate url is responsible to populate filters once.
       */
      if (!ChartingHelpers.isHeavyOrHeavyCustom(this.componentSettings.endpointType)) {
        /*
         * we use non-filtered table data to populate page filters
         * so that even if a page is loaded with sidebar-filters applied all the dataset will be used to populate
         * (bookmark, persistent filter or simply reload)
         */
        this.onDataReceived.emit({
          componentId: this.componentSettings.id,
          data: this.nonFilteredData,
        });
      }
    }

    this._loading = false;
    this.cdRef.detectChanges();
  }

  public emitOnExport(data: SomeEntity[]) {
    const exportConfig: ExportConfig = {
      definition: this.definition,
      filename: this.componentSettings.filename ?? this.header,
      layerId: null,
    };

    // If only a part of table columns are visible we only export these columns
    if (this.$table && this.$table.visibleColumns) {
      this.definition.fields = this.$table.visibleColumns;
    }

    const exportedData = cloneDeep(data);

    this.onexport.emit({
      config: exportConfig,
      exportData: {
        data: exportedData,
        trackingInfo: { exportSource: 'table', componentTitle: exportConfig.filename },
      },
    });
  }

  /**
   * This handle is executed if and entity was saved
   */
  public afterEntitySave = (afterSaveData: AfterSave) => {
    const entity = afterSaveData.entity;
    const type = afterSaveData.eventType;

    /*
     * if this table is of type entity (it mirrors an entity in the DB)
     * and the save action was linked to a different entity, then we don't perform
     * any specific actions, except for deletion (it could be a linked entity)
     */
    if (this.tableType == 'entity' && this.entityName != afterSaveData.entityName && type != UpdateEventType.Delete) {
      return;
    }

    // find the updated item in the table and update the values
    if (type == UpdateEventType.Update || type == UpdateEventType.InlineUpdate) {
      /*
       * we have 2 different situations, if this is standard entity update in entity table
       * we can update the data directly with values that we have in afterSave (we have the whole saved entity)
       * but if this is a query table, we have to find the updated entity inside the loaded non-filtered data
       */
      if (this.tableType == 'entity') {
        const foundItem = EntityDataAccessor.findById(this.data, this.definition, afterSaveData.idToReload);

        /*
         * either we have found the item that should be updated and we will update it
         * or we will add it manually to the table, as it has been added (probably)
         */
        if (foundItem) {
          EntityDataAccessor.updateEntityData(foundItem, entity);
        }
      } /*
       * entity was saved, getValuesForQuery was called and we have non-filtered raw values updated
       * now we have to find the item inside freshly loaded data from endpoint and also find the original
       * item which is bound in the table
       */
      else if (this.tableType == 'query') {
        const foundItemInTable = EntityDataAccessor.findById(this.data, this.definition, afterSaveData.idToReload);
        const foundFreshItemInNonFilteredData = EntityDataAccessor.findById(
          this.nonFilteredData,
          this.definition,
          afterSaveData.idToReload,
        );

        /*
         * either we have found the item that should be updated and we will update it
         * or we will add it manually to the table, as it has been added (probably)
         */
        if (foundItemInTable && foundFreshItemInNonFilteredData) {
          EntityDataAccessor.updateEntityData(foundItemInTable, foundFreshItemInNonFilteredData);
        }
      }
    }

    /*
     * If this is an addition of new item and the table is of type entity (directly entity an entity in the DB)
     * then we can just add the result to the table (because the result of entity save the entity). We also add it in
     * the non filtered data array to not loose it should we refresh the table.
     * Note that inline creations and duplicates must NOT be ignored - the are handled by the table directly but would
     * be discarded on refresh if they weren't not saved here!
     */
    if (ADD_TO_DATA_EVENT_TYPES.includes(type) && this.tableType === 'entity') {
      this.nonFilteredData.push(entity);
      this.data.push(entity);
    }

    /**
     * If this is a delete action, we need to reload everything because it could either be the deleted entity type or
     * a linked entity. We need to take the deletion into account in the non filtered data array: deletions won't
     * reflect in the table otherwise and inline deletion would be lost on refresh.
     */
    if (type === UpdateEventType.Delete || type === UpdateEventType.InlineDelete) {
      this.nonFilteredData = this.nonFilteredData.filter(entity => entity.id !== afterSaveData.modifiedEntityId);
      this.filterAndSetDataOnTable();
    }

    /*
     * If this is addition to a table of type query, we can't just append the result. The result does not necessary
     * have the same structure, we have to reload the endpoint and data
     */
    if (type === UpdateEventType.New && this.tableType === 'query') {
      this.filterAndSetDataOnTable();
    }

    this.cdRef.detectChanges();
    this.$table.update();
  };

  /*
   * This function sets the definition for tables before calling setData. This is done by the reloadData method
   * too, which was called in the viewInitialization method. But since we removed this call, using setData without
   * anything prior would result in a failure as the table was lacking its definition. This can occur when giving data
   * to a table modal through instantiation, or when fetching data from a series and setting it into a table for
   * instance.
   */
  public initDefinitionAndSetData(data: SomeEntityChainable[]): void {
    this.getDefinition().then(() => {
      this.setData(data);
      this._loading = false;
    });
  }

  private setData(data: SomeEntityChainable[]): void {
    const sorted = DatabaseHelper.sortByDefinition(data, this.definition);
    this.data = sorted;
    this.noRawData = this.data.length === 0;
    this.$table.setData(sorted);
    this.cdRef.detectChanges();
  }

  public apply(filters: LayerFilter): SomeEntity[] {
    const filtersToApply = cloneDeep(filters);
    const filteredData = this.data.filter(row => FilterHelper.filter(filtersToApply, row));
    this.$table.setData(filteredData);
    this.cdRef.detectChanges();
    return filteredData;
  }

  public async getDefinition(): Promise<void> {
    /*
     * once the definition is loaded we don't have to load it again
     * except in case of dynamic config in which case config might have
     * change
     */
    if (this.definition && !this.dynamicConfigHasChanged) {
      return;
    }

    if (this.tableType === 'entity') {
      this.definition = await this.dataLoader.getTableDefinition(this.entityName);
      this.canChooseColumns = this.definition.generalInfo.canChooseColumns;
      this.noExport = !this.definition.generalInfo.exportCsv;
    } else {
      this.definition = this.getTableDefinitionFromConfig();
      this.canChooseColumns = this.componentSettings.canChooseColumns;
      this.noExport = this.componentSettings.noExport;
    }

    /**
     * Fallback values (if null or undefined) for
     * - canChooseColumns: true if both autoColumnsBasedOnData and hideHeader are falsy, false otherwise
     * - noExport: same as hideHeader
     */
    this.canChooseColumns ??= !this.componentSettings.autoColumnsBasedOnData && !this.componentSettings.hideHeader;
    this.noExport ??= this.componentSettings.hideHeader;
    this.canChooseRows = this.componentSettings.canChooseRows;
    this.cdRef.detectChanges();

    this.$table.init({
      canChooseColumns: this.canChooseColumns,
      canChooseRows: this.canChooseRows,
      noExport: this.noExport,
      autoColumnsBasedOnData: this.componentSettings.autoColumnsBasedOnData,
    });
    this.$table.setDefinition(this.definition);
    this.header = this.definition.header;
  }

  public filterAndSetDataOnTable(): void {
    // Can't refresh yet entity table wrapper hasn't already load data
    if (!this.nonFilteredData) {
      return;
    }
    // Custom heavy means data is displayed as-is
    if (this.isHeavyCustom) {
      return this.setData(this.nonFilteredData);
    }
    const filteredData = this.lightFilteringOfDataSet(this.nonFilteredData);
    this.setData(filteredData);
  }

  public override async refresh(refreshType: RefreshType): Promise<void> {
    await super.refresh(refreshType);
    await this.reloadData(null, refreshType);
    this.dynamicConfigHasChanged = false;
  }

  public onColumnsFiltered(): void {
    this.emitComponentSelect();
  }

  public override updateDisplayOptions(componentState: ComponentStateOptions): void {
    super.updateDisplayOptions(componentState);
    if (!componentState || !this.$table) {
      return;
    }

    /**
     * This call is required because it sets a value for `canChooseColumns`. It is also called in reloadData, which was
     * called at init, but it isn't anymore, so in the component initialisation, the visible columns were never set
     * as this.canChooseColumns was always undefined
     */
    this.getDefinition();
    if (componentState.filteredColumns !== undefined && this.canChooseColumns) {
      this.$table.setVisibleColumns(componentState.filteredColumns);
    }

    this.$table.showDescriptionColumn = componentState.showDescriptionColumn ?? false;
  }

  public emitRowSelected(rowSelectedId: any) {
    this.onrowselected.emit(rowSelectedId);
  }

  /**
   * Determine table tabs from data and select the "selected" tab according to the display options
   */
  public setTableTabsFromData(data: RawDataPoint[]) {
    const tableTabs = ComponentHelper.getComponentTabs(data, this.componentSettings.selectFilter);
    this.$table.tabs = tableTabs;
    const tabIndex = ComponentHelper.getIndexFromTabValue(
      {
        value: this.$table.tabFilterSelected,
        tabs: tableTabs,
        tabFilterMetric: this.componentSettings.selectFilter,
      },
    );
    const selectedTab = tableTabs ? tableTabs[tabIndex] : null;
    this.$table.setSelectedIndex(tabIndex);
    /*
     * If we have tabs but we don't have a selected tabFilterSelect
     * We will init tabFilterSelected with the value of the default tabIndex
     * Then we init the component filter state
     * Same if the new tabValue doesn't match the old one (happen when the tabs change after we applied a filter
     * for example)
     */
    if (
      tableTabs && tableTabs.length
      && (selectedTab?.value
        && (!this.$table.tabFilterSelected || this.$table.tabFilterSelected != selectedTab.value))
    ) {
      this.$table.tabFilterSelected = selectedTab.value;
      this.ownFiltersState[this.componentSettings.selectFilter.prop] = [selectedTab.value];
    }
  }

  public tableTabChanged(tabFilter: any) {
    const tabFilterConfig = this.componentSettings.selectFilter;
    if (tabFilter) {
      this.ownFiltersState[tabFilterConfig.prop] = [tabFilter];
    } else {
      delete this.ownFiltersState[tabFilterConfig.prop];
    }
    this.filterAndSetDataOnTable();
  }

  public onShowDescriptionColumnChanged(): void {
    this.emitComponentSelect();
  }

  /**
   * @description Queries the backend for entity table data
   */
  private async getValuesForEntity(): Promise<SomeEntity[]> {
    return await this.dataLoader.getTable(this.entityName);
  }

  /**
   * @description It can extract the data-array from bigger object. This is used on windfarm-simulator if we a an
   * endpoint that returns a object which is composed of 4 data-arrays which are each used
   *
   * calculateRawFilters method from ComponentHelper apply hardcoded filters from the component the config
   */
  private async getValuesForQuery(): Promise<SomeEntity[]> {
    if (!this.componentSettings.endpoint) {
      return [];
    }

    let dataObject: any = await this.dataReloadFetch();

    if (this.componentSettings.dataKey) {
      dataObject = dataObject[this.componentSettings.dataKey];
    }
    // Custom-heavy endpoint: data is already filtered, displayed as-is
    return dataObject;
  }

  private getTableDefinitionFromConfig(): EntityDefinition {
    this.header = this.componentSettings.title;
    this.description = this.componentSettings.description;

    const fields = this.mergeDefaultAndAdditionColumns(
      this.componentSettings.columns,
      this.componentSettings.additionColumns,
    );
    /*
     * parse the config to construct the new sorting format for the fake definition
     * from field list
     */
    const sortFields: { [fieldId: string]: SortDirection } = {};
    for (const field of fields) {
      if (field.sort) {
        sortFields[field.id] = field.sort == 'descending' ? 'desc' : 'asc';
      }
    }

    const definition: EntityDefinition = {
      idField: this.componentSettings.idField,
      class: '',
      fields: fields,
      /*
       * The header for the table comes from the config
       * the view is bound on this field
       */
      header: this.componentSettings.title,
      generalInfo: {
        addNew: false,
        edit: false,
        duplicate: false,
        delete: false,
        exportCsv: true,
        inlineAdd: false,
        inlineEdit: false,
        inlineDuplicate: false,
        inlineDelete: false,
      },
      sortFields: sortFields,
      emptyEntity: null,
      tabs: null,
      titleString: '',
      calc: null,
      coherencyChecks: {},
      freeStyleFields: [],
      userEntityRights: [],
    };

    return definition;
  }

  private mergeDefaultAndAdditionColumns(
    defaultColumns: Fieldset[],
    additionColumns: Fieldset[],
  ): any[] {
    const defaultFields = FilterHelper.getFieldsToShowInTable(defaultColumns);

    // Default fields are set row=true, so they're shown
    defaultFields.forEach(field => field.row = true);

    if (!additionColumns) {
      return defaultFields;
    }

    const additionFields = FilterHelper.getFieldsToShowInTable(additionColumns);

    const allFields = new Map<string, any>();

    defaultFields.forEach(field => allFields.set(field.id, field));

    additionFields.forEach(field => {
      if (allFields.has(field.id)) {
        return;
      }
      allFields.set(field.id, field);
    });

    return [...allFields.values()];
  }
}
