<pearl-form-field
  [small]="small()"
  [label]="placeholder()"
  [iconPrefix]="className === 'field-search-bar' ? 'search' : null">
  <input
    attr.data-testid="multi-input-{{ fieldId }}"
    #input
    matInput
    [disabled]="disabled || getOptionsLength() === 0"
    [(ngModel)]="searchStr"
    (keydown)="keyHandling($event)"
    [matAutocomplete]="auto"
    [ngModelOptions]="{ standalone: true }"
    autocomplete="{{ autocomplete }}" />
  <mat-autocomplete
    [hideSingleSelectionIndicator]="true"
    class="mat-choices"
    #auto="matAutocomplete"
    (closed)="onClose()">
    <mat-option
      [id]="fieldId + '-mat-option-' + element.value"
      *ngFor="let element of filteredOptions(); trackBy: trackByTitle; let i = index"
      class="mat-choice"
      [ngClass]="{
        'mat-disabled': element.alreadyChosen,
      }"
      [value]="element">
      <div class="interactiveDiv" (click)="onSelected($event, element)" (keydown)="onSelected($event, element)">
        <span>{{ element.title || element.value }}</span>
        <span *ngIf="showSelectAll">
          <ng-container *ngIf="element.value != getSelectAllValue(); else selectAll">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="handleElement(element)"
              [checked]="element.alreadyChosen" />
          </ng-container>
          <ng-template #selectAll>
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="handleElement(element)"
              [checked]="element.alreadyChosen"
              [indeterminate]="isPartialSelection()" />
          </ng-template>
        </span>
      </div>
    </mat-option>
    <mat-option value="toggleShow" class="mat-choice show-more" *ngIf="getOptionsLength() > 40">
      <div (click)="toggleShow($event)" class="interactiveDiv">{{ showMessage() }}</div>
    </mat-option>
  </mat-autocomplete>
</pearl-form-field>
