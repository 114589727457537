import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Host, Injector, NgZone, OnDestroy, OnInit, Output,
  computed, inject, signal } from '@angular/core';
import { DecimalPipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router, Scroll } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Config } from '../../config/config';
import { AppInfoService, PreferencesKeys } from '../../app/app-info-service';
import { DatabaseHelper } from '../../database/database-helper';
import { DeleteEntityDialog } from '../../database/delete-entity-dialog';
import { DialogManager } from '../../database/dialog-manager';
import { EntityDialogManager } from '../../database/entity-dialog-manager';
import { FilterHelper } from '../../filters/filter-helper';
import { SelectorConfig } from '../../selector/selector.types';
import { BrowserHelper } from '../../helpers/browser-helper';
import { DashboardSettings, EntityTableComponentSettings, LayerSettings, PageConfig } from '../../helpers/config-types';
import { translate } from '../../helpers/d3-helpers';
import { DataLoader } from '../../data-loader/data-loader';
import { DateHelper } from '../../helpers/date-helper';
import { NavigationHelper } from '../../helpers/navigation-helper';
import { ActionEvent, AfterSave, Button, ChartModalConfig, CompleteExport, ComponentAction, ComponentState,
  DashboardState, DrawioDiagramRequestParams, EntityAction, EntityInformation, ExportConfig, FieldSettings, Fieldset,
  FilterApplied, FiltersOnLayerChanged, FiltersState, IntervalField, IntervalOrNull, LayerFilter, LayerId,
  LayerLoadingOptions, LayerToggledEvent, LayerUserPreferences, PngExport, SomeEntity, SpinGeoDataPoint,
  UrlParseType } from '../../helpers/types';
import { TimezoneService } from '../../helpers/timezone.service';
import { ChartModalComponent } from '../../pages/chart-modal';
import { TableModalComponent } from '../../pages/table-modal';
import { ErrorService } from '../../error-pages/error.service';
import { DprDrawioDiagramDialogComponent,
  DrawioDiagramData } from '../../live-dpr/dialogs/dpr-drawio-diagram-dialog.component';
import { DprEndpoints, ReportingRefData } from '../../live-dpr/models/reporting-types';
import { IntercomService } from '../../shared/services/intercom.service';
import { ProductAnalyticsService } from '../../shared/product-analytics/product-analytics.service';
import { UIService } from '../../shared/services/ui.service';
import { RefDataProvider } from '../../data-loader/ref-data-provider';
import { JobCategoryDialogComponent } from '../../shared/dialogs/job-category-dialog/job-cateogory-dialog.component';
import { RouterService } from '../../helpers/router.service';
import { StandardLayerState } from './layer-state';
import { RawDataPoint } from '../../graph/chart-types';
import { ErrorWithFingerprint } from '../../helpers/sentry.helper';
import { StringHelper } from '../../helpers/string-helper';

/**
 * Provides injection, basic data and URL manipulation, async loading,
 * export to CSV & a few helpers
 *
 * FIXME: <T extends DashboardSettings> should be more something like <T *in* DashboardSettings>
 *        Even if requested it is not yet available in Typescript: https://github.com/microsoft/TypeScript/issues/17713
 */
@Directive()
export abstract class Dashboard<T extends DashboardSettings> implements OnDestroy, OnInit, AfterViewInit {
  public router: Router;
  public route: ActivatedRoute;
  public location: Location;
  public decimalPipe: DecimalPipe;
  public dialog: MatDialog;
  public config: Config;
  public browser: BrowserHelper;
  public dataLoader: DataLoader;
  public dashboardTitle: string;
  public panelId: string = '';
  public state: DashboardState = {};
  public ready: boolean = false;
  public tracking: boolean = false;

  public dialogManager: DialogManager;
  public entityDialogManager: EntityDialogManager;
  public titleService: Title;
  public injector: Injector;
  public ngZone: NgZone;
  public commonFiltersFieldsets: Fieldset[];
  /** Those fields are standard and advanced filters of common filters - meaning filters that are not part of layers. */
  private allCommonFilterFields: FieldSettings[];
  public selector: SelectorConfig;
  public dashboardActiveStateInit: boolean = false;

  // Determines whether a specific params were passed in while navigating from outside
  public navigationParamsAtStartup: Params;

  public cdRef: ChangeDetectorRef;
  public filteringCommonFilters = false;
  public commonFiltersOnAllLayersDone = false;

  /**
   * Holds the complete state of the common filters, similar to **layers** which hold state of each layer
   */
  public commonFiltersFullState: LayerFilter;

  public appInfoService: AppInfoService; /// < public to be used in template (like in `alert-subscription-list.html`)
  protected intercomService: IntercomService;
  public analyticsService: ProductAnalyticsService;
  protected routerService = inject(RouterService);

  public uiService: UIService = inject(UIService);
  public sidebarHidden = signal<boolean | undefined>(undefined);
  public sidebarCollapsed = computed(() => this.sidebarHidden() ?? this.uiService.isSmallDisplay());

  // Timezone
  @Host()
  public timezoneService: TimezoneService;

  /**
   * Dashboard subscriptions sink: these subscriptions are automatically unsubscribed on detach
   *
   * @deprecated Use angular's takeUntilDestroyed
   */
  protected readonly sink$: Subscription[] = [];

  @Output()
  onParams = new EventEmitter<any>();

  /**
   * @constructor
   *
   * @param {Injector} injector      Injector
   * @param {Title}    titleService  Title service
   */
  constructor(injector: Injector, titleService: Title) {
    this.injector = injector;
    this.config = injector.get(Config);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.location = injector.get(Location);
    this.decimalPipe = injector.get(DecimalPipe);
    this.dialog = injector.get(MatDialog);
    this.browser = injector.get(BrowserHelper);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.panelId = NavigationHelper.getPanelId(this.router.url, this.config.product).panelId;
    this.dialogManager = injector.get(DialogManager);
    this.entityDialogManager = injector.get(EntityDialogManager);
    this.dataLoader = injector.get(DataLoader);
    this.titleService = titleService;
    this.ngZone = injector.get(NgZone);
    this.appInfoService = injector.get(AppInfoService);
    this.intercomService = injector.get(IntercomService);
    this.analyticsService = injector.get(ProductAnalyticsService);
    this.timezoneService = injector.get(TimezoneService);
    this.setDefaultTitle();
    this.navigationParamsAtStartup = this.route.snapshot.queryParams;
    this.config.updateSkipKeys(this.router.url);
    this.appInfoService.reloadPageAction.pipe(takeUntilDestroyed()).subscribe(() => this.reloadPageConfig());
    this.subscribeToRouteChanges();
  }

  public ngOnInit(): void {
    this.setDefaultTitle();
    // some dashboards dont have settings at all, some due and can have common filters defined
    if (this.settings && this.settings.commonFilters) {
      if (this.settings.commonFilters) {
        this.commonFiltersFieldsets = FilterHelper.initializeFieldsetTypeAndProp(this.settings.commonFilters);
      }
    }
    this.allCommonFilterFields = this.getAllCommonFilterFields();

    if (this.settings && this.settings.selector) {
      this.selector = this.settings.selector;
      this.selector.fieldsets = FilterHelper.initializeFieldsetTypeAndProp(this.selector.fieldsets);
    }
  }

  /* Returns the total number of unique applied filters, combining standard and advanced filters */
  protected getNumberOfFiltersAppliedAsString(): string {
    if (!this.commonFiltersState) {
      return '';
    }
    const count = Object.keys(this.commonFiltersState).length;
    return FilterHelper.formatNumberOfFiltersAppliedAsString(count);
  }

  protected subscribeToRouteChanges(): Subscription {
    return this.router.events
      .pipe(filter(value => {
        return value instanceof NavigationEnd;
      }))
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.panelId = this.route.snapshot.url.slice(-1)[0].path;
        if (this.isActiveDashboard()) {
          /**
           * We only do this for current active dashboard/page.
           * /!\ Components-page overrides this method, as it has a specific way of handling route changes.
           */
          this.handleRouteReload();
        } else {
          /**
           * Already visited dashboards would have this executed, because they are still subscribed.
           * Dashboards that desactivated session replay would start it again, only map-dashboard at this moment.
           */
          this.reactivateSessionReplayIfNeeded();
        }
      });
  }

  private jobCategoryDialogOpened = false;
  private hasJobCategoryOrIsImpersonating(): boolean {
    return !!this.config.userInfo.jobCategory || !!this.config.impersonate || this.jobCategoryDialogOpened;
  }

  protected setJobCategory(): void {
    if (this.hasJobCategoryOrIsImpersonating()) return;
    this.jobCategoryDialogOpened = true;
    const dialogRef = this.dialog.open(JobCategoryDialogComponent, {
      disableClose: true,
      data: {
        user: this.config.userInfo,
      },
    });

    dialogRef.beforeClosed().pipe(first()).subscribe(result => {
      this.jobCategoryDialogOpened = false;
      if (!result) return;
      this.config.userInfo.jobCategory = result;
    });
  }

  /**
   * This function is called when navigating back to an already visited route or reloading current one.
   * /!\ Components-page is not using this method as it has a custom way of handling of route reload and change!
   */
  protected handleRouteReload(): void {
    /**
     * We can pass through router extra options to inform:
     * 1. if we want to reset state (e.g. when we navigate with bookmark)
     * 2. if we want to reload data on dashboard we navigate to.
     */
    const { resetState, reloadData } = this.routerService.getNavigationExtraState();
    this.config.updateSkipKeys(this.router.url);
    this.config.loadGlobalFilters();
    if (reloadData) {
      this.reloadData();
    }
    this.reloadState(resetState);
  }

  /**
   * Dashboards should implement this method to reload their data.
   * This function is called when:
   * - a dashboard is navigated on with `reloadData` extra state (see RouterService),
   * - a delete / create occurs on the data of current dashboard (see afterDeleteEntity e.g.).
   */
  public reloadData(): void {
    console.warn(`reloadData() is not implemented by ${this.constructor.name}.`);
  }

  /**
   * Called when a route is visited again.
   * @param resetState flag to tell that the state should be reset to query params, only used with bookmarks.
   */
  private reloadState(resetState?: boolean): void {
    const state = this.urlParse(this.state, null, resetState ? 'resetState' : null); // pass an empty state
    this.applyState(state);
  }

  /**
   * Apply given state on dashboard. /!\ This does not triggers reload of light data. See reloadData() for that.
   * This method is called after a bookmark (through resetStateToUrlParams()) has been applied or a navigation on same
   * dashboard ocurred.
   */
  private applyState(state: DashboardState): void {
    this.state = state;
    this.navigationParamsAtStartup = this.route.snapshot.queryParams;
    this.appInfoService.reinitUserActions();
    this.setDefaultTitle();
    if (this.verifyRight()) {
      this.refresh();
    }
  }

  /**
   * All dashboards implement this method which is responsible to refresh the dashboard with respect to current state.
   * /!\ This method is not supposed to fully reload the data. Especially light data should not be reloaded.
   * Fully reloading the data should be done by `reloadData()` method.
   */
  abstract refresh(): void;

  /** Load ref datasets for current page. Should be called by implementing components BEFORE getting actual data */
  public async loadRefDatasets(): Promise<void> {
    if (this.settings.usedRefDatasets) {
      await Promise.all(this.settings.usedRefDatasets?.map(datasetName => RefDataProvider.loadRefDataset(datasetName)));
    }
  }

  /**
   * After view
   *
   * @return {void}
   */
  public ngAfterViewInit(): void {
    /*
     * We store the selectable values directly in the config. On some dashboard reload (schedule, map), the config is
     * not fetched again, so we need to empty it manually.
     */
    this.setJobCategory();
    this.resetSidebarOptions();
    this.appInfoService.reinitUserActions();
    this.ngOnAttach();
  }

  /**
   * On dashboard attached (after view init or on router reuse)
   *
   * @return {void}
   */
  public ngOnAttach(): void {}

  /**
   * On dashboard detached (on router change)
   * TODO All subscriptions here (will replace 'active' flag)
   *
   * @return {void}
   */
  public ngOnDetach(): void {
    this.sink$.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * On destroy
   * Clean up subscriptions
   *
   * @return {void}
   */
  public ngOnDestroy(): void {
    this.ngOnDetach();

    // Reset timezone service
    this.timezoneService.reset();
  }

  /* * Getters/setters * */

  // but messes with search-specs.html
  public get commonFiltersState(): FiltersState {
    if ('common-filters' in this.state) {
      return this.state['common-filters'];
    }
    return null;
  }

  public set commonFiltersState(filtersState: FiltersState) {
    this.state['common-filters'] = filtersState;
  }

  public get settings(): T {
    return this.config.appConfig.settings[this.panelId] as T;
  }

  /* Return the page config meta info, the one we find in product-config.json e.g. */
  public get pageMetaConfig(): PageConfig {
    return this.config.appConfig.pages.find(page => page.id === this.panelId);
  }

  /* * Public * */

  public defaultLoadingOptions: LayerLoadingOptions = {
    clearCache: true,
    displayLoadingPanel: true,
  };

  /**
   * Will reset the current choices available in the sidebar filters.
   */
  public resetSidebarOptions(): void {
    if (this.commonFiltersFieldsets) {
      this.commonFiltersFieldsets.forEach(f => f.fields.forEach(field => field.values = []));
    }
  }

  /** Called at init of some dashboards. */
  public loadStateAndPersistentFilters(): void {
    const persistentFilters = this.getPersistentFiltersAppliedInCurrentDashboard();

    if (this.settings) {
      this.state = this.urlParse({ ...this.settings.init, ...persistentFilters });
    } else {
      this.state = this.urlParse(persistentFilters);
    }
  }

  public isActiveDashboard() {
    const currentDashboard = AppInfoService.getCurrentDashboard(this.router);
    const currentPage = this.getCurrentPage();

    // if we navigate to the current component we will mark it as active
    if (this.panelId == currentDashboard || this.panelId == currentPage) {
      return true;
    }
    // navigating away
    return false;
  }

  /**
   * Get persistent common filters only
   */
  public getPersistentFiltersAppliedInCurrentDashboard(): DashboardState {
    const persistentFilters: DashboardState = {};
    if (this.config.skipPersistentFilters || !this.settings || !this.settings.commonFilters) {
      return persistentFilters;
    }
    for (const filterId in this.config.persistentFilterState) {
      const selectorFieldsets = this.settings.selector?.fieldsets ?? [];
      const commonFilter = FilterHelper.findFieldInFieldsets(
        [...this.settings.commonFilters, ...selectorFieldsets],
        f => f.id === filterId,
      );
      if (commonFilter) {
        if (!persistentFilters['common-filters']) {
          persistentFilters['common-filters'] = {};
        }
        persistentFilters['common-filters'][filterId] = this.config.persistentFilterState[filterId];
        continue;
      }
    }
    return persistentFilters;
  }

  /**
   * Get the current component page ID.
   * Return `null` if the current dashboard is not a component page.
   */
  protected getCurrentPage() {
    const pageMatches = this.router.url.match(NavigationHelper.PAGE_REGEXP);
    if (pageMatches?.length > 1) {
      return pageMatches[1];
    }
    return null;
  }

  /* Return common filters fields, including common advanced filters. */
  private getAllCommonFilterFields(): FieldSettings[] {
    const fields: FieldSettings[] = [];
    /**
     * Fields from common filters fieldsets.
     * Each fieldset can have a selector: fields from those selectors are added too.
     */
    this.settings?.commonFilters?.forEach(fs => fields.push(...FilterHelper.getFieldsetWithSelectorFields(fs)));
    /* Fields from main selector. We exclude all fields previously added (second parameter).*/
    fields.push(...FilterHelper.getFieldsetsFields(this.settings?.selector?.fieldsets, fields.map(f => f.id)));
    return fields;
  }

  private verifyRight(): boolean {
    if (this.config.isFullAdmin) {
      return true;
    }

    const errorService = this.injector.get(ErrorService);
    errorService.isActive = false;
    const hasRight = this.config.checkRightsFromUrl(this.router.url);
    if (!hasRight) {
      errorService.urlSource = this.router.url;
      this.router.navigate(['/no-rights']);
      return false;
    }
    return true;
  }

  private setDefaultTitle() {
    const getTitleRegex = new RegExp('/dashboard/([^/;?]*)', 'i');
    const result = getTitleRegex.exec(this.router.url);
    if (result && this.titleService) {
      const title = this.getTitle(result[1]);
      this.dashboardTitle = title;
      let tabTitle = title ? title : result[1];
      if (tabTitle === 'dpr') {
        tabTitle = 'Reporting';
      }
      this.titleService.setTitle(`Spinergie | ${tabTitle}`);
    }
  }

  private getTitle(value: string) {
    const dashboard = this.config.appConfig.dashboards.find(dashboard => dashboard.id == value);
    return dashboard ? dashboard.title : null;
  }

  /**
   * Test whether the URL parameter is a simple one (not in the form `layer:value`)
   * @param param The param to test
   */
  public static isSimpleUrlParameter(param: string): boolean {
    return param.split(':').length === 1;
  }

  /**
   * Get the dashboard state from the current state and given navigation parameters.
   * If no navigation paramters are given, we take the query parameters of the URL.
   *
   * @param state         The dashboard state.
   * @param params        Navigation parameters to be added to the state. If none are given, we take as parameters
   *                      the query parameters of the current route snapshot.
   * @param parseOption   Affect how to merge the dashboard state and the navigation parameters :
   *                      - `resetState`  -> discard the given state and only use the navigation parameters
   *                      - `mergeActive` -> merge the state an the given values for "active" parameter.
   *                                         By default, this method overrides the state value with the param value
   */
  public urlParse(
    state: DashboardState = {},
    params: Params = null,
    parseOption: UrlParseType = null,
  ): DashboardState {
    // We take the params from the URL if no params were manually passed to the function
    if (!params) {
      params = { ...this.route.snapshot.queryParams };
    }
    if (parseOption === 'resetState') {
      state = {};
    }
    // Update the state with every navigation parameter
    for (const gkey in params) {
      /*
       * Parameters not in the form `group:key` should not be handled here.
       * Those are specific dashboard parameters (ex. componentsState variable for the ComponentsPage)
       */
      if (Dashboard.isSimpleUrlParameter(gkey)) {
        continue;
      }
      const [group, key] = gkey.split(':');
      if (!state[group]) {
        state[group] = {};
      }
      let parameterValue = `${params[gkey]}`;
      /*
       * If the parse option is mergeActive it means we conserve
       * existing active layers, otherwise existingParam will be overridden
       */
      if (parseOption === 'mergeActive' && key === 'active' && state[group][key]) {
        parameterValue = parameterValue ? `${state[group][key]},${parameterValue}` : state[group][key];
      }

      /*
       * Every parameter value should have been encoded with `NavigationHelper.encodeParameterValue` prior
       * to the call of this function
       */
      state[group][key] = StringHelper.splitByCommas(parameterValue)
        .filter(p => p !== '')
        .map(p => {
          if (p === 'true') {
            return true;
          }
          if (p === 'false') {
            return false;
          }
          return NavigationHelper.decodeSingleParameterValue(p);
        });
    }
    this.specificParamsParsing(state);

    if (params[Config.SPINPERSONATE]) {
      this.config.impersonate = params[Config.SPINPERSONATE];

      delete state[Config.SPINPERSONATE];
    }
    this.urlSerialize(state, this.getAllFieldsetsConfig());
    return state;
  }

  public updateStateWithFilters(layerId: LayerId, filters: LayerFilter): void {
    if (!(layerId in this.state)) {
      this.state[layerId] = {};
    }

    for (const filter in filters) {
      this.state[layerId][filter] = filters[filter].hasOwnProperty('values')
        ? filters[filter].values
        : filters[filter];
    }
    // we have to delete removed filters
    for (const param in this.state[layerId]) {
      if (!filters || !(param in filters)) {
        delete this.state[layerId][param];
      }
    }
  }

  /**
   * A persistent filter is an advanced filter or a filter with an id in PERSISTENT_FILTER_IDS
   * And the layerId need to be 'common-filters' or corresponding to the mainLayerId
   */
  public isPersistentFilter(layerId: LayerId, filterId: string, mainLayerId: string = null): boolean {
    // Only the filters in the common filters or in the main layer can be persistent
    if (layerId !== 'common-filters' && layerId !== mainLayerId) {
      return false;
    }
    // If filter is in PERSISTENT_FILTER_IDS we know we have to make this filter persistent
    if (Config.PERSISTENT_FILTER_IDS.includes(filterId)) {
      return true;
    }
    // Otherwise if this filter is an advancedFilter, we will save it
    if (!this.selector || !this.selector.fieldsets) {
      return false;
    }
    // Check if the filter is in advanced filters
    const filterConfig = FilterHelper.findFieldInFieldsets(this.selector.fieldsets, f => f.id === filterId);
    return filterConfig ? true : false;
  }

  /**
   * Serialize the current application state in the URL.
   *
   * @param params      The current dashboard's state
   * @param fieldsets   The dashboard filters. Some filter values are serialized differently according to the config
   * @returns           The serialized URL
   */
  public urlSerialize(params: DashboardState, fieldsets: Fieldset[] = []): string {
    // Make sure we don't sync empty lists of vessels ids into the url
    for (const param in params) {
      if (params[param].vessel && params[param].vessel.length === 0) {
        delete params[param].vessel;
      }
    }
    const rawParams = {};
    // do not use URL object because of automatic encoding taking place when setting values
    let urlParams = '';
    const newPersistentFilters: FiltersState = {};
    // Serialize filters
    for (const group in params) {
      // global filters such as fleets / osvprojects are handled after
      if (Config.BROWSER_GLOBAL_FILTERS.includes(group)) {
        continue;
      }
      for (const key in params[group]) {
        const gkey = `${group}:${key}`;
        let values = params[group][key];
        const filterConfig = FilterHelper.findFieldInFieldsets(fieldsets, f => f.id === key);
        if (filterConfig?.relativeDate) {
          if (Array.isArray(values)) values = { extent: values };
          values = FilterHelper.serializeRelativeDate(values, filterConfig as IntervalField);
        }
        // Encode the parameter value
        let value = NavigationHelper.encodeParameterValue(values);
        rawParams[gkey] = value;
        if (this.isPersistentFilter(group, key)) {
          newPersistentFilters[key] = values;
        }
        /*
         * Encode the value a second time
         * We do this because URL are automatically decoded by the browser / angular
         * so when reading an URL, we can't differentiate between a comma customly used to separate values in
         * NavigationHelper.encodeParameterValue from a comma in the value string.
         * With this additional encoding, we will obtain something like this in urlParse method :
         * { title: ['Vessel 1', 'Vessel, 2'] } => { title: Vessel%201,Vessel%2C%202 }
         * where we have only our customly used commas de-encoded
         */
        value = encodeURIComponent(value);
        const separator = urlParams ? '&' : '';
        urlParams += `${separator}${gkey}=${value}`;
      }
    }
    this.mergeNewPersistentFilters(newPersistentFilters);
    this.route.snapshot.queryParams = rawParams;

    // Add the selected fleets/projects info.
    for (const storageKey of Config.BROWSER_GLOBAL_FILTERS) {
      if (!window.sessionStorage[storageKey]) continue;
      const separator = urlParams ? '&' : '';
      urlParams += `${separator}${storageKey}=${window.sessionStorage[storageKey]}`;
    }
    // Get the root URL without any parameters
    let finalUrl = this.router.url.split('?')[0];
    // Add the serialized state to it
    if (urlParams) finalUrl += `?${urlParams}`;
    window.history.replaceState({}, '', finalUrl);
    return finalUrl;
  }

  /**
   * In this function we check global persistent filters and current persistent filters on the current dashboard
   * The aim is to know which persistent filters have been removed and which persistent filters have been added
   */
  public mergeNewPersistentFilters(newPersistentFilters: FiltersState) {
    // If the current page should skip persistent filters we avoid modifying the persistent filters state
    if (this.config.skipPersistentFilters) {
      return;
    }
    const persistentFiltersInCurrentDashboard = this.getPersistentFiltersAppliedInCurrentDashboard();
    for (const filterId in this.config.persistentFilterState) {
      /*
       * Case where old persistent filter has a new value in new persistent filters
       * In this case we remove the old one
       */
      if (newPersistentFilters[filterId]) {
        delete this.config.persistentFilterState[filterId];
      } else {
        /*
         * If a filterId in old persistent filter hasn't a value in new persistent filters
         * we check if this filter is still a persistent filters in this dashboard
         * If so it means this filter has been removed in purpose
         * If not it means this filter isn't applied in this dashboard but we need to keep it because it could be valid
         * in another dashboard
         */
        for (const layerId in persistentFiltersInCurrentDashboard) {
          const layerPersistentFilters = persistentFiltersInCurrentDashboard[layerId];
          if (layerPersistentFilters[filterId]) {
            delete this.config.persistentFilterState[filterId];
          }
        }
      }
    }

    /*
     * At this point we should have removed from old persistent filter state the filters that shouldn't be persistent
     * anymore. So we can just merge old and new persistent filter state
     */
    this.config.persistentFilterState = {
      ...this.config.persistentFilterState,
      ...newPersistentFilters,
    };
  }

  /**
   * Set all the filters on a given layer.
   *
   * This updates the layer state and the url with the given filters, then applies the filters to the layer. \
   * The filtering is handled by each component inheriting **Dashboard** component through `afterFilters` function.
   *
   * `filters` expects only the layer specific filters. The "common filters" will be added to the overall filters
   * to be applied to the layer. \
   * The chained filters of common filters are handled in `commonFilters` function.
   * The chained filters of the layer filters should be handled by the layer directly.
   *
   * @param layerId   The layer to be filtered
   * @param filters   The layer specific filters
   */
  public setFilters(layerId: LayerId, filters: LayerFilter): void {
    this.updateStateWithFilters(layerId, filters);
    this.urlSerialize(this.state, this.getAllFieldsetsConfig());
    const commonFilters = this.commonFilters();
    // Concat common filters and the layer filters
    const allFilters = {
      ...commonFilters,
      ...filters,
    };
    this.retrieveFiltersTitles(allFilters, layerId);
    const commonFiltersDone = !this.filteringCommonFilters || this.commonFiltersOnAllLayersDone;
    this.afterFilters(layerId, allFilters, commonFiltersDone);
  }

  public abstract afterFilters(layerId: LayerId, filters: LayerFilter, commonFiltersDone: boolean);
  public abstract afterSidebarSwitch(isHidden: boolean);

  /**
   * Each dashboard can overwrite this method to introduce specific parsing logic
   * @param params
   */
  public specificParamsParsing(params: DashboardState): void {
    // Transform relative dates in timestamps
    for (const group in params) {
      for (const paramId in params[group]) {
        let toParse = params[group][paramId];
        // Join an eventual array of string periods for parseInterval to handle
        if (toParse.length === 2) toParse = [toParse.join(',')];
        const parsedInterval = FilterHelper.parseInterval(toParse[0]);
        if (parsedInterval !== null) {
          /**
           * Only schedule can handle ResolvedInterval in its state.
           * All the other periods rest are converted to Interval
           */
          params[group][paramId] = group === 'schedule' ? parsedInterval : parsedInterval.extent;
        }
      }
    }
  }

  public areFiltersTheSame(previous: FilterApplied, latestFilter: FilterApplied): boolean {
    // ids equal and values equal (length & every)
    return previous.id === latestFilter.id
      && previous.active === latestFilter.active
      && previous.values
      && previous.values.length
      && previous.values.length === latestFilter.values.length
      && previous.values.every((v, i) => v === latestFilter.values[i]);
  }

  /**
   * This is called when a filter is changed. \
   * It selects the layers that need to be filtered and get all the filters to be applied to each layer. \
   * The filtering is done throug the call of `Dashboard.setFilters` for the appropriate layers.
   *
   * @param filtersOnLayer  The filters change data. It holds the filtered layer and the filters to be applied.
   */
  public onfilters(filtersOnLayer: FiltersOnLayerChanged, hasLayers: boolean = false): void {
    if (!this.ready) {
      return;
    }

    if (filtersOnLayer.filters?.latest?.values) {
      this.analyticsService.trackAction('sidebarFilterFieldUsed', {
        filterId: filtersOnLayer.filters.latest.id,
        path: this.router.url,
      });
    }

    const requestedLayerId = filtersOnLayer.layerId;
    const filters = filtersOnLayer.filters;

    if (this.filtersHaveNotChanged(filters.values)) {
      return;
    }

    // this is a special case of common filter, we have to filter all layers at onces
    if (requestedLayerId === 'common-filters' && !hasLayers) {
      // set the state of common filters into the url
      this.updateStateWithFilters('common-filters', filtersOnLayer.filters.values);
      /*
       * we will store the state of common filters inside the specific object which holds them
       * this.state does not hold all the information (allowNulls for instance is missing)
       * allowNulls is stored on the this.layers[layerId] for regular layers
       * but for common filters it has to be handled here
       */
      this.commonFiltersFullState = filters.values;
    }
  }

  /** Compare given filters to previous filters */
  protected filtersHaveNotChanged(newFilters: LayerFilter): boolean {
    if (!newFilters && !this.commonFiltersFullState) {
      return true; // If both new and current filter states are null, filters have not changed
    }

    if (!newFilters || !this.commonFiltersFullState) {
      return false; // If one of new or current filter state is null, but not the other, filters have changed
    }

    const newFilterIds = Object.keys(newFilters);
    const previousFilterIds = Object.keys(this.commonFiltersFullState);

    return newFilterIds.length === previousFilterIds.length && newFilterIds.length > 0
      && newFilterIds.every(filterId =>
        previousFilterIds.includes(filterId)
        && this.areFiltersTheSame(newFilters[filterId], this.commonFiltersFullState[filterId])
      );
  }

  /**
   * Function to get all common filters.
   * It takes the values from the URL state but uses the config to get the
   * `filterType` and `propValue`. It also needs to use the `commonFilters`
   * local variable to get eventually the `allowNulls` value.
   *
   * @param  {string} layerId    The layer for which we generate the common filters.
   *                             This is used for chain filtering.
   * @return {LayerFilter}
   */
  public commonFilters(): LayerFilter {
    const commonFilters: LayerFilter = {};

    // Read URL state
    const commonUrlState = this.state['common-filters'];
    if (!commonUrlState) {
      return commonFilters;
    }

    // Parse URL state
    for (const filterId in commonUrlState) {
      let commonFilterField = this.allCommonFilterFields.find(d => d.id === filterId);
      if (!commonFilterField) {
        /*
         * Some dashboard have some static extra fields not described in config
         * that need to be take into account
         * (excludedEntities in schedule for example)
         */
        const dashboardExtraFields = this.getDashboardExtraFields();
        commonFilterField = dashboardExtraFields.find(d => d.id === filterId);
        // All other filters will be skipped
        if (!commonFilterField) {
          continue;
        }
      }

      // Read `allowNulls` from `commonFiltersFullState` if defined
      let filterFromState = null;
      if (this.commonFiltersFullState) {
        filterFromState = this.commonFiltersFullState[filterId];
      }

      const filterConfig = new FilterApplied({
        ...commonFilterField,
        allowNoProperty: filterFromState ? filterFromState.allowNulls : false,
        values: commonUrlState[filterId],
      });
      commonFilters[filterId] = filterConfig;
    }

    return commonFilters;
  }

  /**
   * Gets the current filters applied on a layer. It takes into account the state of the URL
   * Also the state of this.layers (because some things are not in the URL) and also the common filters
   */
  public currentFilters(layerId: LayerId, _: boolean = false): LayerFilter {
    const layerUrlState = this.state[layerId];
    let filtersConfig: LayerFilter = {};

    if (layerId === 'common-filters') {
      filtersConfig = FilterHelper.getCommonFiltersTypeAndProp(this.settings);
    }

    return this.filterStateAndConfigToApplied(layerUrlState, filtersConfig);
  }

  /**
   * Return the config of all the filters in the given state.
   * The filter values are taken from the state.
   *
   * @param  {FiltersState} layerUrlState          Filters state from URL
   * @param  {LayerFilter}  filtersConfig          Config of all filters
   * @return {LayerFilter}                         Resulting filters
   */
  protected filterStateAndConfigToApplied(layerUrlState: FiltersState, filtersConfig: LayerFilter): LayerFilter {
    const currentFilters: LayerFilter = {};

    // Parse URL state
    for (const filter in layerUrlState) {
      // URL filter found in config
      if (filter in filtersConfig) {
        currentFilters[filter] = {
          ...filtersConfig[filter],
          values: layerUrlState[filter],
        };
      } // Special filter: mode / shapeHidden
      else if (filter === 'mode') {
        currentFilters[filter] = {
          values: layerUrlState[filter],
          filterType: null,
        };
      } // Unknown filter
      else {
        console.warn(`Missing filter properties - ID : ${filter}`);
      }
    }

    return currentFilters;
  }

  /**
   * Returns current or default filter for current dashboard (map/schedule/light and heavy analytics)
   */
  public currentOrDefaultFilters(layerId: LayerId): LayerFilter {
    return this.currentFilters(layerId);
  }

  /**
   * This function will merge current state and new persistent filters
   * It will keep only non-persistent filters from current state
   * and add all new persistentFilters
   * @param state
   * @param persistentFilters
   */
  public mergeStateAndPersistentFilters(state: DashboardState, persistentFilters: any): DashboardState {
    const newState: DashboardState = {};
    // Keep all filters except persistent filters from current state
    for (const group in state) {
      if (!newState[group]) {
        newState[group] = {};
      }
      for (const key in state[group]) {
        // Filter is a persistent filter with an active value so we remove it from newState
        if (this.isPersistentFilter(group, key) && persistentFilters[group]?.[key] != null) {
          continue;
        } else {
          // Filter isn't a persistent filters so we can add it to newState
          newState[group][key] = state[group][key];
          continue;
        }
      }
    }

    // Add new persistent filters
    for (const pGroup in persistentFilters) {
      if (!newState[pGroup]) {
        newState[pGroup] = {};
      }
      for (const pKey in persistentFilters[pGroup]) {
        newState[pGroup][pKey] = persistentFilters[pGroup][pKey];
      }
    }
    return newState;
  }

  /**
   * Returns true if the dashboard handles the given tooltip
   */
  public dashboardSpecificTooltip(_: ActionEvent): boolean {
    return false;
  }

  /**
   * Keep as pseudo-lambda function as `this.onaction` is sometimes passed as an @Input
   * @see map-dashboard.component.html
   */
  public onaction = (actionEvent: ActionEvent): void => {
    const { action, data, event } = actionEvent;
    this.ngZone.run(async () => {
      if (this.dashboardSpecificTooltip(actionEvent) === true) {
        /*
         * We return false with dashbordSpecificTooltip to stop propagation.
         * For example when we used one of the event.type already handle but to do something else.
         */
        return;
      }

      switch (action.type) {
        case 'link':
          event.preventDefault();
          window.open(action.href);
          return;

        case 'tableModal':
        case 'expandArray':
          event.preventDefault();
          const tableModalConfig = action.modalConfig as EntityTableComponentSettings;
          this.generateTableModal(tableModalConfig, actionEvent.modalComponentState);
          return;

        case 'chartModal':
          event.preventDefault();
          this.generateChartModal(
            action.modalConfig as ChartModalConfig,
            actionEvent.modalComponentState,
          );
          return;

        case 'editModal': {
          this.editEntityModal(actionEvent, data);
          return;
        }

        case 'deleteModal': {
          this.deleteEntityModal(
            action,
            () => {
              this.dataLoader.post(this.dataLoader.deleteUrl(action.entityName), { id: data['id'] }).then(response => {
                if (response) {
                  this.afterEntityDelete();
                }
              });
            },
          );
          return;
        }

        case 'openModal': {
          const entity = { id: data['id'] };
          this.openEntityModal(actionEvent, entity);
          return;
        }

        case 'addModal': {
          this.newEntityModal(actionEvent);
          return;
        }

        case 'duplicateEndpointIntoEntityAndOpen': {
          const newEntity = this.createEntityForDuplication(actionEvent.data);
          this.newEntityModal(actionEvent, newEntity);
          return;
        }

        case 'navigate': {
          const href = action.href;
          const curr = this.routerService.getCurrentDashboardUrl();
          if (curr === href && !this.isPage()) {
            this.cleanStateBeforeSameUrlNavigation(data);
            const state = this.urlParse(this.state, data, 'mergeActive');
            this.applyState(state);
            return;
          }

          /*
           * when we navigate we ensure that everything inside the cdk-overlay-container (tooltips etc.) are removed
           * we do that to avoid reminiscence when we navigate
           */
          const overlayContainer = Array.from(document.getElementsByClassName('cdk-overlay-container'));
          for (const div of overlayContainer) {
            div.innerHTML = '';
          }
          /**
           * Wait next tick before navigating to let the app settle if some selects are not closed and closing
           * them would change the URL, which would cause a routing problem with messed up query params (see SP-5431)
           */
          setTimeout(() => {
            this.routerService.navigateWithExtraState(href, data, { resetState: actionEvent.resetState });
          }, 0);
          return;
        }

        case 'endpoint': {
          const endpoint = action.endpoint;
          await this.dataLoader.post<any, any>(endpoint, data ?? {});
          return;
        }

        case 'openSelector':
          this.openSelector(action.tab);
          return;

        case 'drawioDiagram':
          this.openCreateOrCompareDrawioDiagrams(action.isDrawioForCompare);
          return;

        case 'reportingConfigCheck':
          this.checkReportingFullConfig();
          return;

        case 'postprocessReportingVoyages':
          this.postprocessReportingVoyages();
          return;

        case 'openIntercom':
          this.intercomService.openWithMessage(decodeURIComponent(action.message));
          return;

        // Invalid or unknown type
        default:
          console.warn(`A unhandle type button has been encounter : '${event.type}'`);
      }
    });
  };

  public cleanStateBeforeSameUrlNavigation(stateToAdd: Params): void {}

  public isPage(): boolean {
    return this.getCurrentPage() !== null;
  }

  /**
   * Create a new entity using a duplication mask
   */
  public createEntityForDuplication(duplicateEntity: Params) {
    const newEntity: SomeEntity = {};
    for (const key in duplicateEntity) {
      const value = duplicateEntity[key];
      /*
       * We pass the value in `to_construct_for_field_id` only for complexe linked collections
       * where the value is an entity to be created with the definition of its fields.
       * In case of basic linked collection (list of IDs), we just pass the array of IDs.
       */
      if (Array.isArray(value) && !Object.values(value).every(v => typeof v === 'number')) {
        newEntity[key] = [];
        newEntity[`__to_construct_for_${key}`] = value;
      } else {
        newEntity[key] = value;
      }
    }
    return newEntity;
  }

  /**
   * Create a new entity (modal dialog)
   */
  public newEntityModal(actionEvent: ActionEvent, entity: SomeEntity = null) {
    const action = actionEvent.action as EntityAction;
    const entityInformation = DatabaseHelper.editEntityInfo(
      {
        action,
        data: actionEvent.data,
        afterEntitySave: actionEvent.afterEntitySave ?? this.afterEntitySave,
        afterClose: () => this.removeModalFromState(),
      },
      entity,
    );
    this.entityDialogManager.openEntityDialog(entityInformation);
    this.addModalToState(entityInformation);
  }

  /**
   * View entity from entity information (modal dialog)
   */
  public openEntity(info: EntityInformation) {
    this.entityDialogManager.openEntityDialog(info);
  }

  /**
   * View entity (modal dialog)
   */
  public openEntityModal(actionEvent: ActionEvent, entity: SomeEntity) {
    const action = actionEvent.action as EntityAction;
    const entityInformation = DatabaseHelper.editEntityInfo(
      {
        action,
        afterEntitySave: actionEvent.afterEntitySave ?? this.afterEntitySave,
        afterClose: () => this.removeModalFromState(),
      },
      entity,
      false,
    );
    this.entityDialogManager.openEntityDialog(entityInformation);
    this.addModalToState(entityInformation);
  }

  /**
   * Edit entity (modal dialog)
   */
  public editEntityModal(actionEvent: ActionEvent, entity: SomeEntity) {
    const action = actionEvent.action as EntityAction;
    const entityInformation = DatabaseHelper.editEntityInfo(
      {
        action,
        afterEntitySave: actionEvent.afterEntitySave ?? this.afterEntitySave,
        afterClose: () => {
          if (!action.skipUrlModalStateChange) {
            this.removeModalFromState();
          }
        },
      },
      entity,
    );
    this.entityDialogManager.openEntityDialog(entityInformation);
    if (!action.skipUrlModalStateChange) {
      this.addModalToState(entityInformation);
    }
  }

  /**
   * Ask confirmation before deleting entity (modal dialog)
   */
  public deleteEntityModal(action: EntityAction, afterClosed: () => void) {
    const dialogRef = this.dialog.open(DeleteEntityDialog, {
      maxWidth: '500px',
      maxHeight: '250px',
      data: {
        entityTitle: action.entityTitle,
        deleteMessage: `Are you sure you want to delete this ${action.entityTitle}? This action is not reversible.`,
      },
      panelClass: 'spin-dialog-box',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        afterClosed();
      }
    });
  }

  /**
   * View entity from dashboard state (modal dialog)
   */
  public openModalAccordingToState() {
    if (this.state.modal) {
      const entityName = this.state.modal.entityName[0];
      const entityId = this.state.modal.entityId ? this.state.modal.entityId[0] : null;
      const layerId = this.state.modal.reloadLayer ? this.state.modal.reloadLayer[0] : null;
      const readonly = this.state.modal.readonly ? this.state.modal.readonly[0] : null;

      /**
       * FIXME: use prefill instead!
       */
      const fieldsToPrefill = Object.keys(this.state.modal)
        .filter(key => ['entityName', 'entityId', 'readonly'].indexOf(key) == -1);

      const prefilledEntity = {};
      for (const field of fieldsToPrefill) {
        const stringValue = this.state.modal[field][0];
        const intValue = parseInt(stringValue);
        if (isNaN(intValue)) {
          prefilledEntity[field] = stringValue;
        } else {
          prefilledEntity[field] = intValue;
        }
      }

      if (entityId) {
        prefilledEntity['id'] = entityId;
      }

      const entityInformation: EntityInformation = {
        entityName: entityName,
        editMode: !readonly,
        idField: 'id',
        closeAfterSave: true,
        entity: prefilledEntity,
        creation: this.state.modal.entityId == null || this.state.modal.entityId == undefined,
        afterSaveAction: data => this.afterEntitySave(data),
        afterCloseAction: () => this.removeModalFromState(),
        layerId: layerId,
      };
      this.entityDialogManager.openEntityDialog(entityInformation);
    }
  }

  /**
   * Add modal information to dashboard state
   */
  private addModalToState(entityInformation: EntityInformation) {
    this.state.modal = {
      entityName: [entityInformation.entityName],
    };

    if (entityInformation.layerId) {
      this.state.modal.reloadLayer = [entityInformation.layerId];
    }

    if (entityInformation.entity && entityInformation.entity[entityInformation.idField]) {
      this.state.modal.entityId = [entityInformation.entity[entityInformation.idField]];
    }

    if (entityInformation.editMode == false) {
      this.state.modal.readonly = true;
    }

    this.urlSerialize(this.state);
  }

  /**
   * Remove modal information from dashboard state
   */
  public removeModalFromState() {
    delete this.state.modal;
    this.urlSerialize(this.state);
  }

  public async onlayer(event: LayerToggledEvent): Promise<void> {
    /** Do not record vessel historical position in user preferences */
    if (!event.layerId.toLowerCase().includes('historical')) {
      const layerUserPreferences: LayerUserPreferences = { visible: event.visible };
      this.appInfoService.saveUserPreference(PreferencesKeys.layer(this.panelId, event.layerId), layerUserPreferences);
    }

    await this.toggle(event.layerId, event.visible, this.defaultLoadingOptions, true);
  }

  public async toggle(
    _: LayerId,
    visible: boolean,
    options: LayerLoadingOptions,
    manualToggle: boolean = false,
  ): Promise<void> {}

  public isLayerVisible(layerId: string, layer: LayerSettings): boolean {
    const layerUserPreferences = this.appInfoService.getUserPreference<LayerUserPreferences>(
      PreferencesKeys.layer(this.panelId, layerId),
    );

    return (
      !layer.heavy && layerUserPreferences !== undefined
        ? layerUserPreferences.visible
        : (layer.visible ?? false) // For layers where heavy is true, visible is always undefined
    );
  }

  public onexport(exportConfig: ExportConfig | CompleteExport) {}

  public export(completeExport: CompleteExport): void {
    if (!completeExport.config.filename || completeExport.config.filename === 'undefined') {
      completeExport.config.filename = this.dashboardTitle;
    }

    this.dialogManager.panelLoading('export', 'Preparing data for export...');
    setTimeout(() => {
      if (completeExport.isSpecific === true) {
        this.browser.exportXls(completeExport.exportData, completeExport.config.filename);
      } else {
        const data = this.browser.prepareExport(completeExport);
        this.browser.exportXls(data, completeExport.config.filename);
      }
      this.dialogManager.panelLoaded('export');
    }, 200);
  }

  /**
   * @param settings
   * @param modalComponentState
   *
   * Modal's is in most cases linked to some data-item: row in a table, summary on page.
   * Variables specified in the *params* of the modal button are sent to the modal,
   * which passes them to component-wrapper and are considered the modals state.
   * they will potentially override any global state variables.
   * In that regards they are just used to fill the *ownFiltersState* of component-wrapper.
   */
  public generateChartModal(settings: ChartModalConfig, modalComponentState: FiltersState): void {
    const ref = this.dialog.open(ChartModalComponent, {
      maxHeight: '95vh',
      minHeight: '40%',
      panelClass: ['modal-container', 'modal-chart'],
      minWidth: '60vw',
      autoFocus: false,
      data: {
        component: settings,
        modalComponentState,
      },
    });
    ref.componentInstance.onaction.subscribe(
      (actionEvent: ActionEvent) => {
        this.onaction(actionEvent);
      },
    );
  }

  public generateTableModal(
    settings: EntityTableComponentSettings,
    modalComponentState: FiltersState,
  ): void {
    const ref = this.dialog.open(TableModalComponent, {
      maxHeight: '80vh',
      minHeight: '20%',
      panelClass: 'modal-container',
      data: {
        component: settings,
        modalComponentState,
      },
    });
    ref.componentInstance.onaction.subscribe(
      (actionEvent: ActionEvent) => {
        this.onaction(actionEvent);
      },
    );
    ref.componentInstance.exportEmitter.subscribe((event: CompleteExport) => {
      this.export(event);
    });
  }

  /**
   * Generic action that will be executed after an entity has been saved
   * to be overriden by each specific dashboard
   */
  abstract dashboardSpecificAfterEntitySave: (data: AfterSave) => void;
  abstract openSelector(tab?: string);

  /**
   * Open dialog box to create or compare Drawio diagram
   * @param isDrawioForCompare whether we want to create a diagram or compare to another
   */
  public async openCreateOrCompareDrawioDiagrams(isDrawioForCompare: boolean): Promise<void> {
    let dprRefsData: ReportingRefData;
    try {
      dprRefsData = await this.dataLoader.get(DprEndpoints.DPR_REF_DATA);
    } catch (error) {
      this.dialogManager.showMessage(error, 'error');
      return;
    }
    const dialogRef = this.dialog.open(DprDrawioDiagramDialogComponent, {
      width: '300px',
      maxHeight: '600px',
      data: {
        isForCompare: isDrawioForCompare,
        vessels: dprRefsData.vessel,
        projects: dprRefsData.project,
        documentTypes: dprRefsData.documentType,
      } as DrawioDiagramData,
    });

    dialogRef.afterClosed().subscribe(async (result: DrawioDiagramRequestParams) => {
      if (result) {
        this.dialogManager.showMessage('Generating drawio diagram, this may take some time', 'warn');
        try {
          const drawio = await this.dataLoader.get<unknown>(DataLoader.drawioDiagramUrl(result));
          window.open(drawio['editUrl'], '_blank');
        } catch (error) {
          this.dialogManager.showMessage(error, 'error');
        }
      }
    });
  }

  /**
   * Check DPR full config,
   * if there is some errors, open a new tab with the list of errors
   */
  private async postprocessReportingVoyages(): Promise<void> {
    this.dialogManager.showMessage('Post processing all voyages, this can take some time...', 'warn');
    try {
      await this.dataLoader.post('/spindjango/reporting/postprocess-voyages', { forceUpdate: true });
    } catch (error) {
      this.dialogManager.showMessage('An error occured, please contact a member of the tech team.', 'error');
      console.error(
        new ErrorWithFingerprint(
          `[SFM-R] Error while postprocessing voyages: ${error.toString()}`,
          ['sfm-r-error-postprocessing-voyages'],
        ),
      );
      return;
    }
    this.dialogManager.showMessage('Voyages postprocessed!', 'success');
  }

  /**
   * Check DPR full config,
   * if there is some errors, open a new tab with the list of errors
   */
  private async checkReportingFullConfig(): Promise<void> {
    this.dialogManager.showMessage('Checking config, this may take a while..', 'warn');
    let resp: object;
    try {
      resp = await this.dataLoader.get('/spindjango/reporting/config/check-full-config', { forceUpdate: true });
    } catch (error) {
      console.error(
        new ErrorWithFingerprint(
          `[SFM-R] Error while checking the config: ${error.toString()}`,
          ['sfm-r-error-checking-config'],
        ),
      );
      return;
    }
    if (!Object.keys(resp).length) {
      this.dialogManager.showMessage('Reporting config is valid', 'success');
    } else {
      const formatted_checks = JSON.stringify(resp, null, 2).replace(/[{}",]/g, '');
      const win = window.open();
      if (!win) {
        this.dialogManager.showMessage(
          'To view the config, please allow this page to open popups in you browser settings',
          'error',
        );
        return;
      }

      win.document.open();
      win.document.write('<html><body><pre>' + formatted_checks + '</pre></body></html>');
      win.document.close();
    }
  }

  public afterEntitySave = async (data: AfterSave): Promise<void> => {
    const refDataConfig = RefDataProvider.getRefDataConfigFromEntityName(data.entityName);
    if (refDataConfig) {
      const item = await RefDataProvider.refreshRefDataItem(data.idToReload, refDataConfig);
      data.reloadedLocalEntity = item;
    }

    this.dashboardSpecificAfterEntitySave(data);
  };

  /**
   * TODO: implement a better after entity delete, that only reloads concerned components.
   */
  public afterEntityDelete(): void {
    this.reloadData();
  }

  public removeFromActiveLayerList(
    componentState: ComponentState,
    layerId: LayerId,
  ): void {
    if (!componentState.active) return;

    const i = componentState.active.indexOf(layerId);
    if (i > -1) {
      componentState.active.splice(i, 1);
    }
  }

  public addToActiveLayersList(
    componentState: ComponentState,
    layerId: LayerId,
  ): void {
    if (!componentState.active) {
      componentState.active = [layerId];
      return;
    }
    const i = componentState.active.indexOf(layerId);
    if (i === -1) componentState.active.push(layerId);
  }

  public isLayerActive(componentState: ComponentState, layerId: LayerId): boolean {
    return componentState.active?.indexOf(layerId) > -1;
  }

  public currentOrDefaultTimeframe(layer: StandardLayerState<RawDataPoint>): IntervalOrNull {
    const layerId = layer.id;
    const layerConf = layer.settings;
    /*
     * The requested timeframe either comes from the current state
     * or from the default options
     */
    if (this.state[layerId] && this.state[layerId].datetime) {
      /*
       * The datetime parameter is now handled as a single query parameter
       * We need to transform it into an array with 2 elements (start data & end date)
       */
      let dateArray = this.state[layerId].datetime;
      if (dateArray.length == 1) {
        dateArray = dateArray[0].split(',');
      }
      const startLoad = +dateArray[0];
      const endLoad = +dateArray[1];
      return [startLoad, endLoad];
    } else if (layerConf.defaultPeriod) {
      /*
       * the granularity of period that we request is "datetime"
       * which will give the period according to the config to "now"
       * eg: Last 2 days (up-to-now)
       */
      const period = DateHelper.period({ era: layerConf.defaultPeriod });
      this.state[layerId].datetime = period;
      return period;
    } else {
      return null;
    }
  }

  public onButtonClick(button: Button) {
    const buttonEvent = NavigationHelper.constructActionEvent({ action: button });
    this.onaction(buttonEvent);
  }

  public switchSidebar(hide: boolean) {
    this.sidebarHidden.set(hide);
    this.cdRef.detectChanges();
    this.afterSidebarSwitch(hide);
  }

  public width: string = '0px';
  public getSidebarStyle(): Partial<CSSStyleDeclaration> {
    this.width = this.sidebarCollapsed() ? '0px' : 'auto';
    return this.sidebarCollapsed()
      ? {
        'width': this.width,
        'visibility': 'hidden',
      }
      : {
        'width': this.width,
        'visibility': 'visible',
        'zIndex': '2',
      };
  }

  public exportPng(exportParameters: PngExport) {
    if (this.dashboardTitle) {
      exportParameters.title = exportParameters.title
        ? `${this.dashboardTitle} - ${exportParameters.title}`
        : this.dashboardTitle;
    }

    this.wrapAndExportPng(exportParameters);
  }

  public async wrapAndExportPng(exportParameters: PngExport) {
    const svg = exportParameters.svg;
    const width = exportParameters.width;
    let height = exportParameters.height;

    const currentX = 10;
    const bigFont = Math.round(width / 60);

    if (exportParameters.title) {
      svg.insert('svg', ':first-child')
        .append('text')
        .attr('x', currentX)
        .attr('dy', '0.35em')
        .attr('y', bigFont)
        .attr('font-size', bigFont + 'px')
        .attr('font-weight', 800)
        .attr('class', 'export-title')
        .text(exportParameters.title);
    }
    svg.selectAll(`.${exportParameters.contentId}`).attr('transform', translate(0, bigFont));

    height += bigFont;
    svg
      .attr('font-family', Config.SPIN_DATA_FONT);

    await this.browser.exportSvgToPng(exportParameters);

    exportParameters.afterExport();
  }

  /**
   * Iterate through common filters and layers filters to get
   * all fieldsets config. This function can be override by each
   * dashboard if these dashboards contains specific fieldsets
   */
  public getAllFieldsetsConfig(): Fieldset[] {
    const fieldsets: Fieldset[] = [];
    if (this.selector?.fieldsets) {
      fieldsets.push(...this.selector.fieldsets);
    }
    if (this.commonFiltersFieldsets) {
      fieldsets.push(...this.commonFiltersFieldsets);
    }
    return fieldsets;
  }

  /**
   * Iterate through common filters and layers filters to
   * retrieve titles corresponding to applied 'id' filters
   */
  protected retrieveFiltersTitles(_: LayerFilter, __: LayerId) {
  }

  public getDashboardExtraFields(): FieldSettings[] {
    return [];
  }

  public reactivateSessionReplayIfNeeded(): void {
    return;
  }

  /**
   * Reload the page config (json files) instead of reloading the whole app (by Ctrl + R)
   *
   *  This is an option for dev mode.
   *
   *  This method must be overriden by children classes - see components-page for example.
   */
  public reloadPageConfig(): void {
    console.info(`reloadPageConfig() is not implemented by ${this.constructor.name}.`);
  }
}
