import { Injectable, Injector, OnDestroy } from '@angular/core';

import dayjs from 'dayjs';
import { max } from 'lodash-es';
import { Subject, Subscription, delayWhen } from 'rxjs';
import Dexie from 'dexie';

import { PersistentDbConfig } from '../database/local/persistent-db.types';
import { PERSISTENT_CACHE_TABLE_NAME, PersistentCacheConfig, PersistentCacheDatabaseEntry,
  PersistentCacheDatabaseSchema, PersistentCacheEndpointConfig } from './persistent-cache.types';
import { PersistentDb } from '../database/local/persistent-db';
import { Config } from '../config/config';
import { DataLoader } from './data-loader';
import { DateHelper } from '../helpers/date-helper';
import { NavigationHelper } from '../helpers/navigation-helper';
import { ResponseWithMetadata } from './data-loader.types';
import { RefDataProvider } from './ref-data-provider';

/** Manages cached endpoints data */
@Injectable(
  {
    providedIn: 'root',
  },
)
export class PersistentCacheService implements OnDestroy {
  private static readonly PERSISTENT_DB_CONFIG: PersistentDbConfig<PersistentCacheDatabaseSchema> = {
    databaseVersion: {
      major: 2,
      minor: 2,
    },
    databaseName: 'persistent_cache',
    databaseSchema: {
      /**
       * Unique index on url, index on endpoint and compound index on url + cachedTimestamp.
       */
      [PERSISTENT_CACHE_TABLE_NAME]: '&url, endpoint, [url+cachedTimestamp]',
    },
  };
  private static readonly CONFIG_URL = '/base/db/frontend-persistent-cache-validity';

  private serviceConfig: PersistentCacheConfig;
  private config: Config;
  private persistentDb: PersistentDb<PersistentCacheDatabaseSchema>;
  private storingQueue$ = new Subject<{ url: string; response: ResponseWithMetadata }>();
  private storingQueueSub: Subscription;

  constructor(injector: Injector) {
    this.config = injector.get(Config);
  }

  public ngOnDestroy(): void {
    this.storingQueueSub?.unsubscribe();
  }

  /** Disabled for impersonate, because perf are not at stake and we don't want the IndexedDb to grow too much. */
  public get isEnabled(): boolean {
    return !this.config.impersonate;
  }

  /**
   * @param dataLoader should be provided here to avoid circular dependency
   */
  public async setup(dataLoader: DataLoader): Promise<void> {
    this.serviceConfig = await dataLoader.get<PersistentCacheConfig>(PersistentCacheService.CONFIG_URL);
    await this.setupDb(this.config.userInfo.spinergieUserEmail);
    this.startStoring();
  }

  private async setupDb(userEmail: string): Promise<void> {
    this.persistentDb = new PersistentDb(PersistentCacheService.PERSISTENT_DB_CONFIG, userEmail);
    await this.persistentDb.setup();
  }

  /**
   * Delete cache entries found in browser IndexedDb but not in cached endpoints config.
   * Note: this operation can be long if some endpoints have been removed from `frontend-persistent-cache-config.json5`,
   * otherwise it would be fast.
   */
  public prune(): Promise<number> {
    const cachedEndpointList = this.serviceConfig.cachedEndpoints.map(entry => entry.endpoint);

    console.info('Deleting cache entries of endpoints not in config anymore.');

    return this.persistentDb.query(
      PERSISTENT_CACHE_TABLE_NAME,
      table =>
        table
          .where('endpoint')
          .noneOf(cachedEndpointList)
          .delete(),
    );
  }

  /**
   * Retrieve cache of given url.
   * Beware! hasValidCache should be called before.
   * @param url
   * @returns Promise<undefined> if not found or invalid.
   */
  public async retrieve<T>(url: string): Promise<T> {
    console.info(`Retrieving ${url} from persistent cache`);

    return this.persistentDb.get<PersistentCacheDatabaseEntry<T>>(
      PERSISTENT_CACHE_TABLE_NAME,
      url,
    ).then(entry => entry.data);
  }

  /**
   * @returns Promise(false) if cache config not found or cache not valid anymore
   */
  public hasValidCache(url: string): Promise<boolean> {
    const cachedEndpointConfig = this.findCachedEndpointConfig(url);
    if (cachedEndpointConfig === undefined) return Promise.resolve(false);

    const lastInvalidatedTimestamp = PersistentCacheService.computeLastInvalidatedTimestamp(
      cachedEndpointConfig,
      this.serviceConfig.serverTime,
    );

    return this.persistentDb.query(
      PERSISTENT_CACHE_TABLE_NAME,
      table =>
        table
          /**
           * We use where + between with a compound key to efficiently do `url == url AND cachedTimestamp >
           * lastInvalidatedTimestamp`
           */
          .where('[url+cachedTimestamp]')
          .between([url, lastInvalidatedTimestamp], [url, Dexie.maxKey])
          .count(count => count === 1),
    );
  }

  /**
   * For a given url, retrieves the lastInvalidatedTimestamp.
   * This is a coalesce between endpoint lastInvalidatedTimestamp and ttl.
   */
  public static computeLastInvalidatedTimestamp(
    cachedEndpointConfig: PersistentCacheEndpointConfig,
    serverTime: number,
  ): number {
    const ttlStartTime = DateHelper.getTtlStartTime(cachedEndpointConfig.ttl, dayjs.utc(serverTime));
    return max([cachedEndpointConfig.lastInvalidatedTimestamp, ttlStartTime]);
  }

  /**
   * Check if given url matches a `persistent-cache-config.json` cached endpoint.
   */
  public shouldCache(url: string): boolean {
    return this.findCachedEndpointConfig(url) !== undefined;
  }

  /**
   * For a given url, finds the corresponding cache config.
   * @returns undefined if not found
   */
  private findCachedEndpointConfig(url: string): PersistentCacheEndpointConfig | undefined {
    const endpoint = NavigationHelper.getUrlPath(url);
    return this.serviceConfig?.cachedEndpoints.find(entry => entry.endpoint === endpoint);
  }

  /**
   * Store url data in cache, ResponseWithMetadata should be passed to access __serverTime
   * Beware: Should call `shouldCache(url)` before
   */
  public async store(url: string, { data, __serverTime }: ResponseWithMetadata): Promise<unknown> {
    console.info(`Storing ${url} in persistent cache`);

    return this.persistentDb.put(PERSISTENT_CACHE_TABLE_NAME, {
      url,
      endpoint: NavigationHelper.getUrlPath(url),
      cachedTimestamp: __serverTime,
      data,
    });
  }

  /**
   * The storing is done asynchronously thanks to a queue. `startStoring` should be called too.
   */
  public addToStoringQueue(url: string, response: ResponseWithMetadata): void {
    this.storingQueue$.next({ url, response });
  }

  /**
   * Would start storing what's in the storingQueue, after RefDataProvider.initialDataLoaded$ send its value
   */
  private startStoring(): void {
    /** Only the first event would be delayed until initialDataLoaded$ is emitted. */
    this.storingQueueSub = this.storingQueue$.pipe(delayWhen(() => RefDataProvider.initialDataLoaded$)).subscribe((
      { url, response },
    ) => this.store(url, response));
  }
}
