import tinycolor from 'tinycolor2';

import { Color, HEX } from './types';

export const SPINERGIE_DEFAULT_GREEN = '#009688';

/**
 * Default blue and reverse blue, used for instance for the header when no custom color is configured.
 */
export const SPINERGIE_BLUE: HEX = '#035785';

export class ColorHelper {
  static readonly colors: Color[] = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
    '#aec7e8',
    '#ffbb78',
    '#98df8a',
    '#ff9896',
    '#c5b0d5',
    '#c49c94',
    '#f7b6d2',
    '#c7c7c7',
    '#dbdb8d',
    '#9edae5',
  ];
  static quantilesColors: { [key: string]: Color } = {
    q10: 'rgb(159, 197, 232)',
    q10_alpha: 'rgba(159, 197, 232, 0.63)',
    q25: 'rgb(95, 158, 206)',
    q25_alpha: 'rgba(31, 119, 180, 0.5)',
    median: 'rgb(31, 119, 180)',
    average: 'rgb(127, 127, 127)', // or standard 'blue': 'rgb(31, 119, 180)',
  };
  static notAvailableColor: Color = 'lightgrey';
  static barOpacity = 0.825;
  public static getColorScale(): Color[] {
    return this.colors;
  }
  public static getNotAvailableColor(): Color {
    return this.notAvailableColor;
  }

  public static pickFromScale(index: number, isNotAvailable: boolean): Color {
    const colors = this.getColorScale();
    return isNotAvailable ? this.getNotAvailableColor() : colors[index % colors.length];
  }

  public static applyOpacity(color: Color, opacity: number): Color {
    return tinycolor(color).setAlpha(opacity).toRgbString() as Color;
  }

  public static applyBarOpacity(color: Color): Color {
    return ColorHelper.applyOpacity(color, ColorHelper.barOpacity);
  }
}
