<pearl-form-field
  [small]="small()"
  [highlighted]="highlighted()"
  [hasLabel]="hasLabel()"
  [label]="searchLabel()"
  (click)="onClickInput()"
  [hintError]="!!errorMessage"
  [readonly]="readonly"
  [errorMessage]="errorMessage"
  [iconPrefix]="showIcon() ? 'search' : null"
  [hintStart]="hint">
  <input
    #input
    matInput
    type="text"
    [matAutocomplete]="auto"
    [placeholder]="searchLabel() | truncateString: 27"
    [disabled]="disabled"
    (click)="onClickInput()"
    (input)="onChangeSearchValue($any($event.target).value)"
    (keydown)="$event.stopPropagation()"
    [value]="searchBarInput"
    autocomplete="off"
    [required]="required" />
  <mat-autocomplete
    #auto="matAutocomplete"
    #searchAutoComplete
    [displayWith]="optionDisplay"
    (optionSelected)="optionSelected($event.option.value)">
    <ng-container *ngFor="let option of searchResults; trackBy: trackSearchOption">
      <mat-option [value]="option" [ngClass]="{ 'disabled-option': option.isOutOfScope }">
        <a
          *ngIf="option.routerLink && !option.isOutOfScope; else simpleResult"
          class="search-link"
          [href]="option.routerLink"
          (click)="onClickOption($event)">
          <ng-template *ngTemplateOutlet="searchOption; context: { option: option }"> </ng-template>
        </a>
        <ng-template #simpleResult>
          <ng-template *ngTemplateOutlet="searchOption; context: { option: option }"> </ng-template>
        </ng-template>
      </mat-option>
    </ng-container>

    <mat-option *ngIf="showMoreVisible" (click)="showAll($event)">
      <div class="option border-top">{{ showMoreText }}</div>
    </mat-option>
    <mat-option *ngIf="canRequestVessel" (click)="requestVessel(null)">
      <div class="option border-top multiline">
        <small>The vessel you are looking for is not in the list?</small>
        <span class="request-form">Request to add a vessel</span>
      </div>
    </mat-option>
  </mat-autocomplete>
  <ng-container pearlFormSuffix>
    <ng-content select="[sync-button]"></ng-content>
    <description-button matSuffix [description]="description" [iconFontSizePx]="20" />
  </ng-container>
</pearl-form-field>

<!-- mat-option template -->
<ng-template #searchOption let-option="option">
  <div class="option" [ngClass]="{ 'option-email': option.type === 'user' }">
    <pearl-icon *ngIf="option.icon" [class.disabled-option]="option.isOutOfScope" [icon]="option.icon" [size]="24" />
    <div class="option-text">
      <div class="option-title">
        <div [id]="getOptionHTMLId(option)">{{ option.title }}</div>
        <div>
          <pearl-button
            matTooltip="This vessel is currently not part of your subscription. Click to request access"
            matTooltipPosition="above"
            *ngIf="option.isOutOfScope"
            icon="add"
            size="small"
            type="secondary"
            (click)="requestVessel(option)">
            Add
          </pearl-button>
          <pearl-icon
            [matTooltip]="option.description"
            *ngIf="option.description"
            [size]="24"
            class="description-button"
            icon="information" />

          <pearl-icon *ngIf="option.historyIndex > -1" class="history-icon" icon="history" [size]="24" />
        </div>
      </div>
      <div>
        <small>{{ option.typeTitle }} </small>
        <small *ngIf="option.subtitle">({{ option.subtitle }})</small>
      </div>
      <div class="option-info">
        <span *ngIf="option.additionalSearchInfo">{{ option.additionalSearchInfo }}</span>
      </div>
    </div>
  </div>
</ng-template>
