import { Config } from '../config/config';
import { ActionEvent, EntityFieldDefinition, FieldSettings, Fieldset, FilterApplied, LayerFilter, NumOrString,
  SpinProduct } from '../helpers/types';
import { SelectableEntityConfig, SelectableEntityInfo, SelectorConfig, SelectorState } from './selector.types';
import { FilterHelper } from '../filters/filter-helper';
import { PageLinkHelper } from '../helpers/page-link-helper';

export class SelectorHelper {
  public static getNumberAdvancedFilterApplied(advancedFilters: LayerFilter): number {
    let count = 0;
    const filters = Object.values(advancedFilters ?? {});
    filters.forEach(filter => {
      if (filter.active === false || filter.notApplied) {
        return;
      }
      if (filter.filterType === 'checkbox' && (!filter.values || !filter.values.length || !filter.values[0])) {
        return;
      }
      if (filter.filterType === 'excludemulti' && !filter.values.length) {
        return;
      }
      count++;
    });
    return count;
  }

  /** Return fields that are in both selectorFieldsets and standardFieldsets. */
  public static getStandardFieldsFromSelector(
    selector: SelectorConfig,
    standardFieldsets: Fieldset[] = [],
  ): FieldSettings[] {
    const standardFieldIds = FilterHelper.flattenFieldsetsIntoFields(standardFieldsets).map(f => f.id);
    const selectorFields = FilterHelper.flattenFieldsetsIntoFields(selector.fieldsets);

    return selectorFields.filter(field => standardFieldIds.includes(field.id) === true);
  }

  /**
   * Return fields that are in selectorFieldsets but not in standardFieldsets, by definition those are advancedFilters.
   */
  public static getAdvancedFieldsFromSelector(
    selector: SelectorConfig,
    standardFieldsets: Fieldset[] = [],
  ): FieldSettings[] {
    const standardFields = FilterHelper.flattenFieldsetsIntoFields(standardFieldsets);
    return FilterHelper.getFieldsetsFields(selector?.fieldsets, standardFields.map(f => f.id));
  }

  public static getSelectorTitleProp(specName: string, product: SpinProduct): string {
    if (product === 'spinrig' && specName === 'rig') {
      return 'vessel';
    }

    return specName;
  }

  public static getSelectorButtonLabel({ entity }: SelectorConfig): string {
    return `${entity.name} selection`;
  }

  /*
   * From standard and selector fieldsets, split filters between standard and advanced filters.
   * Filters that neither are on standard fieldsets nor on selector ones are not returned.
   */
  public static splitSelectorFilters(
    filters: LayerFilter,
    standardFieldsets: Fieldset[],
    selectorFieldsets: Fieldset[],
  ): { standardFilters: LayerFilter; advancedFilters: LayerFilter } {
    const standardFieldIds = FilterHelper.flattenFieldsetsIntoFields(standardFieldsets).map(f => f.id);
    const advancedFieldIds = FilterHelper.getFieldsetsFields(selectorFieldsets, standardFieldIds).map(f => f.id);

    const standardFilters = FilterHelper.getFiltersMatchingIds(filters, standardFieldIds);
    const advancedFilters = FilterHelper.getFiltersMatchingIds(filters, advancedFieldIds);

    return { standardFilters, advancedFilters };
  }

  /** From a filters and selectorFieldsets, returns filters that are not in selector. */
  public static getFiltersNotInSelector(
    filters: LayerFilter,
    selectorConfig: SelectorConfig,
  ): LayerFilter {
    const filtersNotInSelector = {};

    const selectorFieldIds = FilterHelper.flattenFieldsetsIntoFields(selectorConfig.fieldsets).map(f => f.id);
    selectorFieldIds.push(selectorConfig.entity.filterId);

    Object.keys(filters).forEach(filterId => {
      if (!selectorFieldIds.includes(filterId)) {
        filtersNotInSelector[filterId] = filters[filterId];
      }
    });

    return filtersNotInSelector;
  }

  /**
   * From a SelectableEntityConfig and main Config, compute properties url and pageLinkHelper.
   * Config is used to compute pageLinkHelper and check if user fleet has to be skipped.
   */
  public static buildEntityInfo(
    { name, titleProp, idProp, url, filterId }: SelectableEntityConfig,
    config: Config,
  ): SelectableEntityInfo {
    return {
      name,
      titleProp,
      idProp,
      filterId,
      /**
       * If given entity is vessel and skipUserFleet is set, take whole client vessel scope instead of userVesselFleet
       * as input data.
       */
      url: name === 'vessel' && config.skipUserFleet ? '@local<items>vessel' : url,
      pageLinkHelper: PageLinkHelper.inferFromKey(name, config.availablePages),
    };
  }

  /**
   * Used to pre-filter the selector by the entities actually available on the dashboard data. This is done by getting
   * the populate values of the field corresponding to the selector entity.
   * @param selectorConfig
   * @param standardFieldsets the entity field would be found on those fieldsets
   * @param vesselField can also be the entity field whether we are in vessel layer with special vessel field
   * @returns an empty Set if entity field not found or without populate values, otherwise a Set with populate values.
   */
  public static getEntityIdsFromSidebarPopulateValues(
    { entity }: SelectorConfig,
    standardFieldsets: Fieldset[],
    vesselField?: EntityFieldDefinition,
  ): Set<number> {
    const entityIds = new Set<number>([]);
    // retrieve entity field config
    const entityField = FilterHelper.findFieldInFieldsets(
      standardFieldsets,
      f => f.id === entity.filterId,
      vesselField ? [vesselField] : [],
    );

    if (entityField) {
      entityField.values?.forEach(v => entityIds.add(v.value));
    }
    return entityIds;
  }

  /* For now, this function is only used by the comparator selector, so the wording is "compared". */
  public static computeMaxSelectionMsg(maxItemCount: number): string {
    return `Only ${maxItemCount} elements can be compared simultaneously.`;
  }
}
