import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { FilterHelper } from '../filters/filter-helper';
import { AppConfig, DashboardCommonSettings, DashboardLayerSettings, DashboardSettings, DevSettings,
  MapDashboardSettings, PageConfig, SearchedConfigResult, UrlSubdirectory,
  WeatherLayerConfig } from '../helpers/config-types';
import { DatabaseEntity, DateTimezone, Feature, Fieldset, IdentityItem, MenuItem, OsvProject, SpecName, SpinProduct,
  UserInfo } from '../helpers/types';
import { VesselFleet } from '../user-saving/user-saving-types';
import { AvailablePages } from '../helpers/page-link-helper';
import { NavigationHelper } from '../helpers/navigation-helper';
import { getNumberListFromString } from '../helpers/data-helpers';
import { DialogManager } from '../database/dialog-manager';
import { environment } from '../environments/environment';
import { RefDataProvider } from '../data-loader/ref-data-provider';
import { getLocalRequestInfo } from '../data-loader/local-loader';
import { AppContextResponse, AppParams, UserRights } from '../helpers/app-types';
import { DataLoader } from '../data-loader/data-loader';

@Injectable()
export class Config {
  private http: HttpClient;
  private router: Router;
  private dialogManager: DialogManager;

  public gmapsLoadedResolve: (loaded: boolean) => void;
  public readonly gmapsLoadedPromise: Promise<boolean> = new Promise((resolve, _) => {
    this.gmapsLoadedResolve = resolve;
  });

  public specsLoadedResolve: (loaded: boolean) => void;
  public readonly specsLoadedPromise: Promise<boolean> = new Promise((resolve, _) => {
    this.specsLoadedResolve = resolve;
  });

  public appConfig: AppConfig;
  public userRights: UserRights;
  public appParams: AppParams;
  public userInfo: UserInfo;
  public devSettings: DevSettings;

  public dashboards: { [dashboardId: string]: boolean } = {};
  public urls: { [url: string]: boolean } = {};

  public rightMenuDashboards: MenuItem[] = [];
  public searchItemsAvailable: IdentityItem[] = null;
  public itemPageTitle: { [itemType: string]: { [itemId: string]: { title: string; fullTitle: string } } } = {};
  public searchItemsReceived = new BehaviorSubject<boolean>(false);

  public static SPINPERSONATE = '__spinpersonate';
  public static SPINDEVSETTINGS = '__spinDevSettings';
  public static SELECTEDFLEETS = '__selectedFleets';
  public static SELECTED_PROJECTS = '__selectedProjectIds';

  public static SPIN_DATA_FONT = 'Roboto, sans-serif';
  public static SPIN_DEFAULT_FONT_SIZE = 12;

  public static SPIN_LOGO_PATH = '/assets/img/spinlogo.png';
  public static SPIN_MIN_DATE = 1451606400000; // 2016-01-01 00:00 UTC
  public static MARKET_INTEL_PRODUCTS: SpinProduct[] = ['construction', 'spinrig'];
  // filters that persist over sessions, i.e stored in sessionStorage
  public static BROWSER_GLOBAL_FILTERS = [Config.SELECTEDFLEETS, Config.SELECTED_PROJECTS];
  public static SELECTED_PROJECTS_RE = new RegExp(`${Config.SELECTED_PROJECTS}\\=(.*?)(\\?|$|;|&)`);
  public static SELECTED_FLEETS_RE = new RegExp(`${Config.SELECTEDFLEETS}\\=(.*?)(\\?|$|;|&)`);

  public searchLabel: string = '';
  public searchBarResultOrder: string[] = [];

  public impersonate: string;
  public selectedFleets: number[] = [];
  public selectedOsvProjectIds: number[] = [];

  public isFullAdmin: boolean;
  public isAppOwner: boolean;
  public canImpersonate: boolean;
  public trialModeDownloadsDisabled: boolean;
  public spinergieUser: string;
  public product: SpinProduct;

  public usersAndGroups: IdentityItem[] = [];

  /** Holds the promise of the HTTP request for the config data */
  private __loadingPromise: Promise<AppContextResponse>;

  /**
   * TODO: from @tourfl, I think this logic of having a list in the front-end to tell which filters should be persistent
   * is quite obscure.
   * Maybe this list should belong to the project.json config?
   */
  public static readonly PERSISTENT_FILTER_IDS = [
    'currentCountry',
    'currentRegion',
    'currentOperator',
    'currentContractStatus',
    'currentActivityStatus',
    'country',
    'developer',
    'region',
    'operator',
    'vessel',
    'vesselType',
    'project',
    'port',
    'quay',
    'basePoiType',
    'basePoiCountry',
    'basePoiRegion',
    'basePoiManager',
    'basePoiStatus',
  ];
  public persistentFilterState = {};

  public vesselFleetVisible: boolean = false;
  public vesselFieldsets: Fieldset[] = [];
  public lastFleetCreatedId: number;
  /*
   * This boolean is used to know if the current page or dashboard (currently our usecase is only in case of a page)
   * should ignore the user vessel fleet and call the endpoints with the full vessel fleet
   * This is the case for vessel page and manager page for exemple
   */
  public skipUserFleet: boolean = false;
  /*
   * This boolean is used to know if the current page or dashboard
   * should ignore the persistent filters
   */
  public skipPersistentFilters: boolean = false;
  public skipSaveAnalysis: boolean = false;
  public loadingConfigPromise: Promise<void> = null;
  /** True when the config has been loaded */
  public loaded: boolean = false;
  /** Vessel fleets available for the current user */
  private availableVesselFleets: VesselFleet[];

  constructor(private injector: Injector) {
    this.http = injector.get(HttpClient);
    this.router = injector.get(Router);
    this.dialogManager = injector.get(DialogManager);
  }

  public get isMarketIntel(): boolean {
    return Config.MARKET_INTEL_PRODUCTS.includes(this.product);
  }

  public get isLightAdmin(): boolean {
    return this.hasFeature('light-admin');
  }

  public get isInternal(): boolean {
    return this.hasFeature('internal_use', { bypassForAdmin: false });
  }

  public get isLightOrFullAdmin(): boolean {
    return this.isFullAdmin || this.isLightAdmin;
  }

  /**
   * Tooltip tells that XLSX downloads are disabled during trial mode.
   * noExport is set to true in config when the user doesn't have the matching download feature
   */
  public getXlsxDownloadTooltip(noExport: boolean = false): string {
    return this.trialModeDownloadsDisabled || noExport
      ? 'XLSX downloads are disabled during trials'
      : 'Download XLSX';
  }

  /** The promise of the HTTP request for the config data */
  public get loadingPromise(): Promise<AppContextResponse> {
    return this.__loadingPromise;
  }

  get availablePages(): AvailablePages {
    // lazy loading -- delete getter and reset a variable with same name
    delete this.constructor.prototype.availablePages;

    const enabledPagesList = this.appConfig.pages.filter(p =>
      this.appConfig.enabledDashboards.includes('page/' + p.id) && p.idField
    );

    return this.constructor.prototype.availablePages = new AvailablePages(enabledPagesList);
  }

  public getWeatherLayerConfig(weatherLayerId: string): WeatherLayerConfig {
    return this.appConfig.weatherLayersConfig?.find(wl => wl.id === weatherLayerId);
  }

  public getPageConfigFromRoute(route: string): PageConfig {
    const { type, pageId } = NavigationHelper.getRouteInfo(route);

    return this.appConfig[`${type}s`]?.find(page => page.id === pageId)
      ?? { id: pageId, title: pageId.replace('-', ' ') };
  }

  public async getUsersAndGroups() {
    if (!this.canImpersonate) {
      return [];
    }

    const fullUrl = this.completeUrl('/spinadmin/product-packages/right-search-items/');
    this.usersAndGroups = await this.http.get(fullUrl).toPromise() as any;
  }

  /**
   * Read default timezones defined in DPR configs
   *
   * @return {array}  Timezones as { title, code }
   */
  public async getDefaultTimezones(): Promise<Array<{ title: string; code: string }>> {
    if (this.product !== 'osv') {
      return [];
    }

    const fullUrl = this.completeUrl('/osv/dpr-config-summary');
    const dprConfig = await this.http.get(fullUrl).toPromise() as any;
    const timezones = {};

    dprConfig.forEach(conf => {
      const code = conf.dprTimezone;
      if (code === 'utc' || code === 'UTC' || timezones[code]) {
        return;
      }
      timezones[code] = { code, title: code };
    });

    return Object.values(timezones);
  }

  private generateSearchLabelAndLink() {
    // Use to get type availables
    const itemTypeSet = new Set<string>();
    for (const item of this.searchItemsAvailable) {
      const itemLink = `/dashboard/page/${item.type}?${item.idField}=${item.id}`;
      item.routerLink = itemLink;
      itemTypeSet.add(item.type);
    }

    const availablePages = this.appConfig.pages
      .filter(page => [...itemTypeSet].includes(page.id));

    // Construct search label by respecting project page config order
    this.searchLabel = availablePages
      .map(page => page.title)
      .join(', ');

    // search bar result order are stocked without capital letters
    this.searchBarResultOrder = availablePages.map(page => page.id.toLowerCase());
  }

  /**
   * Get the default dashboard from the application config.
   * The default dashboards are defined in the project config. We return the first one available to the user.
   * If none of the default dashboards is available to the user, we simply return the first available dashboard.
   *
   * @param config    The app config.
   * @returns         The default dashboard ID
   */
  public static getDefaultDashboard(config: AppConfig, product: string): string {
    const availableDashboards = config.enabledDashboards;

    if (!availableDashboards.length) {
      console.error('Empty dashboard list - Cannot determine default dashboard');
      return '';
    }
    let defaultDashboard: string;
    // defaultDashboards is not necessarily set
    if (config.defaultDashboards) {
      for (const dashboard of config.defaultDashboards) {
        if (availableDashboards.includes(dashboard)) {
          defaultDashboard = dashboard;
          break;
        }
      }
    }
    // Fallback to the first available dashboard
    defaultDashboard = defaultDashboard ?? availableDashboards[0];

    /*
     * Special case for analysts project
     * Its dashboards are prefixed with /analysts
     */
    if (product === 'analysts' && defaultDashboard && defaultDashboard.indexOf('page/') === -1) {
      defaultDashboard = `analysts/${defaultDashboard}`;
    }

    if (defaultDashboard && defaultDashboard.startsWith('dpr-')) {
      defaultDashboard = defaultDashboard.replace('dpr-', 'dpr/');
    }

    return defaultDashboard;
  }

  public async load(): Promise<void> {
    if (!this.loadingConfigPromise) this.loadingConfigPromise = this._load();
    await this.loadingConfigPromise;
  }

  /**
   * Load main + mode-dependant configs
   */
  private async _load(): Promise<void> {
    // try to find a user to be impersonated
    const rePersonate = /__spinpersonate\=(.*)/;
    const fullUrl = window.location.href;
    const impersonateMatch = rePersonate.exec(fullUrl);

    // if have found a user to be impersonated store it on config and in session storage
    if (impersonateMatch && impersonateMatch.length > 1) {
      this.impersonate = decodeURIComponent(impersonateMatch[1]);
      window.sessionStorage[Config.SPINPERSONATE] = this.impersonate;
    } // if there is no impersonation user in the url, look in session storage
    else if (window.sessionStorage.getItem(Config.SPINPERSONATE)) {
      this.impersonate = window.sessionStorage.getItem(Config.SPINPERSONATE);
    }

    const configUrl = this.completeUrl('/base/config/app-context');
    this.__loadingPromise = this.http.get<AppContextResponse>(configUrl).toPromise();

    const { appConfig, userRights, appParams, userInfo } = await this
      .loadingPromise;
    this.appConfig = appConfig;
    this.appParams = appParams;
    this.userRights = userRights;
    this.userInfo = userInfo;
    RefDataProvider.config = this;
    // Load all the vessel fleet available for the user
    await this.loadAvailableVesselFleets();
    // Select the activated vessel fleets (based on userPreferences && sessionStorage )
    this.loadGlobalFilters();

    const devSettings = window.sessionStorage.getItem(Config.SPINDEVSETTINGS);
    /** Searching for Dev Settings Config */
    if (devSettings) {
      /** Dev Settings already existing */
      this.devSettings = JSON.parse(devSettings);
    } else {
      /** Dev Settings to be created */
      this.initDevSettings();
    }

    for (const dashboard of this.appConfig.enabledDashboards) {
      this.dashboards[dashboard] = true;
      this.urls['/dashboard/' + dashboard] = true;
      if (dashboard.startsWith('dpr-')) {
        this.urls['/dashboard/' + dashboard.replace('dpr-', 'dpr/')] = true;
      }
    }

    this.product = this.appParams.product;
    this.rightMenuDashboards = this.appConfig.rightMenuDashboards;
    this.isFullAdmin = this.userInfo.isAdmin;
    this.isAppOwner = this.userInfo.isAppOwner;
    this.canImpersonate = this.userInfo.canImpersonate;
    /** In market-intel apps, XLSX downloads are disabled for user with client status 2 (trial mode). */
    this.trialModeDownloadsDisabled = this.isMarketIntel && this.userInfo.clientStatus === 2;
    this.spinergieUser = this.userInfo.spinergieUser;
    if (this.appConfig.fleetBuilder) {
      this.vesselFieldsets = FilterHelper.initializeFieldsetTypeAndProp(this.appConfig.fleetBuilder.fieldsets);
    }
    this.vesselFleetVisible = this.appConfig.fleetBuilder ? true : false;

    /** Store datasets to load for each dashboard page */
    Object.entries(this.appConfig.settings).forEach(([dashboardName, dashboardConfig]: [string, object]) => {
      (this.appConfig.settings[dashboardName] as DashboardSettings).usedRefDatasets = Config.getLazyDatasetsToLoad(
        dashboardConfig,
      );
    });
    this.loaded = true;
  }

  /**
   * DevMode Tool Kit configuration
   */
  public saveDevSettingsToSessionStorage() {
    window.sessionStorage.setItem(Config.SPINDEVSETTINGS, JSON.stringify(this.devSettings));
  }

  /**
   * Complete Initialisation of the Dev Settings
   */
  public initDevSettings() {
    this.devSettings = {
      showToolbox: false,
      vesselAutoreload: true,
      internetAutoreload: true,
      mocksActive: false,
    };
    this.saveDevSettingsToSessionStorage();
  }

  public getDevSetting(setting: string) {
    if (!this.userInfo.isDevMode || !this.devSettings) {
      return false;
    }
    return this.devSettings[setting];
  }

  public toggleDevSetting(setting: string) {
    this.devSettings[setting] = !this.devSettings[setting];
    this.saveDevSettingsToSessionStorage();
  }

  public get autoReloadEnabled(): boolean {
    return this.devSettings?.vesselAutoreload || !this.userInfo.isDevMode;
  }

  public getInternetAutoReloadState(): boolean {
    return this.devSettings?.internetAutoreload || !this.userInfo.isDevMode;
  }

  /** End Of DevMod ToolKit Configuration*/

  public loadGlobalFilters(): void {
    this.loadSelectedFleets();
    this.loadSelectedOsvProjects();
  }

  // Will perform an intersection between accessible projects and provided project ids
  public getAccessibleOsvProjects(osvProjectIds: number[]): OsvProject[] {
    if (!this.userInfo.accessibleOsvProjects) return [];
    return this.userInfo.accessibleOsvProjects.filter(project => osvProjectIds.includes(project.id));
  }

  // Will set selected projects according to user preferences, and available projects, and persist it in URL
  public loadSelectedOsvProjects(): void {
    if (!this.userInfo.accessibleOsvProjects) return;
    const accessibleProjectIds = this.userInfo.accessibleOsvProjects.map(p => p.id);
    // priority : URL, then preferences
    const selectedProjectsMatch = Config.SELECTED_PROJECTS_RE.exec(window.location.href);
    let projectsPreference;
    let projectsFromUrl = false;
    const selectedFromNavigation = this.router.getCurrentNavigation()?.extras?.queryParams?.[Config.SELECTED_PROJECTS];
    // used when navigating using router, i.e bookmark
    if (selectedFromNavigation) {
      projectsPreference = getNumberListFromString(selectedFromNavigation);
      projectsFromUrl = true;
    } else if (selectedProjectsMatch?.length > 1) {
      projectsPreference = NavigationHelper.getNumberListFromUri(selectedProjectsMatch[1]);
      projectsFromUrl = true;
    } else projectsPreference = this.userInfo.preferences?.selectedOsvProjectIds;
    // If all projects in preferences are available, set it as selected
    if (projectsPreference?.every(pid => accessibleProjectIds.includes(pid))) {
      this.selectedOsvProjectIds = projectsPreference;
    } else {
      if (projectsFromUrl) {
        this.dialogManager.showMessage(
          "You don't have access to some projects specified in the URL.\
Only data available for your projects will be visible on the application.",
          'warn',
        );
      }
      // else, default to all projects accessible
      this.selectedOsvProjectIds = accessibleProjectIds;
    }
    const projectsStr = this.selectedOsvProjectIds.toString();
    window.sessionStorage[Config.SELECTED_PROJECTS] = projectsStr;
    NavigationHelper.serializeToCurrentUrl({ [Config.SELECTED_PROJECTS]: projectsStr });
  }

  /** Either load selected fleets from url, session storage or user preferences. */
  public loadSelectedFleets(): void {
    const requestedFleetIds: number[] = [];

    if (this.getFleetIdsFromUrl().length) {
      requestedFleetIds.push(...this.getFleetIdsFromUrl());
    } else if (this.getFleetIdsFromSessionStorage().length) {
      requestedFleetIds.push(...this.getFleetIdsFromSessionStorage());
    } else {
      requestedFleetIds.push(...this.getFleetIdsFromUserPreferences());
    }

    // We filter the selectedFleets to only applied fleets to which the user is entitled
    const filteredFleetIds = requestedFleetIds.filter(fleetId =>
      this.availableVesselFleets.findIndex(fleet => fleet.id === fleetId) > -1
    );

    this.storeCurrentFleets(filteredFleetIds);
  }

  private getFleetIdsFromUrl(): number[] {
    const selectedFleetsMatch = Config.SELECTED_FLEETS_RE.exec(window.location.href);
    if (selectedFleetsMatch?.length > 1) {
      return NavigationHelper.getNumberListFromUri(selectedFleetsMatch[1]);
    }
    return [];
  }

  private getFleetIdsFromSessionStorage(): number[] {
    const fleetIds = window.sessionStorage.getItem(Config.SELECTEDFLEETS);
    if (!fleetIds) {
      return [];
    }
    return getNumberListFromString(window.sessionStorage.getItem(Config.SELECTEDFLEETS));
  }

  private getFleetIdsFromUserPreferences(): number[] {
    return this.userInfo.preferences?.selectedFleets ?? [];
  }

  // Store current applied fleets in selectedFleets attribute and in session storage
  public storeCurrentFleets(fleetIds: number[]): void {
    this.selectedFleets = fleetIds;
    window.sessionStorage[Config.SELECTEDFLEETS] = fleetIds.toString();
  }

  public getSearchbarItems(dataLoader: DataLoader): void {
    if (!this.appConfig.searchEndpoint) return;
    dataLoader.get(this.appConfig.searchEndpoint).then((result: IdentityItem[]) => {
      // get all searchable items (vessels, managers etc..)
      this.searchItemsAvailable = result
        .map(item => {
          const page = this.appConfig.pages.find(page => page.id === item.type);
          if (page) {
            item.typeTitle = NavigationHelper.getPageTypeTitle(item, page);
            item.subtitle = NavigationHelper.getPageSubtitle(item, page);

            const keysNotToCopy = new Set<string>(['title', 'subtitle', 'id', 'typeTitle']);
            /*
             * We copy all the general information of the type into the item
             * to be able to access those information from the item
             */
            for (const key in page) {
              if (keysNotToCopy.has(key)) {
                continue;
              }

              item[key] = page[key];
            }
          }
          return item;
        });

      /**
       * Build page title for every searchable items (items returned by the search endpoint).
       * We keep the first occurence per item type/ID, as in the backend, we need to order vessel features by
       * `latest DESC` to ensure unicity is done properly. Seeing as the current vessel title is defined by the latest
       * vessel feature, the title needs to be sourced in the first occurence.
       */
      this.searchItemsAvailable.forEach(item => {
        this.itemPageTitle[item.type] ??= {};
        if (this.itemPageTitle[item.type][item.id] === undefined) {
          this.itemPageTitle[item.type][item.id] = {
            title: item.title,
            fullTitle: `${item.title} (${item.typeTitle})`,
          };
        }
      });

      /*
       * determine which searchable items the user has right to see in the app
       * admin can see all items, if not admin, we will filter using the page rights
       */
      if (!this.isFullAdmin) {
        this.searchItemsAvailable = this.searchItemsAvailable
          .filter(item => 'page/' + item.type in this.dashboards);
      }
      this.generateSearchLabelAndLink();
      this.searchItemsReceived.next(true);
    });
  }

  /**
   * Mini language for variables integrated config.
   * If any parameter casts as false, its whole paramGroup will be
   * reduced to empty string.
   *
   * @param configText String similar to '<{datumBoolean} some nested {datumAttribute}>'
   * @param datum
   *  object to apply to configText
   */
  public static parse(configText: string, datum: any) {
    // if configText or datum is missing, return configText as it is
    if (!datum || !configText) {
      return configText;
    }

    const paramGroups = configText.match(/<[^<>]*>/g) || [];
    return paramGroups
      .map(paramGroup => paramGroup.replace(/<|>/g, ''))
      .map(paramGroup => {
        let values = paramGroup.match(/{(\w+)}/g)
            .map(param => param.replace(/{|}/g, ''))
            // negation
            .map(param => (param[0] === '!') ? !datum[param.slice(1)] : datum[param])
            // do not serialize booleans
            .map(value => value === true ? ' ' : value),
          paramRes = '';

        // any false value => empty string
        if (values.every(value => value)) {
          // replace parameter with value
          paramRes = values
            .reduce((acc, value) => acc.replace(/{(\w*)}/, value), paramGroup);
        }

        return paramRes;
      })
      // replace paramGroup with paramRes
      .reduce((acc, paramRes) => acc.replace(/<[^<>]*>/, paramRes), configText)
      // multi spaces
      .replace(/  +/g, ' ')
      .replace(/ +\./g, '.')
      // trailing spaces & commmas
      .replace(/^( |,)*/, '')
      .replace(/( |,)*$/, '');
  }

  public getTitlePage(id: string, type: string) {
    // if the search items have not yet been received we don't return anaything
    if (!this.itemPageTitle || !this.itemPageTitle[type] || !this.itemPageTitle[type][id]) {
      return null;
    }
    return this.itemPageTitle[type][id].title;
  }

  public checkRightsFromUrl(url: string): boolean {
    /*
     * match any dashboard, will work for:
     * - dashboard/vessel-comparator#dsv
     * - dashboard/vessel-comparator?something
     * - dashboard/vessel-comparator
     */
    const getTitleRegex = /dashboard\/([^#\/;?]*)(\/[^#\/;?]*)?((#.*)|(\?.*)|$)/i;
    const regexResult = getTitleRegex.exec(url);

    if (url.includes('dashboard') && !regexResult) {
      return false;
    }

    // if the match was not successful, the user is hitting URL that we can't check rights for
    if (!regexResult) {
      return true;
    }

    let result = regexResult[1];

    /*
     * special dashboards that are always available
     * database access is handled on the entity rights level
     * subscriptions are always available for the user
     */
    if (result === 'database-overview' || result === 'table' || this.appConfig.enabledDashboards.includes(result)) {
      return true;
    }

    /*
     * special cases for page, analysts and dpr dashboards
     * for pages the id of the dashboard is page/{dashboard}
     */
    if (UrlSubdirectory[result] === UrlSubdirectory.page) {
      result += regexResult[2];
    }

    /*
     * TODO: Needs standardization
     * Due to JS code splitting some dashboards are prefixed with 'analysts' / 'dpr'
     * In such case the id of the dashboard is only what is after the '/analysts' or 'dpr-{dashboardName}'
     */
    if (UrlSubdirectory[result] === UrlSubdirectory.analysts) {
      result = regexResult[2].replace('/', '');
    }

    if (UrlSubdirectory[result] === UrlSubdirectory.dpr) {
      result += regexResult[2].replace('/', '-');
    }

    let hasAccess = result in this.dashboards;

    // If doesn't has access on regular dashboard, check if has access on admin dashboards
    if (!hasAccess) {
      if (UrlSubdirectory[regexResult[1]] === UrlSubdirectory.page) {
        result = regexResult[2].replace('/', '');
      }
      hasAccess = this.rightMenuDashboards.find(item => item.value === result) !== undefined;
    }
    return hasAccess;
  }

  public hasAccessToVessel(vesselId?: number | string): boolean {
    if (this.product !== 'osv' || this.userInfo.accessibleOsvVesselIds == null || vesselId == null) return true;
    return this.userInfo.accessibleOsvVesselIds.includes(+vesselId);
  }

  public getFullPageTitle(id: string, type: string): string {
    // if the search items have not yet been received we don't return anything
    return this.itemPageTitle?.[type]?.[id]?.fullTitle ?? null;
  }

  public completeUrl(url: string): string {
    let fullUrl = url;
    if (this.impersonate) {
      fullUrl = NavigationHelper.addParameterToUrl(fullUrl, Config.SPINPERSONATE, encodeURIComponent(this.impersonate));
    }

    if (this.selectedFleets?.length && !this.skipUserFleet) {
      fullUrl = NavigationHelper.addParameterToUrl(
        fullUrl,
        Config.SELECTEDFLEETS,
        encodeURIComponent(this.selectedFleets.toString()),
      );
    }

    if (this.selectedOsvProjectIds?.length) {
      fullUrl = NavigationHelper.addParameterToUrl(
        fullUrl,
        Config.SELECTED_PROJECTS,
        encodeURIComponent(this.selectedOsvProjectIds.toString()),
      );
    }
    return fullUrl;
  }

  /**
   * Checks whether the current user has a feature or not. By default, admins have all features. If you want to change
   * this behavior, pass { bypassForAdmin: false } as a second parameter.
   */
  public hasFeature(
    feature: Feature,
    { bypassForAdmin: bypassForAdmin }: { bypassForAdmin: boolean } = { bypassForAdmin: true },
  ): boolean {
    if (!this.appConfig || !this.appConfig.features) {
      return false;
    }

    return (bypassForAdmin && this.isFullAdmin) || this.appConfig.features.indexOf(feature) > -1;
  }

  public canShareFleet(): boolean {
    return this.hasFeature('shareFleet');
  }

  /**
   * Check if a page or dashboard should skip some default dashboard behaviour:
   *  - skipUserFleet: User vessel fleet should not be applied to this page/dashboard
   *  - skipPersistentFilters: Persistent filters should not be applied to this page/dashboard
   *  - skipSaveCurrentAnalysis: Save current analysis should not be applied to this page/dashboard
   *
   * @param urlTarget The URL to check if it should skip default dashboard behaviour
   */
  public updateSkipKeys(urlTarget: string): void {
    const { panelId, isPage } = NavigationHelper.getPanelId(urlTarget, this.product);

    this.skipUserFleet = this.shouldSelectedPanelSkipKey(panelId, isPage, 'skipUserFleet');
    this.skipPersistentFilters = this.shouldSelectedPanelSkipKey(
      panelId,
      isPage,
      'skipPersistentFilters',
    );
    this.skipSaveAnalysis = this.shouldSelectedPanelSkipKey(
      panelId,
      isPage,
      'skipSaveCurrentAnalysis',
    );
  }

  /**
   * Check if a page or dashboard should skip user vessel fleet / persistent filters
   * If a panel should skip user fleet panel config should contain 'skipPersistentFilters': true or
   * 'skipPersistentFilters': true
   */
  protected shouldSelectedPanelSkipKey(
    selectedPanel: string,
    page: boolean,
    keyToCheck: 'skipPersistentFilters' | 'skipUserFleet' | 'skipSaveCurrentAnalysis',
  ) {
    if (!this.appConfig) {
      return false;
    }
    if (page) {
      for (const page of this.appConfig.pages) {
        if (page.id == selectedPanel) {
          if (page[keyToCheck]) {
            return true;
          }
        }
      }
    } else {
      for (const dashboard of this.appConfig.dashboards) {
        if (dashboard.id == selectedPanel) {
          if (dashboard[keyToCheck]) {
            return true;
          }
        }
      }
    }
    return false;
  }

  /**
   * Global timezone for the application. Fallbacks to UTC (used by construction/spinrig apps)
   *
   * @return {DateTimezone}  Default timezone (user defined)
   */
  public getDateTimezone(): DateTimezone {
    return this.appConfig.timezone ?? 'local';
  }

  /**
   * Load all fleets created or shared with user
   * @returns VesselFleet[] Fleets created or shared with user
   */
  public async loadAvailableVesselFleets(): Promise<VesselFleet[]> {
    const url = this.completeUrl('/base/saving/vessel-fleets');
    this.availableVesselFleets = await this.http.get(url).toPromise() as VesselFleet[];
    return this.availableVesselFleets;
  }

  /**
   * @returns  availableVesselFleets: Fleets created or shared with user
   */
  public getAvailableVesselFleets(): VesselFleet[] {
    return this.availableVesselFleets;
  }

  public get defaultRefDatasetName(): SpecName {
    return Config.defaultSpecByProduct(this.product);
  }

  public static defaultSpecByProduct(product: SpinProduct): SpecName {
    return Config.specByProduct[product];
  }

  private static readonly specByProduct: { [product: string]: SpecName } = {
    construction: 'vessel',
    spinrig: 'rig',
    analysts: 'vessel',
  };

  /**
   * Searches for a key in a given config / part of config and returns an array
   * of objects containing the path and value of the key.
   *
   * @param config The configuration object to search in.
   * @param searchedKey The key to search for.
   * @param currentPath An optional parameter to keep track of the current path in the object.
   * @param results An optional parameter to store the results.
   * @returns An array of objects containing the path and value of the key.
   */
  public static searchConfigForKey(
    config: unknown,
    searchedKey: string,
    currentPath = [],
    results: SearchedConfigResult[] = [],
  ): SearchedConfigResult[] {
    if (config === null || config === undefined) {
      return results;
    }

    if (Array.isArray(config)) {
      config.forEach((x, i) => Config.searchConfigForKey(x, searchedKey, currentPath.concat(i), results));
    } else if (typeof config === 'object') {
      Object.entries(config).forEach(([key, value]) => {
        if (key === searchedKey) {
          results.push({ path: currentPath.join('.'), value });
        } else {
          Config.searchConfigForKey(value, searchedKey, currentPath.concat(key), results);
        }
      });
    }

    return results;
  }

  public static _getLazyDatasetsToLoad(config: unknown, datasetNames: Set<string>): void {
    if (typeof config === 'string') {
      const localRequestInfos = getLocalRequestInfo(config, true);
      if (localRequestInfos !== null) {
        datasetNames.add(localRequestInfos.datasetName);
      } else {
        RefDataProvider.getChainedDatasets(config).forEach(name => datasetNames.add(name));
      }
    } else if (Array.isArray(config)) {
      config.forEach(item => Config._getLazyDatasetsToLoad(item, datasetNames));
    } else if (typeof config === 'object' && config !== null) {
      Object.entries(config).forEach(([key, value]) => {
        /** For distanceToEntity filter. If there is a key 'refDataset', the designated dataset is loaded. */
        if (key === 'refDataset' && typeof value === 'string') {
          datasetNames.add(value);
        } else {
          Config._getLazyDatasetsToLoad(value, datasetNames);
        }
      });
    }
  }

  /**
   * Walk through provided config, finding strings that are in the format of chained dataset access, if lazy-loadable
   * i.e 'windfarm.isTerminated'. Will return a list of dataset names.
   */
  public static getLazyDatasetsToLoad(config: unknown): string[] {
    const datasetNames = new Set<string>();
    Config._getLazyDatasetsToLoad(config, datasetNames);
    return [...datasetNames].filter(datasetName =>
      RefDataProvider.LAZY_DATASET_CONFIG.find(configDef => configDef.id === datasetName)
    );
  }

  public get isProduction(): boolean {
    return environment.production;
  }

  /**
   * Get entities that are available for database overview dashboard.
   * These are entities defined in config.database.entities that are available in config.availableEntities, ordered by
   * config.database.entities.
   */
  public get databaseAvailableEntities(): DatabaseEntity[] {
    const dbAvailableEntities: DatabaseEntity[] = [];
    this.appConfig.database.entities.forEach((entityClass: string): void => {
      const availableEntity: DatabaseEntity = this.userRights.availableEntities.find(
        (item: DatabaseEntity): boolean => item.class === entityClass,
      );

      if (availableEntity) {
        dbAvailableEntities.push(availableEntity);
      }
    });

    return dbAvailableEntities;
  }

  public findAvailableEntity(entityName: string): DatabaseEntity | undefined {
    return this.userRights.availableEntities.find(availableEntity => availableEntity.class === entityName);
  }

  /**
   * Test whether settings inherits from DashboardLayerSettings
   */
  public static isLayerSettings(
    settings: DashboardCommonSettings,
  ): settings is DashboardLayerSettings {
    return settings && (settings as DashboardLayerSettings).layers !== undefined;
  }

  /**
   * Test whether settings inherits from MapDashboardSettings
   */
  public static isMapSettings(
    settings: DashboardCommonSettings,
  ): settings is MapDashboardSettings {
    return settings && settings.type === 'map';
  }
}
