<fieldset class="spin-filters-fieldset selector-fieldset">
  <div (click)="legendOnclick()" [matTooltip]="fieldset.description" matTooltipPosition="after" class="fieldset-legend">
    <pearl-icon [icon]="this.fieldset.expanded ? 'chevron_down' : 'chevron_right'" [size]="24" />

    <span [ngClass]="getLegendClass()">{{ fieldset.title }} </span>
    <div *ngIf="count > 0 && showCount" class="spin-rounded-div spin-bg-primary spin-padded">
      {{ count }}
    </div>
  </div>
  <div class="fieldset-fields" [ngStyle]="getFieldsetFieldsStyle()">
    <div *ngFor="let field of fieldset.fields">
      <div
        class="field-box"
        *ngIf="field.filteringEnabled !== false && field.filterType"
        [ngSwitch]="field.filterType"
        [hidden]="isFieldHidden(field)">
        <spin-filters-interval *ngSwitchCase="'interval'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

        <spin-filters-interval *ngSwitchCase="'datetime'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

        <spin-filters-interval *ngSwitchCase="'date'" [field]="$any(field)" (onchange)="onbubblingchange($event)" />

        <spin-filters-doubledate
          *ngSwitchCase="'doubledate'"
          [field]="$any(field)"
          (onchange)="onbubblingchange($event)" />

        <spin-filters-doubledate
          *ngSwitchCase="'intersection'"
          [field]="$any(field)"
          (onchange)="onbubblingchange($event)" />

        <spin-filters-checkbox
          *ngSwitchCase="'checkbox-exclude'"
          [field]="field"
          (checkboxFilterChange)="onbubblingchange($event)" />

        <spin-filters-checkbox
          *ngSwitchCase="'checkbox'"
          [field]="field"
          (checkboxFilterChange)="onbubblingchange($event)" />

        <spin-filters-field *ngSwitchCase="'multi'" [field]="field" (onchange)="onbubblingchange($event)" />

        <spin-filters-field *ngSwitchCase="'excludemulti'" [field]="field" (onchange)="onbubblingchange($event)" />

        <spin-filters-distance-to-entity
          *ngSwitchCase="'distanceToEntity'"
          [field]="field"
          (filterChange)="onbubblingchange($event)" />
      </div>
    </div>
  </div>
</fieldset>
