<div [id]="componentSettings.id" class="spin-graph full-height plotly-boxplot">
  <div *ngIf="title && !opts.scheduleGraph" class="component-title">
    <h3>{{ title }}</h3>
    <description-button [description]="description" />
  </div>

  <spin-graph-options
    *ngIf="!opts.hideOptions"
    #chartOptions
    [graphId]="componentSettings.id"
    [opts]="opts"
    [noData]="noRawData"
    (showTable)="onTable()"
    (onchange)="onchange($event.chartParam, $event.value)"
    (xls)="onXls()"
    (onintervalchange)="onintervalchange($event)"
    (png)="onpng()"
    [availableSelects]="availableSelects"
    [selects]="selects"
    [display]="display"
    [intervalField]="intervalField"
    [showTableButton]="datatable"
    [minX]="minX"
    [maxX]="maxX"
    [dynamicGroupByState]="dynamicGroupByState" />

  <div class="loading-indicator" *ngIf="loading">
    <mat-spinner />
  </div>
  <div
    *ngIf="!noRawData"
    #chartArea
    id="{{ plotlyChartId }}"
    data-testid="spin-boxplot"
    class="chart-area"
    [ngClass]="{ 'chart-hidden': loading }"></div>

  <div *ngIf="noRawData" class="no-data-wrapper">
    <span>No data available</span>
  </div>
</div>

<chart-tooltip (navigate)="navigateToPage($event)" />
