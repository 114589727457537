import { DateHelper } from '../../helpers/date-helper';
import { FieldValidityResult } from '../../helpers/types';

export function validateDigitsLengthEntry(
  requiredLength: number,
  enteredNumber: number,
): undefined | { valid: boolean; msg: string } {
  if (`${enteredNumber}`.length !== requiredLength) {
    return {
      valid: false,
      msg: `must be ${requiredLength} digits long`,
    };
  }
}

export function validateUrlEntry(url: string): FieldValidityResult {
  if (!(/^(http:\/\/|https:\/\/)/.test(url))) {
    return {
      valid: false,
      msg: 'must start with http:// or https://',
    };
  }
  return { valid: true };
}

export function validateCamelCaseEntry(text: string): FieldValidityResult {
  /**
   * [a-z]+: String should start with one to unlimited lowercase chars
   * ([A-Z][a-z]*)*: String should end with zero to unlimited chars following the pattern
   */
  if (!(/^[a-z]+([A-Z][a-z]*)*$/).test(text)) {
    return {
      valid: false,
      msg: 'must be in camelcase',
    };
  }
  return { valid: true };
}

/**
 * This function check if a dedicated SSO email regex is valid
 * Perform simple check on the regex in order to raise simple syntax errors.
 * Doesn't check its efficiency.
 *
 * ONLY check regexes, DOES NOT check emails
 *
 * regex coherency based on:
 * - pair of [], {}, (), <>
 * - number of $
 * - no spaces, ',', ';', '&'
 * - presence of '.' and '@'
 *
 * Compile the regex to check if it's valid
 */
export function validateEmailDomainEntry(emailRegex: string): FieldValidityResult {
  try {
    const _ = new RegExp(emailRegex);
  } catch (error) {
    return {
      valid: false,
      msg: `Syntax error: ${error.message}`,
    };
  }
  const count = {};
  for (let i = 0; i < emailRegex.toLowerCase().length; i++) {
    const value = emailRegex[i];
    count[value] = (count[value] || 0) + 1;
  }

  if (
    !(count['$'] === 1 && count['@'] && count['.'] && !count[' '] && !count[','] && !count[';'] && !count['&']
      && (count['['] || 0) === (count[']'] || 0) && (count['('] || 0) === (count[')'] || 0)
      && (count['{'] || 0) === (count['}'] || 0) && (count['<'] || 0) === (count['>'] || 0))
  ) {
    return {
      valid: false,
      msg: 'Invalid regex: Regex are usually of this form "@(.*[.])?(domain1|domain2).com$"',
    };
  }
  return { valid: true };
}

export function validateEmailEntry(value: string): FieldValidityResult {
  // From https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  if (
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
      .test(value)
  ) {
    return { valid: true };
  }

  return {
    valid: false,
    msg: `${value} is not a valid email`,
  };
}

export function validateNumberFormatEntry(text: string): FieldValidityResult {
  /**
   * \d\.\d-\d: With a, b and c being single digit numbers, the string should have the format a.b-c.
   * Second condition is that b must be inferior or equal to c.
   */
  const regexNumberFormat = /^\d\.(\d)-(\d)$/;
  const match = text.match(regexNumberFormat);
  if (match === null || (parseInt(match[1]) > parseInt(match[2]))) {
    return {
      valid: false,
      msg: 'Format must be a.b-c with a, b and c being single digit numbers and b <= c.',
    };
  }
  return { valid: true };
}
