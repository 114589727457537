<ng-container class="spin-link">
  <ng-container *ngIf="_link">
    <ng-container *ngIf="isSimpleLink(); else linkList">
      <pearl-button-link size="small" *ngIf="tooltip && button; else linkOrButton" href="{{ _link }}" target="_blank">
        {{ linkTitle }}
      </pearl-button-link>
      <ng-template #linkOrButton>
        <pearl-button-link
          *ngIf="icon"
          [icon]="icon"
          type="icon"
          size="small"
          href="{{ _link }}"
          [matTooltip]="description"
          target="_blank" />
        <pearl-button-link
          *ngIf="!icon && button"
          type="sticky"
          size="small"
          href="{{ _link }}"
          [matTooltip]="description"
          target="_blank">
          {{ linkTitle }}
        </pearl-button-link>
        <a *ngIf="!icon && !button" href="{{ _link }}" [matTooltip]="description" target="_blank"> {{ linkTitle }} </a>
      </ng-template>
    </ng-container>
    <ng-template #linkList>
      <span class="link-list">
        <pearl-button
          *ngIf="tooltip && button; else classicButton"
          type="alternative"
          [matMenuTriggerFor]="colMenu"
          (click)="onClick($event)">
          {{ linkTitle }}
        </pearl-button>
        <ng-template #classicButton>
          <pearl-button
            *ngIf="icon"
            size="small"
            type="icon"
            [icon]="icon"
            [matTooltip]="description"
            [matMenuTriggerFor]="colMenu"
            (click)="onClick($event)" />
          <pearl-button
            *ngIf="!icon"
            size="small"
            type="alternative"
            [matTooltip]="description"
            [matMenuTriggerFor]="colMenu"
            (click)="onClick($event)">
            {{ linkTitle }}
          </pearl-button>
        </ng-template>
        <mat-menu #colMenu="matMenu" class="spin-link-vertical-menu" color="primary">
          <div *ngFor="let linkField of extractLinks()">
            <pearl-button-link
              type="alternative"
              size="small"
              [href]="linkField.link"
              target="_blank"
              (click)="onClick($event)">
              {{ linkField.title }}
            </pearl-button-link>
          </div>
        </mat-menu>
      </span>
    </ng-template>
  </ng-container>
</ng-container>
