<div *ngIf="parentField.formType == 'multi'; else collectionTable">
  <div class="collection-multi mat-form-field-flex">
    <pearl-button
      *ngIf="parentField.selector && editMode"
      type="icon"
      [matTooltip]="getOpenSelectorTooltip(parentField)"
      icon="sort"
      class="advanced-filters-button"
      (click)="advancedFiltersClick(parentField)" />
    <mat-spinner [diameter]="20" *ngIf="showLoading" />
    <multi-form-field
      #collectionMulti
      matInput
      [small]="small()"
      class="multi-form-field"
      [label]="parentField.title"
      [disabled]="!parentField.editable"
      [parentField]="parentField"
      [parentEntityDefinition]="parentEntityDefinition"
      [editMode]="editMode"
      (onchange)="setCollection($event)"
      [entity]="parentEntity" />
  </div>
</div>

<ng-template #collectionTable>
  <mat-spinner [diameter]="20" *ngIf="showLoading" class="definition-spinner"> </mat-spinner>

  <div class="collection-edit-header" *ngIf="isEditableManyToMany">
    <div *ngIf="addExistingEnabled" class="add-existing-menu">
      <search-bar
        #search_bar
        [small]="small()"
        [searchLabel]="'Find items to add'"
        [searchItemsAvailable]="$any(linkableValuesNotLinked)"
        [resultAlreadySorted]="true"
        (onoptionselected)="optionSelected($event)"
        class="add-existing-search-bar">
      </search-bar>

      <pearl-button type="alternative" icon="add" (click)="addSelected()" class="add-button">
        Add existing
      </pearl-button>
    </div>

    <pearl-button
      *ngIf="addExistingEnabled && addNewEnabled"
      type="alternative"
      icon="add"
      (click)="createNewForRelation('ManyToMany')"
      class="add-button">
      Create new
    </pearl-button>
  </div>

  <div>{{ message }}</div>

  <div *ngIf="isEditableOneToMany" class="collection-edit-header">
    <ng-container *ngIf="!needAddConfirm; else ConfirmAdd">
      <div *ngIf="addExistingEnabled" class="add-existing-menu">
        <search-bar
          [small]="small()"
          #search_bar
          [searchLabel]="'Find items to add'"
          [searchItemsAvailable]="$any(linkableValuesNotLinked)"
          (onoptionselected)="optionSelected($event)"
          class="add-existing-search-bar">
        </search-bar>

        <pearl-button type="alternative" icon="add" (click)="prepareAddExistingOneToMany()" class="add-button">
          Add existing
        </pearl-button>
      </div>

      <pearl-button
        *ngIf="addExistingEnabled && addNewEnabled"
        type="alternative"
        icon="add"
        (click)="addNewOneToMany()"
        class="add-button">
        Create new
      </pearl-button>
    </ng-container>
    <ng-template #ConfirmAdd>
      <div class="warning">{{ confirmMessage }}</div>
      <div>
        <pearl-button type="alternative" icon="saved" (click)="addExistingOneToMany()" class="add-button">
          Yes
        </pearl-button>
        <pearl-button type="alternative" icon="cancel" (click)="cancelAddExistingOneToMany()" class="add-button">
          No
        </pearl-button>
      </div>
    </ng-template>
  </div>

  <div>{{ message }}</div>

  <!-- we tell the nested table to reload the data from the parent after successfull save -->
  <entity-table
    [ngClass]="{ hidden: !definitionLoaded }"
    [editMode]="true"
    [readOnly]="!isTableEditable"
    [parentEntity]="parentEntity"
    [parentField]="parentField"
    [parentTitle]="parentTitle"
    [parentEntityDefinition]="definition"
    [afterSaveAction]="afterEntitySave"
    (exportFunction)="onExport($event)"
    (onaction)="onaction($event)"
    [buttonList]="editMode ? buttonsUnlink : []"
    [addNewEnable]="addNewEnabled"
    [linkBy]="linkBy"
    [hideHeader]="parentField.hideHeader"
    [formTable]="parentField.formTable"
    [unopenable]="parentField.unopenable"
    (openEntityRequest)="bubbleOpenEntityRequest($event)" />
</ng-template>
