import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy,
  OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, inject } from '@angular/core';
import { Router } from '@angular/router';

import { isArray, max, round, sumBy } from 'lodash-es';
import dayjs from 'dayjs';
import { Layout, PlotData, PlotDatum, PlotMouseEvent } from 'plotly.js';
import { format } from 'd3-format';
import { select } from 'd3-selection';
import { ErrorWithFingerprint } from 'src/helpers/sentry.helper';
import { is, pathOr } from 'ramda';

import { Config } from '../config/config';
import { BrowserHelper } from '../helpers/browser-helper';
import { DateHelper } from '../helpers/date-helper';
import { SelectComponent } from '../filters/select';
import { ChartingHelpers } from './charting-helpers';
import { AxisConfig, AxisFormatting, BaseSeries, ChartCalcConfig, ChartComponentSettings, ChartEventHandler,
  ChartExportData, ChartIntervalChange, ChartOptions, ChartSelect, ChartSelectKey, ChartSelects, ChartSeries,
  ChartSeriesOptions, ChartSettings, ChartStraightLine, CustomTooltipData, DataSeries, PlotHTMLElement, RawDataPoint,
  RealPlotDatum, SelectableGroupBy, SelectableMetric, SelectableSplitBy, SelectableValue, SeriesHeader, TimeLevelEnum,
  TimeLevelHalfDuration, YAxisKey, YAxisLayout, YAxisMap, isSimpleAggregation, xAxisType } from './chart-types';
import { GraphOptionsComponent } from './graph-options';
import { TimeCreator } from '../helpers/time-creator';
import { VerticalBreakLine, xAxisParameters } from '../schedule/schedule-types';
import { ScheduleDrawingHelper } from '../schedule/schedule-drawing-helper';
import { ChartTimeManager } from './chart-time-manager';
import { COMPLETE_ERA, ChartDisplayMode, ChartSelectOptions, ChartType, Color, ColumnOption, ComponentOptionsTab,
  ComponentStateOptions, Coords, DateTimezone, DynamicGroupByState, ExportData, Interval, IntervalField, IntervalOrNull,
  PngExport, ResolvedInterval, SeriesSplit, SpinTimeUnit, StringDuration, TooltipSeries,
  TooltipSeriesBasicInfo } from '../helpers/types';
import { ChartTooltipComponent } from '../shared/chart-tooltip';
import { shortenWithPoints } from '../helpers/d3-helpers';
import { DatabaseHelper } from '../database/database-helper';
import { SplitByColorService } from '../helpers/split-by-color.service';
import { TimezoneService } from '../helpers/timezone.service';
import { DataHelpers } from '../helpers/data-helpers';
import { PageLinkHelper } from '../helpers/page-link-helper';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { TrackedActionType } from '../shared/product-analytics/product-analytics.types';
import { NavigationHelper } from '../helpers/navigation-helper';

// Each y-axis occupies 60px
export const Y_AXIS_WIDTH = 60;

type SelectedTraceDict = {
  [selections: string]: {
    [seriesId: string]: boolean;
  };
};
type GraphOptionsProductTracking = {
  [graphOptions: string]: TrackedActionType;
};

@Directive()
export abstract class NvGraph implements AfterViewInit, OnDestroy, OnChanges, OnInit {
  @ViewChildren(SelectComponent)
  private $selects: QueryList<SelectComponent>;
  @ViewChild('chartOptions')
  public $chartOptions: GraphOptionsComponent;
  @ViewChild(ChartTooltipComponent)
  public $chartTooltip: ChartTooltipComponent;

  @Input()
  public componentSettings: ChartComponentSettings;
  @Input()
  loading: boolean;
  @Input()
  public noRawData: boolean;
  @Input()
  public datatable: boolean;
  @Input()
  public isScheduleChart: boolean = false;
  @Output()
  public onselect = new EventEmitter<ComponentStateOptions>();
  @Output()
  public onchartintervalchange = new EventEmitter<ChartIntervalChange>();
  @Output()
  public showTable = new EventEmitter<void>();
  @Output()
  public navigate = new EventEmitter<any>();

  private plotlyLoader: Promise<any> | any;
  private plotlyLib: any;
  private formattedTitle: string; // used by get title()
  private formattedSubtitle: string; // used by get subtitle()
  private xAxisTickvals: number[]; // used to force xAxis tick values for schedule chart
  private _graphTabs: ComponentOptionsTab[]; // private to force using corresponding setter

  public data: ChartSeries[] = [];
  public noRawDataForMetric: boolean;
  public svg: d3.Selection<any>;
  public buffer: ChartExportData;

  public display: ChartSelectOptions;
  public browser: BrowserHelper = inject(BrowserHelper);

  public chartType: ChartType;
  public zone: NgZone = inject(NgZone);
  public cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  public config: Config = inject(Config);
  public minX: number;
  public maxX: number;
  public minY: number;
  public maxY: number;
  public maxYAxis: 'y1' | 'y2' = 'y1';

  public selectedMetrics: SelectableMetric[];
  public selectedGroup: SelectableGroupBy;
  public selectedSplit: SelectableSplitBy | undefined;
  public selectedSize: SelectableValue | undefined;
  public dynamicGroupByState?: DynamicGroupByState;
  public selectedTab: string;
  public straightLines: ChartStraightLine[] = [];
  public availableSelects: ChartSelectKey[] = [];
  public showBarModeControls: boolean = false;
  public barMode: string = 'stack';
  // Allow to detect if the barMode has been changed by the user
  public barModeChanged: boolean = false;
  protected traces: PlotData[];
  protected layout: Partial<Layout>;
  public _interval: Interval;
  /** Case of graph without interval field, but with a master filter applied to the whole page. */
  public masterPeriod: IntervalOrNull;
  protected settings: ChartSettings;
  protected container: d3.Selection<any>;
  protected gd: { calcdata: any[] };
  protected splitIds: { [split: string | number]: string | number };
  protected isFullScreen: boolean;

  /**
   * TimeCreator keeps 2 datezones component (config) and data
   * For charts the data timezone is local because it has been localy-enforced
   */
  public timeCreator: TimeCreator;
  protected timezoneService: TimezoneService = inject(TimezoneService);

  // Calculated data returned by plotly after chart is drawn
  public calculatedData: any[];

  // for plotly charts - once we know that we have plotted, we can optimize lot of calls
  public plotted: boolean = false;

  static readonly noneSplitSuffix = '__none__';

  public splitByColorService: SplitByColorService = inject(SplitByColorService);

  private verticalBreaklines: VerticalBreakLine[];
  public breaklineUnit: SpinTimeUnit;
  public renderingLoading: boolean;
  public showLoading = () => this.loading || this.renderingLoading;
  public handlers: ChartEventHandler[] = [];
  public opts: ChartOptions = {};
  /*
   * this object hold as key the base name (its key in the object is `title`) of a series and as values the name
   * of all its split so that when we have a split we can get the name of its series
   */
  public namesBySeries: {
    [seriesName: string]: string[];
  } = {};
  public xAxisTicks = {};

  protected productAnalyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);
  private router: Router = inject(Router);

  private graphOptionsProductTracking: GraphOptionsProductTracking = {
    groupby: 'categoryGroupedBy',
    splitby: 'categoryColoredBy',
  };

  /**
   * @constructor
   *
   * @param {ElementRef} elementRef  Element reference
   * @param {ChartType}  chartType   Chart type
   */
  constructor(public elementRef: ElementRef, chartType: ChartType) {
    this.chartType = chartType;
    this.display = {};
    this.plotlyLoader = import('../libs/plotly-spinergie.min.js').then(plotly => this.plotlyLib = plotly.default);
  }

  /**
   * On init
   *
   * @return {void}
   */
  ngOnInit(): void {
    this.settings = this.componentSettings.chart;
    this.container = select(this.elementRef.nativeElement);
    this.svg = this.container.select('div.svg').append('svg').attr('id', this.svgId);
    this.initSelectValues();

    this.timeCreator = new TimeCreator(this.timezoneService, 'local');
  }

  ngAfterViewInit(): void {
    this.opts = this.configOpts ?? {};
    if (this.settings.opts?.dynamicGroupBy) {
      this.dynamicGroupByState = {
        groupByManuallySelected: false,
        groupByMinSelectable: 0,
        serverSide: this.settings.series?.some(series => ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType)),
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('settings' in changes) {
      this.opts = { ...this.opts, ...changes.settings.currentValue?.opts } ?? {};
    }

    if ('configOpts' in changes) {
      this.opts = this.configOpts ?? {};
    }
  }

  ngOnDestroy() {
    // Remove event listeners
    this.handlers
      .forEach(({ handler, target, event }) =>
        target
          .removeEventListener(event, handler)
      );
  }

  /* * Getters/setters * */

  public get description(): string {
    return this.componentSettings?.description;
  }

  public get title(): string { // lazy loading
    return this.formattedTitle ??= DataHelpers.needsParameterInjection(this.componentSettings?.title)
      ? DataHelpers.injectParameters(this.componentSettings.title, this.settings.valueBag)
      : this.componentSettings?.title;
  }

  public get subtitle(): string { // lazy loading
    return this.formattedSubtitle ??= DataHelpers.needsParameterInjection(this.componentSettings?.subtitle)
      ? DataHelpers.injectParameters(this.componentSettings.subtitle, this.settings.valueBag)
      : this.componentSettings?.subtitle;
  }

  public get configOpts(): ChartOptions {
    return this.settings?.opts;
  }

  public get selects(): ChartSelects {
    return this.settings?.selects;
  }

  public get intervalField(): IntervalField {
    return this.settings?.intervalField;
  }

  public get plotlyChartId(): string {
    return `div-${this.componentSettings.id}`;
  }

  protected get splitNullTitle(): string {
    return this.selectedSplit?.nullTitle || ChartingHelpers.nullGroupTitle;
  }

  public get interval(): [number, number] {
    // To know why we enforce utc here, see the comment of DateHelper.enforceLocalTz
    const timezone = this.timezoneService.timezone;
    if (this._interval && timezone !== 'local') {
      return this._interval.map(ts => DateHelper.enforceLocalTz(ts, timezone)) as Interval;
    }
    return this._interval;
  }

  private get isTimeGroupingChart(): boolean {
    return ChartingHelpers.isTimeGrouping(this.selectedGroup);
  }

  protected get plotElement(): PlotHTMLElement | null {
    return document.getElementById(this.plotlyChartId) as PlotHTMLElement;
  }

  /* * Public * */
  // Bunch of methods necessary for remembering selected traces when changing time period
  /*
   * this dictionnary has for first key a hash that represents the user selections
   * you can check currentSelectionKey to see how it is generated
   * the second key of the dictionnary is a series id and its value is a boolean
   * which tells whether the series is visible or not
   * so we have a dictionnary telling for each tuple of user selection and each serie
   * if the series is visible or not
   */
  public selectedTracesBySelection: SelectedTraceDict = {};

  get currentSelectionKey(): string {
    return `m${this.selectedMetrics?.[0]?.title}g${this.selectedGroup?.title}s${this.selectedSplit?.title}`;
  }

  get isPolarPlot(): boolean {
    return this.chartType === 'polar' || this.chartType === 'barpolar';
  }

  get graphTabs(): ComponentOptionsTab[] {
    return this._graphTabs;
  }

  // Set GraphTabs and chartOptions Tabs
  set graphTabs(graphTabs: ComponentOptionsTab[]) {
    this._graphTabs = graphTabs;
    // graph-option can be optional, as ngIf can remove it from the DOM
    if (this.$chartOptions?.tabs) {
      this.$chartOptions.tabs = graphTabs;
    }
  }

  setTraceVisibility(id: string, visibility: boolean) {
    this.selectedTracesBySelection[this.currentSelectionKey] ??= {};
    this.selectedTracesBySelection[this.currentSelectionKey][id] = visibility;
  }

  setSeriesVisibility(series: ChartSeries, split: string | null, visibility: boolean): void {
    const id = this.getTraceId(series, split);

    if (pathOr(null, [this.currentSelectionKey, id], this.selectedTracesBySelection) === null) {
      this.setTraceVisibility(id, visibility);
    }
  }

  initSelectedTraces(allSeries: ChartSeries[]): void {
    /*
     * selectedTraces holds the trace selection on every of the user's selection
     * so when changing the metric, the group by or the split by, selectedTraces
     * changes and this function ensures it stays up to date by setting every
     * series in it
     */
    for (const series of allSeries) {
      const splits = Object.keys(series.header);

      if (splits.length === 0) {
        this.setSeriesVisibility(series, null, !series.disabled);
      }

      for (const split of splits) {
        this.setSeriesVisibility(series, split, !series.disabled);
      }
    }
  }

  isTraceVisible(id: string): boolean {
    return pathOr(false, [this.currentSelectionKey, id], this.selectedTracesBySelection);
  }

  nbHiddenTraces(): number {
    const t = pathOr({}, [this.currentSelectionKey], this.selectedTracesBySelection) as SelectedTraceDict;
    return Object.values(t)
      .reduce((nbHidden, curIsVisible) => nbHidden + (!curIsVisible ? 1 : 0), 0);
  }

  getTraceId(series: BaseSeries, split?: string): string {
    return `${series.title} ${split || NvGraph.noneSplitSuffix}`;
  }

  // to implement in child class for custom after plot actions
  protected afterPlotActions(): void {}

  // use of arrow function to not inherit from the "wrong" context provided by the listener
  private onAfterPlot = (): void => {
    this.createClickableTicks();
    this.getTracesVisibility();
    this.afterPlotActions();
  };

  /*
   * Will attach listeners to the plotly_afterplot event, to do additionnal operations after ploting.
   * By default, will check which traces are displayed for keeping state between draws.
   * Plotly uses npm's events module so we can use `removeListener` even though it is not documented.
   * https://github.com/plotly/plotly.js/issues/107#issuecomment-279716312
   * TODO: remove this method, use this.addEventHandler(id: string, h: ChartEventHandler) instead
   */
  private attachAfterPlot(): void {
    // TODO: remove any cast when migrating to using plotly types
    const plotElem = this.plotElement as any;
    if (!plotElem) return;
    plotElem.removeListener('plotly_afterplot', this.onAfterPlot);
    plotElem.on('plotly_afterplot', this.onAfterPlot);
    this.handlers['plotly_afterplot'] = this.onAfterPlot;
  }

  /*
   * Called after ploting. This removes the need to listen to the plotly_legendclick event,
   * as we directly iterate over all traces in the chart to see if they are visible
   */
  private getTracesVisibility(): void {
    const plotElem = this.plotElement;
    if (!plotElem || !(plotElem as any).data) return;
    (plotElem as any).data.forEach((dataSeries: PlotData) => {
      const { id, visible } = dataSeries;
      const isVisible = visible === true;
      this.setTraceVisibility(id, isVisible);
    });
  }

  private createClickableTicks(): void {
    select(this.plotElement).selectAll('.xtick a').on('click', event => {
      this.navigateToPage(event);
    });
  }

  /**
   * Initialize the graph selects (groupby, metric, splitby) from the config
   */
  public initSelectValues(): void {
    // Reset display & selected values
    this.display = {};
    this.selectedMetrics =
      this.selectedGroup =
      this.selectedSplit =
      this.selectedSize =
        null;
    // In case called by external component (like schedule), make sure settings are up-to-date
    this.settings = this.componentSettings.chart;
    for (const name in this.selects) {
      if (!this.display[name]) {
        if (name !== 'selectFilter') {
          const select = this.selects[name] as ChartSelect;
          let selectValue = select.value;
          // We return the first found select value if force is set
          if (!selectValue && select.force) {
            selectValue = ChartingHelpers.firstSelectValue(select)?.value;
          }
          if (selectValue !== undefined) this.display[name] = selectValue;
        }
      }
    }
    if (!this.display.period && this.intervalField) {
      let period = { extent: null, era: COMPLETE_ERA }; // all dataset, if no default interval
      if (this.intervalField.default) {
        period = {
          extent: DateHelper.period({ era: this.intervalField.default }),
          era: this.intervalField.default,
        };
      }
      this.display.period = period;
    }
  }

  /**
   * By default all metrics are available when multiple is off.
   * This method is overriden in plotly-multi to enable/disable metrics in the dropdown
   */
  public updateMetricsAvailability(): void {}

  /**
   * Event sent by graph options any time a select changes. Here we store the value
   * inside the **display** object
   */
  public onchange(name: ChartSelectKey, value: any) {
    if (name === 'tabFilterSelected') {
      this.selectedTab = value;
    }
    this.display[name] = isArray(value) ? value.join(',') : value;
    if (value == null) {
      console.info('display value is null');
    } else if (name === 'groupby' && this.dynamicGroupByState) {
      this.dynamicGroupByState.groupByManuallySelected = true;
    }

    if (name in this.graphOptionsProductTracking) {
      const trackingInfo = {
        graphTitle: this.title,
        path: this.router.url,
      };
      trackingInfo[`${this.graphOptionsProductTracking[name]}Id`] = value;
      this.productAnalyticsService.trackAction(this.graphOptionsProductTracking[name], trackingInfo);
    }

    // generic serializable values for all graphs
    this.onselect.emit(this.display);
  }

  /**
   * Toggle tail (10+ bars)
   *
   * @param  {boolean} value  Show/hide tail
   */
  public ontoggletail(value: boolean): void {
    this.display.showTail = value;
    this.onselect.emit(this.display);
  }

  /**
   * Toggle display mode (all, relevant or quantiles)
   *
   * @param  {string} value  Display mode
   */
  public ondisplaymode(value: string): void {
    this.display.displayMode = value as ChartDisplayMode;
    this.onselect.emit(this.display);
  }

  /**
   * This methods is meant to be implemented by each chart. It should order the data for export
   * In other words re-organise the data into rows and columns.
   * Note: this method should keep data as is - only re-organise - it should not touch the timestamps
   */
  public prepareCsvData(header: SeriesHeader, data: any[]): ChartExportData {
    return { header, data };
  }

  // Spread event from Graph Option to Chart Wrapper
  public onTable(): void {
    this.showTable.emit();
  }

  /**
   * Prepare Png Export for Plotly charts
   */
  public async onpng(fullExport: boolean = true, width: number = 800): Promise<Element> {
    // Adapt chart dimensions to keep initial width/height ratio
    const actualSvg = document.getElementById(this.componentSettings.id).getElementsByClassName('main-svg')[0];

    // ActualSvg is null when there is no data available
    if (!actualSvg) return null;

    const svgDimensionRatio = actualSvg.clientHeight / actualSvg.clientWidth;
    // Limiting ratio to 0.5 a nicely proportioned graph
    const ratio = svgDimensionRatio >= 0.5 ? svgDimensionRatio : 0.5;
    const dimensions = {
      height: width * ratio,
      width: width,
    };

    await this.plotlyLoader;
    // this should give the full merged SVG but encoded as URL
    let svgUrl = await this.plotlyLib.toImage(
      this.gd,
      { format: 'svg', height: dimensions.height, width: dimensions.width },
    );

    // remove header
    svgUrl = svgUrl.replace('data:image/svg+xml,', '');
    // decode to get back the full svg
    const fullSVG = decodeURIComponent(svgUrl);
    const body = select('body');

    // add the svg as hidden element off the screen
    const svgElement = body.insert('svg')
      .classed('png-svg', true)
      .style('position', 'absolute')
      .style('left', '-5000px')
      .style('width', `${dimensions.width}px`)
      .style('height', `${dimensions.height}px`)
      .html(fullSVG);

    const scale = 2;

    this.prepareSvgForOnPng(svgElement, dimensions, scale, fullExport);

    // Retrieving svg element
    const svgToExport = document.querySelector('.png-svg .main-svg');
    const title = this.title.length ? this.title : this.componentSettings.id;

    if (fullExport) {
      const exportParameters: PngExport = {
        svgId: 'temp-svg',
        svg: undefined,
        contentId: 'svg-content',
        fileName: title,
        height: dimensions.height,
        width: dimensions.width,
        scale: scale,
        afterExport: () => {},
        trackingInfo: {
          exportSource: 'chart',
          componentTitle: title,
          additionalData: {
            trackedMetric: this.display.metric,
            groupedBy: this.display.groupby,
            coloredBy: this.display.splitby,
          },
        },
      };

      await this.browser.exportSvgElementToPng(svgToExport as HTMLElement, exportParameters);
      document.body.removeChild(svgToExport.parentElement);
    }

    return svgToExport;
  }

  /**
   * Adds DOM elements to the given `svgElement`.
   *
   * This method can be overridden by child classes.
   *
   * The default behavior is to add an header & footer (footer only when fullExport is true)
   * Header includes current selects (splitBy, groupBy, metric, tab), current extent.
   *
   * @param svgElement The svg placeholder inside the DOM
   * @param dimensions {height, width} for placement
   * @param fullExport Full export contains logo and translated legends and then download div
   * @returns void
   */
  protected prepareSvgForOnPng(
    svgElement: d3.Selection<HTMLElement>,
    dimensions: { height: number; width: number },
    scale: number,
    fullExport: boolean,
  ): void {
    const graphSvg = svgElement.select('.main-svg');

    // Append header and footer elements to graphSvg
    const header = graphSvg.append('g')
      .attr('class', 'export-header');
    const footer = graphSvg.append('g')
      .attr('class', 'export-footer');

    const groupByTitle = this.selectedGroup ? this.selectedGroup.title : null;
    const colorByTitle = this.selectedSplit ? this.selectedSplit.title : null;
    const metricTitle = this.selectedMetrics?.length
      ? (this.selectedMetrics[0].groupTitle
        ? this.selectedMetrics[0].groupTitle + ' - ' + this.selectedMetrics[0].title
        : this.selectedMetrics[0].title)
      : null;
    const tabTitle = this.selectedTab ? this.selectedTab : null;

    const latestExtent = this.$chartOptions?.intervalRange ?? null;

    const headerHeight = ScheduleDrawingHelper.drawExportHeader(
      header,
      this.title,
      latestExtent,
      dimensions.width,
      groupByTitle,
      colorByTitle,
      metricTitle,
      tabTitle,
    );

    dimensions.height += headerHeight;

    header.attr('font-family', Config.SPIN_DATA_FONT);

    // Full export contains logo and translated legends
    if (fullExport) {
      // Cartesian Layer is the actual graph, Info layer is axis titles and legend
      const graphLayer = this.isPolarPlot ? '.polarlayer' : '.cartesianlayer';
      graphSvg.selectAll(graphLayer + ', .layer-above, .infolayer')
        .attr('transform', `translate(0, ${(headerHeight / scale)})`);

      const logoSrc = '/assets/img/spinergie-logo-confidential.png';

      footer.append('svg:image')
        .attr('x', dimensions.width - 220)
        .attr('y', dimensions.height - 110)
        .attr('width', 200)
        .attr('height', 100)
        .attr('xlink:href', logoSrc);
    }
  }

  /**
   * Triggered on click or by chart-wrapper
   * XLS Export of a chart
   */
  public onXls() {
    const chartExportData = this.prepareCsvData(this.buffer.header, this.buffer.data);
    /*
     * x represents the absis, so we always want to sort by x
     * Unless x is not a number (boxplots for example)
     * In that case the order should remain the same
     */
    if (chartExportData.data.length > 0 && !isNaN(chartExportData.data[0].x as number)) {
      chartExportData.data = chartExportData.data.sort((a, b) => {
        return (a.x as number) - (b.x as number);
      });
    }
    const title = this.title.length ? this.title : this.componentSettings.id;
    const exportData = NvGraph.prepareExport(
      chartExportData,
      this.opts,
      this.getXAxisFormat(true),
      title,
      this.selectedMetrics?.[0],
      this.selectedGroup,
      this.selectedSplit,
    );
    this.browser.exportXls(exportData, title);
  }

  public static prepareExport(
    chartExportData: ChartExportData,
    opts: any,
    xAxisFormatting: AxisFormatting,
    title?: string,
    metric?: SelectableMetric,
    group?: SelectableGroupBy,
    split?: SelectableSplitBy,
  ): ExportData {
    const exportHeader: string[] = [];
    const finalData: any[] = [];
    const header = chartExportData.header;
    const label: string = Object.keys(header).find(key => header[key] === 'Label');

    // Add first label if it is in header
    if (label) exportHeader.push(header[label] as string);

    // Add X-axis value: either explicitly passed in header, or 'date' if the x-axis is 'datetime', else empty
    const xHeader = header.x ? header.x : opts['xaxis'] === 'datetime' ? 'date' : '';
    exportHeader.push(xHeader as string);

    /*
     * Note that we used to have a code here that would set the type of the Excel cell to *date*
     * for time based grouping - we do not do it anymore, because technically it is almost impossible
     * to make it work correctly with timezones - please read time-handling.md:
     * ```
     * if (xAxisFormatting.isTimeFormatting) {
     *  const col = label ? 'B' : 'A';
     *   columnOptions[col]['t'] = 'd';
     * }
     * ```
     */

    for (const key in header) {
      // Label and X-axis value already added
      if (key === 'x' || key === label) continue;
      // Use header title if set, else key (eg. `{ Q1: "1st Quartile" }`)
      const headerForColumn = header[key] || key;
      exportHeader.push(headerForColumn as string);
    }

    // Init column options
    const columnOptions: ColumnOption[] = [];
    for (let i = 0; i < exportHeader.length; i++) {
      columnOptions.push({});
    }

    // Go over all rows to be exported
    chartExportData.data.forEach(row => {
      const exportRow = [];

      // If graph got labels (usually scatter), we want them to be firt column (even before X-axis)
      if (label) exportRow.push(row[label]);

      // Push X-axis value using exportFormatter (dates as 'YYYY-MM-DD', other types as is)
      exportRow.push(xAxisFormatting.exportFormatter(row.x));

      // For other columns try to determine if they should be formatted as numbers
      for (const key in header) {
        // Label and X-axis value already added
        if (key === 'x' || key === label) continue;

        const cellValue = row[key];

        // Find column index (check on `key` alone if `header[key]` not found, see above)
        const i: number = exportHeader.indexOf((header[key] || key) as string);
        const col = String.fromCharCode(65 + i); // 65 is 'A'

        // If the value in the cell can be converted into a number, then we'll set the format and round the value
        if (Number(cellValue)) {
          if (cellValue === round(cellValue, 0) && !columnOptions[i].increasedPrecision) {
            columnOptions[i].col = col => {
              col.numFmt = '0';
            };
          } else {
            columnOptions[i].col = col => {
              col.numFmt = '0.0';
            };
            columnOptions[i].increasedPrecision = true;
          }
        }
        exportRow.push(cellValue ? cellValue : 0);
      }
      finalData.push(exportRow);
    });

    if (metric) {
      finalData.push([]);
      finalData.push(['Metric: ' + (metric.groupTitle ? (metric.groupTitle + ' - ') : '') + metric.title]);
    }

    return {
      data: finalData,
      header: exportHeader,
      columnOpts: columnOptions,
      trackingInfo: {
        exportSource: 'chart',
        componentTitle: title,
        additionalData: { trackedMetric: metric?.title, groupedBy: group?.title, coloredBy: split?.title },
      },
    };
  }

  public static formatNumberWithCommas(number: number) {
    return number.toLocaleString('en');
  }

  public barColors(splitValue: string): Color {
    const splitby = this.selects.splitby?.values.find(v => v.value === this.display.splitby);

    // if we have managed to find the config we will use it
    if (splitby) {
      /*
       * splitby has colors attribute, we will use it
       * there might be specific split value specified which will be used for the coloring
       * that's in case the key is composite (eg "Demand Contract Options") where only "Contract Options" should
       * be used for coloring
       */

      if (splitby.colors && splitby.colors.length > 0) {
        return splitby.colors.find(color => color.id === splitValue)?.fill;
      }
      /*
       * this is the case where we have the splitby config - we now acccording to which
       * value we should color, but we don't know how = so we will pick
       */
    }

    // if we didn't manage to find any splitby config, we will use by default
    return this.splitByColorService.getOrAssignColor(this.selectedSplit, splitValue)?.fill;
  }

  public getXAxisFormat(forExport: boolean = false): AxisFormatting {
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(this.selectedGroup);
    /**
     * If a custom title is set (for scatter series for example), do not rely on selectedGroup
     * nor on opts.xaxis, return value as-is (except for export purpose)
     */
    if (!forExport && this.opts?.tooltip?.title) {
      return {
        isTimeFormatting: false,
        formatter: d => d,
        exportFormatter: d => d,
      };
    }
    /*
     * For graph with mixed data (numerical and string)
     * the numerical are already formatted so we just return xAxisFormatting
     */
    if (isTimeGrouping && this.opts.xAxisType !== 'mixed') {
      const totalInterval: Interval = [this.minX, this.maxX];

      /*
       * For temporal scatter we can't use the groupby to determine the best tick and formatting
       * but we can use the min and max X values as interval
       */
      if (this.data.every(series => series.type === 'scatter')) {
        return this.timeCreator.getTimeAxisFormattingForInterval(totalInterval, null, this.selectedGroup);
      }
      return this.timeCreator.getXAxisFormattingForGroupbyTimeAxis(this.selectedGroup, totalInterval);
    }

    /* Set the formatter to use given d3Format, fallback on generic formatting otherwise */
    const formatter = this.opts.xaxis?.tickformat
      ? d => format(this.opts.xaxis.tickformat)(d)
      : TimeCreator.genericFormatting;

    let exportFormatter = d => d;
    if (isTimeGrouping && this.opts.xAxisType === 'mixed') {
      exportFormatter = d => this.timeCreator.timeStampExportFormat(d);
    }

    return {
      isTimeFormatting: false,
      formatter: formatter,
      d3Format: this.opts.xaxis?.tickformat ? this.opts.xaxis?.tickformat : ',.1~f',
      exportFormatter: exportFormatter,
      /* Set the step in-between ticks */
      dtick: this.opts.xaxis?.dtick,
    };
  }

  /**
   * Get Y-axis formatting options
   * Formatting method, tick suffix & options
   *
   * @param  string         yaxis    Y-axis name
   * @param  boolean        tooltip  To be used in tooltip
   * @return AxisFormatting
   */
  public getYAxisFormat(yaxis = 'yaxis', tooltip: boolean = false): AxisFormatting {
    let decimalFormat = this.layout[yaxis]?.tickformat;
    const intFormat = ',';
    const ticksuffix = this.layout[yaxis]?.ticksuffix;
    const yRange = [this.minY, this.maxY];

    // If no format defined, guess from range
    if (!decimalFormat) {
      decimalFormat = this.determineDecimalFormat(yRange);
    }

    const formatter = d => {
      // If number is lower than 1, displays 2 decimal, otherwise 1
      if (this.layout[yaxis]?.adaptiveDecimal) {
        return d < 1 ? format('.2f')(d) : format('.1f')(d);
      }
      if (typeof d === 'string' || d === undefined || d === null) {
        return d;
      }
      if (isNaN(Number(d))) {
        console.info("Can't convert");
        return d;
      }
      if (d || d === 0) {
        return !(d % 1) ? format(intFormat)(d) : format(decimalFormat)(d);
      }
    };

    const formatting: AxisFormatting = {
      formatter: formatter,
      d3Format: decimalFormat,
      exportFormatter: d => d,
      isTimeFormatting: false,
      ticksuffix,
    };

    return formatting;
  }

  public setFilter(filter) {
    this.$selects.forEach(($select, i) => {
      if (!filter[i]) {
        return;
      }

      // visible
      $select.value = filter[i];
      // usable
      this.display[$select.id] = filter[i];
    });

    this.onselect.emit(this.display);
  }

  public findSelectValues(): void {
    if (this.selects.metric) {
      this.selectedMetrics = ChartingHelpers.findSelectMetrics(this.display.metric, this.selects.metric);
    }
    if (this.selects.groupby) {
      this.selectedGroup = ChartingHelpers.findSelectValue('groupby', this.display, this.selects);
    }
    if (this.selects.splitby) {
      this.selectedSplit = ChartingHelpers.findSelectValue('splitby', this.display, this.selects);
    }
    if (this.selects.size) {
      this.selectedSize = ChartingHelpers.findSelectValue('size', this.display, this.selects);
    }
  }

  public chartTimeZone(): DateTimezone {
    const hasLightConfig = this.settings.componentTimezone === 'local';
    /**
     *  if chart is a heavy chart or has a heavy series, but it received `local` timezone from the parent
     *  it will change it to utc (in the scope of the component - on the chart level) the parent timezone will be unchanged
     */
    if (this.hasHeavySeries() && hasLightConfig) {
      this.timezoneService.timezoneConfig = { timezone: 'utc' };
    }

    return this.timezoneService.timezone;
  }

  private hasHeavySeries(): boolean {
    return this.settings.series?.some(s => s.endpointType === 'heavy');
  }

  public plotCalc(
    calc: ChartCalcConfig,
    raw: RawDataPoint[],
    chartSettings: ChartSettings = null,
    lines: ChartStraightLine[] = [],
  ): void {
    this.traces = [];
    this.straightLines = lines;

    calc.timezone = this.timezoneService.timezone;

    const { header, data, xAxisType } = ChartingHelpers.transformSeries(calc, raw, chartSettings);
    this.opts.enableRangeSlider = chartSettings.rangeSliderEnabled;
    this.opts.enableToggleTail = chartSettings.toggleTailEnabled;
    this.opts.enableModes = chartSettings.modesEnabled;
    if (chartSettings.rangeSliderEnabled) this.updateBarsDetails(chartSettings);
    if (chartSettings.modesEnabled) this.updateLinesDetails(chartSettings);

    /*
     * We need to detect changes before ploting because
     * plotly graph requires the visual state of the container
     * to be up-to-date in order to correctly estimate the graph size and margins.
     */
    this.cdRef.detectChanges();

    // all drawing is excluded from angular change detection
    this.zone.runOutsideAngular(() => this.plot(header, data, lines, xAxisType));

    // but we need to detect change on the component using the standard way
    this.cdRef.detectChanges();
  }

  /**
   * Bars & lines series details ('Show all' checkbox)
   * FIXME: these 2 methods are only required by plotly-multi, will be removed
   * and dealt in `PlotlyMulti.plotCalc()` directly
   */
  public updateBarsDetails(_: ChartSettings): void {}
  public updateLinesDetails(_: ChartSettings): void {}

  /**
   * Plot single DataSeries
   * Common method for all single-series chart: boxplot, barpolar & waterfall
   */
  public plotDataSeries(
    dataSeries: DataSeries[],
    calc: ChartCalcConfig,
    linesData: ChartStraightLine[] = [],
  ): void {
    if (dataSeries.length > 1) {
      console.warn('Incorrect configuration, cannot plot more than one series in', this.componentSettings.title);
    }
    if (!dataSeries.length) {
      console.error('Incorrect configuration, no series defined in', this.componentSettings.title);
      return;
    }

    // Load selected metrics & chart settings
    this.findSelectValues();
    const chartSettings = this.componentSettings.chart;

    const series = dataSeries[0];
    const metric = this.selectedMetrics[0];
    const title = metric.title ?? series.title ?? '';
    calc.yaxis = { ...metric, title, aggregation: ChartingHelpers.metricColumn(metric.value ?? '') };
    calc.series = series;
    calc.timezone = this.timezoneService.timezone;

    /**
     * FIXME: boxplot metric values should always be 'q:variable' or 'q(aggregation:variable:groupby:prop)'
     * Overriding for now if operation is not set
     */
    if (
      series.type === 'boxplot' && isSimpleAggregation(calc.yaxis?.aggregation) && !calc.yaxis.aggregation.operation
    ) {
      calc.yaxis.aggregation.operation = 'q';
    }

    // Plot first series
    if (ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType)) {
      const { headerMap, data } = series;
      const header = headerMap[metric.value];
      const xAxisType: xAxisType = ChartingHelpers.hasNumericX(data) ? 'numeric' : 'string';
      this.plot(header, data, linesData, xAxisType);
    } else {
      this.plotCalc(calc, series.data, chartSettings, linesData);
    }
  }

  abstract chartSpecificPlot(header: SeriesHeader, data: any[], linesData: ChartStraightLine[]): void;

  /**
   * Default plot()
   */
  public plot(header: SeriesHeader, data: any[], linesData: ChartStraightLine[], xAxisType: xAxisType): void {
    this.buffer = { header, data };
    this.prepareSeriesForPageLink();
    this.fillGapsWithValues();
    this.opts.xAxisType = xAxisType;
    this.traces = [];
    this.chartSpecificPlot(header, data, linesData);
  }

  private prepareSeriesForPageLink(): void {
    // when series come from transformSeries, splitIds has been stored in head.__splitIds
    if (this.buffer.header?.__splitIds) {
      this.splitIds = this.buffer.header.__splitIds;
      delete this.buffer.header.__splitIds; // remove this technical key
    } else {
      this.splitIds = {};
    }

    this.buffer.data.forEach((series: ChartSeries) => {
      if (ChartingHelpers.isHeavyOrHeavyCustom(series.endpointType)) {
        this.prepareHeavySeriesForPageLink(series);
      }
    });
  }

  private prepareHeavySeriesForPageLink(series: ChartSeries): void {
    const hasGroupbyPageLink: boolean = this.hasGroupbyPageLink();

    series.data.forEach(value => {
      if (hasGroupbyPageLink && value.x_id !== undefined) {
        // Store x_id value as a technical key (prefixed with '__'), used to compute url
        value.__xId = value.x_id;
      }

      delete value.x_id;
    });

    this.fillSplitIds(Object.keys(this.buffer.header));
  }

  private fillSplitIds(newIds: (string | number)[]): void {
    newIds.forEach(id => {
      // Only fill if id is valid
      if (DataHelpers.isIdValid(id)) {
        this.splitIds[id] = id;
      }
    });
  }

  protected hasGroupbyPageLink(): boolean {
    return ChartingHelpers.getPageLink(this.selectedGroup, this.config.availablePages) != null;
  }

  private fillGapsWithValues(): void {
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(this.selectedGroup);

    if (isTimeGrouping) {
      this.buffer.data.forEach((series: ChartSeries) => {
        const duration = this.selectedGroup.samplingUnit
          ? { n: this.selectedGroup.samplingDuration || 1, unit: this.selectedGroup.samplingUnit }
          : DateHelper.parseDurationUnitFromString(
            (this.selectedGroup.granularity ?? this.selectedGroup.value) as StringDuration,
          );
        if (!duration) return;

        if (series.connectGaps === 'hide' || series.connectGaps === 'tozero') {
          /** display period is not defined when there is no intervalField so we need to fallback to masterPeriod. */
          const interval: Interval = this.display.period?.extent ?? this.masterPeriod;
          const cumulative = ChartingHelpers.isCumulative(
            this.selectedMetrics ? this.selectedMetrics[0] : series.metric,
          );
          series.data = ChartingHelpers.fillGapsWithValues(series.data, duration, interval, cumulative);
        }
      });
    }
  }

  /**
   * Event handler to the change of the interval inside chart options
   * If dynamic grouping is active it might have to adapt the groupby situation
   * otherwise it will just notify the parent (chart-wrapper for instance)
   * It does not force the refresh of the chart. Chart-wrapper will filter the data
   * based on the interval and ask for a refresh
   */
  public onintervalchange(intervalChange: ChartIntervalChange): void {
    this.display.period = { extent: intervalChange.extent, era: intervalChange.selectedEra };
    this._interval = intervalChange.extent;
    if (intervalChange.intervalReleased === false) {
      this.renderingLoading = true;
      this.cdRef.detectChanges();
      return;
    }
    this.renderingLoading = false;

    this.onchartintervalchange.emit(intervalChange);
  }

  // Get current bounds of interval (min/max)
  public getIntervalBounds(): Interval {
    if (this.$chartOptions) {
      return this.$chartOptions.getRange();
    }
    return this._interval;
  }

  public setInterval(newInterval: ResolvedInterval): void {
    this._interval = newInterval.extent;
    if (this.$chartOptions) {
      this.$chartOptions.setIntervalRange(newInterval, true);
    }
  }

  /**
   * For a double date add a function to force area to select visually the complete dataset
   */
  public setIntervalFullTime(): void {
    if (this.$chartOptions) {
      this.$chartOptions.setIntervalFullTime();
    }
  }

  public resetInterval() {
    this.minX = null;
    this.maxX = null;
    this.initInterval();
  }

  public setVerticalBreaklines(verticalBreaklines: VerticalBreakLine[], breaklineUnit: SpinTimeUnit) {
    this.verticalBreaklines = verticalBreaklines;
    this.breaklineUnit = breaklineUnit;
  }

  public setXAxisTickvals(tickvals: number[]): void {
    const currentTickvals = this.xAxisTickvals;

    // Skip if tickvals have not changed
    if (tickvals.length === currentTickvals?.length && tickvals.every((val, index) => currentTickvals[index] === val)) {
      return;
    }

    // Set the tickvals on nvgraph, which is used on interval change
    this.xAxisTickvals = tickvals;

    if (this.layout) {
      // Set the tickvals to the current layout and refresh the plot
      this.layout.xaxis.tickvals = tickvals;
      this.layout.xaxis.tickmode = 'array';

      this.plotlyPlot();
    }
  }

  public setSelectedIndex(selectedTabIndex: number) {
    if (!this.$chartOptions) {
      return;
    }
    this.$chartOptions.setSelectedIndex(selectedTabIndex);
  }

  public initInterval(): void {
    if (
      this.intervalField
      && (this.intervalField.default || this.intervalField.initBrushInterval)
      && !this.opts.hideOptions
    ) {
      this.$chartOptions.initInterval();
      this._interval = this.$chartOptions.intervalRange;
    }
  }

  /**
   * Plotly charts have different Layout opts from nvd3 charts.
   * This function is used to map opts from chartOpts to plotly opts
   */
  public mapChartOptsInPlotlyLayout(): Partial<Layout> {
    const chartOpts = this.opts;
    const plotlyLayout: Partial<Layout> = {};

    /**
     * If autosize is true and we do not define layout height or width, plotly will initialize them on each relayout.
     * https://plotly.com/javascript/reference/layout/#layout-autosize
     */
    plotlyLayout.autosize = true;

    // Copy axis from config or initialize
    plotlyLayout.xaxis = {
      automargin: true,
      showgrid: true,
      spikedash: 'solid',
      spikethickness: '1',
      spikecolor: 'black',
      // Determines whether spikelines are stuck to the cursor or to the closest datapoints
      spikesnap: 'hovered data',
      spikemode: 'toaxis+across',
      tickfont: { size: this.isScheduleChart ? 11 : 12 },
      ...(this.opts.xaxis ?? {}),
    } as AxisConfig;

    // Fix title size & standoff
    if (plotlyLayout.xaxis.title) {
      plotlyLayout.xaxis.title = {
        text: plotlyLayout.xaxis.title,
        standoff: 10,
        font: { size: this.isScheduleChart ? 11 : 12 },
      };
    }

    const formatter = this.getXAxisFormat();
    const timezone = this.timezoneService.timezone;

    /*
     * Check if we need to force the range of the y-axis
     * We need to do this for line series when the extremes are very close (threshold is 10e-3)
     * because plotly automatically zooms in order to see the first digit difference...
     * This is a precision we do not desire
     * FIXME: Both the 2 blocks below rely on minY/maxY, which are 'all-series' boundaries.
     *        This behavior should be metric dependent.
     */
    const yRange = [this.minY, this.maxY];
    const decimalFormat = this.determineDecimalFormat(yRange);
    if (this.traces.every(trace => trace.type === 'line') && decimalFormat === ',.3~f') {
      // We set a range around this number by changing the second digit value
      const rangeCenterNumber = yRange[0] === 0 ? 0.001 : yRange[0];
      // Get the 1 digit exponent in base 10
      const exp = Math.floor(Math.log10(Math.abs(rangeCenterNumber)));
      const diffExp = exp > -3 ? exp - 1 : exp;
      const offset = Math.pow(10, diffExp);
      plotlyLayout.yaxis = { range: [rangeCenterNumber - offset, rangeCenterNumber + offset] };
    }

    /*
     * Apply "range margins"
     * Customize the range, applying a margin (AxisConfig.rangeMargin) around extrema
     * At this time, only works with a single yaxis
     */
    if (this.selectedMetrics?.[0]?.rangeMargin) {
      const margin = this.selectedMetrics[0].rangeMargin;
      plotlyLayout.yaxis = { range: [yRange[0] - margin, yRange[1] + margin] };
    }

    // Horizontal range slider
    if (this.settings.rangeSliderEnabled && (this.display.showTail || !this.settings.toggleTailEnabled)) {
      /*
       * `automargin: true` makes graph width change when an x-axis label is partially overflowing
       * This is incompatible with rangeslider (making legend glitch when sliding) and option `xanchor: "right"`
       * and not required as every graph can have margin set manually (using opts.margin.l|r|t|b)
       * Set to false if rangeslider is on
       * @see https://plotly.com/javascript/reference/layout/xaxis/
       */
      plotlyLayout.xaxis.automargin = false;
      // Also disable x-axis autorange
      plotlyLayout.xaxis.autorange = false;
      // Use previously calculated shownBarValues if found to avoid a change in the number of bars between the 2 modes
      plotlyLayout.xaxis.range = [-.5, (this.settings.shownBarValues || this.settings.opts.barsTargetNumber) - .5];
      // @see https://plotly.com/javascript/reference/layout/xaxis/#layout-xaxis-rangeslider
      plotlyLayout.xaxis.rangeslider = {
        visible: true,
        bgcolor: '#fff',
        bordercolor: '#fff',
        borderwidth: 1,
        thickness: .05,
      };
    }

    const seriesIndex = this.opts?.xaxis?.restrainRangeToSeries;

    // In this case, range of the whole chart would be the range of the given restrainRangeToSeries
    if (seriesIndex !== undefined && this.traces[seriesIndex]) {
      plotlyLayout.xaxis.range = [this.traces[seriesIndex].x.at(0), this.traces[seriesIndex].x.at(-1)];
    }

    /*
     * handle time grouping charts
     * - if the vertical break lines are passed to this chart we will use them to calculate ticks for the chart
     */
    const isTimeGrouping = ChartingHelpers.isTimeGrouping(this.selectedGroup);
    if (this.interval && isTimeGrouping) {
      const currentExtent = this.$chartOptions?.intervalRange || this.interval;

      /*
       * only if meaningfull interval is specified and vertical breaks are passed from the outside
       * we will try to determine exact ticks
       */
      if (
        currentExtent?.[0] && currentExtent?.[1]
        && currentExtent[0] !== currentExtent[1]
        && this.verticalBreaklines && this.breaklineUnit
      ) {
        let xAxisTickvals: number[];

        // If tick values have been set from the outside, use them (schedule chart for example)
        if (this.xAxisTickvals?.length) {
          xAxisTickvals = this.xAxisTickvals;
        } else {
          /*
           * Otherwise, calculate the tick values
           * Current extent must be shifted *backward* regarding timezone so it comes back when enforceLocalTz is
           * applied avoid missing ticks on the edges when timezone is not local
           */
          const shiftedExtent = currentExtent.map(ts => DateHelper.enforceLocalTz(ts, timezone, true)) as Interval;
          const xAxisParameters: xAxisParameters = {
            currentExtent: shiftedExtent,
            xScale: null,
            breakLineUnit: this.breaklineUnit,
            verticalBreaklines: this.verticalBreaklines,
            width: null,
          };

          xAxisTickvals = ChartTimeManager.getXaxisTicks(xAxisParameters, 10);
          // Timestamp ticks are shifted forward to be displayed as local datetimes
          if (timezone !== 'local') {
            xAxisTickvals = xAxisTickvals.map(ts => DateHelper.enforceLocalTz(ts, timezone));
          }
        }

        plotlyLayout.xaxis.tickvals = xAxisTickvals;
        plotlyLayout.xaxis.tickmode = 'array';
        // Real range is the original one in UTC
        plotlyLayout.xaxis.range = this.interval;
        plotlyLayout.xaxis.autorange = false;
        const formatting = this.timeCreator.getTimeAxisFormattingForInterval(
          this.interval,
          xAxisTickvals.length,
          this.selectedGroup,
        );
        plotlyLayout.xaxis.tickformat = formatting?.d3Format;
      }
    }

    /*
     * When grouping by week with a dtick value, we need to pass plotly the first value.
     * If we don't, Plotly arbitrary takes a random day as the first tick (usually the day before the first week day)
     */
    if (isTimeGrouping && this.selectedGroup.value === 'week' && formatter.dtick != null) {
      plotlyLayout.xaxis.tick0 = dayjs(this.minX).format('YYYY-MM-DD');
    }

    /*
     * By default plotly will try to determine the xaxis type (date, linear, category)
     * based on data. However, there are cases where our xaxis are discrete values of numbers.
     * If we do not specify the 'category' type plotly will plot the values on a linear scale trying to format the x
     * values
     */
    if (this.isTimeGroupingChart && this.opts.xAxisType !== 'mixed') {
      plotlyLayout.xaxis.type = 'date';
    } else if (this.opts.xAxisType) {
      plotlyLayout.xaxis.type = this.opts.xAxisType === 'string' || this.opts.xAxisType === 'mixed' ? 'category' : '-';
    }

    if (plotlyLayout.xaxis.tickformat == null) {
      plotlyLayout.xaxis.tickformat = formatter.d3Format;
    }

    if (plotlyLayout.xaxis.ticks == null && plotlyLayout.xaxis.tickvals == null) {
      plotlyLayout.xaxis.dtick = formatter.dtick;
    }

    if (this.isTimeGroupingChart && plotlyLayout.xaxis.range === undefined) {
      plotlyLayout.xaxis.autorange = true;
      plotlyLayout.xaxis.ticklabelmodel = 'period';
    }

    if (!chartOpts.hovermode) {
      plotlyLayout.hovermode = 'x unified';
    }

    if (this.isPolarPlot) {
      // FIXME: this.selectedMetrics will always be defined soon, using ?. notation until then
      const metric = this.selectedMetrics?.[0];
      if (metric?.suffix && !['°', '%', ' '].includes(metric.suffix.charAt(0))) {
        metric.suffix = ' ' + metric.suffix;
      }
      plotlyLayout.polar = {
        hole: this.opts.holeSize || this.chartType === 'barpolar' ? 0.2 : 0,
        bargap: this.opts.barGap ?? 0.1,
        sector: this.opts.xaxis.sector,
        angularaxis: {
          direction: this.opts.xaxis.direction || 'clockwise',
          rotation: this.opts.xaxis.rotation,
          title: this.opts.xaxis.title,
          ticksuffix: this.opts.xaxis.ticksuffix,
        },
        radialaxis: {
          autorange: true,
          rangemode: 'tozero',
          angle: this.opts.xaxis.rotation,
          side: this.opts.xaxis.direction === 'clockwise' ? 'counterclockwise' : 'clockwise',
          title: metric?.title ?? '',
          ticksuffix: metric?.suffix ?? '',
          showticksuffix: metric?.suffix ? 'all' : 'none',
          tickangle: 90,
        },
      };
    }
    plotlyLayout.paper_bgcolor = 'transparent';
    plotlyLayout.plot_bgcolor = 'transparent';

    // Left margin always the same as right, top as bottom
    plotlyLayout.margin = {
      r: chartOpts.margin && !isNaN(chartOpts.margin.right) ? chartOpts.margin.right : 30,
      l: chartOpts.margin && !isNaN(chartOpts.margin.left) ? chartOpts.margin.left : 30,
      t: chartOpts.margin && !isNaN(chartOpts.margin.top) ? chartOpts.margin.top : 25,
      b: chartOpts.margin && !isNaN(chartOpts.margin.bottom) ? chartOpts.margin.bottom : 25,
      pad: 5,
    };
    if (chartOpts.barGap) plotlyLayout.bargap = chartOpts.barGap;

    // if legend should shown and orientation not defined we will define default legend style
    plotlyLayout.showlegend = chartOpts.showlegend;
    plotlyLayout.legend = chartOpts.legend;
    if (!plotlyLayout.legend && plotlyLayout.showlegend !== false) {
      plotlyLayout.showlegend = true;
      plotlyLayout.legend = { orientation: 'h', yanchor: 'bottom', y: 1.02, xanchor: 'right', x: 1 };
    }

    /*
     * Plotly option which makes the legend stuff of the same size instead of taking the size of the trace
     * (marker or line).
     * https://plotly.com/javascript/reference/layout/#layout-legend-itemsizing
     * Not ideal because everything is a bit too big
     */
    if (this.straightLines && this.straightLines.length > 0 && plotlyLayout.legend) {
      plotlyLayout.legend.itemsizing = 'constant';
    }
    return plotlyLayout;
  }

  /**
   * Copy axis options from metrics (& series metrics)
   */
  protected getYAxisLayout(): Partial<Layout> {
    const yAxisMap: YAxisMap = { y1: 1 };
    const yAxisLayout: YAxisLayout = {};
    const metrics: SelectableMetric[] = [];
    // Selected metric
    if (this.selectedMetrics?.length) {
      metrics.push({ ...this.selectedMetrics[0], yaxisId: 'y1' });
    }
    // Series metrics (FIXME: soon to be removed)
    this.settings.series.forEach(series => {
      if (!series.metric) return;
      const metric = { ...series.metric, yaxisId: series.yaxis ?? 'y1' };
      if (!yAxisMap[metric.yaxisId]) {
        yAxisMap[metric.yaxisId] = Object.keys(yAxisMap).length + 1;
      }
      metrics.push(metric);
    });
    metrics.forEach(metric => this.updateYAxisLayout(yAxisMap, yAxisLayout, metric));
    this.adjustYAxisTitles(yAxisLayout);
    return yAxisLayout;
  }

  /**
   * Update y-axis layout with current metric (single-metrics) or chartSeries (multi-metrics)
   */
  protected updateYAxisLayout(yAxisMap: YAxisMap, yAxisLayout: YAxisLayout, config: ChartSeriesOptions): void {
    const index = yAxisMap[config.yaxisId];
    const nbAxis = Object.keys(yAxisMap).length;
    const plotlyAxisName = (index > 1 ? 'yaxis' + index : 'yaxis') as YAxisKey;
    // Already defined axis
    if (yAxisLayout[plotlyAxisName]) {
      // Complete title (comma-separated)
      if (config.title && yAxisLayout[plotlyAxisName].title) {
        (yAxisLayout[plotlyAxisName].title as any).text += ', ' + config.title;
      }
      return;
    }
    // Common config
    const axisConfig: AxisConfig = {
      automargin: true,
      rangemode: 'nonnegative',
      side: index % 2 ? 'left' : 'right',
      showline: true,
      showgrid: index === 1,
      ticks: 'outside',
      ticklabelstandoff: 3,
      tickfont: { size: this.isScheduleChart ? 11 : 12 },
    };
    // For alternative axis (index > 1), enable overlaying
    if (index > 1) axisConfig.overlaying = 'y';
    // For y3 & y4, shift axes (using free anchoring)
    if (index >= 3) {
      axisConfig.position = index === 3 ? 0 : 1;
      axisConfig.anchor = 'free';
      if (this.isScheduleChart) {
        // For schedule charts, we impose the same width for all axis, to keep chart aligned with schedule
        axisConfig.shift = index === 3 ? -Y_AXIS_WIDTH : Y_AXIS_WIDTH;
      } else {
        // For other charts, we let Plotly adjust width regarding the range & unit (autoshift)
        axisConfig.autoshift = true;
        axisConfig.shift = index === 3 ? -10 : 10; // add 10px between adjacent axis
      }
    }
    // Add metric title if there is only 1 axis (y1 without y3, or y2 without y4)
    if (index <= 2 && nbAxis < index + 2) {
      axisConfig.title = { font: { size: 12 }, standoff: 15, text: config.title };
    }
    // Report metric options to axis config
    if (config.suffix && !['°', '%', ' '].includes(config.suffix.charAt(0))) {
      config.suffix = ' ' + config.suffix;
    }
    ['format', 'suffix'].forEach(optionName => {
      if (config[optionName]) axisConfig['tick' + optionName] = config[optionName];
    });
    ['adaptativeDecimal', 'range', 'rangemode'].forEach(optionName => {
      if (config[optionName]) axisConfig[optionName] = config[optionName];
    });
    yAxisLayout[plotlyAxisName] = axisConfig;
  }

  /**
   * Shorten y-axis titles if more than 40 characters (or 20 on schedule charts)
   */
  protected adjustYAxisTitles(yAxisLayout: YAxisLayout): void {
    for (const yAxisKey in yAxisLayout) {
      const axisConfig = yAxisLayout[yAxisKey];
      if (axisConfig.title) {
        const title = axisConfig.title as any;
        title.text = shortenWithPoints(title.text, this.isScheduleChart ? 20 : 40);
      }
    }
  }

  /**
   * Create additional vertical trace corresponding to today.
   * For numeric x-axis type, it works well because the xaxis is linear and plotly handles it. In this case
   *    the line is placed at the right place in the correct time group.
   * For mixed x-axis type, we pass ordered fixed groups to plotly. We can only put the line in today time group, thus centered...
   */
  createTodayLine() {
    if (!this.opts.todayLine || !ChartingHelpers.isTimeGrouping(this.selectedGroup)) {
      return;
    }

    const timeLevel = ChartingHelpers.getTimeLevel(this.selectedGroup);
    const nowDate = dayjs.utc();
    const timezone = this.timezoneService.timezone;

    let now: number = nowDate.valueOf();
    if (timezone !== 'local' && timeLevel < TimeLevelEnum.week) {
      now = DateHelper.enforceLocalTz(now, timezone);
    }

    /*
     * If xAxisType is mixed and we are in a time grouping chart, xAxis values should be
     * formatted before plot, so we have to do the same with the today line
     */
    if (this.isTimeGroupingChart && this.opts.xAxisType === 'mixed') {
      now = ChartingHelpers.renderTimeToGroupByLevel(dayjs(now), this.selectedGroup) as any;
    } else if (timeLevel > TimeLevelEnum.week) {
      const { level, times } = TimeLevelHalfDuration[timeLevel];

      now = nowDate.subtract(times, level).valueOf();
    }

    const todayTrace: PlotData = {
      x: [now, now],
      y: [this.minY < 0 ? this.minY : 0, this.getHighestGraphPoint()],
      showlegend: false,
      mode: 'lines',
      name: `Today ${ChartingHelpers.renderTimeToGroupByLevel(nowDate, this.selectedGroup)}`,
      customData: { hoverType: NvGraph.todayLineHoverType },
      // we are making the point of the same size as the line
      line: {
        color: 'grey',
        dash: 'dash',
        width: 2,
      },
      marker: {
        size: 0,
      },
    };
    if (this.maxYAxis) {
      todayTrace.yaxis = this.maxYAxis;
    }
    if (this.maxX > now) {
      this.traces.push(todayTrace);
    }
  }

  /**
   * Returning the highest point on the chart. We have 3 situations:
   * 1. Some ranges are defined in the config or due to adjustMultipleAxisTicks
   * 2. No ranges are defined and we don't have bars - we can just take the highet line point
   * 3. No ranges are defined and we have bars - we need to sum the bars to get the highest point
   */
  protected getHighestGraphPoint() {
    const configuredRanges = [
      this.layout?.yaxis?.range?.[1] ?? null,
      this.layout?.yaxis2?.range?.[1] ?? null,
    ].filter(d => d != null);

    if (configuredRanges.length > 0) {
      return max(configuredRanges);
    }

    const bars = this.traces.filter(({ type }) => type === 'bar');
    /*
     * maxY contains the biggest Y value for single split or serie
     * for lines this is the highest point, but for bars it's not the case
     * the highest point for bars if they are stacked is the addition
     */
    if (!bars.length) {
      return this.maxY;
    }

    const maxFromBars = this.getMaxTotalOfBars(bars);
    const maxY = this.maxY ?? -Infinity;

    return Math.max(maxY, maxFromBars);
  }

  getMaxTotalOfBars(bars: PlotData[]) {
    let max = null;
    for (let i = 0; i < bars[0].x.length; i++) {
      // for each point on xaxis determine the sum of all the bars (only for bar series)
      const totalValueForBar = sumBy(bars, d => d['y'][i]);
      if (!isNaN(totalValueForBar) && (max == null || totalValueForBar > max)) {
        max = totalValueForBar;
      }
    }
    return max;
  }

  /**
   * Test if a point should be visible in tooltip. Can be overiden by specific charts
   */
  protected pointVisibleInTooltip(_: PlotDatum, __: TooltipSeries): boolean {
    return true;
  }

  protected specificTooltip(_: any, __: TooltipSeries): void {}

  computeDistanceOnX(x: any, xval: number): number {
    let value = 0;

    if (!isNaN(x)) {
      value = x as number;
    } else if (dayjs(x).isValid()) {
      value = dayjs.utc(x).valueOf();
    } else {
      /** If x is not a number nor a valid date, we can't compute a distance. */
      return 0;
    }
    return Math.abs(xval - value);
  }

  /**
   * Calculate the closest point to the tooltip xval, return the point and distance
   * This function will be use to remove points outside this minimum distance
   */
  getTooltipClosestPointAndDistanceOnX(points: PlotDatum[], xval: number | string): [PlotDatum, number] {
    let closestPoint = null;
    let shortestDistance = Number.MAX_SAFE_INTEGER;

    // if xval is a string, we are in a categorical (bar) chart. Do not bother calculate distances
    if (typeof xval === 'string') {
      return [points.find(d => d.x === xval), 0];
    }

    for (const point of points) {
      const xDistance = this.computeDistanceOnX(this.getDataPointX(point), xval);
      if (xDistance === 0) {
        return [point, 0];
      }

      if (closestPoint === null || xDistance < shortestDistance) {
        closestPoint = point;
        shortestDistance = xDistance;
      }
    }
    return [closestPoint, shortestDistance];
  }

  getFormattedXAxisTooltipValue(point: RealPlotDatum, formatting: AxisFormatting): string {
    const xaxis = this.isPolarPlot ? 'theta' : 'x';
    const customData = this.getPointCustomData(point);
    if (customData?.title) {
      // Already formatted title
      return customData.title;
    }

    // format and add suffix
    const xValue = customData?.fullX ?? point[xaxis];
    return formatting.formatter(xValue) + (this.opts.xaxis?.ticksuffix ?? '');
  }

  /**
   * Format series to be used in tooltip
   *
   * @param  TooltipSeriesBasicInfo seriesBasics  Data series
   * @return TooltipSeries                        Tooltip series
   */
  public createTooltipSeries(seriesBasics: TooltipSeriesBasicInfo): TooltipSeries {
    const xAxisFormatting = this.getXAxisFormat();
    const [closestPoint, _] = this.getTooltipClosestPointAndDistanceOnX(seriesBasics.points, seriesBasics.xval);

    const points = seriesBasics.points;

    // Look up the original data series linked to this datapoint
    let chartSeries: ChartSeries = null;
    if (!this.isMultiSeriesChart()) {
      chartSeries = {
        title: this.title,
        data: this.buffer.data,
        header: this.buffer.header,
        hideTotal: this.opts.hideTotal,
      } as ChartSeries;
    } else {
      // Find closest series by name
      if (this.buffer.data.length > 1) {
        chartSeries = this.buffer.data.find(({ title }) => seriesBasics.seriesName === String(title));
      }
      // Fallback to first series
      if (!chartSeries) {
        chartSeries = this.buffer.data[0];
      }
    }
    const name = !this.isMultiSeriesChart() || this.buffer.data.length === 1 ? '' : chartSeries.title;

    // x-axis title shared for all series on given X position
    const xAxisTitle = this.getFormattedXAxisTooltipValue(closestPoint, xAxisFormatting);
    const tooltipSeries: TooltipSeries = {
      type: chartSeries.type,
      errorMaxs: {},
      errorMins: {},
      name,
      title: chartSeries.title, // keep original title
      splits: [],
      showErrors: chartSeries.errorBars ? true : false,
      total: null,
      xValue: xAxisTitle,
    };

    // xPageUrl can be defined in the current groupBy...
    if (this.hasGroupbyPageLink()) {
      const pageLink = ChartingHelpers.getPageLink(this.selectedGroup, this.config.availablePages);
      tooltipSeries.xPageUrl = this.computeXPageUrl(pageLink, points[0]);
    }
    // ... or in the tooltip options
    const pageLinkHelper = this.getTooltipTitlePageLink();
    if (pageLinkHelper) {
      tooltipSeries.xPageUrl = this.computeXPageUrl(pageLinkHelper, points[0]);
    }

    let total = null;

    points.forEach(point => {
      if (!this.pointVisibleInTooltip(point, tooltipSeries)) {
        return;
      }
      const yAxisFormatting = this.getYAxisFormat(point.yaxis._name, true);

      /*
       * in relative points are points such as waterfall points
       * for those we have the access to the previsous (initial), delta, and total values
       * we will add those as splits
       */
      if (point.measure === 'relative') {
        const initialSplit: SeriesSplit = {
          color: this.getDatapointColor(point),
          title: 'Initial',
          value: this.applyFormatTooltip(point.initial, yAxisFormatting),
        };

        const addedSplit: SeriesSplit = {
          color: this.getDatapointColor(point),
          title: xAxisTitle,
          value: this.applyFormatTooltip(point.delta, yAxisFormatting),
        };

        tooltipSeries.splits.push(initialSplit);
        tooltipSeries.splits.push(addedSplit);
        chartSeries.hideTotal = true;
      }

      // We try to detect straight lines (these are fake scatters with 2 points on the same axis)
      const isStraightLine = point.data.type === 'scatter'
        && point.data?.customData?.hoverType === NvGraph.straightLineHoverType;
      /*
       * if we already have them in the tooltip we skip. We are faking straight line with a scatter
       * so we will get 2 points in the tooltip if we are close enough
       */
      if (isStraightLine && tooltipSeries.splits.some(d => d.title === point.data.name)) {
        return;
      }

      const skipProps = [this.opts?.tooltip?.comment, this.opts?.tooltip?.title, this.opts?.labelProperty];
      const customData = this.getPointCustomData(point);

      const seriesSplit: SeriesSplit = {
        color: this.getDatapointColor(point),
        title: this.getDataPointTitle(point, chartSeries),
        value: this.applyFormatTooltip(point.y, yAxisFormatting),
        comment: customData?.comments?.[point.data.splitTitle],
      };

      if (this.isSplitByPageLink()) {
        seriesSplit.pageUrl = this.computeSplitPageUrl(point);
      }

      tooltipSeries.splits.push(seriesSplit);
      // We want to exclude median and overall series value from tooltip total
      if (
        this.display.displayMode !== 'relevant'
        || !(seriesSplit.title === 'Median' || seriesSplit.title.startsWith('Overall trend'))
      ) {
        total = total ? total + point.y : point.y;
      }

      // Additional properties
      if (customData?.additionalProps) {
        for (const split in customData.additionalProps) {
          customData.additionalProps[split].forEach(additionalProp => {
            if (!skipProps.includes(additionalProp.prop) && additionalProp.value != null) {
              tooltipSeries.splits.push(additionalProp);
            }
          });
        }
      }

      this.specificTooltip(point, tooltipSeries);
    });

    if (
      !this.opts.hideTotal
      && !chartSeries.hideTotal
      && this.selectedMetrics?.length === 1
      && tooltipSeries.splits.length > 1
    ) {
      const yAxisFormatting = this.getYAxisFormat(points[0].yaxis._name, true);
      tooltipSeries.total = this.applyFormatTooltip(total, yAxisFormatting);
    }

    return tooltipSeries;
  }

  /** @overriden by plotly-multi */
  protected isMultiSeriesChart(): boolean {
    return false;
  }

  protected computeXPageUrl(pageLinkHelper: PageLinkHelper, point: RealPlotDatum): string {
    const id = this.getPointCustomData(point).xId;
    return this.computeEntityPageLink(pageLinkHelper, id);
  }

  protected getTooltipTitlePageLink(): PageLinkHelper {
    const opts = this.opts.tooltip;
    if (!opts) return null;
    return opts.pageLink !== undefined
      ? PageLinkHelper.fromConfigPageLink(opts.pageLink, this.config.availablePages)
      : PageLinkHelper.inferFromKey(opts.title, this.config.availablePages);
  }

  protected getTooltipAdditionalProps(): SeriesSplit[] {
    const opts = this.opts.tooltip;
    const additionalProps: SeriesSplit[] = [];
    if (this.opts.labelProperty) {
      additionalProps.push({
        prop: this.opts.labelProperty,
        title: 'Label',
      });
    }
    if (!opts) return additionalProps;
    for (const field of opts?.extend ?? []) {
      additionalProps.push({
        prop: field.id,
        title: field.title,
        type: field.type,
        format: field.format,
      });
    }
    /**
     * FIXME: To preserve the initial logic behind scatter tooltip, `title` & `comment` have a special
     *        treatment, but this will be discarded to keep only `extend`ed properties.
     */
    if (opts?.title && (!opts?.extend || !opts.extend.find(field => field.prop === opts.title))) {
      additionalProps.push({
        prop: opts.title,
        title: 'Tooltip title',
      });
    }
    if (opts?.comment && (!opts?.extend || !opts.extend.find(field => field.prop === opts.comment))) {
      additionalProps.push({
        prop: opts.comment,
        title: 'Tooltip comment',
      });
    }
    return additionalProps;
  }

  protected isSplitByPageLink(): boolean {
    return ChartingHelpers.getPageLink(this.selectedSplit, this.config.availablePages) != null;
  }

  private computeSplitPageUrl(point: RealPlotDatum): string {
    const id = this.splitIds[point.data.split ?? point.data.name];
    const pageLink = ChartingHelpers.getPageLink(this.selectedSplit, this.config.availablePages);
    return pageLink ? this.computeEntityPageLink(pageLink, id) : null;
  }

  public splitSeriesPoints(data: PlotMouseEvent): TooltipSeriesBasicInfo[] {
    const xval = data.xvals[0];

    if (Object.keys(this.namesBySeries).length <= 1) {
      return [{
        seriesName: '',
        points: data.points as PlotDatum[],
        xval,
      }];
    }

    return Object.keys(this.namesBySeries).map(seriesName => ({
      seriesName,
      xval,
      // *series* variable is a custom variable added on the trace level
      points: data.points.filter(point => point.data.series === seriesName),
    }));
  }

  /**
   * Creates a TooltipSeries for straight lines - object that is later shown in the tooltip
   */
  createStraightLineTooltipSeries(point: PlotDatum): TooltipSeries {
    /*
     * straight lines - are currently only vertical lines they can use the xAxisFormatting
     * if we ever have horizontal lines in the future, we will have to pass this information
     * to the series object and get it out from the series here
     */
    const x = this.getFormattedXAxisTooltipValue(point, this.getXAxisFormat());

    const opts: TooltipSeries = {
      type: 'line',
      xValue: x,
      splits: [{
        color: point.data.line.color as Color,
        title: point.data.name,
        value: x,
      }],
      showErrors: false,
      hideTotal: true,
    };
    return opts;
  }

  /*
   * Plotly does not have a "x unified" hovermode for polar plots and only returns the closest point.
   * This method's aim is emulating a "theta unified" behavior.
   */
  private unifiedPolarPlotTooltipSeries(mouseData: PlotMouseEvent): TooltipSeries[] {
    const formatPct = format('.1~%');
    const formatDecimal = format('~d');
    const closestPoint = mouseData.points[0];
    const yAxisFormatting = this.getYAxisFormat(mouseData.points[0].yaxis._name, true);
    const xAxisFormatting = this.getXAxisFormat();
    const xAxisTitle = this.getFormattedXAxisTooltipValue(closestPoint, xAxisFormatting);
    const allSeries = [];
    const isBar = this.chartType === 'barpolar';
    for (const data of this.data) {
      const series: TooltipSeries = {
        type: data.type,
        xValue: xAxisTitle,
        showErrors: false,
        total: 0,
        splits: [],
      };
      let i = 0;
      for (const split in data.header) {
        const [closestPointSplit, _] = this.getTooltipClosestPointAndDistanceOnX(
          data.data.filter(d => d[split] !== null).map(d => ({ value: d[split], 'x': d.x })),
          closestPoint.theta,
        );
        // value of 0 in polar means null: don't display in tooltip
        if (!this.isTraceVisible(this.getTraceId(data, split)) || (isBar && closestPointSplit.value === 0)) {
          ++i;
          continue;
        }
        series.total += closestPointSplit.value;
        const valueNb = closestPointSplit.value;
        let title = data.header[split] as string;
        let value = this.applyFormatTooltip(valueNb, yAxisFormatting);
        // if distribution, we append the suffix to the legend title instead of the value
        if (data.isBinDistribution) {
          title = this.applyFormatTooltip(title, yAxisFormatting);
          value = valueNb;
        }
        // if to percent, display the percentage of values, as well as the original value
        if (data.toPercent && closestPoint.data.total) {
          value = `${formatPct(valueNb / 100)} (${formatDecimal(valueNb * closestPoint.data.total / 100)})`;
        }
        const serieSplit: SeriesSplit = {
          color: this.traces[i].line?.color || this.traces[i].marker?.color,
          title,
          value,
          rawValue: closestPointSplit.value,
        };

        if (this.isSplitByPageLink()) {
          serieSplit.pageUrl = this.computeSplitPageUrl(closestPoint);
        }
        series.splits.push(serieSplit);
        ++i;
      }
      // tooltip entries order reflect the displayed bar order on barpolar
      if (!isBar) series.splits.sort((a, b) => b.rawValue - a.rawValue);
      if (this.opts.hideTotal && series.splits.length !== 1) {
        series.total = null;
        // if only one split, it is the total that is displayed in the tooltip
      } else if (series.splits.length === 1) {
        series.total = series.splits[0].value;
      } else {
        series.total = this.applyFormatTooltip(series.total, yAxisFormatting);
      }
      allSeries.push(series);
    }
    return allSeries;
  }

  /**
   * Update tooltip layout configuration
   * depending on the number of series, and for each the number of splits (see .head)
   */
  public updateTooltipLayout(): void {
    const conf = {};
    /**
     * nbSplitPerSeries keep track of the number of splits and/or metrics per series,
     * to decide how to render them in the tooltip. We use `max()` here to be sure
     * the value is 1 (special case) only if there is a single metric and no splits.
     */
    const nbMetrics = this.selectedMetrics?.length ?? 1;
    this.data.forEach(series => {
      if (!series.title || !series.header) return;
      const nbSplits = Object.keys(series.header).length;
      conf[series.title] = Math.max(nbMetrics, nbSplits);
    });
    this.$chartTooltip.nbSplitPerSeries = conf;
  }

  // use of arrow function to make sure we access the right "this" in a listener callback
  plotlyTooltip = (data: PlotMouseEvent): void => {
    if (this.opts.usePlotlyTooltip) {
      return;
    }

    if (this.isPolarPlot) {
      const coords: Coords = [data.event.x, data.event.y];
      const series = this.unifiedPolarPlotTooltipSeries(data);
      this.$chartTooltip.show({ series }, null, coords);
      return;
    }

    /*
     * we take away the straight line to calculate the tooltip series only on the
     * data we then add the straight lines series after we calculated the series
     */
    const normalData = [];
    const straightLines = [];

    /*
     * go over all data points that plotly sends in the event
     * check if they are straight lines or regular points
     * today-lines are not added to the tooltip
     */
    for (const series of data.points) {
      const hoverType = series.data?.customData?.hoverType;
      if (hoverType === NvGraph.straightLineHoverType) {
        straightLines.push(series);
      } else if (hoverType !== NvGraph.todayLineHoverType) {
        normalData.push(series);
      }
    }

    /*
     * Plotly sometimes return series values even for series
     * that do not have a point exactly for the tooltip xVal (but close to this point).
     * We will therefore calculate the closest point of all series.
     * In a second step we will keep only the points that have exactly this distance.
     * (It is assumed that before plotting we group the points of all the series at the same ticks values)
     */
    const xVal = data.xvals[0];
    const [closestPoint, shortestDistance] = this.getTooltipClosestPointAndDistanceOnX(data.points, xVal);

    const coords: Coords = [data.event.x, data.event.y];
    const series = this.splitSeriesPoints({ ...data, points: normalData })
      .filter(d => {
        /*
         * We calculate for each point the distance to the tooltip xVal
         * if this distance if further than the closest point we remove these points
         */
        d.points = d.points.filter(point => {
          const xDistance = this.computeDistanceOnX(this.getDataPointX(point), xVal);
          return closestPoint && xDistance <= shortestDistance;
        });
        return d.points.length > 0;
      })
      .map(d => this.createTooltipSeries(d))
      .concat(
        straightLines.filter(line => {
          /*
           * same for straight lines points, we only keep straight line if distance to
           * current xVal is less than the closestPoint
           */
          const xDistance = this.computeDistanceOnX(line.x, xVal);
          return closestPoint && xDistance <= shortestDistance;
        }).map(point => this.createStraightLineTooltipSeries(point)),
      );
    if (!series.length) {
      // Skip if no data to show in tooltip (happens on today line)
      return;
    }
    this.$chartTooltip.show({ timezone: this.timezoneService.timezone, series }, null, coords);
  };

  static readonly straightLineHoverType = 'straight-lines-hover-type';
  static readonly todayLineHoverType = 'today-line-hover-type';

  /**
   * Get data point X value
   * On mixed-type charts (scatter + line/bar series), `x-unified` hovering mode fails at filtering the scatter series,
   * adding scatter points with different X values (as calculated distances on categories are always 0)
   * Using the private `_categoriesMap` property of the Plotly event, one can convert X-axis label to a numeric value
   * (from 0 to N - 1, N being the number of categories shown).
   * @see https://github.com/spinergie/spinapp/pull/8488 (SP-8522)
   */
  protected getDataPointX(point: any): number {
    // Special case for points on non-numeric X-axis (aka categories)
    if (isNaN(point.x) && !dayjs(point.x).isValid() && point.xaxis?._categoriesMap) {
      return point.xaxis._categoriesMap[point.x];
    }
    return point.x;
  }

  public getDatapointColor(point: any) {
    if (point.data.marker) {
      return point.data.marker.color;
    }

    if (point.fullData.line) {
      return point.fullData.line.color;
    }

    if (point.fullData.marker) {
      return point.fullData.marker.color;
    }

    if (point.data.type !== 'waterfall') {
      console.info('Cant figure out color for point: ', point);
    }
  }

  /**
   * Returns the title of single split or single series inside the tooltip
   */
  public getDataPointTitle(point: PlotDatum, series: ChartSeries): any {
    /*
     * Remove series title at the beginning of the split title
     * When the split title is 'explicit', it has the form: "{Series title} - {Split title}"
     * in that case we remove the first part in tooltip: "{Series title} - "
     */
    if (series.title?.length > 1 && point.data.name.startsWith(series.title + ' - ')) {
      return point.data.name.slice(series.title.length + 3);
    }

    return point.data.name;
  }

  public applyFormatTooltip(value: number | string, formatting: AxisFormatting): string {
    let formatted = value as string;
    if (typeof value === 'number') formatted = formatting.formatter(value);
    if (formatting.ticksuffix) {
      formatted = formatted + formatting.ticksuffix;
    }
    return formatted;
  }

  /**
   * Plots a graph in the `this.plotElement`, according to `this.traces` and `this.layout`
   */
  public async plotlyPlot(): Promise<void> {
    if (this.noDataToShow()) {
      return;
    }
    const config = { responsive: true, displayModeBar: false };

    this.makeXaxisPageLinks();
    await this.plotlyLoader; // if import not finished, wait for it
    /*
     * Since, page may have changed and DOM may not be available anymore
     * FIXME: worse, if changing from a vessel page to another vessel page, element has the same ID
     * and the last finished query will win :(
     * Div should be referenced sooner as a DOMElement, then check if element still exists (and has a parent)
     *   this._plotlyChartDiv = document.getElementById(this.plotlyChartId)
     *   if (!this._plotlyChartDiv.parentNode) { ... return; }
     *   this.plotlyLib.react(this._plotlyChartDiv, ...)
     */
    if (!this.plotElement) {
      console.info('Too late plotly... skip');
      return;
    }
    /** We don't want any information from plotly hover, as we implement our own */
    this.traces.forEach(t => t.hoverinfo = 'none');
    this.plotlyLib.react(this.plotElement, this.traces, this.layout, config).then(gd => {
      this.gd = gd;
      this.calculatedData = gd.calcdata;
    });

    /*
     * When using both `xaxis.type = 'date'` and `xaxis.autorange = true`, Plotly defaults to displaying the year
     * 2000 when all traces are deselected from the legend, which is not desirable.
     * To mitigate this, disable autorange once plotly has calculated the range.
     */
    const plotlyLayout: Layout = this.plotElement.layout;
    if (
      plotlyLayout.xaxis.type === 'date'
      && plotlyLayout.xaxis.autorange === true
      && plotlyLayout.xaxis.range?.[0]
      && plotlyLayout.xaxis.range?.[1]
    ) {
      this.plotlyRelayout({ 'xaxis.autorange': false });
    }

    this.attachAfterPlot();
    this.plotted = true;
    // Reinit xAxisTicksLabel dict
    this.xAxisTicks = {};
  }

  private isHref(value: string): boolean {
    // Sometimes value can be a timestamp (scatter), aka a number instead of an expected string, using .toString()
    return value.toString().includes('<a href="');
  }

  private makeXaxisPageLinks(): void {
    if (!this.hasGroupbyPageLink()) return;

    if (!this.areTracesMissingPageLinks()) return;

    if (this.hasCustomTicks()) {
      this.makeCustomTicksPageLink();
    } else {
      this.traces.forEach(trace => {
        if (!trace.x || !trace.customData) return;

        trace.x = trace.x.map(
          // Using .?xId in case customData isn't defined for one of the x values
          (xValue: string, i: number) => this.makeTickAnHtmlLink(xValue, trace.customData[i]?.xId),
        );
      });
    }
  }

  /**
   * For now we generate all PageLinks in one call
   * Adding a check in case one day we generate the page links a different way
   */
  private areTracesMissingPageLinks(): boolean {
    return this.traces.some(trace => {
      if (!trace.x || !trace.x.length) return false;
      /** We only check the first elem of each trace to verify if we need to build page links */
      const pageLink = this.hasCustomTicks() ? this.layout?.xaxis?.ticktext?.[0] : trace.x[0];
      /** Check if a trace is not a hRef */
      return !this.isHref(pageLink);
    });
  }

  private hasCustomTicks(): boolean {
    return Boolean(this.layout?.xaxis?.ticktext?.length);
  }

  protected makeCustomTicksPageLink(): void {
    // In this case, we need to concatenate customData from all traces
    const customData = this.traces.flatMap(t => t.customData);

    this.layout.xaxis.ticktext = this.layout.xaxis.ticktext.map(
      (tickValue: string, i: number) => this.makeTickAnHtmlLink(tickValue, customData[i].xId),
    );
  }

  protected makeTickAnHtmlLink(tickValue: string, entityId: string): string {
    /** In case pageLink has already been build */
    if (this.isHref(tickValue)) return tickValue;

    const pageLink = ChartingHelpers.getPageLink(this.selectedGroup, this.config.availablePages);
    const url = pageLink ? this.computeEntityPageLink(pageLink, entityId) : null;
    return url !== null ? `<a href="${url}">${tickValue}</a>` : tickValue;
  }

  private computeEntityPageLink(pageLinkHelper: PageLinkHelper, entityId: string | number) {
    if (!pageLinkHelper) return null;
    const url = pageLinkHelper.computeUrlFromId(entityId);

    if (this.settings.opts.pageLinkSendIntervalAsMasterPeriod && this.interval) {
      return `${url}&masterPeriod=${this.interval.toString()}`;
    }
    return url;
  }

  /**
   * Chart could have navigate links in xAxis ticks or tooltips.
   * This function allows to navigate without reloading the app.
   */
  public navigateToPage(event: any): void {
    if (DatabaseHelper.isSpecialClick(event)) {
      return;
    }
    const url = event.target.href.baseVal ?? event.target.href; // href.baseVal for svg links
    const actionEvent = NavigationHelper.buildInternalNavigationEventFromUrl(url, event);
    this.navigate.emit(actionEvent);
  }

  /**
   * Update whole graph without data change (faster than plotlyLib.react)
   */
  public plotlyUpdate(): void {
    this.plotlyLib.update(this.plotElement, {}, this.layout, Array.from(this.traces.keys()));
  }

  /**
   * Update plotly's internal context `_baseUrl` to the current url.
   * Needed because plotly uses clip-paths referencing elements with the format url#id,
   * which breaks when we update our SPA's url
   */
  private refreshPlotlyContextBaseUrl(): void {
    if (!this.gd) {
      return;
    }

    this.gd['_context']['_baseUrl'] = document.location.href;
  }

  /**
   * Forces plotly to relayout, includes hacks to address plotly's legend-related bugs
   * https://github.com/spinergie/spinapp/pull/3849
   * https://github.com/spinergie/spinapp/pull/4328
   */
  public forceRelayout(): void {
    const plotElem = this.plotElement;
    // These hacks are only needed if a legend is displayed
    if (!plotElem?.layout?.['showlegend']) {
      return;
    }

    // Ensure plotly's base url is up to date with the browser's url, so that plotly's legend clip-path value is valid
    this.refreshPlotlyContextBaseUrl();

    /**
     * Relayout without and then with the legend displayed to force plotly to place it correctly
     * TODO: remove when fixed by plotly: https://github.com/plotly/plotly.js/issues/6492
     */
    this.plotlyRelayout({ 'showlegend': false });
    this.plotlyRelayout({ 'showlegend': true });
  }

  public setFullScreenIndicator(fullScreen: boolean): void {
    this.isFullScreen = fullScreen;
  }

  /**
   * Only updates the layout of the graph.
   */
  public plotlyRelayout(update: unknown = {}): void {
    // If plotly is not ready or if the elem has been removed from the dom (happens when no data to show), skip
    if (!this.plotlyLib || !this.plotElement) {
      return;
    }

    try {
      this.plotlyLib.relayout(this.plotElement, update);
    } catch (e) {
      if (
        e instanceof TypeError && (e.message.includes("(reading '_preGUI')") || e.message.includes('pt is undefined'))
      ) {
        throw new ErrorWithFingerprint('Plotly relayout error', ['plotly-relayout-error-plotly-undefined']);
      } else {
        throw e;
      }
    }
  }

  public shouldShowLabelsCheckbox(): boolean {
    return this.configOpts.labelProperty !== undefined;
  }

  // use of arrow function to not inherit from the "wrong" context provided by the listener
  hideTooltip = (): void => {
    this.$chartTooltip.hide();
  };

  public addPlotlyTooltip(): boolean {
    const target = this.plotElement;

    const handlers = [
      {
        id: 'plotly-hover-for-tooltip',
        event: 'plotly_hover',
        handler: this.plotlyTooltip as () => void,
        target,
      },
      {
        id: 'plotly-unhover-for-tooltip',
        event: 'plotly_unhover',
        handler: this.hideTooltip,
        target,
      },
    ];

    return handlers
      .map(({ id, ...handler }) => this.addEventHandler(id, handler))
      .some(inserted => inserted);
  }

  public addEventHandler(id: string, h: ChartEventHandler): boolean {
    const { event, handler, target } = h;

    if (!target) {
      return false;
    }

    if (is(Function, (target as any).on)) {
      /**
       * Plotly uses npm's `events` module so we can use `removeListener` even though it is not documented.
       * https://github.com/plotly/plotly.js/issues/107#issuecomment-279716312
       */
      (target as any).removeListener(event, handler);
      (target as PlotHTMLElement).on(event, handler);
    } else {
      target.addEventListener(event, handler);
    }
    this.handlers[id] = h;
    return true;
  }

  /**
   * Plotly will use x values as tick labels.
   * We need to shorten long labels (like vessel names) using ellipsis (...)
   * but ensure unique x values lead to unique labels (like two vessel starting with the same substring)
   */
  protected adaptValueToXAxisTick(x: any) {
    if (typeof x !== 'string') {
      return x;
    }

    // Default label (remove '(formerly X)')
    if (x.indexOf('formerly') > 0) {
      x = x.substring(0, x.indexOf('('));
    }

    // Former value
    if (this.xAxisTicks[x]) {
      return this.xAxisTicks[x];
    }

    // Increase label length until a unique label is found
    let len = 15;
    let label: string;
    do {
      label = shortenWithPoints(x, len++);
    } while (Object.values(this.xAxisTicks).includes(label));

    // Store
    this.xAxisTicks[x] = label;
    return label;
  }

  /**
   * Retrieve custom data for given point (custom data are Plotly custom data in each plotted point)
   * @param point a point from the Plotly chart
   */
  protected getPointCustomData(point: PlotData): CustomTooltipData {
    return point.data?.customData?.[point.pointIndex];
  }

  public defaultValueFormatter(d: any): string {
    if (typeof d === 'string') {
      return d;
    }

    if (d === undefined || d === null) {
      return d;
    }

    if (typeof d === 'boolean') {
      return d ? 'Yes' : 'No';
    }

    if (Array.isArray(d)) {
      return d[0];
    }

    if (isNaN(Number(d))) {
      console.info('Default formatter cant convert value');
      return d;
    }

    const decimalFormat = ',.1~f';
    const intFormat = ',';
    return !(d % 1) ? format(intFormat)(d) : format(decimalFormat)(d);
  }

  public noDataToShow() {
    return this.noRawData || this.noRawDataForMetric;
  }

  public getDefaultSplitKeyValue(data: any) {
    if (!data['All'] && this.selectedMetrics[0]?.title && data[this.selectedMetrics[0].title]) {
      return this.selectedMetrics[0].title;
    }
    return 'All';
  }

  /** Suffix given text with "sampling", except if the text already contains it (e.g "No sampling") */
  public formatSamplingText(text: string): string {
    if (text.includes('sampling')) return text;
    else return `<strong> ${text}</strong> sampling`;
  }

  /* * Private * */
  private get svgId() {
    return this.componentSettings.id + '-svg';
  }

  private determineDecimalFormat(range: number[], tickNumber: number = 10): string {
    const yDiff = range[1] - range[0];

    const tickDifference = yDiff / tickNumber; // difference between each tick value
    /*
     * if the difference between each tick is less than one then we display a number after the decimal point
     * the smaller the difference, the greater the number of digits after the decimal point.
     * We limit the max number of decimals to 3
     */
    let decimalNumber = (yDiff !== 0)
      ? Math.min(
        3,
        Math.max(0, Math.ceil(-1 * Math.log10(tickDifference))),
      )
      : 0;

    if (decimalNumber === 0 || isNaN(decimalNumber)) {
      decimalNumber = 1;
    }

    return ',.' + decimalNumber.toString() + '~f';
  }
}
