import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { AnalyticsBrowser, AnalyticsBrowserSettings } from '@segment/analytics-next';

import { Config } from '../../config/config';
import { AppInfoService } from '../../app/app-info-service';
import { TrackedActionType } from './product-analytics.types';

/** Beware! The following settings should be updated when updating the package, see https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/custom-proxy/#self-hosting-analyticsjs */
import SEGMENT_CDN_SETTINGS from './segment-cdn-settings';

@Injectable({
  providedIn: 'root',
})
export class ProductAnalyticsService {
  constructor(
    private config: Config,
    private router: Router,
    private route: ActivatedRoute,
    private appInfoService: AppInfoService,
  ) {
  }

  /* To avoid product analytics being blocked by clients browser, we send the analytics to a proxy. */
  private static readonly ANALYTICS_PROXY_URL = 'api-segmnt.spinergie.net/v1';

  private active: boolean = false;
  private analytics: AnalyticsBrowser;

  /**
   * Analytics have to be enabled in config
   */
  public get isEnabled(): boolean {
    return this.config.appParams?.analyticsToken !== null;
  }

  public activate(): void {
    this.setup();
    this.identifyUser();
    this.trackPagesWhenNavigating();
    this.active = true;
    this.trackAction('userLoggedIn');
  }

  private setup(): void {
    const proxyOptions = { integrations: { 'Segment.io': { apiHost: ProductAnalyticsService.ANALYTICS_PROXY_URL } } };
    /** We're using the Analytics package instead of the script to avoid script loading being blocked by uBlock. */
    this.analytics = AnalyticsBrowser.load(this.settings, proxyOptions);
  }

  private get settings(): AnalyticsBrowserSettings {
    const cdnSettings = SEGMENT_CDN_SETTINGS as any;
    SEGMENT_CDN_SETTINGS['integrations']['Segment.io']['apiKey'] = this.config.appParams.analyticsToken;

    return {
      writeKey: this.config.appParams.analyticsToken,
      cdnSettings,
    };
  }

  private identifyUser(): void {
    const userInfo = ProductAnalyticsService.buildUser(this.config);
    this.analytics.identify(userInfo.externalUserId, userInfo);
  }

  private static buildUser(config: Config): Params {
    const {
      spinergieUserId: userId,
      externalUserId,
      client,
      isAppOwner,
      spinergieUserEmail: email,
      jobCategory,
      userGroupId,
    } = config.userInfo;
    const { product, env } = config.appParams;
    const project = config.appParams.project;
    const productPackages = config.userRights.productPackages;

    const name = `${userId} - ${client}`;
    const domain = email.match(/@(.+)$/)?.[1] ?? 'dummyDomainForUsernamesWithoutEmail';

    return {
      userId,
      externalUserId,
      name,
      domain,
      client,
      isAppOwner,
      productPackages,
      product,
      env,
      project,
      userGroupId,
      jobCategory,
    };
  }

  private trackPagesWhenNavigating(): void {
    this.router.events
      .pipe(filter(value => value instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        /** We don't track map page here but in the map dashboard, because of missing params at init. */
        if (this.appInfoService.currentDashboardIsMap()) return;

        this.trackCurrentPage();
      });
  }

  // see https://web.dev/learn/pwa/detection#detecting_display_mode
  private isPwa(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  /** track current page with specificied props or use route params */
  public trackCurrentPage(props?: Readonly<Params>): void {
    this.trackPage(this.router.url.split('?')[0], props ?? this.route.snapshot.queryParams);
  }

  public trackPage(route: string, params: Readonly<Params> = {}): Promise<void> {
    if (!this.active) return;

    const pageConfig = this.config.getPageConfigFromRoute(route);

    this.analytics.page(pageConfig.title, {
      ...params,
      userAgent: window.navigator.userAgent,
      isPwa: this.isPwa(),
    });
  }

  /** Modal is tracked as a page, but without a route specified. This allows to have info about source page. */
  public trackModal(title: string, props: Readonly<Params> = {}): void {
    if (!this.active) return;

    this.analytics.page(title, { ...props, userAgent: window.navigator.userAgent, isPwa: this.isPwa() });
  }

  public trackAction(actionType: TrackedActionType, params: Readonly<Params> = {}): void {
    if (!this.active) return;

    this.analytics.track(actionType, {
      ...params,
      userAgent: window.navigator.userAgent,
      isPwa: this.isPwa(),
    });
  }
}
