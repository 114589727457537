export default {
  'integrations': {
    'Mixpanel (Actions)': { 'versionSettings': { 'componentTypes': [] } },
    'Segment.io': {
      'apiKey': 'PLACEHOLDER_REPLACED_AT_RUNTIME',
      'unbundledIntegrations': [],
      'addBundledMetadata': true,
      'maybeBundledConfigIds': {},
      'versionSettings': { 'version': '4.4.7', 'componentTypes': ['browser'] },
      'apiHost': 'api-segmnt.spinergie.net//v1',
    },
  },
  'plan': {
    'track': { '__default': { 'enabled': true, 'integrations': {} } },
    'identify': { '__default': { 'enabled': true } },
    'group': { '__default': { 'enabled': true } },
  },
  'edgeFunction': {},
  'analyticsNextEnabled': true,
  'middlewareSettings': {},
  'enabledMiddleware': {},
  'metrics': { 'sampleRate': 0.1, 'host': 'api-segmnt.spinergie.net//v1' },
  'legacyVideoPluginsEnabled': false,
  'remotePlugins': [],
};
