<div class="kpi_title">
  {{ fieldSettings().title }}
  <description-button
    *ngIf="fieldSettings().description"
    [description]="fieldSettings().description"></description-button>
</div>
<ng-container *ngIf="!loading(); else loadingIndicator">
  <ng-container *ngIf="hasValue(); else noData">
    <field-button
      *ngIf="fieldSettings().button; else kpiValue"
      [buttonConfig]="fieldSettings().button"
      [values]="{}"
      (buttonClick)="buttonClick.emit($event)">
      <ng-container *ngTemplateOutlet="kpiValue"></ng-container>
    </field-button>

    <ng-template #kpiValue>
      <div class="kpi_value">
        <div>
          <span class="kpi_value_number">
            {{ formattedValue() }}
            <ng-container *ngIf="hasTarget()"> / {{ target() }}</ng-container>
          </span>
          {{ fieldSettings().suffix }}
        </div>
        <pearl-icon
          *ngIf="comparisonArrow()"
          [icon]="comparisonArrowIcon()"
          [size]="24"
          [style.color]="comparisonArrow()?.color" />
      </div>
    </ng-template>

    <div class="kpi_additional-data" *ngIf="hasPreviousValue() || numericTarget()">
      <div *ngIf="hasPreviousValue()" class="kpi_previous-value">
        (prev: {{ formattedPreviousValue() }} {{ fieldSettings().suffix }})
      </div>

      <div *ngIf="numericTarget() as numeric" class="kpi_progress-bar">
        <pearl-progress-bar
          [value]="numeric.value"
          [maxValue]="numeric.target"
          [maxExceededMessage]="targetExceededMessage()" />
      </div>
    </div>
  </ng-container>

  <ng-template #noData>
    <div class="kpi_value">No data</div>
    <div class="kpi_additional-data"></div>
  </ng-template>
</ng-container>

<ng-template #loadingIndicator>
  <mat-spinner diameter="20"></mat-spinner>
</ng-template>
