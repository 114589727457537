import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, inject,
  signal } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Router } from '@angular/router';

import dayjs from 'dayjs';
import { throttle } from 'lodash-es';

import { DoubleDateComponent } from '../filters/doubledate';
import { IntervalComponent } from '../filters/interval';
import { FilterHelper } from '../filters/filter-helper';
import { ChartIntervalChange, ChartOptions, ChartParamChange, ChartSelectKey, ChartSelects,
  PlotlyChartSeries } from '../graph/chart-types';
import { TimeCreator } from '../helpers/time-creator';
import { Config } from '../../src/config/config';
import { COMPLETE_ERA, ChartDisplayMode, ChartSelectOptions, ComponentOptionsTab, DynamicGroupByState, Era,
  FilterApplied, Interval, IntervalField, ResolvedInterval } from '../helpers/types';
import { ChartingHelpers } from './charting-helpers';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { SelectComponent } from '../filters/select';
import { FiltersMenuComponent } from '../filters/filters-menu';
import { UIService } from '../shared/services/ui.service';
import { PearlButtonComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-graph-options',
  templateUrl: 'graph-options.html',
  styleUrls: ['graph-options.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatTabsModule,
    NgFor,
    SelectComponent,
    MatCheckboxModule,
    MatTooltipModule,
    FormsModule,
    FiltersMenuComponent,
    IntervalComponent,
    NgClass,
    DoubleDateComponent,
    PearlButtonComponent,
    MatButtonToggleModule,
  ],
})
export class GraphOptionsComponent {
  @Input()
  graphId: string;
  @Input()
  opts: ChartOptions;
  @Input()
  noData: boolean;
  @Input()
  showTableButton: boolean;
  @Input()
  selects: ChartSelects;
  @Input()
  messages: { [select: ChartSelectKey]: string } = {};
  @Output()
  showTable = new EventEmitter<void>();
  @Output()
  xls = new EventEmitter<any>();
  @Output()
  png = new EventEmitter<any>();
  @Output()
  onchange = new EventEmitter<ChartParamChange>();
  @Output()
  onintervalchange = new EventEmitter<ChartIntervalChange>();
  @Output()
  onshowlabels = new EventEmitter<boolean>();
  @Output()
  ontoggletail = new EventEmitter<boolean>();
  @Output()
  ondisplaymode = new EventEmitter<string>();

  @Input()
  intervalField: IntervalField;
  @Input()
  availableSelects: ChartSelectKey[];
  @Input()
  display: ChartSelectOptions;
  @Input()
  minX: number;
  @Input()
  maxX: number;
  @Input()
  labelCheckbox: boolean;
  @Input()
  showBarModeControls: boolean;
  @Input()
  dynamicGroupByState?: DynamicGroupByState;
  @Input()
  multiple?: boolean;

  private _tabs = signal<ComponentOptionsTab[]>([]);

  @ViewChild('chartInterval')
  public $intervalBrush: IntervalComponent;
  @ViewChild('chartDoubleDate')
  public $intervalDoubleDate: DoubleDateComponent;

  public cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  public config: Config = inject(Config);

  public intervalRange: Interval;
  public intervalMinMax: Interval;
  public showLabel: boolean;
  public selectedIndex: number = 0;

  private productAnalyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);
  private router: Router = inject(Router);
  public uiService: UIService = inject(UIService);

  set tabs(tabs: ComponentOptionsTab[]) {
    this._tabs.set(tabs);
  }

  get tabs(): ComponentOptionsTab[] {
    return this._tabs();
  }

  // Init eras and interval min max range in doubledate if needed
  public updateIntervalDoubleDate(): void {
    if (this.$intervalDoubleDate) {
      this.$intervalDoubleDate.update();
    }
  }

  public onChange(select: ChartSelectKey, $event): void {
    if (select === 'metric') {
      const selectedMetrics = ChartingHelpers.findSelectMetrics($event, this.selects.metric);
      this.productAnalyticsService.trackAction('chartMetricUpdated', {
        graphTitle: this.graphId,
        metric: selectedMetrics.map(metric => metric.title).join(','),
      });
    }
    this.onchange.emit({ chartParam: select, value: $event });
  }

  public onXls($event): void {
    this.xls.emit($event);
  }

  public onpng($event): void {
    this.png.emit($event);
  }

  public onShowTable(): void {
    this.showTable.emit();
  }

  /**
   * Triggered when a tab is selected.
   */
  public indexChanged(index: number): void {
    this.onchange.emit({ chartParam: 'tabFilterSelected', value: this.tabs[index].value });
  }

  public setSelectedIndex(index: number): void {
    if (this.selectedIndex !== index) {
      this.selectedIndex = index;
    }
  }

  /**
   * EventHandler for interval change inside chart options
   * we use a throttle to limit the interval update to one update each 200ms
   */
  public onChartIntervalChange = throttle((filter: FilterApplied, changeEnd: boolean) => {
    /*
     * If filter isn't active clear interval
     * TODO: we should not rely on this event and changeEnd. We should remove changeEnd
     * there is a specific interval released event
     */
    if (!filter.active || !changeEnd) {
      this.onintervalchange.emit({
        id: filter.id,
        extent: filter.values as Interval,
        filterType: this.intervalField.filterType ? this.intervalField.filterType : 'datetime',
        clearInterval: true,
        intervalReleased: changeEnd,
        selectedEra: filter?.selectedEra,
      });
      return;
    }
    const resolvedInterval = FilterHelper.resolvedIntervalFromFilterApplied(filter);
    this.setIntervalRange(resolvedInterval, false);
    this.productAnalyticsService.trackAction('chartPeriodUpdated', { graphTitle: this.graphId });
  }, 200);

  /**
   * SetIntervalRange can be called either from the EventHandler or from nvgraph directly.
   * When it is called from nvgraph new interval might come from the exterior (chart-wrapper) probably
   * so the min and max bounds might have to be adapted
   */
  public setIntervalRange(resolvedInterval: ResolvedInterval, forceTheInterval: boolean): void {
    let newInterval = resolvedInterval.extent;
    if (resolvedInterval.era?.isCompleteDataset) newInterval = [null, null];
    else if (isNaN(newInterval[0]) || isNaN(newInterval[1])) {
      return;
    }
    this.intervalRange = newInterval;

    if (this.$intervalBrush) {
      this.$intervalBrush.setFilter(resolvedInterval, true, false);
    }
    if (this.$intervalDoubleDate) {
      this.$intervalDoubleDate.setFilter(resolvedInterval, false);
    }
    if (!this.intervalMinMax) {
      this.intervalMinMax = newInterval;
    }

    /*
     * only if we are called from outside graph-options we might be forced to adapt our bounds
     * if we are called from the intervalhandler then we now that the bounds never have to go outside
     */
    if (forceTheInterval) {
      // if the interval would be out of bounds, they have to be adapted
      if (this.intervalMinMax[0] > newInterval[0]) {
        this.intervalMinMax[0] = newInterval[0];
      }

      if (this.intervalMinMax[1] < newInterval[1]) {
        this.intervalMinMax[1] = newInterval[1];
      }
    } else {
      // Only bubbles if interval was not forced, to avoid an excedentary refresh
      this.onintervalchange.emit({
        id: this.intervalField.id,
        extent: newInterval,
        filterType: this.intervalField.filterType ? this.intervalField.filterType : 'datetime',
        clearInterval: !newInterval[0] && !newInterval[1],
        intervalReleased: true,
        selectedEra: resolvedInterval?.era,
      });
    }
    this.cdRef.detectChanges();
  }

  /**
   * For a double date add a function to force area to select visually the complete dataset
   */
  public setIntervalFullTime(): void {
    if (this.$intervalDoubleDate) {
      this.$intervalDoubleDate.selectEra(COMPLETE_ERA, false);
      this.cdRef.detectChanges();
    }
  }

  public setIntervalMinMax(intervalMinMax: number[]): void {
    this.minX = intervalMinMax[0];
    this.maxX = intervalMinMax[1];
    this.intervalField.interval = intervalMinMax;
    if (this.$intervalBrush) this.$intervalBrush.setNewBounds(intervalMinMax);
  }

  public getRange(): Interval {
    return [this.minX, this.maxX];
  }

  /**
   * Init the interval filter range and min max
   */
  public initInterval(): void {
    if (!this.intervalField || this.intervalRange) {
      return;
    }
    // If intervalField interval is populated we take it as min & max
    if (this.intervalField?.interval) {
      this.minX = this.intervalField.interval[0];
      this.maxX = this.intervalField.interval[1];
    }
    /*
     * If minX and maxX are not defined, this means there is no data available
     * we set them by default to 1st january of 2016 and today
     */
    this.minX = this.minX ? this.minX : Config.SPIN_MIN_DATE;
    this.maxX = this.maxX ? this.maxX : Date.now();
    this.intervalMinMax = this.getRange();
    let initIntervalRange: Interval = [null, null];
    if (this.intervalField.fixedInterval) {
      const fixedIntervalRange = TimeCreator.getInitTimestamps(
        dayjs(),
        this.intervalField.initBrushInterval,
        this.intervalField.initBrushUnit,
        this.minX,
        this.maxX,
        this.minX,
        null,
        true,
      );
      this.intervalRange = fixedIntervalRange;
      this.intervalField.interval = fixedIntervalRange;
      initIntervalRange = fixedIntervalRange;
    } else {
      if (this.minX !== Number.MAX_VALUE && this.maxX !== Number.MIN_VALUE) {
        initIntervalRange = TimeCreator.getInitTimestamps(
          dayjs(),
          this.intervalField.initBrushInterval,
          this.intervalField.initBrushUnit,
          this.minX,
          this.maxX,
          this.minX,
          null,
        );
      }
      this.intervalRange = initIntervalRange;
      /*
       * For some graphs the initInterval and the intervalMinMax have different max,
       * so we need to be sure that we take the biggest interval
       */
      if (this.intervalMinMax[1] < this.intervalRange[1]) {
        this.intervalField.interval = [this.intervalMinMax[0], this.intervalRange[1]];
      } else {
        this.intervalField.interval = this.intervalMinMax;
      }
    }
    let defaultEra: Era;
    if (this.$intervalDoubleDate) {
      // use the default era if defined
      if (this.intervalField.default) {
        defaultEra = this.intervalField.default;
        this.intervalRange = [this.$intervalDoubleDate.start.valueOf(), this.$intervalDoubleDate.end.valueOf()];
      } else {
        this.$intervalDoubleDate.update();
        this.$intervalDoubleDate.setFilter({ extent: initIntervalRange }, false);
      }
    }
    if (this.$intervalBrush) {
      this.$intervalBrush.update();
      this.$intervalBrush.setFilter({ extent: initIntervalRange, era: defaultEra }, true, false);
    }

    this.cdRef.detectChanges();
  }

  /**
   * This function apply is the series processing needed before plotting
   * If the asked interval is under the minX and maxX values we need to
   * add false points on series. We need this because otherwise nvd3 plot only series values
   * so xaxis would be between minX and maxX.
   *
   * We always add intervalRange min and max point for non discrete series because even if minX and maxX are in this
   * interval the filtered minX and maxX displayed will be inside this interval. So we add these points to have a
   * continuous line from the interval min to the interval max
   */
  public static seriesIntervalProcessing(
    allSeries: PlotlyChartSeries[],
    currentExtent: Interval,
    discreteSeries: boolean,
  ): PlotlyChartSeries[] {
    if (!discreteSeries) {
      allSeries.forEach(series => {
        series.values.push({
          x: currentExtent[0],
          y: null,
        });
      });

      allSeries.forEach(series => {
        series.values.push({
          x: currentExtent[1],
          y: null,
        });
      });
    }

    return allSeries;
  }

  public getSelectId(select: string): string {
    return `${this.graphId}-${select}`;
  }

  public intervalHasValues(): boolean {
    return this.intervalRange?.filter(el => el != null).length === 2;
  }

  public showLabels($event: boolean): void {
    this.onshowlabels.emit($event);
  }

  /**
   * Show tail values else aggregate after N bars
   *
   * @param  {boolean} $event  Is checked?
   */
  public toggleTail($event: boolean): void {
    if ($event) {
      this.productAnalyticsService.trackAction('showAllButtonClicked', {
        graphTitle: this.graphId,
        url: this.router.url,
      });
    }
    this.ontoggletail.emit($event);
  }

  /**
   * Switch between 'all' and 'relevant'
   *
   * @param  {boolean} $event  Is checked?
   */
  public toggleMode($event: boolean): void {
    const mode = $event ? 'all' : 'relevant';
    if (mode === 'all') {
      this.productAnalyticsService.trackAction('showAllButtonClicked', {
        graphTitle: this.graphId,
        url: this.router.url,
      });
    }
    this.ondisplaymode.emit(mode as ChartDisplayMode);
  }

  /**
   * Change display mode: all / relevant / quantiles
   *
   * @param  {string} $event  Chosen mode
   */
  public selectMode($event: string): void {
    this.ondisplaymode.emit($event as ChartDisplayMode);
  }

  public getMinSelectable(select: ChartSelectKey): number | undefined {
    if (select !== 'groupby') {
      return undefined;
    }
    return this.dynamicGroupByState?.groupByMinSelectable;
  }
}
