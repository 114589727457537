import { groupBy, orderBy } from 'lodash-es';
import dayjs from 'dayjs';

import { FilteredScheduleItems, FilteredScheduleLayer, PunctualShapeSettings, ScheduleFilteringParams, ScheduleLayer,
  ScheduleLike, ScheduleTab, Scheduled, VisibilityDict } from './schedule-types';
import { Interval, NumOrString, SpinTimeUnit, milliSecondInPeriod } from '../helpers/types';
import { NOT_AVAILABLE } from './schedule-drawing-helper';
import { getChained } from '../data-loader/ref-data-provider';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';

/**
 * Contains drawing helper methods that could have been extracted from the schedule and schedule drawing helper
 * help with time
 */
export class ScheduleTimeManager {
  /**
   * In some calculation (e.g. the schedule interval minDate) We need to introduce a RIGHT sideMargin to be sure that we
   * draw the punctual contract and its label without any cut
   */
  public static getPunctualShapeDefaultDateEnd(
    punctualDate: number,
    punctualShapeSettings: PunctualShapeSettings,
  ): number {
    const milliSecondDuration = milliSecondInPeriod[punctualShapeSettings.durationUnit]
      ? milliSecondInPeriod[punctualShapeSettings.durationUnit]
      : milliSecondInPeriod['month'];

    return punctualDate + punctualShapeSettings.minWidthDuration * milliSecondDuration;
  }

  /**
   * In some calculation (e.g. the schedule interval minDate) We need to introduce a left sideMargin to be sure that we
   * draw the punctual contract and its shape without any cut.
   * This sidemargin will be one quarter of the margin that is added to the end date
   */
  public static getPunctualShapeDefaultDateStart(
    punctualDate: number,
    punctualShapeSettings: PunctualShapeSettings,
  ): number {
    const milliSecondDuration = milliSecondInPeriod[punctualShapeSettings.durationUnit]
      ? milliSecondInPeriod[punctualShapeSettings.durationUnit]
      : milliSecondInPeriod['month'];

    return punctualDate - (punctualShapeSettings.minWidthDuration * milliSecondDuration) / 4;
  }

  /**
   * Depending the interval latest extent determine the correct unit for vertical breaklines.
   * e.g.: If the interval is more than 1 month => vertical unit will be month
   * If the interval is more than 1 year => vertical unit will be year ...
   */
  public static determineBreakLineUnit(latestExtent: number[]): SpinTimeUnit {
    if (!latestExtent || latestExtent.length !== 2) {
      return null;
    }
    const intervalRange = latestExtent[1] - latestExtent[0];
    if (!intervalRange || intervalRange < 0) {
      return null;
    }
    let breakLineUnit = null;

    if (intervalRange < milliSecondInPeriod['day'] * 2) {
      breakLineUnit = 'hour';
    } else if (intervalRange < milliSecondInPeriod['isoWeek'] * 2) {
      breakLineUnit = 'day';
    } else if (intervalRange < milliSecondInPeriod['month'] * 2) {
      breakLineUnit = 'isoWeek';
    } else if (intervalRange < milliSecondInPeriod['quarter'] * 2) {
      breakLineUnit = 'month';
    } else if (intervalRange < milliSecondInPeriod['year'] * 2) {
      breakLineUnit = 'quarter';
    } else {
      breakLineUnit = 'year';
    }
    /*
     * we return true if we need to redraw the timeline (case where we previously had a breakline unit
     * and the new one is different from the old one)
     */
    return breakLineUnit;
  }

  /*
   * This function return the period unit above current breakline unit
   * for exemple if the current breakline unit is month this function will return quarter
   * By default, or if the current breakline unit is already the max unit, we return the max one (year)
   */
  public static getMajorBreaklineUnit(breakLineUnit: SpinTimeUnit): SpinTimeUnit {
    if (!milliSecondInPeriod[breakLineUnit]) {
      return 'year';
    }
    const periodUnits = Object.keys(milliSecondInPeriod) as SpinTimeUnit[];
    const periodUnitIndex = periodUnits.findIndex(d => d === breakLineUnit);
    if (periodUnitIndex < periodUnits.length - 1) {
      return periodUnits[periodUnitIndex + 1];
    }
    return 'year';
  }

  /**
   * Applies the datetime filter and tabFilter and returns number of items filtered out due to the date filter iteself
   */
  public static filteredOutDueToIntervalAndTab(filteringParams: ScheduleFilteringParams): {
    visibleVessels: VisibilityDict;
    layerData: FilteredScheduleItems;
  } {
    if (!filteringParams.interval || filteringParams.interval.length !== 2) {
      throw new ErrorWithFingerprint(
        `No interval has been defined in 'filteringParams', there might be an issue in your config.
         Schedule 'intervalFilter' in config only works with masterFilter (not with fieldsets).
      `,
        ['no-interval-defined-in-filtering-params', 'schedule-time-manager', 'filtered-out-due-to-interval-and-tab'],
      );
    }

    const todayTimestamp = dayjs().valueOf();

    const tabDict = {};
    filteringParams.tabs.forEach(tab => {
      /*
       * initLineCount is the count after initTabs
       * this count can be modified by excludeFiltered layer (e.g: retired vessels)
       * So each time we enter in filteredOutDueToIntervalAndTab we reinit the count to initLineCount
       * and we let the excludeFiltered removed from the count the vessel that should be hidden
       */
      tab.lines = tab.initLineCount;
      tabDict[tab.title] = tab;
    });

    const allLayerVisibleData: FilteredScheduleItems = {};
    const visibleVessels: VisibilityDict = {};

    /*
     * if we are in order intervalMode it means that a vessel which has its contracts filtered
     * by interval will be visible but it will be placed at the end of the group in small
     */
    const keepIntervalFilteredVessel = filteringParams.intervalMode === 'order';
    const layerCount = {};

    // layers with filtering excludeFiltered should be last because these layers override vessel visibility at end
    const orderedLayers = orderBy(
      filteringParams.allLayers,
      layer => layer.options.filtering === 'excludeFiltered',
      'asc',
    );

    for (const layer of orderedLayers) {
      const layerIdProperty = layer.options.idProperty;
      layerCount[layer.layerId] = 0;

      /*
       * Sampled layers are layers for which we know that there won't be any overlaps
       * and we will activate sampled filtering. We filter using the interval but we also look at occupied pixels.
       * If contract (or any schedule item) would end on a pixel
       * that is already occupied we will the contracts.
       * For these sampled layers we can apply this optimization on the filtering level
       * even before we go to determining overlaps and positions
       */
      if (layer.options.sampling) {
        const sampledFilteringResult = ScheduleTimeManager.sampledFiltering(layer, filteringParams);
        allLayerVisibleData[layer.layerId] = sampledFilteringResult;
        continue;
      }

      for (const scheduled of layer.data) {
        scheduled.layerId = layer.layerId;
        const propId = layerIdProperty ? layerIdProperty : filteringParams.settings.idProperty;

        let passIntervalFilter = true;
        const layerItemDateStart = scheduled.dateStart;
        let layerItemDateEnd = filteringParams.today;

        // dateEnd is defined
        if (scheduled.dateEnd != null) {
          layerItemDateEnd = scheduled.dateEnd;
        } else if (scheduled.dateStart >= todayTimestamp) {
          // Future events with no dateEnd
          layerItemDateEnd = scheduled.visualDateEnd || scheduled.dateStart;
        }

        /*
         * For a punctual contract we can't use the dateEnd as layerItemDateEnd
         * As this dateEnd will be calculated to display correctly the punctual contract title
         * If there isn't any contract after the punctual contract it will use all the available space
         * And the dateEnd would always been current interval range
         * So we use the dateStart as a dateEnd
         */
        if (scheduled.punctualShape) {
          layerItemDateEnd = scheduled.dateStart;
        }

        // Check if the item intersects with interval
        if (layerItemDateStart > filteringParams.interval[1] || layerItemDateEnd < filteringParams.interval[0]) {
          passIntervalFilter = false;
        }

        /*
         * If heavy layers are present in the schedule,
         * **propId** has to be the ID of the elements  (vessels, windfarms),
         * because it will be used and passed to the backend to restrict
         * the vessels for which the heavy layer should return WPs.
         * Some background:
         * visibleVessels dictionary stores the information about which vessels are visible due to filtering
         * specially hard layers can make the vessel hidden,
         * but the **heavy layer** endpoint does not have this information
         * So we use this dictionary to extract the list of visible items - and we need the IDs of these items.
         * that is why if there is a WP heavy layer, propId really needs to hold the ID and not a title for instance.
         */
        const lineValue = getChained<string>(scheduled, propId)
          || getChained(scheduled, filteringParams.settings.titleProperty);
        // Prop used to identify lineValue in tab
        const tabLineProp = filteringParams.settings.idProperty;
        const tabLineValue = getChained<NumOrString>(scheduled, tabLineProp) || lineValue;

        if (!getChained(visibleVessels, lineValue)) {
          visibleVessels[lineValue] = {
            layers: {},
            visible: false,
          };
        }
        if (!visibleVessels[lineValue].layers[scheduled.layerId]) {
          visibleVessels[lineValue].layers[scheduled.layerId] = {
            scheduled: [],
            vesselVisibleDueToLayer: false,
          };
        }
        /*
         * in general case if the contract is supposed to be shown we will show it
         * visibility determine by the sidebar
         */
        const prefilteredVisibility = filteringParams.filteredItemsBeforeBrush[layer.layerId][scheduled.uniqueId];

        /*
         * At this moment we check if the data can make the layer visible (and potentially the vessel visible)
         * A contract can make a layer visible if it's shown or if it filtered by interval but we're in order mode,
         * so would make the vessel visible in grey
         * Please note that at this time the filterTab has not yet been applied.
         * This is because to define the tab count we need to know the visibility of a boat no matter
         * if it is filtered by the tab or not.
         */
        const scheduledMakeLayerVisible = prefilteredVisibility && (passIntervalFilter || keepIntervalFilteredVessel);

        // if there are any hard layers then all of them have to agree to make the vessel visible
        if (filteringParams.activeHardLayers && filteringParams.activeHardLayers.indexOf(scheduled.layerId) > -1) {
          /*
           * per layer visibility indicator for all hard layers,
           * all hard layers say if the vessel should be visible due to them
           */
          visibleVessels[lineValue].layers[scheduled.layerId].vesselVisibleDueToLayer =
            visibleVessels[lineValue].layers[scheduled.layerId].vesselVisibleDueToLayer || scheduledMakeLayerVisible;
          // vessel is visible only if all hard layers make it visible - but only those hard layers that are active
          if (filteringParams.activeHardLayers.every(layerId => layerId in visibleVessels[lineValue].layers)) {
            visibleVessels[lineValue].visible = filteringParams.activeHardLayers.every(
              layerId => visibleVessels[lineValue].layers[layerId].vesselVisibleDueToLayer,
            );
          }
        } else if (!filteringParams.activeHardLayers || filteringParams.activeHardLayers.length === 0) {
          // if there is no hard filtering layer in the schedule then any contract makes the vessel visible
          visibleVessels[lineValue].visible = visibleVessels[lineValue].visible || scheduledMakeLayerVisible;
        }

        let excludeVesselFromTabCount = false;
        /*
         * mode ExcludeFiltered is defined for layer as retired vessel
         * In this this kind of layer if an element is filtered out it means we have to exclude the vessel
         * this behaviour is somekind different of hard layer because in hard layer we keep only vessel for which
         * we have data on active hard layers. So if retirement was a hard layer.
         * Only vessel with retirement date would be visible.
         */
        if (!passIntervalFilter && layer.options.filtering === 'excludeFiltered') {
          visibleVessels[lineValue].visible = false;
          excludeVesselFromTabCount = true;
        }

        let tabValue = filteringParams?.tabFilter?.tabProp
          ? getChained<string>(scheduled, filteringParams.tabFilter.tabProp)
          : null;
        if (tabValue === null || tabValue === undefined) {
          tabValue = NOT_AVAILABLE;
        }

        /*
         * Some layer are exclude filtered and remove visibility for the vessel
         * in this case we need to dicrease corresponding tab count
         */
        if (
          (!layer.options.ignoreSelectFilter && tabValue !== '__noFilterValue')
          && (visibleVessels[lineValue].visible || excludeVesselFromTabCount)
        ) {
          // get the tab for this tabValue
          const tab = getChained<ScheduleTab>(tabDict, tabValue);
          if (tab) {
            if (excludeVesselFromTabCount && tab.lineValues[tabLineValue] && tab.lineValues[tabLineValue].visible) {
              tab.lines--;
              const allTab = tabDict['All'];
              if (allTab) {
                allTab.lines--;
              }
            }
          }
        }

        /*
         * Now that we have finished taking the contract into account in the tab count,
         * we can apply the tab filter to it.
         */
        let passTabFilter;
        if (
          filteringParams.tabFilter?.tabValue === 'All'
          || layer.options.ignoreSelectFilter
          || tabValue === '__noFilterValue'
          || !filteringParams.tabFilter?.tabValue
        ) {
          passTabFilter = true;
        } else {
          passTabFilter = tabValue === filteringParams.tabFilter?.tabValue;
        }

        /*
         * when contract passes the interval filter, tabs filter and it should be visible
         * however if we are in keepINtervalFilteredVessel mode (that is we are in `order` mode) than we have to
         * pass show=true as well here because we need the contracts later in the pipelay (in setContractsInGroups)
         */
        scheduled.show = prefilteredVisibility && (passIntervalFilter || keepIntervalFilteredVessel) && passTabFilter;

        /*
         * intervalFiltered specifies just if a contract pases the interval and the sidebar
         * if intervalFiltered = false we can remove later in the pipeline the contracts
         * that have been passed to setContractsInGroups
         * just to initialize the group and vessel line.
         */
        scheduled.intervalFiltered = prefilteredVisibility && !passIntervalFilter;

        if (scheduled.show) {
          visibleVessels[lineValue].layers[scheduled.layerId].scheduled.push(scheduled);

          // If scheduled is shown and is in selected interval, we increase layer count
          if (!scheduled.intervalFiltered) {
            layerCount[scheduled.layerId]++;
          }
        }
      }
      const filteredLayer = {
        visibleItems: [],
        filteredCount: 0,
        layerId: layer.layerId,
      };
      allLayerVisibleData[layer.layerId] = filteredLayer;
    }

    /*
     * Now that we iterate trough all filtered data and we establish
     * for each layer a list of visible contracts
     */
    for (const vesselGroup in visibleVessels) {
      if (!visibleVessels[vesselGroup].visible) {
        continue;
      }
      for (const layerId in visibleVessels[vesselGroup].layers) {
        allLayerVisibleData[layerId].visibleItems.push(...visibleVessels[vesselGroup].layers[layerId].scheduled);
        allLayerVisibleData[layerId].filteredCount = layerCount[layerId];
      }
    }

    return {
      visibleVessels: visibleVessels,
      layerData: allLayerVisibleData,
    };
  }

  public static sampledFiltering(
    layer: ScheduleLayer,
    filteringParams: ScheduleFilteringParams,
  ): FilteredScheduleLayer {
    // real count of items in the layer
    let layerCount = 0;
    const visibleItems: ScheduleLike[] = [];
    let currentPixel: number = 0;

    /*
     * before we apply sampling (droping in case of occupied pixel)
     * we will group the scheduled items (usually vessel activities) by the shortItemTitle
     * shortItemTitle is the value that is stored to the left on the schedule - usually vessel name
     */
    const groupByVessel = groupBy(layer.data, d => getChained(d, filteringParams.settings.idProperty));
    for (const groupKey in groupByVessel) {
      /*
       * reinit currentPixel for each vessel
       * because there will be on different lines
       */
      currentPixel = 0;
      const groupItems = groupByVessel[groupKey];
      const ordered = orderBy(groupItems, d => d.dateStart);
      for (const scheduled of ordered) {
        // Check if element is not already filtered by tabs
        if (filteringParams.tabFilter) {
          if (
            filteringParams.tabFilter.tabValue === NOT_AVAILABLE
            && getChained(scheduled, filteringParams.tabFilter.tabProp)
          ) {
            continue;
          }
          if (
            filteringParams.tabFilter.tabValue !== 'All'
            && !(getChained(scheduled, filteringParams.tabFilter.tabProp) === filteringParams.tabFilter.tabValue)
          ) {
            continue;
          }
        }

        scheduled.sampledOut = false;

        // visibility determined by filtering of the sidebar
        const preFilterdVisibility = filteringParams.filteredItemsBeforeBrush[layer.layerId][scheduled.uniqueId];
        scheduled.show = preFilterdVisibility;
        const layerItemDateStart = scheduled.dateStart;
        let layerItemDateEnd = filteringParams.today;

        if (scheduled.dateEnd != null) {
          layerItemDateEnd = scheduled.dateEnd;
        }

        // if the item is really filtered due to the datetime filter we will hide it
        if (layerItemDateStart > filteringParams.interval[1] || layerItemDateEnd < filteringParams.interval[0]) {
          /*
           * if intervalFiltered equals true it means that the contract has been filtered by the interval
           * but wasn't filtered by sidebar filters
           * This will be used to grey (but not filter) vessels without visible contract in the selected interval window
           */
          scheduled.intervalFiltered = false;
          scheduled.show = false;
        }

        if (scheduled.show) {
          const pixelEnd = filteringParams.xScale(layerItemDateEnd);
          const pixel = Math.round(pixelEnd);
          /*
           * if the end of the contract would go to the same pixel already occopied by the previous contract
           * we will drop it
           */
          if (currentPixel == null || currentPixel < pixel) {
            scheduled.show = true;
            currentPixel = pixel;
            visibleItems.push(scheduled);
          } else {
            scheduled.show = false;
            scheduled.sampledOut = true;
          }
          // increment the real counter
          layerCount++;
        }
      }
    }

    const result: FilteredScheduleLayer = {
      visibleItems: visibleItems,
      filteredCount: layerCount,
      layerId: layer.layerId,
    };
    return result;
  }

  /**
   * Find first highlighted contract and get initial interval
   *
   * @return {Interval}  Initial interval in case highlighted interval else null
   */
  public static getIntervalFromHighlightedContract(layers: { [layerId: string]: ScheduleLayer }): Interval | null {
    // Find first layer with highlightedContractProp enabled
    const highlightedLayerId: string = Object.keys(layers).find(layerId => {
      return layers[layerId].options.highlightedContractProp;
    });
    if (!highlightedLayerId) {
      return null;
    }

    // Get highlighted contract
    const highlighted = layers[highlightedLayerId].data.find((d: Scheduled) => getChained(d, 'highlighted'));
    if (!highlighted) {
      return null;
    }

    // Set interval margins from contract extent (> 1 week & < 1 year)
    const day = 86400 * 1000;
    const margin = Math.min(365 * day, Math.max(7 * day, highlighted.dateEnd - highlighted.dateStart));

    // Return initial schedule interval
    return [highlighted.dateStart - margin, highlighted.dateEnd + margin];
  }
}
