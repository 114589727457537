import { Injectable, Injector } from '@angular/core';

import { clone, isEmpty } from 'lodash-es';
import { parse } from 'wkt';

import { DataAccessor } from './data-accessor';
import { DataLoader } from '../data-loader/data-loader';
import { EntitiesUpdateDto, EntityDefinition, EntityDeletedDto, EntityFieldDefinition, EntityOrder, EntityUpdateDto,
  Geometry, LinkBy, NumOrString, OptionValue, SomeEntity } from '../helpers/types';
import { DatabaseHelper } from './database-helper';
import { DataPoint, EntityMapping } from '../data-loader/data-loader.types';
import { Config } from '../config/config';

@Injectable({
  providedIn: 'root',
})
export class EntityDataAccessor extends DataAccessor {
  public override definition: EntityDefinition;
  public entityHasChanged = false;
  private dataLoader: DataLoader;
  private valueBeforeEdit: SomeEntity;
  private entityName: string;

  constructor(injector: Injector) {
    super();
    this.dataLoader = injector.get(DataLoader);
  }

  public override setDefinition(definition: EntityDefinition): void {
    super.setDefinition(definition);
    /*
     * when loading the definition we will create the new entity in the value field
     * this one might be later override by setValue (loading existing)
     * we are cloning the emptyEntity, the definition object might be reused multiple types (it is cached)
     * so if we would assign it to value ti would get modified
     */
    if (definition.emptyEntity) {
      this.value = Object.assign({}, definition.emptyEntity);
    } else {
      this.value = {};
    }
    this.entityName = definition.class;
  }

  /*
   * In some cases a field can be prefilled (*linkBy Intel for total eg). In that case the field
   * might be defined as not-editable. In that case the values won't be loaded and the title can't be determined
   * on the client side and has to be retrieved from server
   */

  public static fillEntityWithPrefillValues(value: SomeEntity, prefill: any): SomeEntity {
    if (!prefill) {
      return value;
    }
    if (value == null) {
      value = {};
    }
    for (const fieldId in prefill) {
      if (value[fieldId] == null) {
        value[fieldId] = prefill[fieldId];
      }
    }
    return value;
  }

  public static fillEntityWithParentPrefill(
    parentField: EntityFieldDefinition,
    parentEntity: SomeEntity,
    entity: SomeEntity,
  ): void {
    if (parentField.prefill) {
      for (const fieldId in parentField.prefill) {
        const parentFieldId = parentField.prefill[fieldId];
        if (entity[fieldId] == null) {
          entity[fieldId] = parentEntity[parentFieldId];
        }
      }
    }
  }

  public setEntity(entityName: string, value: SomeEntity, prefill?: unknown): void {
    /*
     * if there was an entity selected previously, undo it's editability
     * except if we reselect the same value
     */
    if (this.value && this.value.editable && this.value !== value) {
      this.value.editable = false;
    }

    this.entityName = entityName;

    /*
     * An entity can have a prefill, it means that if the loaded entity has
     * some null values we replace it by the default prefill values
     */
    if (prefill) {
      /*
       * If entity definition has emptyEntity defined (i.e. default values during entity creation)
       * and the entity value we're opening is empty we have to preserve these default values before apply prefill
       */
      const valueToPrefill = !value && this.definition.emptyEntity ? this.value : value;
      value = EntityDataAccessor.fillEntityWithPrefillValues(valueToPrefill, prefill);
    }

    /*
     * only if concrete value is passed then modify the current selected value
     * otherwise when creating new entity or duplicating the default entity value
     * or the clone created as duplicate could be override
     */
    // call parent.setData instead of block
    if (value) {
      this.value = value;
      this.valueBeforeEdit = clone(value);
    } else {
      this.valueBeforeEdit = null;
    }
  }

  public setCollection(field: EntityFieldDefinition, value: OptionValue[]): void {
    this.value[field.id] = value.map(item => item.id);
    this.value[DatabaseHelper.getReadableTitleFieldId(field.id)] = value.map(item => item.title).join(', ');
  }

  /**
   * Checks the validity of all fields and returns the error messages of invalid fields.
   *
   * @param fieldTitleOnly If true, returns invalid fields titles instead of invalid fields messages
   *
   * The parentField is used to not consider the absence of the entityId
   * of the parent entity as an error because when the parentEntity is being created
   * it doesn't have any id yet
   */
  public getFieldsErrors(mappedBy: string = null, fieldTitleOnly: boolean): string[] {
    const coherencyErrors: string[] = [];
    const allFields = this.getAllFields();

    for (const field of allFields) {
      const fieldValidity = this.fieldValidity(field, mappedBy);
      if (!fieldValidity.valid) {
        coherencyErrors.push(fieldTitleOnly ? field.title : fieldValidity.msg);
      }
    }
    return coherencyErrors;
  }

  public static async duplicate(
    entity: SomeEntity,
    definition: EntityDefinition,
    dataLoader: DataLoader,
    firstCall: boolean = true,
  ): Promise<EntityUpdateDto> {
    let message = '';
    if (firstCall) {
      message = `${definition.header} duplicated`;
    }
    const MTMmessagePart = [];
    const OTMmessagePart = [];
    const duplicated = Object.assign({}, entity);
    duplicated[definition.idField] = null;

    const fieldsToRemove = ['createdEmail', 'modifiedEmail', 'mtime', 'ctime', 'createdName', 'modifiedName'];
    for (const toRemove of fieldsToRemove) {
      delete duplicated[toRemove];
    }

    for (const field of definition.fields) {
      // by default all fields are duplicated except those that are explicitly set to be excluded
      if (field.duplicate === false) {
        delete duplicated[field.id];
      }

      if (field.type === 'collection') {
        if (field.collectionType === 'ManyToMany') {
          if (field.duplicateChildren === false) {
            duplicated[field.id] = [];
          } else if (entity[field.id] && (entity[field.id] as number[]).length > 0) {
            MTMmessagePart.push(field.id);
          }
        } else {
          duplicated[field.id] = [];
          if (field.duplicateChildren) {
            if (entity[field.id] && (entity[field.id] as number[]).length > 0) {
              const fieldDef = await dataLoader.getEntityDefinition(field.class, false);
              const toConstruct: SomeEntity[] = [];
              let messagePart = '';
              for (const id of entity[field.id] as number[]) {
                const value = await dataLoader.getEntity(field.class, { 'id': id });
                const duplicateValue = await EntityDataAccessor.duplicate(value, fieldDef, dataLoader, false);
                /*
                 * This is not the correct solution. It partially work but it should be replaced by
                 * with something that can composed correctly the final message
                 */
                if (duplicateValue.message.length > messagePart.length) {
                  messagePart = duplicateValue.message;
                }
                toConstruct.push(duplicateValue.entity);
              }
              duplicated[EntityDataAccessor.toConstructForId(field)] = toConstruct;
              OTMmessagePart.push(field.id + messagePart);
            }
          }
        }
      }
    }
    if (MTMmessagePart.length > 0 || OTMmessagePart.length > 0) {
      message += firstCall ? ' which is linked to ' : ', which are linked to ';
    }
    if (MTMmessagePart.length > 0) {
      message += `the same ${EntityDataAccessor.ArrayToEnglishString(MTMmessagePart)} as the duplicated element`
          + firstCall
        ? ''
        : 's';
    }
    if (MTMmessagePart.length > 0 && OTMmessagePart.length > 0) {
      message += ' and linked to ';
    }
    if (OTMmessagePart.length > 0) {
      message +=
        `duplicate of the ${EntityDataAccessor.ArrayToEnglishString(OTMmessagePart)} from the duplicated element`
          + firstCall
          ? ''
          : 's';
    }
    if ((MTMmessagePart.length > 0 || OTMmessagePart.length > 0) && !firstCall) {
      message += ',';
    }

    // once all duplication is done, go over all calculation rules and apply them
    if (definition.calc) {
      for (const calcField in definition.calc) {
        definition.calc[calcField](duplicated);
      }
    }

    return { success: true, message: message, entity: duplicated };
  }

  public static ArrayToEnglishString(arr: string[]): string {
    let outStr = '';
    if (arr.length === 1) {
      outStr = arr[0];
    } else if (arr.length === 2) {
      outStr = arr.join(' and ');
    } else if (arr.length > 2) {
      outStr = arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1)[0];
    }
    return outStr;
  }

  public setIdFromServer(id: string | number): void {
    this.value[this.definition.idField] = id;
  }

  public enterEditMode(): void {
    this.valueBeforeEdit = clone(this.value);
  }

  /**
   * Leave edit mode without saving changes
   */
  public leaveEditMode(): SomeEntity {
    if (!this.value || isEmpty(this.value)) {
      return;
    }

    this.value.editable = false;
    if (this.valueBeforeEdit) {
      this.valueBeforeEdit.editable = false;
    }
    this.value = clone(this.valueBeforeEdit);

    return this.value;
  }

  public override afterUpdate(fieldId: string | number): void {
    super.afterUpdate(fieldId);

    this.definition?.calc?.[fieldId]?.(this.value);
    const allFields = this.getAllFields();
    this.entityHasChanged = !this.compareEntities(this.value, this.valueBeforeEdit, allFields);
  }

  public override inputValueChanges(field: EntityFieldDefinition, value: string): void {
    super.inputValueChanges(field, value);
    const allFields = this.getAllFields();
    this.entityHasChanged = !this.compareEntities(this.value, this.valueBeforeEdit, allFields);
  }

  private compareEntities(valueOne: any, valueTwo: any, fields: Set<EntityFieldDefinition>): boolean {
    if (valueOne && valueTwo) {
      for (const field of fields) {
        if (valueOne[field.id] !== valueTwo[field.id] && field.type !== 'collection') {
          return false;
        }

        if (field.type === 'collection') {
          if (valueOne[field.id] == null || valueTwo[field.id] == null) {
            if (valueOne[field.id] !== valueTwo[field.id]) {
              return false;
            } else {
              continue;
            }
          }

          const valueListOne = valueOne[field.id] as number[];
          valueListOne.sort();

          const valueListTwo = valueTwo[field.id] as number[];
          valueListTwo.sort();

          if (valueListOne.length !== valueListTwo.length) {
            return false;
          }
          for (let i = 0; i < valueListOne.length; i++) {
            if (valueListOne[i] !== valueListTwo[i]) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  public async linkEntity(linkBy: LinkBy): Promise<void> {
    if (linkBy.id && linkBy.fieldName) {
      this.value[linkBy.fieldName] = linkBy.id;

      // find the field on the definition
      const field = this.definition.fields.find(f => f.id === linkBy.fieldName);
      if (!field) {
        console
          .warn(`Cannot linked ${linkBy.fieldName} to ${this.definition.class}. This field name isn't in field list`);
        return;
      }
      // we prevent the field corresponding to the link to be edited
      field.editable = false;

      // get the whole linked entity
      const linkedEntity = await this.dataLoader.getEntity(field.class, { id: linkBy.id });

      const linkedEntityDefinition = await this.dataLoader.getEntityDefinition(field.class, false);

      const title = DatabaseHelper.getTitleForEntityAndDefinition(linkedEntity, linkedEntityDefinition);

      // artificially create a single item in the value list
      if (title && field && !field.values) {
        field.values = [{ id: this.value[field.id], title: title }];
      }
    }
  }

  public getEntityTitle(): string {
    return DatabaseHelper.getTitleForEntityAndDefinition(this.value, this.definition);
  }

  public isNew(value = this.value, definition = this.definition): boolean {
    /*
     * When there is no entity or definition it's because they are not load yet.
     * We prefer return true in this situation because isNew returning true add some restriction
     * To the view. We prefer having button appearing at the end of loading than a button disappearing
     * when the loading end
     */
    if (!value || !definition) {
      return true;
    }
    return value[this.definition.idField] == null;
  }

  /**
   * Delete either the actual selected entity or one passed as parameter
   */
  public async deleteEntity(value: SomeEntity = null): Promise<EntityDeletedDto> {
    if (value) {
      this.value = value;
    }
    return this.dataLoader
      .post(this.dataLoader.deleteUrl(this.entityName), this.value)
      .then((result: EntityDeletedDto) => {
        return result;
      })
      .catch(err => {
        return {
          success: false,
          message: err,
        } as EntityDeletedDto;
      });
  }

  /**
   * Post Request to update orders of entities using a list of EntityOrder.
   *  - EntityOrder: a pair of (id, newOrder)
   *    - id: entity's id to update
   *    - newOrder: new order value for entity
   * @param entityName  The name of the entity table
   * @param orders Array of EntityOrder
   * @returns Result of post
   */
  public async saveOrdersFor(entityName: string, orders: EntityOrder[]): Promise<EntitiesUpdateDto> {
    return this.dataLoader
      .post(this.dataLoader.updateOrderUrl(entityName), orders)
      .then((result: EntitiesUpdateDto) => {
        return result;
      })
      .catch(err => {
        const errorResult: EntitiesUpdateDto = {
          success: false,
          message: err,
        };
        return errorResult;
      });
  }

  /**
   * Saves the whole entity.
   */
  public async saveEntity(): Promise<EntityUpdateDto> {
    const dto = clone(this.value);
    // remove all moment fields before sending to the server
    for (const property in dto) {
      if (property.indexOf('__dayjs') !== -1) {
        delete dto[property];
      }
    }
    const result = await this.dataLoader.post<SomeEntity, EntityUpdateDto>(
      this.dataLoader.updateUrl(this.entityName),
      dto,
    ).catch((err: string): EntityUpdateDto => {
      return {
        success: false,
        message: err,
      };
    });

    if (!result.success) {
      return result;
    }

    // update the id of the entity that has been saved - TODO: could be removed as already handled by afterEntityUpdate?
    this.setIdFromServer(result.id);
    this.afterEntityUpdate(result.entity);

    return result;
  }

  /**
   * Get the fields that have been updated due to the given field's new value
   */
  private getUpdatedCalcFields(field: EntityFieldDefinition): Set<EntityFieldDefinition> {
    const calcFields = new Set<EntityFieldDefinition>();

    // Return early if there is no calc defined for this field
    if (!this.definition?.calc?.[field.id]) {
      return calcFields;
    }

    // Retrieve the value before any changes were applied
    const tempValue = { ...this.valueBeforeEdit };

    // Apply the new field value on the temp value
    tempValue[field.id] = this.value[field.id] as unknown;

    // Save temp value before calc is applied
    const valueBeforeCalc = { ...tempValue };

    // Apply calc on temp value
    this.definition.calc[field.id](tempValue);

    // Compare before and after calc.
    this.getAllFields().forEach(calcFieldCandidate => {
      if (valueBeforeCalc[calcFieldCandidate.id] !== tempValue[calcFieldCandidate.id]) {
        calcFields.add(calcFieldCandidate);
      }
    });

    return calcFields;
  }

  /**
   * Saves only this entity's given field and its related calc fields.
   */
  public async saveEntityField(field: EntityFieldDefinition): Promise<EntityUpdateDto> {
    if (!this.getAllFields().has(field)) {
      throw new Error(`Cannot find '${field.id}' field in all fields`);
    }

    const fieldValue: unknown = this.value[field.id];
    const calcFields: Record<string, unknown> = {};
    this.getUpdatedCalcFields(field).forEach(calcField => {
      calcFields[calcField.id] = this.value[calcField.id];
    });

    // Build payload with id, field and updated calc fields
    const payload = {
      id: this.value.id,
      [field.id]: fieldValue,
      ...calcFields,
    };

    const result = await this.dataLoader.post<typeof payload, EntityUpdateDto>(
      this.dataLoader.updateUrl(this.entityName),
      payload,
    ).catch((err: string): EntityUpdateDto => {
      return { success: false, message: err };
    });

    if (!result.success) {
      return result;
    }

    this.afterEntityUpdate(result.entity);

    return result;
  }

  /**
   * Method called after the entity has been updated (via saveEntity or saveEntityField).
   * Syncs the entity and backup entity kept in the state with the values of the updated entity, and cleans up
   * temporary fields.
   */
  private afterEntityUpdate(updatedEntity: SomeEntity): void {
    // Sync the entity value
    EntityDataAccessor.updateEntityData(this.value, updatedEntity);

    // Remove all "__to_construct_for_"
    for (const property in this.value) {
      if (property.indexOf('__to_construct_for_') !== -1) {
        delete this.value[property];
      }
    }

    // Sync the backup value
    this.valueBeforeEdit = clone(this.value);
  }

  private getAllFields(): Set<EntityFieldDefinition> {
    return new Set([...this.definition.fields, ...(this.definition.freeStyleFields ?? [])]);
  }

  /**
   * Go over all fields in entity (data-set) and update the values. Remove every potentially existing
   * formatted_* or *__dayjs fields, because these are potentially cached old values
   */
  public static updateEntityData(target: SomeEntity, data: SomeEntity): void {
    /** Clear formatted props - some keys are built using chaining, e.g. 'formatted_vessel.title' */
    Object.keys(target).forEach(key => {
      if (key.startsWith('formatted_')) {
        delete target[key];
      }

      if (key.endsWith('__dayjs')) {
        delete target[key];
      }
    });

    Object.keys(data).forEach(key => {
      if (key !== 'editable') {
        target[key] = data[key];
      }
    });
  }

  public static findById(data: SomeEntity[], definition: EntityDefinition, id: any): SomeEntity {
    let idField = definition.idField;
    if (!definition.idField) {
      console
        .warn('idField not specified for table and we have to find an item (after-update probably), we assume: id');
      idField = 'id';
    }
    return data.find(d => d[idField] === id);
  }

  public isGroupModified(groupName: string): boolean {
    const advancedFields = this.definition.fields.filter(f => f.groups && f.groups.indexOf(groupName) !== -1);
    for (const field of advancedFields) {
      if (this.value[field.id] && (!Array.isArray(this.value[field.id]) || this.value[field.id].length)) {
        return true;
      }
    }
    return false;
  }

  public deleteLinkedEntity(fieldId: string, entityId: NumOrString): void {
    if (!fieldId || !entityId) {
      return;
    }
    this.value[fieldId].splice(this.value[fieldId].indexOf(entityId));
  }

  public addLinkedEntity(fieldId: string, entityId: number): void {
    if (!this.value[fieldId]) {
      this.value[fieldId] = [];
    }
    if (this.value[fieldId].indexOf(entityId) === -1) {
      this.value[fieldId].push(entityId);
    }
  }

  public entityLoaded(): boolean {
    return this.value != null;
  }

  public getLinkedEntityId(field: EntityFieldDefinition): number {
    if (!this.value) {
      return 0;
    }
    return this.value[field.id];
  }

  /**
   * Perform basic checks on WKT string. We ensure the geometry type is allowed and that coordinates
   * are in our representation boundaries [-180, 180]x[-90, 90]
   *
   * We actually check in the back-end that the geometry is valid.
   * We have several issue about checking this in front-end:
   *
   *  1- WKT parsing function is too permissive, for ex: "LINESTRING (1 1, 2 2, 3 2) Blabll 1223" will be wrongly parse
   *  as "LINESTRING (1 1, 2 2, 3 2)""
   *
   *  2- We need a support to validate the geometry topology: Rings must be closed, interior rings of a polygon must be
   *  declared anti-clockwise, ...
   *  Unfortunately, plugin @turf/boolean-valid is not correctly implemented and can't be used for this.
   *
   * This function is used by the entities coherency checks
   * @param geometry Input WKT string
   * @returns Whether the WKT string is valid
   */
  private isValidWkt(geometry: string): boolean {
    const geometryRegex = /LINESTRING|MULTILINESTRING|POLYGON|MULTIPOLYGON/gi;
    const matches = geometry.match(geometryRegex);
    if (!matches) {
      this.errorMessageDetails += ' - Accepted geometries are LINESTRING, MULTILINESTRING, POLYGON, MULTIPOLYGON';
      return false;
    }
    if (matches.length !== 1) {
      this.errorMessageDetails +=
        ' - Geometry field must contain only one geometry in LINESTRING, MULTILINESTRING, POLYGON, MULTIPOLYGON';
      return false;
    }

    // parse function return null if it can't parse the given string
    const parsedGeometry = parse(geometry) as Geometry;
    if (parsedGeometry == null) {
      this.errorMessageDetails += ' - Invalid WKT syntax';
      return false;
    }

    // Ensure all longitude / latitude are in acceptable range [-180, 180] and [-90, 90]
    let coordinates = parsedGeometry.coordinates as any;
    switch (parsedGeometry.type) {
      case 'LineString':
      case 'Polygon':
      case 'MultiLineString':
        coordinates = [coordinates];
        break;
      case 'MultiPolygon':
        for (const polygon of coordinates) {
          if (!Array.isArray(polygon)) {
            this.errorMessageDetails += ' - Invalid WKT syntax. Wrong number of parentheses.';
            return false;
          }
          for (const path of polygon) {
            if (!Array.isArray(path)) {
              this.errorMessageDetails += ' - Invalid WKT syntax. Wrong number of parentheses.';
              return false;
            }
            for (const point of path) {
              if (!Array.isArray(point)) {
                this.errorMessageDetails += ' - Invalid WKT syntax. Wrong number of parentheses.';
                return false;
              }
              if (Math.abs(point[0]) > 180) {
                this.errorMessageDetails += ' - Longitude must be in [-180°, 180°]';
                return false;
              }
              if (Math.abs(point[1]) > 90) {
                this.errorMessageDetails += ' - Latitude must be in [-90°, 90°]';
                return false;
              }
            }
          }
        }
        break;
      default:
    }

    return true;
  }

  /**
   * A given `field` is light editable if all the following conditions are met:
   * - light edit is not disabled in the `field` config
   * - the `item` has a `__propertyToEntityMappings.[field.Id]` property
   * - the item has a value for the entity id property given by the mapping
   * - current user has edit rights on the target entity
   */
  public static isLightEditable(config: Config, field: EntityFieldDefinition, item: DataPoint): boolean {
    if (field.disableLightEdit) {
      // Light edit disabled in field config
      return false;
    }

    const mapping: EntityMapping = item.__propertyToEntityMappings?.[field.id];
    if (!mapping || !item[mapping.idPropertyName]) {
      // No mapping or missing info in mapping
      return false;
    }

    const entityInformation = config.findAvailableEntity(mapping.entityName);
    if (!entityInformation || !DatabaseHelper.hasEditRight(entityInformation)) {
      return false;
    }

    return true;
  }
}
