import { ChangeDetectorRef, Component, Inject, NgZone, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgFor } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export interface DialogData {
  message: string;
  taskId: any;
}

@Component({
  selector: 'spin-loading',
  templateUrl: 'loading.html',
  styleUrls: ['loading.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatProgressSpinnerModule, NgFor],
})
export class LoadingComponent {
  private static inst: MatDialogRef<LoadingComponent>;
  private tasks: { [taskId: string]: string } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: DialogData,
    public cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {
    this.tasks[dialogData.taskId] = dialogData.message;
  }

  public get taskList() {
    return Object.values(this.tasks);
  }

  static push(dialog: MatDialog, taskId: string, msg: string = '', disabledNavBar: boolean = false) {
    if (!LoadingComponent.inst) {
      const inst = dialog.open(LoadingComponent, {
        disableClose: true,
        data: {
          message: msg,
          taskId: taskId,
        },
        backdropClass: disabledNavBar ? 'loading-full-screen' : '',
      });
      LoadingComponent.inst = inst;
      return;
    }

    /*
     * Unfortunately it can happen that dialog.open runs,
     * *inst* is created but componentInstance is still null (angular probably asynchrounously creating it)
     * If there is a second task to report as loading the componentInstance could still be null
     * in such case we prefer not to report
     */
    if (LoadingComponent.inst?.componentInstance == null) {
      return;
    }

    LoadingComponent.inst.componentInstance.ngZone.run(() => {
      LoadingComponent.inst.componentInstance.tasks[taskId] = msg;
    });
  }

  static pull(taskId: string) {
    const inst = LoadingComponent.inst;

    /*
     * it can happen that the pull get's called too early, very quick endpoint for instance
     * if it is the case we prefer to close the modal directly
     */
    if (inst?.componentInstance == null) {
      inst?.close();
      delete LoadingComponent.inst;
      return;
    }

    // remove the task from the list
    delete inst.componentInstance.tasks[taskId];

    // if there are still some tasks to show as loading we do not need to do anything else
    if (Object.keys(inst.componentInstance.tasks).length) {
      return;
    }

    /*
     * if this was the last task (we passed previous if), we have to close the dialog
     * and remove the instance
     */
    inst.componentInstance.ngZone.run(() => {
      inst?.close();
    });
    inst.componentInstance.cdRef.detectChanges();
    delete LoadingComponent.inst;
  }
}
