import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { JsonPipe, NgFor, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { NavigationHelper } from '../helpers/navigation-helper';
import { ActionEvent, Button, LinkButton } from '../helpers/types';
import { PearlIconComponent } from './pearl-components';

export interface DialogConfig {
  buttons: Button[];
  title: string;
  text: string;
  json?: object;
  datum?: any;
  onAction?: (event: ActionEvent) => void;
}

@Component({
  selector: 'spin-dialog',
  templateUrl: 'dialog.html',
  styleUrls: ['dialog.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatIconModule,
    NgFor,
    MatButtonModule,
    NgIf,
    JsonPipe,
    PearlIconComponent,
  ],
})
export class DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public config: DialogConfig,
    public dialogRef: MatDialogRef<DialogComponent>,
  ) {}

  public getButtonLink(button: Button): string {
    return NavigationHelper.getNavigateLink(button as LinkButton, this.config.datum);
  }

  public onclick(event: MouseEvent, button: Button): void {
    event.preventDefault();
    // Do no try to create an event for fake buttons (like 'OK' to close a dialog)
    if (!this.config.onAction) {
      return;
    }

    const buttonEvent = NavigationHelper.constructActionEvent({
      event,
      action: button,
      values: this.config.datum,
    });

    this.config.onAction(buttonEvent);
  }
}
