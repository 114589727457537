import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';

import { FieldComponent } from './field';
import { SpinFiltersCheckboxComponent } from './spin-checkbox/spin-filters-checkbox.component';
import { IntervalComponent } from './interval';
import { DoubleDateComponent } from './doubledate';
import { NumberComponent } from './number';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { UpperFirstLetterPipe } from '../helpers/pipes';
import { FieldsetComponent } from './fieldset';
import { FilterApplied, MasterFieldset } from '../helpers/types';

@Component({
  selector: 'master-fieldset',
  templateUrl: './master-fieldset.component.html',
  styleUrls: ['./fieldset.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    MatButtonModule,
    NgClass,
    NgStyle,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    IntervalComponent,
    NumberComponent,
    DoubleDateComponent,
    SpinFiltersCheckboxComponent,
    FieldComponent,
    SpinTooltipDirective,
    UpperFirstLetterPipe,
  ],
})
export class MasterFieldsetComponent extends FieldsetComponent {
  @Input()
  override fieldset: MasterFieldset;

  public override onbubblingchange(filter: FilterApplied): void {
    filter.masterFilter = true;
    super.onbubblingchange(filter);
  }
}
