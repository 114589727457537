import { ChangeDetectorRef, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { FieldComponent } from './field';
import { SpinFiltersCheckboxComponent } from './spin-checkbox/spin-filters-checkbox.component';
import { IntervalComponent } from './interval';
import { DoubleDateComponent } from './doubledate';
import { NumberComponent } from './number';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { UpperFirstLetterPipe } from '../helpers/pipes';
import { FieldsetComponent } from './fieldset';
import { ActionEvent, LayerFilter, SidebarFieldset } from '../helpers/types';
import { FilterHelper } from './filter-helper';
import { SelectorHelper } from '../selector/selector.helper';
import { SelectorConfig, SelectorState } from '../selector/selector.types';
import { DialogManager } from '../database/dialog-manager';
import { RawDataPoint } from '../graph/chart-types';
import { PearlButtonComponent, PearlIconComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-filters-fieldset',
  templateUrl: './sidebar-fieldset.component.html',
  styleUrls: ['./fieldset.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    PearlButtonComponent,
    MatIconModule,
    NgClass,
    NgStyle,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    IntervalComponent,
    NumberComponent,
    DoubleDateComponent,
    PearlButtonComponent,
    PearlIconComponent,
    SpinFiltersCheckboxComponent,
    FieldComponent,
    SpinTooltipDirective,
    UpperFirstLetterPipe,
  ],
})
export class SidebarFieldsetComponent extends FieldsetComponent implements OnInit {
  @Input()
  override fieldset: SidebarFieldset;
  @Input()
  public onaction: (event: ActionEvent) => void;

  public dialogManager: DialogManager;

  public numberAdvancedFilterApplied: number;
  public selectorButtonLabel: string;

  constructor(injector: Injector, cdRef: ChangeDetectorRef) {
    super(injector, cdRef);
    this.dialogManager = injector.get(DialogManager);
  }

  ngOnInit(): void {
    if (this.selector) {
      FilterHelper.initializeFieldsetTypeAndProp(this.selector.fieldsets);
      this.selectorButtonLabel = SelectorHelper.getSelectorButtonLabel(this.selector);
    }
  }

  public get selector(): SelectorConfig {
    return this.fieldset.selector;
  }

  public openSelector(): void {
    const selectorState: SelectorState = {
      ...this.selector,
      // Note: all fields in given current fieldset have to relate to selector
      afterFilterAction: filters => this.clearAndSet(filters),
      appliedFilters: this.appliedFilters,
      entityIdsFromData: SelectorHelper.getEntityIdsFromSidebarPopulateValues(this.selector, [this.fieldset]),
      onAction: this.onaction,
    };
    this.dialogManager.openSelectorDialog(selectorState);
  }

  /* Populate filters using super method, and also store populating data that would be used to populate selector. */
  public override populate(
    data: RawDataPoint[],
    keepExisting: boolean,
  ): void {
    super.populate(data, keepExisting);
  }

  /**
   * Set the filters on a fieldset from the outside
   */
  public override set(filters: LayerFilter, fire: boolean = true): void {
    super.set(filters, false);

    if (this.selector) {
      const { advancedFilters } = SelectorHelper.splitSelectorFilters(
        filters,
        [this.fieldset],
        this.selector.fieldsets,
      );
      this.numberAdvancedFilterApplied = SelectorHelper.getNumberAdvancedFilterApplied(advancedFilters);
      this.appliedFilters = { ...advancedFilters, ...this.appliedFilters };
    }

    if (fire) {
      this.onchange.emit(null);
    }
  }

  public override reset(fire: boolean): void {
    this.numberAdvancedFilterApplied = 0;
    super.reset(fire);
  }

  public openConfig(config: object): void {
    this.dialogManager.simpleDialog('Config', '', config);
  }
}
