import { RefDataProvider } from '../data-loader/ref-data-provider';
import { PageConfig } from './config-types';

/** helper to manipulate pageLink url and parameters */
export class PageLinkHelper {
  private baseUrl: string;
  public idParamKey: string;

  public constructor(baseUrl: string, idParamKey: string) {
    this.baseUrl = baseUrl;
    this.idParamKey = idParamKey;
  }

  /** constructor to use when a pageLink has been specified in config */
  public static fromConfigPageLink(pageLinkConfig: string, availablePages: AvailablePages): PageLinkHelper {
    const { pageId, idParamKey } = PageLinkHelper.parsePageLinkFromConfig(pageLinkConfig);

    const pageUrl = availablePages?.findBaseUrl(pageId);
    return pageUrl ? new PageLinkHelper(pageUrl, idParamKey) : null;
  }

  /** accept both a form with "pageId:idParamKey" or "pageId", in this case, idParamKey is inferred out of pageId */
  private static parsePageLinkFromConfig(pageLink: string): { pageId: string; idParamKey: string } {
    const splitted = pageLink.split(':');
    const pageId = splitted[0];
    const idParamKey = splitted[1] ?? `${pageId}Id`;
    return { pageId, idParamKey };
  }

  /**
   * Helper function in the case of trying to infer page name from a property (string).
   * Check if key is a dot access notation, and if it can reference one of our pages, i.e accessing the property
   * "vessel.title" means that we want to link to vessel page, but not for "vessel.mainPurpose", since we don't have
   * a "purpose" dashboard page
   */
  private static datasetsToPotentialPageName(key: string): string | null {
    const datasets = RefDataProvider.getChainedDatasets(key, false);
    if (!datasets.length) return null;
    const [lastAccessedDataset, accessedKey] = datasets.slice(datasets.length - 2);
    /**
     * If chained dataset, get last referenced, if we access a simple property like title or id.
     * We don't want to have a link in the case of "vessel.mainPurpose" for instance
     */
    if (accessedKey === 'title' || accessedKey.slice(-2).toLowerCase() === 'id') {
      return lastAccessedDataset;
    }
    return null;
  }

  /** constructor to use when no config has been given */
  public static inferFromKey(key: string, availablePages: AvailablePages): PageLinkHelper {
    if (!key) return null;
    const potentialPageName = PageLinkHelper.datasetsToPotentialPageName(key) ?? key;
    const pageUrl = availablePages?.findBaseUrl(potentialPageName);
    const idParamKey = `${potentialPageName}Id`;
    return pageUrl ? new PageLinkHelper(pageUrl, idParamKey) : null;
  }

  /** @var paramBag a dict of values */
  public computeUrlFromDict(paramBag: unknown): string {
    const id = paramBag?.[this.idParamKey];
    return this.computeUrlFromId(id);
  }

  public computeUrlFromId(id: string | number): string {
    if (id === undefined || String(id).startsWith('_')) return null;
    return this.baseUrl + id;
  }
}

export class AvailablePages {
  constructor(pages: PageConfig[]) {
    this.pages = pages;
  }

  private pages: PageConfig[];

  public findBaseUrl(pageId: string): string {
    const pageConfig = this.findConfig(pageId);
    return pageConfig ? `/dashboard/page/${pageConfig.id}?${pageConfig.idField}=` : null;
  }

  public findConfig(pageId: string): PageConfig {
    return this.pages.find(page => page.id === pageId || page.aliases?.includes(pageId));
  }
}
