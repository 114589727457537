import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Host, Injector, NgZone,
  Output } from '@angular/core';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';

import tinycolor from 'tinycolor2';

import { TimezoneService } from '../helpers/timezone.service';
import { ChartTooltipSettings, Coords, SeriesSplit, TooltipSeries } from '../helpers/types';
import { PositionedTooltip } from './tooltip';

type cssPropDict = { [cssProp: string]: string };

interface TooltipLine {
  title: string;
  rowClass: string;
  pageUrl?: string;
  value?: string;
  valuePageUrl?: string;
  errors?: string;
  spinergieValue?: string;
  entryStyle?: cssPropDict;
}

@Component({
  selector: 'chart-tooltip',
  templateUrl: 'chart-tooltip.html',
  styleUrls: ['chart-tooltip.css'],
  providers: [{ provide: TimezoneService }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgIf,
    NgStyle,
  ],
})
export class ChartTooltipComponent extends PositionedTooltip {
  public settings: ChartTooltipSettings;
  public tooltipLines: TooltipLine[];
  public nbSplitPerSeries: { [seriesTitle: string]: number } = {};

  @Output()
  public navigate = new EventEmitter<any>();
  @Host()
  public timezoneService: TimezoneService;

  constructor(elRef: ElementRef, cdRef: ChangeDetectorRef, protected ngZone: NgZone, injector: Injector) {
    super(elRef, cdRef);
    this.timezoneService = injector.get(TimezoneService);
    this.timezoneService.name = this.constructor.name;
    this.timezoneService.timezoneConfig = { timezone: 'local' };
  }

  /**
   * Tooltip legend
   * Taken from X value of first series
   * TODO: should reuse DateHelper.formatDatetime... which takes timestamps, not formatted date
   */
  public commonXValue(series: TooltipSeries[]): string {
    const timezone = this.timezoneService.timezone;
    const label = series[0]?.xValue;

    // No timezone or default ones: keep as-is
    if (!timezone || timezone === 'local' || timezone === 'none' || timezone === 'dprDay') {
      return label;
    }

    // Regular strings, categories or numbers: keep as-is
    if (!label || !label.toString().match(/\d:\d{2}$/)) {
      return label;
    }

    // Add timezone title after time: `HH:mm Timezone/Name`
    return `${label} (${this.timezoneService.title})`;
  }

  public singleSplitInSeries(tooltipSeries: TooltipSeries): boolean {
    // Single split if only one series active
    if (tooltipSeries.title in this.nbSplitPerSeries) {
      return this.nbSplitPerSeries[tooltipSeries.title] === 1;
    } else {
      // Only consider number of splits in the current point
      return tooltipSeries.splits?.length === 1;
    }
  }

  public isSeriesNameDifferentFromSplitTitle(tooltipSeries: TooltipSeries): boolean {
    return tooltipSeries.name && tooltipSeries.name !== tooltipSeries.splits[0].title;
  }

  public seriesTitle(tooltipSeries: TooltipSeries): string {
    if (this.singleSplitInSeries(tooltipSeries) && !this.isSeriesNameDifferentFromSplitTitle(tooltipSeries)) {
      return tooltipSeries.splits[0].title;
    }
    return tooltipSeries.name;
  }

  public getTotalError(series: TooltipSeries): string {
    return `(+${series.errorMaxs['__total']}/-${series.errorMins['__total']})`;
  }

  public getSeriesSplitError(series: TooltipSeries, split: SeriesSplit): string {
    return `(+${series.errorMaxs[split.title]}/-${series.errorMins[split.title]})`;
  }

  public show(tooltipSettings: ChartTooltipSettings, _, coords: Coords, autoHideAfter?: number): void {
    this.resetTimeout();
    this.settings = tooltipSettings;
    const series = tooltipSettings.series.map(seriesConfig => {
      return {
        ...seriesConfig,
        showTotal: Boolean(!seriesConfig.hideTotal && seriesConfig.total !== null && seriesConfig.total !== undefined),
      };
    });
    this.computeFinalLayout(series);
    this.showTooltipAtPosition(coords);

    this.cdRef.detectChanges();
    if (autoHideAfter) {
      this.ngZone.runOutsideAngular(() => setTimeout(() => this.hide(), autoHideAfter));
    }
  }

  public getTitleColSpan(line): number {
    let colspan = 3;
    if (line.entryStyle) --colspan;
    if (line.value) --colspan;
    return colspan;
  }

  /*
   * Computes the final layout of the tooltip
   * See doc on Notion or read this function for understanding the behavior
   * FIXME: this is overlapping a lot with `createTooltipSeries` (in nvgraph and overrides)
   *        to be merged: this routine should only "format", not "update" TooltipSeries properties
   */
  private computeFinalLayout(allSeries: TooltipSeries[]): void {
    const lines = [];
    if (!allSeries.length) {
      this.tooltipLines = [];
      return;
    }
    const singleSeries = allSeries.length === 1;
    const firstLine: TooltipLine = { title: this.commonXValue(allSeries), rowClass: 'header' };

    /*
     * If only one series, show total / errors on same line as X value
     * and use title & xPageUrl if defined
     */
    if (singleSeries) {
      const firstSeries = allSeries[0];
      // Use first series custom page URL
      if (firstSeries.xPageUrl) firstLine.pageUrl = firstSeries.xPageUrl;
      // Optional total & errors
      if (firstSeries.showTotal) firstLine.value = firstSeries.total;
      if (firstSeries.showErrors) firstLine.errors = this.getTotalError(firstSeries);
    }
    lines.push(firstLine);
    allSeries.forEach(series => {
      const seriesTitle = this.seriesTitle(series);
      const isSingleSplit = this.singleSplitInSeries(series);
      /*
       * nominal case: series with title and multiple split: add series title with optional total and error
       * in the case of a single series, the title is implicit and not displayed
       */
      if (!singleSeries && !isSingleSplit && seriesTitle) {
        const titleLine: TooltipLine = { title: seriesTitle, rowClass: 'series-header' };
        if (series.showTotal) titleLine.value = series.total;
        if (series.showErrors) titleLine.errors = this.getTotalError(series);
        lines.push(titleLine);
      }
      series.splits.forEach(split => {
        const line: TooltipLine = {
          title: isSingleSplit ? this.seriesTitle(series) : split.title,
          value: split.value,
          rowClass: isSingleSplit ? 'single-split' : 'split',
        };
        if (split.valuePageUrl) line.valuePageUrl = split.valuePageUrl;
        if (split.pageUrl) line.pageUrl = split.pageUrl;
        if (split.spinergieValue) line.spinergieValue = `(Spinergie value: ${split.spinergieValue})`;
        if (series.showErrors) line.errors = this.getSeriesSplitError(series, split);
        if (!series.hideColorCircle) line.entryStyle = this.splitColorIconStyle(series, split);
        if (line.value !== '' && line.value != null) {
          lines.push(line);
        }
        if (split.comment) {
          const commentLine: TooltipLine = { title: split.comment, rowClass: 'comment' };
          // have a cell before to not mess up the layout
          if (!series.hideColorCircle) commentLine.entryStyle = { height: '4px', width: '20px' };
          lines.push(commentLine);
        }
      });
    });
    this.tooltipLines = lines;
  }

  /**
   * For a given series and split, returns the style to be applied on the div,
   * which represent the color in tooltip
   * It will return either a line (flat div) or rectangle of a color
   * color comes from split or from series (in case of single split)
   *
   * @param  TooltipSeries series  Tooltip series
   * @param  SeriesSplit   split   Split option
   * @return object                CSS style as an object
   */
  public splitColorIconStyle(series: TooltipSeries, split?: SeriesSplit): cssPropDict {
    const color = split ? split.color : series.splits[0].color;
    if (series.type === 'line') {
      return {
        height: '4px',
        width: '20px',
        background: color,
        'border-radius': '2px',
      };
    }

    if (series.type === 'scatter') {
      return {
        height: '10px',
        width: '10px',
        background: color,
        'border-width': color ? '1px' : '0',
        'border-style': color ? 'solid' : 'none',
        'border-color': color ? tinycolor(color).setAlpha(1).toString() : '',
        'border-radius': '50%',
      };
    }

    return {
      height: '15px',
      width: '15px',
      background: color,
    };
  }
}
