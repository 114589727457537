import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { DoubleDateComponent } from '../filters/doubledate';
import { FieldComponent } from '../filters/field';
import { FieldsetComponent } from '../filters/fieldset';
import { IntervalComponent } from '../filters/interval';
import { SpinFiltersCheckboxComponent } from '../filters/spin-checkbox/spin-filters-checkbox.component';
import { DistanceToEntityComponent } from '../filters/distance-to-entity/distance-to-entity.component';
import { PearlIconComponent } from '../shared/pearl-components';

@Component({
  selector: 'selector-fieldset',
  templateUrl: 'selector-fieldset.component.html',
  styleUrls: ['../filters/fieldset.scss', 'selector-fieldset.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTooltipModule,
    MatIconModule,
    NgClass,
    NgIf,
    NgStyle,
    NgFor,
    NgSwitch,
    NgSwitchCase,
    IntervalComponent,
    DoubleDateComponent,
    SpinFiltersCheckboxComponent,
    FieldComponent,
    DistanceToEntityComponent,
    PearlIconComponent,
  ],
})
export class SelectorFieldsetComponent extends FieldsetComponent {
}
