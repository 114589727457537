import { Component, EventEmitter, Injector, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { ChartSelectKey, ChartSelects } from '../graph/chart-types';
import { AppInfoService } from '../app/app-info-service';
import { ChartSelectOptions } from '../helpers/types';
import { SelectComponent } from './select';
import { PearlButtonComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-filters-menu',
  templateUrl: 'filters-menu.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatMenuModule,
    PearlButtonComponent,
    NgFor,
    NgIf,
    SelectComponent,
  ],
})
export class FiltersMenuComponent {
  @Input()
  public selectsgroup: ChartSelectKey[];
  @Input()
  public selects: ChartSelects;
  @Input()
  public display: ChartSelectOptions;
  @Input()
  public messages: { [select: ChartSelectKey]: string } = {};
  @Input()
  public multiple?: boolean;
  @Output()
  public onchange = new EventEmitter<any>();

  private appInfoService: AppInfoService;

  constructor(injector: Injector) {
    this.appInfoService = injector.get(AppInfoService);
  }

  onChange(select, $event): void {
    this.appInfoService.userAction('menu');
    this.onchange.emit({ 'chartParam': select, 'value': $event });
  }
}
