import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit, inject } from '@angular/core';
import { NgIf } from '@angular/common';

import { IdentityItem } from '../helpers/types';
import { FieldConfig, FormResults, SimpleFormConfig } from '../shared/simple-form.types';
import { SimpleFormComponent } from '../shared/simple-form.component';
import { DataLoader } from '../data-loader/data-loader';

@Component({
  selector: 'vessel-request-form',
  templateUrl: './vessel-request-form.component.html',
  standalone: true,
  styleUrls: ['./vessel-request-form.component.css'],
  imports: [
    SimpleFormComponent,
    NgIf,
  ],
})
export class VesselRequestFormComponent implements OnInit {
  public existingVessel: boolean;
  public readonly dataLoader = inject(DataLoader);
  public formConfig: SimpleFormConfig;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IdentityItem,
  ) {}

  ngOnInit(): void {
    this.existingVessel = this.data !== null;

    this.initConfig();
  }

  private initConfig(): void {
    const baseFields: FieldConfig[][] = [
      [
        {
          id: 'vesselId',
          title: 'id',
          placeholder: 'ID',
          value: this.data?.id,
          hidden: true,
        },
      ],
      [
        {
          id: 'vesselImo',
          title: 'IMO',
          placeholder: 'IMO',
          value: this.data?.imo,
          disabled: this.existingVessel,
        },
        {
          id: 'vesselMmsi',
          title: 'MMSI',
          placeholder: 'MMSI',
          value: this.data?.mmsi,
          disabled: this.existingVessel,
        },
      ],
      [
        {
          id: 'vesselTitle',
          title: 'vessel title',
          placeholder: 'Vessel title',
          value: this.data?.title,
          disabled: this.existingVessel,
          fullWidth: this.existingVessel,
          required: true,
        },
        {
          id: 'vesselManager',
          title: 'vessel manager',
          placeholder: 'Vessel manager',
          hidden: this.existingVessel,
        },
      ],
      [
        {
          id: 'vesselSpecSheetUrl',
          title: 'spec sheet URL',
          placeholder: 'Spec sheet URL',
          fullWidth: true,
          hidden: this.existingVessel,
        },
      ],
      [
        {
          id: 'vesselAisProviderUrl',
          title: 'AIS provider URL',
          placeholder: 'AIS Provider URL (MarineTraffic, Vessel Finder, etc.)',
          fullWidth: true,
          hidden: this.existingVessel,
        },
      ],
      [
        {
          id: 'vesselAdditionalInfo',
          title: 'additional info',
          placeholder: 'Additional information to find vessel (vessel type, flag country, etc)',
          fullWidth: true,
          hidden: this.existingVessel,
        },
      ],
      [
        {
          id: 'reason',
          title: 'reason',
          placeholder: 'Please provide as much context as possible to explain your request',
          fullWidth: true,
          required: true,
          multiline: true,
        },
      ],
    ];

    let successMessage = 'Our team will study your request and refer to your app owner for discussion';
    if (!this.existingVessel) {
      successMessage += ' if the vessel is out of scope';
    }
    this.formConfig = {
      title: 'Add the following vessel to my scope',
      subTitle: this.existingVessel
        ? "This vessel is out of your subscription scope. Please provide a brief summary of the reason why you'd like "
          + 'to add this vessel to your subscription. We will refer to your app owner for discussion.'
        : 'Please make sure you searched for the vessel using the IMO and/or MMSI before submitting your request.'
          + ' If this new vessel falls out of your subscription scope, we will refer to your app owner for discussion.',
      infoMessage: 'If your demand is more specific or concerns a large list of vessels, please send a request via the '
        + 'in app chat',
      actionButton: {
        label: 'Send request',
        icon: 'mail',
        type: 'primary',
        onClick: () => this.sendForm(),
      },
      fields: baseFields,
      formSent: false,
      successMessage,
    };
  }

  private sendForm(): void {
    const url = '/spindjango/pipelay-vessel-request';
    const formResults = this.buildFormResults();
    this.dataLoader.post(url, formResults)
      .then(resp => {
        this.formConfig.formSent = true;
        if (resp['error'] === 'Duplicate request') {
          this.formConfig.duplicateMessage =
            'Someone from your organization already asked to add this vessel to your scope. '
            + 'The request is currently being reviewed by our team.';
        }
      })
      .catch(() => {
        this.formConfig.errored = true;
      });
  }

  private buildFormResults(): FormResults {
    const results: FormResults = {};
    this.formConfig.fields.flat().forEach(field => results[field.id] = field.value);
    return results;
  }
}
