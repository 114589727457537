import { Params } from '@angular/router';

import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

import { Config } from '../config/config';
import { DatabaseHelper } from '../database/database-helper';
import { ErrorService } from '../error-pages/error.service';
import { LinkData } from '../shared/spin-link';
import { DataHelpers, getNumberListFromString } from './data-helpers';
import { ActionEvent, ActionEventParams, ActionType, ComponentAction, ConfigParams, FieldSettings, FiltersState,
  LinkAction, NumOrString, PageLinkSpec, SpinProduct, isComponentAction, isEntityAction, isLinkAction } from './types';
import { PageConfig, UrlSubdirectory } from './config-types';
import { RawDataPoint } from '../graph/chart-types';
import { getChained } from '../data-loader/ref-data-provider';
import { getLocalRequestInfo, injectParamsInLocalUrl } from '../data-loader/local-loader';

export const BreakLinkStyle = {
  'cursor': 'default',
  'pointer-events': 'none',
  'text-decoration': 'none',
  'color': 'unset',
};

export class NavigationHelper {
  private static errorService: ErrorService = new ErrorService();
  public static PAGE_REGEXP = new RegExp(/page\/([-\w]+)/);

  /**
   * Generate a navigation link from a base route and parameters to be added as query parameters.
   * The query parameters should already be treated (stringified & encoded once)
   */
  public static generateNavigationLink(route: string, params: Params): string {
    // Add the vessel fleet / projects parameter
    if (window.sessionStorage[Config.SELECTEDFLEETS] && !(Config.SELECTEDFLEETS in params)) {
      params[Config.SELECTEDFLEETS] = window.sessionStorage[Config.SELECTEDFLEETS];
    }

    return NavigationHelper.appendParamsToUrl(route, params);
  }

  /**
   * Generate an app. navigation link.
   *
   * @param action  The action config. It contains the target route and the query parameters to be added.
   * @param values  The data object. Used to get the parameter values.
   * @param route   The current route. Used to get query parameters values.
   * @returns       The link
   */
  public static getNavigateLink(action: LinkAction, values: any): string {
    const actionEvent = NavigationHelper.constructActionEvent({ action, values });
    if ((actionEvent.action as LinkAction).href == null) {
      console.info(`ERROR: Could not generate a link for an action of type '${action.type}' - Check its config`);
      return null;
    }
    return NavigationHelper.generateNavigationLink((actionEvent.action as LinkAction).href, actionEvent.data);
  }

  /*
   * If the first character is ":", we need to extract the name from values
   * Otherwise it is a static string
   */
  public static getLinkTitle(field: any, values: any) {
    const linkTitleField = field.linkPropTitle as string;
    if (!linkTitleField) {
      return null;
    }
    if (DataHelpers.isDataParam(linkTitleField)) {
      return values[DataHelpers.extractDataParam(linkTitleField)];
    } else {
      return linkTitleField;
    }
  }

  /**
   * Construct the link full href from the button values.
   * Should replace the link-related code in constructActionEvent method
   */
  public static getLinkActionData(action: LinkAction, values: any): LinkData {
    // Case of simple link(s). URL is already formatted in values
    if (action.key && (!action.href || action.href == '')) {
      return values[action.key];
    } else {
      let href = action.href;
      if (action.key) {
        href += values[action.key];
      } else if (action.keys) {
        // Set of keys separated by a back slash /key1/key2
        href = action.keys.reduce((acc, key) => acc.replace(/<>/, values[key]), action.href);
      }
      return href;
    }
  }

  /**
   * Constructs an ActionEvent object which holds all the data
   * necessary to handle button click. As parameters it takes the action config
   * and eventually a data-object
   *
   * @param event             MouseEvent from browsers API
   * @param action            Action config
   * @param values            A data object used to extract parameter values
   *                           (usually what is shown in the tooltip/summary)
   * @param title             The title of the button
   * @return ActionEvent
   */
  public static constructActionEvent(actionEventParams: ActionEventParams): ActionEvent {
    const { event, action, values } = actionEventParams;

    /*
     * Extract parameters
     * Encode the button parameters regarding its type (see excludeEncodingTypes)
     */
    const data = NavigationHelper.extractData(
      action.params,
      values,
      Object.fromEntries(new URL(window.location.href).searchParams),
      {
        removeLayerWhenNoFilter: action.removeLayerWhenNoFilter,
        valueIndex: action.valueIndex,
      },
    );

    /**
     * Build ActionEvent
     */
    const targetAction = Object.assign({}, action);
    if (!targetAction.layer && values) targetAction.layer = values['layerId'];
    const actionEvent: ActionEvent = {
      action: targetAction,
      data,
      event,
    };

    /*
     * Entity actions
     */
    if (isEntityAction(action)) {
      /*
       * Always store the ID of the original item inside the EntityAction
       * Use the item ID if reloadIdField is not defined in conf
       */
      actionEvent.idOfOriginalItem = values ? values[action.reloadIdField ?? 'id'] : null;
      /*
       * If a button should duplicate part of the endpoint data into an entity, this is the part that extracts
       * the entity from the underlying endpoint item
       */
      if (action.type === 'duplicateEndpointIntoEntityAndOpen') {
        actionEvent.data = NavigationHelper.fillParametersDuplication(action.duplication, values);
      }
    }

    /**
     * Link actions
     */
    if (isLinkAction(action)) {
      const href = action.type === 'link' ? NavigationHelper.getLinkActionData(action, values) as string : action.href;
      (actionEvent.action as LinkAction).href = href;

      /*
       * We always want to reset the state when we have a navigate button.
       * We only want to see what has been passed in parameters on the target dashboard.
       */
      if (action.type === 'navigate') {
        actionEvent.resetState = true;
      }
    }

    /**
     * Component actions
     */
    if (isComponentAction(action)) {
      /*
       * Add modal config if set
       */
      if (action.modalConfig) {
        (actionEvent.action as ComponentAction).modalConfig = cloneDeep(action.modalConfig);
      }

      /*
       * Set data for expandable arrays
       */
      if (action.type === 'expandArray') {
        actionEvent.data['table'] = values[action.expandKey];
      }

      /*
       * Use params as modal state for charts
       */
      if (action.type === 'chartModal' || action.type == 'tableModal') {
        Object.keys(data).forEach(key => {
          if (data[key] === undefined) {
            delete data[key];
          } else if (!Array.isArray(data[key])) {
            data[key] = [data[key]];
          }
        });
        actionEvent.modalComponentState = data as FiltersState;
      }
    }

    return actionEvent;
  }

  public static fillParametersDuplication(duplicateMap: any, values: any) {
    const duplicate = {};
    for (const key in duplicateMap) {
      const value = duplicateMap[key];
      if (Array.isArray(value)) {
        duplicate[key] = value.map(v => {
          if (typeof v === 'object') {
            return NavigationHelper.fillParametersDuplication(v, values);
          } else if (typeof v === 'string' && v.charAt(0) == ':') {
            return NavigationHelper.substituteProperty(v, values);
          } else if (typeof v === 'string' && v.indexOf('compute => ') > -1) {
            return NavigationHelper.computeProperty(v, values);
          } else if (typeof v === 'string' && v.indexOf('array => ') > -1) {
            return NavigationHelper.substitutePropertyIntoArray(v, values);
          } else {
            return v;
          }
        });
      } else if (typeof value === 'object') {
        duplicate[key] = NavigationHelper.fillParametersDuplication(value, values);
      } else if (typeof value === 'string' && value.charAt(0) == ':') {
        duplicate[key] = this.substituteProperty(value, values);
      } // computation on data performed and result passed to tooltip
      else if (typeof value === 'string' && value.indexOf('compute => ') > -1) {
        duplicate[key] = this.computeProperty(value, values);
      } else if (typeof value === 'string' && value.indexOf('array => ') > -1) {
        duplicate[key] = NavigationHelper.substitutePropertyIntoArray(value, values);
      } else {
        duplicate[key] = value;
      }
      if (!duplicate[key] || (Array.isArray(duplicate[key]) && !duplicate[key].some(v => v != null))) {
        delete duplicate[key];
      }
    }
    return duplicate;
  }

  public static substituteProperty(value: any, values: any) {
    const keyInData = value.substr(1);

    if (!values) {
      this.errorService.handle('Values or null or undefined. Risk of unhandled behavior.');
    }

    if (keyInData in values) {
      return values[keyInData];
    } else {
      console.warn('Navigation parameter ' + keyInData + ' not found in the data');
      return null;
    }
  }

  public static substitutePropertyIntoArray(value: string, values: any) {
    const keyInData = value.replace('array => ', '');
    if (keyInData.charAt(0) != ':') {
      console.warn(`Wrong use of 'array => :fieldId' syntax: ${value}`);
      return null;
    }
    const property = NavigationHelper.substituteProperty(keyInData, values);
    return property ? [property] : null;
  }

  public static computeProperty(value: any, values: any) {
    const computation = value.replace('compute => ', 'return ');
    const fn = Function('value', 'NavigationHelper', computation);
    return fn(values, NavigationHelper);
  }

  public static roundToDate(value: number): number {
    const strDate = new Date(value).toDateString();
    if (strDate) {
      const newDate = dayjs(strDate).utc(true);
      return newDate.valueOf();
    }
    return value;
  }

  /**
   * Extract data from config parameters, values & query parameters.
   */
  public static extractData(
    configParams: ConfigParams,
    values: RawDataPoint,
    queryParams: Params = null,
    options: {
      removeLayerWhenNoFilter?: boolean;
      valueIndex?: number;
    } = {
      removeLayerWhenNoFilter: false,
      valueIndex: null,
    },
  ): object {
    const data: object = {};
    const layersWithActiveFilter: string[] = [];
    const valueIndex = options.valueIndex;

    for (const key in configParams) {
      const isString = typeof configParams[key] === 'string';
      /** Get the navigation parameters from the data behind */
      if (isString && DataHelpers.isDataParam(configParams[key] as string)) {
        const dataKey = DataHelpers.extractDataParam(configParams[key] as string);
        if (values) {
          const value = getChained<object>(values, dataKey);
          if (value && (valueIndex == null || valueIndex in value)) {
            data[key] = valueIndex == null ? value : value[valueIndex];
            layersWithActiveFilter.push(key.split(':')[0]);
          }
        }
      } else if (isString && DataHelpers.isQueryParam(configParams[key] as string)) {
        const queryKey = DataHelpers.extractQueryParam(configParams[key] as string);
        if (queryParams && queryKey in queryParams) {
          data[key] = queryParams[queryKey];
        }
      } else {
        // Hardcoded value - We pass it directly from the config to the tooltip
        data[key] = cloneDeep(configParams[key]);
      }
    }

    // De-activate layers with no active filters (otherwise all elements are displayed)
    if (options.removeLayerWhenNoFilter && 'map:active' in data) {
      // "map:active" has 2 allowed types: string (single layer) or array (list of layers)
      const singleMapLayer = typeof data['map:active'] === 'string';
      const mapActiveLayers = (singleMapLayer ? [data['map:active']] : data['map:active']) as string[];
      const layerToRemove = [];
      for (const value of mapActiveLayers) {
        // Remove if no active filters
        if (!layersWithActiveFilter.includes(value)) {
          layerToRemove.push(value);
        }
      }
      // Also check that layers in historicalPositions mode have id filter (otherwise de-activate it)
      const filterLayerToRemove = [];
      for (const paramKey in data) {
        if (data[paramKey] === 'historicalPositions') {
          const layer = paramKey.split(':')[0];
          if (!data.hasOwnProperty(layer + ':' + 'vessel')) {
            filterLayerToRemove.push(layer);
            layerToRemove.push(layer);
          }
        }
      }
      for (const layer of layerToRemove) {
        const ind = mapActiveLayers.indexOf(layer);
        mapActiveLayers.splice(ind, 1);
      }
      // Delete all filters related to de-activated layer
      for (const paramKey in data) {
        if (filterLayerToRemove.includes(paramKey.split(':')[0])) {
          delete data[paramKey];
        }
      }
      // Keep list as an array, will be properly encoded afterwards (to avoid double-encoding of comas)
      data['map:active'] = mapActiveLayers;
    }

    // TODO: really? why not set __scenarioHint = true directly?
    if ('scenarioHint' in data) {
      data['__scenarioHint'] = true;
    }

    return data;
  }

  public static getPageRouterLink(entity: any, field: any) {
    const pLinkProp = field.id + '_pLink';
    if (pLinkProp in entity) {
      return entity[pLinkProp];
    }
    const pageLinkButton: any = {
      ...field,
      type: 'navigate',
    };
    entity[pLinkProp] = NavigationHelper.getNavigateLink(pageLinkButton, entity);

    return entity[pLinkProp];
  }

  public static getRouteInfo(route: string): { type: 'dashboard' | 'page' | 'unknown'; pageId: string } {
    const matches = route.match(/(dashboard\/page|dashboard)\/([-\w]+)/);

    if (matches?.length !== 3) {
      return { type: 'unknown', pageId: route };
    }

    const [_, type, pageId] = matches;
    return { type: type === 'dashboard' ? type : 'page', pageId };
  }

  public static getPageLinkFromList(
    config: Config,
    values: any,
    field: FieldSettings,
    linkList: string[],
  ): PageLinkSpec[] {
    const pageLinkList: PageLinkSpec[] = [];
    linkList?.forEach((link, index) => {
      const pageLinkButton: any = {
        ...field,
        type: 'navigate',
        valueIndex: index,
      };
      const pageLink: PageLinkSpec = {
        href: NavigationHelper.getNavigateLink(pageLinkButton, values),
        title: link,
        index,
        linkListLength: linkList.length,
      };

      pageLinkList.push(pageLink);
    });
    return pageLinkList;
  }

  /**
   * Create a ActionEvent for page link redirection. This will open the page in a new tab and reload the app
   *
   * @param event       Mouse event triggering the page link.
   * @param field       Holds the link configuration
   * @param entity      The values used to search for parameter values
   * @param valueIndex  If passed, we assume the parameter value is an array and we will access
   *                    the value at the given index
   * @returns           The tooltip button event or void if we should not reroute
   */
  public static getPageNewTabLinkButtonEvent(
    event: MouseEvent,
    field: any,
    entity: any,
    valueIndex?: number,
  ): ActionEvent {
    const data = NavigationHelper.extractData(
      field.params,
      entity,
      Object.fromEntries(new URL(window.location.href).searchParams),
      { valueIndex },
    ) as Params;
    event.preventDefault();
    const actionEvent: ActionEvent = {
      action: {
        type: 'link',
        href: `${window.location.origin}${this.appendParamsToUrl(field.href, data)}`,
      },
      event,
    };
    return actionEvent;
  }

  /**
   * Create a ActionEvent for page link navigation.
   *
   * @param event       Mouse event triggering the page link.
   * @param field       Holds the link configuration
   * @param entity      The values used to search for parameter values
   * @param valueIndex  If passed, we assume the parameter value is an array and we will access
   *                    the value at the given index
   * @returns           The tooltip button event or void if we should not navigate
   */
  public static getPageNavigationButtonEvent(
    event: MouseEvent,
    field: any,
    entity: any,
    valueIndex?: number,
  ): void | ActionEvent {
    if (DatabaseHelper.isSpecialClick(event)) {
      return;
    }
    const data = NavigationHelper.extractData(
      field.params,
      entity,
      Object.fromEntries(new URL(window.location.href).searchParams),
      { valueIndex },
    );
    event.preventDefault();
    const actionEvent: ActionEvent = {
      action: {
        type: 'navigate',
        href: field.href,
        layer: entity['layerId'] ?? null,
      },
      data,
      event,
    };
    return actionEvent;
  }

  /* PreventDefault behavior and build an ActionEvent to trigger internal navigation. */
  public static buildInternalNavigationEventFromUrl(url: string, event?: MouseEvent): ActionEvent {
    event?.preventDefault();
    const urlObj = new URL(url, window.location.href);
    const data = Array.from(urlObj.searchParams as any).reduce((acc, [k, v]) => Object.assign(acc, { [k]: v }), {});
    return {
      action: {
        type: 'navigate',
        href: urlObj.pathname,
      },
      data: data as object,
      event,
    };
  }

  /**
   * Check if target page type is in the dashboard/page list to which he has access rights
   * We also check if we have a require parameter. If there is a require parameter and no value corresponding
   * the link shouldn't be clickable
   */
  public static getPageLinkStyle(
    href: string,
    allowedUrls: { [dashboardId: string]: boolean },
    values: RawDataPoint,
    require?: string,
    valueIndex?: number,
  ): object {
    if (
      href in allowedUrls
      && (!require
        || (require && getChained(values, require) && (valueIndex == null || getChained(values, require)[valueIndex])))
    ) {
      return {};
    }

    // break standard link behaviour if user doesn't right to access to the target page
    return BreakLinkStyle;
  }

  /**
   * Custom encoding of an URL query parameter. \
   * If the query parameter is an array, we encode each value and join them with a comma `,`
   *
   * @param value The query parameter to encode
   * @returns     The encoded query parameter value
   */
  public static encodeParameterValue(value: any): string {
    if (Array.isArray(value)) {
      return value.map(v => this.encodeSingleParameterValue(v)).join(',');
    }
    return this.encodeSingleParameterValue(value);
  }

  /**
   * Custom encoding of an URL string. \
   * We use basic `encodeURIComponent` function, but we additionally force the %-encoding of parenthesis.
   *
   * @param value The string to encode
   * @returns     The encoded string
   */
  private static encodeSingleParameterValue(value: string): string {
    /** Ensure we don't encode twice a value */
    if (typeof value === 'string' && NavigationHelper.isUriEncoded(value)) return value;
    return encodeURIComponent(value)
      .replace(/\(/g, '%28')
      .replace(/\)/g, '%29');
  }

  /**
   * Custom decoding of an URL string. \
   * We use basic `decodeURIComponent` function, but we additionally replace undefined & null by an empty string.
   *
   * @param value The URL string to decode
   * @returns     The decoded URL string
   */
  public static decodeSingleParameterValue(value: string): string {
    return decodeURIComponent(value)
      .replace(/^null|undefined$/, '');
  }

  /**
   * @param pathUrl: an URL with or without base, i.e /spindjango/myendpoint?param=5 or
   * http://market-intelligence.spinergie.com/spindjango/myendpoint?param=5
   * @returns the URL split in two: the base path and the query params, i,e "/spindjango/myendpoint" and "{param: 5}"
   *
   * Note: to be clear on url parts, see https://developer.mozilla.org/en-US/docs/Web/API/Location
   */
  public static getUrlPathAndQueryParams(url: string): { path: string; queryParams: URLSearchParams } {
    const parsedUrl = new URL(url, window.location.origin);
    return { path: parsedUrl.pathname, queryParams: parsedUrl.searchParams };
  }

  public static getUrlPath(url: string): string {
    return this.getUrlPathAndQueryParams(url).path;
  }

  /** Get query parameters (as a dict, not an URLSeachParams) from an URL string. */
  public static getUrlQueryParams(url: string): { [paramId: string]: string } {
    const { queryParams } = this.getUrlPathAndQueryParams(url);
    return Object.fromEntries(queryParams);
  }

  /**
   * Check if url1 and url2 have the same path
   * e.g. 'http://test.com/test?testId=1 and 'test.com/test?otherId=3' have the same path '/test'
   */
  public static urlPathMatch(url1: string, url2: string): boolean {
    return this.getUrlPath(url1) === this.getUrlPath(url2);
  }

  /**
   * Removes empty query params from provided URL (i.e "vesselId=").
   *
   * @param url     The URL to clean.
   */
  public static cleanURL(url: string): string {
    const urlParts = url.split('?');
    if (urlParts.length === 1) return url;
    return [urlParts[0], urlParts[1].split('&').filter(b => !b.endsWith('=')).join('&')].join('?');
  }

  /**
   * Inject parameters in an url (could be any text), thanks to paramBag attributes
   * e.g. 'test=[:testValue:]' will replace [:testValue:] by paramBag.testValue
   *
   * @param templateUrl Input text with substrings to be replaced (e.g. 'test=[:testValue:]')
   * @param paramBag Object containing attributes that are used to replace in string (e.g. paramBag = {testValue: 5})
   * @returns        Text with replaced parameters (e.g. 'test=5')
   */
  public static injectParamsInUrl(
    templateUrl: string,
    paramBag: { [key: string]: unknown },
  ): string {
    if (!templateUrl) return templateUrl;

    if (getLocalRequestInfo(templateUrl) !== null) return injectParamsInLocalUrl(templateUrl, paramBag);

    const replacer = (_: string, varName: string): string => {
      if (!paramBag || !(varName in paramBag)) {
        console.warn(`Unable to fill templateUrl parameter ${varName} because it not present in the parameters`);
        return '';
      }
      return encodeURIComponent(paramBag[varName] as NumOrString);
    };
    return NavigationHelper.cleanURL(templateUrl.replace(/\[:(\w+):\]/g, replacer));
  }

  /** Append all params at the end of the baseUrl. Accept an input baseUrl that already has some params */
  public static appendParamsToUrl(baseUrl: string, params: object): string {
    if (!baseUrl) return baseUrl;

    // Remove undefined parameters
    Object.keys(params).forEach(key => {
      if (params[key] == null) delete (params[key]);
    });
    const paramString = new URLSearchParams(params as Record<string, string>).toString();

    const separator = baseUrl.includes('?') ? '&' : '?';
    return paramString ? `${baseUrl}${separator}${paramString}` : baseUrl;
  }

  public static addParameterToUrl(url: string, key: string, value: string): string {
    return url += (url.indexOf('?') > 0 ? '&' : '?') + `${key}=${value}`;
  }

  public static emptyUrlSearchParams(searchParams: URLSearchParams): void {
    const toDelete = Array.from(searchParams.keys());
    for (const key of toDelete) {
      searchParams.delete(key);
    }
  }

  // will serialize provided dict into URL. Will keep existing params, unless keepExistingArguments is false
  public static serializeToCurrentUrl(paramsDict: { [key: string]: string }, keepExistingArguments = true): void {
    const url = new URL(window.location.href);
    if (!keepExistingArguments) {
      NavigationHelper.emptyUrlSearchParams(url.searchParams);
    }
    Object.entries(paramsDict).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    window.history.replaceState({}, '', url);
  }

  public static getNumberListFromUri(uriSegment: string): number[] {
    return getNumberListFromString(decodeURIComponent(uriSegment));
  }

  /**
   * Type title appears in:
   * - subtitle of an option in the search bar
   * - a page title, between parenthesis
   */
  public static getPageTypeTitle(item: unknown, pageConfig: PageConfig): string {
    if (pageConfig?.typeTitle == null) {
      return pageConfig?.title;
    }

    // If it starts with an ':' it means variable should be injected, otherwise typeTitle is a constant string
    const typeTitle = pageConfig?.typeTitle?.startsWith(':')
      ? item?.[pageConfig.typeTitle.slice(1)]
      : pageConfig.typeTitle;

    return typeTitle ?? pageConfig.title;
  }

  /**
   * Subtitle appears in:
   * - subtitle of an option in the search bar
   */
  public static getPageSubtitle(item: unknown, pageConfig: PageConfig): string | null {
    if (pageConfig?.subtitle == null) {
      return null;
    }

    const subtitle = pageConfig.subtitle.startsWith(':')
      ? item?.[pageConfig.subtitle.slice(1)]
      : pageConfig.subtitle;

    return subtitle ?? null;
  }

  /**
   * Get the current dashboard panelId. PanelId is used to get dashboard specific settings
   * @param routerUrl
   */
  public static getPanelId(routerUrl: string, product: SpinProduct): {
    panelId: string;
    isPage: boolean;
  } {
    /*
     * match any dashboard, will work for:
     * - dashboard/vessel-comparator#dsv
     * - dashboard/vessel-comparator?something
     * - dashboard/vessel-comparator
     */
    const panelRegex = new RegExp(
      `/dashboard${product === 'analysts' ? '(?:\\/analysts)?' : ''}\\/([^#\\/;?]*)(\\/[^#\\/;?]*)?((#.*)|(\\?.*)|$)`,
      'i',
    );

    const panelRegexResult = panelRegex.exec(routerUrl);
    let panelId = panelRegexResult[1];
    const selectedPanelIsPage = UrlSubdirectory[panelId] === UrlSubdirectory.page;
    const selectedPanelIsDpr = UrlSubdirectory[panelId] === UrlSubdirectory.dpr;

    if (selectedPanelIsPage) {
      panelId = panelRegexResult[2].replace('/', '');
    } else if (selectedPanelIsDpr) {
      panelId += `${panelRegexResult[2]}`.replace('/', '-');
    }
    return {
      panelId: panelId,
      isPage: selectedPanelIsPage,
    };
  }

  public static isUriEncoded(uri: string): boolean {
    return uri !== decodeURIComponent(uri);
  }

  public static getQueryParamsFromSessionStorage(): object {
    const params = {};
    for (const storageKey of Config.BROWSER_GLOBAL_FILTERS) {
      if (!window.sessionStorage[storageKey]) continue;
      params[storageKey] = window.sessionStorage[storageKey];
    }
    return params;
  }
}
