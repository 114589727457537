<div class="spin-legend" *ngIf="showLegend">
  <!-- Show/hide bar labels (mat-slide-toggle) -->
  <div class="legend-labels">
    <mat-slide-toggle
      class="toggle title mat-primary legend-slide-toggle"
      [matTooltip]="labelsTooltip"
      [checked]="showLabels"
      (change)="toggleLabels($event)">
      Show labels
    </mat-slide-toggle>
  </div>

  <!-- Body -->
  <div class="legend-body" #legendBody>
    <!-- Layer -->
    <div *ngFor="let legendState of visibleLegends" [hidden]="!legendState.show" class="legend-layer">
      <!-- Layer.header -->
      <div class="legend-layer-header">
        <pearl-form-field *ngIf="legendState.colorBys.length > 1" [label]="legendState.title">
          <mat-select
            #select
            [(value)]="legendState.currentColorBy"
            [id]="legendState.id"
            [disabled]="!legendState.colorBys"
            panelClass="legend-select"
            (selectionChange)="colorByChanged(legendState, $event.value)">
            <mat-option *ngFor="let colorBy of legendState.colorBys" [value]="colorBy">
              {{ colorBy.title }}
            </mat-option>
          </mat-select>
        </pearl-form-field>

        @if (legendState.colorBys.length == 1) {
          <div>{{ legendState.colorBys[0].title }}</div>
        } @else if (!legendState.colorBys.length && legendState.total) {
          <div>{{ legendState.title }}</div>
        }

        <span
          *ngIf="isLayerExportable(legendState)"
          [matTooltip]="
            isLayerLegendInvalidForExport(legendState)
              ? 'You cannot export more than ' +
                legendState.maxExportableNumber +
                ' entities. Filter out some elements to enable XLSX download'
              : config.getXlsxDownloadTooltip(legendState.noExport)
          ">
          <pearl-button
            type="icon"
            icon="download"
            class="legend-export-icon"
            [ngClass]="{ disabled: isLayerLegendInvalidForExport(legendState) }"
            [disabled]="
              isLayerLegendInvalidForExport(legendState) || config.trialModeDownloadsDisabled || legendState.noExport
            "
            (click)="export(legendState)" />
        </span>
      </div>
      <!-- /Layer.header -->

      <!-- Layer.patterns -->
      <ul *ngIf="layerHasPatterns(legendState.patterns)" class="legend-patterns">
        <li *ngFor="let pattern of prepareLayerPatterns(legendState.patterns)">
          <svg class="square-border">
            <ng-container [ngSwitch]="true">
              <rect
                *ngSwitchCase="pattern.id.startsWith('stroke')"
                [attr.stroke]="pattern.fill"
                stroke-width="3"
                width="100%"
                height="100%"></rect>
              <rect
                *ngSwitchCase="pattern.id.startsWith('fill')"
                [attr.fill]="pattern.fill"
                width="100%"
                height="100%"></rect>
              <g *ngSwitchDefault class="square-pattern">
                <rect fill="#888888" width="100%" height="100%"></rect>
                <rect [attr.fill]="pattern.url" width="100%" height="100%"></rect>
              </g>
            </ng-container>
          </svg>
          <span class="legend-title">{{ pattern.title }}</span>
          <span class="legend-count">{{ pattern.count || 0 }}</span>
        </li>
      </ul>
      <!-- /Layer.patterns -->

      @if (legendState.mapDash) {
        <div class="legend-pattern dash-line">
          <svg width="16" height="16">
            <line x1="0" y1="8" x2="16" y2="8"></line>
          </svg>
          <span class="legend-pattern-title">{{ legendState.mapDash.title }}</span>
        </div>
      }

      <!-- Layer.colorBy -->
      @if (legendState.currentColorBy?.continuous) {
        <div class="legend-continous" [style.background]="getGradient(legendState.currentColorBy)"></div>
        <p class="bounds">
          <span>{{ legendState.currentColorBy.min | number: "1.0-3" }}</span>
          <span>{{ legendState.currentColorBy.max | number: "1.0-3" }}</span>
        </p>
      } @else if (legendState.currentColorBy) {
        <ul>
          <li *ngFor="let color of legendState.currentColorBy.colors" [ngClass]="{ hide: !color.visible }">
            @if (color.visible) {
              <div class="square-border" [ngStyle]="svgPaintAttributesToCss(color.style)"></div>
              <span class="legend-title">{{ color.title || color.id }}&nbsp;</span>
              <span *ngIf="color.count != null" class="legend-count">{{ color.count }} </span>
            }
          </li>
        </ul>
        <!-- Shown only on layers without any colorBy, but with a global style -->
      } @else if (legendState.total) {
        <ul>
          <li>
            <div class="square-border" [ngStyle]="svgPaintAttributesToCss(legendState.globalStyle)"></div>
            <span class="legend-title">No colouring</span>
            <span class="legend-count">{{ legendState.total }} </span>
          </li>
        </ul>
      }
    </div>
    <!-- /Layer -->
  </div>
  <!-- /Body -->
</div>
