import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, Output,
  SimpleChanges, ViewChild, booleanAttribute, input, signal } from '@angular/core';
import { MatAutocompleteModule,
  MatAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

import { DatabaseHelper } from '../database/database-helper';
import { DataHelpers } from '../helpers/data-helpers';
import { StringHelper } from '../helpers/string-helper';
import { IdentityItem } from '../helpers/types';
import { ProductAnalyticsService } from './product-analytics/product-analytics.service';
import { TruncateStringPipe } from '../helpers/pipes';
import { DescriptionButtonComponent } from './description-button';
import { VesselRequestFormComponent } from '../vessels/vessel-request-form.component';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';
import { PearlFormFieldComponent, PearlIconComponent } from './pearl-components';

export type SearchBarStyle = 'outline' | 'fill';
export const removeableKeywords: string[] = ['st', 'saint'];

@Component({
  selector: 'search-bar',
  templateUrl: 'search-bar.html',
  styleUrl: 'search-bar.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    PearlFormFieldComponent,
    PearlIconComponent,
    MatInputModule,
    MatAutocompleteModule,
    DescriptionButtonComponent,
    NgIf,
    NgFor,
    MatOptionModule,
    NgTemplateOutlet,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    PearlButtonComponent,
    TruncateStringPipe,
    AsyncPipe,
  ],
})
export class SearchBarComponent implements OnChanges {
  @ViewChild('input', { read: MatAutocompleteTrigger })
  $autoComplete: MatAutocompleteTrigger;

  public readonly searchLabel = input<string | null>(null);
  public readonly highlighted = input<boolean>(false);
  public readonly hasLabel = input<boolean>(true);

  @Input()
  searchItemsAvailable: IdentityItem[];
  @Input()
  recentSearch: IdentityItem[];
  @Input()
  clearAfterSelect = false;
  @Output()
  onoptionselected = new EventEmitter<IdentityItem>();
  @Input()
  searchBarStyle: SearchBarStyle = 'outline';
  @Input()
  appearance: string = '';
  @Input()
  searchBarInput: string = '';
  @Input()
  searchSubtitle = true;
  @Input()
  resultTypeOrder: string[] = [];
  @Input()
  resultAlreadySorted: boolean = false;
  @Input()
  required: boolean = false;
  @Input()
  errorMessage: string;
  @Input()
  description: string;
  @Input()
  hint: string;
  @Input()
  disabled: boolean = false;
  @Input()
  readonly: boolean = false;

  showIcon = input(true, { transform: booleanAttribute });

  @Input()
  canRequestVessel: boolean = false;
  @Input()
  source: string = ''; // this is only used for mixpanel, to track the origin of `pageSearched` events (and use filters)

  public readonly small = input<boolean>(true);

  public searchResults: IdentityItem[];
  public currentSearchQuery: string;
  public readonly itemsCountLimit = 40;
  public showMoreText: string;
  public showMoreVisible = false;
  protected specialClick = false;
  private cdRef: ChangeDetectorRef;
  private productAnalyticsService: ProductAnalyticsService;
  public dialog: MatDialog;

  constructor(injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
    this.productAnalyticsService = injector.get(ProductAnalyticsService);
    this.dialog = injector.get(MatDialog);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!('searchItemsAvailable' in changes || 'recentSearch' in changes)) {
      return;
    }

    /*
     * cleaning available search items if we need it
     * comparing if we have different items to avoid retrigger it too many times
     * because we have more than 40k searchable items in case of the header search bar
     */
    if (this.searchItemsAvailable?.length) {
      this.searchItemsAvailable.forEach(item => {
        item.cleanTitle = item.title ? StringHelper.cleanString(item.title) : '';
        item.cleanSubtitle = item.subtitle ? StringHelper.cleanString(item.subtitle) : '';
        item.cleanTypeTitle = item.typeTitle ? StringHelper.cleanString(item.typeTitle) : '';
      });
    }

    // set the history index for all recent searches
    this.initHistoryIndex();
  }

  public optionDisplay = (option?: IdentityItem): string => {
    if (option) {
      return this.clearAfterSelect ? '' : option.title;
    }
    return this.currentSearchQuery;
  };

  public optionSelected(option: IdentityItem): void {
    /*
     * if the options is null it means the user clicked or selected
     * something not-bound (probably the show All option)
     */
    if (!option || option.isOutOfScope) {
      return;
    }
    // If the option is a link and the user click with special click we don't emit event
    if (option.routerLink && this.specialClick) {
      this.specialClick = false;
      return;
    }
    // this is necessary because of the keyboard behavior on mobile
    (document.activeElement as HTMLElement).blur();

    const trackedData = {
      searchKeywords: this.currentSearchQuery,
      entityType: option.type,
      entityId: option.id,
      routerLink: option.routerLink,
      entityTitle: option.title,
      source: this.source,
    };
    this.productAnalyticsService.trackAction('pageSearched', trackedData);
    this.onoptionselected.emit(option);

    // Live update of the search history
    const searchableItem = this.searchItemsAvailable.find(item => item.id === option.id && item.type === option.type);
    if (searchableItem) {
      searchableItem.historyIndex = this.recentSearch?.findIndex(i =>
        searchableItem.id === i.id && searchableItem.type === i.type
      );
    }
    // always unset searchQuery after selection
    this.currentSearchQuery = '';
  }

  public onChangeSearchValue(searchQuery: string): void {
    // if input search query is empty we want to unset selected value
    if (!searchQuery) {
      this.onoptionselected.emit(null);
    }
    this.currentSearchQuery = searchQuery;
    this.updateSearchResults();
  }

  public updateSearchResults(showAll: boolean = false): void {
    const searchResultList = this.search(this.currentSearchQuery);
    if (!searchResultList) return;
    /*
     * searchItemAvailables can be already sorted, in this case the result list should follow this sort
     * otherwise we apply search bar order
     */
    const orderedResults = this.resultAlreadySorted ? searchResultList : this.orderSearchResults(searchResultList);

    if (orderedResults.length > this.itemsCountLimit && !showAll) {
      this.searchResults = orderedResults.slice(0, this.itemsCountLimit);
      this.showMoreText = `Show all ${orderedResults.length} options`;
      this.showMoreVisible = true;
    } else {
      this.searchResults = orderedResults;
      this.showMoreVisible = false;
    }
    this.cdRef.detectChanges();
  }

  public showAll(event: MouseEvent): void {
    event.stopPropagation();
    this.$autoComplete.openPanel();
    this.updateSearchResults(true);
  }

  public trackSearchOption(_, item: IdentityItem): string {
    return item.type + item.id;
  }

  private search(searchQuery: string): IdentityItem[] {
    searchQuery = StringHelper.cleanString(searchQuery);

    if (!searchQuery) {
      return this.searchItemsAvailable || [];
    }

    const results: IdentityItem[] = [];
    const isImoOrMmsi = (/\d{5,9}/g).test(searchQuery);
    let searchTokens = searchQuery.split(/[\s]/g).filter(k => k !== '');
    searchTokens = searchTokens.length > 1
      ? searchTokens.filter(k => !removeableKeywords.includes(k.toLowerCase()))
      : searchTokens;

    for (const searchableItem of this.searchItemsAvailable) {
      const title = this.getItemTitle(searchableItem);
      const subtitle = this.getItemSubtitle(searchableItem);
      /*
       * Remove all spaces from title for this field, which allows to search without any spaces and
       * still match results
       */
      const singleWordTitle = title.replace(' ', '');
      const alias = this.getItemAlias(searchableItem);

      const searchFields = [
        { field: title, priority: 9 },
        { field: singleWordTitle, priority: 9 },
        { field: subtitle, priority: 8 },
        {
          field: alias,
          priority: 6,
          additionalSearchInfo: searchableItem.type === 'manager' ? 'Subsidiary ' : 'Other name ',
        },
      ];

      if (isImoOrMmsi) {
        if (searchableItem.mmsi) {
          searchFields.push({ field: searchableItem.mmsi?.toString(), priority: 11, additionalSearchInfo: 'MMSI ' });
        }
        if (searchableItem.imo) {
          searchFields.push({ field: searchableItem.imo?.toString(), priority: 10, additionalSearchInfo: 'IMO ' });
        }
      }
      let resultAdded: boolean = false;
      for (let i = 0; i < searchFields.length; i++) {
        const field = searchFields[i];

        const additionalSearchInfo = field['additionalSearchInfo']
          ? field['additionalSearchInfo'] + field['field']
          : null;
        if (field['field'] === searchQuery) {
          results.push({
            ...searchableItem,
            priority: field['priority'],
            // Set to true only for titles, as we don't want to prioritize on other fields
            isExactMatch: field['field'] === title,
            additionalSearchInfo: additionalSearchInfo,
          });
          resultAdded = true;
        } else if (field['field'].startsWith(searchQuery)) {
          results.push({
            ...searchableItem,
            priority: field['priority'] - 1,
            additionalSearchInfo: additionalSearchInfo,
          });
          resultAdded = true;
        }
        if (resultAdded) {
          break;
        }
      }
      if (resultAdded) {
        continue;
      }

      let nbMatchingWords = 0;
      let itemPriority = 0;
      let keywordExactMatches = 0;
      for (const searchToken of searchTokens) {
        if (title.split(' ').includes(searchToken)) {
          /*
           * This means a word of the title has matched the current input searchToken. We want to include this option in
           * the results with a priority proportional to the searchToken's length compared to the full title's length
           * AND compared to the length of the total search query.
           * We therefore increment the priority value based on the sum of those two comparisons, that we multiply by 4.
           */
          keywordExactMatches += 1;
          itemPriority += 4
            * (searchToken.length / searchQuery.replace(/\s/g, '').length
              + searchToken.length / title.replace(/\s/g, '').length);
          nbMatchingWords++;
          continue;
        }
        if (title.indexOf(searchToken) !== -1) {
          itemPriority = 5;
          nbMatchingWords++;
          continue;
        }
        if (subtitle.indexOf(searchToken) !== -1) {
          itemPriority = Math.max(itemPriority, 2);
          nbMatchingWords++;
          continue;
        }

        if (this.getItemTypeTitle(searchableItem).indexOf(searchToken) !== -1) {
          itemPriority = Math.max(itemPriority, 1);
          nbMatchingWords++;
          continue;
        }
      }
      /*
       * If the searchToken is included in the title, or if there's an exact match of the searchToken, then we must
       * consider this search option
       */
      if (nbMatchingWords === searchTokens.length || keywordExactMatches) {
        results.push({
          ...searchableItem,
          // the ** keywordExactMatches allows to give a higher priority to results with more searchToken matches
          priority: itemPriority * (1.1 ** keywordExactMatches),
        });
      }
    }

    return results;
  }

  private getItemTitle(item: IdentityItem): string {
    return item.cleanTitle || item.title?.toLowerCase() || '';
  }

  private getItemSubtitle(item: IdentityItem): string {
    return item.cleanSubtitle || item.subtitle?.toLowerCase() || '';
  }

  private getItemAlias(item: IdentityItem): string {
    return item.alias?.toLowerCase() || '';
  }

  private getItemTypeTitle(item: IdentityItem): string {
    return item.cleanTypeTitle || item.typeTitle?.toLowerCase() || '';
  }

  /**
   * Init the historyIndex for the available search items if we have recent searches
   */
  private initHistoryIndex(): void {
    if (!this.recentSearch?.length || !this.searchItemsAvailable?.length) {
      return;
    }

    this.searchItemsAvailable.forEach(item => {
      // For values not in recentSearch, init historyIndex to -1. It's important for orderSearchResults comparison
      item.historyIndex = this.recentSearch?.findIndex(i => item.id === i.id && item.type === i.type);
    });
  }

  private orderSearchResults(results: IdentityItem[]): IdentityItem[] {
    /*
     * Order by exact match, then by history index, then by scope, then by priority, then by type, then by
     * alphabetical order
     */
    const sorted = results.sort((a: IdentityItem, b: IdentityItem) => {
      // We want to prioritize exact search matches over any history searches
      if (a.isExactMatch && !b.isExactMatch) {
        return -1;
      }
      if (!a.isExactMatch && b.isExactMatch) {
        return 1;
      }

      /*
       * We can order either by order or priority
       * If priority is used, the item with the highest priority will be first
       * If order is used, the item with the lowest order will be first
       */
      if (a.priority < b.priority) {
        return 1;
      }
      if (a.priority > b.priority) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      if (a.order < b.order) {
        return -1;
      }

      if (a.historyIndex > b.historyIndex) {
        return -1;
      }
      if (a.historyIndex < b.historyIndex) {
        return 1;
      }

      if (a.isOutOfScope && !b.isOutOfScope) {
        return 1;
      }
      if (!a.isOutOfScope && b.isOutOfScope) {
        return -1;
      }

      /*
       * This applies to vessels only. If two records have the same priority, order and historyIndex, they could be
       * differentiated by the `latest` field as we want to select the current vessel feature
       */
      if ((a.latest && !b.latest) || (a.latest > b.latest)) {
        return -1;
      }
      // If we don't specify resultTypeOrder we just order by default
      if (!a.type || !b.type || !this.resultTypeOrder || !this.resultTypeOrder.length) {
        return this.orderSearchResultsDefault(a, b);
      }
      let aTypePriority = this.resultTypeOrder.indexOf(a.type.toLowerCase());
      let bTypePriority = this.resultTypeOrder.indexOf(b.type.toLowerCase());

      aTypePriority = aTypePriority === -1 ? Number.MAX_SAFE_INTEGER : aTypePriority;
      bTypePriority = bTypePriority === -1 ? Number.MAX_SAFE_INTEGER : bTypePriority;

      if (aTypePriority < bTypePriority) {
        return -1;
      } else if (aTypePriority === bTypePriority) {
        return this.orderSearchResultsDefault(a, b);
      }
      return 1;
    });

    const uniqueValues = new Map<string, IdentityItem>();
    sorted.forEach(elt => {
      /*
       * Unique key is `id;type`, to keep the option with the highest priority per record, as multiple vessel features
       * can point to the same vessel for instance.
       */
      const key = `${elt['id']};${elt['type']}`;
      if (!uniqueValues.has(key)) {
        uniqueValues.set(key, elt);
      }
    });

    return [...uniqueValues.values()];
  }

  private orderSearchResultsDefault(a: IdentityItem, b: IdentityItem): number {
    if (a.type === b.type && a.typeOrder != null && b.typeOrder != null) {
      const typeOrderDiff = a.typeOrder - b.typeOrder;

      // if typeOrder is different: reflect order difference
      if (typeOrderDiff !== 0) {
        return typeOrderDiff;
      }
    }

    // default order: alphabetical
    return DataHelpers.alphabeticalOrder(a, b, d => d.title.toLowerCase(), this.currentSearchQuery);
  }

  public onClickInput(): void {
    this.updateSearchResults();
  }

  public onClickOption(event: MouseEvent): void {
    if (DatabaseHelper.isSpecialClick(event)) {
      this.specialClick = true;
      return;
    }
    event.preventDefault();
  }

  public requestVessel(option): void {
    this.dialog.open(VesselRequestFormComponent, {
      maxHeight: '80vh',
      minHeight: '60%',
      maxWidth: '40em',
      panelClass: 'modal-container',
      data: option,
    });
  }

  getOptionHTMLId(option: { title: string; type?: string }): string {
    let title = 'option-title-';

    if (option?.type) {
      title += `${option.type}-`;
    }

    title += `${option.title}`;
    return title;
  }
}
