import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { get, set, sum } from 'lodash-es';
import { Subject } from 'rxjs';

import { Config } from '../config/config';
import { DataLoader } from '../data-loader/data-loader';
import { LayerFilter, NumOrString, VesselFleetInfo } from '../helpers/types';
import { RefDataProvider, getChained } from '../data-loader/ref-data-provider';
import { FilterHelper } from '../filters/filter-helper';

export abstract class PreferencesKeys {
  public static timezone = ['timezone'];
  public static selectedFleets = ['selectedFleets'];
  public static selectedOsvProjectIds = ['selectedOsvProjectIds'];
  public static searchItems = ['searchItems'];
  public static dpr = ['dpr'];
  public static layer = (dashboardId: string, layerId: string): string[] => [
    'dashboards',
    dashboardId,
    'layers',
    layerId,
  ];
  public static filter = (dashboardId: string, layerId: string, filterId: string): string[] => [
    'dashboards',
    dashboardId,
    'layers',
    layerId,
    'filters',
    filterId,
  ];
  public static tableSchedule = (dashboardId: string): string[] => [
    'dashboards',
    dashboardId,
    'components',
    'tableSchedule',
  ];
  public static tableEntityTable = (entityName: string): string[] => [
    'dashboards',
    'entity-table',
    entityName,
    'components',
    'table',
  ];
  public static pageSections = (pageId: string): string[] => [
    'pages',
    pageId,
    'sections',
  ];
  public static pageSection = (pageId: string, sectionName: string): string[] => [
    'pages',
    pageId,
    'sections',
    sectionName,
  ];
  public static pageComponent = (pageId: string, componentId: string): string[] => [
    'pages',
    pageId,
    'components',
    componentId,
  ];
  public static pageComponentLayers = (pageId: string, componentId: string): string[] => [
    'pages',
    pageId,
    'components',
    componentId,
    'layers',
  ];
  public static googleMapsLabels = (panelId: string): string[] => [
    'dashboards',
    panelId,
    'googleMapsLabels',
  ];
}

@Injectable({
  providedIn: 'root',
})
export class AppInfoService {
  constructor(
    public config: Config,
    private router: Router,
    injector: Injector,
  ) {
    this.dataLoader = injector.get(DataLoader);
  }
  private dataLoader: DataLoader;

  // Notify if display save current analysis is needed
  private displaySaveAnalysisSource: Subject<boolean> = new Subject();
  public displaySaveAnalysis = this.displaySaveAnalysisSource.asObservable();

  /*
   * Use to notify components when user made an action linked to the state in the app
   * (e.g. change a filter or a graph select)
   */
  private singleUserActionSource: Subject<boolean> = new Subject();
  public singleUserAction = this.singleUserActionSource.asObservable();

  private userActionList: { [actionId: string]: number } = {};
  public dashboardActionIgnored = ['global-alert-subscription'];

  private reloadPageActionSource: Subject<boolean> = new Subject();
  public reloadPageAction = this.reloadPageActionSource.asObservable();

  public reinitUserActions() {
    this.userActionList = {};
    this.setSaveAnalysis(false);
  }

  /*
   * Call when user made an action
   * ActionId corresponds to the fieldId or the selectId the user change
   * removeAction is true if the action was to remove something applied
   * e.g remove a filter
   * Some action can be cumulative i.e. for a multi filter if user select two values, we'll count two actions
   * but for most of the case (interval for exemple) we don't want to cumulate the action but count 1 user action for all interaction on the interval
   */
  public userAction(actionId: string, removeAction: boolean = false, counter: boolean = false) {
    this.setSingleUserAction();

    const currentDashboard = AppInfoService.getCurrentDashboard(this.router);
    if (this.dashboardActionIgnored.includes(currentDashboard)) {
      return;
    }

    /*
     * If the action was a 'removeAction' i.e. for exemple removing a filter
     * and the action was in our action list we remove it from this list
     */
    if (removeAction && this.userActionList[actionId]) {
      this.userActionList[actionId]--;
      if (this.userActionList[actionId] == 0) {
        delete this.userActionList[actionId];
      }
      // otherwise we add the action to the userActionList
    } else if (!removeAction && !this.userActionList[actionId]) {
      this.userActionList[actionId] = 1;
      /*
       * If we alread add an entry for this action we increase the counter only if the action is
       * a cumulative action (e.g. a multi filter) and counter true
       */
    } else if (!removeAction && this.userActionList[actionId] && counter) {
      this.userActionList[actionId]++;
    }
    /*
     * If the user is simultaneously applying 3 actions or more
     * we notify the app to display save analaysis button
     */
    if (sum(Object.values(this.userActionList)) >= 3) {
      this.setSaveAnalysis(true);
    }
  }

  public setSaveAnalysis(value: boolean) {
    this.displaySaveAnalysisSource.next(value);
  }

  public setSingleUserAction() {
    this.singleUserActionSource.next(true);
  }

  // Notify if a vessel fleet need to be created
  private vesselFleetCreateSource: Subject<VesselFleetInfo> = new Subject();
  public vesselFleetCreate = this.vesselFleetCreateSource.asObservable();

  public userCreateVesselFleet(title: string, filters: LayerFilter, sharedFleet: boolean = false): void {
    const newVesselFleet: VesselFleetInfo = {
      title: title,
      filters: filters,
      sharedFleet: sharedFleet,
    };
    this.vesselFleetCreateSource.next(newVesselFleet);
  }

  private enterLastFleetCreatedSource: Subject<boolean> = new Subject();
  public enterLastFleetCreated = this.enterLastFleetCreatedSource.asObservable();

  public userEnterLastFleetCreated() {
    this.enterLastFleetCreatedSource.next(true);
  }

  // Notify if a vessel fleet has been deleted
  private vesselFleetDeletedSource: Subject<boolean> = new Subject();
  public vesselFleetDeleted = this.vesselFleetDeletedSource.asObservable();

  public userDeletedVesselFleet() {
    this.vesselFleetDeletedSource.next(true);
  }

  /** Retrieve intelligible title of given filterId */
  public getVesselFilterTitle(filterId: string): string {
    return FilterHelper.findFieldInFieldsets(this.config.vesselFieldsets, f => f.id === filterId)?.title;
  }

  /** Retrieve intelligible title of a value of given filterId */
  public getTitleForVesselFilterValue(filterId: string, valueToFind: string): string {
    const filterConfig = FilterHelper.findFieldInFieldsets(this.config.vesselFieldsets, f => f.id === filterId);
    if (!filterConfig?.propTitle || filterConfig.propValue === filterConfig.propTitle) {
      return valueToFind; // Nothing to do if propTitle is not defined or if title and value are from the same prop!
    }

    // Find the first vessel that has the given valueToFind on its propValue.
    const foundVessel = RefDataProvider.currentProjectVesselDataset?.find(v => {
      const candidateValue = getChained<NumOrString>(v, filterConfig.propValue);
      return candidateValue === valueToFind;
    });

    // If a vessel has been found, return its propTitle value, otherwise return the raw valueToFind.
    return foundVessel ? getChained<string>(foundVessel, filterConfig.propTitle) : valueToFind;
  }

  public static getCurrentDashboard(router: Router) {
    const dashboardMatches = router.url.match(/dashboard\/([-\w]+)/);
    if (dashboardMatches?.length > 1) {
      return dashboardMatches[1];
    }
    return null;
  }

  public saveUserPreference(preferenceKeys: string[], value: unknown): Promise<void> {
    set(this.config.userInfo, ['preferences', ...preferenceKeys], value);
    return this.saveUserPreferences();
  }

  public getUserPreference<T>(preferenceKeys: string[]): T {
    /**
     * We always want to retrieve the userPreference because we do not modify them while impersonating
     * It's also required to check user feedback
     */
    return get(this.config.userInfo, ['preferences', ...preferenceKeys]);
  }

  private saveUserPreferences(): Promise<void> {
    if (!this.config.impersonate) {
      return this.dataLoader.post<any, any>('/base/saving/user-preferences', this.config.userInfo.preferences);
    }
  }

  public currentDashboardIsMap(): boolean {
    return this.router.isActive('dashboard/map', {
      paths: 'subset',
      queryParams: 'ignored',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }

  public reloadComponentsPage(): void {
    this.reloadPageActionSource.next(true);
  }
}
